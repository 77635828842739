import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import BoundaryInfo from '@/views/components/boundary/BoundaryInfo.vue';
import GroupListItemMobile from '@/views/components/group/GroupListItemMobile.vue';
import { SourceGroup } from '@/entities/models/SourceGroup';
import DecimalConversion from '@/services/DecimalConversion';
import { ServiceState } from '@/entities/enums/ServiceState';
import LoggedUserManager from '@/services/LoggedUserManager';
let GroupList = class GroupList extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.ServiceState = ServiceState;
        this.tableData = [];
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    changeVisibility(source) {
        this.$emit('changeVisibility', source);
    }
    async created() {
        this.sourceGroups.forEach((group) => {
            this.groupsArrayForTable(group);
        });
    }
    groupsArrayForTable(group) {
        let sources = [];
        group.sources.forEach((source) => {
            sources.push({
                source: source,
                isVisible: false
            });
        });
        this.tableData.push({
            id: group.id,
            name: group.name,
            sources: sources,
            visible: group.visible
        });
    }
    isSourceGroup(element) {
        return element instanceof SourceGroup;
    }
    isSourceInGroup(source) {
        let group = this.sourceGroups.find((x) => x.sources.includes(source));
        return group != null && group.name != null;
    }
    getBoundaryIcon(boundaryType) {
        let icon = '';
        switch (boundaryType) {
            case 'temperature':
                icon = 'fas fa-thermometer-half';
                break;
            case 'humidity':
                icon = 'fas fa-tint';
                break;
            case 'acceleration':
                icon = 'fas fa-car-crash';
                break;
            default:
                icon = 'fas fa-question';
                break;
        }
        return icon;
    }
    chevronIcon(isVisible) {
        if (isVisible)
            return 'chevron-down';
        return 'chevron-right';
    }
    toggle(row, index) {
        let rowIndex = this.tableData[index].sources.indexOf(row);
        this.tableData[index].sources[rowIndex].isVisible = !this.tableData[index].sources[rowIndex].isVisible;
        this.$refs.sourceGroupTable[index].toggleDetails(row);
    }
    selectedRow(row, index) {
        let rowIndex = this.tableData[index].sources.indexOf(row);
        if (this.tableData[index].sources[rowIndex].isVisible)
            return 'is-primary';
        return '';
    }
};
__decorate([
    Prop({ type: Array })
], GroupList.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Object })
], GroupList.prototype, "dateTimeManager", void 0);
GroupList = __decorate([
    Component({ components: { BoundaryInfo, GroupListItemMobile } })
], GroupList);
export default GroupList;

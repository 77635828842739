import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoggedUserManager from '@/services/LoggedUserManager';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let DashboardsList = class DashboardsList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.componentName = 'dashboardList';
        this.tableQueries = null;
        this.tableColumns = {
            name: {
                field: 'name',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DashboardsListName)
            },
            createdBy: {
                field: 'createdBy',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DashboardsListCreatedBy)
            },
            sourceList: {
                field: 'sourceList',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DashboardsListSourceList)
            },
            gatewayList: {
                field: 'gatewayList',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DashboardsListGatewayList)
            },
            sourceGroupList: {
                field: 'sourceGroupList',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DashboardsListSourceGroupList)
            },
            isPublic: {
                field: 'isPublic',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DashboardsListIsPublic)
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get isLoggedInCompany() {
        return LoggedUserManager.isLoggedToCompany(this);
    }
    created() {
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    fillFilterFromRoute() {
        try {
            this.tableQueries = JSON.parse(this.$router.currentRoute.query.dashboardList);
            this.sortField = this.tableQueries.sortField;
            this.sortDirection = this.tableQueries.sortDirection;
            this.page = this.tableQueries.page;
            this.pageSize = this.tableQueries.pageSize;
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.DashboardsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.DashboardsSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.DashboardsPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.DashboardsRowsPerPage.defaultValue;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange();
    }
    onPageChange(page) {
        this.page = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    onOptionsChange() {
        let tableQueries = {
            sortField: this.sort.field,
            sortDirection: this.sort.direction == '-' ? 'desc' : 'asc',
            page: this.page,
            pageSize: this.pageSize,
            filters: null
        };
        this.changeRouterUrl(JSON.stringify(tableQueries));
    }
    changeRouterUrl(tableQueries) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                [this.componentName]: tableQueries
            })
        })
            .catch((err) => err);
    }
    confirmDashboardDelete(id) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.public_dashboard.delete.title'),
            message: this.$t('component.public_dashboard.delete.message'),
            confirmText: this.$t('component.public_dashboard.delete.confirm'),
            type: 'is-primary',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('deleteDashboard', id);
            }
        });
    }
    dashboardSelected(row) { }
    getDepartmentId() {
        if (this.isLoggedInCompany)
            return this.$route.params.departmentId;
        else if (this.isLoggedFromAdmin)
            return this.$route.params.companyId;
        else
            return this.$route.params.departmentId;
    }
    getRouterName() {
        if (this.isLoggedInCompany)
            return 'publicDashboardDetail';
        else if (this.isLoggedFromAdmin)
            return 'adminPublicDashboardDetail';
        else
            return 'publicDashboardDetail';
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.createdBy.field:
                if (!event) {
                    if (this.sortField == this.tableColumns.createdBy.field)
                        this.sortField = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DashboardsListCreatedBy, event);
                break;
            case this.tableColumns.sourceList.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DashboardsListSourceList, event);
                break;
            case this.tableColumns.gatewayList.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DashboardsListGatewayList, event);
                break;
            case this.tableColumns.sourceGroupList.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DashboardsListSourceGroupList, event);
                break;
            case this.tableColumns.isPublic.field:
                if (!event) {
                    if (this.sortField == this.tableColumns.isPublic.field)
                        this.sortField = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DashboardsListIsPublic, event);
                break;
        }
    }
};
__decorate([
    Prop({ type: Array })
], DashboardsList.prototype, "dashboards", void 0);
__decorate([
    Prop({ type: Object })
], DashboardsList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], DashboardsList.prototype, "totalSize", void 0);
DashboardsList = __decorate([
    Component({})
], DashboardsList);
export default DashboardsList;

import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Header from '@/views/components/common/Header.vue';
import Footer from '@/views/components/common/Footer.vue';
let Default = class Default extends Vue {
};
Default = __decorate([
    Component({
        components: { Header, Footer }
    })
], Default);
export default Default;

import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import { SourceGroup } from '@/entities/models/SourceGroup';
import { ApiQueries } from '@/entities/ApiQueries';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiStatus from '@/entities/enums/apiStatuses';
import ApiResponse from '@/entities/ApiResponse';
import LoggedUserManager from '../LoggedUserManager';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class SourceGroupRepository {
    constructor(ctx) {
        this.ctx = ctx;
        this.isAdmin = LoggedUserManager.isAdmin(this.ctx);
    }
    async getSourceGroups(pagination = null, sort = null, allGroups = false, departmentId = this.getSelectedDepartmentId()) {
        let finalResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.SOURCE_GROUP) && !this.isAdmin) {
            return finalResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        queries.all = allGroups;
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return finalResponse;
        else {
            //Create response with pagination data
            finalResponse.setPagination(response.data);
            finalResponse.setData(SourceGroup.sortGroupsByName(SourceGroup.fromApi(response.data.data), null));
            return finalResponse;
        }
    }
    async getSourceGroup(groupId, departmentId = this.getSelectedDepartmentId(), processError = true) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.SOURCE_GROUP)) {
            return new SourceGroup();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}/${groupId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, processError);
        if (response.status != ApiStatus.SUCCESS)
            return new SourceGroup();
        else
            return SourceGroup.fromApi(response.data.data);
    }
    async removeGroup(groupId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP)) {
            throw 'Forbidden action removeGroup';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}/${groupId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx, false);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async checkIfNameExists(name, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP)) {
            throw 'Forbidden action checkIfNameExists SourceGroups';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}${ApiLinks.Sources.Keys.Exists}`;
        let queries = new ApiQueries();
        queries.name = name;
        url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        return response.data.data;
    }
    async updateGroup(groupId, dto, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP)) {
            return new SourceGroup();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}/${groupId}`;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new SourceGroup();
        else
            return SourceGroup.fromApi(response.data.data);
    }
    async createGroup(sourceGroup, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP)) {
            return new SourceGroup();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}`;
        let response = await AxiosService.postDataToServer(url, sourceGroup, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return new SourceGroup();
        else
            return SourceGroup.fromApi(response.data.data);
    }
    async adminGetAllGroups(departmentId, pagination = null, sort = null) {
        let finalResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_SOURCES_GROUPS)) {
            return finalResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Sources.Keys.Groups}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return finalResponse;
        else {
            //Create response with pagination data
            finalResponse.setPagination(response.data);
            finalResponse.setData(SourceGroup.sortGroupsByName(SourceGroup.fromApi(response.data.data), null));
            return finalResponse;
        }
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

export default class Quantity {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Quantity.define(et));
        }
        else if (data != null) {
            return Quantity.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let quantity = new Quantity();
        quantity.key = data.Key;
        quantity.producesType = data.ProducesType;
        quantity.requiredTypes = data.RequiredTypes;
        return quantity;
    }
}

import { __decorate } from "tslib";
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppConfig from '@/configLoader';
import SessionStorage from '@/services/repository/SessionStorage';
import { vxm } from '@/store/store.vuex';
var authenticationCalls;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
let AdminSideBar = class AdminSideBar extends Vue {
    constructor() {
        super(...arguments);
        this.isSideMenuVisible = false;
        this.currentConfig = AppConfig.getConfig();
    }
    created() {
        authenticationCalls = new AuthenticationCalls(this);
        this.$eventBus.$on('isSideMenuVisible', (value) => {
            this.isSideMenuVisible = value;
        });
    }
    onNavigation() {
        this.mobileExpand(false);
        this.$eventBus.$emit('closeBurgerMenu', false);
        window.scrollTo(0, 0);
    }
    async logoutAsync() {
        let success = await authenticationCalls.logout();
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            LoggedUserManager.deleteAdminLoginData(this);
            SessionStorage.clearStorageForSession();
            vxDepartmentStore.clearData();
            vxNotificationStore.clearData();
            this.$router.push({ name: 'login', params: { lang: this.$route.params.lang } }).catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
    isActive(routes) {
        if (Array.isArray(routes) && this.$route.name != null) {
            return routes.find((el) => el == this.$route.name) != undefined;
        }
    }
    expandButtonClicked(isExpanded) {
        this.$emit('SideMenuExpanded', isExpanded);
    }
    mobileExpand(isExpanded) {
        this.isSideMenuVisible = isExpanded;
    }
};
__decorate([
    Prop({ type: Boolean })
], AdminSideBar.prototype, "isExpanded", void 0);
AdminSideBar = __decorate([
    Component({})
], AdminSideBar);
export default AdminSideBar;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MeasuredRuleTemplate from '@/views/components/admin/notifications/MeasuredRuleTemplate.vue';
import GeneralRuleTemplate from '@/views/components/admin/notifications/GeneralRuleTemplate.vue';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import NotificationPresetType from '@/entities/enums/NotificationPresetType';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
var notificationSubscriptionsRepository;
let ManageUserPresets = class ManageUserPresets extends Vue {
    constructor() {
        super(...arguments);
        this.selectedPreset = null;
        this.isEditing = false;
        this.isLoading = false;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.localPresets = [];
    }
    get singleMeasuredRule() {
        return this.selectedPreset?.subscriptions?.find((x) => x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
    }
    get hasPresetSelected() {
        return this.selectedPreset != null;
    }
    confirmDeletePreset() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.notification.presets.delete_preset_dialog.title'),
            message: (this.$t('component.notification.presets.delete_preset_dialog.message', { name: this.selectedPreset.name })),
            confirmText: this.$t('component.notification.presets.delete_preset_dialog.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.delete();
            }
        });
    }
    editPreset() {
        this.isEditing = !this.isEditing;
        if (!this.isEditing) {
            this.localPresets = JSON.parse(JSON.stringify(this.presets));
            this.selectedPreset = this.localPresets.find((x) => x.id === this.selectedPreset.id);
            this.rerenderPage();
        }
    }
    async created() {
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        //create copy
        this.localPresets = JSON.parse(JSON.stringify(this.presets));
        if (this.localPresets.length > 0) {
            this.selectedPreset = this.localPresets[0];
        }
        if (this.selectedPreset.id == null) {
            this.isEditing = true;
        }
    }
    getType(name) {
        return this.types.find((x) => x.name === name);
    }
    getRule(name) {
        return this.selectedPreset.subscriptions.find((x) => x.NotificationType === name);
    }
    async onPresetSelected() {
        if (this.selectedPreset.id == null) {
            this.isEditing = true;
        }
        this.rerenderPage();
    }
    /**
     * Re-render component
     */
    rerenderPage() {
        this.isLoading = true;
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    save() {
        if (this.selectedPreset.id == null) {
            this.createPreset();
        }
        else {
            this.updatePreset();
        }
    }
    async createPreset() {
        this.selectedPreset.subscriptions.forEach((subs) => {
            Object.keys(subs.Channels).forEach((chanKey) => {
                if (subs.Channels[chanKey].Settings.enabled === false) {
                    delete subs.Channels[chanKey];
                }
            });
        });
        let dto = {
            Name: this.selectedPreset.name,
            Subscriptions: this.selectedPreset.subscriptions
        };
        this.$emit('createPreset', dto);
    }
    updatePreset() {
        this.selectedPreset.subscriptions.forEach((subs) => {
            Object.keys(subs.Channels).forEach((chanKey) => {
                if (subs.Channels[chanKey].Settings.enabled === false) {
                    delete subs.Channels[chanKey];
                }
            });
        });
        let dto = {
            Name: this.selectedPreset.name,
            Subscriptions: this.selectedPreset.subscriptions,
            Id: this.selectedPreset.id,
            Enabled: true,
            Type: NotificationPresetType.DEPARTMENT
        };
        this.$emit('updatePreset', this.selectedPreset.id, dto);
    }
    delete() {
        this.$emit('deletePreset', this.selectedPreset.id);
    }
    /**
     * This method is called when rule is changed
     */
    onRuleChange(changedRule, isEnabled) {
        //remove current subscription from DTO
        this.selectedPreset.subscriptions = this.selectedPreset.subscriptions.filter((x) => x.NotificationType !== changedRule.NotificationType);
        //handle two types of measured rule
        if (changedRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM) {
            this.selectedPreset.subscriptions = this.selectedPreset.subscriptions.filter((x) => x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        }
        else if (changedRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            this.selectedPreset.subscriptions = this.selectedPreset.subscriptions.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM);
        }
        //if rule is enabled, add it to DTO
        if (isEnabled === true) {
            this.selectedPreset.subscriptions.push(changedRule);
        }
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], ManageUserPresets.prototype, "presets", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ManageUserPresets.prototype, "types", void 0);
ManageUserPresets = __decorate([
    Component({ components: { MeasuredRuleTemplate, GeneralRuleTemplate } })
], ManageUserPresets);
export default ManageUserPresets;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb",
              attrs: { "aria-label": "breadcrumb my-1" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-chevron-left is-size-5",
                          staticStyle: { "padding-right": "10px" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("admin.component.loggers.loggerList.title")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.registerLoggers.title"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        [
          _c("AdminRegisterLogger", {
            attrs: {
              deviceList: _vm.deviceList,
              loggerList: _vm.loggerList,
              dateTimeManager: _vm.dateTimeManager,
            },
            on: {
              connectToDevice: _vm.connectToDevice,
              deleteLogger: _vm.deleteLogger,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
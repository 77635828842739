var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "columns is-mobile is-multiline status-cards" }, [
      _c(
        "div",
        { staticClass: "column is-one-third status-card-text-mobile" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "box is-full-height px-4 py-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered",
              class: _vm.deviceList.find(function (x) {
                return x.isOnline == false
              })
                ? "border-warning"
                : "",
              attrs: {
                to: {
                  name: "configuration_devices",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: _vm.$route.params.departmentId,
                  },
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "has-text-weight-semibold is-size-6 mb-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.devicesStatus.devices"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.deviceList.length > 0
                ? _c("div", [
                    _vm.deviceList.filter(function (x) {
                      return x.isOnline == false
                    }).length > 0
                      ? _c("p", { staticClass: "has-text-centered" }, [
                          _c("img", {
                            staticClass: "signal mx-1",
                            attrs: {
                              src: require("@/assets/img/no_signal.svg"),
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.dashboard.status.offline", {
                                  count:
                                    _vm.deviceList.filter(function (x) {
                                      return x.isOnline == false
                                    }).length +
                                    "/" +
                                    _vm.deviceList.length,
                                })
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-centered" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "wifi",
                                type: "is-success",
                                size: "is-small",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.dashboard.status.onlineMobile"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                  ])
                : _c("p", { staticClass: "has-text-centered" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.devicesStatus.noDevices"
                        )
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-one-third status-card-text-mobile" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "box is-full-height px-4 py-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered",
              class:
                _vm.sources.length !==
                _vm.sources.filter(function (x) {
                  return x.logger && x.logger.isOnline === true
                }).length
                  ? "border-warning"
                  : "",
              attrs: {
                to: {
                  name: "configuration_loggers",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: _vm.$route.params.departmentId,
                  },
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "has-text-weight-semibold is-size-6 mb-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.loggersStatus.loggers"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.sources.length > 0
                ? _c("div", [
                    _vm.sources.length !==
                    _vm.sources.filter(function (x) {
                      return x.logger && x.logger.isOnline === true
                    }).length
                      ? _c("p", { staticClass: "has-text-centered" }, [
                          _c("img", {
                            staticClass: "signal mx-1",
                            attrs: {
                              src: require("@/assets/img/no_signal.svg"),
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.dashboard.status.offline", {
                                  count:
                                    _vm.sources.length -
                                    _vm.sources.filter(function (x) {
                                      return (
                                        x.logger && x.logger.isOnline === true
                                      )
                                    }).length +
                                    "/" +
                                    _vm.sources.length,
                                })
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-centered" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "wifi",
                                type: "is-success",
                                size: "is-small",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.dashboard.status.onlineMobile"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                  ])
                : _c("p", { staticClass: "has-text-centered" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.loggersStatus.noLoggers"
                        )
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-one-third status-card-text-mobile" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "box is-full-height px-4 py-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered",
              class: _vm.outOfBoundsCount > 0 ? "border-warning" : "",
              attrs: {
                to: {
                  name: "configuration_loggers",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: _vm.$route.params.departmentId,
                  },
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "has-text-weight-semibold is-size-6 mb-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.measurementsStatus.measurements"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.measuredEventsCount > 0
                ? _c("div", [
                    _vm.outOfBoundsCount > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-mobile is-justify-content-center",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "is-flex is-mobile" },
                              [
                                _c("b-icon", {
                                  staticClass: "pt-2",
                                  attrs: {
                                    size: "is-small",
                                    icon: "thermometer-half",
                                    type:
                                      _vm.outOfBoundsTempCount > 0
                                        ? "is-primary"
                                        : "is-grey",
                                  },
                                }),
                                _c("p", [
                                  _vm._v(_vm._s(_vm.outOfBoundsTempCount)),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "is-flex is-mobile ml-2" },
                              [
                                _c("b-icon", {
                                  staticClass: "pt-2",
                                  attrs: {
                                    size: "is-small",
                                    icon: "tint",
                                    type:
                                      _vm.outOfBoundsHumidityCount > 0
                                        ? "is-primary"
                                        : "is-grey",
                                  },
                                }),
                                _c("p", [
                                  _vm._v(_vm._s(_vm.outOfBoundsHumidityCount)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _c(
                          "p",
                          { staticClass: "has-text-centered" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "check-circle",
                                type: "is-success",
                                size: "is-small",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.dashboard.status.measurementsStatus.withinBoundsMobile"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                  ])
                : _c("p", { staticClass: "has-text-centered" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.measurementsStatus.noMeasurementsMobile"
                          )
                        ) +
                        " "
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-one-third status-card-text-mobile" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "box is-full-height px-4 py-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered",
              class:
                _vm.withBatteryUnder15.length > 0 ||
                _vm.withBatteryUnder50.length > 0 ||
                _vm.withBatteryUnknown.length > 0
                  ? "border-warning"
                  : "",
              attrs: {
                to: {
                  name: "configuration_loggers",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: _vm.$route.params.departmentId,
                  },
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "has-text-weight-semibold is-size-6 mb-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.batteryStatus.battery"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.sources.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "is-flex is-mobile is-justify-content-space-between is-align-items-center",
                    },
                    [
                      _vm.withBatteryUnder15.length > 0
                        ? _c(
                            "b-tooltip",
                            {
                              staticClass: "mr-3 has-text-centered",
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "component.dashboard.status.batteryStatus.criticalTooltip"
                                ),
                                position: "is-right",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "is-small",
                                attrs: {
                                  icon: "battery-empty",
                                  type: "is-primary",
                                  size: "is-small",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.withBatteryUnder15.length) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.withBatteryUnder50.length > 0
                        ? _c(
                            "b-tooltip",
                            {
                              staticClass: "mr-3 has-text-centered",
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "component.dashboard.status.batteryStatus.lowTooltip"
                                ),
                                position: "is-right",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "is-small",
                                attrs: {
                                  icon: "battery-quarter",
                                  type: "is-info",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.withBatteryUnder50.length) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.withBatteryAbove50.length > 0
                        ? _c(
                            "b-tooltip",
                            {
                              staticClass: "mr-3 has-text-centered",
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "component.dashboard.status.batteryStatus.okTooltip"
                                ),
                                position: "is-right",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "is-small",
                                attrs: {
                                  icon: "battery-full",
                                  type: "is-success",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.withBatteryAbove50.length) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.withBatteryUnknown.length > 0
                        ? _c(
                            "b-tooltip",
                            {
                              staticClass: "has-text-centered",
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "component.dashboard.status.batteryStatus.unknownTooltip"
                                ),
                                position: "is-right",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "is-small",
                                attrs: {
                                  icon: "battery-empty",
                                  type: "is-grey",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.withBatteryUnknown.length) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("p", { staticClass: "has-text-centered" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.loggersStatus.noLoggers"
                        )
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-one-third status-card-text-mobile" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "box is-full-height px-4 py-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered",
              class:
                _vm.companyLoggerExpirationList.length !==
                _vm.companyLoggerExpirationList.filter(function (x) {
                  return x.logger && x.logger.calibration.status.id === 1
                }).length
                  ? "border-warning"
                  : "",
              attrs: {
                to: {
                  name: "configuration_loggers",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: _vm.$route.params.departmentId,
                  },
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "has-text-weight-semibold is-size-6 mb-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.calibrationStatus.calibration"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.companyLoggerExpirationList.length > 0
                ? _c("div", [
                    _vm.companyLoggerExpirationList.length !==
                    _vm.companyLoggerExpirationList.filter(function (x) {
                      return x.logger && x.logger.calibration.status.id === 1
                    }).length
                      ? _c(
                          "p",
                          { staticClass: "has-text-centered" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "exclamation-triangle",
                                type: "is-primary",
                                size: "is-small",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.dashboard.status.calibrationStatus.expiredMobile",
                                    {
                                      count:
                                        _vm.companyLoggerExpirationList.length -
                                        _vm.companyLoggerExpirationList.filter(
                                          function (x) {
                                            return (
                                              x.logger &&
                                              x.logger.calibration.status.id ===
                                                1
                                            )
                                          }
                                        ).length +
                                        "/" +
                                        _vm.companyLoggerExpirationList.length,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        )
                      : _c(
                          "p",
                          { staticClass: "has-text-centered" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "check-circle",
                                type: "is-success",
                                size: "is-small",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.dashboard.status.calibrationStatus.allCalibratedMobile"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                  ])
                : _c("p", { staticClass: "has-text-centered" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.loggersStatus.noLoggers"
                        )
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-one-third status-card-text-mobile" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "box is-full-height px-4 py-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered",
              class: _vm.totalSize > 0 ? "border-warning" : "",
              attrs: {
                to: {
                  name: "notifications",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: _vm.$route.params.departmentId,
                  },
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "has-text-weight-semibold is-size-6 mb-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.notificationsStatus.notifications"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "p",
                { staticClass: "has-text-centered" },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "bell",
                      type: _vm.totalSize > 0 ? "is-warning" : "is-grey",
                      size: "is-small",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.totalSize) +
                      " " +
                      _vm._s(
                        _vm.$t(
                          "component.dashboard.status.notificationsStatus.count"
                        )
                      ) +
                      " "
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "is-flex is-flex-direction-column" }, [
    _c(
      "div",
      {
        staticClass:
          "is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-flex-end",
      },
      [
        _c(
          "div",
          {
            staticClass: "is-flex is-flex-wrap-wrap notification-table-mobile",
          },
          [
            _c(
              "b-field",
              {
                staticClass: "mr-2-tablet",
                attrs: {
                  label: _vm.$t("component.issues.issuesFilter.issues"),
                  "custom-class": "is-hidden-mobile",
                },
              },
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "mt-2",
                    on: { input: _vm.issueTableCheckbox },
                    model: {
                      value: _vm.isIncludeResolved,
                      callback: function ($$v) {
                        _vm.isIncludeResolved = $$v
                      },
                      expression: "isIncludeResolved",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("component.issues.issuesFilter.resolved")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticClass: "mr-2-tablet",
                attrs: {
                  label: _vm.$t("component.issues.issuesFilter.issueType"),
                  "custom-class": "is-hidden-mobile",
                },
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      placeholder: _vm.$t(
                        "component.issues.issuesFilter.typePlaceholder"
                      ),
                    },
                    on: { input: _vm.typeSelected },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  },
                  _vm._l(_vm.DepartmentIssueTypes, function (type) {
                    return _c(
                      "option",
                      { key: type, domProps: { value: type } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("component.issues.issueTypes." + type)
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm.devices &&
            _vm.devices.length > 0 &&
            _vm.DepartmentIssueTypes.DEVICE_OFFLINE == _vm.selectedType
              ? _c(
                  "b-field",
                  {
                    staticClass: "mr-2-tablet",
                    attrs: {
                      label: _vm.$t("component.issues.issuesFilter.device"),
                      "custom-class": "is-hidden-mobile",
                    },
                  },
                  [
                    _c(
                      "b-select",
                      {
                        attrs: {
                          placeholder: _vm.$t(
                            "component.issues.issuesFilter.selectDevice"
                          ),
                        },
                        on: { input: _vm.selectDevice },
                        model: {
                          value: _vm.selectedDevice,
                          callback: function ($$v) {
                            _vm.selectedDevice = $$v
                          },
                          expression: "selectedDevice",
                        },
                      },
                      _vm._l(_vm.devices, function (device) {
                        return _c(
                          "option",
                          { key: device.deviceId, domProps: { value: device } },
                          [_vm._v(_vm._s(device.deviceName))]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.sources &&
            _vm.sources.length > 0 &&
            (_vm.DepartmentIssueTypes.LOW_BATTERY == _vm.selectedType ||
              _vm.DepartmentIssueTypes.MEASURED_DATA_ALARM ==
                _vm.selectedType ||
              _vm.DepartmentIssueTypes.SOURCE_OFFLINE == _vm.selectedType)
              ? _c(
                  "b-field",
                  {
                    staticClass: "mr-2-tablet",
                    attrs: {
                      label: _vm.$t("component.issues.issuesFilter.source"),
                      "custom-class": "is-hidden-mobile",
                    },
                  },
                  [
                    _c(
                      "b-select",
                      {
                        attrs: {
                          placeholder: _vm.$t(
                            "component.issues.issuesFilter.selectSource"
                          ),
                        },
                        on: { input: _vm.selectSource },
                        model: {
                          value: _vm.selectedSource,
                          callback: function ($$v) {
                            _vm.selectedSource = $$v
                          },
                          expression: "selectedSource",
                        },
                      },
                      _vm._l(_vm.sources, function (source) {
                        return _c(
                          "option",
                          { key: source.id, domProps: { value: source } },
                          [_vm._v(_vm._s(source.name))]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-field",
              {
                attrs: {
                  label: _vm.$t("component.issues.issuesFilter.created"),
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "block !important" } },
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: { "aria-role": "list" },
                        on: { change: _vm.handleDateRangeValue },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c("b-button", {
                                  attrs: {
                                    label: _vm.dateRangeLabel,
                                    "icon-left": "calendar",
                                    "icon-right": "menu-down",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.dateRangeValue,
                          callback: function ($$v) {
                            _vm.dateRangeValue = $$v
                          },
                          expression: "dateRangeValue",
                        },
                      },
                      [
                        _c("div", { staticClass: "columns" }, [
                          _c(
                            "div",
                            { staticClass: "column" },
                            [
                              _vm._l(
                                _vm.IssuesFilterDateRangeOptions,
                                function (item) {
                                  return [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        key: item,
                                        attrs: {
                                          value: item,
                                          "aria-role": "listitem",
                                        },
                                      },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.issuesFilter.dateRangeOptions." +
                                                  item
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                }
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    focusable: false,
                                    custom: "",
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "has-text-grey-light is-3" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.issues.issuesFilter.dateRangeMessage"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "column" },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "pr-2",
                                  attrs: {
                                    focusable: false,
                                    paddingless: "",
                                    custom: "",
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "component.issues.issuesFilter.dateRange"
                                        ),
                                        "custom-class": "is-hidden-mobile",
                                      },
                                    },
                                    [
                                      _c("b-datepicker", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "component.issues.issuesFilter.created"
                                          ),
                                          name: _vm.$t(
                                            "component.issues.issuesFilter.created"
                                          ),
                                          "mobile-native": false,
                                          range: "",
                                          inline: "",
                                          "date-formatter": function (date) {
                                            return _vm.selectedDateRange
                                              .length > 0
                                              ? _vm.dateTimeManager.formatTimeRangeInInput(
                                                  date
                                                )
                                              : null
                                          },
                                          "nearby-selectable-month-days": true,
                                          "max-date": _vm.today,
                                          "first-day-of-week": 1,
                                          icon: "calendar-alt",
                                        },
                                        on: { input: _vm.selectDateRange },
                                        model: {
                                          value: _vm.selectedDateRange,
                                          callback: function ($$v) {
                                            _vm.selectedDateRange = $$v
                                          },
                                          expression: "selectedDateRange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm.dateRangeValue && _vm.dateRangeMessage
                      ? _c("p", { staticClass: "is-size-7 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.issues.issuesFilter.selected")
                              ) +
                              " : " +
                              _vm._s(_vm.dateRangeMessage) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "is-flex-mobile" }, [
          _c(
            "div",
            { staticClass: "buttons mb-1" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFilter()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
              ),
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "is-dark-grey",
                                  staticStyle: { "font-size": "1rem" },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field == _vm.tableColumns.type.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.issues.table." + column.field)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export class ReportRecordValue {
    constructor() { }
    static fromApi(data, datepoint) {
        if (Array.isArray(data)) {
            return data.map((et) => ReportRecordValue.define(et, datepoint));
        }
        else if (data != null) {
            return ReportRecordValue.define(data, datepoint);
        }
        else
            return null;
    }
    static define(data, datepoint) {
        let recordValue = new ReportRecordValue();
        recordValue.key = data.Key;
        recordValue.time = data.Time;
        recordValue.eventType = data.EventType;
        recordValue.value = data.Value;
        recordValue.datepoint = datepoint || data.Time;
        return recordValue;
    }
}

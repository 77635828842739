import i18n from '@/lang/lang';
import GeneralChartDataConverter from './GeneralChartDataConverter';
export class AdminLoggerChartDataConverter extends GeneralChartDataConverter {
    constructor(chartData, logger) {
        super();
        this._hasData = false;
        this._services = this.createServices(chartData);
        this._data = this.convertData(chartData, logger);
        this._hasData = this.checkIfHasData(chartData);
    }
    get services() {
        return this._services;
    }
    get data() {
        return this._data;
    }
    get hasData() {
        return this._hasData;
    }
    checkIfHasData(chartData) {
        let hasData = false;
        Object.keys(chartData).forEach((serviceName) => {
            if (serviceName)
                hasData = true;
        });
        return hasData;
    }
    convertData(chartData, logger) {
        let convertedData = new Array();
        Object.keys(chartData).forEach((serviceName) => {
            let borderValues = {
                max_value: chartData[serviceName].info.valueMax,
                min_value: chartData[serviceName].info.valueMin,
                min_time: chartData[serviceName].info.timeMin * 1000,
                max_time: chartData[serviceName].info.timeMax * 1000
            };
            let data = new Array();
            chartData[serviceName].data.forEach((dataUnit) => {
                data.push({ time: dataUnit[0] * 1000, value: dataUnit[1] });
            });
            let loggerData = {
                data: data,
                metadata: borderValues,
                serviceId: chartData[serviceName].unit.id,
                name: logger.currentlyPublishedAs.serialNumber,
                serviceName: i18n.t(`services.${serviceName.toLowerCase()}`)
            };
            convertedData.push(loggerData);
        });
        return convertedData;
    }
    createServices(chartData) {
        let services = new Array();
        Object.keys(chartData).forEach((serviceName) => {
            if (i18n.t(`services.${serviceName.toLowerCase()}`) != i18n.t(`services.dewpoint`)) {
                let existService = services.find((x) => x.name == i18n.t(`services.${serviceName.toLowerCase()}`));
                if (existService == null) {
                    let service = {
                        name: i18n.t(`services.${serviceName.toLowerCase()}`),
                        sign: chartData[serviceName].unit.unit,
                        id: chartData[serviceName].unit.id
                    };
                    services.push(service);
                }
            }
        });
        services = services.sort((a, b) => (a.id > b.id ? 1 : -1));
        return services;
    }
}

import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiStatus from '@/entities/enums/apiStatuses';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiResponse from '@/entities/ApiResponse';
import { vxm } from '@/store/store.vuex';
import DepartmentIssue from '@/entities/models/DepartmentIssue';
import LoggedUserManager from '../LoggedUserManager';
import IssueNotification from '@/entities/models/IssueNotification';
var vxDepartmentStore = vxm.departmentStore;
export default class DeviceRepository {
    constructor(ctx) {
        this.ctx = ctx;
        this.isAdmin = LoggedUserManager.isAdmin(this.ctx);
    }
    async getDepartmentCurrentIssues(pagination = null, sort = null, filters = [], departmentId = this.getSelectedDepartmentId()) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ISSUES) && !this.isAdmin) {
            return parsedResponse;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Issues.Keys.Issues}${ApiLinks.Issues.Keys.Active}`;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filters.length > 0) {
            queries.addFilters(filters);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            //Create response with pagination data
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(DepartmentIssue.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async getDepartmentIssue(issueId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ISSUES)) {
            return new DepartmentIssue();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Issues.Keys.Issues}/${issueId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new DepartmentIssue();
        else
            return DepartmentIssue.fromApi(response.data.data);
    }
    async getDepartmentIssues(pagination = null, sort = null, filters = [], departmentId = this.getSelectedDepartmentId()) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ISSUES) && !this.isAdmin) {
            return parsedResponse;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Issues.Keys.Issues}`;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filters.length > 0) {
            queries.addFilters(filters);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            //Create response with pagination data
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(DepartmentIssue.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async getDepartmentIssueNotifications(issueId, departmentId = this.getSelectedDepartmentId()) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ISSUES) && !this.isAdmin) {
            return parsedResponse;
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Issues.Keys.Issues}/${issueId}${ApiLinks.Issues.Keys.Notifications}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            //Create response with pagination data
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(IssueNotification.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async acknowledgeIssue(issueId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ISSUES) && !this.isAdmin) {
            throw 'Forbidden action acknowledge';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Issues.Keys.Issues}/${issueId}${ApiLinks.Issues.Keys.Acknowledge}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

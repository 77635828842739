var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "multi-header mx-3 mx-0-desktop" }, [
        _c(
          "h1",
          { staticClass: "title is-3 is-spaced has-text-centered-touch my-1" },
          [_vm._v(" " + _vm._s(_vm.$t("admin.component.user.title")) + " ")]
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_USERS
            )
              ? _c(
                  "b-button",
                  {
                    attrs: { outlined: "", type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.createNewUser()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("admin.component.user.createUserBtn")))]
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.MAILINGLIST,
              _vm.$permSubjects.ADMIN_USERS
            )
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      "icon-pack": "fa",
                      "icon-left": "download",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.exportMailingList()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("admin.component.company.export_mailing_list")
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
        _vm.$ability.can(_vm.$permActions.LIST, _vm.$permSubjects.ADMIN_USERS)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminUserList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    users: _vm.userList.getData() || [],
                    dateTimeManager: _vm.dateTimeManager,
                    totalSize: _vm.userList.getPagination().total,
                  },
                  on: {
                    onOptionsChange: _vm.onOptionsChange,
                    loadUsers: _vm.loadUsers,
                    changeRouterUrlFromUser: _vm.changeRouterUrlFromUser,
                    reload: _vm.loadUsers,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateUserModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateUserModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateUserModalActive = $event
            },
            "close-modal": _vm.closeCreateUserModal,
          },
        },
        [
          _c("CreateUserModal", {
            on: { modalClosed: _vm.closeCreateUserModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import SendNotification from '@/views/components/admin/notifications/SendNotification.vue';
import UserRepository from '@/services/repository/UserRepository';
import NotificationRepository from '@/services/repository/NotificationRepository';
import DateTimeManager from '@/services/DateTimeManager';
import NotificationsTable from '@/views/components/notification/NotificationsTable.vue';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import ApiResponse from '@/entities/ApiResponse';
import { vxm } from '@/store/store.vuex';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import AdminPresetsList from '@/views/components/admin/notifications/AdminPresetsList.vue';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import EventDomain from '@/entities/enums/eventDomains';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import LoggedUserManager from '@/services/LoggedUserManager';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import AdminNotificationComponent from '@/views/components/notification/configurationPerType/AdminNotificationComponent.vue';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationPresetType from '@/entities/enums/NotificationPresetType';
import NotificationTabs from '@/entities/notifications/NotificationTabs';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
import NonWorkingHours from '@/views/components/notification/NonWorkingHours.vue';
import Timetable from '@/views/components/notification/Timetable.vue';
import { DepartmentUserSettings } from '@/entities/DepartmentUserSettings';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
import TiersRepository from '@/services/repository/TiersRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
var vxDepartmentStore = vxm.departmentStore;
var userRepository;
var notificationRepository;
var notificationSubscriptionsRepository;
var departmentRepository;
var eventTypeRepository;
var tiersRepository;
var userSettingsRepository;
let AdminNotificationManagement = class AdminNotificationManagement extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationTabs = NotificationTabs;
        this.isLoading = true;
        this.isLoadingNotifications = false;
        this.isChangingMode = false;
        this.notificationPriorities = [];
        this.notificationsResult = new ApiResponse();
        this.dateTimeManager = {};
        this.availableTypes = [];
        this.adminTypes = [];
        this.notificationSubscriptions = [];
        this.currentUser = null;
        this.channels = [];
        this.selectedDepartment = null;
        this.selectedDepartmentId = null;
        this.allDepartments = [];
        this.allUsers = [];
        this.eventTypes = [];
        this.copiedRules = { companyId: null, rules: null, companyName: null };
        this.departments = [];
        this.presets = [];
        this.adminNotificationSubscriptions = [];
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.selectedMeasuredRulesMode = NotificationMeasuredRuleScope.COMPANY;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.departmentName = '';
        //selectedPreset: NotificationPresetWithCount = null;
        this.activeTabNotifications = NotificationTabs.GENERAL;
        this.timetableEnabled = false;
        this.defaultScheduleId = null;
        this.defaultSchedule = null;
        this.subscriptionsToCreate = [];
        this.haveChangesToSave = false;
        this.timetableSchedules = [];
        this.selectedPreset = null;
        this.isUnsavedChangesModalActive = false;
        this.routeToNavigate = null;
        this.notificationDepartmentUserSettings = DepartmentUserSettings.fromApi({});
        this.schedules = [];
        this.tier = null;
        this.measuredRuleEnabled = null;
    }
    get singleMeasuredRule() {
        return this.notificationSubscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredRules() {
        return this.notificationSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get systemRules() {
        return this.notificationSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.SYSTEM_RULE);
    }
    get hasRules() {
        return this.notificationSubscriptions.length > 0;
    }
    get hasPresetSelected() {
        return this.selectedPreset != null;
    }
    get filteredDepartments() {
        return this.allDepartments.filter((dep) => dep.companyName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.departmentName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    get hasAdminDepartmentSelectedInNotifications() {
        return this.systemAdminDepartmentId === this.selectedDepartmentId;
    }
    get systemAdminDepartmentId() {
        return this.departments.find(x => x.member.Role.Key === ApiUserRoles.SYSTEM_ADMIN)?.id;
    }
    get nonWorkingSchedules() {
        return this.schedules.filter((x) => x.type == NotificationScheduleType.SPECIAL_DAYS);
    }
    get hasRulesForEntities() {
        return this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
    }
    async beforeCreate() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
    }
    async created() {
        userRepository = new UserRepository(this);
        notificationRepository = new NotificationRepository(this);
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        departmentRepository = new DepartmentRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        tiersRepository = new TiersRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let promises = [];
        promises.push(userRepository.getCurrentUser());
        promises.push(departmentRepository.getDepartments());
        promises.push(departmentRepository.getAllDepartmentsAdmin());
        promises.push(userRepository.getUsersAdmin());
        Promise.all(promises).then((response) => {
            this.processDataForPage(response[0], response[1], response[2], response[3]);
        });
    }
    async processDataForPage(currentUser, departments, allDepartments, allUsers) {
        this.currentUser = currentUser;
        this.departments = departments.getData().getData();
        this.allDepartments = allDepartments;
        this.allUsers = allUsers.getData();
        try {
            let givenTS = this.$route.query.selectDepartment;
            if (givenTS) {
                this.selectedDepartmentId = this.allDepartments.find((x) => x.companyId == givenTS)
                    ? givenTS
                    : this.allDepartments[0].companyId;
            }
            else {
                this.selectedDepartmentId = this.allDepartments[0].companyId;
            }
        }
        catch (error) {
            this.selectedDepartmentId = this.allDepartments[0].companyId;
        }
        this.loadNotificationRules();
    }
    async loadNotificationRules() {
        let allPromises = [];
        allPromises.push(notificationRepository.getNotificationPriorities());
        allPromises.push(notificationSubscriptionsRepository.getNotificationTypes());
        allPromises.push(notificationSubscriptionsRepository.getNotificationChannels());
        allPromises.push(notificationSubscriptionsRepository.adminGetAdminNotificationTypes());
        allPromises.push(eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true, false, this.selectedDepartmentId));
        if (this.isResellerAdmin) {
            allPromises.push(notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId, this.selectedDepartmentId));
        }
        else {
            allPromises.push(notificationSubscriptionsRepository.adminGetNotificationPresets());
        }
        allPromises.push(notificationSubscriptionsRepository.getNotificationSchedules(this.currentUser.apiUserId, this.selectedDepartmentId));
        allPromises.push(tiersRepository.getActiveCompanyTier(this.selectedDepartmentId));
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.id, true, this.selectedDepartmentId));
        Promise.all(allPromises).then((response) => {
            this.processAdditionalDataForPage(response[0], response[1], response[2], response[3], response[4], response[5], response[6], response[7], response[8]);
        });
    }
    async processAdditionalDataForPage(priorities, types, channels, adminTypes, eventTypes, presets, schedules, tier, departmentUseSettings) {
        this.notificationPriorities = priorities;
        this.channels = channels;
        this.adminTypes = adminTypes;
        this.notificationDepartmentUserSettings = departmentUseSettings;
        this.eventTypes = eventTypes;
        this.availableTypes = types;
        this.schedules = schedules;
        this.tier = tier;
        this.selectedDepartment = this.allDepartments.find((x) => x.companyId == this.selectedDepartmentId);
        this.presets = presets.filter(x => x.type != NotificationPresetType.DEPARTMENT);
        this.selectedMeasuredRulesMode =
            departmentUseSettings.notifications.rules.mode ||
                NotificationMeasuredRuleScope.COMPANY;
        let timetableMode = departmentUseSettings.notifications.schedule.mode;
        if (timetableMode === NotificationScheduleType.TIMETABLE) {
            this.timetableEnabled = true;
            this.notificationSubscriptions = [];
            this.timetableSchedules = schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        }
        else {
            this.timetableEnabled = false;
            this.defaultSchedule = schedules.find((x) => x.type === NotificationScheduleType.DEFAULT);
            this.defaultScheduleId = this.defaultSchedule?.id;
            this.notificationSubscriptions = this.defaultSchedule?.subscriptions || [];
            this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
            this.adminNotificationSubscriptions = this.defaultSchedule?.subscriptions?.filter(x => x.scopeType == "");
        }
        this.isLoading = false;
    }
    //triggered when measured rules mode is changed
    async changeMode(selectedMode) {
        this.isChangingMode = true;
        this.measuredRuleEnabled = true;
        this.selectedMeasuredRulesMode = selectedMode;
        this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM &&
            x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        this.isChangingMode = false;
    }
    async copyRulesDialog() {
        //if there is rule with logger or group scope it cannot be coppied
        if (this.notificationSubscriptions.find(x => x.scopeType !== NotificationScopeType.COMPANY)) {
            this.$buefy.dialog.confirm({
                title: this.$t('component.notification.rules_list.copy_rules_dialog.copy_tooltip_msg'),
                message: this.$t('component.notification.rules_list.copy_rules_dialog.dialog_msg'),
                cancelText: this.$t('component.notification.rules_list.copy_rules_dialog.cancel'),
                confirmText: this.$t('component.notification.rules_list.copy_rules_dialog.apply'),
                type: 'is-success',
                onConfirm: () => this.copyRules()
            });
        }
        else {
            this.copyRules();
        }
    }
    getMeasuredRuleEnabled() {
        return this.singleMeasuredRule ? this.singleMeasuredRule.enabled : false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    async copyRules() {
        this.copiedRules.rules = this.notificationSubscriptions;
        this.copiedRules.companyId = this.selectedDepartment.companyId;
        this.copiedRules.companyName = this.selectedDepartment.companyName;
        VueUtilities.openSuccessToast(this, this.$t('component.notification.rules_list.copy_rules.copy_success').toString());
    }
    async reloadPresets() {
        let presets = await notificationSubscriptionsRepository.adminGetNotificationPresets();
        this.presets = presets.filter(x => x.type != NotificationPresetType.DEPARTMENT);
    }
    async pasteRules() {
        this.isLoading = true;
        let response = await notificationSubscriptionsRepository.deleteAllNotificationSubscriptions(this.currentUser.id, this.defaultSchedule?.id, this.selectedDepartmentId);
        if (response !== true) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                error: response
            }));
            this.isLoading = false;
            return;
        }
        //now create all copied rules in new department
        let rulesToCreate = [];
        this.copiedRules.rules.forEach((rule) => {
            //filter out settings for loggers and source groups, we are not able to copy such rules
            if (rule.scopeType === NotificationScopeType.SOURCE ||
                rule.scopeType === NotificationScopeType.SOURCE_GROUP)
                return;
            let currentChannelsDTO = {};
            rule.channels.forEach((channel) => {
                let channelDTO = {
                    Settings: {
                        delay: channel.settings.delay,
                        onResolve: channel.settings.onResolve,
                        repeat: channel.settings.repeat
                    },
                    IsPaused: false
                };
                currentChannelsDTO[channel.type] = channelDTO;
            });
            let newRule = {
                ScopeType: rule.scopeType,
                ScopeId: this.selectedDepartmentId,
                NotificationType: rule.notificationType,
                Priority: rule.priority.name,
                Enabled: rule.enabled,
                Channels: currentChannelsDTO,
                DisplayMode: rule.displayMode,
                PresetId: rule.presetId
            };
            rulesToCreate.push(newRule);
        });
        let result = await notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.currentUser.id, this.selectedDepartment.companyId, this.defaultSchedule?.id);
        if (result instanceof (Array)) {
            VueUtilities.openSuccessToast(this, this.$t('component.notification.create_rule.bulk_success'));
            this.loadNotificationRules();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                error: result
            }));
            this.isLoading = false;
        }
    }
    confirmPasteRules() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.notification.rules_list.paste_rules.paste_tooltip_msg'),
            message: this.$t('component.notification.rules_list.paste_rules.dialog_msg', {
                originDep: this.copiedRules.companyName,
                newDep: this.selectedDepartment.companyName
            }),
            cancelText: this.$t('component.notification.rules_list.paste_rules.cancel'),
            confirmText: this.$t('component.notification.rules_list.paste_rules.apply'),
            type: 'is-success',
            onConfirm: () => this.pasteRules()
        });
    }
    async sendNotification(notification) {
        this.isLoading = true;
        let result = await notificationRepository.sendNotification(notification);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.notifications.sendNotification.messages.success').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.notifications.sendNotification.messages.failed').toString());
        }
        this.isLoading = false;
    }
    async onOptionsChange(options, currentFilter, dateRange, departmentId = '') {
        this.callOptions = options;
        this.loadNotifications(options.pagination, options.sort, currentFilter, dateRange, departmentId);
    }
    async loadNotifications(pagination, sort, currentFilter, dateRange, departmentId) {
        this.isLoadingNotifications = true;
        let convertedDateRange = dateRange.map((x) => this.dateTimeManager.formatTime(x, null, 'MM-DD-YYYY', null, false));
        this.notificationsResult = await notificationRepository.getNotifications(pagination, sort, currentFilter, convertedDateRange, departmentId);
        this.isLoadingNotifications = false;
    }
    async notificationRemoved(notifIds, pagination, sort, filters = [], dateRange = [], departmentId = '') {
        this.isLoadingNotifications = true;
        await notificationRepository.markNotificationsAsRead(notifIds);
        await this.loadNotifications(pagination, sort, filters, dateRange, departmentId);
        this.isLoadingNotifications = false;
    }
    async removeAllNotifications() {
        this.isLoadingNotifications = true;
        await notificationRepository.markAllNotificationsAsRead();
        this.notificationsResult.setData([]);
        this.isLoadingNotifications = false;
    }
    async applyPreset(preset) {
        this.isLoading = true;
        this.notificationSubscriptions = [];
        let presetWithSubscriptions = null;
        //Load preset with subscriptions
        let response = null;
        if (LoggedUserManager.isResellerAdmin(this)) {
            response = preset;
        }
        else {
            response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(preset.id);
        }
        //handle error response
        if (typeof response == 'string') {
            VueUtilities.openErrorToast(this, response);
            return;
        }
        else {
            presetWithSubscriptions = response;
        }
        this.selectedPreset = presetWithSubscriptions;
        this.selectedPreset.subscriptions.forEach((subs) => {
            this.notificationSubscriptions.push(NotificationSubscription.convertFromPreset(subs, this.selectedDepartmentId));
        });
        this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    async selectDepartment(department) {
        this.isLoading = true;
        this.$router
            .push({
            name: this.$route.name,
            params: {
                lang: this.$route.params.lang
            },
            query: Object.assign({}, this.$route.query, { selectDepartment: department.companyId })
        })
            .catch((err) => err);
        this.selectedDepartmentId = department.companyId;
        this.subscriptionsToCreate = [];
        this.loadNotificationRules();
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    getRule(name) {
        return this.systemRules.find((x) => x.notificationType === name);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    getAdminRule(name) {
        return this.adminNotificationSubscriptions.find((x) => x.notificationType === name);
    }
    getAdminType(name) {
        return this.adminTypes.find((x) => x.name === name);
    }
    focusDepartmentName(event) {
        if (this.$refs.departmentName && event) {
            this.$refs.departmentName.focus();
        }
    }
    tabChanged(tab) {
        //set active tab in url;
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: tab
            })
        })
            .catch((err) => err);
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
    }
    async saveDefaultRules() {
        let errors = [];
        this.isLoading = true;
        //check if preset is used
        //check if preset was selected and compare current subscriptions with preset
        if (this.selectedPreset) {
            let presetIsSameAsSubs = this.selectedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
            //if subscriptions are same, set preset id
            if (presetIsSameAsSubs == true) {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = this.selectedPreset.id;
                });
            }
            else {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        else {
            // if subscriptions are not same, check if there was some preset defined before and if yes,
            //compare subscriptions from this preset (if exists), otherwise set presetId to null
            let presetIds = [...new Set(this.subscriptionsToCreate.map((x) => x.PresetId))];
            if (presetIds.length === 1 && presetIds[0] != null) {
                //Load preset with subscriptions
                let response = null;
                if (LoggedUserManager.isResellerAdmin(this)) {
                    response = this.presets.find((x) => x.id === presetIds[0]);
                }
                else {
                    response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(presetIds[0]);
                }
                //handle error response
                if (typeof response == 'string') {
                    VueUtilities.openErrorToast(this, response);
                    return;
                }
                let loadedPreset = response;
                if (!loadedPreset) {
                    //if preset does not exists
                    this.subscriptionsToCreate.forEach((sub) => {
                        sub.PresetId = null;
                    });
                }
                else {
                    //if preset exists, compare subscriptions
                    let presetIsSameAsSubs = loadedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
                    if (presetIsSameAsSubs == true) {
                        //if subs are same
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = loadedPreset.id;
                        });
                    }
                    else {
                        //if not, set preset id to null
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = null;
                        });
                    }
                }
            }
            else {
                //if there is multiple presets in subscriptions or is null
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        let promises = [];
        //default schedule is already created
        //check if type of measured rule was updated
        if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.COMPANY) {
            let measuredForEntity = this.defaultSchedule?.subscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                [NotificationScopeType.SOURCE, NotificationScopeType.SOURCE_GROUP].includes(x.scopeType)) || [];
            measuredForEntity.forEach((subToDel) => {
                promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.selectedDepartmentId));
            });
        }
        else if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP) {
            let measuredForCompany = this.defaultSchedule?.subscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                x.scopeType === NotificationScopeType.COMPANY) || [];
            measuredForCompany.forEach((subToDel) => {
                promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.selectedDepartmentId));
            });
        }
        //update or create subscription
        let newSubsToCreate = [];
        this.subscriptionsToCreate.forEach((subs) => {
            let idOfExisting = null;
            if (this.availableTypes.find((x) => x.name === subs.NotificationType)?.category ==
                NotificationSubscriptionCategory.MEASUREMENT_RULE) {
                idOfExisting = this.defaultSchedule?.subscriptions?.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                    x.scopeType === subs.ScopeType &&
                    x.scopeId === subs.ScopeId)?.notificationSubscriptionId;
            }
            else {
                idOfExisting = this.defaultSchedule?.subscriptions?.find((x) => x.notificationType === subs.NotificationType)?.notificationSubscriptionId;
            }
            if (idOfExisting) {
                let subsUpdateDto = {
                    Channels: subs.Channels,
                    NotificationType: subs.NotificationType,
                    PresetId: subs.PresetId,
                    Priority: subs.Priority,
                    DisplayMode: subs.DisplayMode,
                    Enabled: subs.Enabled
                };
                promises.push(notificationSubscriptionsRepository.updateNotificationSubscription(idOfExisting, subsUpdateDto, this.currentUser.apiUserId, this.selectedDepartmentId));
            }
            else {
                let newRule = {
                    ScopeType: subs.ScopeType,
                    ScopeId: subs.ScopeId,
                    NotificationType: subs.NotificationType,
                    Enabled: subs.Enabled,
                    Channels: subs.Channels,
                    DisplayMode: subs.DisplayMode,
                    PresetId: subs.PresetId
                };
                newSubsToCreate.push(newRule);
            }
        });
        //Create subs in one call
        if (newSubsToCreate.length > 0) {
            promises.push(notificationSubscriptionsRepository.createNotificationSubscription(newSubsToCreate, this.currentUser.apiUserId, this.selectedDepartmentId, null));
        }
        //check responses
        await Promise.all(promises).then((response) => {
            errors = response.filter((x) => typeof x === 'string');
        });
        if (errors.length <= 0) {
            await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.MODE], this.selectedMeasuredRulesMode, this.selectedDepartmentId);
        }
        if (errors.length <= 0) {
            VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.success'));
            this.loadNotificationRules();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.failure', {
                error: errors.join(', ')
            }));
            this.isLoading = false;
        }
        this.isUnsavedChangesModalActive = false;
    }
    //triggered when some subscriptions is changed
    ruleChanged(newRule) {
        if (newRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            newRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            let indexOfMeasured = this.subscriptionsToCreate.findIndex((x) => (x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                x.ScopeId === newRule.ScopeId);
            if (indexOfMeasured >= 0) {
                //remove this measured rule by index
                this.subscriptionsToCreate.splice(indexOfMeasured, 1);
            }
        }
        else {
            //remove current subscription from list
            this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== newRule.NotificationType);
        }
        this.subscriptionsToCreate.push(newRule);
        this.haveChangesToSave = !NotificationSubscription.compareSubscriptionsWithSubscriptionsCreateDto(this.defaultSchedule?.subscriptions || [], this.subscriptionsToCreate);
    }
    async onEnableTimetable() {
        this.isLoading = true;
        await notificationSubscriptionsRepository.changeTimetableMode(this.currentUser.apiUserId, this.selectedDepartmentId);
        VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.use_timetable_success'));
        this.loadNotificationRules();
    }
    async reloadSchedules() {
        this.isLoading = true;
        this.schedules = await notificationSubscriptionsRepository.getNotificationSchedules(this.currentUser.apiUserId, this.selectedDepartmentId);
        this.notificationDepartmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, true, this.selectedDepartmentId);
        this.timetableSchedules = this.schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        this.isLoading = false;
    }
};
AdminNotificationManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if ((vm.$ability.can(Actions.READ, Subjects.ADMIN_NOTIFICATIONS_SETTINGS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_PRESETS)) ||
                    LoggedUserManager.isResellerAdmin(vm)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        }, beforeRouteLeave(to, from, next) {
            if (this.timetableEnabled === false) {
                if (this.haveChangesToSave === true && to.query.confirmed != 'true') {
                    this.routeToNavigate = to;
                    this.isUnsavedChangesModalActive = true;
                }
                else {
                    next();
                }
            }
            else if (this.timetableEnabled === true) {
                if (this.$refs.timetableComponent.haveChangesToSave === true && to.query.confirmed != 'true') {
                    this.$refs.timetableComponent.routeToNavigateOnExit = to;
                    this.$refs.timetableComponent.isUnsavedChangesModalActive = true;
                }
                else {
                    next();
                }
            }
            else {
                next();
            }
        },
        components: {
            SendNotification,
            NotificationsTable,
            AdminPresetsList,
            MeasuredDataNotification,
            GeneralNotificationComponent,
            AdminNotificationComponent,
            NonWorkingHours,
            Timetable,
            UnsavedChangesDialog,
            EntityMeasuredDataNotification
        }
    })
], AdminNotificationManagement);
export default AdminNotificationManagement;

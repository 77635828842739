import User from '../User';
import { TierFeature } from './TierFeature';
export class Tier {
    constructor() {
        this.tierFeatures = new Array();
        this.enabledFeatures = new Array();
    }
    sortFeaturesByName() {
        this.tierFeatures = this.tierFeatures.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.enabledFeatures = this.enabledFeatures.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Tier.define(et));
        }
        else if (data != null) {
            return Tier.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let tier = new Tier();
        tier.id = data.Id;
        tier.name = data.Name;
        tier.displayName = data.DisplayName;
        tier.order = data.Order;
        tier.active = data.Active;
        tier.createdAt = data.CreatedAt;
        if (data.CreatedBy)
            tier.createdBy = User.fromApi(data.CreatedBy);
        tier.tierFeatures = data.TierFeatures;
        tier.enabledFeatures = TierFeature.filterEnabledFeatures(data.TierFeatures);
        tier.sortFeaturesByName();
        return tier;
    }
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import { SourceGroup } from '@/entities/models/SourceGroup';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import GroupInfo from '@/views/components/group/GroupInfo.vue';
import VueUtilities from '@/services/VueUtilities';
import EventDomain from '@/entities/enums/eventDomains';
import EventType from '@/entities/EventType';
import { Source } from '@/entities/models/Source';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
var groupRepository;
var eventTypeRepository;
var sourceRepository;
let GroupDetail = class GroupDetail extends Vue {
    constructor() {
        super(...arguments);
        this.group = new SourceGroup();
        this.sources = [];
        this.isLoading = true;
        this.hasEditPermissions = false;
        this.eventTypes = [];
        this.isUnsavedChangesModalActive = false;
        this.routeToNavigate = null;
    }
    created() {
        groupRepository = new SourceGroupRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        sourceRepository = new SourceRepository(this);
        if (this.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP) &&
            this.$ability.can(Actions.MANAGE, Subjects.SOURCE) &&
            this.$ability.can(Actions.MANAGESOURCES, Subjects.SOURCE_GROUP)) {
            this.hasEditPermissions = true;
        }
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(this.loadGroupInfo(this.$route.params.groupId));
        allPromises.push(this.loadSources());
        allPromises.push(this.loadEventTypes());
        allPromises.push(this.loadUnits());
        await Promise.all(allPromises).then((response) => {
            this.processData(response[0], response[1], response[2], response[3]);
        });
    }
    processData(group, sources, eventTypes, units) {
        this.group = SourceGroup.assignSourcesToSourceGroup(group, sources.getData());
        this.sources = Source.sortByGroup(sources.getData());
        this.eventTypes = EventType.addUnits(eventTypes, units);
        this.isLoading = false;
    }
    removeGroupConfirm() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.group.detail.msg.dialog_title')}`,
            message: this.$t('component.group.detail.msg.dialog_msg').toString(),
            confirmText: `${this.$t('generals.yes')}`,
            cancelText: `${this.$t('generals.no')}`,
            type: 'is-primary',
            hasIcon: true,
            onConfirm: () => {
                this.removeGroup();
            },
            onCancel: () => { }
        });
    }
    async removeGroup() {
        let response = await groupRepository.removeGroup(this.$route.params.groupId);
        if (response == true) {
            VueUtilities.openSuccessToast(this, this.$t('component.group.detail.msg.removed'));
            this.$router
                .push({
                name: 'configuration_groups',
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.$route.params.departmentId
                }
            })
                .catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.group.detail.msg.remove_failed'));
        }
    }
    async loadGroupInfo(groupId) {
        return groupRepository.getSourceGroup(groupId);
    }
    async loadEventTypes() {
        return eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true);
    }
    async loadUnits() {
        return eventTypeRepository.getSelectedUnits();
    }
    async loadSources() {
        return sourceRepository.getVisibleSources();
    }
    hasChanges() {
        return this.$refs.groupInfoComponent.hasChanges;
    }
    triggerUnsavedChangesModal(reload = false) {
        this.isUnsavedChangesModalActive = !this.isUnsavedChangesModalActive;
        if (reload === true) {
            this.$refs.groupInfoComponent.reloadDefaults();
            VueUtilities.openSuccessToast(this, this.$t('component.group.detail.msg.discarded_changes'));
        }
    }
    saveUnsavedChanges() {
        this.triggerUnsavedChangesModal();
        this.$refs.groupInfoComponent.validate();
    }
};
GroupDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.SOURCE_GROUP)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            if (this.hasChanges() && to.query.confirmed != 'true') {
                this.routeToNavigate = to;
                this.isUnsavedChangesModalActive = true;
            }
            else {
                next();
            }
        },
        components: { GroupInfo, UnsavedChangesDialog }
    })
], GroupDetail);
export default GroupDetail;

export default class SessionStorage {
    static getStorage() {
        return window.sessionStorage;
    }
    static storeData(key, data) {
        this.getStorage().setItem(key, JSON.stringify({
            data: data,
            lastUpdated: Date.now()
        }));
    }
    static loadData(key) {
        let cached = this.getStorage().getItem(key);
        if (cached)
            return JSON.parse(cached);
        return null;
    }
    static clearStorageForSession() {
        this.getStorage().clear();
    }
    static removeDataForCompany() {
        this.getStorage().removeItem(SessionStorage.keys.EVENT_TYPES);
        this.getStorage().removeItem(SessionStorage.keys.FEATURES);
    }
    static loadDataWithType(key, type) {
        let data = this.loadData(key)?.data;
        if (data == null)
            return null;
        if (Array.isArray(data))
            return data.map((e) => this.convert(e, type));
        else
            return this.convert(data, type);
    }
    static convert(data, type) {
        data.__proto__ = type;
        return data;
    }
}
SessionStorage.keys = {
    EVENT_TYPES: 'eventTypes',
    SETTINGS: 'userSettings',
    FEATURES: 'features',
    USER_DEPARTMENT_SETTINGS: 'departmentSettings',
    REFRESH_INTERVAL: 'refreshInterval'
};

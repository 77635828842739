/**
 * Class that defines methods for array of departments.
 */
export class DepartmentArray {
    constructor(data) {
        this._data = data;
    }
    setData(data) {
        this._data = data;
    }
    getData() {
        return this._data;
    }
    /**
     * Gets first department in array
     * @returns Department
     */
    getFirst() {
        if (this._data.length > 0)
            return this._data[0];
        else
            return null;
    }
    groupByOrganization() {
        let organizations = [...new Set(this.getData().map((x) => x.organization))];
        let groupedArray = new Array();
        organizations.forEach((org) => {
            groupedArray.push({
                organization: org,
                departments: this.getData().filter((x) => x.organization == org)
            });
        });
        return groupedArray;
    }
}

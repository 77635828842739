import User from '@/entities/User';
//V1
export class Company {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Company.define(et));
        }
        else if (data != null) {
            return Company.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let company = new Company();
        company.companyId = data.CompanyId;
        company.companyName = data.CompanyName;
        company.publicKey = data.PublicKey;
        company.createdAt = data.CreatedAt;
        company.owner = User.fromApi(data.Owner);
        company.isDeleted = data.IsDeleted;
        return company;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "header",
        { staticClass: "modal-card-head" },
        [
          _c("b-icon", {
            staticClass: "mr-1",
            attrs: { icon: "pause", size: "is-medium" },
          }),
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.company.pauseNotificationsModal.title"
                  )
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.pauseNotificationsModal.selectUser"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "select-location-table border select-user-table",
                  attrs: {
                    data: _vm.users,
                    "checked-rows": _vm.selectedUsers,
                    paginated: "",
                    "current-page": _vm.page,
                    "per-page": _vm.pageSize,
                    total: _vm.totalSize,
                    narrowed: "",
                    checkable: "",
                    "mobile-cards": false,
                    "custom-is-checked": function (a, b) {
                      return a.apiUserId === b.apiUserId
                    },
                  },
                  on: {
                    "update:checkedRows": function ($event) {
                      _vm.selectedUsers = $event
                    },
                    "update:checked-rows": function ($event) {
                      _vm.selectedUsers = $event
                    },
                    "update:currentPage": function ($event) {
                      _vm.page = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.page = $event
                    },
                    "page-change": _vm.onPageChange,
                    check: _vm.selecteUser,
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "email",
                      label: _vm.$t(
                        "admin.component.company.pauseNotificationsModal.table.selectAll"
                      ),
                      searchable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "searchable",
                        fn: function (props) {
                          return [
                            _c(
                              "b-field",
                              { staticClass: "is-hidden-mobile" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    size: "is-small",
                                    expanded: "",
                                  },
                                  model: {
                                    value: props.filters[props.column.field],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        props.filters,
                                        props.column.field,
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "props.filters[props.column.field]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("span", [
                              _c(
                                "div",
                                { staticClass: "field flex-space-between" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(props.row.fullName) +
                                        " ( " +
                                        _vm._s(props.row.email) +
                                        ")"
                                    ),
                                  ]),
                                  _vm.selectedUsers.find(function (data) {
                                    return data.apiUserId == props.row.apiUserId
                                  })
                                    ? _c(
                                        "b-field",
                                        [
                                          _c(
                                            "b-select",
                                            {
                                              attrs: {
                                                size: "is-small",
                                                placeholder: _vm.$t(
                                                  "admin.component.company.pauseNotificationsModal.table.pauseValue"
                                                ),
                                                name: _vm.$t(
                                                  "admin.component.company.pauseNotificationsModal.table.pauseValue"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.pauseValues[
                                                    props.row.apiUserId
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pauseValues,
                                                    props.row.apiUserId,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pauseValues[props.row.apiUserId]",
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  key: _vm
                                                    .PauseNotificationsInterval
                                                    .FIFTEEN_MIN,
                                                  domProps: {
                                                    value:
                                                      _vm
                                                        .PauseNotificationsInterval
                                                        .FIFTEEN_MIN,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .PauseNotificationsInterval
                                                          .FIFTEEN_MIN
                                                      ) +
                                                      " Mins "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                {
                                                  key: _vm
                                                    .PauseNotificationsInterval
                                                    .THIRTY_MIN,
                                                  domProps: {
                                                    value:
                                                      _vm
                                                        .PauseNotificationsInterval
                                                        .THIRTY_MIN,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .PauseNotificationsInterval
                                                          .THIRTY_MIN
                                                      ) +
                                                      " Mins "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                {
                                                  key: _vm
                                                    .PauseNotificationsInterval
                                                    .ONE_HOUR,
                                                  domProps: {
                                                    value:
                                                      _vm
                                                        .PauseNotificationsInterval
                                                        .ONE_HOUR,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .PauseNotificationsInterval
                                                          .ONE_HOUR
                                                      ) +
                                                      " Mins "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "admin.component.company.pauseNotificationsModal.submit"
                  )
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
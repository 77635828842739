var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkbox-select" },
    [
      _c(
        "b-dropdown",
        {
          attrs: { multiple: "", "aria-role": "list", expanded: "" },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "is-justify-content-space-between",
                      attrs: { "icon-right": "angle-down", expanded: "" },
                    },
                    [
                      _vm.selectedOptions.length == 0
                        ? _c("span", [_vm._v("-")])
                        : _vm._l(_vm.selectedOptions, function (option, index) {
                            return _c(
                              "span",
                              { key: index },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.langPath +
                                          _vm.getValue(_vm.path, option)
                                      )
                                    ) +
                                    " "
                                ),
                                index !== _vm.selectedOptions.length - 1
                                  ? [_vm._v(", ")]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.selectedOptions,
            callback: function ($$v) {
              _vm.selectedOptions = $$v
            },
            expression: "selectedOptions",
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "b-dropdown-item",
            { key: index, attrs: { custom: "" } },
            [
              _c(
                "b-checkbox",
                {
                  attrs: { "native-value": option },
                  on: {
                    input: function ($event) {
                      return _vm.entitySelected()
                    },
                  },
                  model: {
                    value: _vm.localSelectedOptions,
                    callback: function ($$v) {
                      _vm.localSelectedOptions = $$v
                    },
                    expression: "localSelectedOptions",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.langPath + _vm.getValue(_vm.path, option))
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportRecord } from '@/entities/models/ReportRecord';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import VueUtilities from '@/services/VueUtilities';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var reportRecordRepository;
const MS_IN_DAY = 86400;
let CreateAnnotationModal = class CreateAnnotationModal extends Vue {
    constructor() {
        super(...arguments);
        this.PredefinedText = PredefinedText;
        this.predefined = null;
        this.annotationText = null;
        this.annotationRange = null;
        this.diffIsMoreThanOneDay = false;
        this.isLoadingOverlay = false;
    }
    mounted() {
        reportRecordRepository = new ReportRecordRepository(this);
        this.annotationRange = this.createTimeRange();
    }
    createTimeRange() {
        const first = Math.min.apply(Math, this.selectedRecordsToAnnotate.map((o) => o.time));
        const last = Math.max.apply(Math, this.selectedRecordsToAnnotate.map((o) => o.time));
        let sortedRecords = this.selectedRecordsToAnnotate.sort(ReportRecord.sortRecordsSelectedInTable);
        for (let i = 0; i < sortedRecords.length; i++) {
            if (sortedRecords[i + 1] && sortedRecords[i + 1].datepoint - sortedRecords[i].datepoint > MS_IN_DAY) {
                this.diffIsMoreThanOneDay = true;
                break;
            }
        }
        const firstDate = new Date(first);
        const lastDate = new Date(last);
        const inSameDay = this.dateTimeManager.areSameDate(firstDate, lastDate);
        if (first === last) {
            return `${this.dateTimeManager.formatTime(firstDate, null, null, null)}`;
        }
        else {
            return `${this.dateTimeManager.formatTime(firstDate, null, null, null)} - ${this.dateTimeManager.formatTime(lastDate, null, inSameDay === false ? null : '', null)}`;
        }
    }
    validateBeforeSubmit() {
        if (this.predefined == null) {
            VueUtilities.openErrorToast(this, this.$t('component.report.create_annotation.error'));
            return;
        }
        else if (this.predefined === PredefinedText.OWN && this.annotationText == null) {
            VueUtilities.openErrorToast(this, this.$t('component.report.create_annotation.error_text'));
            return;
        }
        else {
            this.isLoadingOverlay = true;
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    const first = Math.min.apply(Math, this.selectedRecordsToAnnotate.map((o) => o.datepoint));
                    const last = Math.max.apply(Math, this.selectedRecordsToAnnotate.map((o) => o.datepoint));
                    let annotationDto = {
                        SourceId: this.selectedRecordsToAnnotate[0].record.sourceId,
                        Text: this.predefined === PredefinedText.OWN
                            ? this.annotationText
                            : this.$t(`component.report.create_annotation.predefined.${this.predefined}`),
                        StartDate: Math.floor(first),
                        EndDate: Math.floor(last + 1)
                    };
                    let annotation = await reportRecordRepository.createAnnotation(annotationDto);
                    if (annotation === ApiResponseCodes.FEATURE_DISABLED) {
                        this.resultRetrieved(false);
                    }
                    else {
                        VueUtilities.openSuccessToast(this, this.$t('component.report.create_annotation.success').toString());
                        this.resultRetrieved(true);
                    }
                    this.isLoadingOverlay = false;
                }
                else {
                }
            });
        }
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Array })
], CreateAnnotationModal.prototype, "selectedRecordsToAnnotate", void 0);
__decorate([
    Prop({ type: Object })
], CreateAnnotationModal.prototype, "dateTimeManager", void 0);
CreateAnnotationModal = __decorate([
    Component({})
], CreateAnnotationModal);
export default CreateAnnotationModal;
var PredefinedText;
(function (PredefinedText) {
    PredefinedText["SANITATION"] = "sanitation";
    PredefinedText["DEFROSTING"] = "defrosting";
    PredefinedText["MAINTENANCE"] = "maintenance";
    PredefinedText["MALFUNCTION"] = "malfunction";
    PredefinedText["BLACKOUT"] = "blackout";
    PredefinedText["TAKEN_OUT"] = "taken_out";
    PredefinedText["STOCKING"] = "stocking";
    PredefinedText["POSITION"] = "position";
    PredefinedText["OWN"] = "own";
})(PredefinedText || (PredefinedText = {}));

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AdminDeviceSelectComponent from '@/views/components/admin/devices/AdminDeviceSelectComponent.vue';
import AdminRegisteredLoggersComponent from '@/views/components/admin/loggers/AdminRegisteredLoggersComponent.vue';
let AdminRegisterLogger = class AdminRegisterLogger extends Vue {
    constructor() {
        super(...arguments);
        this.connectedDevice = null;
    }
    created() { }
    connectToDevice(device) {
        this.connectedDevice = device;
        this.$emit('connectToDevice', device);
    }
    deleteLogger(loggerId) {
        this.$emit('deleteLogger', loggerId);
    }
};
__decorate([
    Prop({ type: Array })
], AdminRegisterLogger.prototype, "deviceList", void 0);
__decorate([
    Prop({ type: Array })
], AdminRegisterLogger.prototype, "loggerList", void 0);
__decorate([
    Prop({ type: Object })
], AdminRegisterLogger.prototype, "dateTimeManager", void 0);
AdminRegisterLogger = __decorate([
    Component({
        components: { AdminDeviceSelectComponent, AdminRegisteredLoggersComponent }
    })
], AdminRegisterLogger);
export default AdminRegisterLogger;

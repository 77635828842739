var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.userAdministration.list.title")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _vm.$featuresManager.isAvailable(
                _vm.$features.USERS_IN_COMPANY
              ) &&
              _vm.$ability.can(
                _vm.$permActions.REGISTER,
                _vm.$permSubjects.USER
              )
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      attrs: { type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.inviteUser()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.userAdministration.list.inviteUser_btn"
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "is-dark-grey",
                                      staticStyle: { "font-size": "1rem" },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field ==
                                  _vm.tableColumns.fullname.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.userAdministration.list.table." +
                                        column.field
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          attrs: {
            data: _vm.users,
            paginated: "",
            "backend-pagination": "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.total,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            striped: "",
            "page-input": true,
            "pagination-order": "is-centered",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.users.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.total,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.fullname.field,
              label: _vm.$t(
                "component.userAdministration.list.table." +
                  _vm.tableColumns.fullname.field
              ),
              visible: _vm.tableColumns.fullname.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "userDetail",
                            params: {
                              userId: users.row.apiUserId,
                              departmentId: _vm.$route.params.departmentId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(users.row.fullName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.username.field,
              label: _vm.$t(
                "component.userAdministration.list.table." +
                  _vm.tableColumns.username.field
              ),
              visible: _vm.tableColumns.username.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "userDetail",
                            params: {
                              userId: users.row.apiUserId,
                              departmentId: _vm.$route.params.departmentId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(users.row.username))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "role.Name",
              label: _vm.$t(
                "component.userAdministration.list.table." +
                  _vm.tableColumns.role.field
              ),
              visible: _vm.tableColumns.role.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _vm._v(_vm._s(_vm.$t("users.roles." + users.row.role.Key))),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.email.field,
              label: _vm.$t(
                "component.userAdministration.list.table." +
                  _vm.tableColumns.email.field
              ),
              visible: _vm.tableColumns.email.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "userDetail",
                            params: {
                              userId: users.row.apiUserId,
                              departmentId: _vm.$route.params.departmentId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(users.row.email))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.enabled.field,
              label: _vm.$t(
                "component.userAdministration.list.table." +
                  _vm.tableColumns.enabled.field
              ),
              visible: _vm.tableColumns.enabled.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _c("b-icon", {
                      attrs: { icon: users.row.enabled ? "check" : "times" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "action",
              label: _vm.$t("component.userAdministration.list.table.action"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    users.row.role.Key !== _vm.COMPANY_ADMIN_KEY &&
                    users.row.email !== _vm.username
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              position: "is-bottom-left",
                              "append-to-body": "",
                              "aria-role": "list",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function () {
                                    return [
                                      _c("b-icon", {
                                        staticClass: "clickable",
                                        attrs: { icon: "ellipsis-h" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            users.row.enabled
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "custom-dropdown-item",
                                    attrs: { "aria-role": "listitem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.disableUserDialog(users.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                    _c("span", { staticClass: "pl-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.userAdministration.list.table.action_disable"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "custom-dropdown-item",
                                    attrs: { "aria-role": "listitem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.enableUser(users.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "check" } }),
                                    _c("span", { staticClass: "pl-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.userAdministration.list.table.action_enable"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                            users.row.role.Key !==
                              _vm.ApiUserRoles.COMPANY_OWNER &&
                            users.row.role.Key !== _vm.ApiUserRoles.SYSTEM_ADMIN
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "custom-dropdown-item",
                                    attrs: { "aria-role": "listitem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openChangeRoleModal(
                                          users.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "user" } }),
                                    _c("span", { staticClass: "pl-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.userAdministration.list.table.action_change_role"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "custom-dropdown-item",
                                attrs: { "aria-role": "listitem" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeUserFromDepartment(
                                      users.row
                                    )
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "trash" } }),
                                _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.userAdministration.list.table.action_delete"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.userAdministration.list.table.empty")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isChangeRoleModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isChangeRoleModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("ChangeUserRoleModal", {
            attrs: { user: _vm.selectedUser },
            on: { modalClosed: _vm.closeModal, changeRole: _vm.changeRole },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var EventTypesDefault;
(function (EventTypesDefault) {
    EventTypesDefault["TEMPERATURE_LOWER"] = "15,00";
    EventTypesDefault["TEMPERATURE_UPPER"] = "25,00";
    EventTypesDefault["HUMIDITY_LOWER"] = "0,00";
    EventTypesDefault["HUMIDITY_UPPER"] = "70,00";
    EventTypesDefault["DEFAULT_LOWER"] = "0,00";
    EventTypesDefault["DEFAULT_UPPER"] = "1,00";
})(EventTypesDefault || (EventTypesDefault = {}));
export default EventTypesDefault;
export function assignDefaultBoundariesValues(boundary, typeSlug) {
    switch (typeSlug) {
        case 'temperature':
            boundary.lowerAlarmBoundary = EventTypesDefault.TEMPERATURE_LOWER;
            boundary.upperAlarmBoundary = EventTypesDefault.TEMPERATURE_UPPER;
            boundary.lowerWarningBoundary = null;
            boundary.upperWarningBoundary = null;
            break;
        case 'humidity':
            boundary.lowerAlarmBoundary = EventTypesDefault.HUMIDITY_LOWER;
            boundary.upperAlarmBoundary = EventTypesDefault.HUMIDITY_UPPER;
            boundary.lowerWarningBoundary = null;
            boundary.upperWarningBoundary = null;
            break;
        default:
            boundary.lowerAlarmBoundary = EventTypesDefault.DEFAULT_LOWER;
            boundary.upperAlarmBoundary = EventTypesDefault.DEFAULT_UPPER;
            boundary.lowerWarningBoundary = null;
            boundary.upperWarningBoundary = null;
            break;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-table",
        { attrs: { data: _vm.newBoundaries } },
        [
          _c("b-table-column", {
            attrs: { label: _vm.$t("component.boundaries.service") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (boundaries) {
                  return [
                    _c(
                      "div",
                      { staticClass: "select-boundary" },
                      [
                        boundaries.row.measuredDataTypeSlug == null
                          ? _c(
                              "b-field",
                              {
                                attrs: {
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t("component.boundaries.service")
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t("component.boundaries.service")
                                  ),
                                },
                              },
                              [
                                _c(
                                  "b-select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "component.boundaries.select_type"
                                      ),
                                      name: _vm.$t(
                                        "component.boundaries.service"
                                      ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.typeSelected(
                                          boundaries.row,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.notBoundedEventTypes,
                                    function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option.eventTypeId,
                                          domProps: { value: option.slug },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "services." +
                                                    option.name.toLowerCase()
                                                ) +
                                                  " (" +
                                                  _vm.eventTypes.find(function (
                                                    x
                                                  ) {
                                                    return x.slug == option.slug
                                                  }).unit.unit +
                                                  ")"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "services." +
                                      boundaries.row.measuredDataTypeSlug.toLowerCase()
                                  ) +
                                    " (" +
                                    _vm.eventTypes.find(function (x) {
                                      return (
                                        x.slug ==
                                        boundaries.row.measuredDataTypeSlug
                                      )
                                    }).unit.unit +
                                    ")"
                                )
                              ),
                            ]),
                        boundaries.row.isNew == true
                          ? _c("button", {
                              staticClass:
                                "remove-boundary delete is-vcentered",
                              on: {
                                click: function ($event) {
                                  return _vm.remove(boundaries.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "LowerAlarmBoundary",
              label: _vm.$t("component.boundaries.lower_alarm"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (boundaries) {
                  return [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t("component.boundaries.lower_alarm") +
                                " (" +
                                _vm.$t(
                                  "services." +
                                    boundaries.row.measuredDataTypeSlug
                                ) +
                                ")"
                            ),
                          },
                          message: _vm.errors.has(
                            _vm.$t("component.boundaries.lower_alarm") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")"
                          )
                            ? _vm.$t("validation.lowerBoundary")
                            : "",
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true, decimal: [2, ","] },
                              expression:
                                "{ required: true, decimal: [2, ','] }",
                            },
                          ],
                          attrs: {
                            name:
                              _vm.$t("component.boundaries.lower_alarm") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")",
                          },
                          model: {
                            value: boundaries.row.lowerAlarmBoundary,
                            callback: function ($$v) {
                              _vm.$set(
                                boundaries.row,
                                "lowerAlarmBoundary",
                                $$v
                              )
                            },
                            expression: "boundaries.row.lowerAlarmBoundary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "LowerWarningBoundary",
              label: _vm.$t("component.boundaries.lower_warning"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (boundaries) {
                  return [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t("component.boundaries.lower_warning") +
                                " (" +
                                _vm.$t(
                                  "services." +
                                    boundaries.row.measuredDataTypeSlug
                                ) +
                                ")"
                            ),
                          },
                          message: _vm.errors.has(
                            _vm.$t("component.boundaries.lower_warning") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")"
                          )
                            ? _vm.$t("validation.lowerBoundary")
                            : "",
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { decimal: [2, ","] },
                              expression: "{ decimal: [2, ','] }",
                            },
                          ],
                          attrs: {
                            name:
                              _vm.$t("component.boundaries.lower_warning") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")",
                          },
                          model: {
                            value: boundaries.row.lowerWarningBoundary,
                            callback: function ($$v) {
                              _vm.$set(
                                boundaries.row,
                                "lowerWarningBoundary",
                                $$v
                              )
                            },
                            expression: "boundaries.row.lowerWarningBoundary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "UpperWarningBoundary",
              label: _vm.$t("component.boundaries.upper_warning"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (boundaries) {
                  return [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t("component.boundaries.upper_warning") +
                                " (" +
                                _vm.$t(
                                  "services." +
                                    boundaries.row.measuredDataTypeSlug
                                ) +
                                ")"
                            ),
                          },
                          message: _vm.errors.has(
                            _vm.$t("component.boundaries.upper_warning") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")"
                          )
                            ? _vm.$t("validation.upperBoundary")
                            : "",
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { decimal: [2, ","] },
                              expression: "{ decimal: [2, ','] }",
                            },
                          ],
                          attrs: {
                            name:
                              _vm.$t("component.boundaries.upper_warning") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")",
                          },
                          model: {
                            value: boundaries.row.upperWarningBoundary,
                            callback: function ($$v) {
                              _vm.$set(
                                boundaries.row,
                                "upperWarningBoundary",
                                $$v
                              )
                            },
                            expression: "boundaries.row.upperWarningBoundary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "UpperAlarmBoundary",
              label: _vm.$t("component.boundaries.upper_alarm"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (boundaries) {
                  return [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t("component.boundaries.upper_alarm") +
                                " (" +
                                _vm.$t(
                                  "services." +
                                    boundaries.row.measuredDataTypeSlug
                                ) +
                                ")"
                            ),
                          },
                          message: _vm.errors.has(
                            _vm.$t("component.boundaries.upper_alarm") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")"
                          )
                            ? _vm.$t("validation.upperBoundary")
                            : "",
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true, decimal: [2, ","] },
                              expression:
                                "{ required: true, decimal: [2, ','] }",
                            },
                          ],
                          attrs: {
                            name:
                              _vm.$t("component.boundaries.upper_alarm") +
                              " (" +
                              _vm.$t(
                                "services." +
                                  boundaries.row.measuredDataTypeSlug
                              ) +
                              ")",
                          },
                          model: {
                            value: boundaries.row.upperAlarmBoundary,
                            callback: function ($$v) {
                              _vm.$set(
                                boundaries.row,
                                "upperAlarmBoundary",
                                $$v
                              )
                            },
                            expression: "boundaries.row.upperAlarmBoundary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "RemoveButton",
              label: _vm.$t("component.boundaries.action"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (boundaries) {
                  return [
                    boundaries.row.isNew == false
                      ? _c(
                          "b-button",
                          {
                            staticClass: "is-vcentered is-primary",
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.remove(boundaries.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("generals.remove")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c(
              "div",
              { staticClass: "content has-text-grey has-text-centered" },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("component.boundaries.empty"))),
                ]),
              ]
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { class: _vm.newBoundaries.length == 0 && "has-text-centered" },
        [
          _vm.newBoundaries.length < _vm.eventTypesWithoutUptime.length
            ? _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary",
                    outlined: "",
                    disabled: !_vm.addNewAllowed,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addNewBoundary()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("component.boundaries.add")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
const MORE_BTN_ID = 'ignoreClickOutside';
let NotificationItem = class NotificationItem extends Vue {
    constructor() {
        super(...arguments);
        this.name = 'NotificationItem';
        this.MORE_BTN_ID = MORE_BTN_ID;
    }
    created() { }
    notificationRemoved() {
        this.$emit('notificationRemoved', [this.notificationItem.notificationId]);
    }
};
__decorate([
    Prop({ type: Object })
], NotificationItem.prototype, "notificationItem", void 0);
__decorate([
    Prop({ type: Object })
], NotificationItem.prototype, "dateTimeManager", void 0);
NotificationItem = __decorate([
    Component({})
], NotificationItem);
export default NotificationItem;

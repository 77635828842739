import { render, staticRenderFns } from "./DeviceManagement.vue?vue&type=template&id=9c1b7244&"
import script from "./DeviceManagement.vue?vue&type=script&lang=ts&"
export * from "./DeviceManagement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c1b7244')) {
      api.createRecord('9c1b7244', component.options)
    } else {
      api.reload('9c1b7244', component.options)
    }
    module.hot.accept("./DeviceManagement.vue?vue&type=template&id=9c1b7244&", function () {
      api.rerender('9c1b7244', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/DeviceManagement.vue"
export default component.exports
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("canvas", {
      attrs: { id: _vm.canvasId, width: "2px", height: "2px" },
      on: {
        mousemove: function ($event) {
          return _vm.displayName($event)
        },
        click: function ($event) {
          return _vm.redirect($event)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiStatus from '@/entities/enums/apiStatuses';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class BoundaryRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async createBoundary(boundaries, sourceId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            throw 'Forbidden action createBoundary';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Boundaries}`;
        let response = await AxiosService.postDataToServer(url, boundaries, this.ctx);
        if (response.status != ApiStatus.CREATED)
            throw this.ctx.$t('error_messages.fatal_error');
        else
            return response;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          staticClass: "locations-table",
          attrs: {
            data: _vm.arrayForTable,
            "mobile-cards": false,
            "row-class": function (row, index) {
              return _vm.isSourceGroup(row.entity)
                ? "is-source-group"
                : row.lastSourceInGroup
                ? "td-with-bottom-padding"
                : ""
            },
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "name",
              label: _vm.$t(
                "component.wizard.wizardBoundaries.withBoundaries.table.name"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (element) {
                  return [
                    _c(
                      "div",
                      { staticClass: "is-flex is-align-items-flex-start" },
                      [
                        !element.row.isEditNameActive
                          ? _c("div", [
                              _c("p", { staticClass: "pr-3" }, [
                                _vm.isSourceGroup(element.row.entity)
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          element.row.entity.name
                                            ? _vm.$t("generals.group") +
                                                " " +
                                                element.row.entity.name
                                            : _vm.$t(
                                                "component.source_group_picker.default_group_name"
                                              )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(element.row.entity.name)
                                      ),
                                    ]),
                              ]),
                            ])
                          : _c(
                              "div",
                              [
                                _vm.isSourceGroup(element.row.entity)
                                  ? _c(
                                      "b-field",
                                      {
                                        staticClass: "pr-3",
                                        attrs: {
                                          type: {
                                            "is-danger":
                                              _vm.errors.has(
                                                _vm.$t(
                                                  "component.group.create.name"
                                                )
                                              ) || _vm.nameExists,
                                          },
                                          message: _vm.nameExists
                                            ? _vm.$t(
                                                "component.group.create.msg.unique_name"
                                              )
                                            : _vm.errors.first(
                                                _vm.$t(
                                                  "component.group.create.name"
                                                )
                                              ),
                                          expanded: "",
                                        },
                                      },
                                      [
                                        _c("b-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: _vm.$t(
                                              "component.group.detail.table.name"
                                            ),
                                            expanded: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.checkIfGroupNameExists(
                                                element.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: element.row.newName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                element.row,
                                                "newName",
                                                $$v
                                              )
                                            },
                                            expression: "element.row.newName",
                                          },
                                        }),
                                        _c(
                                          "p",
                                          { staticClass: "control" },
                                          [
                                            _c("b-button", {
                                              attrs: {
                                                disabled: !element.row.newName,
                                                type: "is-primary",
                                                label: _vm.$t("generals.save"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveGroupName(
                                                    element.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "b-field",
                                      {
                                        staticClass: "pr-3",
                                        attrs: {
                                          type: {
                                            "is-danger":
                                              _vm.errors.has(
                                                _vm.$t(
                                                  "component.group.create.name"
                                                )
                                              ) || _vm.nameExists,
                                          },
                                          message: _vm.nameExists
                                            ? _vm.$t(
                                                "component.location.create.msg.unique_name"
                                              )
                                            : _vm.errors.first(
                                                _vm.$t(
                                                  "component.location.create.name"
                                                )
                                              ),
                                          expanded: "",
                                        },
                                      },
                                      [
                                        _c("b-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: _vm.$t(
                                              "component.location.detail.table.name"
                                            ),
                                            expanded: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.checkIfSourceNameExists(
                                                element.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: element.row.newName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                element.row,
                                                "newName",
                                                $$v
                                              )
                                            },
                                            expression: "element.row.newName",
                                          },
                                        }),
                                        _c(
                                          "p",
                                          { staticClass: "control" },
                                          [
                                            _c("b-button", {
                                              attrs: {
                                                disabled: !element.row.newName,
                                                type: "is-primary",
                                                label: _vm.$t("generals.save"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveSourceName(
                                                    element.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                        element.row.entity.name
                          ? _c("b-icon", {
                              staticClass: "clickable",
                              attrs: { icon: "pencil-alt", size: "is-small" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.editName(element.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "loggerSN",
              label: _vm.$t(
                "component.wizard.wizardBoundaries.withBoundaries.table.serialNumber"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (element) {
                  return [
                    !_vm.isSourceGroup(element.row.entity)
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                element.row.entity.logger != null
                                  ? element.row.entity.logger.serialNumber
                                  : ""
                              ) +
                              " "
                          ),
                        ])
                      : _c("p"),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "sourceGroup.name",
              label: _vm.$t(
                "component.wizard.wizardBoundaries.withBoundaries.table.group"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (element) {
                  return [
                    !_vm.isSourceGroup(element.row.entity)
                      ? _c(
                          "b-field",
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "component.location.detail.table.select_group"
                                  ),
                                  expanded: "",
                                  name: _vm.$t(
                                    "component.location.detail.table.group"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.groupSelected(element.row)
                                  },
                                },
                                model: {
                                  value: element.row.selectedGroup,
                                  callback: function ($$v) {
                                    _vm.$set(element.row, "selectedGroup", $$v)
                                  },
                                  expression: "element.row.selectedGroup",
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    staticClass:
                                      "is-italic has-background-light",
                                    domProps: { value: "null" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.wizard.wizardBoundaries.withBoundaries.table.create_group"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.sourceGroupsForSelect,
                                  function (group) {
                                    return _c(
                                      "option",
                                      {
                                        key: group.id,
                                        domProps: { value: group },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              group.name ||
                                                _vm.$t("generals.unassigned")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _c("p"),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "boundaries",
              label: _vm.$t(
                "component.wizard.wizardBoundaries.withBoundaries.table.boundaries"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (element) {
                  return [
                    _vm.isSourceGroup(element.row.entity) &&
                    element.row.entity.name
                      ? [
                          _vm.boudariesForGroup(element.row.entity).length > 0
                            ? _c("BoundaryInfo", {
                                attrs: {
                                  boundaries: _vm.boudariesForGroup(
                                    element.row.entity
                                  ),
                                  editOption: true,
                                },
                                on: {
                                  editBoundaries: function ($event) {
                                    return _vm.editBoundaries(
                                      element.row.entity
                                    )
                                  },
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "is-flex is-align-items-center is-justify-content-space-between",
                                },
                                [
                                  _c("div"),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "is-clickable has-text-weight-bold has-text-primary has-text-centered",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editBoundaries(
                                                element.row.entity
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "pr-2",
                                            attrs: {
                                              icon: "exclamation-triangle",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "component.wizard.wizardBoundaries.withBoundaries.table.without_boundaries"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c("b-icon", {
                                        staticClass: "clickable pl-3",
                                        attrs: {
                                          icon: "pencil-alt",
                                          size: "is-small",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editBoundaries(
                                              element.row.entity
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div"),
                                ]
                              ),
                        ]
                      : !_vm.isSourceGroup(element.row.entity) &&
                        !_vm.isSourceInGroup(element.row.entity)
                      ? [
                          element.row.entity.boundaries.length > 0
                            ? _c("BoundaryInfo", {
                                attrs: {
                                  boundaries: element.row.entity.boundaries,
                                  editOption: true,
                                },
                                on: {
                                  editBoundaries: function ($event) {
                                    return _vm.editBoundaries(
                                      element.row.entity
                                    )
                                  },
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "is-flex is-align-items-center is-justify-content-space-between",
                                },
                                [
                                  _c("div"),
                                  _c("div", { staticClass: "is-flex" }, [
                                    _c("div", { staticClass: "pr-3" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "is-clickable has-text-weight-bold has-text-primary has-text-centered",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editBoundaries(
                                                element.row.entity
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "pr-2",
                                            attrs: {
                                              icon: "exclamation-triangle",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "component.wizard.wizardBoundaries.withBoundaries.table.without_boundaries"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("b-icon", {
                                          staticClass: "clickable",
                                          attrs: {
                                            icon: "pencil-alt",
                                            size: "is-small",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.editBoundaries(
                                                element.row.entity
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div"),
                                ]
                              ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.wizard.wizardBoundaries.withBoundaries.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE_GROUP)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isCreateGroupModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeGroupModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isCreateGroupModalActive = $event
                },
                "close-modal": _vm.closeGroupModal,
              },
            },
            [
              _c("CreateGroupModal", {
                attrs: { loggerToAssign: _vm.loggerToAssign },
                on: { modalClosed: _vm.closeGroupModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isBoundaryModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeBoundaryModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isBoundaryModalActive = $event
                },
                "close-modal": _vm.closeBoundaryModal,
              },
            },
            [
              _c("BoundaryModal", {
                attrs: {
                  boundaries: _vm.getCopyOfBoundaries(),
                  eventTypes: _vm.eventTypes,
                },
                on: {
                  modalClosed: _vm.closeBoundaryModal,
                  saveBoundaries: _vm.saveBoundariesCommand,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
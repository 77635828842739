var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c(
      "header",
      { staticClass: "modal-card-head" },
      [
        _c("b-icon", {
          staticClass: "mr-2",
          attrs: { icon: "user-plus", size: "is-medium" },
        }),
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.company.addCompanyUsersModal.title")
              ) +
              " "
          ),
        ]),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(
                "admin.component.company.createCompanyModal.addUsers"
              ),
            },
          },
          [
            _c(
              "b-table",
              {
                staticClass: "select-location-table border select-user-table",
                attrs: {
                  data: _vm.availableUsers,
                  "checked-rows": _vm.selectedUsers,
                  paginated: true,
                  "backend-pagination": "",
                  "current-page": _vm.page,
                  "per-page": _vm.pageSize,
                  total: _vm.totalSize,
                  "backend-filtering": "",
                  narrowed: "",
                  checkable: "",
                  "mobile-cards": false,
                  "row-class": function (row) {
                    return _vm.members.filter(function (x) {
                      return x.email === row.email
                    }).length > 0
                      ? "is-disabled has-text-grey"
                      : ""
                  },
                  "is-row-checkable": function (row) {
                    return (
                      _vm.members.filter(function (x) {
                        return x.email === row.email
                      }).length === 0
                    )
                  },
                  "custom-is-checked": function (a, b) {
                    return a.id === b.id
                  },
                },
                on: {
                  "update:checkedRows": function ($event) {
                    _vm.selectedUsers = $event
                  },
                  "update:checked-rows": function ($event) {
                    _vm.selectedUsers = $event
                  },
                  "update:currentPage": function ($event) {
                    _vm.page = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.page = $event
                  },
                  "page-change": _vm.onPageChange,
                  select: _vm.selectedUser,
                },
              },
              [
                _c("b-table-column", {
                  attrs: {
                    field: "email",
                    label: _vm.$t(
                      "admin.component.company.createCompanyModal.form.selectAll"
                    ),
                    searchable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "searchable",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "buttons" },
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("generals.search"),
                                  size: "is-small",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.filterUsers(_vm.emailSearch)
                                  },
                                },
                                model: {
                                  value: _vm.emailSearch,
                                  callback: function ($$v) {
                                    _vm.emailSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "emailSearch",
                                },
                              }),
                              _c("b-icon", {
                                staticClass: "level-item clickable",
                                attrs: { icon: "search" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.filterUsers(_vm.emailSearch)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (users) {
                        return [
                          _c("span", [
                            _c(
                              "div",
                              {
                                staticClass: "field flex-space-between",
                                class: !_vm.members.find(function (x) {
                                  return x.apiUserId === users.row.id
                                })
                                  ? "is-clickable"
                                  : "",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(users.row.fullName) +
                                      " ( " +
                                      _vm._s(users.row.email) +
                                      ")"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(
                "admin.component.company.createCompanyModal.list.selectedUsers"
              ),
            },
          },
          [
            _c(
              "b-table",
              {
                staticClass:
                  "select-location-table border select-user-table-list",
                attrs: { data: _vm.selectedUsers, striped: "" },
              },
              [
                _c("b-table-column", {
                  attrs: {
                    field: "name",
                    label: _vm.$t(
                      "admin.component.company.createCompanyModal.list.userName"
                    ),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (assignUsers) {
                        return [
                          _c("span", [
                            _c(
                              "div",
                              { staticClass: "field flex-space-between" },
                              [
                                _vm._v(
                                  _vm._s(assignUsers.row.fullName) +
                                    " (" +
                                    _vm._s(assignUsers.row.email) +
                                    ")"
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("template", { slot: "empty" }, [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.createCompanyModal.list.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.addResellerUsers()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t("admin.component.company.addCompanyUsersModal.confirm")
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
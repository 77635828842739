var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb",
              attrs: { "aria-label": "breadcrumb my-1" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _vm._m(0),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                      ]
                    ),
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminCompanies",
                              params: {
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "home" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.home")))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminFeedback",
                              params: {
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("contact_form.feedback")))]
                      ),
                    ],
                    1
                  ),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(_vm._s(_vm.feedback.id)),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        _c(
          "div",
          { staticClass: "tile is-parent" },
          [
            !_vm.isLoading
              ? _c("FeedbackDetail", {
                  staticClass: "tile is-child box",
                  attrs: {
                    feedback: _vm.feedback,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { closeFeedback: _vm.closeFeedback },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
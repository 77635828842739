import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import Device from '@/entities/Device';
import DeviceInfo from '@/views/components/device/DeviceInfo.vue';
import DateTimeManager from '@/services/DateTimeManager';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import TiersRepository from '@/services/repository/TiersRepository';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import VueUtilities from '@/services/VueUtilities';
var deviceRepository;
var departmentRepository;
var tiersRepository;
let DeviceSettings = class DeviceSettings extends Vue {
    constructor() {
        super(...arguments);
        this.device = new Device();
        this.dateTimeManager = null;
        this.isLoading = true;
        this.department = null;
        this.tier = null;
        this.buzzerIsAllowed = false;
        this.deviceIsBuzzer = false;
        this.isEditingSettings = false;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        deviceRepository = new DeviceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        tiersRepository = new TiersRepository(this);
        this.loadDeviceInfo(this.$route.params.deviceId);
    }
    async loadDeviceInfo(deviceId) {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(deviceRepository.getDevice(deviceId));
        allPromises.push(departmentRepository.getDepartment());
        allPromises.push(tiersRepository.getActiveTier());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2]);
        });
    }
    processLoadedDataForPage(device, department, tier) {
        this.device = device;
        this.department = department;
        this.tier = tier;
        //check if buzzer feature is allowed in current department
        this.buzzerIsAllowed =
            this.tier.features.find((x) => x.Name === 'Observer.BuzzerNotifications')?.Value?.Allowed === true;
        //check if current device is set as buzzer in department settings
        this.deviceIsBuzzer = this.department.settings.Notifications.Buzzer.DeviceId === this.device.deviceId;
        this.isLoading = false;
    }
    editSettings() {
        this.isEditingSettings = !this.isEditingSettings;
    }
    async saveChanges() {
        this.isLoading = true;
        let selectedValue = '';
        if (this.deviceIsBuzzer === true)
            selectedValue = this.device.deviceId;
        let result = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.NOTIFICATIONS, DepartmentSettingsKeys.BUZZER, DepartmentSettingsKeys.DEVICE_ID], selectedValue);
        if (result === null) {
            VueUtilities.openErrorToast(this, this.$t('component.device.list.settings.save_error'));
            this.loadDeviceInfo(this.$route.params.deviceId);
        }
        else {
            this.isEditingSettings = false;
            this.isLoading = false;
        }
    }
};
DeviceSettings = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.DEVICE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { DeviceInfo }
    })
], DeviceSettings);
export default DeviceSettings;

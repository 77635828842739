export class BatteryState {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => BatteryState.define(et));
        }
        else if (data != null) {
            return BatteryState.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let battery = new BatteryState();
        battery.value = data.Value;
        battery.unit = data.Unit;
        return battery;
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ScheduleUpdateModal from '@/views/components/admin/devices/ScheduleUpdateModal.vue';
let AdminFirmwareDetail = class AdminFirmwareDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isEditing = false;
        this.fwKeyValueObj = null;
        this.fwKeyValueArray = [];
        this.fwKeyValueOriginalArray = [];
        this.updatedFwKeyValueObj = null;
        this.isScheduleUpdateModalActive = false;
    }
    created() {
        this.isLoading = true;
        this.fwKeyValueObj = this.firmware.data;
        this.loadData();
        this.isLoading = false;
    }
    loadData() {
        this.fwKeyValueArray = Object.entries(this.fwKeyValueObj);
        this.fwKeyValueOriginalArray = Object.entries(this.fwKeyValueObj);
    }
    editFw() {
        this.isEditing = !this.isEditing;
    }
    async deleteFirmware() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.devices.deleteFirmwareModel.title')}`,
            message: this.$t('admin.component.devices.deleteFirmwareModel.message', {
                firmwareId: this.firmware.id
            }),
            confirmText: `${this.$t('admin.component.devices.deleteFirmwareModel.confirm')}`,
            cancelText: `${this.$t('admin.component.devices.deleteFirmwareModel.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('deleteFirmware');
            },
            onCancel: () => { }
        });
    }
    openScheduleFirmware() {
        this.isScheduleUpdateModalActive = true;
    }
    saveChanges() {
        this.updatedFwKeyValueObj = Object.fromEntries(this.fwKeyValueArray);
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.firmware.data = this.updatedFwKeyValueObj;
                this.$emit('firmwareChanges', this.firmware);
            }
        });
    }
    closeScheduleUpdateModal() {
        this.isScheduleUpdateModalActive = false;
    }
    reload() {
        this.$emit('reload');
    }
    get hasChanges() {
        return this.fwKeyValueArray.toString() != this.fwKeyValueOriginalArray.toString();
    }
    addKeyValue() {
        this.fwKeyValueArray.push({ 0: '', 1: '' });
    }
    removeKeyValue(index) {
        this.fwKeyValueArray = this.fwKeyValueArray.filter((x) => x != index);
    }
    errorMessageKey(id) {
        var message = this.errors.first(this.$t('admin.component.devices.firmwareList.detail.firmwareInfo.properties.key') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
    errorMessageValue(id) {
        var message = this.errors.first(this.$t('admin.component.devices.firmwareList.detail.firmwareInfo.properties.value') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
};
__decorate([
    Prop({ type: Object })
], AdminFirmwareDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminFirmwareDetail.prototype, "firmware", void 0);
__decorate([
    Prop({ type: Array })
], AdminFirmwareDetail.prototype, "deviceList", void 0);
AdminFirmwareDetail = __decorate([
    Component({
        components: {
            ScheduleUpdateModal
        }
    })
], AdminFirmwareDetail);
export default AdminFirmwareDetail;

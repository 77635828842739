var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-collapse",
        {
          staticClass: "panel",
          attrs: { animation: "slide" },
          on: {
            open: function ($event) {
              return _vm.statesCollapseChanged(true)
            },
            close: function ($event) {
              return _vm.statesCollapseChanged(false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "panel-heading", attrs: { role: "button" } },
                    [
                      _c(
                        "h2",
                        { staticClass: "subtitle is-4" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("component.states.title")) + " "
                          ),
                          _vm.statesVisible
                            ? _c("b-icon", {
                                staticClass: "is-size-5 mx-1",
                                attrs: {
                                  size: "is-small",
                                  icon: "chevron-down",
                                },
                              })
                            : _c("b-icon", {
                                staticClass: "is-size-5 mx-1",
                                attrs: {
                                  size: "is-small",
                                  icon: "chevron-right",
                                },
                              }),
                          !_vm.statesVisible && _vm.loggerOfflineCount !== 0
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    type: "is-primary",
                                    icon: "exclamation-triangle",
                                    rounded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("component.states.loggerCount", {
                                          count: _vm.loggerOfflineCount,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : !_vm.statesVisible
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "mx-2",
                                  attrs: { type: "is-success", rounded: "" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.states.loggersAllOnline"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.statesVisible && _vm.deviceOfflineCount !== 0
                            ? _c(
                                "b-tag",
                                {
                                  attrs: {
                                    type: "is-primary",
                                    icon: "exclamation-triangle",
                                    rounded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("component.states.deviceCount", {
                                          count: _vm.deviceOfflineCount,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : !_vm.statesVisible && _vm.allDevices.length !== 0
                            ? _c(
                                "b-tag",
                                { attrs: { type: "is-success", rounded: "" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.states.devicesAllOnline"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.statesVisible,
            callback: function ($$v) {
              _vm.statesVisible = $$v
            },
            expression: "statesVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("label", { staticClass: "mr-3" }, [
                _vm._v(_vm._s(_vm.$t("component.states.all"))),
              ]),
              _c("b-switch", {
                staticClass: "my-1 mb-3",
                on: { input: _vm.allSelected },
                model: {
                  value: _vm.showGroups,
                  callback: function ($$v) {
                    _vm.showGroups = $$v
                  },
                  expression: "showGroups",
                },
              }),
              _c("label", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.$t("component.states.byGroup"))),
              ]),
              _vm.showGroups
                ? _c(
                    "b-select",
                    {
                      staticClass: "is-inline align-top",
                      on: { input: _vm.sourceGroupSelected },
                      model: {
                        value: _vm.selectedGroup,
                        callback: function ($$v) {
                          _vm.selectedGroup = $$v
                        },
                        expression: "selectedGroup",
                      },
                    },
                    [
                      _vm._l(_vm.sourceGroups, function (source) {
                        return _c(
                          "option",
                          { key: source.id, domProps: { value: source.id } },
                          [_vm._v(" " + _vm._s(source.name) + " ")]
                        )
                      }),
                      _vm.unassignedSources.length !== 0
                        ? _c("option", { domProps: { value: null } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.source_group_picker.default_group_name"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c("hr"),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("h3", { staticClass: "subtitle is-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.states.loggerStates")) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "is-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "box has-text-centered p-5",
                          class:
                            _vm.selectedLoggersState === false
                              ? "selected"
                              : "not-selected",
                          on: {
                            click: function ($event) {
                              return _vm.selectLoggerState(false)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "signal",
                            attrs: {
                              src: require("@/assets/img/no_signal.svg"),
                            },
                          }),
                          _c("p", { staticClass: "is-size-7" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.states.countOffline", {
                                    count: _vm.loggerOfflineCount,
                                  })
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "box has-text-centered p-5 ml-4 mb-auto",
                          class:
                            _vm.selectedLoggersState === true
                              ? "selected"
                              : "not-selected",
                          on: {
                            click: function ($event) {
                              return _vm.selectLoggerState(true)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "signal",
                            attrs: {
                              src: require("@/assets/img/excellent_signal.svg"),
                            },
                          }),
                          _c("p", { staticClass: "is-size-7" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.states.countOnline", {
                                    count: _vm.loggerOnlineCount,
                                  })
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "b-table",
                      {
                        attrs: {
                          data: _vm.sourcesStates,
                          "default-sort-direction": _vm.sortDirection,
                          "default-sort": _vm.sortField,
                          paginated: "",
                          "current-page": _vm.pageLogger,
                          "per-page": _vm.pageSize,
                          total: _vm.sourcesStates.length,
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            _vm.pageLogger = $event
                          },
                          "update:current-page": function ($event) {
                            _vm.pageLogger = $event
                          },
                          "page-change": _vm.onPageChangeLogger,
                        },
                      },
                      [
                        _c("b-table-column", {
                          attrs: {
                            field: "loggerState",
                            label: _vm.$t("component.states.state"),
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (logger) {
                                return [
                                  _c(
                                    "p",
                                    [
                                      _c("b-icon", {
                                        staticClass: "is-size-7",
                                        attrs: {
                                          type: logger.row.isOnline
                                            ? "is-success"
                                            : "is-danger",
                                          icon: "circle",
                                        },
                                      }),
                                      logger.row.isOnline
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("generals.online"))
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("generals.offline"))
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("b-table-column", {
                          attrs: {
                            field: "loggerName",
                            label: _vm.$t("component.states.name"),
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (logger) {
                                return [
                                  _vm._v(" " + _vm._s(logger.row.name) + " "),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("b-table-column", {
                          attrs: {
                            field: "loggerDescr",
                            label: _vm.$t("component.states.description"),
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (logger) {
                                return [
                                  logger.row.description
                                    ? _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(logger.row.description) +
                                            " "
                                        ),
                                      ])
                                    : _c("p", [_vm._v("-")]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("b-table-column", {
                          attrs: {
                            field: "lastSeen",
                            label: _vm.$t("component.states.lastSeen"),
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (logger) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dateTimeManager.formatTime(
                                          new Date(logger.row.lastSeen * 1000)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("template", { slot: "empty" }, [
                          _c("section", { staticClass: "section" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "content has-text-grey has-text-centered",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("component.states.emptyLoggers")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.allDevices.length !== 0
                  ? _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("h3", { staticClass: "subtitle is-5" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("component.states.deviceStates")) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "is-flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "box has-text-centered p-5",
                              class:
                                _vm.selectedDevicesState === false
                                  ? "selected"
                                  : "not-selected",
                              on: {
                                click: function ($event) {
                                  return _vm.selectDeviceState(false)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "signal",
                                attrs: {
                                  src: require("@/assets/img/no_signal.svg"),
                                },
                              }),
                              _c("p", { staticClass: "is-size-7" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("component.states.countOffline", {
                                        count: _vm.deviceOfflineCount,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "box has-text-centered p-5 ml-4 mb-auto",
                              class:
                                _vm.selectedDevicesState === true
                                  ? "selected"
                                  : "not-selected",
                              on: {
                                click: function ($event) {
                                  return _vm.selectDeviceState(true)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "signal",
                                attrs: {
                                  src: require("@/assets/img/excellent_signal.svg"),
                                },
                              }),
                              _c("p", { staticClass: "is-size-7" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("component.states.countOnline", {
                                        count: _vm.deviceOnlineCount,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "b-table",
                          {
                            attrs: {
                              data: _vm.devicesStates,
                              "default-sort-direction": _vm.sortDirection,
                              "default-sort": _vm.sortField,
                              paginated: "",
                              "current-page": _vm.pageDevice,
                              "per-page": _vm.pageSize,
                              total: _vm.devicesStates.length,
                            },
                            on: {
                              "update:currentPage": function ($event) {
                                _vm.pageDevice = $event
                              },
                              "update:current-page": function ($event) {
                                _vm.pageDevice = $event
                              },
                              "page-change": _vm.onPageChangeDevice,
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "loggerState",
                                label: _vm.$t("component.states.state"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (device) {
                                      return [
                                        _c(
                                          "p",
                                          [
                                            _c("b-icon", {
                                              staticClass: "is-size-7",
                                              attrs: {
                                                type: device.row.isOnline
                                                  ? "is-success"
                                                  : "is-danger",
                                                icon: "circle",
                                              },
                                            }),
                                            device.row.isOnline
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("generals.online")
                                                    )
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("generals.offline")
                                                    )
                                                  ),
                                                ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3668060257
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "deviceName",
                                label: _vm.$t("component.states.name"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (device) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(device.row.deviceName) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2030499490
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "deviceDescr",
                                label: _vm.$t("component.states.description"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (device) {
                                      return [
                                        device.row.description
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    device.row.description
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("p", [_vm._v("-")]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                82267438
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "lastSeen",
                                label: _vm.$t("component.states.lastSeen"),
                                sortable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (device) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dateTimeManager.formatTime(
                                                new Date(
                                                  device.row.lastSeen * 1000
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1207091203
                              ),
                            }),
                            _c("template", { slot: "empty" }, [
                              _c("section", { staticClass: "section" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "content has-text-grey has-text-centered",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.states.emptyDevices"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
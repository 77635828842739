var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.notification.settings.test_notification")
            ) +
            " "
        ),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _c("p", { staticClass: "subtitle has-text-centered" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.notification.settings.test_notification_format")
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "is-flex is-flex-direction-row is-justify-content-space-around",
        },
        [
          _c(
            "b-button",
            {
              staticClass: "level-item",
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.sendTestNotification(
                    _vm.ValidationRuleScope.SOURCE
                  )
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "component.notification.notification_format.measuredDataAlarm"
                  )
                )
              ),
            ]
          ),
          _c(
            "b-button",
            {
              staticClass: "level-item",
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.sendTestNotification(
                    _vm.ValidationRuleScope.SOURCE_GROUP
                  )
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "component.notification.notification_format.groupMeasuredDataAlarm"
                  )
                )
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import SettingOptions from '@/entities/enums/SettingOptions';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuCategoryKeys } from '@/entities/interfaces/MenuCategories';
let OrganizationSideBar = class OrganizationSideBar extends Vue {
    constructor() {
        super(...arguments);
        this.SettingOptions = SettingOptions;
        this.isSideMenuVisible = false;
        this.settingsSubpages = [SettingOptions.PROFILE.activeOnPages];
        this.mainCategories = {
            MAIN_ORG: {
                name: MenuCategoryKeys.MAIN_ORG,
                expandable: false,
                expanded: false
            },
            NOTIFICATIONS: {
                name: MenuCategoryKeys.NOTIFICATION_ORG,
                expandable: false,
                expanded: false
            },
            PROFILE: {
                name: MenuCategoryKeys.PROFILE,
                expandable: false,
                expanded: false
            }
        };
    }
    created() {
        this.$eventBus.$on('isSideMenuVisible', (value) => {
            this.isSideMenuVisible = value;
        });
    }
    onNavigation() {
        this.mobileExpand(false);
        this.$eventBus.$emit('closeBurgerMenu', false);
        window.scrollTo(0, 0);
    }
    isActive(routes) {
        if (Array.isArray(routes) && this.$route.name != null) {
            return routes.find((el) => el == this.$route.name) != undefined;
        }
    }
    expandButtonClicked(isExpanded) {
        this.$emit('SideMenuExpanded', isExpanded);
    }
    mobileExpand(isExpanded) {
        this.isSideMenuVisible = isExpanded;
    }
    expandCategory(event, category) {
        event.stopPropagation();
        event.preventDefault();
        category.expanded = !category.expanded;
    }
};
__decorate([
    Prop({ type: Boolean })
], OrganizationSideBar.prototype, "isExpanded", void 0);
OrganizationSideBar = __decorate([
    Component({})
], OrganizationSideBar);
export default OrganizationSideBar;

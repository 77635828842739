var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      [
        _c("div", { staticClass: "level section-header-level" }, [
          _c("div", { staticClass: "level-left" }, [
            _vm.isLayoutList
              ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.reseller.detail.resellerUsers.title"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "level-right" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "is-dark-grey",
                                      staticStyle: { "font-size": "1rem" },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field ==
                                  _vm.tableColumns.fullName.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.reseller.detail.resellerUsers.list." +
                                        column.field
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "b-table",
          {
            attrs: {
              data: _vm.users,
              striped: "",
              "default-sort-direction": _vm.sortDirection,
              "default-sort": _vm.sortField,
            },
          },
          [
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.fullName.field,
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerUsers.list." +
                    _vm.tableColumns.fullName.field
                ),
                visible: _vm.tableColumns.fullName.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (users) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminUserDetail",
                              params: {
                                userId: users.row.apiUserId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(users.row.fullName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.email.field,
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerUsers.list." +
                    _vm.tableColumns.email.field
                ),
                visible: _vm.tableColumns.email.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (users) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminUserDetail",
                              params: {
                                userId: users.row.apiUserId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(users.row.email))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.username.field,
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerUsers.list." +
                    _vm.tableColumns.username.field
                ),
                visible: _vm.tableColumns.username.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (users) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminUserDetail",
                              params: {
                                userId: users.row.apiUserId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(users.row.username))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "action",
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerUsers.list.action"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (users) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-flex-direction-row is-align-items-center",
                        },
                        [
                          _c("b-icon", {
                            staticClass: "control-icon clickable",
                            attrs: { icon: "trash" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.actionSelectedUser(users.row)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("template", { slot: "empty" }, [
              _c("section", { staticClass: "section" }, [
                _c(
                  "div",
                  { staticClass: "content has-text-grey has-text-centered" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "admin.component.reseller.detail.resellerUsers.list.empty"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
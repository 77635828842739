import { TierFeature } from './TierFeature';
export class CompanyTier {
    constructor() {
        this.features = new Array();
    }
    sortFeaturesByName() {
        this.features = this.features.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.enabledFeatures = this.enabledFeatures.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    }
    checkUsage() {
        let hasBeenUsed = false;
        this.features.forEach((feature) => {
            if (feature.CurrentUsage?.Count > 0)
                hasBeenUsed = true;
        });
        return hasBeenUsed;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => CompanyTier.define(et));
        }
        else if (data != null) {
            return CompanyTier.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let tier = new CompanyTier();
        tier.id = data.Id;
        tier.tierId = data.TierId;
        tier.name = data.Name;
        tier.createdAt = data.CreatedAt;
        tier.activatedAt = data.ActivatedAt;
        tier.deactivatedAt = data.DeactivatedAt;
        tier.active = data.Active;
        tier.features = data.Features;
        tier.enabledFeatures = TierFeature.filterEnabledFeatures(data.Features);
        tier.sortFeaturesByName();
        tier.alreadyUsed = tier.checkUsage();
        return tier;
    }
}

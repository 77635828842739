import InvitationStates from '@/entities/enums/InvitationStates';
export class ReceivedInvitation {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => ReceivedInvitation.define(et));
        }
        else if (data != null) {
            return ReceivedInvitation.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let invitation = new ReceivedInvitation();
        invitation.id = data.Id;
        invitation.state = data.State;
        invitation.validUntil = data.ValidUntil;
        invitation.department = data.Department;
        return invitation;
    }
    isExpired() {
        return Date.now() >= this.validUntil * 1000 && this.state === InvitationStates.PENDING;
    }
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ValidationRuleScope from '@/entities/notifications/ValidationRuleScope';
let TestNotificationModal = class TestNotificationModal extends Vue {
    constructor() {
        super(...arguments);
        this.ValidationRuleScope = ValidationRuleScope;
    }
    created() { }
    closeModal() {
        this.$emit('modalClosed');
    }
    sendTestNotification(val = ValidationRuleScope.SOURCE) {
        this.$emit('sendTestNotification', val);
    }
};
TestNotificationModal = __decorate([
    Component({ components: {} })
], TestNotificationModal);
export default TestNotificationModal;

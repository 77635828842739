import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import jsQR from 'jsqr';
import VueUtilities from '@/services/VueUtilities';
let QRCodeFromFile = class QRCodeFromFile extends Vue {
    constructor() {
        super(...arguments);
        this.scannedData = [];
        this.numOfFiles = 0;
        this.isFileExplorerOpened = false;
        this.validTypes = ['image/jpeg', 'image/png'];
    }
    mounted() {
        this.$refs.fileInput.focus();
        this.$refs.fileInput.click();
        this.isFileExplorerOpened = true;
    }
    handleFocus(event) {
        if (this.isFileExplorerOpened) {
            // delay to check files due to async load
            setTimeout(() => {
                const input = event.target;
                if (input.files && input.files.length == 0) {
                    this.isFileExplorerOpened = false;
                    this.$emit('setScanMethod', null);
                }
            }, 150);
        }
    }
    handleFiles(event) {
        const input = event.target;
        // convert FileList to normal Array because of missing some array methods
        if (input.files) {
            const validFiles = Array.from(input.files).filter((file) => this.validTypes.includes(file.type));
            if (validFiles.length !== input.files.length) {
                VueUtilities.openErrorToast(this, this.$t('component.qr_scanner.invalid_file_type').toString());
            }
            this.numOfFiles = validFiles.length;
            this.isFileExplorerOpened = false;
            validFiles.forEach((file, index) => this.processFile(file, index));
            input.value = '';
        }
    }
    processFile(file, index) {
        const reader = new FileReader();
        // reading operation is successfully completed
        reader.onload = (e) => {
            if (e.target) {
                const img = new Image();
                // image is fully loaded
                img.onload = () => {
                    const canvasElement = document.createElement('canvas');
                    const context = canvasElement.getContext('2d');
                    if (context) {
                        canvasElement.width = img.width;
                        canvasElement.height = img.height;
                        context.drawImage(img, 0, 0, img.width, img.height);
                        this.decodeQRCode(canvasElement, context, index, file.name);
                    }
                };
                img.src = e.target.result;
            }
        };
        // starting the reading operation, async function
        reader.readAsDataURL(file);
    }
    decodeQRCode(canvas, ctx, index, fileName) {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code && code.data) {
            this.scannedData.push({
                fileName: fileName,
                dataStream: code.data
            });
            if (this.numOfFiles - 1 === index) {
                this.$emit('parseData', this.scannedData);
                this.$emit('setScanMethod', null);
            }
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.qr_scanner.invalid_image').toString());
        }
    }
};
QRCodeFromFile = __decorate([
    Component
], QRCodeFromFile);
export default QRCodeFromFile;

import NotificationPriority from '../enums/NotificationPriority';
import NotificationChannel from './NotificationChannel';
import NotificationChannelType from './NotificationChannelType';
import NotificationScopeType from './NotificationScopeType';
import NotificationSubscriptionCategory from './NotificationSubscriptionCategory';
import NotificationSubscriptionType from './NotificationSubscriptionType';
import { NotificationRuleDisplayMode } from './NotificationRuleDisplayMode';
export default class NotificationSubscription {
    constructor() {
        this.additionalProperties = { isEditing: false };
    }
    makeCopy(copyScope = false) {
        let newOne = new NotificationSubscription();
        newOne.channels = this.channels;
        newOne.notificationType = this.notificationType;
        newOne.subscriptionCategory = this.subscriptionCategory;
        newOne.priority = this.priority;
        newOne.enabled = this.enabled;
        newOne.additionalProperties = JSON.parse(JSON.stringify(this.additionalProperties));
        newOne.displayMode = this.displayMode;
        newOne.presetId = this.presetId;
        newOne.scheduleId = this.scheduleId;
        if (copyScope === true) {
            newOne.scopeId = this.scopeId;
            newOne.scopeType = this.scopeType;
        }
        return newOne;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => NotificationSubscription.define(et));
        }
        else if (data != null) {
            return NotificationSubscription.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let subscription = new NotificationSubscription();
        subscription.channels = NotificationChannel.sortByType(NotificationChannel.ResponseToArray(data.Channels));
        subscription.notificationSubscriptionId = data.NotificationSubscriptionId;
        subscription.notificationType = data.NotificationType;
        subscription.priority = NotificationPriority.getNotificationPriorityByName(data.Priority);
        subscription.scopeId = data.ScopeId;
        subscription.scopeType = data.ScopeType;
        subscription.enabled = data.Enabled;
        subscription.displayMode = data.DisplayMode;
        subscription.presetId = data.PresetId;
        subscription.scheduleId = data.ScheduleId;
        if (subscription.notificationType == NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            subscription.notificationType == NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM)
            subscription.subscriptionCategory = NotificationSubscriptionCategory.MEASUREMENT_RULE;
        else
            subscription.subscriptionCategory = NotificationSubscriptionCategory.SYSTEM_RULE;
        return subscription;
    }
    static convertFromPreset(presetDto, departmentId) {
        let subscription = new NotificationSubscription();
        subscription.channels = NotificationChannel.sortByType(NotificationChannel.ResponseToArray(presetDto.Channels));
        subscription.notificationType = presetDto.NotificationType;
        subscription.priority = NotificationPriority.getNotificationPriorityByName(presetDto.Priority);
        subscription.scopeId = departmentId;
        subscription.scopeType = NotificationScopeType.COMPANY;
        subscription.enabled = true;
        subscription.displayMode = NotificationRuleDisplayMode.BASIC;
        if (subscription.notificationType == NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            subscription.notificationType == NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM)
            subscription.subscriptionCategory = NotificationSubscriptionCategory.MEASUREMENT_RULE;
        else
            subscription.subscriptionCategory = NotificationSubscriptionCategory.SYSTEM_RULE;
        return subscription;
    }
    static getDefaultValuesForTypeAndChannel(type, channel) {
        let turnedOff = { enabled: false, delay: 0, onResolve: false, repeat: -1 };
        switch (type) {
            case NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM:
            case NotificationSubscriptionType.MEASURED_DATA_ALARM:
                if (channel === NotificationChannelType.SYSTEM) {
                    return { enabled: true, delay: 900, onResolve: true, repeat: -1 };
                }
                else if (channel === NotificationChannelType.EMAIL) {
                    return { enabled: true, delay: 1800, onResolve: true, repeat: 43200 };
                }
                else {
                    return turnedOff;
                }
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                if (channel === NotificationChannelType.SYSTEM) {
                    return { enabled: true, delay: 900, onResolve: false, repeat: 86400 };
                }
                else if (channel === NotificationChannelType.EMAIL) {
                    return { enabled: true, delay: 86400, onResolve: false, repeat: 604800 };
                }
                else {
                    return turnedOff;
                }
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                if (channel === NotificationChannelType.SYSTEM) {
                    return { enabled: true, delay: 900, onResolve: true, repeat: 86400 };
                }
                else if (channel === NotificationChannelType.EMAIL) {
                    return { enabled: true, delay: 10800, onResolve: true, repeat: 86400 };
                }
                else {
                    return turnedOff;
                }
                break;
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                if (channel === NotificationChannelType.SYSTEM) {
                    return { enabled: true, delay: 900, onResolve: true, repeat: 86400 };
                }
                else if (channel === NotificationChannelType.EMAIL) {
                    return { enabled: true, delay: 3600, onResolve: true, repeat: 43200 };
                }
                else {
                    return turnedOff;
                }
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
            case NotificationSubscriptionType.RECORDS_UPDATED:
            case NotificationSubscriptionType.LOGGER_STATE:
                if (channel === NotificationChannelType.SYSTEM) {
                    return { enabled: true, delay: 0, onResolve: false, repeat: -1 };
                }
                else if (channel === NotificationChannelType.EMAIL) {
                    return { enabled: true, delay: 0, onResolve: false, repeat: -1 };
                }
                else {
                    return turnedOff;
                }
                break;
            case NotificationSubscriptionType.ADMIN_LOGGER_CALIBRATION_ENDING:
            case NotificationSubscriptionType.FEEDBACK:
            case NotificationSubscriptionType.ROLE_CHANGED:
            case NotificationSubscriptionType.ADMIN_MESSAGE:
            case NotificationSubscriptionType.CUSTOMER_REGISTERED:
            case NotificationSubscriptionType.ADMIN_LOGGER_STATE:
                if (channel === NotificationChannelType.SYSTEM) {
                    return { enabled: true, delay: 0, onResolve: false, repeat: -1 };
                }
                else if (channel === NotificationChannelType.EMAIL) {
                    return { enabled: true, delay: 0, onResolve: false, repeat: -1 };
                }
                else {
                    return turnedOff;
                }
                break;
            default:
                return turnedOff;
        }
    }
    /**
     * Compares array of subscriptions with array of subscriptionsDTO. Returns true if arrays are same
     * @param subscriptions : Array<NotificationSubscription>
     * @param subscriptionsDTO : Array<INotificationSubscriptionCreateDTO
     * @returns boolean (true if arrays are same)
     */
    static compareSubscriptionsWithSubscriptionsCreateDto(subscriptions, subscriptionsDTO) {
        let enabledSubscriptionDTOTypes = subscriptionsDTO.map((x) => x.NotificationType);
        let typesInSubscriptions = subscriptions.map((x) => x.notificationType);
        let newInDTO = enabledSubscriptionDTOTypes.filter((x) => !typesInSubscriptions.includes(x));
        let newInSubs = typesInSubscriptions.filter((x) => !enabledSubscriptionDTOTypes.includes(x));
        if (newInDTO.length > 0 || newInSubs.length > 0)
            return false;
        let hasDifferences = false;
        subscriptionsDTO.forEach((sub) => {
            let subInDto = subscriptions.find((x) => x.notificationType === sub.NotificationType && x.scopeId === sub.ScopeId);
            if (sub.Enabled !== subInDto?.enabled) {
                hasDifferences = true;
                return;
            }
            let channelsInDTO = Object.keys(sub.Channels);
            let channelsInSubs = subInDto?.channels.map((x) => x.type);
            let newChanInDTO = channelsInDTO.filter((x) => !channelsInSubs.includes(x));
            let newChanInSubs = channelsInSubs.filter((x) => !channelsInDTO.includes(x));
            if (newChanInDTO.length > 0 || newChanInSubs.length > 0) {
                hasDifferences = true;
                return;
            }
            channelsInDTO.forEach((chanSub) => {
                let chanInSub = subInDto?.channels.find((x) => x.type === chanSub);
                if (sub.Channels[chanSub]?.Settings?.delay !== chanInSub?.settings?.delay ||
                    sub.Channels[chanSub]?.Settings?.onResolve !== chanInSub?.settings?.onResolve ||
                    sub.Channels[chanSub]?.Settings?.repeat !== chanInSub?.settings?.repeat) {
                    hasDifferences = true;
                }
            });
        });
        if (hasDifferences)
            return false;
        else
            return true;
    }
}

import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NotificationCategory from '@/entities/enums/NotificationCategory';
import LoggedUserManager from '@/services/LoggedUserManager';
let NotificationTableFilter = class NotificationTableFilter extends Vue {
    constructor() {
        super(...arguments);
        this.isLoggedInCompany = LoggedUserManager.isLoggedToCompany(this);
        this.selectedCategories = [];
        this.selectedDepartmentName = '';
        this.currentlySelectedCategory = null;
        this.selectedDateRange = [];
        this.today = new Date();
    }
    changeCurrentFilter() {
        this.fillFilters();
    }
    get availableCategoriesToFilter() {
        return NotificationCategory.getAvailableCategories(this);
    }
    get filteredDepartments() {
        return this.departmentList.filter((dep) => dep.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.selectedDepartmentName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    created() {
        this.fillFilters();
    }
    fillFilters() {
        this.selectedDepartmentName = this.selectedDepartment ? this.selectedDepartment.name : '';
        this.selectedDateRange = this.dateRange;
        this.selectedCategories = this.availableCategoriesToFilter.filter((x) => this.currentFilter.includes(x.name));
    }
    categoryAlreadySelected(category) {
        return this.selectedCategories.find((x) => x.name == category.name) != null;
    }
    categoryRemoved(category) {
        this.selectedCategories = this.selectedCategories.filter((x) => x.name != category.name);
        this.applyFilterChange();
    }
    categorySelected(category) {
        if (!category)
            return;
        this.selectedCategories.push(category);
        this.applyFilterChange();
        this.$nextTick(() => {
            this.currentlySelectedCategory = null;
        });
    }
    applyFilterChange() {
        this.$emit('filterChanged', this.selectedCategories.map((x) => x.name));
    }
    clearFilter() {
        this.selectedDepartmentName = '';
        this.selectedDateRange = [];
        this.currentlySelectedCategory = null;
        this.$emit('clearFilter');
    }
    departmentNameChanged(department) {
        if ((department && department.id === this.selectedDepartmentName) ||
            (!department && this.selectedDepartmentName === '')) {
            return;
        }
        this.selectedDepartmentName = department ? department.id : '';
        this.$emit('departmentNameChanged', department);
    }
    dateRangeChanged(data) {
        this.$emit('dateRangeChanged', data);
    }
    chooseTableColumns(field, event) {
        this.$emit('chooseTableColumns', field, event);
    }
};
__decorate([
    Prop({ type: Array })
], NotificationTableFilter.prototype, "currentFilter", void 0);
__decorate([
    Prop({ type: Array })
], NotificationTableFilter.prototype, "dateRange", void 0);
__decorate([
    Prop({ type: Array })
], NotificationTableFilter.prototype, "departmentList", void 0);
__decorate([
    Prop({ type: Object })
], NotificationTableFilter.prototype, "selectedDepartment", void 0);
__decorate([
    Prop({ type: Object })
], NotificationTableFilter.prototype, "tableColumns", void 0);
__decorate([
    Prop({ type: Object })
], NotificationTableFilter.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], NotificationTableFilter.prototype, "inNotificationDepartmentPage", void 0);
__decorate([
    Watch('currentFilter')
], NotificationTableFilter.prototype, "changeCurrentFilter", null);
NotificationTableFilter = __decorate([
    Component({ components: {} })
], NotificationTableFilter);
export default NotificationTableFilter;

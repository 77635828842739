import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DeviceRegistrationComponent from '@/views/components/device/DeviceRegistrationComponent.vue';
let DeviceRegistrationStep = class DeviceRegistrationStep extends Vue {
    constructor() {
        super(...arguments);
        this.deviceIsActiveFlag = true;
    }
    deviceIsActive(value) {
        this.deviceIsActiveFlag = value;
    }
    closeRegistrationModal(result) {
        this.$emit('closeRegistrationModal', result);
    }
};
DeviceRegistrationStep = __decorate([
    Component({ components: { DeviceRegistrationComponent } })
], DeviceRegistrationStep);
export default DeviceRegistrationStep;

export var Features;
(function (Features) {
    Features["LOGGERS_IN_COMPANY"] = "Loggers.LoggersInCompany";
    Features["OBSERVER_IN_COMPANY"] = "Observer.DevicesInCompany";
    Features["USERS_IN_COMPANY"] = "Users.UsersInCompany";
    Features["REPORTS_ANNOTATIONS"] = "Reports.Annotations";
    Features["REPORTS_CONFIG"] = "Reports.Configuration";
    Features["REPORTS_CSV"] = "Reports.Csv";
    Features["REPORTS_PDF"] = "Reports.Pdf";
    Features["NOTIFICATIONS_ADVANCED"] = "Notifications.Advanced";
    Features["NOTIFICATIONS_CALLS"] = "Notifications.ReceiveCalls";
    Features["NOTIFICATIONS_SMS"] = "Notifications.ReceiveSms";
    Features["VIRTUAL_QUANTITIES"] = "Data.VirtualQuantities";
})(Features || (Features = {}));

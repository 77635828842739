import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import debounce from 'lodash.debounce';
const DEBOUNCE_TIMER = 500;
let UserListComponent = class UserListComponent extends Vue {
    constructor() {
        super(...arguments);
        this.ApiUserRoles = ApiUserRoles;
        this.currentFilter = [];
        this.page = null;
        this.pageSize = null;
        this.jumpToPageValue = null;
        this.sortDirection = '';
        this.sortField = '';
        this.isEmailSearch = true;
        this.isUsernameSearch = false;
        this.isFullNameSearch = false;
        this.emailSearch = '';
        this.usernameSearch = '';
        this.fullnameSearch = '';
        this.selectedValue = '';
        this.tableColumns = {
            fullName: {
                field: 'FullName',
                visible: true,
                hasSearch: true
            },
            email: {
                field: 'Email',
                visible: true,
                hasSearch: true
            },
            username: {
                field: 'Username',
                visible: true,
                hasSearch: true
            }
        };
        this.handleFilterEmailDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.email.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterUsernameDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.username.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterFullnameDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.fullName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    created() {
        this.selectedValue = this.tableColumns.email.field;
        this.fillFromPref();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadUsers', this.pagination, this.sort, this.currentFilter);
    }
    fillFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminUserSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminUserSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.UserListComponentPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.UserListComponentRowsPerPage.defaultValue;
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        this.$emit('loadUsers', pagination, sort, this.currentFilter);
    }
    changeSearchField(event) {
        this.isEmailSearch = false;
        this.isUsernameSearch = false;
        this.isFullNameSearch = false;
        switch (event) {
            case this.tableColumns.email.field:
                this.isEmailSearch = true;
                break;
            case this.tableColumns.username.field:
                this.isUsernameSearch = true;
                break;
            case this.tableColumns.fullName.field:
                this.isFullNameSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    filterUsers(searchField, event) {
        switch (searchField) {
            case this.tableColumns.email.field:
                this.replaceFilter('Email@=', event);
                break;
            case this.tableColumns.username.field:
                this.replaceFilter('Username@=', event);
                break;
            case this.tableColumns.fullName.field:
                this.replaceFilter('FullName@=', event);
                break;
            default:
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, event) {
        let index = this.filterExists(filter);
        if (index !== -1)
            this.currentFilter[index] = filter + event;
        else
            this.currentFilter.push(filter + event);
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    clearFilter() {
        this.emailSearch = '';
        this.usernameSearch = '';
        this.fullnameSearch = '';
        this.currentFilter = [];
        this.onPageChange(1);
    }
    check(users) {
        this.$emit('selectedUsersChanged', users);
    }
};
__decorate([
    Prop({ type: Array })
], UserListComponent.prototype, "users", void 0);
__decorate([
    Prop({ type: Number })
], UserListComponent.prototype, "totalSize", void 0);
UserListComponent = __decorate([
    Component({ components: {} })
], UserListComponent);
export default UserListComponent;

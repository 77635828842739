import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoggerConfigurationList from '@/views/components/logger/LoggerConfigurationList.vue';
import IdleLoggersList from '@/views/components/logger/IdleLoggersList.vue';
let LoggerRegistrationStep = class LoggerRegistrationStep extends Vue {
    reloadData(showLoadingAnimation) {
        this.$emit('reloadLoggers', showLoadingAnimation);
    }
    onLoggerModalEvent(val) {
        this.$emit('logger-modal-visible', val);
    }
};
__decorate([
    Prop({ type: Array })
], LoggerRegistrationStep.prototype, "companyLoggerList", void 0);
__decorate([
    Prop({ type: Array })
], LoggerRegistrationStep.prototype, "sourceList", void 0);
__decorate([
    Prop({ type: Object })
], LoggerRegistrationStep.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: String })
], LoggerRegistrationStep.prototype, "deviceId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], LoggerRegistrationStep.prototype, "openLoggerScan", void 0);
LoggerRegistrationStep = __decorate([
    Component({ components: { LoggerConfigurationList, IdleLoggersList } })
], LoggerRegistrationStep);
export default LoggerRegistrationStep;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import TierCard from '@/views/components/admin/tiers/TierCard.vue';
let TiersList = class TiersList extends Vue {
    created() { }
};
__decorate([
    Prop({ type: Array })
], TiersList.prototype, "tiers", void 0);
TiersList = __decorate([
    Component({ components: { TierCard } })
], TiersList);
export default TiersList;

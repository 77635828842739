import LoggedUserManager from '@/services/LoggedUserManager';
class NotificationCategory {
    constructor() {
        //user
        this.EVENT_BOUNDARY = { name: 'boundary', type: 'is-bad' };
        this.DEVICE_OFFLINE = { name: 'observer_offline', type: 'is-info-darker' };
        this.DEVICE_ONLINE = { name: 'observer_online', type: 'is-info' };
        this.SOURCE_OFFLINE = { name: 'source_offline', type: 'is-danger' };
        this.LOGGER_BATTERY = { name: 'logger_battery', type: 'is-warning' };
        this.LOGGER_CALIBRATION_ENDING = { name: 'company_logger_ending_calibration', type: 'is-warning' };
        this.PDF_RECORDS_REGENERATED = { name: 'pdf_report', type: 'is-info' };
        this.OFFLINE_SYNC = { name: 'offline_sync', type: 'is-success' };
        this.LOGGER_STATE = { name: 'service_state', type: 'is-danger' };
        this.ADMIN_MESSAGE = { name: 'adminMessage', type: 'is-primary' };
        //admin
        this.USER_ROLE_CHANGED = { name: 'user_role_changed', type: 'is-info' };
        this.CUSTOMER_REGISTERED = { name: 'customer_registered', type: 'is-success' };
        this.FEEDBACK = { name: 'feedback_sent', type: 'is-info' };
        this.UNKNOWN = { name: 'unknown', type: 'is-success' };
    }
    getNotificationCategoryByName(name) {
        let priority = Object.values(this).find((x) => x.name == name);
        if (!priority)
            priority = this.UNKNOWN;
        return priority;
    }
    getAllNotificationCategories() {
        return Object.values(this).filter((x) => x.name != this.UNKNOWN.name);
    }
    getSubsetCategories() {
        return [
            this.EVENT_BOUNDARY,
            this.DEVICE_OFFLINE,
            this.DEVICE_ONLINE,
            this.SOURCE_OFFLINE,
            this.LOGGER_BATTERY,
            this.LOGGER_CALIBRATION_ENDING,
            this.PDF_RECORDS_REGENERATED,
            this.OFFLINE_SYNC,
            this.LOGGER_STATE
        ];
    }
    getAvailableCategories(ctx) {
        if (LoggedUserManager.isAdmin(ctx)) {
            return this.getAllNotificationCategories();
        }
        else {
            return this.getSubsetCategories();
        }
    }
}
export default new NotificationCategory();

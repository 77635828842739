import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import WizardSteps from '@/entities/enums/wizardSteps';
let SkipStep = class SkipStep extends Vue {
    constructor() {
        super(...arguments);
        this.WizardSteps = WizardSteps;
    }
    get showMsg() {
        return this.hasMsg && this.skipAllowed && this.canSkip && this.actualStep != WizardSteps.INSTRUCTIONS;
    }
    get skipAllowed() {
        if (this.actualStep == WizardSteps.INSTRUCTIONS ||
            this.actualStep == WizardSteps.DEVICE ||
            this.actualStep == WizardSteps.LOGGERS ||
            this.actualStep == WizardSteps.BOUNDARIES ||
            this.actualStep == WizardSteps.NOTIFICATIONS)
            return true;
        else
            return false;
    }
    get backAllowed() {
        if (this.actualStep != WizardSteps.INSTRUCTIONS)
            return true;
        else
            return false;
    }
    skipStep(actStep) {
        this.$emit('skipStep', actStep);
    }
    backStep(actStep) {
        this.$emit('backStep', actStep);
    }
    get skipMessage() {
        switch (this.actualStep) {
            case WizardSteps.DEVICE:
                return `${this.$t('component.wizard.skipMessages.device')} ${this.$t('component.wizard.skipMessages.canContinue')}`;
                break;
            case WizardSteps.LOGGERS:
                return `${this.$t('component.wizard.skipMessages.logger')} ${this.$t('component.wizard.skipMessages.canContinue')}`;
                break;
            case WizardSteps.BOUNDARIES:
                return `${this.$t('component.wizard.skipMessages.boundaries')} ${this.$t('component.wizard.skipMessages.canContinue')}`;
                break;
            case WizardSteps.NOTIFICATIONS:
                return this.$t('component.wizard.skipMessages.canContinue');
                break;
            default:
                return this.$t('component.wizard.skipMessages.generic');
                break;
        }
    }
};
__decorate([
    Prop({ type: Number, default: WizardSteps.DEVICE })
], SkipStep.prototype, "actualStep", void 0);
__decorate([
    Prop({ type: Boolean })
], SkipStep.prototype, "canSkip", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], SkipStep.prototype, "hasMsg", void 0);
SkipStep = __decorate([
    Component({})
], SkipStep);
export default SkipStep;

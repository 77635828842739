const UnitNames = Object.freeze({
    TEMPERATURE: { name: 'temperature', id: 0, defaultUnit: '°C' },
    HUMIDITY: { name: 'humidity', id: 1, defaultUnit: '%' },
    SHOCK: { name: 'shock', id: 2, defaultUnit: 'mg' },
    PERCENTAGE: { name: 'percentage', id: 3, defaultUnit: '%' },
    VOLTAGE: { name: 'voltage', id: 4, defaultUnit: 'mV' },
    TIME: { name: 'time', id: 5, defaultUnit: 's' },
    STATE: { name: 'state', id: 6, defaultUnit: '' },
    STATUS: { name: 'status', id: 7, defaultUnit: '' },
    DEFAULT: { name: 'default', id: 8, defaultUnit: '' },
    PRESSURE: { name: 'pressure', id: 9, defaultUnit: 'kPa' },
    DEWPOINT: { name: 'dewpoint', id: 0, defaultUnit: '°C' }
});
export function getUnitById(id) {
    let foundUnit = Object.values(UnitNames).find((x) => x.id == id);
    if (!foundUnit)
        return UnitNames.DEFAULT;
    else
        return foundUnit;
}
export default UnitNames;

import { __decorate } from "tslib";
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import LoggedUserManager from '@/services/LoggedUserManager';
import { Component, Vue, Prop } from 'vue-property-decorator';
let ChangeCompanyUserRoleModal = class ChangeCompanyUserRoleModal extends Vue {
    constructor() {
        super(...arguments);
        this.ApiUserRoles = ApiUserRoles;
        this.newRole = null;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
    }
    get availableUserRoles() {
        if (this.isResellerAdmin) {
            return Object.values(ApiUserRoles).filter((x) => x != this.user.role.Key && x != ApiUserRoles.SYSTEM_ADMIN && x != ApiUserRoles.RESELLER_ADMIN);
        }
        else {
            return Object.values(ApiUserRoles).filter((x) => x != this.user.role.Key);
        }
    }
    get adminRoles() {
        return Object.values(this.availableUserRoles).filter((x) => x == ApiUserRoles.SYSTEM_ADMIN || x == ApiUserRoles.RESELLER_ADMIN);
    }
    get userRoles() {
        return Object.values(this.availableUserRoles).filter((x) => x != ApiUserRoles.SYSTEM_ADMIN && x != ApiUserRoles.RESELLER_ADMIN);
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    save() {
        if (this.newRole == ApiUserRoles.COMPANY_OWNER) {
            this.$buefy.dialog.confirm({
                title: this.$t('users.change_user_role.confirm_dialog_owner.title'),
                message: this.$t('users.change_user_role.confirm_dialog_owner.msg'),
                confirmText: this.$t('users.change_user_role.confirm_dialog_owner.confirm'),
                type: 'is-primary',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('changeRole', this.user, this.newRole);
                }
            });
        }
        else {
            this.$emit('changeRole', this.user, this.newRole);
        }
    }
};
__decorate([
    Prop({ type: Object })
], ChangeCompanyUserRoleModal.prototype, "user", void 0);
ChangeCompanyUserRoleModal = __decorate([
    Component({ components: {} })
], ChangeCompanyUserRoleModal);
export default ChangeCompanyUserRoleModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-item" }, [
    _c("div", { staticClass: "item-row" }, [
      _c("h3", {
        staticClass: "subtitle is-6",
        domProps: { innerHTML: _vm._s(_vm.notificationItem.title) },
      }),
      _c("a", {
        staticClass: "delete is-small mark-as-read",
        attrs: { id: _vm.MORE_BTN_ID },
        on: { click: _vm.notificationRemoved },
      }),
    ]),
    _c(
      "div",
      { staticClass: "item-row" },
      [
        _c("p", { staticClass: "is-size-6 has-text-grey" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.dateTimeManager.formatTime(
                  new Date(_vm.notificationItem.createdAt * 1000)
                )
              ) +
              " "
          ),
        ]),
        _c(
          "b-tag",
          { attrs: { type: _vm.notificationItem.category.type, rounded: "" } },
          [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "component.notifications_list.category." +
                    _vm.notificationItem.category.name
                )
              )
            ),
          ]
        ),
      ],
      1
    ),
    _c("p", {
      staticClass: "description text-wrap-anywhere",
      domProps: { innerHTML: _vm._s(_vm.notificationItem.description) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export class AdminDepartmentSettings {
    constructor(data) {
        this.settings = [];
        this.settings = data;
    }
    getSettings() {
        return this.settings || [];
    }
    static fromApi(data) {
        if (!data)
            return new AdminDepartmentSettings(null);
        else {
            let settings = [];
            let keys = Object.keys(data);
            keys.forEach((key) => {
                let setting = this.define(data[key], key);
                settings.push(setting);
            });
            return new AdminDepartmentSettings(settings);
        }
    }
    static define(data, key) {
        let setting = new AdminCompanySetting();
        setting.Key = key;
        setting.Value = data.Value;
        setting.CreatedAt = data.CreatedAt;
        setting.UpdatedAt = data.UpdatedAt;
        setting.Permission = data.Permission;
        return setting;
    }
}
export class AdminCompanySetting {
    constructor() { }
}
export var AdminCompanySettingPermissions;
(function (AdminCompanySettingPermissions) {
    AdminCompanySettingPermissions["ADMIN_ONLY"] = "AdminOnly";
    AdminCompanySettingPermissions["EDITABLE"] = "Editable";
    AdminCompanySettingPermissions["VISIBLE"] = "Visible";
})(AdminCompanySettingPermissions || (AdminCompanySettingPermissions = {}));

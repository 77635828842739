import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageUploader from 'vue-image-upload-resize/src/components/ImageUploader.vue';
import VueUtilities from '@/services/VueUtilities';
import { Buffer } from 'buffer';
import UserPreferences from '@/services/UserPreferences';
import FloorPlanEntities from '@/entities/enums/FloorPlanEntities';
let FloorPlanUpload = class FloorPlanUpload extends Vue {
    constructor() {
        super(...arguments);
        this.FloorPlanEntities = FloorPlanEntities;
        this.image = new Image();
        this.imageFile = null;
        this.ctx = null;
        this.numberOfLoggers = 0;
        this.editedFloorPlanData = [];
        this.isUploading = false;
        this.isUpdating = false;
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FloorPlanUploadLayoutList);
        this.offsetX = null;
        this.offsetY = null;
        this.dragok = false;
        this.startX = null;
        this.startY = null;
        this.isDragging = false;
    }
    async mounted() {
        this.loadData();
    }
    async created() {
        this.numberOfLoggers = 0;
    }
    async loadData() {
        this.editedFloorPlanData = JSON.parse(JSON.stringify(this.floorPlanData));
        this.editedFloorPlanData.forEach((x) => (x.isDragging = false));
        if (this.editedFloorPlanData && this.floorPlanImage) {
            await new Promise((resolve, reject) => {
                this.image.src = 'data:' + this.imageType + ';base64, ' + this.arrayBufferToBase64(this.floorPlanImage);
                this.image.onload = resolve;
                this.image.onerror = reject;
            });
            this.numberOfLoggers = 0;
            this.canvas = document.getElementById('canvas');
            this.ctx = this.canvas.getContext('2d');
            // Getting and apply percentage for creating responsive image
            let percentage = this.getPercentage(this.canvas.offsetWidth, this.image.width);
            let percentageForWidth = (this.image.width / 100) * percentage;
            let percentageForHeight = (this.image.height / 100) * percentage;
            this.canvas.width = this.image.width + percentageForWidth;
            this.canvas.height = this.image.height + percentageForHeight;
            this.image.setAttribute('crossOrigin', 'anonymous');
            this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
            if (this.isOldFormat()) {
                this.editedFloorPlanData.forEach((x) => {
                    x.coordinates[0] = (x.coordinates[0] / this.image.width) * this.canvas.width;
                    x.coordinates[1] = (x.coordinates[1] / this.image.height) * this.canvas.height;
                    this.drawData(x.coordinates[0], x.coordinates[1]);
                });
            }
            else {
                this.editedFloorPlanData.forEach((x) => {
                    x.coordinates[0] = x.coordinates[0] * this.canvas.width;
                    x.coordinates[1] = x.coordinates[1] * this.canvas.height;
                    this.drawData(x.coordinates[0], x.coordinates[1]);
                });
            }
        }
    }
    /**
     * Check if coordinates are in old format
     */
    isOldFormat() {
        return this.editedFloorPlanData.filter((x) => x.coordinates[0] > 1).length > 0;
    }
    async setImage(file) {
        await new Promise((resolve, reject) => {
            this.image.src = file;
            this.image.onload = resolve;
            this.image.onerror = reject;
        });
        this.numberOfLoggers = 0;
        this.canvas = document.getElementById('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.offsetX = this.ctx.left;
        this.offsetY = this.ctx.top;
        // Getting and apply percentage for creating responsive image
        let percentage = this.getPercentage(this.canvas.offsetWidth, this.image.width);
        let percentageForWidth = (this.image.width / 100) * percentage;
        let percentageForHeight = (this.image.height / 100) * percentage;
        this.canvas.width = this.image.width + percentageForWidth;
        this.canvas.height = this.image.height + percentageForHeight;
        this.image.setAttribute('crossOrigin', 'anonymous');
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
        let filename = document.getElementById('fileInput');
        this.imageFile = this.dataUrlToFile(this.image.src, filename.files[0].name);
        this.isUploading = true;
    }
    getPercentage(a, b) {
        return ((a - b) / b) * 100;
    }
    getAvailableSources() {
        return this.sources.filter((source) => !this.editedFloorPlanData.some((plan) => source.id === plan.entityId));
    }
    getAvailableDevices() {
        return this.devices.filter((device) => !this.editedFloorPlanData.some((plan) => device.deviceId === plan.entityId));
    }
    hasDuplicate() {
        return (this.editedFloorPlanData.map((v) => v.entityId).length >
            new Set(this.editedFloorPlanData.map((v) => v.entityId)).size);
    }
    selectEntity(entityId, index) {
        if (this.sources.find((x) => x.id == entityId)) {
            this.editedFloorPlanData[index].entity = FloorPlanEntities.SOURCE;
        }
        else {
            this.editedFloorPlanData[index].entity = FloorPlanEntities.GATEWAY;
        }
    }
    myMove(e) {
        if (this.isUploading || this.isUpdating) {
            // if we're dragging anything...
            if (this.dragok) {
                // tell the browser we're handling this mouse event
                e.preventDefault();
                e.stopPropagation();
                // get the current mouse position
                let pos = this.getCursorPosition(this.canvas, e);
                // calculate the distance the mouse has moved
                // since the last mousemove
                const dx = pos.x - this.startX;
                const dy = pos.y - this.startY;
                // move each rect that isDragging
                // by the distance the mouse has moved
                // since the last mousemove
                this.editedFloorPlanData.forEach((floorPlan) => {
                    if (floorPlan.isDragging) {
                        floorPlan.coordinates[0] += dx;
                        floorPlan.coordinates[1] += dy;
                    }
                });
                this.clear();
                this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
                // redraw the scene with the new rect positions
                this.editedFloorPlanData.forEach((x, i) => {
                    this.reDrawData(x.coordinates[0], x.coordinates[1], i);
                });
                // reset the starting mouse position for the next mousemove
                this.startX = pos.x;
                this.startY = pos.y;
            }
        }
    }
    // clear the canvas
    clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
    resetData() {
        this.resetFloorPlan();
        this.imageFile = null;
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.canvas.height = 0;
    }
    myDown(e) {
        if (this.isUploading || this.isUpdating) {
            // tell the browser we're handling this mouse event
            e.preventDefault();
            e.stopPropagation();
            // get the current mouse position
            let pos = this.getCursorPosition(this.canvas, e);
            // test each shape to see if mouse is inside
            this.dragok = false;
            this.editedFloorPlanData.forEach((floorPlan, i) => {
                let width = this.ctx.measureText(i.toString()).width;
                // test if the mouse is inside this rect
                if (!this.dragok &&
                    pos.x > floorPlan.coordinates[0] - width / 2 - 7 &&
                    pos.x < floorPlan.coordinates[0] + width + 10 &&
                    pos.y > floorPlan.coordinates[1] - 17 &&
                    pos.y < floorPlan.coordinates[1] + parseInt(this.ctx.font, 10)) {
                    // if yes, set that rects isDragging=true
                    this.dragok = true;
                    this.isDragging = true;
                    floorPlan.isDragging = true;
                }
            });
            // save the current mouse position
            this.startX = pos.x;
            this.startY = pos.y;
        }
    }
    myUp(e) {
        if (this.isUploading || this.isUpdating) {
            // tell the browser we're handling this mouse event
            e.preventDefault();
            e.stopPropagation();
            // clear all the dragging flags
            this.dragok = false;
            this.isDragging = this.editedFloorPlanData.find((x) => x.isDragging == true) ? true : false;
            this.editedFloorPlanData.forEach((floorPlan) => {
                floorPlan.isDragging = false;
            });
        }
    }
    draw(e) {
        if (this.isUploading || this.isUpdating) {
            if (!this.isDragging) {
                let pos = this.getCursorPosition(this.canvas, e);
                let clickX = pos.x;
                let clickY = pos.y;
                this.editedFloorPlanData.push({
                    entity: FloorPlanEntities.SOURCE,
                    entityId: '',
                    coordinates: [pos.x, pos.y],
                    isDragging: false
                });
                this.ctx.font = '15px Arial';
                let width = this.ctx.measureText(this.numberOfLoggers.toString()).width;
                this.ctx.fillStyle = '#be2528';
                this.ctx.beginPath();
                this.ctx.roundRect(clickX - width / 2 - 7, clickY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
                this.ctx.fill();
                this.ctx.fillStyle = 'white';
                this.ctx.textAlign = 'center';
                this.ctx.fillText(this.numberOfLoggers.toString(), clickX, clickY);
                this.numberOfLoggers += 1;
            }
        }
    }
    saveFloorPlan() {
        let combined = [];
        this.$validator.validateAll().then(async (result) => {
            if (result && this.editedFloorPlanData.length > 0) {
                if (this.hasDuplicate()) {
                    VueUtilities.openErrorToast(this, this.$t('component.floorPlan.duplicate'));
                }
                else {
                    for (let i = 0; i < this.editedFloorPlanData.length; i++) {
                        this.editedFloorPlanData[i].coordinates[0] = this.editedFloorPlanData[i].coordinates[0] / this.canvas.width;
                        this.editedFloorPlanData[i].coordinates[1] =
                            this.editedFloorPlanData[i].coordinates[1] / this.canvas.height;
                        combined.push({
                            Entity: this.editedFloorPlanData[i].entity,
                            SourceId: this.editedFloorPlanData[i].entity == FloorPlanEntities.SOURCE
                                ? this.editedFloorPlanData[i].entityId
                                : null,
                            EntityId: this.editedFloorPlanData[i].entityId,
                            Coordinates: this.editedFloorPlanData[i].coordinates
                        });
                    }
                    this.$emit('uploadFloorPlan', this.imageFile, combined);
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.error'));
            }
        });
    }
    updateFloorPlan() {
        let combined = [];
        this.$validator.validateAll().then(async (result) => {
            if (result && this.editedFloorPlanData.length > 0) {
                if (this.hasDuplicate()) {
                    VueUtilities.openErrorToast(this, this.$t('component.floorPlan.duplicate'));
                }
                else {
                    for (let i = 0; i < this.editedFloorPlanData.length; i++) {
                        this.editedFloorPlanData[i].coordinates[0] = this.editedFloorPlanData[i].coordinates[0] / this.canvas.width;
                        this.editedFloorPlanData[i].coordinates[1] =
                            this.editedFloorPlanData[i].coordinates[1] / this.canvas.height;
                        combined.push({
                            Entity: this.editedFloorPlanData[i].entity,
                            SourceId: this.editedFloorPlanData[i].entity == FloorPlanEntities.SOURCE
                                ? this.editedFloorPlanData[i].entityId
                                : null,
                            EntityId: this.editedFloorPlanData[i].entityId,
                            Coordinates: this.editedFloorPlanData[i].coordinates
                        });
                    }
                    this.$emit('updateFloorPlan', combined);
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.error'));
            }
        });
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        if (this.isUploading) {
            this.resetFloorPlan();
            this.imageFile = null;
        }
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FloorPlanUploadLayoutList, this.isLayoutList);
        this.loadData();
    }
    updatePlan() {
        this.isUpdating = !this.isUpdating;
        if (!this.isUpdating) {
            this.loadData();
        }
    }
    deleteFloorPlan() {
        this.isUpdating = false;
        this.$emit('deleteFloorPlan');
    }
    resetFloorPlan() {
        this.numberOfLoggers = 0;
        this.editedFloorPlanData = [];
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
    }
    removeLogger(index) {
        this.editedFloorPlanData.splice(index, 1);
        this.numberOfLoggers = this.editedFloorPlanData.length;
        this.clear();
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
        this.editedFloorPlanData.forEach((x, i) => {
            this.reDrawData(x.coordinates[0], x.coordinates[1], i);
        });
    }
    reDrawData(posX, posY, i) {
        this.ctx.font = '15px Arial';
        let width = this.ctx.measureText(i.toString()).width;
        this.ctx.fillStyle = '#be2528';
        this.ctx.beginPath();
        // this.ctx.roundRect(posX, posY, 20, 20, 4);
        this.ctx.roundRect(posX - width / 2 - 7, posY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
        this.ctx.fill();
        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'center';
        this.ctx.fillText(i.toString(), posX, posY);
    }
    drawData(posX, posY) {
        this.ctx.font = '15px Arial';
        let width = this.ctx.measureText(this.numberOfLoggers.toString()).width;
        this.ctx.fillStyle = '#be2528';
        this.ctx.beginPath();
        // this.ctx.roundRect(posX, posY, 20, 20, 4);
        this.ctx.roundRect(posX - width / 2 - 7, posY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
        this.ctx.fill();
        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'center';
        this.ctx.fillText(this.numberOfLoggers.toString(), posX, posY);
        this.numberOfLoggers += 1;
    }
    getCursorPosition(canvas, e) {
        // Gets click position
        let rect = canvas.getBoundingClientRect();
        return {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };
    }
    dataUrlToFile(dataUrl, filename) {
        const arr = dataUrl.split(',');
        if (arr.length < 2) {
            return undefined;
        }
        const mimeArr = arr[0].match(/:(.*?);/);
        if (!mimeArr || mimeArr.length < 2) {
            return undefined;
        }
        const mime = mimeArr[1];
        const buff = Buffer.from(arr[1], 'base64');
        return new File([buff], filename, { type: mime });
    }
    arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    getLoggerName(entityId) {
        return this.sources.find((x) => x.id == entityId).name;
    }
    getDeviceName(entityId) {
        return this.devices.find((x) => x.deviceId == entityId).deviceName;
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FloorPlanUpload.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], FloorPlanUpload.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanUpload.prototype, "floorPlanData", void 0);
__decorate([
    Prop({ default: null })
], FloorPlanUpload.prototype, "floorPlanImage", void 0);
__decorate([
    Prop({ type: String, default: '' })
], FloorPlanUpload.prototype, "imageType", void 0);
FloorPlanUpload = __decorate([
    Component({ components: { ImageUploader } })
], FloorPlanUpload);
export default FloorPlanUpload;

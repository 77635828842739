import User from '@/entities/User';
import FeedbackCategories from '../enums/FeedbackCategories';
import FeedbackStatus from '../enums/FeedbackStatus';
export default class AdminFeedback {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminFeedback.define(et));
        }
        else if (data != null) {
            return AdminFeedback.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let feedback = new AdminFeedback();
        feedback.id = data.Id;
        feedback.text = data.Text;
        feedback.subject = data.Subject;
        feedback.category = FeedbackCategories.getFeedbackCategoryByName(data.Category);
        feedback.createdAt = data.CreatedAt;
        feedback.status = FeedbackStatus.getFeedbackStatusByName(data.Status);
        if (data.SentBy)
            feedback.sentBy = User.fromApi(data.SentBy);
        return feedback;
    }
}

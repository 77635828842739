import NotificationSubscription from './NotificationSubscription';
export default class NotificationSchedule {
    constructor() {
        this.subscriptions = [];
        this.weekMinutes = [];
        this.additionalProperties = {
            isEditing: false,
            editedSubscriptions: [],
            isNew: null,
            color: null,
            selectedMeasuredRulesModeTimetable: null,
            originalSubscriptions: [],
            usedPreset: null
        };
    }
    /**
     * Determines if schedule has selected range of whole day
     * @returns true if whole day is selected
     */
    isAllDay(day) {
        let firstVal = day * 24;
        let lastVal = day * 24 + 24;
        let filteredHours = this.weekMinutes.filter((x) => x >= firstVal && x < lastVal);
        return filteredHours.length === 24;
    }
    getDateRange(dateTimeManager) {
        let fromDateString = dateTimeManager.formatTime(new Date(this.validFrom * 1000), null, null, '');
        let toDateString = dateTimeManager.formatTime(new Date(this.validTo * 1000), null, null, '');
        //is same date
        if (fromDateString === toDateString) {
            return fromDateString;
        }
        else {
            return fromDateString + ' - ' + toDateString;
        }
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => NotificationSchedule.define(et));
        }
        else if (data != null) {
            return NotificationSchedule.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let schedule = new NotificationSchedule();
        schedule.id = data.Id;
        schedule.name = data.Name;
        schedule.type = data.Type;
        schedule.validFrom = data.ValidFrom;
        schedule.validTo = data.ValidTo;
        schedule.weekMinutes = data.WeekMinutes;
        if (data.Subscriptions) {
            schedule.subscriptions = NotificationSubscription.fromApi(data.Subscriptions);
            schedule.additionalProperties.originalSubscriptions = NotificationSubscription.fromApi(data.Subscriptions);
        }
        return schedule;
    }
    /**
     * Compares array of subscriptions with array of subscriptionsDTO. Returns true if arrays are same
     * @param subscriptions : Array<NotificationSubscription>
     * @param subscriptionsDTO : Array<INotificationSubscriptionCreateDTO
     * @returns boolean (true if arrays are same)
     */
    static compareSchedules(originalSchedules, editedSchedules) {
        if (editedSchedules.find((x) => x.additionalProperties.isNew === true) != null) {
            return false;
        }
        else if (editedSchedules.length !== originalSchedules.length)
            return false;
        let hasDifferences = false;
        editedSchedules.forEach((schedule) => {
            let origSchedule = originalSchedules.find((x) => x.id === schedule.id);
            if (!origSchedule)
                hasDifferences = true;
            if (origSchedule.name != schedule.name)
                hasDifferences = true;
            if (JSON.stringify(origSchedule.weekMinutes || []) !== JSON.stringify(schedule.weekMinutes || []))
                hasDifferences = true;
            if (!NotificationSubscription.compareSubscriptionsWithSubscriptionsCreateDto(schedule.additionalProperties.originalSubscriptions, schedule.additionalProperties.editedSubscriptions)) {
                hasDifferences = true;
            }
        });
        if (hasDifferences) {
            return false;
        }
        else
            return true;
    }
}

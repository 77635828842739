import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DecimalConversion from '@/services/DecimalConversion';
let GroupListItemMobile = class GroupListItemMobile extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
    }
    async created() { }
};
__decorate([
    Prop({ type: Object })
], GroupListItemMobile.prototype, "data", void 0);
__decorate([
    Prop({ type: Object })
], GroupListItemMobile.prototype, "dateTimeManager", void 0);
GroupListItemMobile = __decorate([
    Component
], GroupListItemMobile);
export default GroupListItemMobile;

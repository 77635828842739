export class RegisteredLogger {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => RegisteredLogger.define(et));
        }
        else if (data != null) {
            return RegisteredLogger.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let logger = new RegisteredLogger();
        logger.loggerId = data.LoggerId;
        logger.macAddress = data.MacAddress;
        logger.rssi = data.Rssi;
        logger.registeredAt = data.RegisteredAt;
        return logger;
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let TierDetail = class TierDetail extends Vue {
};
__decorate([
    Prop({ type: Object })
], TierDetail.prototype, "tier", void 0);
__decorate([
    Prop({ type: Object })
], TierDetail.prototype, "dateTimeManager", void 0);
TierDetail = __decorate([
    Component({ components: {} })
], TierDetail);
export default TierDetail;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SourceGroupPicker from '@/views/components/widgets/SourceGroupPicker.vue';
import ReportsOverallChart from '@/views/components/reports/ReportsOverallChart.vue';
import ExportPdfModal from '@/views/components/reports/ExportPdfModal.vue';
import ReportConfigurationComponent from '@/views/components/reports/ReportConfigurationComponent.vue';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import { SourceGroup } from '@/entities/models/SourceGroup';
import DateTimeManager from '@/services/DateTimeManager';
import EventType from '@/entities/EventType';
import SourceTabs from '@/views/components/reports/SourceTabs.vue';
import ReportsList from '@/views/components/reports/ReportsList.vue';
import { Source } from '@/entities/models/Source';
import VueUtilities from '@/services/VueUtilities';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import UserPreferences from '@/services/UserPreferences';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsValues } from '../../entities/UserSettings';
import { Report } from '@/entities/models/Report';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import AppConfig from '@/configLoader';
import UserRepository from '@/services/repository/UserRepository';
import { ReportGenerationStatus } from '@/entities/enums/ReportGenerationStatus';
import ApiQueriesKeys from '@/entities/enums/ApiQueriesKeys';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import { vxm } from '@/store/store.vuex';
import ExportCsvModal from '@/views/components/reports/ExportCsvModal.vue';
import { PdfReportTypes } from '@/entities/enums/ReportTypes';
var vxDepartmentStore = vxm.departmentStore;
var sourceGroupRepository;
var reportRecordRepository;
var eventTypeRepository;
var sourceRepository;
var userSettingsRepository;
var userRepository;
const HAS_ANNOTATIONS = true;
let Reports = 
/*
On this page can user view and generate reports calculated from raw data on server.
*/
class Reports extends Vue {
    constructor() {
        super(...arguments);
        //Loading handlers and flags
        this.isLoading = true;
        this.isFileDownloading = false;
        this.hasSources = true;
        this.isPdfModalActive = false;
        this.isCsvModalActive = false;
        this.axisRangesLoaded = false;
        this.filterRecords = false;
        //data
        this.sourceGroups = [];
        this.selectedSourceGroupId = null;
        this.sources = [];
        this.sourceGroupSources = [];
        this.sourceGroupStatistics = {};
        this.reportRecordsForChart = {};
        this.reportRecordsForTable = {};
        this.eventTypes = [];
        this.favoriteGroupId = null;
        this.axisRanges = [];
        this.annotationsForSource = [];
        this.reports = [];
        this.configuration = null;
        this.userSettings = null;
        this.departmentUserSettings = null;
        //helper objects
        this.dateTimeManager = null;
        this.monthFilter = null;
        this.dateRange = [];
        this.showEmpty = true;
        this.SELECTED_DEPARTMENT_ID = null;
        this.SELECTED_GROUP_ID = null;
        this.chartHeight = null;
        this.reportExportSetting = null;
    }
    get isReportSettingsAllowed() {
        return AppConfig.getConfig().features.email_report_settings === true;
    }
    get unassignedSources() {
        return Source.getUnassignedSources(this.sources);
    }
    urlIdExists(id) {
        return !(this.sourceGroups.find((x) => x.id === id) || id === 'A' || id === 'U');
    }
    selectGroupFromRoute() {
        if (this.$router.currentRoute.query.groupId) {
            // check if url id exists
            if (this.urlIdExists(this.$router.currentRoute.query.groupId)) {
                VueUtilities.openErrorToast(this, this.$t('error_messages.invalid_group_id').toString());
                let selectedGroupId = this.findGroupToSelect();
                this.$router.replace({
                    name: this.$route.name,
                    query: { groupId: selectedGroupId },
                    params: {
                        lang: this.$route.params.lang,
                        departmentId: this.$route.params.departmentId
                    }
                });
                return selectedGroupId;
            }
            return this.$router.currentRoute.query.groupId;
        }
        return this.findGroupToSelect();
    }
    async created() {
        //Init repositories and managers
        sourceGroupRepository = new SourceGroupRepository(this);
        reportRecordRepository = new ReportRecordRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        sourceRepository = new SourceRepository(this);
        userRepository = new UserRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.preload();
    }
    /*Close websocket if exists */
    async beforeDestroy() {
        if (this.connection) {
            await this.connection.stop();
        }
    }
    loadInitDateRange() {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setHours(0, 0, 0, 0);
        yesterday.setDate(today.getDate() - 1);
        return [yesterday, today];
    }
    preload() {
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        //read TS from route
        try {
            let givenTS = this.$route.query.dateRange;
            let dateRange = JSON.parse(givenTS);
            if (dateRange.find((date) => date && !isNaN(date))) {
                this.dateRange = [new Date(dateRange[0]), new Date(dateRange[1])];
            }
            else {
                this.dateRange = this.loadInitDateRange();
            }
        }
        catch (error) {
            this.dateRange = this.loadInitDateRange();
        }
        this.monthSelected(this.dateRange);
        this.loadDataForPage();
        this.openSocket();
    }
    /*
  Load user settings and then load initial data for this page. When there are any sources, app loads additional data.
  */
    async loadDataForPage() {
        this.isLoading = true;
        this.currentUser = await userRepository.getCurrentUser();
        this.departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, true);
        this.configuration = this.departmentUserSettings.reports.configuration;
        this.filterRecords = this.departmentUserSettings.reports.records.filterByBoundary == 'true' ? true : false;
        let initialPromises = [];
        initialPromises.push(this.loadSourceGroups());
        initialPromises.push(this.loadSources());
        initialPromises.push(this.loadEventTypes());
        initialPromises.push(reportRecordRepository.getReportRequests());
        initialPromises.push(userSettingsRepository.loadUserSettings(true));
        initialPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId));
        initialPromises.push(userSettingsRepository.loadUserSettings());
        await Promise.all(initialPromises).then((response) => {
            this.processInitialDataForPage(response[0], response[1], response[2], response[3], response[4], response[5]);
        });
        if (this.sources.length > 0)
            await this.loadAdditionalData();
        else {
            this.hasSources = false;
            this.isLoading = false;
        }
    }
    /**
     * Process loaded data.
     */
    async processInitialDataForPage(sourceGroups, sources, eventTypes, reports, settings, departmentUserSettings) {
        this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(sourceGroups.getData(), sources.getData());
        //TODO: Filter uptime, will be removed
        this.sourceGroups.forEach((sg) => {
            sg.sources = sg.sources.filter((x) => x.logger == null || x.logger.availableTypes.find((x) => x.id == EventType.UPTIME_ID) == null);
        });
        this.sources = sources
            .getData()
            .filter((x) => x.logger == null || x.logger.availableTypes.find((x) => x.id == EventType.UPTIME_ID) == null);
        this.eventTypes = eventTypes;
        this.userSettings = settings;
        this.chartHeight = settings.preferences.chartHeight;
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        this.selectedSourceGroupId = this.selectGroupFromRoute();
        this.SELECTED_GROUP_ID = this.selectedSourceGroupId;
        this.sourceGroupSources = this.getSourcesForSelectedGroup(this.selectedSourceGroupId, this.sources);
        //TODO: Filter uptime, will be removed
        this.sourceGroupSources = this.sourceGroupSources.filter((x) => x.logger == null || x.logger.availableTypes.find((x) => x.id == EventType.UPTIME_ID) == null);
        Source.sortByName(this.sourceGroupSources);
        this.reports = reports;
    }
    /**
     * Load additional data from server (records and statistics)
     * TODO: Remove duplicit call for loaded data if user sets filter flag to true.
     * This can be achieved by adding measurement state fo every record.
     */
    async loadAdditionalData() {
        let additionalPromises = [];
        additionalPromises.push(this.loadReportRecords(this.sourceGroupSources.map((x) => x.id), this.monthFilter.start, this.monthFilter.stop, false, this.showEmpty));
        additionalPromises.push(this.loadStatistics(this.sourceGroupSources.map((x) => x.id), this.monthFilter.start, this.monthFilter.stop));
        if (this.filterRecords === true) {
            additionalPromises.push(this.loadReportRecords(this.sourceGroupSources.map((x) => x.id), this.monthFilter.start, this.monthFilter.stop, this.filterRecords, this.showEmpty));
        }
        await Promise.all(additionalPromises).then((response) => {
            this.processAdditionalDataForPage(response[0], response[1], response[2]);
        });
    }
    processAdditionalDataForPage(reportRecords, statistics, filteredReportRecords) {
        this.reportRecordsForChart = reportRecords;
        this.reportRecordsForTable = this.filterRecords === true ? filteredReportRecords : reportRecords;
        this.sourceGroupStatistics = statistics;
        this.isLoading = false;
    }
    async changeFilterRecords(filter) {
        this.isLoading = true;
        let depUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.REPORTS, UserSettingsKeys.RECORDS, UserSettingsKeys.FILTER_BY_BOUNDARY], filter);
        this.filterRecords = depUserSettings.reports.records.filterByBoundary == 'true' ? true : false;
        //remove page from route
        let queries = JSON.parse(JSON.stringify(this.$route.query));
        delete queries[ApiQueriesKeys.PAGE];
        this.$router
            .replace({
            query: Object.assign({}, queries),
            params: this.$route.params
        })
            .catch((err) => { });
        this.loadAdditionalData();
    }
    async changeUserSetting(option) {
        this.chartHeight = option;
        await userSettingsRepository.saveUserSetting([UserSettingsKeys.PREFERENCES, UserSettingsKeys.CHARTHEIGHT], option);
    }
    async reloadRecords() {
        this.isLoading = true;
        this.reportRecordsForTable = await this.loadReportRecords(this.sourceGroupSources.map((x) => x.id), this.monthFilter.start, this.monthFilter.stop, this.filterRecords, this.showEmpty);
        this.isLoading = false;
    }
    findGroupToSelect() {
        // if already selected
        if (this.selectedSourceGroupId && this.selectedSourceGroupId.length > 0) {
            return this.selectedSourceGroupId;
        }
        // sourceGroups.length == 0
        if (this.sourceGroups.length <= 0) {
            return this.unassignedSources.length > 0
                ? UserSettingsValues.UNASSIGNED_GROUP_ID
                : UserSettingsValues.ALL_GROUP_ID;
        }
        // sourceGroups.length > 0
        switch (this.favoriteGroupId) {
            case UserSettingsValues.UNASSIGNED_GROUP_ID:
                return UserSettingsValues.UNASSIGNED_GROUP_ID;
            case UserSettingsValues.ALL_GROUP_ID:
                return UserSettingsValues.ALL_GROUP_ID;
            case '' || null:
                return this.sourceGroups[0].id;
            default:
                if (this.sourceGroups.find((x) => x.id === this.favoriteGroupId)) {
                    return this.favoriteGroupId;
                }
                else {
                    return this.sourceGroups[0].id;
                }
        }
    }
    getSourcesForSelectedGroup(groupId, sources) {
        return SourceGroup.assignSourcesForSelectedGroup(groupId, sources);
    }
    async loadSources() {
        return sourceRepository.getVisibleSources();
    }
    async loadEventTypes() {
        return eventTypeRepository.getAllTypes();
    }
    async loadReportRecords(sourceIds, start, stop, alarmOnly, showEmpty) {
        return reportRecordRepository.getReportRecordsForSources(sourceIds, start, stop, HAS_ANNOTATIONS, alarmOnly, showEmpty, this.configuration.getModel().model, this.configuration.interval, this.configuration.getOffsetForConfiguration(), this.dateTimeManager.getSelectedTimezone());
    }
    async loadStatistics(sourceIds, start, stop) {
        return reportRecordRepository.getStatisticForSources(sourceIds, start, stop, this.configuration.getModel().model, this.configuration.interval, this.configuration.getOffsetForConfiguration(), this.dateTimeManager.getSelectedTimezone());
    }
    async loadAnnotationsForSource(sourceId) {
        return reportRecordRepository.getAnnotationsForSource(sourceId, true, this.monthFilter.stop, null, null, this.monthFilter.start, this.configuration.getModel().model, this.configuration.interval, this.configuration.getOffsetForConfiguration(), this.dateTimeManager.getSelectedTimezone());
    }
    async loadSourceGroups() {
        return sourceGroupRepository.getSourceGroups();
    }
    /**
     * Load data for selected source group
     */
    async sourceGroupSelected(sourceGroupId) {
        UserPreferences.deletePreference(UserPreferences.LocalStorageKeys.AnnotationTablePage);
        this.isLoading = true;
        this.selectedSourceGroupId = sourceGroupId;
        this.sourceGroupSources = this.getSourcesForSelectedGroup(this.selectedSourceGroupId, this.sources);
        this.axisRanges = [];
        this.axisRangesLoaded = false;
    }
    async favoriteSelected(id) {
        this.isLoading = true;
        let idToStore = this.favoriteGroupId == id ? '' : id;
        let departmentUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.PREFERENCES, UserSettingsKeys.FAVORITE_GROUP_ID], idToStore);
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        let msg = idToStore != ''
            ? this.$t('component.source_group_picker.selected_as_favorite')
            : this.$t('component.source_group_picker.favorite_removed');
        VueUtilities.openSuccessToast(this, msg);
        this.isLoading = false;
    }
    monthSelected(dateRange) {
        this.axisRanges = [];
        this.axisRangesLoaded = false;
        let convertedDateRange = this.dateTimeManager.formatDateRange(dateRange);
        this.monthFilter = {
            start: this.dateTimeManager.formatStartDateForReports(convertedDateRange[0]).text,
            stop: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).text,
            startTS: this.dateTimeManager.formatStartDateForReports(convertedDateRange[0]).ts,
            stopTS: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).ts
        };
    }
    axisRangeSelected(axisRanges) {
        this.axisRanges = axisRanges;
        this.axisRangesLoaded = true;
    }
    openCsvModal() {
        this.isCsvModalActive = true;
    }
    async closeCsvModal() {
        this.isCsvModalActive = false;
    }
    openPdfModal() {
        this.isPdfModalActive = true;
    }
    async closePdfModal(result) {
        this.isPdfModalActive = false;
    }
    storeExportSetting(reportExportSetting) {
        this.reportExportSetting = reportExportSetting;
        this.scrollToReportsTable();
    }
    scrollToReportsTable() {
        //Scroll to pdf reports table
        this.$el.querySelector('[id=ReportsList]').scrollIntoView();
    }
    /*Open websocket for PDF status */
    openSocket() {
        if (!this.connection)
            this.listenForReportUpdate();
    }
    /**
     * Listen for update of PDF status
     */
    listenForReportUpdate() {
        //Security
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        //open connection
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.Reports.StatusSocket}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.currentUser.apiUserId);
        })
            .catch((error) => console.log(error));
        this.connection.on('SendCoreAsync', (data) => {
            console.log(data);
        });
        /*On report status update */
        this.connection.on('ReportStateChanged', (data) => {
            let report = Report.define(data); //parse data as report object
            let reportInList = this.reports.findIndex((x) => x.id === report.id);
            //check if report with given ID already exists
            if (reportInList >= 0) {
                // if exists, remove it from array
                this.reports.splice(reportInList, 1);
            }
            if (report.state == ReportGenerationStatus.DONE) {
                report.isUpdated = true; // set animation trigger
                //Open toast
                this.$buefy.toast.open({
                    message: `${this.$t('reports.report_created', {
                        name: report.fileName
                    })}`,
                    type: 'is-success'
                });
            }
            this.reports.push(report); //add report to array
        });
    }
    async sourceSelected(sourceId) {
        this.annotationsForSource = await this.loadAnnotationsForSource(sourceId);
    }
    async downloadReport(filename) {
        document.body.style.cursor = 'wait';
        let res = await reportRecordRepository.downloadPdfReport(filename);
        if (!res) {
            VueUtilities.openErrorToast(this, this.$t('error_messages.file_download_failed'));
        }
        document.body.style.cursor = 'auto';
    }
    async regenerateReport(report) {
        let res = null;
        console.log(report);
        if (report.reportType === PdfReportTypes.ONE_PAGE) {
            let config = {
                from: this.dateTimeManager.getTimeStringForInputDate(new Date(report.from * 1000)).replace('Z', ''),
                to: this.dateTimeManager.getTimeStringForInputDate(new Date(report.to * 1000)).replace('Z', ''),
                sourceIds: JSON.parse(report.metadata.sourceIds),
                models: report.metadata.models,
                offset: report.metadata.offset,
                crop: JSON.parse(report.metadata.services).map(function (x) {
                    let o = {
                        serviceId: x.ServiceId,
                        min: x.CropMin,
                        max: x.CropMax
                    };
                    return o;
                }),
                interval: report.metadata.interval,
                timezone: report.metadata.timezone,
                showEmpty: report.metadata.toggles.showEmpty,
                types: report.metadata.types.map((x) => x.Name.toLowerCase())
            };
            res = await reportRecordRepository.createOnePagePdfReport(config);
        }
        else {
            let config = {
                from: this.dateTimeManager.getTimeStringForInputDate(new Date(report.from * 1000)).replace('Z', ''),
                to: this.dateTimeManager.getTimeStringForInputDate(new Date(report.to * 1000)).replace('Z', ''),
                sourceIds: JSON.parse(report.metadata.sourceIds),
                showChart: report.metadata.toggles.showChart,
                showAnnotationTable: report.metadata.toggles.showAnnotationsTable,
                showRecordsTable: report.metadata.toggles.showRecordsTable,
                alarmOnly: report.metadata.toggles.alarmOnlyRecords,
                models: report.metadata.models,
                crop: JSON.parse(report.metadata.services).map(function (x) {
                    let o = {
                        serviceId: x.ServiceId,
                        min: x.CropMin,
                        max: x.CropMax
                    };
                    return o;
                }),
                offset: report.metadata.offset,
                interval: report.metadata.interval,
                showEmpty: report.metadata.toggles.showEmpty,
                types: report.metadata.types.map((x) => x.Name.toLowerCase()),
                timezone: report.metadata.timezone
            };
            res = await reportRecordRepository.createPdfReport(config);
        }
        if (!res) {
            VueUtilities.openErrorToast(this, this.$t('error_messages.file_download_failed'));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('reports.report_requested'));
            await reportRecordRepository.deletePdfReport(report.id);
            this.reports = await reportRecordRepository.getReportRequests();
        }
    }
    async removeReport(id) {
        let res = await reportRecordRepository.deletePdfReport(id);
        if (!res) {
            VueUtilities.openErrorToast(this, this.$t('error_messages.file_delete_failed'));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('reports.report_deleted'));
            this.reports = await reportRecordRepository.getReportRequests();
        }
    }
    async removeReports(removeReports) {
        this.isLoading = true;
        let reportDeletepromises = new Array();
        removeReports.forEach((report) => {
            reportDeletepromises.push(reportRecordRepository.deletePdfReport(report.id));
        });
        await Promise.all(reportDeletepromises).then(async (response) => {
            if (response.find((x) => x !== true)) {
                VueUtilities.openErrorToast(this, this.$t('reports.reports_remove_dialog.failure'));
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('reports.reports_remove_dialog.success'));
            }
            this.reports = await reportRecordRepository.getReportRequests();
            this.isLoading = false;
        });
    }
    /**
     * This method is triggered when user changes the report configuration.
     * New data are loaded when configuration change is submitted.
     * When user changes date of records, this change is saved in DB and new timestamp is inserted to route.
     */
    async applyConfiguration(configuration, dateRange, showEmpty) {
        this.isLoading = true;
        this.showEmpty = showEmpty;
        this.dateRange = dateRange;
        this.monthSelected(this.dateRange);
        let serializedConfiguration = JSON.stringify(configuration);
        let depUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.REPORTS, UserSettingsKeys.CONFIGURATION], serializedConfiguration);
        this.configuration = depUserSettings.reports.configuration;
        this.updateDateRouteQuery(this.dateRange);
        if (this.hasSources)
            await this.loadAdditionalData();
        else
            this.isLoading = false;
    }
    updateDateRouteQuery(dateRange) {
        let dateRangeTs = [dateRange[0].getTime(), dateRange[1].getTime()];
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, { dateRange: JSON.stringify(dateRangeTs) }),
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
Reports = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.SOURCE) &&
                    (vm.$ability.can(Actions.READ, Subjects.SOURCE_GROUP) || vm.$ability.can(Actions.READ, Subjects.REPORTS))) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            UserPreferences.deletePreference(UserPreferences.LocalStorageKeys.AnnotationTablePage);
            next();
        },
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_GROUP_ID !== this.selectedSourceGroupId ||
                this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartmentId()) {
                this.selectedSourceGroupId = '';
                this.hasSources = true;
                this.preload();
                next(); // to resolve hook
            }
        },
        components: {
            ExportCsvModal,
            SourceGroupPicker,
            ReportsOverallChart,
            SourceTabs,
            ExportPdfModal,
            ReportsList,
            ReportConfigurationComponent
        }
    })
    /*
    On this page can user view and generate reports calculated from raw data on server.
    */
], Reports);
export default Reports;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
import DateTimeManager from '@/services/DateTimeManager';
import UserPreferences from '@/services/UserPreferences';
var deviceRepository;
let DeviceUpdateModal = class DeviceUpdateModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.selectedFirmware = null;
        this.dateTimeManager = null;
        this.sortDirection = UserPreferences.LocalStorageKeys.DeviceUpdateModalSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.DeviceUpdateModalSortField.defaultValue;
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
    }
    scheduleUpdate() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.selectedFirmware) {
                    this.isLoading = true;
                    let data = {
                        FirmwareId: this.selectedFirmware.id
                    };
                    let result = await deviceRepository.adminDeviceFirmwareUpdate(this.device.deviceId, data);
                    this.isLoading = false;
                    if (result === true) {
                        VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.ota_update.success'));
                        this.resultRetrieved(true);
                        this.$emit('reload');
                    }
                    else {
                        VueUtilities.openErrorToast(this, this.$t('admin.component.devices.ota_update.failure'));
                    }
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.ota_update.selectFirmware'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], DeviceUpdateModal.prototype, "device", void 0);
__decorate([
    Prop({ type: Array })
], DeviceUpdateModal.prototype, "deviceFirmwareList", void 0);
DeviceUpdateModal = __decorate([
    Component({})
], DeviceUpdateModal);
export default DeviceUpdateModal;

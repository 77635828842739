var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.statistics.filter(function (x) {
      return x.count > 0
    }).length > 0
      ? _c("div", { staticClass: "columns" }, [
          _vm.statistics.find(function (x) {
            return x.overTime > 0
          })
            ? _c("div", { staticClass: "column has-text-centered px-1" }, [
                _c("p", { staticClass: "has-text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.report.record_table.statistics_table.over_boundary"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "columns is-gapless" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.record_table.statistics_table.max"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._l(_vm.statistics, function (statistic, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            _vm._s(statistic.max) + _vm._s(statistic.unit)
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.record_table.statistics_table.duration"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._l(_vm.statistics, function (statistic, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DateTimeManager.timestampToDuration(
                                  statistic.overTime
                                )
                              ) +
                              " "
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.statistics.find(function (x) {
            return x.overTime > 0
          })
            ? _c("div", { staticClass: "column vertical-line px-1" }, [
                _c("div", { staticClass: "line" }),
              ])
            : _vm._e(),
          _vm.statistics.find(function (x) {
            return x.belowTime > 0
          })
            ? _c("div", { staticClass: "column has-text-centered px-1" }, [
                _c("p", { staticClass: "has-text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.report.record_table.statistics_table.below_boundary"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "columns is-gapless" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.record_table.statistics_table.max"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._l(_vm.statistics, function (statistic, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            _vm._s(statistic.min) + _vm._s(statistic.unit)
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.record_table.statistics_table.duration"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._l(_vm.statistics, function (statistic, index) {
                        return _c(
                          "p",
                          { key: index, staticClass: "text-no-wrap" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.DateTimeManager.timestampToDuration(
                                    statistic.belowTime
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.statistics.find(function (x) {
            return x.belowTime > 0
          })
            ? _c("div", { staticClass: "column vertical-line px-1" }, [
                _c("div", { staticClass: "line" }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "column has-text-centered px-1" }, [
            _c("p", { staticClass: "has-text-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "component.report.record_table.statistics_table.summary"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "columns is-gapless" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("p", { staticClass: "has-text-weight-semibold" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.report.record_table.statistics_table.avg"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm._l(_vm.statistics, function (statistic, index) {
                    return _c("p", { key: index }, [
                      _vm._v(_vm._s(statistic.avg) + _vm._s(statistic.unit)),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("p", { staticClass: "has-text-weight-semibold" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.report.record_table.statistics_table.service"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm._l(_vm.statistics, function (statistic, index) {
                    return _c(
                      "p",
                      { key: index, staticClass: "text-no-wrap" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("services." + statistic.service)) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import ApiLinks from './ApiLinks';
export class ApiQueries {
    constructor() {
        this.sourcesArray = [];
    }
    addPagination(pagination) {
        if (!pagination) {
            this.page = 0;
            this.pageSize = 10000;
        }
        else {
            this.page = pagination.page;
            this.pageSize = pagination.pageSize;
        }
    }
    addSorts(sort) {
        this.sorts = [sort.direction + sort.field].join(',');
    }
    addFilters(filters) {
        this.filters = filters.join(',');
    }
    hasQueries() {
        if (this.sources ||
            this.timeFrom ||
            this.timeTo ||
            this.page ||
            this.pageSize ||
            this.sorts ||
            this.annotations ||
            this.name ||
            this.companyLoggerId ||
            this.companyId ||
            this.serialNumber ||
            this.from ||
            this.to ||
            this.duration ||
            this.deviceId ||
            this.calibrationExpiresBefore ||
            this.tz ||
            this.since ||
            this.alarmOnly ||
            this.showEmpty ||
            this.showChart ||
            this.showAnnotationTable ||
            this.showRecordsTable ||
            this.includeStatistics ||
            this.startAfter ||
            this.startBefore ||
            this.endAfter ||
            this.endBefore ||
            this.includeSystemNotifications ||
            this.filters ||
            this.sourcesArray.length > 0 ||
            this.sourceId ||
            this.all ||
            this.departmentId ||
            this.timezone ||
            this.scheduleId)
            return true;
        else
            return false;
    }
    toQueryString() {
        let params = [];
        if (this.page)
            params.push(`${ApiLinks.Queries.Page}=${this.page}`);
        if (this.pageSize)
            params.push(`${ApiLinks.Queries.PageSize}=${this.pageSize}`);
        if (this.sources)
            params.push(`${ApiLinks.Queries.Sources}=${this.sources}`);
        if (this.timeFrom)
            params.push(`${ApiLinks.Queries.Time.From}=${this.timeFrom}`);
        if (this.timeTo)
            params.push(`${ApiLinks.Queries.Time.To}=${this.timeTo}`);
        if (this.sorts)
            params.push(`${ApiLinks.Queries.Sorts}=${this.sorts}`);
        if (this.annotations)
            params.push(`${ApiLinks.Queries.Annotations}=${this.annotations}`);
        if (this.name)
            params.push(`${ApiLinks.Queries.Name}=${this.name}`);
        if (this.companyLoggerId)
            params.push(`${ApiLinks.Queries.CompanyLoggerId}=${this.companyLoggerId}`);
        if (this.companyId)
            params.push(`${ApiLinks.Queries.CompanyId}=${this.companyId}`);
        if (this.departmentId)
            params.push(`${ApiLinks.Queries.DepartmentId}=${this.departmentId}`);
        if (this.serialNumber)
            params.push(`${ApiLinks.Queries.SerialNumber}=${this.serialNumber}`);
        if (this.token)
            params.push(`${ApiLinks.Queries.Token}=${this.token}`);
        if (this.from)
            params.push(`${ApiLinks.Queries.From}=${this.from}`);
        if (this.to)
            params.push(`${ApiLinks.Queries.To}=${this.to}`);
        if (this.duration)
            params.push(`${ApiLinks.Queries.Duration}=${this.duration}`);
        if (this.deviceId)
            params.push(`${ApiLinks.Queries.DeviceId}=${this.deviceId}`);
        if (this.calibrationExpiresBefore)
            params.push(`${ApiLinks.Queries.CalibrationExpiresBefore}=${this.calibrationExpiresBefore}`);
        if (this.tz)
            params.push(`${ApiLinks.Queries.Tz}=${this.tz}`);
        if (this.since)
            params.push(`${ApiLinks.Queries.Since}=${this.since}`);
        if (this.alarmOnly)
            params.push(`${ApiLinks.Queries.AlarmOnly}=${this.alarmOnly}`);
        if (this.showEmpty === true || this.showEmpty === false)
            params.push(`${ApiLinks.Queries.ShowEmpty}=${this.showEmpty}`);
        if (this.showChart)
            params.push(`${ApiLinks.Queries.ShowChart}=${this.showChart}`);
        if (this.showAnnotationTable)
            params.push(`${ApiLinks.Queries.ShowAnnotationTable}=${this.showAnnotationTable}`);
        if (this.showRecordsTable)
            params.push(`${ApiLinks.Queries.ShowRecordsTable}=${this.showRecordsTable}`);
        if (this.includeStatistics)
            params.push(`${ApiLinks.Queries.IncludeStatistics}=${this.includeStatistics}`);
        if (this.startBefore)
            params.push(`${ApiLinks.Queries.StartBefore}=${this.startBefore}`);
        if (this.startAfter)
            params.push(`${ApiLinks.Queries.StartAfter}=${this.startAfter}`);
        if (this.endBefore)
            params.push(`${ApiLinks.Queries.EndBefore}=${this.endBefore}`);
        if (this.endAfter)
            params.push(`${ApiLinks.Queries.EndAfter}=${this.endAfter}`);
        if (this.includeSystemNotifications)
            params.push(`${ApiLinks.Queries.IncludeSystemNotifications}=${this.includeSystemNotifications}`);
        if (this.filters)
            params.push(`${ApiLinks.Queries.Filters}=${encodeURIComponent(this.filters)}`);
        if (this.sourceId)
            params.push(`${ApiLinks.Queries.SourceId}=${this.sourceId}`);
        if (this.all)
            params.push(`${ApiLinks.Queries.All}=${this.all}`);
        if (this.sourcesArray.length > 0) {
            this.sourcesArray.forEach((id) => {
                params.push(`${ApiLinks.Queries.SourcesArray}=${id}`);
            });
        }
        if (this.note)
            params.push(`${ApiLinks.Queries.Note}=${this.note}`);
        if (this.model)
            params.push(`${ApiLinks.Queries.Model}=${this.model}`);
        if (this.interval)
            params.push(`${ApiLinks.Queries.Interval}=${this.interval}`);
        if (this.offset)
            params.push(`${ApiLinks.Queries.Offset}=${this.offset}`);
        if (this.timezone)
            params.push(`${ApiLinks.Queries.Timezone}=${this.timezone}`);
        if (this.scheduleId)
            params.push(`${ApiLinks.Queries.ScheduleId}=${this.scheduleId}`);
        return params.join('&');
    }
}

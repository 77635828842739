import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiStatus from '@/entities/enums/apiStatuses';
import FileDownloader from '../FileDownloader';
import AdminFeedback from '@/entities/models/AdminFeedback';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiResponse from '@/entities/ApiResponse';
const DEFAULT_MAILING_LIST_FILENAME = 'mailingList.csv';
export default class SalesRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getMailingList(pagination = null, sort = null, filter = []) {
        if (!this.ctx.$ability.can(Actions.MAILINGLIST, Subjects.ADMIN_USERS)) {
            return null;
        }
        let url = `${ApiLinks.Users.AdminHome}${ApiLinks.AdminSales.MailingList}`;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.downloadFile_GET(url, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS) {
            FileDownloader.downloadFile(response, DEFAULT_MAILING_LIST_FILENAME);
            return true;
        }
        else {
            return false;
        }
    }
    async getFeedbackList(pagination = null, sort = null, filter = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_FEEDBACK)) {
            return parsedResponse;
        }
        let url = ApiLinks.AdminSales.Feedback;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(AdminFeedback.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async getFeedbackById(id) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_FEEDBACK)) {
            throw 'Forbidden action getFeedbackById';
        }
        let url = ApiLinks.AdminSales.Feedback + '/' + id;
        let response = await AxiosService.getDataFromServer(url, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS) {
            return AdminFeedback.fromApi(response.data.data);
        }
        else {
            return null;
        }
    }
    async updateFeedback(id, dto) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_FEEDBACK)) {
            throw 'Forbidden action updateFeedback';
        }
        let url = ApiLinks.AdminSales.Feedback + '/' + id;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx, false);
        return response.status === ApiStatus.SUCCESS;
    }
    async sendFeedback(data) {
        let url = ApiLinks.Sales.Feedback;
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
}

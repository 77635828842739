import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import AuthenticationCalls, { isLoginData } from '@/services/AuthenticationCalls';
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import { vxm } from '@/store/store.vuex';
import UserRepository from '@/services/repository/UserRepository';
var userSettingsRepository;
var authenticationCalls;
var departmentRepository;
var vxDepartmentStore = vxm.departmentStore;
var userRepository;
let DemoLogin = class DemoLogin extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDepartmentId = null;
        this.isLoading = false;
    }
    async created() {
        this.isLoading = true;
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        userRepository = new UserRepository(this);
        await this.submitDemoCredentialsAsync();
        this.isLoading = false;
    }
    async submitDemoCredentialsAsync() {
        let loginData = await authenticationCalls.login(AppConfig.getConfig().demo.name, AppConfig.getConfig().demo.pswd);
        if (isLoginData(loginData)) {
            const sessionPassword = loginData.session;
            const apiUserFullName = loginData.apiUserFullName;
            const authName = loginData.apiUserUsername;
            LoggedUserManager.saveLoginData(this, authName, sessionPassword, apiUserFullName);
            this.loginSucessfulAsync();
        }
        else {
            let loginFailedResponse = loginData;
            if (loginFailedResponse.code) {
                switch (loginFailedResponse.code) {
                    case ApiResponseCodes.UNAUTHORIZED:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.unauthorized').toString()}`);
                        break;
                    case ApiResponseCodes.WRONG_CREDENTIALS:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.wrong_credentials').toString()}`);
                        break;
                    case ApiResponseCodes.ACCOUNT_DISABLED:
                        let reasonParts = loginFailedResponse.message.split(':');
                        reasonParts.shift();
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_disabled', {
                            reason: reasonParts.join(':')
                        }).toString()}`);
                        break;
                    case ApiResponseCodes.ACCOUNT_NOT_VERIFIED:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_not_verified')}`);
                        break;
                    default:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                        break;
                }
            }
            else {
                VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
            }
        }
    }
    setDepartments(value) {
        vxDepartmentStore.departments = value;
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
    getSelectedDepartmentPermissions() {
        return vxDepartmentStore.selectedDepartmentPermissions;
    }
    /**
     * Function which initializes selected and favorite department id in store
     * If user has favorite deparment in preferences, it's also his currently selected
     * If user hasn't favorite department, his currently selected department is first from list of departments
     * @param departments
     * @param favoriteDepartmentId
     * @returns currently selected department
     */
    setSelectedDepartment(departments, favoriteDepartmentId) {
        if (favoriteDepartmentId) {
            vxDepartmentStore.setSelectedDepartmentById(favoriteDepartmentId);
            vxDepartmentStore.setFavoriteDepartmentById(favoriteDepartmentId);
        }
        else {
            vxDepartmentStore.selectedDepartment = departments[0];
        }
        return this.getSelectedDepartment();
    }
    async loginSucessfulAsync() {
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        userSettingsRepository = UserSettingsRepository.getInstance(this, true);
        departmentRepository = new DepartmentRepository(this);
        let settings = await userSettingsRepository.loadUserSettings(true);
        let departments = (await departmentRepository.getDepartments()).getData().getData();
        this.setDepartments(departments);
        // load selected and favorite department to store
        let preselectedDepartment = this.setSelectedDepartment(departments, settings.preferences.favoriteDepartmentId);
        vxDepartmentStore.selectedDepartmentRoleKey = preselectedDepartment.member.Role.Key;
        this.selectedDepartmentId = preselectedDepartment?.id;
        let me = await userRepository.getCurrentUser();
        LoggedUserManager.saveIdOfLoggedUser(this, me.apiUserId);
        let user = await userRepository.getUser(me.apiUserId, this.selectedDepartmentId);
        /*TODO: Co ak user nebude mat ziadny department?
        Ak je to company admin, mali by sme ho navigovat na vytvorenie company.*/
        vxDepartmentStore.selectedDepartmentPermissions = user.role.Permissions;
        this.$ability.update(new AbilityManager(this.getSelectedDepartmentPermissions()).getAbilities().rules);
        EventTypeRepository.getInstance(this, true); // reload instance of repository singleton class
        if (ApiUserRoles.SYSTEM_ADMIN != vxDepartmentStore.selectedDepartmentRoleKey)
            await this.$featuresManager.loadTier();
        this.$i18n.locale = settings.language;
        this.$validator.localize(settings.language);
        this.$router
            .push({
            name: 'dashboard',
            params: {
                departmentId: this.selectedDepartmentId,
                lang: this.$route.params.lang
            }
        })
            .catch((err) => err);
    }
};
DemoLogin = __decorate([
    Component
], DemoLogin);
export default DemoLogin;

export var ServiceState;
(function (ServiceState) {
    ServiceState["IDLE"] = "Idle";
    ServiceState["READY"] = "Ready";
    ServiceState["DOWNLOADED"] = "Downloaded";
    ServiceState["STOPPED"] = "Stopped";
    ServiceState["RUNNING"] = "Running";
    ServiceState["NO_TIME"] = "NoTime";
    ServiceState["ERROR"] = "Error";
    ServiceState["NOT_CALIBRATED"] = "NotCalibrated";
    ServiceState["MEMORY_FULL"] = "MemoryFull";
})(ServiceState || (ServiceState = {}));

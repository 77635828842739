import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import SourceRepository from '@/services/repository/SourceRepository';
import VueUtilities from '@/services/VueUtilities';
var sourceRepository;
let ChangeLoggerModal = class ChangeLoggerModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedLogger = null;
    }
    created() {
        sourceRepository = new SourceRepository(this);
    }
    get availableCompanyLoggers() {
        return this.companyLoggers.filter((x) => x.active === true);
    }
    resultRetrieved(data) {
        this.$emit('modalClosed', data);
    }
    replace() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let self = this;
                this.$buefy.dialog.confirm({
                    title: this.modalIsReassigning == true
                        ? `${this.$t('component.logger.change_modal.confirmReplaceDialog.title')}`
                        : `${this.$t('component.logger.change_modal.confirmReplaceDialog.title_assign')}`,
                    message: this.selectedLogger.source.id ? this.$t('component.logger.change_modal.confirmReplaceDialog.message', {
                        name1: this.source.logger ? this.source.logger.serialNumber : '-',
                        name2: this.selectedLogger.serialNumber
                    }) : this.$t('component.logger.change_modal.confirmReplaceDialog.message_only_assign', {
                        name1: this.selectedLogger.serialNumber,
                        sourceName: this.source.name
                    }),
                    confirmText: `${this.$t('component.logger.change_modal.confirmReplaceDialog.confirm')}`,
                    cancelText: `${this.$t('component.logger.change_modal.confirmReplaceDialog.deny')}`,
                    type: 'is-primary',
                    hasIcon: true,
                    onConfirm: () => {
                        this.saveChanges();
                    },
                    onCancel: () => { }
                });
            }
        });
    }
    async saveChanges() {
        let updatedSource = await sourceRepository.assignLogger(this.source.id, this.selectedLogger.companyLoggerId);
        if (updatedSource.logger.companyLoggerId == this.selectedLogger.companyLoggerId) {
            VueUtilities.openSuccessToast(this, this.$t('component.logger.change_modal.msg.success'));
            this.resultRetrieved(true);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.change_modal.msg.failure'));
        }
    }
};
__decorate([
    Prop({ type: Object })
], ChangeLoggerModal.prototype, "source", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ChangeLoggerModal.prototype, "modalIsReassigning", void 0);
__decorate([
    Prop({ type: Array })
], ChangeLoggerModal.prototype, "companyLoggers", void 0);
ChangeLoggerModal = __decorate([
    Component({ components: {} })
], ChangeLoggerModal);
export default ChangeLoggerModal;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import LoggerRepository from '@/services/repository/LoggerRepository';
var loggerRepository;
var LoggerType;
(function (LoggerType) {
    LoggerType["CCL"] = "C";
    LoggerType["TEMPEGG"] = "T";
    LoggerType["MINI"] = "M";
    LoggerType["LITE"] = "L";
})(LoggerType || (LoggerType = {}));
var Services;
(function (Services) {
    Services["TEM"] = "TEM";
    Services["HUM"] = "HUM";
    Services["SHO"] = "SHO";
    Services["PRE"] = "PRE";
})(Services || (Services = {}));
var PrefixServices;
(function (PrefixServices) {
    PrefixServices["TEM"] = "A";
    PrefixServices["HUM"] = "B";
    PrefixServices["SHO"] = "C";
    PrefixServices["TEM_HUM"] = "D";
    PrefixServices["TEM_SHO"] = "E";
    PrefixServices["HUM_SHO"] = "F";
    PrefixServices["TEM_HUM_SHO"] = "G";
    PrefixServices["TEM_HUM_SHO_PRE"] = "H";
})(PrefixServices || (PrefixServices = {}));
const PREFIX_START = 'LH';
const LINE1DEFAULT_SERVICE = 'LogHub {service} Logger';
const LINE1DEFAULT_MULTISERVICE = 'LogHub Multiservice Logger';
const LINE2DEFAULT = 'www.loghub.sk';
let GenerateStickerModal = class GenerateStickerModal extends Vue {
    constructor() {
        super(...arguments);
        this.LoggerType = LoggerType;
        this.Services = Services;
        this.loggerType = null;
        this.selectedServices = [];
        this.prefix = null;
        this.isCustom = false;
        this.startNumber = null;
        this.count = 0;
        this.line1 = '';
        this.line2 = LINE2DEFAULT;
        this.isLine1Custom = false;
    }
    created() {
        loggerRepository = new LoggerRepository(this);
    }
    downloadFile() {
        this.prefix = this.createLoggerPrefix(this.loggerType, this.selectedServices);
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let fileDownloaded = await loggerRepository.postAdminLoggerCSV(this.prefix, this.count, this.isCustom ? this.startNumber : null, this.isCustom, this.line1, this.line2);
                if (fileDownloaded)
                    this.resultRetrieved(fileDownloaded);
            }
        });
    }
    createLoggerPrefix(type, services) {
        let prefix = PREFIX_START;
        prefix += type;
        let serviceShortcuts = [];
        if (services.includes(Services.TEM))
            serviceShortcuts.push(Services.TEM);
        if (services.includes(Services.HUM))
            serviceShortcuts.push(Services.HUM);
        if (services.includes(Services.SHO))
            serviceShortcuts.push(Services.SHO);
        if (services.includes(Services.PRE))
            serviceShortcuts.push(Services.PRE);
        prefix += PrefixServices[serviceShortcuts.join('_')];
        return prefix;
    }
    typeSelected() {
        if (this.loggerType == LoggerType.TEMPEGG) {
            this.selectedServices = [Services.TEM];
            this.serviceSelected();
        }
    }
    serviceSelected() {
        if (this.isLine1Custom == true)
            return;
        if (this.selectedServices.includes(Services.PRE)) {
            this.selectedServices = [Services.TEM, Services.HUM, Services.SHO, Services.PRE];
        }
        if (this.selectedServices.length == 1) {
            let serviceName = '';
            switch (this.selectedServices[0]) {
                case Services.TEM:
                    serviceName = 'Temperature';
                    break;
                case Services.HUM:
                    serviceName = 'Humidity';
                    break;
                case Services.SHO:
                    serviceName = 'Shock';
                    break;
                case Services.PRE:
                    serviceName = 'Pressure';
                    break;
                default:
                    break;
            }
            this.line1 = LINE1DEFAULT_SERVICE.replace('{service}', serviceName);
        }
        else if (this.selectedServices.length > 1)
            this.line1 = LINE1DEFAULT_MULTISERVICE;
    }
    line1Changed() {
        this.isLine1Custom = true;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
GenerateStickerModal = __decorate([
    Component({})
], GenerateStickerModal);
export default GenerateStickerModal;

import AdminCommand from './AdminCommand';
import { AdminFirmware } from './AdminFirmware';
export default class AdminDeviceUpdate {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminDeviceUpdate.define(et));
        }
        else if (data != null) {
            return AdminDeviceUpdate.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let updates = new AdminDeviceUpdate();
        updates.id = data.Id;
        if (data.Command)
            updates.command = AdminCommand.fromApi(data.Command);
        if (data.Firmware)
            updates.firmware = AdminFirmware.fromApi(data.Firmware);
        return updates;
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let ConfirmCreateLoggerModal = class ConfirmCreateLoggerModal extends Vue {
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    createSources() {
        this.$emit('createSources');
    }
    onlyActivate() {
        this.$emit('onlyActivate');
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], ConfirmCreateLoggerModal.prototype, "loggesWithoutNameNO", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], ConfirmCreateLoggerModal.prototype, "loggesToAssignNO", void 0);
ConfirmCreateLoggerModal = __decorate([
    Component({ components: {} })
], ConfirmCreateLoggerModal);
export default ConfirmCreateLoggerModal;

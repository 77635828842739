import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import NotificationRuleMode from '@/entities/enums/NotificationRuleMode';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import VueUtilities from '@/services/VueUtilities';
var userSettingsRepository;
let AdminUserGeneralSettings = class AdminUserGeneralSettings extends Vue {
    constructor() {
        super(...arguments);
        this.fillingValues = true;
        this.isEditing = false;
        this.isAdding = false;
        this.changes = [];
        this.userSettingsKeys = UserSettingsKeys;
        this.NotificationRuleMode = NotificationRuleMode;
        this.notificationsRulesModes = [];
        this.selectedFilterByBoundary = {};
        this.hasChangeInDepartmentSettings = false;
        this.selectedNotificationsMode = {};
        this.selectedConfiguration = {};
        this.selectedReceiveReports = {};
        this.selectedFavoriteGroupId = {};
        this.selectedEventsRefreshEnabled = {};
        this.selectedRefreshInterval = {};
        this.selectedFieldKey = null;
        this.selectedFieldValue = null;
        this.fieldEditing = null;
        this.selectedNotificationsPauseUntil = '';
        this.selectedNotificationsPauseMsgDissmisedAt = '';
    }
    get isNotificationsPauseUntilChanged() {
        return (this.selectedNotificationsPauseUntil !== this.departmentUserSettings?.rawSettings?.notifications?.pause?.until);
    }
    get isNotificationsPauseMsgDissmisedAtChanged() {
        return (this.selectedNotificationsPauseMsgDissmisedAt !==
            this.departmentUserSettings?.rawSettings?.notifications?.pause?.msgDismissedAt);
    }
    get customDepartmentUserSettings() {
        if (this.departmentUserSettings.rawSettings?.customSettings) {
            return Object.entries(this.departmentUserSettings.rawSettings?.customSettings).map((o) => {
                return { key: o[0], value: o[1] };
            });
        }
        else
            return [];
    }
    get hasChanges() {
        return this.hasChangeInDepartmentSettings;
    }
    get enablePropInChanges() {
        return this.changes.find((setting) => setting.Key.includes(UserSettingsKeys.ENABLED));
    }
    get refreshIntervalPropInChanges() {
        return this.changes.find((setting) => setting.Key.includes(UserSettingsKeys.INTERVAL));
    }
    isConfigurationChanged() {
        for (let index = 0; index < this.departments.length; index++) {
            if (this.selectedConfiguration[this.departments[index].Department.Id] !==
                JSON.stringify(this.departmentUserSettings.rawSettings?.reports?.configuration))
                return true;
        }
        return false;
    }
    isConfigurationChangedForDepartment(depId) {
        return (this.selectedConfiguration[depId] !==
            JSON.stringify(this.departmentUserSettings.rawSettings?.reports?.configuration));
    }
    isNotificationsModeChanged() {
        for (let index = 0; index < this.departments.length; index++) {
            if (this.selectedNotificationsMode[this.departments[index].Department.Id] !==
                this.departmentUserSettings.rawSettings?.notifications?.rules?.mode)
                return true;
        }
        return false;
    }
    isNotificationsModeChangedForDepartment(depId) {
        return (this.selectedNotificationsMode[depId] !== this.departmentUserSettings.rawSettings?.notifications?.rules?.mode);
    }
    isFilterByBoundaryChanged() {
        for (let index = 0; index < this.departments.length; index++) {
            if (this.selectedFilterByBoundary[this.departments[index].Department.Id] !==
                this.getBooleanValue(this.departmentUserSettings.rawSettings?.reports?.records?.filterByBoundary)) {
                return true;
            }
        }
        return false;
    }
    isFilterByBoundaryChangedForDepartment(depId) {
        return (this.selectedFilterByBoundary[depId] !==
            this.getBooleanValue(this.departmentUserSettings.rawSettings?.reports?.records?.filterByBoundary));
    }
    isReceiveReportsChanged() {
        for (let index = 0; index < this.departments.length; index++) {
            if (this.selectedReceiveReports[this.departments[index].Department.Id] !==
                this.getBooleanValue(this.departmentUserSettings.rawSettings?.reports?.receiveByEmail)) {
                return true;
            }
        }
        return false;
    }
    isReceiveReportsChangedForDepartment(depId) {
        return (this.selectedReceiveReports[depId] !==
            this.getBooleanValue(this.departmentUserSettings.rawSettings?.reports?.receiveByEmail));
    }
    isFavoriteGroupIdChanged(depId) {
        if (this.selectedFavoriteGroupId[depId] == '' && this.departmentUserSettings?.preferences?.favoriteGroupId == null)
            return false;
        return this.selectedFavoriteGroupId[depId] != this.departmentUserSettings?.preferences?.favoriteGroupId;
    }
    isEventsRefreshEnabledChanged(depId) {
        return (this.selectedEventsRefreshEnabled[depId] !==
            this.getBooleanValue(this.departmentUserSettings?.preferences?.eventsRefresh?.enabled));
    }
    isRefreshIntervalChanged(depId) {
        if (isNaN(this.selectedRefreshInterval[depId]) &&
            isNaN(this.departmentUserSettings?.preferences?.eventsRefresh?.interval))
            return false;
        return (this.selectedRefreshInterval[depId] !==
            parseInt(this.departmentUserSettings?.preferences?.eventsRefresh?.interval));
    }
    created() {
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.loadData();
    }
    loadData() {
        this.fillingValues = true;
        this.notificationsRulesModes = Object.values(NotificationRuleMode);
        this.departments.forEach((dep) => {
            this.selectedNotificationsMode[dep.Department.Id] =
                this.departmentUserSettings.rawSettings?.notifications?.rules?.mode;
            this.selectedFilterByBoundary[dep.Department.Id] = this.getBooleanValue(this.departmentUserSettings.rawSettings?.reports?.records?.filterByBoundary);
            this.selectedReceiveReports[dep.Department.Id] = this.getBooleanValue(this.departmentUserSettings.rawSettings?.reports?.receiveByEmail);
            this.selectedConfiguration[dep.Department.Id] = JSON.stringify(this.departmentUserSettings.rawSettings?.reports?.configuration);
            this.selectedFavoriteGroupId[dep.Department.Id] = this.departmentUserSettings?.preferences?.favoriteGroupId;
            this.selectedEventsRefreshEnabled[dep.Department.Id] = this.getBooleanValue(this.departmentUserSettings?.preferences?.eventsRefresh?.enabled);
            this.selectedRefreshInterval[dep.Department.Id] = parseInt(this.departmentUserSettings?.preferences?.eventsRefresh?.interval);
        });
        this.selectedNotificationsPauseUntil = this.departmentUserSettings?.rawSettings?.notifications?.pause?.until;
        this.selectedNotificationsPauseMsgDissmisedAt =
            this.departmentUserSettings?.rawSettings?.notifications?.pause?.msgDismissedAt;
        this.fillingValues = false;
    }
    getBooleanValue(prop) {
        return prop === 'true' || prop === 'True' ? true : false;
    }
    edit() {
        this.isEditing = !this.isEditing;
    }
    // function which adds attribute to changes
    addToChanges(keys, value, isValueChanged) {
        this.hasChangeInDepartmentSettings =
            this.isNotificationsModeChanged() ||
                this.isFilterByBoundaryChanged() ||
                this.isReceiveReportsChanged() ||
                this.isConfigurationChanged() ||
                this.isFavoriteGroupIdChanged(this.selectedDepartment.Department.Id) ||
                this.isEventsRefreshEnabledChanged(this.selectedDepartment.Department.Id) ||
                this.isRefreshIntervalChanged(this.selectedDepartment.Department.Id) ||
                this.isNotificationsPauseUntilChanged ||
                this.isNotificationsPauseMsgDissmisedAtChanged;
        let path = keys.join('.');
        let index = this.changes.map((x) => x.Key).indexOf(path);
        // if user clicked on value which was set before edit, remove previous one from array
        if (!isValueChanged && index !== -1) {
            this.changes.splice(index, 1);
        }
        else {
            // if not in array
            if (index === -1) {
                this.changes.push({ Key: path, Value: value });
            }
            // else replace previous one with new
            else {
                this.changes.splice(index, 1);
                this.changes.push({ Key: path, Value: value });
            }
        }
    }
    async saveChanges() {
        // if enable prop in changes, refresh interval has to be also assigned
        if (this.enablePropInChanges && !this.refreshIntervalPropInChanges) {
            this.addToChanges([UserSettingsKeys.PREFERENCES, UserSettingsKeys.EVENTS_REFRESH, UserSettingsKeys.INTERVAL], this.enablePropInChanges.Value
                ? this.departmentUserSettings.preferences?.eventsRefresh?.interval.toString()
                : 0, this.isRefreshIntervalChanged(this.selectedDepartment.Department.Id));
        }
        // if refresh interval in changes and enable not (and enable is assigned to false), interval has to be removed
        else if (!this.enablePropInChanges &&
            !this.selectedEventsRefreshEnabled[this.selectedDepartment.Department.Id] &&
            this.refreshIntervalPropInChanges) {
            let index = this.changes.map((x) => x.Key).indexOf(this.refreshIntervalPropInChanges.Key);
            if (index !== -1) {
                this.changes.splice(index, 1);
            }
        }
        let response = await userSettingsRepository.saveUserSettingsForDepartment(this.userId, this.changes, this.selectedDepartment.Department.Id);
        if (response)
            this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
        this.isEditing = false;
        this.changes = [];
        this.$emit('reloadData', this.userId);
    }
    addNew() {
        this.isAdding = true;
    }
    cancelAdding() {
        this.isAdding = false;
        this.fieldEditing = null;
        this.selectedFieldKey = null;
        this.selectedFieldValue = null;
    }
    async saveNewSetting() {
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.checkIfKeyExists(this.selectedFieldKey)) {
                let keys = [UserSettingsKeys.CUSTOM, this.selectedFieldKey];
                let path = keys.join('.');
                this.changes.push({ Key: path, Value: this.selectedFieldValue });
                let response = await userSettingsRepository.saveUserSettingsForDepartment(this.userId, this.changes, this.selectedDepartment.Department.Id);
                if (response) {
                    this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
                    this.$emit('reloadData', this.userId);
                    this.cancelAdding();
                }
                this.changes = [];
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('system_settings.already_exists'));
                this.changes = [];
            }
        });
    }
    checkIfKeyExists(key) {
        if (this.customDepartmentUserSettings.find((x) => x.key === key) != null)
            return true;
        else
            return false;
    }
    editSettings(key) {
        this.fieldEditing = key;
        this.selectedFieldKey = key;
        let setting = this.customDepartmentUserSettings.find((x) => x.key == key);
        this.selectedFieldValue = setting.value;
    }
    cancelEdit() {
        this.fieldEditing = null;
        this.selectedFieldKey = null;
        this.selectedFieldValue = null;
    }
    async saveEdit(key) {
        let keys = [UserSettingsKeys.CUSTOM, key];
        let path = keys.join('.');
        this.changes.push({ Key: path, Value: this.selectedFieldValue });
        let response = await userSettingsRepository.saveUserSettingsForDepartment(this.userId, this.changes, this.selectedDepartment.Department.Id);
        if (response) {
            this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
            this.$emit('reloadData', this.userId);
        }
        this.changes = [];
        this.cancelEdit();
    }
    confirmSettingDelete(key) {
        let keys = [UserSettingsKeys.CUSTOM, key];
        let path = keys.join('.');
        this.$buefy.dialog.confirm({
            title: this.$t('custom_settings.delete_title'),
            message: this.$t('custom_settings.delete_msg', { key: key }),
            cancelText: this.$t('generals.close'),
            confirmText: this.$t('custom_settings.delete_confirm'),
            type: 'is-danger',
            onConfirm: () => this.deleteSetting(path)
        });
    }
    async deleteSetting(key) {
        let res = await userSettingsRepository.removeUserSetting(this.userId, key, this.selectedDepartment.Department.Id);
        this.$emit('reloadData', this.userId);
    }
    concatString(str1, str2) {
        return str1.concat('.', str2);
    }
};
__decorate([
    Prop({ type: String })
], AdminUserGeneralSettings.prototype, "userId", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserGeneralSettings.prototype, "adminUserSettings", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserGeneralSettings.prototype, "departmentUserSettings", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserGeneralSettings.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserGeneralSettings.prototype, "departments", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserGeneralSettings.prototype, "selectedDepartment", void 0);
AdminUserGeneralSettings = __decorate([
    Component({})
], AdminUserGeneralSettings);
export default AdminUserGeneralSettings;

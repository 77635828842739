var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card logger-modal-header" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _vm.modalIsReassigning == true
        ? _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.logger.list.reassign_loggers")) +
                " "
            ),
          ])
        : _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.logger.list.assign_loggers")) + " "
            ),
          ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _vm.modalIsReassigning == true
          ? _c("p", { staticClass: "is-italic" }, [
              _vm._v(
                _vm._s(_vm.$t("component.logger.list.reassign_loggers_note"))
              ),
            ])
          : _c("p", { staticClass: "is-italic" }, [
              _vm._v(
                _vm._s(_vm.$t("component.logger.list.assign_loggers_note"))
              ),
            ]),
        _vm.modalIsReassigning == true
          ? _c(
              "h3",
              {
                staticClass:
                  "is-size-4 has-text-centered pb-2 text-wrap-anywhere",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.logger.change_modal.subtitle", {
                        name: _vm.source.name,
                      })
                    ) +
                    " "
                ),
              ]
            )
          : _c(
              "h3",
              {
                staticClass:
                  "is-size-4 has-text-centered pb-2 text-wrap-anywhere",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.logger.change_modal.subtitle_assign", {
                        name: _vm.source.name,
                      })
                    ) +
                    " "
                ),
              ]
            ),
        _c(
          "b-field",
          {
            staticClass: "has-text-centered",
            attrs: {
              type: { "is-danger": _vm.errors.has("selectLogger") },
              message: _vm.errors.has("selectLogger")
                ? _vm.$t("error_messages.required_field")
                : null,
            },
          },
          [
            _c(
              "b-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "component.logger.change_modal.select_source_placeholder"
                  ),
                  name: "selectLogger",
                },
                model: {
                  value: _vm.selectedLogger,
                  callback: function ($$v) {
                    _vm.selectedLogger = $$v
                  },
                  expression: "selectedLogger",
                },
              },
              _vm._l(_vm.availableCompanyLoggers, function (logger) {
                return _c(
                  "option",
                  { key: logger.companyLoggerId, domProps: { value: logger } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(logger.serialNumber) +
                        " | " +
                        _vm._s(
                          logger.source.id
                            ? logger.source.name
                            : _vm.$t("generals.unassigned")
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _vm.selectedLogger && _vm.selectedLogger.source.id
          ? _c("p", { staticClass: "has-text-centered has-text-primary" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "component.logger.change_modal.logger_already_assigned",
                      {
                        name1: _vm.selectedLogger.source.name,
                        name2: _vm.source.name,
                      }
                    )
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c("footer", { staticClass: "modal-card-foot is-block" }, [
      _c("div", { staticClass: "level is-mobile" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                on: {
                  click: function ($event) {
                    return _vm.resultRetrieved()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.close")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _vm.modalIsReassigning == true
              ? _c(
                  "b-button",
                  {
                    staticClass: "level-item",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.replace()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("component.logger.list.reassign_loggers"))
                    ),
                  ]
                )
              : _c(
                  "b-button",
                  {
                    staticClass: "level-item",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.replace()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("component.logger.list.assign_loggers"))
                    ),
                  ]
                ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
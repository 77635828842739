var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "subtitle is-4 has-text-centered-touch" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("component.notification.pause.title")) + " "
        ),
      ]),
      _vm.paused
        ? _c("b-message", { attrs: { type: "is-warning" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("component.notification.pause.paused_msg", {
                    time: _vm.dateTimeManager.formatTime(
                      new Date(_vm.pausedTill * 1000),
                      null,
                      "",
                      null
                    ),
                  })
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _c("div", [
        _c(
          "div",
          { staticClass: "buttons is-centered" },
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.pauseNotifications(
                      _vm.PauseNotificationsInterval.FIFTEEN_MIN
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.notification.pause.pause15")) + " "
                ),
              ]
            ),
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.pauseNotifications(
                      _vm.PauseNotificationsInterval.THIRTY_MIN
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.notification.pause.pause30")) + " "
                ),
              ]
            ),
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.pauseNotifications(
                      _vm.PauseNotificationsInterval.ONE_HOUR
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.notification.pause.pause60")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import Languages from '@/entities/enums/languages';
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import SessionStorage from '@/services/repository/SessionStorage';
import NotificationItem from '@/views/components/notification/NotificationItem.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import NotificationRepository from '@/services/repository/NotificationRepository';
import DateTimeManager from '@/services/DateTimeManager';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import CookiesStorage from '@/services/CookiesStorage';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { Department } from '@/entities/models/Department';
import SettingOptions from '@/entities/enums/SettingOptions';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import { vxm } from '@/store/store.vuex';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var userSettingsRepository;
var notificationRepository;
var authenticationCalls;
var departmentRepository;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
const MORE_BTN_ID = 'ignoreClickOutside';
const BURGER_BTN_ID = 'navbar-burger';
let Header = class Header extends Vue {
    constructor() {
        super(...arguments);
        this.SettingOptions = SettingOptions;
        this.MORE_BTN_ID = MORE_BTN_ID;
        this.BURGER_BTN_ID = BURGER_BTN_ID;
        this.showNavMenu = false;
        this.showUserMenu = false;
        this.showNotificationMenu = false;
        this.showDepartmentsMenu = false;
        this.showOrgClmn = true;
        this.showDepartmentsClmn = false;
        this.isSideMenuVisible = false;
        this.showLangMenu = false;
        this.selectedDepartment = null;
        this.departments = [];
        this.departmentName = '';
        this.Languages = Languages;
        this.DateFormats = [];
        this.TimeZones = [];
        this.ClockFormats = [];
        this.selectedDateFormat = '';
        this.selectedTimezone = '';
        this.selectedClockFormat = '';
        this.actualTimeFormatted = '';
        this.actualTime = new Date();
        this.shownNotificationCount = 5;
        this.notifications = [];
        this.notificationsCount = 0;
        this.departmentOrganizations = [];
        this.selectedShowTzInfo = false;
        this.usedShowTzInfo = false;
        this.detailedOrganizationId = null;
        this.sessionPassword = CookiesStorage.loadData(this, CookiesStorage.keys.SESSION_PASSWORD);
        this.username = CookiesStorage.loadData(this, CookiesStorage.keys.USERNAME);
        this.isLoading = true;
        this.isLoadingNotifications = true;
        this.pendingRequest = false;
        this.focusedIndex = 0;
    }
    onRouteChanges() {
        this.loadData();
    }
    async created() {
        this.$root.$off('reloadNotifications');
        this.$root.$on('reloadNotifications', async () => {
            vxNotificationStore.clearData();
            await this.loadNotifications(false);
            this.loadNotificationsConfig();
        });
        this.handleOutsideClick();
    }
    async mounted() {
        if (LoggedUserManager.storageContainLoginCredentials(this.$cookies) == false) {
            return;
        }
        notificationRepository = new NotificationRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        authenticationCalls = new AuthenticationCalls(this);
        departmentRepository = new DepartmentRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.selectedDateFormat = this.dateTimeManager.getSelectedDateFormat();
        this.usedDateFormat = this.dateTimeManager.getSelectedDateFormat();
        this.selectedClockFormat = this.dateTimeManager.getSelectedClockFormat();
        this.usedClockFormat = this.dateTimeManager.getSelectedClockFormat();
        this.selectedShowTzInfo = this.dateTimeManager.getTzInClockFormatPreference();
        this.usedShowTzInfo = this.dateTimeManager.getTzInClockFormatPreference();
        this.selectedTimezone = this.dateTimeManager.getSelectedTimezone();
        this.usedTimeZone = this.dateTimeManager.getSelectedTimezone();
        this.$eventBus.$on('closeBurgerMenu', (value) => {
            this.isSideMenuVisible = value;
        });
        this.showSelectedTimeFormat();
        this.loadData();
    }
    get routeHasHiddenSelect() {
        return this.$route.meta.hiddenSelectInHeader === true;
    }
    get isWizard() {
        if (this.$route.name == 'wizard')
            return true;
        else
            return false;
    }
    get isOrganizations() {
        return (this.$route.name == 'organizations' || this.$route.name == 'notificationsOrg' || this.$route.name == 'profile');
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get hasMultipleOrganizations() {
        return this.departmentOrganizations.length > 1;
    }
    get departmentsForOrg() {
        return this.departmentOrganizations.find((x) => x.organization === this.detailedOrganizationId).departments;
    }
    get filteredDepartments() {
        return this.departments.filter((dep) => dep.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.departmentName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    get hasIdInParams() {
        return (Object.keys(this.$route.params).filter((p) => p.slice(-2).toLowerCase() === 'id' && p !== 'departmentId').length >
            0);
    }
    async loadNotifications(loadMore) {
        this.isLoadingNotifications = true;
        if (!notificationRepository)
            return;
        if (loadMore) {
            this.shownNotificationCount = this.shownNotificationCount + 3;
        }
        let pagination = {
            page: 1,
            pageSize: this.shownNotificationCount
        };
        await notificationRepository.loadNotificationsToStore(pagination);
        this.loadNotificationsConfig();
        this.isLoadingNotifications = false;
    }
    loadNotificationsConfig() {
        this.notifications = vxNotificationStore.notifications;
        this.notificationsCount = vxNotificationStore.totalSize;
    }
    keyArrows(event) {
        if (this.showDepartmentsMenu) {
            this.focusedIndex = 0;
            this.focusItem();
        }
    }
    focusPrevious() {
        if (this.showDepartmentsMenu) {
            this.focusedIndex = this.focusedIndex - 1;
            if (this.focusedIndex == -1) {
                this.focusedIndex = 0;
            }
            this.focusItem();
        }
    }
    focusNext() {
        if (this.showDepartmentsMenu) {
            this.focusedIndex = this.focusedIndex + 1;
            if (this.focusedIndex >= this.filteredDepartments.length) {
                this.focusedIndex = this.filteredDepartments.length - 1;
            }
            this.focusItem();
        }
    }
    focusItem() {
        if (this.filteredDepartments.length > 0)
            this.$refs.departments.children[this.focusedIndex].children[0].focus();
    }
    async notificationRemoved(notifIds) {
        await notificationRepository.markNotificationsAsRead(notifIds);
        this.$root.$emit('reloadNotifications');
    }
    async dateFormatSelected(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setSelectedDateFormat(this.selectedDateFormat);
    }
    async clockFormatSelected(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setSelectedClockFormat(this.selectedClockFormat);
    }
    async timezoneSelected(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setSelectedTimezone(this.selectedTimezone);
    }
    async showTzChanged(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setTzInClockFormatPreference(this.selectedShowTzInfo.toString());
    }
    showSelectedTimeFormat() {
        this.actualTimeFormatted = this.dateTimeManager.formatTime(new Date(), this.selectedTimezone, this.selectedDateFormat, this.selectedClockFormat, this.selectedShowTzInfo);
    }
    closeNavMenu(event) {
        this.showNavMenu = false;
    }
    closeUserMenu(event) {
        this.showUserMenu = false;
        if (this.needsReload()) {
            location.reload();
        }
    }
    closeNotificationMenu(event) {
        if (event.target.id != MORE_BTN_ID)
            this.showNotificationMenu = false;
    }
    handleOutsideClick() {
        document.addEventListener('click', (event) => {
            if (event.target.id != 'side-menu' && event.target.id != BURGER_BTN_ID) {
                if (this.isSideMenuVisible) {
                    this.isSideMenuVisible = false;
                    this.$eventBus.$emit('isSideMenuVisible', this.isSideMenuVisible);
                }
            }
        });
    }
    closeDepartmentsMenu() {
        this.showDepartmentsMenu = false;
    }
    setSelectedDepartment(department) {
        vxDepartmentStore.selectedDepartment = department;
        if (department.member)
            vxDepartmentStore.selectedDepartmentRoleKey = department.member.Role.Key;
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
    getDepartments() {
        return vxDepartmentStore.departments;
    }
    closeLangMenu(event) {
        if (event.target.id != MORE_BTN_ID)
            this.showLangMenu = false;
    }
    async changeDepartment(department) {
        if (department instanceof Department && this.selectedDepartment.id !== department.id) {
            this.selectedDepartment = department;
            await this.$featuresManager.loadTier();
            let componentName = this.$route.name.slice(0); // deepcopy
            let params = null;
            if (!this.hasIdInParams && componentName !== 'create_group') {
                params = JSON.parse(JSON.stringify(this.$route.params));
                params.departmentId = this.selectedDepartment.id;
            }
            else {
                params = {
                    departmentId: this.selectedDepartment.id,
                    lang: this.$route.params.lang
                };
                switch (componentName) {
                    case 'deviceConf':
                        componentName = 'configuration_devices';
                        break;
                    case 'logger_detail':
                        componentName = 'configuration_loggers';
                        break;
                    case 'issueDetail':
                        componentName = 'notifications';
                        break;
                    case 'create_group':
                    case 'group_detail':
                        componentName = 'configuration_groups';
                        break;
                    case 'userDetail':
                        componentName = 'companySettings';
                        break;
                    case 'publicDashboardDetail':
                        componentName = 'publicDashboards';
                        break;
                    default:
                        componentName = 'dashboard';
                        break;
                }
            }
            this.$router.push({
                name: componentName,
                params: params
            });
        }
        this.isSideMenuVisible = false;
        this.closeDepartmentsMenu();
    }
    toggleDepartmentsMenu(org) {
        this.detailedOrganizationId = org;
        this.showOrgClmn = !this.showOrgClmn;
        this.showDepartmentsClmn = !this.showDepartmentsClmn;
    }
    toggleMainDepartmentsMenu() {
        this.showDepartmentsMenu = !this.showDepartmentsMenu;
        if (this.showDepartmentsMenu && this.isLoggedFromAdmin) {
            this.$nextTick(() => {
                this.$refs.departmentName.focus();
            });
        }
    }
    async changeLanguage(language) {
        this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.LANGUAGE], language);
        this.$i18n.locale = language;
        this.$validator.localize(language);
        this.$router
            .push({
            name: this.$route.name,
            params: {
                lang: language,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    isCurrentLanguage(lang) {
        if (!this.settings)
            return false;
        else if (this.settings.language == lang)
            return true;
        return false;
    }
    needsReload() {
        if (this.selectedDateFormat != this.usedDateFormat ||
            this.selectedTimezone != this.usedTimeZone ||
            this.selectedClockFormat != this.usedClockFormat ||
            this.selectedShowTzInfo != this.usedShowTzInfo) {
            return true;
        }
        else {
            return false;
        }
    }
    mobileExpand() {
        this.isSideMenuVisible = !this.isSideMenuVisible;
        this.$eventBus.$emit('isSideMenuVisible', this.isSideMenuVisible);
    }
    async logoutAsync() {
        let success = await authenticationCalls.logout();
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            LoggedUserManager.deleteAdminLoginData(this);
            SessionStorage.clearStorageForSession();
            vxDepartmentStore.clearData();
            vxNotificationStore.clearData();
            this.$router.push({ name: 'login', params: { lang: this.$route.params.lang } }).catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
    async backToAdminInterface() {
        LoggedUserManager.reloadAdminLoginData(this);
        SessionStorage.removeDataForCompany();
        vxDepartmentStore.clearPermissionsData();
        //  if (this.isLoggedFromAdmin) {
        let last_url = JSON.parse(localStorage.getItem(LocalStorageKeys.ADMIN_LAST_URL));
        this.$router
            .push({
            name: last_url.name,
            params: last_url.params
        })
            .catch((err) => err);
        /* } else {
          this.$router
            .push({ name: "login", params: { lang: this.$route.params.lang } })
            .catch((err: any) => err);
        }*/
    }
    loadData() {
        if (this.pendingRequest === true)
            return;
        this.pendingRequest = true;
        let allPromises = [];
        allPromises.push(this.loadNotifications(false));
        allPromises.push(userSettingsRepository.loadUserSettings());
        /*  if (!this.routeHasHiddenSelect) {
          if (this.isLoggedFromAdmin) {
            allPromises.push(departmentRepository.getAllDepartmentsAdmin());
          } else {
            allPromises.push(departmentRepository.getDepartments());
          }
        }*/
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[1]);
        });
    }
    processLoadedDataForPage(settings
    // departments: ApiResponse<DepartmentArray> | Array<AdminDepartment>
    ) {
        /* if (!this.routeHasHiddenSelect) {
          if (this.isLoggedFromAdmin) {
            this.departments = AdminDepartment.convertToUserDepartments(
              departments as Array<AdminDepartment>
            );
            //TODO: We don't have organization in AdminDepartment so we can't group by organization for admin.
          } else {
            this.departments = (departments as ApiResponse<DepartmentArray>)
              .getData()
              .getData();
            this.departmentOrganizations = (
              departments as ApiResponse<DepartmentArray>
            )
              .getData()
              .groupByOrganization();
          }
    
          let selectedDepartmentId: string = this.getSelectedDepartmentId();
          this.selectedDepartment = this.departments.find(
            (x) => x.id == selectedDepartmentId
          );
        }*/
        this.loadNotificationsConfig();
        this.departments = this.getDepartments();
        this.selectedDepartment = this.getSelectedDepartment();
        this.settings = settings;
        if (this.$i18n)
            this.$i18n.locale = this.settings.language;
        this.$validator.localize(this.settings.language);
        this.DateFormats = this.dateTimeManager.getAllDateFormats();
        this.TimeZones = this.dateTimeManager.getLimitedTimezones();
        this.ClockFormats = this.dateTimeManager.getAllClockFormats();
        this.isLoading = false;
        this.pendingRequest = false;
    }
};
__decorate([
    Watch('$route')
], Header.prototype, "onRouteChanges", null);
Header = __decorate([
    Component({ components: { NotificationItem } })
], Header);
export default Header;

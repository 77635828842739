import { __decorate } from "tslib";
import { Component, Ref, Vue } from 'vue-property-decorator';
import jsQR from 'jsqr';
import VueUtilities from '@/services/VueUtilities';
const SCAN_AREA_SIZE = 300;
let QRCodeScanner = class QRCodeScanner extends Vue {
    constructor() {
        super(...arguments);
        this.stream = null;
        this.isTorchEnabled = false;
    }
    async mounted() {
        const videoElement = this.$refs.videoElement;
        await this.initVideo(videoElement);
        this.toggleTorch();
    }
    beforeDestroy() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
            this.stream = null;
        }
    }
    async initVideo(videoElement) {
        // Choose 'environment' for mobile and 'user' for desktop
        let facingMode = 'user';
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            facingMode = 'environment';
        }
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode }
            });
            videoElement.srcObject = this.stream;
            videoElement.play();
        }
        catch (err) {
            let message = '';
            if (err.name === 'NotAllowedError') {
                message += this.$t('component.qr_scanner.camera_blocked').toString();
            }
            VueUtilities.openErrorToast(this, message + ' ' + err);
            return;
        }
        this.scan(videoElement);
    }
    async scan(videoElement) {
        const canvasElement = document.createElement('canvas');
        const context = canvasElement.getContext('2d');
        const scanQR = () => {
            if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA && context) {
                canvasElement.height = videoElement.videoHeight;
                canvasElement.width = videoElement.videoWidth;
                context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
                const x = (canvasElement.width - SCAN_AREA_SIZE) / 2;
                const y = (canvasElement.height - SCAN_AREA_SIZE) / 2;
                const imageData = context.getImageData(x, y, SCAN_AREA_SIZE, SCAN_AREA_SIZE);
                const code = jsQR(imageData.data, imageData.width, imageData.height);
                if (code && code.data) {
                    this.$emit('parseData', [
                        {
                            fileName: '',
                            dataStream: code.data
                        }
                    ]);
                    // return;
                }
            }
            requestAnimationFrame(scanQR);
        };
        scanQR();
    }
    async toggleTorch() {
        const track = this.stream.getVideoTracks()[0];
        // Check for function compatability in browser
        if (typeof track.getCapabilities === 'function') {
            const capabilities = track.getCapabilities();
            if ('torch' in capabilities) {
                try {
                    await track.applyConstraints({ advanced: [{ torch: !this.isTorchEnabled }] });
                    this.isTorchEnabled = !this.isTorchEnabled;
                }
                catch (err) {
                    VueUtilities.openErrorToast(this, err);
                }
            }
        }
    }
};
__decorate([
    Ref()
], QRCodeScanner.prototype, "videoElement", void 0);
QRCodeScanner = __decorate([
    Component
], QRCodeScanner);
export default QRCodeScanner;

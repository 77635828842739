import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import SalesRepository from '@/services/repository/SalesRepository';
import FeedbackList from '@/views/components/admin/feedback/FeedbackList.vue';
import DateTimeManager from '@/services/DateTimeManager';
import ApiResponse from '@/entities/ApiResponse';
var salesRepository;
let AdminFeedbackManagement = class AdminFeedbackManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.dateTimeManager = null;
        this.feedbackList = new ApiResponse();
        this.objStringAdminFeedback = null;
        this.tableAdminFeedbackQueries = 'adminFeedback';
        this.currentFilter = [];
    }
    async created() {
        salesRepository = new SalesRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
    }
    async loadFeedback(sort, currentFilter) {
        this.isLoading = true;
        let promises = [];
        promises.push(salesRepository.getFeedbackList(null, sort, currentFilter));
        await Promise.all(promises).then((response) => {
            this.feedbackList = response[0];
            this.isLoading = false;
        });
    }
    async onOptionsChange(options, filters = []) {
        this.callOptions = options;
        this.currentFilter = filters;
        this.loadFeedback(options.sort, filters);
    }
    changeRouterUrlFromFeedback(objStringAdminFeedback) {
        this.objStringAdminFeedback = objStringAdminFeedback;
        this.changeRouterUrl(this.objStringAdminFeedback);
    }
    changeRouterUrl(objStringAdminFeedback) {
        this.$router
            .push({
            query: {
                [this.tableAdminFeedbackQueries]: objStringAdminFeedback
            }
        })
            .catch((err) => err);
    }
};
AdminFeedbackManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_FEEDBACK)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { FeedbackList }
    })
], AdminFeedbackManagement);
export default AdminFeedbackManagement;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EventValidationRepository from '@/services/repository/EventValidationRepository';
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import OtherNotificationSettings from '@/views/components/notification/OtherNotificationSettings.vue';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import { vxm } from '@/store/store.vuex';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
var eventsValidationRepository;
var departmentRepository;
var vxDepartmentStore = vxm.departmentStore;
let NotificationsStep = class NotificationsStep extends Vue {
    constructor() {
        super(...arguments);
        this.isChangingMode = false;
        this.isLoading = false;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.selectedMeasuredRulesMode = null;
        this.localDepartmentSettings = null;
        this.selectedPreset = null;
        this.measuredRuleEnabled = null;
    }
    notificationSubscriptionsChanged() {
        this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
    }
    created() {
        eventsValidationRepository = new EventValidationRepository(this);
        departmentRepository = new DepartmentRepository(this);
        this.selectedMeasuredRulesMode =
            this.departmentUserSettings.notifications.rules.mode ||
                NotificationMeasuredRuleScope.COMPANY;
        this.localDepartmentSettings = this.departmentSettings;
        this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
    }
    get singleMeasuredRule() {
        return this.notificationSubscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredRules() {
        return this.notificationSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get systemRules() {
        return this.notificationSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.SYSTEM_RULE);
    }
    get hasRulesForEntities() {
        return this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
    }
    get selectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    get hasPresetSelected() {
        return this.selectedPreset != null;
    }
    async changeMode(selectedMode) {
        this.isChangingMode = true;
        this.measuredRuleEnabled = true;
        await eventsValidationRepository.changeNotificationsMode(selectedMode, this.currentUser.apiUserId);
        this.$emit('reloadAll');
        this.isChangingMode = false;
    }
    getRule(name) {
        return this.systemRules.find((x) => x.notificationType === name);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    getMeasuredRuleEnabled() {
        return this.singleMeasuredRule ? this.singleMeasuredRule.enabled : false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    async updateOptimizeNotificationsSetting(value) {
        this.isLoading = true;
        this.localDepartmentSettings = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.SOURCE, DepartmentSettingsKeys.DO_NOT_NOTIFY], value);
        this.isLoading = false;
    }
    applyPreset() {
        this.$emit('applyPreset', this.selectedPreset);
    }
};
__decorate([
    Prop({ type: Object })
], NotificationsStep.prototype, "departmentUserSettings", void 0);
__decorate([
    Prop({ type: Object })
], NotificationsStep.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], NotificationsStep.prototype, "availableTypes", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], NotificationsStep.prototype, "notificationSubscriptions", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], NotificationsStep.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], NotificationsStep.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], NotificationsStep.prototype, "presets", void 0);
__decorate([
    Prop({ type: Object })
], NotificationsStep.prototype, "departmentSettings", void 0);
__decorate([
    Prop({ type: Object })
], NotificationsStep.prototype, "tier", void 0);
__decorate([
    Watch('notificationSubscriptions')
], NotificationsStep.prototype, "notificationSubscriptionsChanged", null);
NotificationsStep = __decorate([
    Component({
        components: {
            MeasuredDataNotification,
            GeneralNotificationComponent,
            EntityMeasuredDataNotification,
            OtherNotificationSettings
        }
    })
], NotificationsStep);
export default NotificationsStep;

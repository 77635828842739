import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import { ServiceState } from '@/entities/enums/ServiceState';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
let AdminCompanyLoggersComponent = class AdminCompanyLoggersComponent extends Vue {
    constructor() {
        super(...arguments);
        this.CalibrationStatus = CalibrationStatus;
        this.ServiceState = ServiceState;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanyLoggersSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanyLoggersSortField.defaultValue;
        this.tableColumns = {
            loggerId: {
                field: 'loggerId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersLoggerId)
            },
            macAddress: {
                field: 'macAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersMacAddress)
            },
            serialNumber: {
                field: 'serialNumber',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersSerialNumber)
            },
            sourceName: {
                field: 'sourceName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersSourceName)
            },
            lastSeen: {
                field: 'lastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersLastSeen)
            },
            calibration: {
                field: 'calibration',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersCalibration)
            },
            isOnline: {
                field: 'isOnline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersIsOnline)
            },
            active: {
                field: 'active',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersActive)
            },
            battery: {
                field: 'battery',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersBattery)
            },
            expiration: {
                field: 'expiration',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersExpiration)
            }
        };
    }
    get loggerList() {
        let loggerList = [];
        let deactivatedList = [];
        this.companyLoggers.forEach((logger) => {
            logger.source = this.sources.find((x) => x.logger && x.logger.macAddress === logger.macAddress);
            if (!logger.active)
                deactivatedList.push(logger);
            else
                loggerList.push(logger);
        });
        if (deactivatedList.length != 0)
            loggerList = loggerList.concat(deactivatedList);
        return loggerList;
    }
    loggerDetail(loggerId) {
        this.$emit('loggerDetail', loggerId.toString());
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.loggerId.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersLoggerId, event);
                break;
            case this.tableColumns.macAddress.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersMacAddress, event);
                break;
            case this.tableColumns.sourceName.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersSourceName, event);
                break;
            case this.tableColumns.lastSeen.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersLastSeen, event);
                break;
            case this.tableColumns.expiration.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersExpiration, event);
                break;
            case this.tableColumns.isOnline.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersIsOnline, event);
                break;
            case this.tableColumns.active.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersActive, event);
                break;
            case this.tableColumns.battery.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyLoggersBattery, event);
                break;
        }
    }
    downloadCalibration(id) {
        this.$emit('downloadCalibration', id);
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
};
__decorate([
    Prop({ type: Array })
], AdminCompanyLoggersComponent.prototype, "companyLoggers", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyLoggersComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyLoggersComponent.prototype, "company", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminCompanyLoggersComponent.prototype, "sources", void 0);
AdminCompanyLoggersComponent = __decorate([
    Component({})
], AdminCompanyLoggersComponent);
export default AdminCompanyLoggersComponent;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DeviceRegistrationComponent", {
        attrs: { deviceIsActiveFlag: _vm.deviceIsActiveFlag },
        on: {
          result: function ($event) {
            return _vm.closeRegistrationModal($event)
          },
          deviceIsActive: _vm.deviceIsActive,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
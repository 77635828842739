import { Source } from './Source';
import { UserSettingsValues } from '../UserSettings';
import MeasurementsSort from '../enums/MeasurementsSort';
const ALIVE_OFFSET = 600;
export class SourceGroup {
    constructor() {
        this.sources = new Array();
    }
    getBoundariesAsString() {
        if (this.sources.length <= 0 || this.sources[0].boundaries.length <= 0)
            return [];
        let boundaries = this.sources[0].boundaries;
        let stringBoundaries = [];
        boundaries.forEach((boundary) => {
            stringBoundaries.push(boundary.toString());
        });
        return stringBoundaries;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => SourceGroup.define(et));
        }
        else if (data != null) {
            return SourceGroup.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let sourceGroup = new SourceGroup();
        sourceGroup.id = data.Id;
        sourceGroup.name = data.Name;
        sourceGroup.isDefault = data.IsDefault;
        sourceGroup.sources = Source.fromApi(data.Sources);
        sourceGroup.visible = data.Visible;
        return sourceGroup;
    }
    static createNullSourceGroup(sources) {
        let nullSource = new SourceGroup();
        nullSource.id = null;
        nullSource.name = null;
        nullSource.sources = sources;
        nullSource.visible = true;
        return nullSource;
    }
    static assignSourcesToSourceGroups(sourceGroups, sources) {
        sourceGroups.forEach((group) => {
            group.sources = sources.filter((x) => x.sourceGroup && x.sourceGroup.id == group.id);
        });
        return sourceGroups;
    }
    static assignSourcesToSourceGroup(sourceGroup, sources) {
        sourceGroup.sources = sources.filter((x) => x.sourceGroup && x.sourceGroup.id == sourceGroup.id);
        return sourceGroup;
    }
    static assignSourcesForSelectedGroup(selectedSourceGroupId, sources) {
        switch (selectedSourceGroupId) {
            case null:
                return null;
            case UserSettingsValues.UNASSIGNED_GROUP_ID:
                return Source.getUnassignedSources(sources);
            case UserSettingsValues.ALL_GROUP_ID:
                return sources;
            default:
                return sources.filter((x) => x.sourceGroup && x.sourceGroup.id == selectedSourceGroupId);
        }
    }
    static sort(groups, favoriteGroupId, sortPreference) {
        switch (sortPreference) {
            case MeasurementsSort.ALPHABETICAL:
                return SourceGroup.sortGroupsByName(groups, favoriteGroupId);
            case MeasurementsSort.TIME:
                return SourceGroup.sortGroupsByLastSeenSource(groups, favoriteGroupId);
            default:
                throw new Error('Missing sort preference');
        }
    }
    static createArrayCopy(array) {
        let arrCopy = [];
        let jsonCopy = JSON.parse(JSON.stringify(array));
        jsonCopy.forEach((el) => {
            let obj = Object.assign(new SourceGroup(), el);
            arrCopy.push(obj);
        });
        return arrCopy;
    }
    static sortGroupsByLastSeenSource(groups, favoriteGroupId) {
        return groups.sort(function (a, b) {
            if (favoriteGroupId)
                if (a.id === favoriteGroupId || (a.id == null && favoriteGroupId == UserSettingsValues.UNASSIGNED_GROUP_ID))
                    return 0;
                else if (b.id === favoriteGroupId ||
                    (b.id == null && favoriteGroupId == UserSettingsValues.UNASSIGNED_GROUP_ID))
                    return 1;
            let aLastSeenSourceTime = Math.max.apply(Math, a.sources.map((x) => x.lastSeen));
            let bLastSeenSourceTime = Math.max.apply(Math, b.sources.map((x) => x.lastSeen));
            let aId = aLastSeenSourceTime;
            let bId = bLastSeenSourceTime;
            return aId < bId ? 1 : aId > bId ? -1 : 0;
        });
    }
    static sortGroupsByName(groups, favoriteGroupId) {
        return groups.sort(function (a, b) {
            if (a.id === favoriteGroupId || (a.id === null && favoriteGroupId == UserSettingsValues.UNASSIGNED_GROUP_ID))
                return 0;
            else if (b.id === favoriteGroupId || (b.id === null && favoriteGroupId == UserSettingsValues.UNASSIGNED_GROUP_ID))
                return 1;
            let aId = a && a.name ? a.name.toLowerCase() : null;
            let bId = b && b.name ? b.name.toLowerCase() : null;
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static sortByNotificationRule(sourceGroups, notificationSubscriptions) {
        return sourceGroups.sort(function (a, b) {
            let aId = notificationSubscriptions.find((x) => x.scopeId == a.id) ? 1 : 0;
            let bId = notificationSubscriptions.find((x) => x.scopeId == b.id) ? 1 : 0;
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
}

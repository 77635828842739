import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import InvitationStates from '@/entities/enums/InvitationStates';
import UserPreferences from '@/services/UserPreferences';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let SentInvitationsManagement = class SentInvitationsManagement extends Vue {
    constructor() {
        super(...arguments);
        this.invitationGroupByMail = [];
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.sortDirection = UserPreferences.LocalStorageKeys.SentInvitationsManagementSortDirection
            .defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.SentInvitationsManagementSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.SentInvitationsManagementPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.SentInvitationsManagementRowsPerPage.defaultValue;
        this.total = 0;
        this.InvitationStates = InvitationStates;
        this.tableColumns = {
            invitedEmail: {
                field: 'invitedEmail',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SentInvitationsManagementInvitedEmail)
            },
            state: {
                field: 'state',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SentInvitationsManagementState)
            },
            role: {
                field: 'role',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SentInvitationsManagementRole)
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SentInvitationsManagementCreatedAt)
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    async created() {
        this.sentInvitations.forEach((x) => {
            if (x.isExpired())
                x.state = InvitationStates.EXPIRED;
        });
        this.sentInvitations.forEach((invitation) => {
            if (this.invitationGroupByMail.filter((x) => x.email == invitation.invitedEmail).length == 0) {
                this.invitationGroupByMail.push({
                    email: invitation.invitedEmail,
                    latestInvitation: this.getLatestInvitation(this.sentInvitations.filter((x) => x.invitedEmail == invitation.invitedEmail)),
                    invitations: this.sentInvitations.filter((x) => x.invitedEmail == invitation.invitedEmail).reverse()
                });
            }
        });
    }
    mounted() {
        this.total = this.$refs.table._data.newData.length;
    }
    getLatestInvitation(invitations) {
        return invitations.find((x) => x.createdAt ==
            Math.max(...invitations.map((element) => {
                return element.createdAt;
            })));
    }
    getStateType(invitation) {
        switch (invitation.state) {
            case InvitationStates.PENDING:
                return 'is-warning';
            case InvitationStates.CANCELED:
                return 'is-black';
            case InvitationStates.ACCEPTED:
                return 'is-success';
            case InvitationStates.DECLINED:
                return 'is-primary';
            case InvitationStates.EXPIRED:
                return '';
            default:
                return '';
        }
    }
    resendInv(invitationId) {
        let invitation = this.sentInvitations.find((invitation) => invitation.id == invitationId);
        this.$emit('openResendInviteModal', invitation);
    }
    revokeInv(invitationId) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.invitation.revoke_dialog.title')}`,
            message: this.$t('component.invitation.revoke_dialog.msg').toString(),
            confirmText: `${this.$t('component.invitation.revoke_dialog.confirm')}`,
            cancelText: `${this.$t('generals.close')}`,
            type: 'is-primary',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('revokeInvitation', invitationId);
            },
            onCancel: () => { }
        });
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.role.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.SentInvitationsManagementRole, event);
                break;
            case this.tableColumns.createdAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.SentInvitationsManagementCreatedAt, event);
                break;
        }
    }
};
__decorate([
    Prop({ type: Array })
], SentInvitationsManagement.prototype, "sentInvitations", void 0);
__decorate([
    Prop({ type: Object })
], SentInvitationsManagement.prototype, "dateTimeManager", void 0);
SentInvitationsManagement = __decorate([
    Component({})
], SentInvitationsManagement);
export default SentInvitationsManagement;

var LoggerStates;
(function (LoggerStates) {
    LoggerStates["ALL"] = "All";
    LoggerStates["AVAILABLE"] = "Available";
    LoggerStates["PUBLISHED"] = "Published";
    LoggerStates["ACTIVATED"] = "Activated";
    LoggerStates["DEACTIVATED"] = "Deactivated";
    LoggerStates["DESTROYED"] = "Destroyed";
    LoggerStates["UNKNOWN"] = "Unknown";
})(LoggerStates || (LoggerStates = {}));
export default LoggerStates;
export function getLoggerState(state) {
    if (!state)
        return null;
    const stateInstance = LoggerStates[state.toUpperCase()];
    return !stateInstance ? LoggerStates.UNKNOWN : stateInstance;
}

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminDeviceCommands = class AdminDeviceCommands extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.selectedValue = '';
        this.isIdSearch = true;
        this.isTypeSearch = false;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceCommandsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceCommandsSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminDeviceCommandsPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminDeviceCommandsRowsPerPage.defaultValue;
        this.total = 0;
        this.tableColumns = {
            id: {
                field: 'id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsId),
                hasSearch: true
            },
            type: {
                field: 'type',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsType),
                hasSearch: true
            },
            progress: {
                field: 'progress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsProgress),
                hasSearch: false
            },
            allowMultiple: {
                field: 'allowMultiple',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsAllowMultiple),
                hasSearch: false
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsCreatedAt),
                hasSearch: false
            },
            scheduleAt: {
                field: 'scheduleAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsScheduleAt),
                hasSearch: false
            },
            confirmedAt: {
                field: 'confirmedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsConfirmedAt),
                hasSearch: false
            },
            disabledAt: {
                field: 'disabledAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsDisabledAt),
                hasSearch: false
            },
            updatedAt: {
                field: 'updatedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsUpdatedAt),
                hasSearch: false
            }
        };
    }
    commandsChanged() {
        this.total = this.commands.length;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    created() {
        this.selectedValue = this.tableColumns.id.field;
        this.total = this.commands.length;
    }
    mounted() {
        this.total = this.$refs.table._data.newData.length;
    }
    clearFilter() {
        this.$refs.table.filters = {};
        this.page = 1;
    }
    changeSearchField(event) {
        this.isIdSearch = false;
        this.isTypeSearch = false;
        switch (event) {
            case this.tableColumns.id.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.type.field:
                this.isTypeSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    openDisableModal(command) {
        this.$emit('openDisableModal', command.id);
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.id.field;
        this.changeSearchField(this.tableColumns.id.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.type.field:
                if (!event) {
                    this.$refs.table.filters.type = '';
                    this.changeDefaultField();
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsType, event);
                break;
            case this.tableColumns.createdAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsCreatedAt, event);
                break;
            case this.tableColumns.scheduleAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsScheduleAt, event);
                break;
            case this.tableColumns.confirmedAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsConfirmedAt, event);
                break;
            case this.tableColumns.disabledAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsDisabledAt, event);
                break;
            case this.tableColumns.updatedAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsUpdatedAt, event);
                break;
            case this.tableColumns.allowMultiple.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCommandsAllowMultiple, event);
                break;
        }
    }
};
__decorate([
    Prop({ type: Array })
], AdminDeviceCommands.prototype, "commands", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceCommands.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceCommands.prototype, "isLayoutList", void 0);
__decorate([
    Watch('commands')
], AdminDeviceCommands.prototype, "commandsChanged", null);
AdminDeviceCommands = __decorate([
    Component({})
], AdminDeviceCommands);
export default AdminDeviceCommands;

import User from '@/entities/User';
export default class Device {
    constructor() {
        this.registeredBy = new User();
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Device.define(et));
        }
        else if (data != null) {
            return Device.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let device = new Device();
        device.deviceGroup = data.DeviceGroup;
        device.deviceId = data.DeviceId;
        device.deviceName = data.Name;
        device.registeredAt = data.RegisteredAt;
        device.deviceHardwareKey = data.HardwareKey;
        device.deviceMacAddress = data.MacAddress;
        device.isOnline = data.IsOnline;
        device.lastSeen = data.LastSeen;
        device.description = data.Description;
        device.state = data.State;
        device.instanceState = data.InstanceState;
        device.type = data.Type;
        if (data.RegisteredBy)
            device.registeredBy = User.fromApi(data.RegisteredBy);
        return device;
    }
    static sortByName(sources) {
        return sources.sort(function (a, b) {
            let aId = a ? a.deviceName.toLowerCase() : '';
            let bId = b ? b.deviceName.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
}

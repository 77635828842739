var WizardSteps;
(function (WizardSteps) {
    WizardSteps[WizardSteps["INSTRUCTIONS"] = 0] = "INSTRUCTIONS";
    WizardSteps[WizardSteps["DEVICE"] = 1] = "DEVICE";
    WizardSteps[WizardSteps["LOGGERS"] = 2] = "LOGGERS";
    WizardSteps[WizardSteps["BOUNDARIES"] = 3] = "BOUNDARIES";
    WizardSteps[WizardSteps["NOTIFICATIONS"] = 4] = "NOTIFICATIONS";
    WizardSteps[WizardSteps["DONE"] = 5] = "DONE";
})(WizardSteps || (WizardSteps = {}));
export default WizardSteps;

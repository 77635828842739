var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.boundaries.length > 0
      ? _c(
          "table",
          { staticClass: "info-table min-padding has-bottom-margin" },
          [
            _c("tr", [
              _c("th"),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.lower_alarm"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.lower_warning"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.upper_warning"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.upper_alarm"))),
              ]),
              _vm.editOption
                ? _c(
                    "th",
                    [
                      _c("b-icon", {
                        staticClass: "clickable",
                        attrs: { icon: "pencil-alt" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.editBoundaries()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._l(_vm.boundaries, function (boundary) {
              return _c("tr", { key: boundary.eventTypeSlug }, [
                _c("td", [
                  _vm._v(_vm._s(_vm.$t("services." + boundary.eventTypeSlug))),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          boundary.lowerAlarmBoundary.Value
                        )
                      ) +
                      _vm._s(boundary.lowerAlarmBoundary.Unit) +
                      " "
                  ),
                ]),
                _c("td", [
                  boundary.lowerWarningBoundary.Value
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DecimalConversion.toCommaString(
                                boundary.lowerWarningBoundary.Value
                              )
                            ) +
                            _vm._s(boundary.lowerWarningBoundary.Unit)
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]),
                _c("td", [
                  boundary.upperWarningBoundary.Value
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DecimalConversion.toCommaString(
                                boundary.upperWarningBoundary.Value
                              )
                            ) +
                            _vm._s(boundary.upperWarningBoundary.Unit)
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          boundary.upperAlarmBoundary.Value
                        )
                      ) +
                      _vm._s(boundary.upperAlarmBoundary.Unit) +
                      " "
                  ),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
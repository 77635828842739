import User from '../User';
export class ChangeRequest {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => ChangeRequest.define(et));
        }
        else if (data != null) {
            return ChangeRequest.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let changeRequest = new ChangeRequest();
        changeRequest.id = data.Id;
        changeRequest.requestedTier = data.RequestedTier;
        changeRequest.text = data.Text;
        changeRequest.createdAt = data.CreatedAt;
        changeRequest.company = data.Company;
        changeRequest.state = data.State;
        if (data.Requestor)
            changeRequest.requestor = User.fromApi(data.Requestor);
        return changeRequest;
    }
}

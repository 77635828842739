import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let WizardHelp = class WizardHelp extends Vue {
    notificationClosed() {
        this.$emit('notificationClosed');
    }
};
__decorate([
    Prop({ type: String })
], WizardHelp.prototype, "text", void 0);
WizardHelp = __decorate([
    Component({})
], WizardHelp);
export default WizardHelp;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import TiersList from '@/views/components/admin/tiers/TiersList.vue';
import FeaturesList from '@/views/components/admin/tiers/FeaturesList.vue';
import ChangeRequestsList from '@/views/components/admin/tiers/ChangeRequestsList.vue';
import TiersRepository from '@/services/repository/TiersRepository';
var tiersRepository;
let AdminTiersManagement = class AdminTiersManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.dateTimeManager = null;
        this.tiers = [];
        this.features = [];
        this.changeRequest = [];
    }
    async created() {
        tiersRepository = new TiersRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(tiersRepository.getTiers());
        allPromises.push(tiersRepository.getAllFeatures());
        allPromises.push(tiersRepository.getChangeRequests());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2]);
        });
        this.isLoading = false;
    }
    processLoadedDataForPage(tiers, features, changeRequest) {
        this.tiers = tiers;
        this.features = features;
        this.changeRequest = changeRequest;
    }
};
AdminTiersManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_TIERS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_TIERS_REQUESTS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { TiersList, FeaturesList, ChangeRequestsList }
    })
], AdminTiersManagement);
export default AdminTiersManagement;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import NotificationRepository from '@/services/repository/NotificationRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import DateTimeManager from '@/services/DateTimeManager';
import ApiResponse from '@/entities/ApiResponse';
import { Notification } from '@/entities/Notification';
import NotificationsTable from '@/views/components/notification/NotificationsTable.vue';
import PauseNotificationsMessage from '@/views/components/notification/PauseNotificationsMessage.vue';
var notificationRepository;
var userSettingsRepository;
let NotificationOrganization = class NotificationOrganization extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = {};
        this.currentFilter = [];
        this.dateRange = [];
        this.notificationsResult = new ApiResponse();
        this.pauseDismissedAt = 0;
        this.pauseNotificationsUntil = 0;
        this.isLoading = true;
    }
    get pauseComponentVisible() {
        return Notification.pauseComponentIsVisible(this.pauseDismissedAt, this.pauseNotificationsUntil, this.notificationsResult.getData());
    }
    get notificationsPaused() {
        let now = Math.floor(new Date().getTime() / 1000);
        return this.pauseNotificationsUntil > now;
    }
    async beforeCreate() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
    }
    async created() {
        notificationRepository = new NotificationRepository(this);
        userSettingsRepository = await UserSettingsRepository.getInstance(this);
        let settings = await userSettingsRepository.loadUserSettings();
        this.pauseDismissedAt = parseInt(settings.notifications.pause.msgDismissedAt);
        this.pauseNotificationsUntil = parseInt(settings.notifications.pause.until);
    }
    async loadNotifications(pagination, sort, filters = [], dateRange = [], departmentId) {
        this.isLoading = true;
        this.currentFilter = filters;
        this.dateRange = dateRange;
        let convertedDateRange = dateRange.map((x) => this.dateTimeManager.formatTime(x, null, 'MM-DD-YYYY', null, false));
        this.notificationsResult = await notificationRepository.getNotifications(pagination, sort, filters, convertedDateRange, departmentId);
        this.isLoading = false;
    }
    async onOptionsChange(options, filters = [], dateRange = [], departmentId = '') {
        this.callOptions = options;
        this.currentFilter = filters;
        this.dateRange = dateRange;
        this.loadNotifications(options.pagination, options.sort, filters, dateRange, departmentId);
    }
    async notificationRemoved(notifIds, pagination, sort, filters = [], dateRange = [], departmentId = '') {
        this.isLoading = true;
        await notificationRepository.markNotificationsAsRead(notifIds);
        this.$eventBus.$emit('removeNotification', notifIds);
        this.loadNotifications(pagination, sort, filters, dateRange, departmentId);
        this.isLoading = false;
    }
    async removeAllNotifications() {
        this.isLoading = true;
        await notificationRepository.markAllNotificationsAsRead();
        this.isLoading = false;
    }
    async reloadNotificationPause() {
        this.isLoading = true;
        let settings = await userSettingsRepository.loadUserSettings();
        this.pauseDismissedAt = parseInt(settings.notifications.pause.msgDismissedAt);
        this.pauseNotificationsUntil = parseInt(settings.notifications.pause.until);
        this.isLoading = false;
    }
};
NotificationOrganization = __decorate([
    Component({ components: { NotificationsTable, PauseNotificationsMessage } })
], NotificationOrganization);
export default NotificationOrganization;

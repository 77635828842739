import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import SalesRepository from '@/services/repository/SalesRepository';
import FeedbackCategories from '@/entities/enums/FeedbackCategories';
import FeedbackStatus from '@/entities/enums/FeedbackStatus';
var salesRepository;
let FeedbackDetail = class FeedbackDetail extends Vue {
    constructor() {
        super(...arguments);
        this.FeedbackCategories = FeedbackCategories;
        this.FeedbackStatus = FeedbackStatus;
    }
    async created() {
        salesRepository = new SalesRepository(this);
    }
    closeFeedback() {
        this.$buefy.dialog.confirm({
            title: this.$t('contact_form.close_dialog.title'),
            message: this.$t('contact_form.close_dialog.message'),
            confirmText: this.$t('contact_form.close_dialog.confirm'),
            cancelText: this.$t('contact_form.close_dialog.cancel'),
            type: 'is-primary',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('closeFeedback', this.feedback.id);
            },
            onCancel: () => { }
        });
    }
};
__decorate([
    Prop({ type: Object })
], FeedbackDetail.prototype, "feedback", void 0);
__decorate([
    Prop({ type: Object })
], FeedbackDetail.prototype, "dateTimeManager", void 0);
FeedbackDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_FEEDBACK)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {}
    })
], FeedbackDetail);
export default FeedbackDetail;

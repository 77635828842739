import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
var companyLoggerRepository;
let LastDataInfoComponent = class LastDataInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.keys = [];
        this.subkeys = [];
        this.keyValues = [];
        this.values = [];
        this.middleIndex = 0;
        this.firstHalf = [];
        this.secondHalf = [];
        this.hasData = false;
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.loadData();
    }
    loadData() {
        if (this.companyLogger.companyLoggerId) {
            this.getSubKeys(this.companyLogger.lastData);
            this.getKeys(this.companyLogger.lastData);
            this.middleIndex = Math.ceil(this.keys.length / 2);
            this.firstHalf = this.keys.splice(0, this.middleIndex);
            this.secondHalf = this.keys.splice(-this.middleIndex);
        }
        if (!this.companyLogger.companyLoggerId) {
            this.hasData = false;
        }
        else if (Object.keys(this.companyLogger.lastData).length == 0) {
            this.hasData = false;
        }
        else {
            this.hasData = true;
        }
    }
    getSubKeys(o) {
        Object.keys(o).forEach((k) => {
            if (typeof o[k] === 'object') {
                this.keys.push(k);
                this.getSubKeys(o[k]);
            }
            else {
                if (this.keys.length > 0)
                    this.subkeys.push(this.keys + '.' + k);
                else
                    this.subkeys.push(k);
                this.keyValues.push(o[k]);
            }
        });
        this.keys.pop();
    }
    getKeys(o) {
        Object.keys(o).forEach((k) => {
            this.keys.push(k);
            this.values.push(o[k]);
        });
    }
    showDate(x) {
        if (typeof x == 'number' && x.toString().length == 10 && new Date(x).getTime() > 0) {
            return `(${this.dateTimeManager.formatTime(new Date(x * 1000))} | ${x})`;
        }
    }
    reloadLogger() {
        this.$emit('reloadLogger');
    }
};
__decorate([
    Prop({ type: Object })
], LastDataInfoComponent.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Object })
], LastDataInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], LastDataInfoComponent.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], LastDataInfoComponent.prototype, "isLayoutList", void 0);
LastDataInfoComponent = __decorate([
    Component({})
], LastDataInfoComponent);
export default LastDataInfoComponent;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import { Source } from '@/entities/models/Source';
import SourceRepository from '@/services/repository/SourceRepository';
import CreateRuleWarning from '@/views/components/notification/CreateRuleWarning.vue';
var sourceRepository;
let AssignSourceList = class AssignSourceList extends Vue {
    constructor() {
        super(...arguments);
        this.sources = [];
        this.selectedSources = [];
        this.existingNames = [];
        this.errorFields = [];
        this.createRuleWarningVisible = false;
        this.createdSourcesIds = [];
    }
    onLoggersLoaded() {
        this.processData();
    }
    created() {
        sourceRepository = new SourceRepository(this);
        this.processData();
    }
    processData() {
        this.sources = this.loggers.map((x) => this.newSource(x));
    }
    getSelectedSource(source) {
        return this.selectedSources.find((x) => x.logger.companyLoggerId == source.logger.companyLoggerId);
    }
    getSelectedSourceIndex(source) {
        return this.selectedSources.findIndex((x) => x.logger.companyLoggerId == source.logger.companyLoggerId);
    }
    selectSources(selectedSources, source) {
        this.selectedSources = selectedSources;
        this.existingNames = this.selectedSources.map((x) => false);
        this.errorFields = this.selectedSources.map((x) => false);
    }
    newSource(logger) {
        let source = new Source();
        source.name = '';
        source.description = '';
        source.logger = logger;
        return source;
    }
    async validateBeforeSubmit(fromAutofill = false) {
        if (this.selectedSources.filter((x) => !x.name).length > 0 && !fromAutofill) {
            this.$buefy.dialog.confirm({
                title: `${this.$t('component.logger.assignSourceList.list.emptyNamesDialog.title')}`,
                message: this.$tc('component.logger.assignSourceList.list.emptyNamesDialog.message', this.selectedSources.filter((x) => x.name.length <= 0).length).toString(),
                confirmText: `${this.$t('generals.yes')}`,
                cancelText: `${this.$t('component.logger.assignSourceList.list.emptyNamesDialog.skip')}`,
                type: 'is-primary',
                hasIcon: true,
                onConfirm: () => {
                    this.fillNamesWithSerialNumbers();
                    this.validateBeforeSubmit(true);
                },
                onCancel: () => {
                    this.validateBeforeSubmit(true);
                }
            });
            return;
        }
        if (this.selectedSources.filter((x) => x.name).length > 0) {
            if (!this.existingNames.includes(true)) {
                this.checkAllNames((success) => {
                    if (success) {
                        this.createSourcesOnServer((responseSources) => {
                            this.createdSourcesIds = responseSources.map((x) => x.id);
                            this.showSuccessToast();
                            if (!this.inWizard)
                                this.createRuleWarningVisible = true;
                            else {
                                this.$emit('sourcesCreated', true);
                            }
                        });
                    }
                    else {
                        this.showExistingNameToast();
                    }
                });
            }
            else {
                this.showExistingNameToast();
            }
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.all_names_empty').toString());
            return;
        }
    }
    warningClosed() {
        this.$emit('sourcesCreated', true);
    }
    showExistingNameToast() {
        VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString());
    }
    showSuccessToast() {
        VueUtilities.openSuccessToast(this, this.$t('component.logger.assignSourceList.list.table.msg.created').toString());
    }
    checkAllNames(completion) {
        var i = 0;
        let allPromises = [];
        for (i = 0; i < this.selectedSources.length; i++) {
            if (this.selectedSources[i].name)
                allPromises.push(sourceRepository.checkIfNameExists(this.selectedSources[i].name));
        }
        Promise.all(allPromises).then((response) => {
            let result = response.filter((x) => x.Exist == true).length == 0;
            completion(result);
        });
    }
    createSourcesOnServer(completion) {
        var i = 0;
        let allPromises = [];
        for (i = 0; i < this.selectedSources.length; i++) {
            if (this.selectedSources[i].name)
                allPromises.push(this.createSource(i));
        }
        Promise.all(allPromises).then((response) => {
            completion(response);
        });
    }
    fillNamesWithSerialNumbers() {
        var i = 0;
        this.errors.items = [];
        for (i; i < this.selectedSources.length; i++) {
            if (!(this.selectedSources[i].name && this.selectedSources[i].name.length > 0)) {
                this.selectedSources[i].name = this.selectedSources[i].logger.serialNumber;
                this.checkName(this.selectedSources[i], i);
            }
        }
    }
    async checkName(source, index) {
        let checkResponse = await sourceRepository.checkIfNameExists(this.selectedSources[index].name);
        if (source.name && this.selectedSources.filter((x) => x.name.trim() == source.name.trim()).length > 1)
            checkResponse.Exist = true;
        this.existingNames[index] = checkResponse.Exist;
        source.additionalProperties.hasErrorField = this.assignFieldError(index);
        source.additionalProperties.errorMsg = this.assignFieldErrorMsg(index);
    }
    async createSources() {
        if (this.selectedSources.length > 0) {
            this.validateBeforeSubmit();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.empty_logger').toString());
            return;
        }
    }
    async createSource(index) {
        let sourceDto = {
            Name: this.selectedSources[index].name
        };
        sourceDto.LoggerId = this.selectedSources[index].logger.companyLoggerId;
        sourceDto.Description = this.selectedSources[index].description;
        return sourceRepository.createSource(sourceDto);
    }
    assignFieldError(index) {
        if (this.errors.has(this.$t('component.location.create.name') + index.toString()) ||
            this.existingNames[index] == true)
            return true;
        else
            return false;
    }
    assignFieldErrorMsg(index) {
        if (this.errors.has(this.$t('component.location.create.name') + index.toString()))
            return this.$t('component.logger.assignSourceList.list.table.msg.empty_name').toString();
        else if (this.existingNames[index] == true)
            return this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString();
        else
            return null;
    }
};
__decorate([
    Prop({ type: Array })
], AssignSourceList.prototype, "loggers", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AssignSourceList.prototype, "inWizard", void 0);
__decorate([
    Watch('loggers')
], AssignSourceList.prototype, "onLoggersLoaded", null);
AssignSourceList = __decorate([
    Component({ components: { CreateRuleWarning } })
], AssignSourceList);
export default AssignSourceList;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
var authenticationCalls;
let ChangePasswordModal = class ChangePasswordModal extends Vue {
    constructor() {
        super(...arguments);
        this.currentPassword = null;
        this.newPassword = null;
        this.confirmPassword = null;
    }
    created() {
        authenticationCalls = new AuthenticationCalls(this);
    }
    closeModal() {
        this.$emit('modalClosed');
    }
    async changePassword() {
        const passwordValid = await this.$refs.passwordFields.validate();
        this.$validator.validateAll().then(async (result) => {
            if (result && passwordValid) {
                this.$emit('changePassword', this.currentPassword, this.newPassword);
            }
        });
    }
};
__decorate([
    Prop({ type: String })
], ChangePasswordModal.prototype, "email", void 0);
ChangePasswordModal = __decorate([
    Component({ components: { ValidationObserver, ValidationProvider } })
], ChangePasswordModal);
export default ChangePasswordModal;

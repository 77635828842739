import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
let ChangeRequestsList = class ChangeRequestsList extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.ChangeRequestsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.ChangeRequestsSortField.defaultValue;
    }
    created() { }
};
__decorate([
    Prop({ type: Array })
], ChangeRequestsList.prototype, "changeRequests", void 0);
__decorate([
    Prop({ type: Object })
], ChangeRequestsList.prototype, "dateTimeManager", void 0);
ChangeRequestsList = __decorate([
    Component({})
], ChangeRequestsList);
export default ChangeRequestsList;

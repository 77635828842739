import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import TierUsageTable from '@/views/components/admin/tiers/TierUsageTable.vue';
import CompanyTierDetail from '@/views/components/admin/tiers/CompanyTierDetail.vue';
import CompanyTierDetailForm from '@/views/components/admin/tiers/CompanyTierDetailForm.vue';
let ActiveCompanyTierDetail = class ActiveCompanyTierDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
    }
    editTier() {
        this.isEditing = !this.isEditing;
    }
    updateTier(dto) {
        this.$emit('updateTier', dto);
    }
};
__decorate([
    Prop({ type: Object })
], ActiveCompanyTierDetail.prototype, "activeTier", void 0);
__decorate([
    Prop({ type: Object })
], ActiveCompanyTierDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], ActiveCompanyTierDetail.prototype, "company", void 0);
ActiveCompanyTierDetail = __decorate([
    Component({
        components: {
            CompanyTierDetail,
            CompanyTierDetailForm,
            TierUsageTable
        }
    })
], ActiveCompanyTierDetail);
export default ActiveCompanyTierDetail;

export default class AdminCommand {
    constructor() {
        this.additionalProperties = {
            isOnline: 'false'
        };
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminCommand.define(et));
        }
        else if (data != null) {
            return AdminCommand.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let command = new AdminCommand();
        command.id = data.Id;
        command.type = data.Type;
        command.allowMultiple = data.AllowMultiple;
        command.createdAt = data.CreatedAt;
        command.scheduleAt = data.ScheduleAt;
        command.confirmedAt = data.ConfirmedAt;
        command.disabledAt = data.DisabledAt;
        command.progress = data.Progress;
        command.updatedAt = data.UpdatedAt;
        if (data.Data)
            command.data = data.Data;
        return command;
    }
}

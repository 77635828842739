import moment from 'moment-timezone';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import 'moment/locale/sk';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import i18n from '@/lang/lang';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
var userSettingsRepository;
/**
 * Clock format enum
 */
export var ClockFormat;
(function (ClockFormat) {
    ClockFormat["12h"] = " hh:mm A";
    ClockFormat["24h"] = " HH:mm";
})(ClockFormat || (ClockFormat = {}));
const DATE_FORMATS = [
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'DD.MM.YYYY',
    'MM.DD.YYYY',
    'YYYY. MM. DD.',
    'DD-MM-YYYY',
    'MM-DD-YYYY'
];
const DEFAULT_TIMEZONE = moment.tz.guess();
const DEFAULT_CLOCK_FORMAT = '24h';
const DEFAULT_LOCALE = 'sk';
/**
 * Date time manager
 */
export default class DateTimeManager {
    constructor(ctx) {
        userSettingsRepository = UserSettingsRepository.getInstance(ctx);
    }
    /**
     * Asynchronous static function to create new instance of DateTimeManager
     * @param ctx Application context
     * @returns instance of DateTimeManager
     */
    static async CreateManager(ctx, useDefaultSettings = false) {
        let manager = new this(ctx);
        if (useDefaultSettings == false) {
            await manager.loadSettings();
        }
        else {
            manager.setMomentLocale(DEFAULT_LOCALE);
            manager.setMomentTimezone(DEFAULT_TIMEZONE);
        }
        return manager;
    }
    static minutesToDuration(minutes) {
        if (!minutes || minutes == -1)
            return i18n.t('generals.na').toString();
        var days = Math.floor(minutes / 1440);
        var hours = Math.floor(minutes / 60) % 24;
        var minutes = minutes % 60;
        return ((days > 0 ? i18n.tc('time.day', days) + ' ' : '') +
            (hours > 0 ? i18n.tc('time.hour', hours) + ' ' : '') +
            (minutes > 0 ? i18n.tc('time.minute', minutes) : ''));
    }
    areSameDate(date1, date2) {
        let usedTimezone = this.getSelectedTimezone();
        const momD1 = moment(date1).tz(usedTimezone);
        const momD2 = moment(date2).tz(usedTimezone);
        return momD1.date() === momD2.date();
    }
    static timestampToDuration(timestamp) {
        if (timestamp == -1 || timestamp == null)
            return i18n.t('generals.na').toString();
        var days = Math.floor(timestamp / 86400);
        var hours = Math.floor(timestamp / 3600) % 24;
        var minutes = Math.floor(timestamp / 60) % 60;
        var seconds = timestamp % 60;
        return ((days <= 0 ? '' : days + 'd ') +
            (hours <= 0 && days <= 0 ? '' : hours + 'h ') +
            (minutes <= 0 && hours <= 0 && days <= 0 ? '0 min' : minutes + 'min '));
    }
    /**
     * Gets all month names
     * @returns all month names
     */
    getMonthNames() {
        return moment.months();
    }
    /**
     * Gets all timezones from moment.js
     * @returns Array of timezones names
     */
    getAllTimezones() {
        return moment.tz.names();
    }
    /**
     * Filter UTC and Europe timezones from moment.js
     * @returns Array of timezones names
     */
    getLimitedTimezones() {
        return moment.tz.names().filter((name) => name.toLowerCase().includes('europe/') || name.toLowerCase() === 'utc');
    }
    /**
     * Gets all supported date formats
     * @returns Array of all supported date formats
     */
    getAllDateFormats() {
        return DATE_FORMATS;
    }
    /**
     * Gets all clock formats identifiers
     * @returns Array of clock formats [12h,24h]
     */
    getAllClockFormats() {
        return Object.keys(ClockFormat);
    }
    /**
     * Gets selected timezone
     * @returns selected timezone
     */
    getSelectedTimezone() {
        return ((this._settings || {}).datetime || {}).timezone || DEFAULT_TIMEZONE;
    }
    /**
     * Gets selected date format
     * @returns selected date format
     */
    getSelectedDateFormat() {
        return ((this._settings || {}).datetime || {}).dateFormat || DATE_FORMATS[2];
    }
    /**
     * Gets selected clock format
     * @returns selected clock format
     */
    getSelectedClockFormat() {
        return ((this._settings || {}).datetime || {}).clockFormat || DEFAULT_CLOCK_FORMAT;
    }
    /**
     * Gets show TZ preference
     * @returns selected clock format
     */
    getTzInClockFormatPreference() {
        return this._settings?.datetime?.tzInFormat == 'true';
    }
    /**
     * Gets date format object for chart date formatting
     * @returns ChartDateFormat object with user date preferences
     */
    getChartDateFormat() {
        const currentLocale = moment.locale();
        switch (currentLocale) {
            case 'sk':
                return this.createChartDateFormatForSK();
                break;
            case 'en':
                return this.createChartDateFormatForEN();
                break;
            default:
                return this.createChartDateFormatForEN();
                break;
        }
    }
    /**
     * Minutes to formatted hours
     * @param minutes
     * @returns
     */
    minutesToUTCFormattedHours(minutes) {
        const format = this.getSelectedClockFormat();
        return moment.utc().startOf('day').add(minutes, 'minutes').format(this.getValueForClockFormat(format));
    }
    isSummerTime() {
        return moment().tz(this.getSelectedTimezone()).isDST();
    }
    tzUsesSummerTime() {
        return (moment([2010, 12, 20]).tz(this.getSelectedTimezone()).isDST() !=
            moment([2010, 5, 20]).tz(this.getSelectedTimezone()).isDST());
    }
    createChartDateFormatForSK() {
        let clockFormat = this.getSelectedClockFormat();
        let hourFormat = clockFormat == '12h' ? 'hh' : 'HH';
        let signForClockFormat = clockFormat == '12h' ? 'A' : '';
        let formats = {
            second: `${hourFormat}:mm:ss ${signForClockFormat}`,
            minute: `${hourFormat}:mm ${signForClockFormat}`,
            hour: `${hourFormat}:mm ${signForClockFormat}`,
            day: 'DD. MMM',
            date: 'DD. MMM ',
            month: 'MMMM',
            year: 'YYYY'
        };
        return formats;
    }
    createChartDateFormatForEN() {
        let clockFormat = this.getSelectedClockFormat();
        let hourFormat = clockFormat == '12h' ? 'hh' : 'HH';
        let signForClockFormat = clockFormat == '12h' ? 'A' : '';
        let formats = {
            second: `${hourFormat}:mm:ss ${signForClockFormat}`,
            minute: `${hourFormat}:mm ${signForClockFormat}`,
            hour: `${hourFormat}:mm ${signForClockFormat}`,
            day: 'MMM DD',
            date: 'MMM DD',
            month: 'MMMM',
            year: 'YYYY'
        };
        return formats;
    }
    /**
     * Store selected timezone to user settings on server
     * @param timezone selected timezone name
     */
    async setSelectedTimezone(timezone) {
        this.setMomentTimezone(timezone);
        await this.saveSettings([UserSettingsKeys.DATETIME, UserSettingsKeys.TIMEZONE], timezone);
        await this.setSelectedFormat();
    }
    /**
     * Store selected date format to user settings on server
     * @param dateFormat selected date format
     */
    async setSelectedDateFormat(dateFormat) {
        await this.saveSettings([UserSettingsKeys.DATETIME, UserSettingsKeys.DATEFORMAT], dateFormat);
        await this.setSelectedFormat();
    }
    /**
     * Store selected clock format to user settings on server
     * @param clockFormat selected clock format
     */
    async setSelectedClockFormat(clockFormat) {
        await this.saveSettings([UserSettingsKeys.DATETIME, UserSettingsKeys.CLOCKFORMAT], clockFormat);
        await this.setSelectedFormat();
    }
    /**
     * Store selected clock format to user settings on server
     * @param clockFormat selected clock format
     */
    async setTzInClockFormatPreference(tzInFormat) {
        await this.saveSettings([UserSettingsKeys.DATETIME, UserSettingsKeys.TZ_IN_FORMAT], tzInFormat);
        await this.setSelectedFormat();
    }
    formatTimeInInput(date) {
        let usedFormat = this.getFormat(null, '');
        return moment(date).format(usedFormat);
    }
    formatTimeRangeInInput(date) {
        let usedFormat = this.getFormat(null, '');
        let formatted = [null, null];
        if (date.length > 0)
            formatted[0] = moment(date[0]).format(usedFormat);
        if (date.length > 1)
            formatted[1] = moment(date[1]).format(usedFormat);
        return formatted.join(' - ');
    }
    formatDateInInput(date) {
        let usedFormat = this.getFormat(null, '');
        return moment(date).format(usedFormat);
    }
    getMonthFilter(date) {
        let filters = {
            start: null,
            stop: null,
            startTS: null,
            stopTS: null
        };
        let startDate = moment(date);
        filters.startTS = startDate.unix();
        //asi start stop uz vobec netreba, iba TS
        filters.start = startDate.tz('UTC', false).toISOString();
        let endDate = moment(date).endOf('month');
        filters.stopTS = endDate.unix();
        filters.stop = endDate.tz('UTC', true).toISOString();
        return filters;
    }
    formatStartDateForReports(date) {
        let usedTimezone = this.getSelectedTimezone();
        let startDate = moment(date).tz(usedTimezone, true);
        return { ts: startDate.unix(), text: startDate.tz('UTC', false).toISOString() };
    }
    formatEndDateForReports(date, setEndOfDay = false) {
        let usedTimezone = this.getSelectedTimezone();
        let endDate = moment(date).tz(usedTimezone, true);
        if (setEndOfDay === true) {
            endDate = endDate.endOf('day');
        }
        return { ts: endDate.unix(), text: endDate.tz('UTC', false).toISOString() };
    }
    formatDateRange(dateArr) {
        let usedTimezone = this.getSelectedTimezone();
        let startDate = moment.tz(dateArr[0], usedTimezone);
        let endDate = moment.tz(dateArr[1], usedTimezone);
        return [startDate.toDate(), endDate.toDate()];
    }
    /**
     * Formats month name in input
     * @param date given date object from input field
     * @returns formatted date string (eg. January 2020)
     */
    formatMonthNameInInput(date) {
        return moment(date.getMonth() + 1, 'M').format('MMMM') + ' ' + moment(date).year();
    }
    getTimeStringForInputDate(date) {
        let usedTimezone = this.getSelectedTimezone();
        let usedFormat = this.getFormat(null, null);
        let localDate = moment.tz(moment(date).format(usedFormat), usedFormat, usedTimezone).toDate();
        return localDate.toISOString();
    }
    /**
     * Formats given date (default value for params: value from user settings)
     * @param date date value
     * @param [timezone] timezone name (default: value from user settings)
     * @param [format] date format
     * @param [clock] clock format
     * @returns Formatted date string
     */
    formatTime(date, timezone, format, clock, showTz) {
        if (!date || date.getFullYear() === 1970 || date.getFullYear() === 1 || !isFinite(date))
            return '-';
        let usedFormat = this.getFormat(format, clock, showTz);
        let usedTimezone = timezone || this.getSelectedTimezone();
        return moment(date).tz(usedTimezone).format(usedFormat);
    }
    getTimeDifference(date) {
        let differenceInSeconds = Math.floor(new Date().getTime() / 1000 - date.getTime() / 1000);
        var hours = Math.floor(differenceInSeconds / 3600) % 24;
        var minutes = Math.floor(differenceInSeconds / 60) % 60;
        var seconds = differenceInSeconds % 60;
        let timeUnit = { time: null, unit: null };
        if (hours > 0) {
            timeUnit.time = hours;
            if (hours == 1) {
                timeUnit.unit = 'hour';
            }
            else {
                timeUnit.unit = 'hours';
            }
        }
        else if (minutes > 0) {
            timeUnit.time = minutes;
            if (minutes == 1) {
                timeUnit.unit = 'minute';
            }
            else {
                timeUnit.unit = 'minutes';
            }
        }
        else {
            timeUnit.time = seconds;
            timeUnit.unit = 'seconds';
        }
        return timeUnit;
    }
    getTimeDifferenceBySegment(date) {
        let differenceInSeconds = Math.floor(new Date().getTime() / 1000 - date.getTime() / 1000);
        var days = Math.floor(differenceInSeconds / 86400);
        var hours = Math.floor(differenceInSeconds / 3600) % 24;
        var minutes = Math.floor(differenceInSeconds / 60) % 60;
        let timeSegments = {
            days: null,
            hours: null,
            minutes: null,
            seconds: null
        };
        if (days > 0) {
            timeSegments.days = days;
        }
        if (hours > 0) {
            timeSegments.hours = hours;
        }
        if (minutes > 0) {
            timeSegments.minutes = minutes;
        }
        return timeSegments;
    }
    formatTimeOnly(date) {
        let usedFormat = this.getValueForClockFormat(this.getSelectedClockFormat()) + ':ss';
        let usedTimezone = this.getSelectedTimezone();
        return moment(date).tz(usedTimezone).format(usedFormat);
    }
    /**
     * Returns used timezone offset in minutes
     * @returns timezone offset in minutes
     */
    getUsedTzOffsetInMinutes() {
        let usedTimezone = this.getSelectedTimezone();
        return moment().tz(usedTimezone).utcOffset();
    }
    async saveSettings(keyPath, value) {
        this._settings = await userSettingsRepository.saveUserSetting(keyPath, value);
        this.setMomentLocale(this._settings.language);
        this.setMomentTimezone(this._settings.datetime.timezone);
    }
    /**
     * Loads user settings
     */
    async loadSettings() {
        this._settings = await userSettingsRepository.loadUserSettings();
        this.setMomentLocale(this._settings.language);
        this.setMomentTimezone(this._settings.datetime.timezone);
    }
    /**
     * Creates format string
     * @param [format] date format
     * @param [clock] clock format
     * @returns format string
     */
    getFormat(format, clock, showTz) {
        let tzPrefix = (showTz !== false && this.getTzInClockFormatPreference() == true) || showTz === true ? ' zz' : '';
        return ((format != '' ? format || this.getSelectedDateFormat() : '') +
            (clock != '' ? this.getValueForClockFormat(clock || this.getSelectedClockFormat()) + tzPrefix : ''));
    }
    /**
     * Sets selected format for formatter
     */
    async setSelectedFormat() {
        this._settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.DATETIME, UserSettingsKeys.FORMAT], this.getFormat());
    }
    /**
     * Gets value for clock format
     * @param clockFormat clock format identifier
     * @returns value for clock format
     */
    getValueForClockFormat(clockFormat) {
        switch (clockFormat) {
            case '12h':
                return ClockFormat['12h'];
                break;
            case '24h':
                return ClockFormat['24h'];
                break;
            default:
                throw 'Unknown clock format: ' + clockFormat;
        }
    }
    /**
     * Sets locale for moment.js based on app locale
     * @param appLocale: locale from user settings
     */
    setMomentLocale(appLocale) {
        moment.locale(appLocale);
    }
    setMomentTimezone(timezone) {
        //moment.tz.setDefault(timezone);
    }
}

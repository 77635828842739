import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import ContactUsForm from '@/views/components/feedback/ContactUsForm.vue';
var releaseNotes = require('@/release-notes');
let ReleaseNotesComponent = class ReleaseNotesComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.releaseNotes = releaseNotes;
        this.gitVersion = VERSION;
        this.gitCommit = COMMITHASH;
        this.buildDate = BUILD_DATE;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
    }
};
ReleaseNotesComponent = __decorate([
    Component({ components: { ContactUsForm } })
], ReleaseNotesComponent);
export default ReleaseNotesComponent;

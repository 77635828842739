import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import BoundaryManagementComponent from '@/views/components/boundary/BoundaryManagementComponent.vue';
let BoundaryModal = class BoundaryModal extends Vue {
    mounted() { }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    saveBoundaries(boundaries) {
        this.$emit('saveBoundaries', boundaries);
        this.resultRetrieved(true);
    }
};
__decorate([
    Prop({ type: Array })
], BoundaryModal.prototype, "boundaries", void 0);
__decorate([
    Prop({ type: Array })
], BoundaryModal.prototype, "eventTypes", void 0);
BoundaryModal = __decorate([
    Component({ components: { BoundaryManagementComponent } })
], BoundaryModal);
export default BoundaryModal;

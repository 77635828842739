import SessionStorage from '@/services/repository/SessionStorage';
import { UsersTier } from './UsersTier';
import TiersRepository from '@/services/repository/TiersRepository';
import VueUtilities from '@/services/VueUtilities';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import router from '@/router';
import AppConfig from '@/configLoader';
import ApiUserRoles from '../enums/ApiUserRoles';
import i18n from '@/lang/lang';
import { vxm } from '@/store/store.vuex';
var tiersRepository;
var authenticationCalls;
var vxDepartmentStore = vxm.departmentStore;
export class FeaturesManager {
    constructor(ctx) {
        this.LS_TAG = SessionStorage.keys.FEATURES;
        this.ctx = ctx;
        tiersRepository = new TiersRepository(ctx);
        authenticationCalls = new AuthenticationCalls(ctx);
    }
    static getInstance(ctx, reload = false) {
        if (!this._instance || reload) {
            this._instance = new this(ctx);
        }
        return this._instance;
    }
    async loadTier() {
        let response = await tiersRepository.getActiveTier();
        if (!(response instanceof UsersTier))
            throw i18n.t('error_messages.fatal_error');
        else {
            SessionStorage.storeData(this.LS_TAG, response);
            this.tier = response;
            return response;
        }
    }
    getTier() {
        if (this.tier)
            return this.tier;
        else {
            let cachedData = SessionStorage.loadData(this.LS_TAG);
            if (cachedData) {
                this.tier = cachedData.data;
                return this.tier;
            }
            else {
                //when there is no user tiers loaded, load tier for current department and reload current route.
                this.loadTier().then(() => {
                    router.go(0);
                });
            }
        }
    }
    isEnabled(feature) {
        if (AppConfig.getConfig().features.tiers === false ||
            ApiUserRoles.SYSTEM_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey ||
            ApiUserRoles.RESELLER_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey)
            return true;
        let currentTier = this.getTier();
        if (!currentTier)
            return false;
        let enabledFeatures = currentTier.enabledFeatures;
        return enabledFeatures.filter((x) => x.Name == feature).length > 0;
    }
    isAvailable(feature) {
        return this.isEnabled(feature);
    }
    static showFeatureDisabledMsg(ctx) {
        VueUtilities.openErrorToast(ctx, i18n.t('tiers.features.disabled_msg'));
    }
    static showQuotaReachedMsg(ctx) {
        VueUtilities.openErrorToast(ctx, i18n.t('tiers.features.quota_msg'));
    }
}

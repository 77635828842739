export default class Unit {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Unit.define(et));
        }
        else if (data != null) {
            return Unit.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let unit = new Unit();
        unit.identifier = data.Identifier;
        unit.name = data.Name;
        unit.unit = data.Unit;
        return unit;
    }
}

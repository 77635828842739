import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import AuthenticationCalls, { isLoginData } from '@/services/AuthenticationCalls';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import { vxm } from '@/store/store.vuex';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import AppConfig from '@/configLoader';
import UserRepository from '@/services/repository/UserRepository';
import Subjects from '@/services/permissions/Subjects';
import { AdminAction } from '@/services/permissions/Actions';
var userSettingsRepository;
var departmentRepository;
var authenticationCalls;
var vxDepartmentStore = vxm.departmentStore;
var userRepository;
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.name = 'Login';
        this.password = null;
        this.username = null;
        this.inputSize = '';
        this.loginButtonDisabled = false;
        this.toUrl = null;
        this.gitBranchString = process.env.BRANCH;
        this.gitVersionString = process.env.VERSION;
        this.selectedDepartmentId = null;
    }
    get demoAllowed() {
        return AppConfig.getConfig().features.demo == true;
    }
    setDepartments(value) {
        vxDepartmentStore.departments = value;
    }
    getDepartments() {
        return vxDepartmentStore.departments;
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
    getSelectedDepartmentPermissions() {
        return vxDepartmentStore.selectedDepartmentPermissions;
    }
    // Return user enabled departments
    getUserEnabledDepartments() {
        return this.getDepartments().filter((department) => department.member.Enabled);
    }
    // Return user enabled department
    getUserEnabledAdminDepartment() {
        return this.getDepartments().find((department) => department.member.Enabled && department.member.Role.Key === ApiUserRoles.SYSTEM_ADMIN);
    }
    /**
     * Function which initializes selected and favorite department id in store
     * If user has favorite deparment in preferences, it's also his currently selected
     * If user hasn't favorite department, his currently selected department is first from list of departments
     * @param departments
     * @param favoriteDepartmentId
     * @returns currently selected department
     */
    setSelectedDepartment(departments, favoriteDepartmentId) {
        //Checking if the user is enabled in their favorite department
        let userEnabledFavDepartment = departments.find((x) => x.id === favoriteDepartmentId && x.member.Enabled);
        let userEnabledAdminDepartment = this.getUserEnabledAdminDepartment();
        //Choose one department from user enabled departments
        if (userEnabledAdminDepartment) {
            vxDepartmentStore.selectedDepartment = userEnabledAdminDepartment;
        }
        else if (userEnabledFavDepartment) {
            vxDepartmentStore.setSelectedDepartmentById(userEnabledFavDepartment.id);
            vxDepartmentStore.setFavoriteDepartmentById(userEnabledFavDepartment.id);
        }
        else {
            vxDepartmentStore.selectedDepartment = this.getUserEnabledDepartments()[0];
        }
        return this.getSelectedDepartment();
    }
    async created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        userRepository = new UserRepository(this);
    }
    mounted() {
        this.$refs.email.focus();
    }
    async loginSucessfulAsync() {
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        userSettingsRepository = UserSettingsRepository.getInstance(this, true);
        departmentRepository = new DepartmentRepository(this);
        let settings = await userSettingsRepository.loadUserSettings(true);
        // load departments to store
        let departments = (await departmentRepository.getDepartments()).getData().getData();
        let me = await userRepository.getCurrentUser();
        LoggedUserManager.saveIdOfLoggedUser(this, me.apiUserId);
        this.setDepartments(departments);
        if (departments.length > 0) {
            //checking and if user is not enabled in all department handle this situation
            if (this.getUserEnabledDepartments().length === 0) {
                this.$router
                    .push({
                    name: 'organizations',
                    params: {
                        lang: this.$route.params.lang
                    }
                })
                    .catch((err) => err);
            }
            else {
                // load selected and favorite department to store
                let preselectedDepartment = this.setSelectedDepartment(departments, settings.preferences.favoriteDepartmentId);
                vxDepartmentStore.selectedDepartmentRoleKey = preselectedDepartment.member.Role.Key;
                this.selectedDepartmentId = preselectedDepartment?.id;
                let user = await userRepository.getUser(me.apiUserId, this.selectedDepartmentId);
                /*TODO: Co ak user nebude mat ziadny department? Ak je to company admin,
                mali by sme ho navigovat na vytvorenie company.*/
                if (user.role.Key == ApiUserRoles.SYSTEM_ADMIN) {
                    let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
                    vxDepartmentStore.selectedDepartmentPermissions = impersonatedAbilities;
                    this.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
                }
                else {
                    user.role.Permissions.push('Notifications.Company.Presets.Edit');
                    vxDepartmentStore.selectedDepartmentPermissions = user.role.Permissions;
                    this.$ability.update(new AbilityManager(this.getSelectedDepartmentPermissions()).getAbilities().rules);
                }
                EventTypeRepository.getInstance(this, true); // reload instance of repository singleton class
                if (ApiUserRoles.SYSTEM_ADMIN != vxDepartmentStore.selectedDepartmentRoleKey &&
                    ApiUserRoles.RESELLER_ADMIN != vxDepartmentStore.selectedDepartmentRoleKey) {
                    await this.$featuresManager.loadTier();
                }
                let res = await departmentRepository.getDepartment(this.selectedDepartmentId);
                let companySettings = res.settings;
                this.$i18n.locale = settings.language;
                this.$validator.localize(settings.language);
                if (ApiUserRoles.SYSTEM_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey ||
                    ApiUserRoles.RESELLER_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey) {
                    this.$router
                        .push({
                        name: 'adminCompanies',
                        params: { lang: this.$route.params.lang }
                    })
                        .catch((err) => err);
                }
                else if ((ApiUserRoles.COMPANY_OWNER == vxDepartmentStore.selectedDepartmentRoleKey ||
                    ApiUserRoles.COMPANY_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey) &&
                    companySettings.setupRequired != false) {
                    this.$router
                        .push({
                        name: 'wizard',
                        params: {
                            departmentId: this.selectedDepartmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
                else if (this.toUrl) {
                    this.$router
                        .push({
                        path: this.toUrl,
                        params: {
                            departmentId: this.selectedDepartmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
                else {
                    this.$router
                        .push({
                        name: 'dashboard',
                        params: {
                            departmentId: this.selectedDepartmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
            }
        }
        else {
            this.$router
                .push({
                name: 'organizations',
                params: {
                    lang: this.$route.params.lang
                }
            })
                .catch((err) => err);
        }
    }
    async submitCredentialsAsync() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.loginButtonDisabled = true;
                let loginData = await authenticationCalls.login(this.username, this.password);
                if (isLoginData(loginData)) {
                    const sessionPassword = loginData.session;
                    const apiUserFullName = loginData.apiUserFullName;
                    const authName = loginData.apiUserUsername;
                    LoggedUserManager.saveLoginData(this, authName, sessionPassword, apiUserFullName);
                    this.loginSucessfulAsync();
                }
                else {
                    let loginFailedResponse = loginData;
                    if (loginFailedResponse.code) {
                        switch (loginFailedResponse.code) {
                            case ApiResponseCodes.UNAUTHORIZED:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.unauthorized').toString()}`);
                                break;
                            case ApiResponseCodes.WRONG_CREDENTIALS:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.wrong_credentials').toString()}`);
                                break;
                            case ApiResponseCodes.ACCOUNT_DISABLED:
                                let reasonParts = loginFailedResponse.message.split(':');
                                reasonParts.shift();
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_disabled', {
                                    reason: reasonParts.join(':')
                                }).toString()}`);
                                break;
                            case ApiResponseCodes.ACCOUNT_NOT_VERIFIED:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_not_verified')}`);
                                break;
                            default:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                                break;
                        }
                    }
                    else {
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                    }
                }
                this.loginButtonDisabled = false;
            }
        });
    }
};
Login = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.toUrl = to.params.toUrl;
            });
        }
    })
], Login);
export default Login;

import UserPreferences from './UserPreferences';
export class CookieClaimManager {
    constructor(ctx) {
        this.ctx = ctx;
        this.localSelection = [];
    }
    commit() {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.CookiesAccepted, this.localSelection.join(','));
    }
    addLocalClaim(option) {
        if (!this.localSelection.includes(option)) {
            this.localSelection.push(option);
        }
        return this;
    }
    readGivenClaim() {
        return UserPreferences.getPreference(UserPreferences.LocalStorageKeys.CookiesAccepted);
    }
    hasClaim(option) {
        let claims = this.readGivenClaim();
        return claims != null && claims.includes(option);
    }
    canUseAnalytics() {
        let claims = this.readGivenClaim();
        return (claims != null &&
            (claims.includes(CookieClaimOptions.ACCEPT_ALL_COOKIES) ||
                claims.includes(CookieClaimOptions.ACCEPT_ANALYTICS_COOKIES)));
    }
}
export var CookieClaimOptions;
(function (CookieClaimOptions) {
    CookieClaimOptions["ACCEPT_ALL_COOKIES"] = "0";
    CookieClaimOptions["ACCEPT_ESSENTIAL_COOKIES"] = "1";
    CookieClaimOptions["ACCEPT_ANALYTICS_COOKIES"] = "2";
})(CookieClaimOptions || (CookieClaimOptions = {}));

import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AdminRegisterLoggersQrParser from '@/views/components/admin/loggers/AdminRegisterLoggersQrParser.vue';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import VueUtilities from '@/services/VueUtilities';
import AddLoggersComponent from './AddLoggersComponent.vue';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import SourceRepository from '@/services/repository/SourceRepository';
var companyLoggerRepository;
var eventTypeRepository;
var sourceRepository;
let RegisterLoggersToDepartmentModal = class RegisterLoggersToDepartmentModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedLoggerIds = [];
        this.loggersToAssign = [];
        this.activeTab = 'nearbyScan';
        this.scanningMethod = 'nearbyScan';
        this.scannedLoggers = [];
        this.availableLoggers = [];
        this.choosedLoggers = [];
        this.cannotRegister = [];
        this.failedRegistration = [];
    }
    onLoggersLoaded() {
        this.loggersToAssign = this.assignedLoggers.filter((x) => this.selectedLoggerIds.includes(x.companyLoggerId));
    }
    get tableData() {
        return this.scannedLoggers.map((logger) => {
            if (this.cannotRegister.includes(logger.sn)) {
                return {
                    ...logger,
                    cannotRegister: true,
                    registrationFailed: false
                };
            }
            else if (this.failedRegistration.includes(logger.sn)) {
                return {
                    ...logger,
                    cannotRegister: false,
                    registrationFailed: true
                };
            }
            else {
                return {
                    ...logger,
                    cannotRegister: false,
                    registrationFailed: false
                };
            }
        });
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        sourceRepository = new SourceRepository(this);
        let withoutSource = this.assignedLoggers.filter((x) => !x.source.exists());
        if (withoutSource.length > 0) {
            this.loggersToAssign = withoutSource;
        }
    }
    async register(event) {
        if (this.scanningMethod === 'nearbyScan') {
            this.$refs.addLoggerComponent.validateBeforeSubmit();
            this.setScanMethod(null);
            return;
        }
        if (this.scannedLoggers.length == 0) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.emptyArray').toString());
            return;
        }
        let verifyPromises = [];
        this.scannedLoggers.forEach((logger) => {
            verifyPromises.push(companyLoggerRepository.adminVerifyLogger(logger.sn, logger.key));
        });
        await Promise.allSettled(verifyPromises).then(async (response) => {
            // 400 || 404
            this.cannotRegister = response
                .filter((result) => result.status === 'rejected' && this.isClientErrorResponse(result.reason.statusCode))
                .map((logger) => logger.reason.sn);
            if (this.cannotRegister.length > 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.verificationFailed').toString() + this.cannotRegister);
                return;
            }
            // 500
            this.failedRegistration = response
                .filter((result) => result.status === 'rejected' && this.isServerErrorResponse(result.reason.statusCode))
                .map((logger) => logger.reason.sn);
            if (this.failedRegistration.length > 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.verificationFailed').toString() +
                    this.failedRegistration);
                return;
            }
            let verifiedLoggers = response.map((result) => {
                return {
                    serialNumber: result.value.serialNumber,
                    companyLoggerId: result.value.companyLoggerId
                };
            });
            let activateResult = await companyLoggerRepository.activateLoggers(verifiedLoggers.map((logger) => logger.companyLoggerId));
            if (!activateResult) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.activationFailed').toString());
            }
            else {
                await this.createSourcesProcess(verifiedLoggers);
            }
            this.closeModal(event);
            this.$emit('reload-loggers');
        });
    }
    createSourcesProcess(verifiedLoggers) {
        eventTypeRepository.getAllTypes(true);
        let createdSourcesIds;
        try {
            this.createSourcesOnServer(verifiedLoggers, (responseSources) => {
                createdSourcesIds = responseSources.map((x) => x.id);
                if (createdSourcesIds.length > 0) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.registerLoggers.registrationSuccessful').toString());
                }
                else {
                    VueUtilities.openSuccessToast(this, `${this.$t('component.logger.modal.messages.add.all_active')}`);
                }
            });
        }
        catch (error) { }
    }
    createSourcesOnServer(verifiedLoggers, completion) {
        let allPromises = [];
        verifiedLoggers.forEach((logger) => {
            allPromises.push(this.createSource(logger));
        });
        Promise.all(allPromises).then((response) => {
            completion(response);
        });
    }
    async createSource(logger) {
        let sourceDto = {
            Name: logger.serialNumber,
            LoggerId: logger.companyLoggerId
        };
        return sourceRepository.createSource(sourceDto);
    }
    isClientErrorResponse(statusCode) {
        return statusCode === 400 || statusCode === 404;
    }
    isServerErrorResponse(statusCode) {
        return statusCode === 500;
    }
    removeFromList(serialNumberToBeDeleted) {
        this.scannedLoggers = this.scannedLoggers.filter((x) => x.sn !== serialNumberToBeDeleted);
        this.failedRegistration = this.failedRegistration.filter((x) => x !== serialNumberToBeDeleted);
    }
    setScanMethod(method) {
        this.scanningMethod = method;
        this.$emit('scannerModalActive', this.scanningMethod === 'scanner' ? true : false);
    }
    tabChanged(tabName) {
        this.activeTab = tabName;
        if (tabName === 'nearbyScan') {
            this.setScanMethod(tabName);
        }
    }
    dataUpdate(data) {
        this.scannedLoggers = this.scannedLoggers.concat(data);
    }
    closeModal(event) {
        this.$emit('close-modal', event);
    }
};
__decorate([
    Prop({ type: String })
], RegisterLoggersToDepartmentModal.prototype, "deviceId", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], RegisterLoggersToDepartmentModal.prototype, "assignedLoggers", void 0);
__decorate([
    Prop({ type: Array })
], RegisterLoggersToDepartmentModal.prototype, "sourceList", void 0);
__decorate([
    Prop({ type: Object })
], RegisterLoggersToDepartmentModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], RegisterLoggersToDepartmentModal.prototype, "inWizard", void 0);
__decorate([
    Watch('assignedLoggers')
], RegisterLoggersToDepartmentModal.prototype, "onLoggersLoaded", null);
RegisterLoggersToDepartmentModal = __decorate([
    Component({
        components: {
            AdminRegisterLoggersQrParser,
            AddLoggersComponent
        }
    })
], RegisterLoggersToDepartmentModal);
export default RegisterLoggersToDepartmentModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
import ReportingInterval, { getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
let ReportSettings = class ReportSettings extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.loggerIds = [];
        this.selectedSourceGroups = [];
        this.sourcesbyGroup = [];
        this.allMeasuredTimes = [];
    }
    get currentTypes() {
        if (this.reportPdfConfiguration) {
            return this.reportPdfConfiguration.eventTypes.map((x) => this.$t(`services.${x}`)).join(', ');
        }
        else {
            return null;
        }
    }
    async created() {
        if (this.reportPdfConfiguration) {
            this.loggerIds = this.reportPdfConfiguration.sourceIds;
            if (this.loggerIds) {
                this.loggerIds.forEach((x) => {
                    this.sources.forEach((y) => {
                        if (y.id == x) {
                            this.sourcesbyGroup.push(y);
                        }
                    });
                });
            }
            this.allMeasuredTimes = await getAllAvailableMeasureTimes(this, this.reportPdfConfiguration.interval);
        }
    }
    deleteConfigModal() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('reports.report_settings.deleteReportConfigModal.title')}`,
            message: this.$t('reports.report_settings.deleteReportConfigModal.message'),
            confirmText: `${this.$t('reports.report_settings.deleteReportConfigModal.confirm')}`,
            cancelText: `${this.$t('reports.report_settings.deleteReportConfigModal.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('deleteConfig');
            },
            onCancel: () => { }
        });
    }
    openReportSettingModal() {
        this.$emit('openReportSettingModal');
    }
    editSettings() {
        this.isEditing = !this.isEditing;
    }
    save() {
        this.$validator.validateAll().then((result) => {
            if (result) {
            }
        });
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    formatStatModel() {
        if (this.reportPdfConfiguration &&
            this.reportPdfConfiguration.statModels.findIndex((x) => x.includes('daily')) !== -1)
            return 'daily';
        else
            return 'average';
    }
    formatMeasureTime() {
        if (this.reportPdfConfiguration) {
            if (this.reportPdfConfiguration.interval == ReportingInterval.INTERVAL_24_00) {
                let date = new Date(0, 0, 0, 0);
                date.setMinutes(this.reportPdfConfiguration?.offset);
                return date.toTimeString().slice(0, 5);
            }
            else {
                let measurementOffsetIndex = this.reportPdfConfiguration.offset / 60;
                return this.allMeasuredTimes[measurementOffsetIndex];
            }
        }
        else {
            return null;
        }
    }
};
__decorate([
    Prop({ type: Object })
], ReportSettings.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettings.prototype, "reportPdfConfiguration", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettings.prototype, "sources", void 0);
ReportSettings = __decorate([
    Component({
        components: {}
    })
], ReportSettings);
export default ReportSettings;

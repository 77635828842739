import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import AppConfig from '@/configLoader';
import ApiLinks from '@/entities/ApiLinks';
import AxiosService from '@/services/api/AxiosService';
let DeviceMonitor = class DeviceMonitor extends Vue {
    constructor() {
        super(...arguments);
        this.logs = [];
        this.LogType = LogType;
    }
    created() {
        this.listenForLogs();
    }
    async beforeDestroy() {
        await this.connection.stop();
    }
    async resultRetrieved(event) {
        await this.connection.stop();
        this.$emit('modalClosed', event);
    }
    listenForLogs() {
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.DeviceDebug.Home}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.device ? this.device.deviceId : 'all');
        })
            .catch((error) => console.log(error));
        this.connection.on('DeviceRequest', (data) => {
            let parsedBody = data.RequestBody;
            let rawMsg = data;
            try {
                parsedBody = JSON.parse(data.RequestBody);
                rawMsg.RequestBody = parsedBody;
            }
            catch (error) { }
            let parsedMessage = {
                type: LogType.REQUEST,
                deviceId: data.DeviceId,
                messageId: data.RequestId,
                path: data.Path,
                body: parsedBody,
                headers: data.RequestHeaders,
                claims: data.Claims,
                time: new Date().getTime(),
                rawMsg: rawMsg
            };
            this.logs.push(parsedMessage);
        });
        this.connection.on('DeviceResponse', (data) => {
            let parsedBody = data.ResponseBody;
            let rawMsg = data;
            try {
                parsedBody = JSON.parse(data.ResponseBody);
                rawMsg.ResponseBody = parsedBody;
            }
            catch (error) { }
            let parsedMessage = {
                type: LogType.RESPONSE,
                deviceId: data.DeviceId,
                messageId: data.RequestId,
                path: data.Path,
                body: parsedBody,
                headers: data.ResponseHeaders,
                responseStatusCode: data.ResponseStatusCode,
                time: new Date().getTime(),
                rawMsg: rawMsg
            };
            this.logs.push(parsedMessage);
        });
    }
};
__decorate([
    Prop({ type: Object })
], DeviceMonitor.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], DeviceMonitor.prototype, "dateTimeManager", void 0);
DeviceMonitor = __decorate([
    Component({})
], DeviceMonitor);
export default DeviceMonitor;
var LogType;
(function (LogType) {
    LogType["REQUEST"] = "request";
    LogType["RESPONSE"] = "response";
})(LogType || (LogType = {}));

import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let WarningModal = class WarningModal extends Vue {
    constructor() {
        super(...arguments);
        this.reason = '';
    }
    created() { }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    get hasChanges() {
        if (this.reason.trim().length == 0)
            return false;
        else
            return true;
    }
    confirm() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.$emit('confirm', this.action, this.reason);
            }
        });
    }
};
__decorate([
    Prop({ type: String })
], WarningModal.prototype, "title", void 0);
__decorate([
    Prop({ type: String })
], WarningModal.prototype, "message", void 0);
__decorate([
    Prop({ type: String })
], WarningModal.prototype, "confirmBtn", void 0);
__decorate([
    Prop({ type: String })
], WarningModal.prototype, "action", void 0);
WarningModal = __decorate([
    Component({ components: {} })
], WarningModal);
export default WarningModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          staticClass: "locations-table",
          attrs: {
            "checked-rows": _vm.selectedSources,
            checkable: "",
            data: _vm.sources,
            striped: "",
            "sticky-header": "",
          },
          on: {
            "update:checkedRows": function ($event) {
              _vm.selectedSources = $event
            },
            "update:checked-rows": function ($event) {
              _vm.selectedSources = $event
            },
            check: _vm.selectSources,
          },
          scopedSlots: _vm._u([
            {
              key: "bottom-left",
              fn: function () {
                return [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.logger.assignSourceList.list.table.totalChecked"
                        )
                      )
                    ),
                  ]),
                  _vm._v(": " + _vm._s(_vm.selectedSources.length) + " "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "name",
              label: _vm.$t(
                "component.logger.assignSourceList.list.table.name"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (sources) {
                  return [
                    _vm.getSelectedSource(sources.row)
                      ? _c(
                          "b-field",
                          {
                            attrs: {
                              type: {
                                "is-danger": _vm.getSelectedSource(sources.row)
                                  .additionalProperties.hasErrorField,
                              },
                              message: _vm.getSelectedSource(sources.row)
                                .additionalProperties.errorMsg,
                            },
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                name:
                                  _vm.$t(
                                    "component.logger.assignSourceList.list.table.name"
                                  ) +
                                  _vm
                                    .getSelectedSourceIndex(sources.row)
                                    .toString(),
                                expanded: "",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.checkName(
                                    _vm.getSelectedSource(sources.row),
                                    _vm.getSelectedSourceIndex(sources.row)
                                  )
                                },
                              },
                              model: {
                                value: _vm.getSelectedSource(sources.row).name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.getSelectedSource(sources.row),
                                    "name",
                                    $$v
                                  )
                                },
                                expression:
                                  "getSelectedSource(sources.row).name",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "serialNumber",
              label: _vm.$t(
                "component.logger.assignSourceList.list.table.serialNumber"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (sources) {
                  return [_vm._v(_vm._s(sources.row.logger.serialNumber))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "macAddress",
              label: _vm.$t("component.logger.assignSourceList.list.table.mac"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (sources) {
                  return [_vm._v(_vm._s(sources.row.logger.macAddress))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "description",
              label: _vm.$t(
                "component.logger.assignSourceList.list.table.description"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (sources) {
                  return [
                    _vm.getSelectedSource(sources.row)
                      ? _c(
                          "b-field",
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                name: _vm.$t(
                                  "component.logger.assignSourceList.list.table.description"
                                ),
                                expanded: "",
                              },
                              model: {
                                value: _vm.getSelectedSource(sources.row)
                                  .description,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.getSelectedSource(sources.row),
                                    "description",
                                    $$v
                                  )
                                },
                                expression:
                                  "getSelectedSource(sources.row).description",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.logger.assignSourceList.list.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.createRuleWarningVisible
        ? _c("CreateRuleWarning", {
            attrs: { sourceIds: _vm.createdSourcesIds },
            on: { warningClosed: _vm.warningClosed },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
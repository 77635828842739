import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let EntitySelectionTable = class EntitySelectionTable extends Vue {
    constructor() {
        super(...arguments);
        this.selectedEntities = [];
    }
    get tableColumns() {
        return Object.keys(this.filters);
    }
    /**
     * Getter to dynamically filter table data.
     * Iterates over all table columns and finds match with table data.
     */
    get filteredTableData() {
        return this.tableData.filter((row) => {
            let overalMatch = true;
            this.tableColumns.forEach((column) => {
                let currMatch = this.filters[column]
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') === '' ||
                    this.getValue(column, row)
                        ?.toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(this.filters[column]);
                overalMatch = overalMatch && currMatch;
            });
            return overalMatch;
        });
    }
    created() {
        this.assignLocalSelected();
    }
    /**
     * Creates local copy of selected entities
     */
    assignLocalSelected() {
        this.selectedEntities = [...this.assignedEntities];
    }
    /**
     * Dynamically accesses nested object value.
     * @param path. Path to value, using dotted notation, e.g. logger.name
     * @param obj. Object from which value is retrieved.
     * @returns Object value.
     */
    getValue(path, obj) {
        let value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
        return value ? value : '-';
    }
    isCurrentlyChoosed(entity) {
        return this.selectedEntities.includes(entity);
    }
    /**
     * Checks if all rows are selected locally (when loaded data)
     */
    areSelectedAll() {
        return this.selectedEntities.length === this.tableData.length && this.tableData.length !== 0;
    }
    /**
     * Changes state of clicked entity locally (selected/unselected).
     * @param entity. Object from clicked row.
     */
    changeState(entity) {
        if (!this.selectedEntities.includes(entity)) {
            this.selectedEntities.unshift(entity);
        }
        else {
            this.selectedEntities.splice(this.selectedEntities.indexOf(entity), 1);
        }
        this.entitySelected();
    }
    /**
     * Changes state of all entities locally (selected/unselected).
     */
    changeStateAll() {
        if (!this.areSelectedAll()) {
            this.selectedEntities = [...this.tableData];
        }
        else {
            this.selectedEntities = [];
        }
        this.entitySelected();
    }
    entitySelected() {
        this.$emit('entitySelected', this.selectedEntities);
    }
    /**
     * If clickable row, reroutes to object detail
     */
    getLink(obj) {
        this.$emit('getLink', obj);
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], EntitySelectionTable.prototype, "tableData", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], EntitySelectionTable.prototype, "assignedEntities", void 0);
__decorate([
    Prop({ type: Object })
], EntitySelectionTable.prototype, "filters", void 0);
__decorate([
    Prop({ type: String })
], EntitySelectionTable.prototype, "choosedEntityColumn", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], EntitySelectionTable.prototype, "hasEditPermissions", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], EntitySelectionTable.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], EntitySelectionTable.prototype, "isLinkable", void 0);
EntitySelectionTable = __decorate([
    Component
], EntitySelectionTable);
export default EntitySelectionTable;

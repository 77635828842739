import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Source } from '@/entities/models/Source';
import { UserSettingsValues } from '@/entities/UserSettings';
let SourceGroupPicker = class SourceGroupPicker extends Vue {
    constructor() {
        super(...arguments);
        this.UserSettingsValues = UserSettingsValues;
    }
    get unassignedSources() {
        return Source.getUnassignedSources(this.sources);
    }
    get allSources() {
        return this.sources;
    }
    sourceGroupSelected(sourceGroupId) {
        if (this.selectedSourceGroupId !== sourceGroupId) {
            this.$router.replace({
                name: this.$route.name,
                query: { groupId: sourceGroupId },
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.$route.params.departmentId
                }
            });
            this.$emit('sourceGroupSelected', sourceGroupId);
        }
    }
    favoriteSelected(event, id) {
        event.stopPropagation();
        event.preventDefault();
        this.$emit('favoriteSelected', id);
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], SourceGroupPicker.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: String })
], SourceGroupPicker.prototype, "selectedSourceGroupId", void 0);
__decorate([
    Prop({ type: String, default: null })
], SourceGroupPicker.prototype, "favoriteGroupId", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], SourceGroupPicker.prototype, "sources", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], SourceGroupPicker.prototype, "showFavoriteStarIcon", void 0);
SourceGroupPicker = __decorate([
    Component
], SourceGroupPicker);
export default SourceGroupPicker;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import SessionStorage from '@/services/repository/SessionStorage';
import VueUtilities from '@/services/VueUtilities';
let RefreshSettingsModal = class RefreshSettingsModal extends Vue {
    constructor() {
        super(...arguments);
        this.isRefreshOn = false;
        this.refreshInt = null;
        this.tag = SessionStorage.keys.REFRESH_INTERVAL;
    }
    created() {
        this.refreshInt = this.refreshInterval;
        this.isRefreshOn = this.isRefreshEnabled;
    }
    saveRefreshSettings() {
        SessionStorage.storeData(this.tag[this.$route.params.dashboardKey], [this.refreshInt, this.isRefreshOn]);
        this.$emit('saveRefreshSettings');
        VueUtilities.openSuccessToast(this, this.$t('generals.changes_saved'));
    }
    resultRetrieved() {
        this.$emit('modalClosed');
    }
};
__decorate([
    Prop({ type: Number })
], RefreshSettingsModal.prototype, "refreshInterval", void 0);
__decorate([
    Prop({ type: Boolean })
], RefreshSettingsModal.prototype, "isRefreshEnabled", void 0);
RefreshSettingsModal = __decorate([
    Component({})
], RefreshSettingsModal);
export default RefreshSettingsModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb my-1",
              attrs: { "aria-label": "breadcrumb" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c(
                  "ul",
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _vm._m(0),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                        ]
                      ),
                    ]),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Company")
                      ? [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminCompanies",
                                      params: {
                                        lang: _vm.prevRoute.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "warehouse" },
                                  }),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.sidebar.companies"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.companyName())),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Feedback")
                      ? [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminFeedback",
                                      params: {
                                        lang: _vm.prevRoute.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("contact_form.feedback"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(_vm.prevRoute.params.feedbackId)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Reseller")
                      ? [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminResellers",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "box-open" } }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.sidebar.resellers"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.prevRoute.meta.name)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm.prevRoute == null ||
                        _vm.prevRoute.name.includes("User")
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminUsers",
                                    params: {
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "users" } }),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v(_vm._s(_vm.$t("admin.sidebar.users"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("li", { staticClass: "is-active" }, [
                      _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                        _vm._v(_vm._s(_vm.user.username)),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "is-flex" },
            [
              _c(
                "div",
                { staticClass: "mr-3" },
                [
                  !_vm.isLayoutList
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t("admin.component.layout.listView"),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "font-size": "1rem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLayout()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { type: "is-primary", icon: "stream" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t("admin.component.layout.tabView"),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "font-size": "1rem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLayout()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  type: "is-primary",
                                  icon: "layer-group fa-rotate-270",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "b-dropdown",
                {
                  attrs: {
                    position: "is-bottom-left",
                    "append-to-body": "",
                    "aria-role": "list",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.devices.detail.actions.title"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "is-dark-grey",
                                  staticStyle: { "font-size": "1rem" },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      type: "is-primary",
                                      icon: "ellipsis-v",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm.$ability.can(
                    _vm.$permActions.ADD,
                    _vm.$permSubjects.ADMIN_DEPARTMENTS_USERS
                  )
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: { "aria-role": "listitem" },
                          on: {
                            click: function ($event) {
                              return _vm.openAddDepartmentsModal()
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "warehouse" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.user.detail.actions.add_departments"
                                )
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isResellerAdmin
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: { "aria-role": "listitem" },
                          on: {
                            click: function ($event) {
                              return _vm.openInviteUserModal()
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "envelope" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.userAdministration.list.inviteUser_btn"
                                )
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      !_vm.isLayoutList
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "box tile is-child" },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "custom-layout-tabs",
                      attrs: { type: "is-boxed" },
                      on: {
                        input: function ($event) {
                          return _vm.changedActiveTab($event)
                        },
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.user.detail.userInfo.title"
                                ),
                                disabled: _vm.isLoading.AdminUserInfoComponent,
                              },
                            },
                            [
                              _vm.dateTimeManager
                                ? _c("AdminUserInfoComponent", {
                                    ref: "AdminUserInfoComponent",
                                    staticClass: "mb-5",
                                    attrs: {
                                      user: _vm.user,
                                      dateTimeManager: _vm.dateTimeManager,
                                      componentIsLoading:
                                        _vm.isLoading.AdminUserInfoComponent,
                                      departments: _vm.departments,
                                    },
                                    on: {
                                      verifyUser: _vm.openVerificationDialog,
                                      enableUser: function ($event) {
                                        return _vm.enableUser()
                                      },
                                      disableUser: _vm.openWarningModal,
                                      deleteNumber: function ($event) {
                                        return _vm.confirmNumberDelete()
                                      },
                                      saveChanges: _vm.saveChanges,
                                      openSuccessToast: _vm.openSuccessToast,
                                      reloadUser: _vm.reloadUser,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.user.detail.userSettings.title"
                                ),
                                disabled:
                                  _vm.isLoading.AdminUserCompanySettings,
                              },
                            },
                            [
                              _vm.dateTimeManager
                                ? _c("AdminUserSettingsEdit", {
                                    staticClass: "mb-5",
                                    attrs: {
                                      componentIsLoading:
                                        _vm.isLoading.AdminUserSettingsEdit,
                                      userId: _vm.user.id,
                                      adminUserSettings: _vm.adminUserSettings,
                                      departments: _vm.departments,
                                      dateTimeManager: _vm.dateTimeManager,
                                      units: _vm.units,
                                      allEventTypes: _vm.allEventTypes,
                                    },
                                    on: {
                                      openSuccessToast: _vm.openSuccessToast,
                                      reloadData: _vm.reloadData,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.user.detail.userCompanySettings.title"
                                ),
                                disabled:
                                  _vm.isLoading.AdminUserCompanySettings,
                              },
                            },
                            [
                              _vm.isLoading.AdminUserCompanySettings
                                ? [
                                    _c("b-skeleton", {
                                      attrs: { width: "20%", animated: true },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: { width: "40%", animated: true },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: { width: "80%", animated: true },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: { animated: true },
                                    }),
                                  ]
                                : _c("AdminUserCompanySettings", {
                                    staticClass: "mb-5",
                                    attrs: {
                                      componentIsLoading:
                                        _vm.isLoading.AdminUserCompanySettings,
                                      user: _vm.user,
                                      departmentUserSettings:
                                        _vm.departmentUserSettings,
                                      departments:
                                        _vm.departmentsWithResellerEntities,
                                      selectedDepartment:
                                        _vm.selectedDepartment,
                                      userId: _vm.user.id,
                                      adminUserSettings: _vm.adminUserSettings,
                                      dateTimeManager: _vm.dateTimeManager,
                                    },
                                    on: {
                                      openSuccessToast: _vm.openSuccessToast,
                                      reloadData: _vm.reloadData,
                                      selectDepartment: _vm.selectDepartment,
                                    },
                                  }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              staticClass: "mb-5",
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.user.detail.notificationSettings.title"
                                ),
                                disabled:
                                  _vm.isLoading.AdminUserCompanySettings,
                              },
                            },
                            [
                              _vm.departments.length === 0
                                ? [
                                    _c(
                                      "h2",
                                      { staticClass: "subtitle is-4 mb-1" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.detail.notificationSettings.title"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "pt-4" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.component.user.detail.notificationSettings.no_department"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                : _vm.isLoading.AdminRulesList
                                ? [
                                    _c("b-skeleton", {
                                      attrs: { width: "20%", animated: true },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: { width: "40%", animated: true },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: { width: "80%", animated: true },
                                    }),
                                    _c("b-skeleton", {
                                      attrs: { animated: true },
                                    }),
                                  ]
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "multi-header mb-5" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "is-flex" },
                                          [
                                            _c("div", [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "subtitle is-4 mb-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "admin.component.user.detail.notificationSettings.title"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "is-size-7 is-italic has-text-grey",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "notifications.configuration.rules_subtitle"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm.hasAdminRoleDepartment ||
                                            _vm.hasResellerRoleDepartment ||
                                            _vm.isResellerAdmin
                                              ? _c(
                                                  "b-dropdown",
                                                  {
                                                    staticClass: "pl-3",
                                                    attrs: {
                                                      scrollable: true,
                                                      "max-height": 300,
                                                      "aria-role": "list",
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.selectNotificationDepartment,
                                                      "active-change":
                                                        _vm.focusDepartmentName,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "trigger",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "buttons",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "is-justify-content-space-between control-overflow-btn",
                                                                      attrs: {
                                                                        label:
                                                                          _vm
                                                                            .notificationSelectedDepartment
                                                                            .companyName,
                                                                        "icon-right":
                                                                          "angle-down",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1756495361
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.notificationSelectedDepartment,
                                                      callback: function ($$v) {
                                                        _vm.notificationSelectedDepartment =
                                                          $$v
                                                      },
                                                      expression:
                                                        "notificationSelectedDepartment",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-dropdown-item",
                                                      {
                                                        attrs: {
                                                          custom: "",
                                                          "aria-role":
                                                            "listitem",
                                                        },
                                                      },
                                                      [
                                                        _c("b-input", {
                                                          ref: "departmentName",
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "generals.search"
                                                              ),
                                                            expanded: "",
                                                            "icon-right":
                                                              "close-circle",
                                                            "icon-right-clickable":
                                                              "",
                                                          },
                                                          on: {
                                                            "icon-right-click":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.departmentName =
                                                                  ""
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.departmentName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.departmentName =
                                                                $$v
                                                            },
                                                            expression:
                                                              "departmentName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.filteredDepartments,
                                                      function (department) {
                                                        return _c(
                                                          "b-dropdown-item",
                                                          {
                                                            key: department.companyId,
                                                            attrs: {
                                                              value: department,
                                                              "aria-role":
                                                                "listitem",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "media",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "media-content",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h3",
                                                                      {
                                                                        class:
                                                                          _vm.departmentsWithResellerEntities.find(
                                                                            function (
                                                                              x
                                                                            ) {
                                                                              return (
                                                                                x
                                                                                  .Department
                                                                                  .Id ===
                                                                                department.companyId
                                                                              )
                                                                            }
                                                                          )
                                                                            ? "has-text-weight-bold"
                                                                            : "",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              department.companyName
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _c(
                                                  "b-field",
                                                  { staticClass: "pl-3" },
                                                  [
                                                    _c(
                                                      "b-select",
                                                      {
                                                        on: {
                                                          input:
                                                            _vm.notificationsDepartmentSelected,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.notificationSelectedDepartmentId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.notificationSelectedDepartmentId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "notificationSelectedDepartmentId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.departmentsWithResellerEntities,
                                                        function (department) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: department
                                                                .Department.Id,
                                                              domProps: {
                                                                value:
                                                                  department
                                                                    .Department
                                                                    .Id,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    department
                                                                      .Department
                                                                      .Name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                ),
                                            _vm.hasRules &&
                                            _vm.activeTabNotifications ===
                                              _vm.NotificationTabs.GENERAL
                                              ? _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _c(
                                                      "b-tooltip",
                                                      {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "component.notification.rules_list.copy_rules.copy_tooltip_msg"
                                                          ),
                                                          position: "is-left",
                                                          type: "is-dark",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            on: {
                                                              click:
                                                                _vm.copyRulesDialog,
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                type: "is-primary",
                                                                icon: "copy",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.copiedRules.companyId !== null
                                              ? [
                                                  _vm.copiedRules.companyId !==
                                                  _vm.notificationSelectedDepartmentId
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "ml-3" },
                                                        [
                                                          _c(
                                                            "b-tooltip",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  "component.notification.rules_list.paste_rules.paste_tooltip_msg"
                                                                ),
                                                                position:
                                                                  "is-left",
                                                                type: "is-dark",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      _vm.confirmPasteRules,
                                                                  },
                                                                },
                                                                [
                                                                  _c("b-icon", {
                                                                    attrs: {
                                                                      type: "is-primary",
                                                                      icon: "paste",
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-tabs",
                                      {
                                        attrs: { type: "is-boxed" },
                                        on: { input: _vm.tabChanged },
                                        model: {
                                          value: _vm.activeTabNotifications,
                                          callback: function ($$v) {
                                            _vm.activeTabNotifications = $$v
                                          },
                                          expression: "activeTabNotifications",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-tab-item",
                                          {
                                            attrs: {
                                              value:
                                                _vm.NotificationTabs.GENERAL,
                                              label: _vm.$t(
                                                "notifications.configuration.tab_general"
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.hasAdminDepartmentSelectedInNotifications
                                              ? _c(
                                                  "div",
                                                  { staticClass: "pb-4" },
                                                  [
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "subtitle mb-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.use_timetable_title"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          [
                                                            _c("b-switch", {
                                                              attrs: {
                                                                size: "is-small",
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.onEnableTimetable,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.timetableEnabled,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.timetableEnabled =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "timetableEnabled",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "is-size-7 is-italic has-text-grey",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.use_timetable_subtitle"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("hr"),
                                            _vm.timetableEnabled &&
                                            !_vm.hasAdminDepartmentSelectedInNotifications
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("Timetable", {
                                                      ref: "timetableComponent",
                                                      attrs: {
                                                        availableTypes:
                                                          _vm.availableTypes,
                                                        currentUser: _vm.user,
                                                        schedules:
                                                          _vm.timetableSchedules,
                                                        presets: _vm.presets,
                                                        departmentUserSettings:
                                                          _vm.notificationDepartmentUserSettings,
                                                        departmentId:
                                                          _vm.notificationSelectedDepartmentId,
                                                        dateTimeManager:
                                                          _vm.dateTimeManager,
                                                      },
                                                      on: {
                                                        reloadSchedules:
                                                          _vm.reloadSchedules,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "title has-text-dark is-4 mb-0 pb-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.rules_title"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "is-size-7 is-italic has-text-grey pb-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.rules_subtitle"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "pt-4 pb-5 subtitle has-text-weight-semibold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.general_or_preset"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "b-dropdown",
                                                          {
                                                            attrs: {
                                                              "aria-role":
                                                                "list",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.applyPreset,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "trigger",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var active =
                                                                      ref.active
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "is-italic is-underlined clickable-text",
                                                                          attrs:
                                                                            {
                                                                              role: "button",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "notifications.configuration.general_or_preset_link"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "b-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: active
                                                                                    ? "caret-up"
                                                                                    : "caret-down",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1251524264
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.selectedPreset,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.selectedPreset =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "selectedPreset",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.presets,
                                                            function (preset) {
                                                              return _c(
                                                                "b-dropdown-item",
                                                                {
                                                                  key: preset.id,
                                                                  attrs: {
                                                                    "aria-role":
                                                                      "listitem",
                                                                    value:
                                                                      preset,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      preset.name
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.isChangingMode
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c("b-skeleton", {
                                                              attrs: {
                                                                width: "20%",
                                                                animated: true,
                                                              },
                                                            }),
                                                            _c("b-skeleton", {
                                                              attrs: {
                                                                width: "40%",
                                                                animated: true,
                                                              },
                                                            }),
                                                            _c("b-skeleton", {
                                                              attrs: {
                                                                width: "80%",
                                                                animated: true,
                                                              },
                                                            }),
                                                            _c("b-skeleton", {
                                                              attrs: {
                                                                animated: true,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : [
                                                          !_vm.hasRulesForEntities
                                                            ? _c(
                                                                "MeasuredDataNotification",
                                                                {
                                                                  attrs: {
                                                                    rule: _vm.singleMeasuredRule,
                                                                    types:
                                                                      _vm.measuredTypes,
                                                                    currentUser:
                                                                      _vm.user,
                                                                    selectedMeasuredRulesMode:
                                                                      _vm.selectedMeasuredRulesMode,
                                                                    tier: _vm.tier,
                                                                    scheduleId:
                                                                      _vm.defaultScheduleId,
                                                                    departmentId:
                                                                      _vm.notificationSelectedDepartmentId,
                                                                    autoSave: false,
                                                                    measuredRuleEnabled:
                                                                      _vm.measuredRuleEnabled,
                                                                  },
                                                                  on: {
                                                                    changeMode:
                                                                      _vm.changeMode,
                                                                    switchedValue:
                                                                      _vm.switchedValue,
                                                                    ruleChanged:
                                                                      _vm.ruleChanged,
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                "EntityMeasuredDataNotification",
                                                                {
                                                                  attrs: {
                                                                    rules:
                                                                      _vm.measuredRules,
                                                                    types:
                                                                      _vm.measuredTypes,
                                                                    currentUser:
                                                                      _vm.user,
                                                                    selectedMeasuredRulesMode:
                                                                      _vm.selectedMeasuredRulesMode,
                                                                    departmentId:
                                                                      _vm.notificationSelectedDepartmentId,
                                                                    tier: _vm.tier,
                                                                    autoSave: false,
                                                                  },
                                                                  on: {
                                                                    changeMode:
                                                                      _vm.changeMode,
                                                                    ruleChanged:
                                                                      _vm.ruleChanged,
                                                                  },
                                                                }
                                                              ),
                                                        ],
                                                    _c("hr"),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .DEVICE_OFFLINE
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .DEVICE_OFFLINE
                                                          ),
                                                          currentUser: _vm.user,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c("hr"),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .SOURCE_OFFLINE
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .SOURCE_OFFLINE
                                                          ),
                                                          currentUser: _vm.user,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c("hr"),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "subtitle has-text-dark",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "notifications.configuration.other_rules_title"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .LOW_BATTERY
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .LOW_BATTERY
                                                          ),
                                                          currentUser: _vm.user,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .CUSTOMER_ENDING_CALIBRATION
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .CUSTOMER_ENDING_CALIBRATION
                                                          ),
                                                          currentUser: _vm.user,
                                                          advancedAllowed: false,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .PDF_RECORDS_REGENERATED
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .PDF_RECORDS_REGENERATED
                                                          ),
                                                          currentUser: _vm.user,
                                                          advancedAllowed: false,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .AUTOMATIC_REPORTS_GENERATED
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .AUTOMATIC_REPORTS_GENERATED
                                                          ),
                                                          currentUser: _vm.user,
                                                          advancedAllowed: false,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .RECORDS_UPDATED
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .RECORDS_UPDATED
                                                          ),
                                                          currentUser: _vm.user,
                                                          advancedAllowed: false,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "GeneralNotificationComponent",
                                                      {
                                                        attrs: {
                                                          rule: _vm.getRule(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .LOGGER_STATE
                                                          ),
                                                          type: _vm.getType(
                                                            _vm
                                                              .NotificationSubscriptionType
                                                              .LOGGER_STATE
                                                          ),
                                                          currentUser: _vm.user,
                                                          advancedAllowed: false,
                                                          departmentId:
                                                            _vm.notificationSelectedDepartmentId,
                                                          autoSave: false,
                                                        },
                                                        on: {
                                                          ruleChanged:
                                                            _vm.ruleChanged,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "b-tab-item",
                                          {
                                            attrs: {
                                              value:
                                                _vm.NotificationTabs
                                                  .NON_WORKING_DAYS,
                                              label: _vm.$t(
                                                "notifications.configuration.tab_non_working"
                                              ),
                                            },
                                          },
                                          [
                                            _c("NonWorkingHours", {
                                              attrs: {
                                                availableTypes:
                                                  _vm.availableTypes,
                                                notificationSubscriptions:
                                                  _vm.notificationSubscriptions,
                                                presets: _vm.presets,
                                                schedules:
                                                  _vm.nonWorkingSchedules,
                                                departmentUserSettings:
                                                  _vm.notificationDepartmentUserSettings,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                currentUser: _vm.user,
                                              },
                                              on: {
                                                reloadSchedules:
                                                  _vm.reloadSchedules,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("hr"),
                                    _vm.hasAdminRoleDepartment &&
                                    _vm.hasAdminDepartmentSelectedInNotifications &&
                                    _vm.$ability.can(
                                      _vm.$permActions.READ,
                                      _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                                    ) &&
                                    _vm.activeTabNotifications ===
                                      _vm.NotificationTabs.GENERAL
                                      ? _c(
                                          "div",
                                          { staticClass: "pt-3" },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "subtitle has-text-weight-bold pb-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.notification.rules_list.admin_rules_title"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("AdminNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getAdminRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .ADMIN_LOGGER_CALIBRATION_ENDING
                                                ),
                                                type: _vm.getAdminType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .ADMIN_LOGGER_CALIBRATION_ENDING
                                                ),
                                                currentUser: _vm.user,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("AdminNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getAdminRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .FEEDBACK
                                                ),
                                                type: _vm.getAdminType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .FEEDBACK
                                                ),
                                                currentUser: _vm.user,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("AdminNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getAdminRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .ROLE_CHANGED
                                                ),
                                                type: _vm.getAdminType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .ROLE_CHANGED
                                                ),
                                                currentUser: _vm.user,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("AdminNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getAdminRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .CUSTOMER_REGISTERED
                                                ),
                                                type: _vm.getAdminType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .CUSTOMER_REGISTERED
                                                ),
                                                currentUser: _vm.user,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("AdminNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getAdminRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .ADMIN_LOGGER_STATE
                                                ),
                                                type: _vm.getAdminType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .ADMIN_LOGGER_STATE
                                                ),
                                                currentUser: _vm.user,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_NOTIFICATIONS_USER
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.user.detail.notifications.title"
                                ),
                                disabled:
                                  _vm.isLoading.AdminUserCompanySettings,
                              },
                            },
                            [
                              _c("NotificationsTable", {
                                attrs: {
                                  notifications: _vm.notificationsResult,
                                  departmentFilter: null,
                                  canDelete: false,
                                  inNotificationDepartmentPage: false,
                                  dateTimeManager: _vm.dateTimeManager,
                                },
                                on: {
                                  onOptionsChange: _vm.onOptionsChange,
                                  loadNotifications: _vm.loadNotifications,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.dateTimeManager &&
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminUserInfoComponent", {
                      ref: "AdminUserInfoComponent",
                      staticClass: "tile is-child box",
                      attrs: {
                        user: _vm.user,
                        dateTimeManager: _vm.dateTimeManager,
                        componentIsLoading:
                          _vm.isLoading.AdminUserInfoComponent,
                        departments: _vm.departments,
                      },
                      on: {
                        verifyUser: _vm.openVerificationDialog,
                        enableUser: function ($event) {
                          return _vm.enableUser()
                        },
                        disableUser: _vm.openWarningModal,
                        deleteNumber: function ($event) {
                          return _vm.confirmNumberDelete()
                        },
                        saveChanges: _vm.saveChanges,
                        openSuccessToast: _vm.openSuccessToast,
                        reloadUser: _vm.reloadUser,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dateTimeManager &&
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminUserSettingsEdit", {
                      staticClass: "tile is-child box",
                      attrs: {
                        componentIsLoading: _vm.isLoading.AdminUserSettingsEdit,
                        userId: _vm.user.id,
                        adminUserSettings: _vm.adminUserSettings,
                        departments: _vm.departments,
                        dateTimeManager: _vm.dateTimeManager,
                        units: _vm.units,
                        allEventTypes: _vm.allEventTypes,
                      },
                      on: {
                        openSuccessToast: _vm.openSuccessToast,
                        reloadData: _vm.reloadData,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    !_vm.isLoading.AdminRulesList &&
                    !_vm.isLoading.AdminUserCompanySettings
                      ? _c("AdminUserCompanySettings", {
                          staticClass: "tile is-child box",
                          attrs: {
                            componentIsLoading:
                              _vm.isLoading.AdminRulesList ||
                              _vm.isLoading.AdminUserCompanySettings,
                            user: _vm.user,
                            departmentUserSettings: _vm.departmentUserSettings,
                            departments: _vm.departmentsWithResellerEntities,
                            selectedDepartment: _vm.selectedDepartment,
                            userId: _vm.user.id,
                            adminUserSettings: _vm.adminUserSettings,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                          on: {
                            openSuccessToast: _vm.openSuccessToast,
                            reloadData: _vm.reloadData,
                            selectDepartment: _vm.selectDepartment,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
            )
              ? _c("div", { staticClass: "tile is-parent" }, [
                  _c(
                    "div",
                    { staticClass: "tile is-child box" },
                    [
                      _vm.departments.length === 0
                        ? [
                            _c("div", { staticClass: "mb-5" }, [
                              _c("h2", { staticClass: "subtitle is-4 mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.detail.notificationSettings.title"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "pt-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.detail.notificationSettings.no_department"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        : _vm.isLoading.AdminRulesList
                        ? [
                            _c("b-skeleton", {
                              attrs: { width: "20%", animated: true },
                            }),
                            _c("b-skeleton", {
                              attrs: { width: "40%", animated: true },
                            }),
                            _c("b-skeleton", {
                              attrs: { width: "80%", animated: true },
                            }),
                            _c("b-skeleton", { attrs: { animated: true } }),
                          ]
                        : [
                            _c("div", { staticClass: "multi-header mb-5" }, [
                              _c(
                                "div",
                                { staticClass: "is-flex" },
                                [
                                  _c("div", [
                                    _c(
                                      "h2",
                                      { staticClass: "subtitle is-4 mb-1" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.detail.notificationSettings.title"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "is-size-7 is-italic has-text-grey",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "notifications.configuration.rules_subtitle"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm.hasAdminRoleDepartment ||
                                  _vm.hasResellerRoleDepartment ||
                                  _vm.isResellerAdmin
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "pl-3",
                                          attrs: {
                                            scrollable: true,
                                            "max-height": 300,
                                            "aria-role": "list",
                                          },
                                          on: {
                                            input:
                                              _vm.selectNotificationDepartment,
                                            "active-change":
                                              _vm.focusDepartmentName,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "trigger",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "buttons",
                                                      },
                                                      [
                                                        _c("b-button", {
                                                          staticClass:
                                                            "is-justify-content-space-between control-overflow-btn",
                                                          attrs: {
                                                            label:
                                                              _vm
                                                                .notificationSelectedDepartment
                                                                .companyName,
                                                            "icon-right":
                                                              "angle-down",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1756495361
                                          ),
                                          model: {
                                            value:
                                              _vm.notificationSelectedDepartment,
                                            callback: function ($$v) {
                                              _vm.notificationSelectedDepartment =
                                                $$v
                                            },
                                            expression:
                                              "notificationSelectedDepartment",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                custom: "",
                                                "aria-role": "listitem",
                                              },
                                            },
                                            [
                                              _c("b-input", {
                                                ref: "departmentName",
                                                staticClass: "mb-2",
                                                attrs: {
                                                  placeholder:
                                                    _vm.$t("generals.search"),
                                                  expanded: "",
                                                  "icon-right": "close-circle",
                                                  "icon-right-clickable": "",
                                                },
                                                on: {
                                                  "icon-right-click": function (
                                                    $event
                                                  ) {
                                                    _vm.departmentName = ""
                                                  },
                                                },
                                                model: {
                                                  value: _vm.departmentName,
                                                  callback: function ($$v) {
                                                    _vm.departmentName = $$v
                                                  },
                                                  expression: "departmentName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.filteredDepartments,
                                            function (department) {
                                              return _c(
                                                "b-dropdown-item",
                                                {
                                                  key: department.companyId,
                                                  attrs: {
                                                    value: department,
                                                    "aria-role": "listitem",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "media" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "media-content",
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              class:
                                                                _vm.departmentsWithResellerEntities.find(
                                                                  function (x) {
                                                                    return (
                                                                      x
                                                                        .Department
                                                                        .Id ===
                                                                      department.companyId
                                                                    )
                                                                  }
                                                                )
                                                                  ? "has-text-weight-bold"
                                                                  : "",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    department.companyName
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _c(
                                        "b-field",
                                        { staticClass: "pl-3" },
                                        [
                                          _c(
                                            "b-select",
                                            {
                                              on: {
                                                input:
                                                  _vm.notificationsDepartmentSelected,
                                              },
                                              model: {
                                                value:
                                                  _vm.notificationSelectedDepartmentId,
                                                callback: function ($$v) {
                                                  _vm.notificationSelectedDepartmentId =
                                                    $$v
                                                },
                                                expression:
                                                  "notificationSelectedDepartmentId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.departmentsWithResellerEntities,
                                              function (department) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: department.Department
                                                      .Id,
                                                    domProps: {
                                                      value:
                                                        department.Department
                                                          .Id,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          department.Department
                                                            .Name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                  _vm.hasRules &&
                                  _vm.activeTabNotifications ===
                                    _vm.NotificationTabs.GENERAL
                                    ? _c(
                                        "div",
                                        { staticClass: "ml-3" },
                                        [
                                          _c(
                                            "b-tooltip",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "component.notification.rules_list.copy_rules.copy_tooltip_msg"
                                                ),
                                                position: "is-left",
                                                type: "is-dark",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  on: {
                                                    click: _vm.copyRulesDialog,
                                                  },
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      type: "is-primary",
                                                      icon: "copy",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.copiedRules.companyId !== null
                                    ? [
                                        _vm.copiedRules.companyId !==
                                        _vm.notificationSelectedDepartmentId
                                          ? _c(
                                              "div",
                                              { staticClass: "ml-3" },
                                              [
                                                _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "component.notification.rules_list.paste_rules.paste_tooltip_msg"
                                                      ),
                                                      position: "is-left",
                                                      type: "is-dark",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        on: {
                                                          click:
                                                            _vm.confirmPasteRules,
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            type: "is-primary",
                                                            icon: "paste",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                            _c(
                              "b-tabs",
                              {
                                attrs: { type: "is-boxed" },
                                on: { input: _vm.tabChanged },
                                model: {
                                  value: _vm.activeTabNotifications,
                                  callback: function ($$v) {
                                    _vm.activeTabNotifications = $$v
                                  },
                                  expression: "activeTabNotifications",
                                },
                              },
                              [
                                _c(
                                  "b-tab-item",
                                  {
                                    attrs: {
                                      value: _vm.NotificationTabs.GENERAL,
                                      label: _vm.$t(
                                        "notifications.configuration.tab_general"
                                      ),
                                    },
                                  },
                                  [
                                    !_vm.hasAdminDepartmentSelectedInNotifications
                                      ? _c("div", { staticClass: "pb-4" }, [
                                          _c(
                                            "h2",
                                            { staticClass: "subtitle mb-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.use_timetable_title"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                [
                                                  _c("b-switch", {
                                                    attrs: { size: "is-small" },
                                                    on: {
                                                      input:
                                                        _vm.onEnableTimetable,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.timetableEnabled,
                                                      callback: function ($$v) {
                                                        _vm.timetableEnabled =
                                                          $$v
                                                      },
                                                      expression:
                                                        "timetableEnabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "is-size-7 is-italic has-text-grey",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.use_timetable_subtitle"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("hr"),
                                    _vm.timetableEnabled &&
                                    !_vm.hasAdminDepartmentSelectedInNotifications
                                      ? _c(
                                          "div",
                                          [
                                            _c("Timetable", {
                                              ref: "timetableComponent",
                                              attrs: {
                                                availableTypes:
                                                  _vm.availableTypes,
                                                currentUser: _vm.user,
                                                schedules:
                                                  _vm.timetableSchedules,
                                                presets: _vm.presets,
                                                departmentUserSettings:
                                                  _vm.notificationDepartmentUserSettings,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                dateTimeManager:
                                                  _vm.dateTimeManager,
                                              },
                                              on: {
                                                reloadSchedules:
                                                  _vm.reloadSchedules,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "title has-text-dark is-4 mb-0 pb-0",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "notifications.configuration.rules_title"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "is-size-7 is-italic has-text-grey pb-5",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "notifications.configuration.rules_subtitle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "pt-4 pb-5 subtitle has-text-weight-semibold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "notifications.configuration.general_or_preset"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "b-dropdown",
                                                  {
                                                    attrs: {
                                                      "aria-role": "list",
                                                    },
                                                    on: {
                                                      change: _vm.applyPreset,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "trigger",
                                                          fn: function (ref) {
                                                            var active =
                                                              ref.active
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "is-italic is-underlined clickable-text",
                                                                  attrs: {
                                                                    role: "button",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "notifications.configuration.general_or_preset_link"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("b-icon", {
                                                                    attrs: {
                                                                      icon: active
                                                                        ? "caret-up"
                                                                        : "caret-down",
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1251524264
                                                    ),
                                                    model: {
                                                      value: _vm.selectedPreset,
                                                      callback: function ($$v) {
                                                        _vm.selectedPreset = $$v
                                                      },
                                                      expression:
                                                        "selectedPreset",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.presets,
                                                    function (preset) {
                                                      return _c(
                                                        "b-dropdown-item",
                                                        {
                                                          key: preset.id,
                                                          attrs: {
                                                            "aria-role":
                                                              "listitem",
                                                            value: preset,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(preset.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.isChangingMode
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("b-skeleton", {
                                                      attrs: {
                                                        width: "20%",
                                                        animated: true,
                                                      },
                                                    }),
                                                    _c("b-skeleton", {
                                                      attrs: {
                                                        width: "40%",
                                                        animated: true,
                                                      },
                                                    }),
                                                    _c("b-skeleton", {
                                                      attrs: {
                                                        width: "80%",
                                                        animated: true,
                                                      },
                                                    }),
                                                    _c("b-skeleton", {
                                                      attrs: { animated: true },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : [
                                                  !_vm.hasRulesForEntities
                                                    ? _c(
                                                        "MeasuredDataNotification",
                                                        {
                                                          attrs: {
                                                            rule: _vm.singleMeasuredRule,
                                                            types:
                                                              _vm.measuredTypes,
                                                            currentUser:
                                                              _vm.user,
                                                            selectedMeasuredRulesMode:
                                                              _vm.selectedMeasuredRulesMode,
                                                            tier: _vm.tier,
                                                            scheduleId:
                                                              _vm.defaultScheduleId,
                                                            departmentId:
                                                              _vm.notificationSelectedDepartmentId,
                                                            autoSave: false,
                                                            measuredRuleEnabled:
                                                              _vm.measuredRuleEnabled,
                                                          },
                                                          on: {
                                                            changeMode:
                                                              _vm.changeMode,
                                                            switchedValue:
                                                              _vm.switchedValue,
                                                            ruleChanged:
                                                              _vm.ruleChanged,
                                                          },
                                                        }
                                                      )
                                                    : _c(
                                                        "EntityMeasuredDataNotification",
                                                        {
                                                          attrs: {
                                                            rules:
                                                              _vm.measuredRules,
                                                            types:
                                                              _vm.measuredTypes,
                                                            currentUser:
                                                              _vm.user,
                                                            selectedMeasuredRulesMode:
                                                              _vm.selectedMeasuredRulesMode,
                                                            departmentId:
                                                              _vm.notificationSelectedDepartmentId,
                                                            tier: _vm.tier,
                                                            autoSave: false,
                                                          },
                                                          on: {
                                                            changeMode:
                                                              _vm.changeMode,
                                                            ruleChanged:
                                                              _vm.ruleChanged,
                                                          },
                                                        }
                                                      ),
                                                ],
                                            _c("hr"),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .DEVICE_OFFLINE
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .DEVICE_OFFLINE
                                                ),
                                                currentUser: _vm.user,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("hr"),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .SOURCE_OFFLINE
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .SOURCE_OFFLINE
                                                ),
                                                currentUser: _vm.user,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("hr"),
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "subtitle has-text-dark",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.other_rules_title"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .LOW_BATTERY
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .LOW_BATTERY
                                                ),
                                                currentUser: _vm.user,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .CUSTOMER_ENDING_CALIBRATION
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .CUSTOMER_ENDING_CALIBRATION
                                                ),
                                                currentUser: _vm.user,
                                                advancedAllowed: false,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .PDF_RECORDS_REGENERATED
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .PDF_RECORDS_REGENERATED
                                                ),
                                                currentUser: _vm.user,
                                                advancedAllowed: false,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .AUTOMATIC_REPORTS_GENERATED
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .AUTOMATIC_REPORTS_GENERATED
                                                ),
                                                currentUser: _vm.user,
                                                advancedAllowed: false,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .RECORDS_UPDATED
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .RECORDS_UPDATED
                                                ),
                                                currentUser: _vm.user,
                                                advancedAllowed: false,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                            _c("GeneralNotificationComponent", {
                                              attrs: {
                                                rule: _vm.getRule(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .LOGGER_STATE
                                                ),
                                                type: _vm.getType(
                                                  _vm
                                                    .NotificationSubscriptionType
                                                    .LOGGER_STATE
                                                ),
                                                currentUser: _vm.user,
                                                advancedAllowed: false,
                                                departmentId:
                                                  _vm.notificationSelectedDepartmentId,
                                                autoSave: false,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                  ]
                                ),
                                _c(
                                  "b-tab-item",
                                  {
                                    attrs: {
                                      value:
                                        _vm.NotificationTabs.NON_WORKING_DAYS,
                                      label: _vm.$t(
                                        "notifications.configuration.tab_non_working"
                                      ),
                                    },
                                  },
                                  [
                                    _c("NonWorkingHours", {
                                      attrs: {
                                        availableTypes: _vm.availableTypes,
                                        notificationSubscriptions:
                                          _vm.notificationSubscriptions,
                                        presets: _vm.presets,
                                        schedules: _vm.nonWorkingSchedules,
                                        departmentUserSettings:
                                          _vm.notificationDepartmentUserSettings,
                                        departmentId:
                                          _vm.notificationSelectedDepartmentId,
                                        currentUser: _vm.user,
                                      },
                                      on: {
                                        reloadSchedules: _vm.reloadSchedules,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("hr"),
                            _vm.hasAdminRoleDepartment &&
                            _vm.hasAdminDepartmentSelectedInNotifications &&
                            _vm.$ability.can(
                              _vm.$permActions.READ,
                              _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                            ) &&
                            _vm.activeTabNotifications ===
                              _vm.NotificationTabs.GENERAL
                              ? _c(
                                  "div",
                                  { staticClass: "pt-3" },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "subtitle has-text-weight-bold pb-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.notification.rules_list.admin_rules_title"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("AdminNotificationComponent", {
                                      attrs: {
                                        rule: _vm.getAdminRule(
                                          _vm.NotificationSubscriptionType
                                            .ADMIN_LOGGER_CALIBRATION_ENDING
                                        ),
                                        type: _vm.getAdminType(
                                          _vm.NotificationSubscriptionType
                                            .ADMIN_LOGGER_CALIBRATION_ENDING
                                        ),
                                        currentUser: _vm.user,
                                        autoSave: false,
                                      },
                                      on: { ruleChanged: _vm.ruleChanged },
                                    }),
                                    _c("AdminNotificationComponent", {
                                      attrs: {
                                        rule: _vm.getAdminRule(
                                          _vm.NotificationSubscriptionType
                                            .FEEDBACK
                                        ),
                                        type: _vm.getAdminType(
                                          _vm.NotificationSubscriptionType
                                            .FEEDBACK
                                        ),
                                        currentUser: _vm.user,
                                        autoSave: false,
                                      },
                                      on: { ruleChanged: _vm.ruleChanged },
                                    }),
                                    _c("AdminNotificationComponent", {
                                      attrs: {
                                        rule: _vm.getAdminRule(
                                          _vm.NotificationSubscriptionType
                                            .ROLE_CHANGED
                                        ),
                                        type: _vm.getAdminType(
                                          _vm.NotificationSubscriptionType
                                            .ROLE_CHANGED
                                        ),
                                        currentUser: _vm.user,
                                        autoSave: false,
                                      },
                                      on: { ruleChanged: _vm.ruleChanged },
                                    }),
                                    _c("AdminNotificationComponent", {
                                      attrs: {
                                        rule: _vm.getAdminRule(
                                          _vm.NotificationSubscriptionType
                                            .CUSTOMER_REGISTERED
                                        ),
                                        type: _vm.getAdminType(
                                          _vm.NotificationSubscriptionType
                                            .CUSTOMER_REGISTERED
                                        ),
                                        currentUser: _vm.user,
                                        autoSave: false,
                                      },
                                      on: { ruleChanged: _vm.ruleChanged },
                                    }),
                                    _c("AdminNotificationComponent", {
                                      attrs: {
                                        rule: _vm.getAdminRule(
                                          _vm.NotificationSubscriptionType
                                            .ADMIN_LOGGER_STATE
                                        ),
                                        type: _vm.getAdminType(
                                          _vm.NotificationSubscriptionType
                                            .ADMIN_LOGGER_STATE
                                        ),
                                        currentUser: _vm.user,
                                        autoSave: false,
                                      },
                                      on: { ruleChanged: _vm.ruleChanged },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm.dateTimeManager &&
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_NOTIFICATIONS_USER
            )
              ? _c("div", { staticClass: "tile is-parent" }, [
                  _c(
                    "div",
                    { staticClass: "tile is-child box" },
                    [
                      _c("NotificationsTable", {
                        attrs: {
                          notifications: _vm.notificationsResult,
                          departmentFilter: null,
                          dateTimeManager: _vm.dateTimeManager,
                          canDelete: false,
                        },
                        on: {
                          onOptionsChange: _vm.onOptionsChange,
                          loadNotifications: _vm.loadNotifications,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isWarningModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isWarningModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("WarningModal", {
            attrs: {
              title: _vm.warningModalTitle,
              message: _vm.warningModalMessage,
              confirmBtn: _vm.warningModalConfirm,
              action: _vm.warningModalAction,
            },
            on: {
              modalClosed: function ($event) {
                return _vm.closeModal()
              },
              confirm: _vm.processUserAction,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.closeUnsavedChangesModal,
              save: _vm.saveChanges,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedNotifChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedNotifChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.closeUnsavedChangesModal,
              save: _vm.saveNotifChanges,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAddDepartmentModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAddDepartmentsModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAddDepartmentModalActive = $event
            },
            "close-modal": _vm.closeAddDepartmentsModal,
          },
        },
        [
          _c("AddDepartmentsModal", {
            attrs: {
              availableDepartments: _vm.allDepartmentsAdmin,
              departments: _vm.userDepartments,
              isAssignUserToDeps: true,
            },
            on: {
              loadDepartments: _vm.loadAllAdminDepartments,
              modalClosed: _vm.closeAddDepartmentsModal,
              addDepartments: _vm.addDepartmentsToUser,
            },
          }),
        ],
        1
      ),
      !_vm.timetableEnabled &&
      _vm.activeTabNotifications === _vm.NotificationTabs.GENERAL &&
      _vm.haveChangesToSave
        ? _c(
            "div",
            { staticClass: "fixed-form-footer" },
            [
              _c("span", { staticClass: "pb-2 has-text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "notifications.configuration.timetable.save_description"
                    )
                  )
                ),
              ]),
              _c(
                "b-button",
                {
                  attrs: { type: "is-success" },
                  on: {
                    click: function ($event) {
                      return _vm.saveDefault()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("notifications.configuration.timetable.save"))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import User from '../User';
export class PublicDashboardOwnersModel {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => PublicDashboardOwnersModel.define(et));
        }
        else if (data != null) {
            return PublicDashboardOwnersModel.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let publicDashboardOwnerModel = new PublicDashboardOwnersModel();
        publicDashboardOwnerModel.id = data.Id;
        publicDashboardOwnerModel.createdAt = data.CreatedAt;
        publicDashboardOwnerModel.dashboardKey = data.DashboardKey;
        publicDashboardOwnerModel.isPublic = data.IsPublic;
        publicDashboardOwnerModel.settings = data.Settings;
        publicDashboardOwnerModel.name = data.Name;
        publicDashboardOwnerModel.allowedComponents = data.AllowedComponents;
        publicDashboardOwnerModel.sourceList = data.SourceList;
        publicDashboardOwnerModel.sourceGroupList = data.SourceGroupList;
        publicDashboardOwnerModel.gatewayList = data.GatewayList;
        publicDashboardOwnerModel.userList = data.UserList;
        if (data.CreatedBy)
            publicDashboardOwnerModel.createdBy = User.fromApi(data.CreatedBy);
        return publicDashboardOwnerModel;
    }
    hasSourcesOrGroups() {
        return this.sourceList.length > 0 || this.sourceGroupList.length > 0;
    }
}

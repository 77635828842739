import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let RegistrationSuccessful = class RegistrationSuccessful extends Vue {
    async created() {
        this.$validator.localize(this.$route.params.lang);
    }
};
RegistrationSuccessful = __decorate([
    Component({})
], RegistrationSuccessful);
export default RegistrationSuccessful;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let UnsavedChangesDialog = class UnsavedChangesDialog extends Vue {
    exit() {
        if (!this.routeToNavigate) {
            this.$emit('discard', true);
        }
        else {
            this.$router.push({
                name: this.routeToNavigate.name,
                params: this.routeToNavigate.params,
                query: Object.assign(this.routeToNavigate.query, { confirmed: 'true' })
            });
        }
    }
    cancel() {
        this.$emit('cancel');
    }
    save() {
        this.$emit('save');
    }
};
__decorate([
    Prop({ type: Object })
], UnsavedChangesDialog.prototype, "routeToNavigate", void 0);
UnsavedChangesDialog = __decorate([
    Component({
        components: {}
    })
], UnsavedChangesDialog);
export default UnsavedChangesDialog;

import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
/**
 * Singleton repository
 */
export default class SystemRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getSystemSettings() {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_SYSTEM_SETTINGS)) {
            return [];
        }
        let response = await AxiosService.getDataFromServer(`${ApiLinks.AdminSystem.Settings}`, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return response.data.data;
    }
    async getBackgroundJobs() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_SYSTEM_TASKS)) {
            return [];
        }
        let response = await AxiosService.getDataFromServer(`${ApiLinks.AdminSystem.Tasks}`, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return response.data.data;
    }
    async createSystemSetting(key, value) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_SYSTEM_SETTINGS)) {
            throw 'Forbidden action createSystemSetting';
        }
        let systemSetting = {
            SettingKey: key,
            Value: value
        };
        let response = await AxiosService.postDataToServer(ApiLinks.AdminSystem.Settings, systemSetting, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS) {
            if (response.data.code) {
                return response.data.code;
            }
            else {
                return false;
            }
        }
        else
            return true;
    }
    async updateSystemSetting(key, newKey, value) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_SYSTEM_SETTINGS)) {
            throw 'Forbidden action updateSystemSetting';
        }
        let systemSetting = {
            SettingKey: newKey,
            Value: value
        };
        let url = ApiLinks.AdminSystem.Settings + '/' + key;
        let response = await AxiosService.putDataToServer(url, systemSetting, this.ctx);
        if (response.status != ApiStatus.SUCCESS) {
            if (response.data.code) {
                return response.data.code;
            }
            else {
                return false;
            }
        }
        else
            return true;
    }
}

export default class DecimalConversion {
    static toCommaString(value) {
        if (value === null || value === undefined || value === '')
            return null;
        if (typeof value == 'number')
            value = value.toFixed(2);
        return value.toString().replace('.', ',');
    }
    static toFloat(value) {
        if (value === null || value === undefined || value === '')
            return null;
        return parseFloat(value.toString().replace(',', '.'));
    }
}

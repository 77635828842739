import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DashboardsList from '@/views/components/publicDashboard/DashboardsList.vue';
import PublicDashboardOwnerRepository from '@/services/repository/PublicDashboardOwnerRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import VueUtilities from '@/services/VueUtilities';
import UserRepository from '@/services/repository/UserRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import CreateDashboardModal from '@/views/components/publicDashboard/CreateDashboardModal.vue';
import DateTimeManager from '@/services/DateTimeManager';
import { vxm } from '@/store/store.vuex';
import DeviceRepository from '@/services/repository/DeviceRepository';
var publicDashboardOwnerRepository;
var userRepository;
var sourceGroupRepository;
var sourceRepository;
var deviceRepository;
var vxDepartmentStore = vxm.departmentStore;
let PublicDashboardsManagement = class PublicDashboardsManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.dashboards = [];
        this.isAddDashboardModalActive = false;
        this.sourceGroups = [];
        this.sources = [];
        this.users = [];
        this.devices = [];
        this.dateTimeManager = null;
        this.SELECTED_DEPARTMENT_ID = null;
    }
    async created() {
        publicDashboardOwnerRepository = new PublicDashboardOwnerRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        userRepository = new UserRepository(this);
        deviceRepository = new DeviceRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        this.createApiCalls();
    }
    createApiCalls() {
        let allPromises = [];
        allPromises.push(publicDashboardOwnerRepository.getAllPublicDashboards());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0]);
        });
    }
    processLoadedDataForPage(dashboards) {
        this.dashboards = dashboards;
        this.isLoading = false;
    }
    async createPublicDashboard() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(userRepository.getUsers());
        allPromises.push(sourceGroupRepository.getSourceGroups());
        allPromises.push(sourceRepository.getVisibleSources());
        allPromises.push(deviceRepository.getDevices());
        await Promise.all(allPromises).then(async (response) => {
            this.users = response[0];
            this.sourceGroups = response[1].getData();
            this.sources = response[2].getData();
            this.devices = response[3].getData();
            this.isLoading = false;
            this.isAddDashboardModalActive = true;
        });
    }
    onCreateModalClose(result) {
        this.loadData();
        this.isAddDashboardModalActive = false;
    }
    async deleteDashboard(id) {
        let result = await publicDashboardOwnerRepository.deletePublicDashboard(id);
        if (result === true) {
            VueUtilities.openSuccessToast(this, this.$t('component.public_dashboard.delete.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.public_dashboard.delete.failure'));
        }
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
PublicDashboardsManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.MANAGE, Subjects.DASHBOARD)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (this.$ability.can(Actions.MANAGE, Subjects.DASHBOARD)) {
                if (this.SELECTED_DEPARTMENT_ID !=
                    this.getSelectedDepartmentId()) {
                    this.loadData();
                }
                next();
            }
            else {
                this.$route.params.departmentId = vxm.departmentStore.selectedDepartment.id;
                next({
                    name: 'settings',
                    params: this.$route.params
                });
                VueUtilities.openErrorToast(this, this.$t('missing_permissions.title'));
            }
        },
        components: { DashboardsList, CreateDashboardModal }
    })
], PublicDashboardsManagement);
export default PublicDashboardsManagement;

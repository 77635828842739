import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminDeviceList from '@/views/components/admin/devices/AdminDeviceList.vue';
import AdminDeviceFirmwareList from '@/views/components/admin/devices/AdminDeviceFirmwareList.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import CreateDeviceModal from '@/views/components/admin/devices/CreateDeviceModal.vue';
import AdminFirmwareModal from '@/views/components/admin/devices/AdminFirmwareModal.vue';
import ActivateDeviceModal from '@/views/components/admin/devices/ActivateDeviceModal.vue';
import ImportDevicesModal from '@/views/components/admin/devices/ImportDevicesModal.vue';
import DeviceMonitor from '@/views/components/admin/devices/DeviceMonitor.vue';
import ApiResponse from '@/entities/ApiResponse';
import FirmwareRepository from '@/services/repository/FirmwareRepository';
import DateTimeManager from '@/services/DateTimeManager';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var deviceRepository;
var firmwareRepository;
const PAGE_SIZE = 20;
let AdminDeviceManagement = class AdminDeviceManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.deviceList = new ApiResponse();
        this.deviceFirmwareList = new ApiResponse();
        this.isCreateDeviceModalActive = false;
        this.isAdminFirmwareModalActive = false;
        this.isActivateDeviceModalActive = false;
        this.isImportDeviceModalActive = false;
        this.isMonitorModalActive = false;
        this.dateTimeManager = null;
        this.objStringAdminDevice = null;
        this.objStringAdminFirmware = null;
        this.PAGE_SIZE = PAGE_SIZE;
        this.currentFilter = [];
        this.currentFilterFirmware = [];
        this.tableAdminDeviceQueries = 'adminDevice';
        this.tableAdminFirmwareQueries = 'adminFirmware';
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        localStorage.removeItem(LocalStorageKeys.ADMIN_PREV_ROUTE);
    }
    async loadFirmware(pagination, sort, currentFilter) {
        this.isLoading = true;
        firmwareRepository = new FirmwareRepository(this);
        this.deviceFirmwareList = await firmwareRepository.getFirmwaresList(pagination, sort, currentFilter);
        this.isLoading = false;
    }
    async loadDevices(pagination, sort, currentFilter) {
        this.isLoading = true;
        deviceRepository = new DeviceRepository(this);
        this.deviceList = await deviceRepository.getAdminDevices(null, pagination, sort, currentFilter);
        this.isLoading = false;
    }
    async onOptionsChange(options, filters = []) {
        this.callOptions = options;
        this.currentFilter = filters;
        this.loadDevices(options.pagination, options.sort, filters);
    }
    async onOptionsChangeFirmware(options, filters = []) {
        this.callOptionsFirmware = options;
        this.currentFilterFirmware = filters;
        this.loadFirmware(options.pagination, options.sort, filters);
    }
    changeRouterUrlFromDevice(objStringAdminDevice) {
        this.objStringAdminDevice = objStringAdminDevice;
        this.changeRouterUrl(this.objStringAdminDevice, this.objStringAdminFirmware);
    }
    changeRouterUrlFromFirmware(objStringAdminFirmware) {
        this.objStringAdminFirmware = objStringAdminFirmware;
        this.changeRouterUrl(this.objStringAdminDevice, this.objStringAdminFirmware);
    }
    changeRouterUrl(objStringAdminDevice, objStringAdminFirmware) {
        this.$router
            .push({
            query: {
                [this.tableAdminDeviceQueries]: objStringAdminDevice,
                [this.tableAdminFirmwareQueries]: objStringAdminFirmware
            }
        })
            .catch((err) => err);
    }
    createNewDevice() {
        this.isCreateDeviceModalActive = true;
    }
    activateDevice() {
        this.isActivateDeviceModalActive = true;
    }
    importDevices() {
        this.isImportDeviceModalActive = true;
    }
    openAdminFirmwareModal() {
        this.isAdminFirmwareModalActive = true;
    }
    closeCreateDeviceModal(result) {
        this.isCreateDeviceModalActive = false;
        if (result == true) {
            this.loadDevices();
            this.loadFirmware();
        }
    }
    closeAdminFirmwareModal(result) {
        this.isAdminFirmwareModalActive = false;
        if (result == true) {
            this.loadDevices();
            this.loadFirmware();
        }
    }
    closeActivateDeviceModal() {
        this.isActivateDeviceModalActive = false;
    }
    closeImportDeviceModal(result) {
        this.isImportDeviceModalActive = false;
        if (result == true) {
            this.loadDevices();
            this.loadFirmware();
        }
    }
    openDeviceMonitor() {
        this.isMonitorModalActive = true;
    }
    closeMonitorModal() {
        this.isMonitorModalActive = false;
    }
};
AdminDeviceManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            AdminDeviceList,
            AdminDeviceFirmwareList,
            CreateDeviceModal,
            AdminFirmwareModal,
            ActivateDeviceModal,
            ImportDevicesModal,
            DeviceMonitor
        }
    })
], AdminDeviceManagement);
export default AdminDeviceManagement;

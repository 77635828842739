var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "is-narrow-mobile is-fullheight is-left-mobile side-menu",
      class: [
        _vm.isExpanded ? "column expanded-side-menu" : "bar-side-menu",
        _vm.isSideMenuVisible ? "checked" : null,
      ],
      attrs: { id: "side-menu" },
    },
    [
      _c("ul", { staticClass: "menu-list" }, [
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_DEPARTMENTS
        )
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminCompanies",
                        "adminCompanyDetail",
                        "issueDetailAdmin",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminCompanies",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "warehouse" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.companies"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(_vm.$permActions.LIST, _vm.$permSubjects.ADMIN_USERS)
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminUsers",
                        "adminUserDetail",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminUsers",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "users" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.users"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_GATEWAYS
        )
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminDevices",
                        "adminDeviceDetail",
                        "adminFirmwareDetail",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminDevices",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "hdd" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.devices"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_COMPANYLOGGERS
        ) &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_LOGGERS)
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminLoggers",
                        "adminLoggerDetail",
                        "adminRegisterNewLoggers",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminLoggers",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "microchip" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.loggers"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_RESELLER
        )
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminResellers",
                        "adminResellerDetail",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminResellers",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "box-open" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.resellers"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["adminNotifications"]),
                },
                attrs: {
                  to: {
                    name: "adminNotifications",
                    params: {
                      lang: _vm.$route.params.lang,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "envelope" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("admin.sidebar.notifications"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.currentConfig.features.tiers === true &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_TIERS)
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminTiers",
                        "adminCreateTier",
                        "adminTierDetail",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminTiers",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "layer-group" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.tiers"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentConfig.features.feedback === true &&
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_FEEDBACK
        )
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive([
                        "adminFeedback",
                        "adminFeedbackDetail",
                      ]),
                    },
                    attrs: {
                      to: {
                        name: "adminFeedback",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "comment-dots" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("admin.sidebar.feedback"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_SYSTEM_SETTINGS
        )
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(["systemSettings"]),
                    },
                    attrs: {
                      to: {
                        name: "systemSettings",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "cogs" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(
                            _vm._s(_vm.$t("admin.sidebar.system_settings"))
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("li", { staticClass: "is-hidden-desktop" }, [
          _c(
            "a",
            { attrs: { href: "#" }, on: { click: _vm.logoutAsync } },
            [
              _c("b-icon", { attrs: { icon: "sign-out-alt" } }),
              _vm.isExpanded || _vm.isSideMenuVisible
                ? _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.$t("navbar.logout"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c("b-icon", {
        staticClass: "width-button is-hidden-mobile",
        attrs: {
          icon: _vm.isExpanded ? "angle-double-left" : "angle-double-right",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.expandButtonClicked(!_vm.isExpanded)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
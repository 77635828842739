import DateTimeManager from '@/services/DateTimeManager';
const MIN_IN_DAY = 1440;
var ReportingInterval;
(function (ReportingInterval) {
    ReportingInterval[ReportingInterval["INTERVAL_00_15"] = 15] = "INTERVAL_00_15";
    ReportingInterval[ReportingInterval["INTERVAL_00_30"] = 30] = "INTERVAL_00_30";
    ReportingInterval[ReportingInterval["INTERVAL_01_00"] = 60] = "INTERVAL_01_00";
    ReportingInterval[ReportingInterval["INTERVAL_02_00"] = 120] = "INTERVAL_02_00";
    ReportingInterval[ReportingInterval["INTERVAL_04_00"] = 240] = "INTERVAL_04_00";
    ReportingInterval[ReportingInterval["INTERVAL_06_00"] = 360] = "INTERVAL_06_00";
    ReportingInterval[ReportingInterval["INTERVAL_12_00"] = 720] = "INTERVAL_12_00";
    ReportingInterval[ReportingInterval["INTERVAL_24_00"] = 1440] = "INTERVAL_24_00";
})(ReportingInterval || (ReportingInterval = {}));
export default ReportingInterval;
export let defaultReportingIntervals = [
    [ReportingInterval.INTERVAL_00_15, '15min'],
    [ReportingInterval.INTERVAL_00_30, '30min'],
    [ReportingInterval.INTERVAL_01_00, '1hour'],
    [ReportingInterval.INTERVAL_02_00, '2hours'],
    [ReportingInterval.INTERVAL_04_00, '4hours'],
    [ReportingInterval.INTERVAL_06_00, '6hours'],
    [ReportingInterval.INTERVAL_12_00, '12hour'],
    [ReportingInterval.INTERVAL_24_00, '1day']
];
export function getReportingIntervals() {
    let arr = [];
    for (let i in defaultReportingIntervals)
        arr.push(parseInt(defaultReportingIntervals[i][0].toString()));
    return arr;
}
export async function getAllAvailableMeasureTimes(ctx, interval, useDefaultSettings = false) {
    let dateTimeManager = await DateTimeManager.CreateManager(ctx, useDefaultSettings);
    let allOptions = [];
    let intervalsCount = interval / 60;
    for (let offset = 0; offset < intervalsCount; offset++) {
        let allTimesForOffset = getMeasureTimesForInterval(dateTimeManager, interval, offset * 60);
        if (allTimesForOffset.length > 3)
            allTimesForOffset.splice(2, 0, ' ... ');
        allOptions.push(allTimesForOffset.toString());
    }
    return allOptions;
}
export function getMeasureTimesForInterval(dateTimeManager, interval, offset) {
    let allTimesForOffset = [];
    let currOffset = offset;
    while (currOffset > 0)
        currOffset -= interval;
    while (currOffset < 0)
        currOffset += interval;
    while (currOffset < MIN_IN_DAY) {
        if (allTimesForOffset.length < 2 || currOffset + 2 * interval >= MIN_IN_DAY)
            allTimesForOffset.push(dateTimeManager.minutesToUTCFormattedHours(currOffset));
        currOffset += interval;
    }
    return allTimesForOffset;
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "multi-header has-bottom-margin" }, [
      _c("h2", { staticClass: "subtitle is-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("tiers.tier_detail")) + " "),
      ]),
    ]),
    _c("div", { staticClass: "columns columns-info-form" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("tiers.name") } },
            [_c("span", [_vm._v(_vm._s(_vm.tier.name))])]
          ),
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("tiers.activated_at") } },
            [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.tier.activatedAt * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "column" }, [
        _c("p", { staticClass: "has-text-weight-bold is-size-5" }, [
          _vm._v(" " + _vm._s(_vm.$t("tiers.features_list")) + " "),
        ]),
        _c("div", [
          _c(
            "ul",
            {
              staticClass: "has-text-dark",
              staticStyle: { "list-style-type": "disc" },
            },
            _vm._l(_vm.tier.enabledFeatures, function (feature, index) {
              return _c("li", { key: index }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("tiers.features." + feature.Name)) + " "
                  ),
                ]),
                feature.Value.Quota
                  ? _c("span", [
                      _vm._v(
                        "(" +
                          _vm._s(
                            feature.Value.Quota == -1
                              ? _vm.$t("tiers.features.unlimited")
                              : feature.Value.Quota
                          )
                      ),
                      feature.Value.AllowOverflow
                        ? _c("span", [_vm._v("+")])
                        : _vm._e(),
                      _vm._v(")"),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Boundary, BoundaryDTO } from '@/entities/models/Boundary';
import EventType from '@/entities/EventType';
import VueUtilities from '@/services/VueUtilities';
import DecimalConversion from '@/services/DecimalConversion';
import { SourceGroup } from '@/entities/models/SourceGroup';
import { Source } from '@/entities/models/Source';
let BoundaryManagementComponent = class BoundaryManagementComponent extends Vue {
    constructor() {
        super(...arguments);
        this.newBoundaries = [];
        this.originalBoundariesCount = 0;
    }
    /** For now, filter out uptime service. There is no reason to have boundaries for this service */
    get eventTypesWithoutUptime() {
        return this.eventTypes.filter((x) => x.id != EventType.UPTIME_ID);
    }
    get notBoundedEventTypes() {
        let usedTypes = this.newBoundaries.map((x) => x.measuredDataTypeSlug);
        let availableTypes = this.eventTypesWithoutUptime.filter((x) => !usedTypes.includes(x.slug));
        return availableTypes;
    }
    get addNewAllowed() {
        if (!this.newBoundaries.find((x) => !x.measuredDataTypeSlug))
            return true;
        else
            return false;
    }
    editChanged() {
        let boundaries = [];
        if (this.entityToEdit instanceof Source) {
            this.newBoundaries = Boundary.convertBoundariesToDTO(this.entityToEdit.boundaries);
            this.convertBoundaries(this.newBoundaries);
        }
        else if (this.entityToEdit instanceof SourceGroup) {
            this.newBoundaries = Boundary.convertBoundariesToDTO(this.entityToEdit.sources[0].boundaries);
            this.convertBoundaries(this.newBoundaries);
        }
        else {
            this.newBoundaries = [];
            this.addNewBoundary();
        }
    }
    convertBoundaries(boundaries) {
        return boundaries.forEach((x) => {
            x.lowerAlarmBoundary = DecimalConversion.toCommaString(x.lowerAlarmBoundary);
            x.upperAlarmBoundary = DecimalConversion.toCommaString(x.upperAlarmBoundary);
            x.lowerWarningBoundary = DecimalConversion.toCommaString(x.lowerWarningBoundary);
            x.upperWarningBoundary = DecimalConversion.toCommaString(x.upperWarningBoundary);
        });
    }
    created() {
        this.newBoundaries = this.boundaries;
        this.convertBoundaries(this.boundaries);
        this.originalBoundariesCount = this.boundaries.length;
        if (this.newBoundaries.length <= 0)
            this.addNewBoundary();
    }
    addNewBoundary() {
        let newBound = new BoundaryDTO();
        newBound.isNew = true;
        if (this.notBoundedEventTypes.length == 1)
            newBound.measuredDataTypeSlug = this.notBoundedEventTypes[0].slug;
        this.newBoundaries.push(newBound);
    }
    remove(boundary) {
        this.newBoundaries = this.newBoundaries.filter((x) => x.measuredDataTypeSlug != boundary.measuredDataTypeSlug);
    }
    typeSelected(boundary, typeSlug) {
        boundary.measuredDataTypeSlug = typeSlug;
        boundary.unit = this.eventTypes.find((x) => x.slug == typeSlug).unit.unit;
    }
    checkAndConvertBoundaryValues() {
        let comparisonResult = true;
        this.newBoundaries.forEach((boundary) => {
            boundary.lowerAlarmBoundary = DecimalConversion.toFloat(boundary.lowerAlarmBoundary);
            boundary.lowerWarningBoundary = DecimalConversion.toFloat(boundary.lowerWarningBoundary);
            boundary.upperWarningBoundary = DecimalConversion.toFloat(boundary.upperWarningBoundary);
            boundary.upperAlarmBoundary = DecimalConversion.toFloat(boundary.upperAlarmBoundary);
            if (boundary.lowerAlarmBoundary >= boundary.upperAlarmBoundary) {
                comparisonResult = false;
            }
            if (boundary.lowerWarningBoundary != null && boundary.lowerWarningBoundary <= boundary.lowerAlarmBoundary) {
                comparisonResult = false;
            }
            if (boundary.upperWarningBoundary != null && boundary.upperWarningBoundary >= boundary.upperAlarmBoundary) {
                comparisonResult = false;
            }
            if (boundary.lowerWarningBoundary != null &&
                boundary.upperWarningBoundary != null &&
                boundary.lowerWarningBoundary >= boundary.upperWarningBoundary) {
                comparisonResult = false;
            }
        });
        return comparisonResult;
    }
    validateBeforeSubmit() {
        if (this.newBoundaries.length == 0) {
            VueUtilities.openErrorToast(this, this.$t('component.wizard.wizardBoundaries.setBoundaries.addBoundaries'));
        }
        else {
            this.$validator.validateAll().then((result) => {
                if (!this.checkAndConvertBoundaryValues()) {
                    VueUtilities.openErrorToast(this, this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid'));
                }
                else if (result) {
                    this.$emit('saveBoundaries', this.newBoundaries);
                }
            });
        }
    }
};
__decorate([
    Prop({ type: Array })
], BoundaryManagementComponent.prototype, "boundaries", void 0);
__decorate([
    Prop({ type: Array })
], BoundaryManagementComponent.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], BoundaryManagementComponent.prototype, "editing", void 0);
__decorate([
    Prop({ type: Object })
], BoundaryManagementComponent.prototype, "entityToEdit", void 0);
__decorate([
    Watch('entityToEdit')
], BoundaryManagementComponent.prototype, "editChanged", null);
BoundaryManagementComponent = __decorate([
    Component({})
], BoundaryManagementComponent);
export default BoundaryManagementComponent;

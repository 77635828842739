import { __decorate } from "tslib";
import VueUtilities from '@/services/VueUtilities';
import { Component, Vue, Prop } from 'vue-property-decorator';
let SystemSettingsComponent = class SystemSettingsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.isAdding = false;
        this.fieldEditing = null;
        this.selectedFieldValue = null;
        this.selectedFieldLabel = null;
    }
    editSettings(key) {
        this.fieldEditing = key;
        this.selectedFieldLabel = key;
        this.selectedFieldValue = this.systemSettings.find((x) => x.SettingKey == key).Value;
    }
    cancelEdit() {
        this.fieldEditing = null;
        this.selectedFieldLabel = null;
        this.selectedFieldValue = null;
    }
    cancelAdding() {
        this.isAdding = false;
        this.fieldEditing = null;
        this.selectedFieldLabel = null;
        this.selectedFieldValue = null;
    }
    saveEdit(key) {
        this.$emit('updateSetting', key, this.selectedFieldLabel, this.selectedFieldValue);
    }
    addNew() {
        this.isAdding = true;
        this.cancelEdit();
    }
    saveNewSetting() {
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.checkIfKeyExists(this.selectedFieldLabel)) {
                this.$emit('saveNewSetting', this.selectedFieldLabel, this.selectedFieldValue);
            }
            else if (this.checkIfKeyExists(this.selectedFieldLabel)) {
                VueUtilities.openErrorToast(this, this.$t('system_settings.already_exists'));
            }
        });
    }
    checkIfKeyExists(key) {
        if (this.systemSettings.find((x) => x.SettingKey === key) != null)
            return true;
        else
            return false;
    }
};
__decorate([
    Prop({ type: Array })
], SystemSettingsComponent.prototype, "systemSettings", void 0);
SystemSettingsComponent = __decorate([
    Component({
        components: {}
    })
], SystemSettingsComponent);
export default SystemSettingsComponent;

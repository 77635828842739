import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CompanyTierDetail = class CompanyTierDetail extends Vue {
};
__decorate([
    Prop({ type: Object })
], CompanyTierDetail.prototype, "activeTier", void 0);
__decorate([
    Prop({ type: Object })
], CompanyTierDetail.prototype, "dateTimeManager", void 0);
CompanyTierDetail = __decorate([
    Component({ components: {} })
], CompanyTierDetail);
export default CompanyTierDetail;

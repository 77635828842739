import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import UserRepository from '@/services/repository/UserRepository';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import { UserSettings } from '@/entities/UserSettings';
import VueUtilities from '@/services/VueUtilities';
import LoggedUserManager from '@/services/LoggedUserManager';
import UserInfoComponent from '../components/profile/UserInfoComponent.vue';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import UserSettingsComponent from '../components/profile/UserSettingsComponent.vue';
var userSettingsRepository;
var userRepository;
var authenticationCalls;
let ProfileOrganization = class ProfileOrganization extends Vue {
    constructor() {
        super(...arguments);
        this.currentUser = null;
        this.settings = new UserSettings();
        this.isLoading = true;
        this.userRequestPending = false;
    }
    async created() {
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        userRepository = new UserRepository(this);
        authenticationCalls = new AuthenticationCalls(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.currentUser = await userRepository.getCurrentUser();
        let allPromises = [];
        allPromises.push(userSettingsRepository.loadUserSettings());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0]);
        });
    }
    processLoadedDataForPage(settings) {
        this.settings = settings;
        this.isLoading = false;
    }
    async updateUser(fullName) {
        let data = {
            FullName: fullName
        };
        let updatedUser = await userRepository.updateCurrentUser(data);
        if (updatedUser) {
            this.currentUser = updatedUser;
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_info.updateUser.success').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_info.updateUser.failure').toString());
        }
    }
    async reloadUser() {
        this.isLoading = true;
        let user = await userRepository.getCurrentUser();
        this.currentUser = user;
        this.isLoading = false;
    }
    async signOut() {
        let result = await authenticationCalls.logout();
        this.handleSignoutResult(result);
    }
    async signOutAllDevices() {
        let result = await authenticationCalls.deleteUserSessions();
        this.handleSignoutResult(result);
    }
    async changePassword(oldPwd, newPwd) {
        let success = await authenticationCalls.changeCurrentUserPassword(oldPwd, newPwd);
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_settings.change_password.success').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_settings.change_password.failure').toString());
        }
    }
    handleSignoutResult(result) {
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            this.$router
                .push({
                name: 'home',
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.$route.params.departmentId
                }
            })
                .catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
    async languageChanged(language) {
        this.userRequestPending = true;
        this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.LANGUAGE], language);
        this.userRequestPending = false;
        this.$router
            .push({
            name: this.$route.name,
            params: {
                lang: this.settings.language,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    async measurementSortChanged(sort) {
        this.userRequestPending = true;
        this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.PREFERENCES, UserSettingsKeys.MEASUREMENT_SORT], sort);
        this.userRequestPending = false;
    }
    async dateFormatSelected(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setSelectedDateFormat(val);
        this.userRequestPending = false;
    }
    async clockFormatSelected(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setSelectedClockFormat(val);
        this.userRequestPending = false;
    }
    async timezoneSelected(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setSelectedTimezone(val);
        this.userRequestPending = false;
    }
    async showTzChanged(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setTzInClockFormatPreference(val);
        this.userRequestPending = false;
    }
};
ProfileOrganization = __decorate([
    Component({ components: { UserInfoComponent, UserSettingsComponent } })
], ProfileOrganization);
export default ProfileOrganization;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
var deviceRepository;
let KeyUpdateModal = class KeyUpdateModal extends Vue {
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    updateKey() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let result = await deviceRepository.adminUpdatePublicKey(this.device.deviceId);
                if (result === true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.publickey_update.success'));
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.publickey_update.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], KeyUpdateModal.prototype, "device", void 0);
KeyUpdateModal = __decorate([
    Component({})
], KeyUpdateModal);
export default KeyUpdateModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CreateSourcesModal from '@/views/components/logger/CreateSourcesModal.vue';
let IdleLoggersList = class IdleLoggersList extends Vue {
    constructor() {
        super(...arguments);
        this.loggersWithoutSource = [];
        this.isAddLoggersModalActive = false;
    }
    onListChange() {
        this.filterIdleLoggers();
    }
    get activeLoggers() {
        return this.companyLoggerList.filter((x) => x.active == true);
    }
    get sortedLoggers() {
        return this.loggersWithoutSource.sort((a, b) => (a.active > b.active ? -1 : 1));
    }
    created() {
        this.filterIdleLoggers();
    }
    filterIdleLoggers() {
        this.loggersWithoutSource = this.companyLoggerList.filter((x) => !x.source.exists());
    }
    onModalClose(data) {
        this.isAddLoggersModalActive = false;
        if (data != false && data != 'outside')
            this.reloadLoggers();
    }
    reloadLoggers() {
        this.$emit('reload-loggers');
    }
};
__decorate([
    Prop({ type: Array })
], IdleLoggersList.prototype, "companyLoggerList", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IdleLoggersList.prototype, "inWizard", void 0);
__decorate([
    Watch('companyLoggerList')
], IdleLoggersList.prototype, "onListChange", null);
IdleLoggersList = __decorate([
    Component({ components: { CreateSourcesModal } })
], IdleLoggersList);
export default IdleLoggersList;

export default class DeviceCredential {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DeviceCredential.define(et));
        }
        else if (data != null) {
            return DeviceCredential.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let credential = new DeviceCredential();
        credential.keyId = data.KeyId;
        credential.publicKey = data.PublicKey;
        credential.keyType = data.KeyType;
        credential.createdAt = data.CreatedAt;
        credential.revokedAt = data.RevokedAt;
        credential.lastUsedAt = data.LastUsedAt;
        credential.credentialsId = data.CredentialsId;
        return credential;
    }
}

import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { Tier } from '@/entities/tiers/Tier';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import { TierFeature } from '@/entities/tiers/TierFeature';
import { ChangeRequest } from '@/entities/tiers/ChangeRequest';
import { CompanyTier } from '@/entities/tiers/CompanyTier';
import { UsersTier } from '@/entities/tiers/UsersTier';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class TiersRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getActiveTier(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.FEATURES)) {
            throw 'Forbidden action getActiveTier';
        }
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Tiers.Keys.Active}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new UsersTier();
        else {
            return UsersTier.fromApi(response.data.data);
        }
    }
    async getActiveCompanyTier(departmentId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            return null;
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Active}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new CompanyTier();
        else {
            return CompanyTier.fromApi(response.data.data);
        }
    }
    async getCompanyTiersHistory(departmentId) {
        if (!this.ctx.$ability.can(Actions.HISTORY, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            return null;
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Tiers}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return CompanyTier.fromApi(response.data.data);
        }
    }
    async deleteCompanyTier(departmentId, companyTierId) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            return null;
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Tiers}/${companyTierId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else {
            return true;
        }
    }
    async updateCompanyTier(departmentId, companyTierId, dto) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            throw 'Forbidden action updateCompanyTier';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Tiers}/${companyTierId}`;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new CompanyTier();
        else {
            return CompanyTier.fromApi(response.data.data);
        }
    }
    async getTiers() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_TIERS)) {
            return [];
        }
        let url = `${ApiLinks.Tiers.AdminHome}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return Tier.fromApi(response.data.data);
        }
    }
    async assignCompanyTier(departmentId, dto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            throw 'Forbidden action assignCompanyTier';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Tiers}`;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new CompanyTier();
        else {
            return CompanyTier.fromApi(response.data.data);
        }
    }
    async activateTierForCompany(departmentId, companyTierId, dto) {
        if (!this.ctx.$ability.can(Actions.ACTIVATE, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            throw 'Forbidden action activateTierForCompany';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Tiers}/${companyTierId}${ApiLinks.Tiers.Keys.Activate}`;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else {
            return true;
        }
    }
    async deactivateTierForCompany(departmentId, companyTierId, dto) {
        if (!this.ctx.$ability.can(Actions.DEACTIVATE, Subjects.ADMIN_DEPARTMENTS_TIERS)) {
            throw 'Forbidden action deactivateTierForCompany';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Tiers.Keys.Tiers}/${companyTierId}${ApiLinks.Tiers.Keys.Deactivate}`;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else {
            return true;
        }
    }
    async getTier(id) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_TIERS)) {
            throw 'Forbidden action getTier';
        }
        let url = ApiLinks.AdminTiers.Home + '/' + id;
        let response = await AxiosService.getDataFromServer(url, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return response;
        else {
            return Tier.fromApi(response.data.data);
        }
    }
    async getAllFeatures() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_TIERS)) {
            return [];
        }
        let url = ApiLinks.AdminTiers.AllFeatures;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return TierFeature.fromApi(response.data.data);
        }
    }
    async getChangeRequests() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_TIERS_REQUESTS)) {
            return [];
        }
        let url = ApiLinks.AdminTiers.Requests;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return ChangeRequest.fromApi(response.data.data);
        }
    }
    async createTier(dto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_TIERS)) {
            throw 'Forbidden action createTier';
        }
        let url = ApiLinks.AdminTiers.Home;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Tier();
        else {
            return Tier.fromApi(response.data.data);
        }
    }
    async updateTier(dto, id) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_TIERS)) {
            throw 'Forbidden action updateTier';
        }
        let url = ApiLinks.AdminTiers.Home + '/' + id;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Tier();
        else {
            return Tier.fromApi(response.data.data);
        }
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

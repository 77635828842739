var ApiStatus;
(function (ApiStatus) {
    ApiStatus[ApiStatus["SUCCESS"] = 200] = "SUCCESS";
    ApiStatus[ApiStatus["CREATED"] = 201] = "CREATED";
    ApiStatus[ApiStatus["NO_CONTENT"] = 204] = "NO_CONTENT";
    ApiStatus[ApiStatus["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    ApiStatus[ApiStatus["FORBIDDEN"] = 403] = "FORBIDDEN";
    ApiStatus[ApiStatus["NOT_FOUND"] = 404] = "NOT_FOUND";
    ApiStatus[ApiStatus["TIMEOUT"] = 408] = "TIMEOUT";
    ApiStatus[ApiStatus["NOT_ALLOWED"] = 405] = "NOT_ALLOWED";
    ApiStatus[ApiStatus["BAD_REQUEST"] = 400] = "BAD_REQUEST";
})(ApiStatus || (ApiStatus = {}));
export default ApiStatus;

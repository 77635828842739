import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import AdminRegisterLogger from '@/views/components/admin/loggers/AdminRegisterLogger.vue';
import { RegisteredLogger } from '@/entities/models/RegisteredLogger';
import DateTimeManager from '@/services/DateTimeManager';
import DeviceRepository from '@/services/repository/DeviceRepository';
import LoggerRepository from '@/services/repository/LoggerRepository';
import ApiLinks from '@/entities/ApiLinks';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import AxiosService from '@/services/api/AxiosService';
var deviceRepository;
var loggerRepository;
let AdminRegisterLoggersManagement = class AdminRegisterLoggersManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.dateTimeManager = null;
        this.deviceList = [];
        this.loggerList = [];
        this.connectedDevice = null;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        deviceRepository = new DeviceRepository(this);
        loggerRepository = new LoggerRepository(this);
        this.loadData();
    }
    beforeDestroy() {
        this.stopConnection();
    }
    async loadData() {
        this.isLoading = true;
        let deviceResult = await deviceRepository.getAdminDevices();
        this.deviceList = deviceResult.getData();
        this.isLoading = false;
    }
    connectToDevice(device) {
        this.connectedDevice = device;
        this.listenForLoggers();
    }
    async deleteLogger(loggerId) {
        let success = await loggerRepository.adminDeleteLogger(loggerId);
        if (success) {
            this.loggerList = this.loggerList.filter((x) => x.loggerId != loggerId);
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.registerLoggers.deleteSuccess').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.registerLoggers.deleteFailure').toString());
        }
    }
    stopConnection() {
        this.connectedDevice = null;
        if (this.connection)
            this.connection.stop();
    }
    listenForLoggers() {
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.LoggerRegistration.Home}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.connectedDevice.deviceId);
        })
            .catch((error) => console.log(error));
        this.connection.on('LoggerRegistered', (data) => {
            let newLogger = RegisteredLogger.fromApi(data);
            if (newLogger) {
                this.loggerList.push(newLogger);
            }
        });
    }
};
AdminRegisterLoggersManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { AdminRegisterLogger }
    })
], AdminRegisterLoggersManagement);
export default AdminRegisterLoggersManagement;

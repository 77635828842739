import ApiStatuses from '@/entities/enums/apiStatuses';
import LoggedUserManager from '@/services/LoggedUserManager';
import i18n from '@/lang/lang';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
import { FeaturesManager } from '@/entities/tiers/FeaturesManager';
export function processError(error, self) {
    let response = error.response;
    // showFatalErrorMsg(self, response);
    let status = response.status;
    /* if departmentId NOT_FOUND or NOT_VALID, reload to
     * dashboard with favorite department / first from list of departments
     */
    switch (status) {
        case ApiStatuses.NOT_FOUND: {
            let responseData = response?.data;
            if (responseData instanceof ArrayBuffer)
                responseData = arrayBufferToString(response.data);
            if (response?.data?.code === ApiResponseCodes.DEPARTMENT_NOT_FOUND) {
                let id = getSelectedDepartmentId();
                self.$router.replace({
                    name: 'dashboard',
                    params: { departmentId: id, lang: self.$route.params.lang }
                });
                showDepartmentErrorMsg(self, response);
            }
            else if (response?.data?.code === ApiResponseCodes.FLOOR_PLAN_NOT_FOUND ||
                responseData.code === ApiResponseCodes.FLOOR_PLAN_NOT_FOUND) {
                break;
            }
            else {
                showFatalErrorMsg(self, response);
            }
            break;
        }
        case ApiStatuses.BAD_REQUEST: {
            if (response?.data?.code === ApiResponseCodes.VALIDATION_ERROR && 'departmentId' in response?.data?.errors) {
                let id = getSelectedDepartmentId();
                self.$router.replace({
                    name: 'dashboard',
                    params: { departmentId: id, lang: self.$route.params.lang }
                });
                showDepartmentErrorMsg(self, response);
            }
            else {
                showFatalErrorMsg(self, response);
            }
            break;
        }
        case ApiStatuses.UNAUTHORIZED: {
            manageCredentialExpiration(self);
            break;
        }
        case ApiStatuses.NOT_ALLOWED: {
            let responseData = response?.data;
            if (responseData instanceof ArrayBuffer)
                responseData = arrayBufferToString(response.data);
            if (responseData.code === ApiResponseCodes.QUOTA_REACHED)
                FeaturesManager.showQuotaReachedMsg(self);
            else if (responseData.code === ApiResponseCodes.FEATURE_DISABLED)
                FeaturesManager.showFeatureDisabledMsg(self);
            else
                showFatalErrorMsg(self, response);
            break;
        }
        default:
            showFatalErrorMsg(self, response);
            throw response;
            break;
    }
    return response;
}
function arrayBufferToString(buffer) {
    let result = new TextDecoder('utf-8').decode(buffer);
    return JSON.parse(result);
}
function showDepartmentErrorMsg(self, text) {
    self.$buefy.notification.open({
        message: `<strong>${i18n.t('error_messages.department_not_found')}</strong>`,
        duration: 4000,
        position: 'is-top-right',
        type: 'is-warning',
        queue: false
    });
}
function showFatalErrorMsg(self, response) {
    console.log(response);
    let text = '';
    if (response.data)
        text = JSON.stringify(response.data);
    else if (response.statusText)
        text = JSON.stringify(response.statusText);
    self.$buefy.notification.open({
        message: `<strong>${i18n.t('error_messages.fatal_error')}</strong> </br> ${text}`,
        duration: 4000,
        position: 'is-top-right',
        type: 'is-warning',
        queue: false
    });
}
function manageCredentialExpiration(ctx) {
    try {
        ctx.$router instanceof TypeError;
    }
    catch (err) {
        LoggedUserManager.deleteLoginData(ctx);
        LoggedUserManager.deleteAdminLoginData(ctx);
        location.reload();
        return;
    }
    if (ctx.$router == null)
        return;
    LoggedUserManager.deleteLoginData(ctx);
    LoggedUserManager.deleteAdminLoginData(ctx);
    ctx.$router.push({ name: 'login', params: { lang: ctx.$route.params.lang } }).catch((err) => err);
    ctx.$buefy.notification.open({
        message: ctx.$t('error_messages.unauthorized_msg'),
        duration: 4000,
        position: 'is-top-right',
        type: 'is-warning',
        queue: false
    });
}
function getSelectedDepartmentId() {
    return vxDepartmentStore.selectedDepartment.id;
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import NotificationCategory from '@/entities/enums/NotificationCategory';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import UserListComponent from '@/views/components/admin/notifications/UserListComponent.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import NotificationChannelType from '@/entities/notifications/NotificationChannelType';
import UserRepository from '@/services/repository/UserRepository';
var UserScope;
(function (UserScope) {
    UserScope["ALL"] = "allUsers";
    UserScope["COMPANY_OWNERS"] = "companyOwners";
    UserScope["COMPANY_OWNERS_AND_ADMINS"] = "companyOwnersAndAdmins";
    UserScope["SELECTED"] = "selected";
})(UserScope || (UserScope = {}));
var userRepository;
let SendNotification = class SendNotification extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationChannelType = NotificationChannelType;
        this.users = [];
        this.selectedUserScope = UserScope.ALL;
        this.selectedUsers = [];
        this.selectedPriority = null;
        this.selectedCategory = NotificationCategory.ADMIN_MESSAGE;
        this.selectedChannels = [];
        this.notificationTitle = null;
        this.notificationText = null;
        this.totalSize = 0;
        this.userScopes = [
            UserScope.ALL,
            UserScope.COMPANY_OWNERS,
            UserScope.COMPANY_OWNERS_AND_ADMINS,
            UserScope.SELECTED
        ];
    }
    get showUsersTable() {
        return this.selectedUserScope == UserScope.SELECTED;
    }
    get allCategories() {
        return NotificationCategory.getAllNotificationCategories();
    }
    async created() {
        userRepository = new UserRepository(this);
    }
    async loadUsers(pagination, sort, currentFilter) {
        let res = await userRepository.getUsersAdmin(null, pagination, sort, currentFilter);
        this.users = res.getData();
        this.totalSize = res.getPagination().total;
    }
    selectedUsersChanged(users) {
        this.selectedUsers = users;
    }
    async validateBeforeSubmit() {
        const usersSelected = await this.$refs.usersTable.validate();
        this.$validator.validateAll().then((result) => {
            if (result) {
                let usersIds = [];
                let userRoles = [];
                switch (this.selectedUserScope) {
                    case UserScope.ALL:
                        usersIds = this.users.map((x) => x.id);
                        userRoles = Object.values(ApiUserRoles);
                        break;
                    case UserScope.COMPANY_OWNERS:
                        usersIds = this.users.filter((x) => x.role.Name == ApiUserRoles.COMPANY_OWNER).map((x) => x.id);
                        userRoles = [ApiUserRoles.COMPANY_OWNER];
                        break;
                    case UserScope.COMPANY_OWNERS_AND_ADMINS:
                        usersIds = this.users
                            .filter((x) => x.role.Name == ApiUserRoles.COMPANY_OWNER || x.role.Name == ApiUserRoles.COMPANY_ADMIN)
                            .map((x) => x.id);
                        userRoles = [ApiUserRoles.COMPANY_OWNER, ApiUserRoles.COMPANY_ADMIN];
                        break;
                    case UserScope.SELECTED:
                        if (!usersSelected)
                            return;
                        usersIds = this.selectedUsers.map((x) => x.id);
                        userRoles = [];
                        break;
                }
                this.submitNotification(usersIds, userRoles);
            }
        });
    }
    async submitNotification(usersIds, userRoles) {
        let notification = {
            UserList: usersIds,
            Channels: this.selectedChannels,
            ToRole: userRoles,
            Priority: this.selectedPriority.text,
            Category: this.selectedCategory.name,
            Title: this.notificationTitle,
            Description: this.notificationText
        };
        this.$emit('sendNotification', notification);
        this.resetForm();
    }
    resetForm() {
        this.selectedUserScope = UserScope.ALL;
        this.selectedUsers = [];
        this.selectedPriority = null;
        this.notificationTitle = null;
        this.notificationText = null;
        this.$validator.reset(); //to avoid error messages on fields after clearing values
    }
};
__decorate([
    Prop({ type: Array })
], SendNotification.prototype, "priorities", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], SendNotification.prototype, "channels", void 0);
SendNotification = __decorate([
    Component({
        components: { ValidationProvider, ValidationObserver, UserListComponent }
    })
], SendNotification);
export default SendNotification;

export default class VueUtilities {
}
VueUtilities.openErrorToast = function (context, message) {
    context.$buefy.notification.open({
        message: message,
        duration: 4000,
        closable: false,
        position: 'is-bottom-right',
        type: 'is-danger',
        hasIcon: true,
        queue: false,
        autoClose: true
    });
};
VueUtilities.openSuccessToast = function (context, message) {
    context.$buefy.notification.open({
        message: message,
        duration: 4000,
        closable: false,
        position: 'is-bottom-right',
        type: 'is-success',
        hasIcon: true,
        queue: false,
        autoClose: true
    });
};

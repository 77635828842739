import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Source } from '@/entities/models/Source';
import UnitNames from '@/entities/enums/UnitNames';
import { Statistics } from '@/entities/Statistics';
import DateTimeManager from '@/services/DateTimeManager';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import DecimalConversion from '@/services/DecimalConversion';
let StatisticsTable = class StatisticsTable extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.DateTimeManager = DateTimeManager;
        this.UnitNames = UnitNames;
        this.Source = Source;
        this.Statistics = Statistics;
        this.hasTemperature = false;
        this.hasHumidity = false;
        this.hasDewPoint = false;
    }
    onActiveStatisticsLoad() {
        this.processData();
    }
    created() {
        this.processData();
    }
    processData() {
        this.hasTemperature = this.services.includes(UnitNames.TEMPERATURE.name);
        this.hasHumidity = this.services.includes(UnitNames.HUMIDITY.name);
        this.hasDewPoint = this.services.includes(UnitNames.DEWPOINT.name);
        this.statisticsForSource = {
            temperature: this.getStatistics(UnitNames.TEMPERATURE.name),
            humidity: this.getStatistics(UnitNames.HUMIDITY.name),
            dewpoint: this.getStatistics(UnitNames.DEWPOINT.name)
        };
    }
    getStatistics(serviceSlug) {
        return Statistics.getStatisticByService(this.activeStatistics, serviceSlug);
    }
    getCalibrationStatus() {
        if (this.activeSource.logger && this.activeSource.logger.calibration.status != CalibrationStatus.NOT_CALIBRATED) {
            const calibration = this.activeSource.logger.calibration;
            let startDate = new Date(this.dateFilter.startTS * 1000);
            let startUTC = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
            let stopDate = new Date(this.dateFilter.stopTS * 1000);
            let stopUTC = Date.UTC(stopDate.getFullYear(), stopDate.getMonth(), stopDate.getDate(), 23, 59, 59);
            if (calibration.calibratedAt <= Math.floor(startUTC / 1000) &&
                calibration.expiresAt >= Math.floor(stopUTC / 1000))
                return 'yes';
            else if (calibration.calibratedAt > Math.floor(startUTC / 1000) &&
                calibration.expiresAt < Math.floor(stopUTC / 1000))
                return 'no';
            else
                return 'partly';
        }
        else
            return 'no';
    }
    getCalibrationRange() {
        if (this.activeSource.logger && this.activeSource.logger.calibration.status != CalibrationStatus.NOT_CALIBRATED) {
            const calibration = this.activeSource.logger.calibration;
            return `(${this.dateTimeManager.formatTime(new Date(calibration.calibratedAt * 1000), 'UTC', null, '')} - ${this.dateTimeManager.formatTime(new Date(calibration.expiresAt * 1000), 'UTC', null, '')})`;
        }
        else
            null;
    }
};
__decorate([
    Prop({ type: Object })
], StatisticsTable.prototype, "activeSource", void 0);
__decorate([
    Prop({ type: Array })
], StatisticsTable.prototype, "services", void 0);
__decorate([
    Prop({ type: Array })
], StatisticsTable.prototype, "activeStatistics", void 0);
__decorate([
    Prop({ type: Object })
], StatisticsTable.prototype, "dateFilter", void 0);
__decorate([
    Prop({ type: Object })
], StatisticsTable.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], StatisticsTable.prototype, "onPublicPage", void 0);
__decorate([
    Watch('activeStatistics')
], StatisticsTable.prototype, "onActiveStatisticsLoad", null);
StatisticsTable = __decorate([
    Component({})
], StatisticsTable);
export default StatisticsTable;

import { ReportGenerationStatus } from '../enums/ReportGenerationStatus';
import User from '../User';
export class Report {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Report.define(et));
        }
        else if (data != null) {
            return Report.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let report = new Report();
        report.id = data.Id;
        report.metadata = data.Metadata;
        report.from = data.From;
        report.to = data.To;
        report.state = data.State;
        report.fileName = data.FileName;
        report.generatedAt = data.GeneratedAt;
        report.company = data.Company;
        report.reportType = data.ReportType;
        if (data.GeneratedBy)
            report.generatedBy = User.fromApi(data.GeneratedBy);
        report.assignButtonTagProperties();
        return report;
    }
    assignButtonTagProperties() {
        switch (this.state) {
            case ReportGenerationStatus.REQUESTED:
                this.stateTag = { type: 'is-warning', key: 'requested' };
                break;
            case ReportGenerationStatus.PENDING:
                this.stateTag = { type: 'is-info', key: 'pending' };
                break;
            case ReportGenerationStatus.ERROR:
                this.stateTag = { type: 'is-danger', key: 'error' };
                break;
            case ReportGenerationStatus.DONE:
                this.stateTag = { type: 'is-success', key: 'done' };
                break;
            case ReportGenerationStatus.INVALID:
                this.stateTag = { type: 'is-warning', key: 'invalid' };
                break;
            default:
                this.stateTag = { type: 'is-dark', key: 'unknown' };
                break;
        }
    }
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ContactUsForm from '@/views/components/feedback/ContactUsForm.vue';
import ManualComponent from '@/views/components/ManualComponent.vue';
import ReleaseNotesComponent from '@/views/components/ReleaseNotesComponent.vue';
import AppConfig from '@/configLoader';
let About = class About extends Vue {
    constructor() {
        super(...arguments);
        this.currentConfig = AppConfig.getConfig();
    }
};
About = __decorate([
    Component({ components: { ContactUsForm, ReleaseNotesComponent, ManualComponent } })
], About);
export default About;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { AdminUser, UserActions } from '@/entities/models/AdminUser';
import UserRepository from '@/services/repository/UserRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import NotificationRepository from '@/services/repository/NotificationRepository';
import AdminUserCompanySettings from '@/views/components/admin/user/AdminUserCompanySettings.vue';
import WarningModal from '@/views/components/administration/WarningModal.vue';
import AdminUserInfoComponent from '@/views/components/admin/user/AdminUserInfoComponent.vue';
import AdminUserSettingsComponent from '@/views/components/admin/user/AdminUserSettingsComponent.vue';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
import VueUtilities from '@/services/VueUtilities';
import UserPreferences from '@/services/UserPreferences';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import EventDomain from '@/entities/enums/eventDomains';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import ChangeUserRoleModal from '@/views/components/admin/user/ChangeUserRoleModal.vue';
import ApiResponse from '@/entities/ApiResponse';
import { DepartmentUserSettings } from '@/entities/DepartmentUserSettings';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import AdminUserSettingsEdit from '@/views/components/admin/user/AdminUserSettingsEdit.vue';
import { vxm } from '@/store/store.vuex';
import LoggedUserManager from '@/services/LoggedUserManager';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import ResellerRepository from '@/services/repository/ResellerRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import NotificationsTable from '@/views/components/notification/NotificationsTable.vue';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import AdminNotificationComponent from '@/views/components/notification/configurationPerType/AdminNotificationComponent.vue';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationPresetType from '@/entities/enums/NotificationPresetType';
import TiersRepository from '@/services/repository/TiersRepository';
import AddDepartmentsModal from '@/views/components/admin/reseller/AddDepartmentsModal.vue';
import NotificationTabs from '@/entities/notifications/NotificationTabs';
import NonWorkingHours from '@/views/components/notification/NonWorkingHours.vue';
import Timetable from '@/views/components/notification/Timetable.vue';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
var vxDepartmentStore = vxm.departmentStore;
var userSettingsRepository;
var userRepository;
var notificationRepository;
var notificationSubscriptionsRepository;
var eventTypeRepository;
var sourceRepository;
var sourceGroupRepository;
var departmentRepository;
var resellerRepository;
var tiersRepository;
let AdminUserDetailManagement = class AdminUserDetailManagement extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationTabs = NotificationTabs;
        this.user = new AdminUser();
        this.notificationsResult = new ApiResponse();
        this.isWarningModalActive = false;
        this.notificationSubscriptions = [];
        this.dateTimeManager = null;
        this.availableTypes = [];
        this.adminTypes = [];
        this.eventTypes = [];
        this.allEventTypes = [];
        this.sourceGroups = [];
        this.sources = [];
        this.units = [];
        this.allDepartmentsAdmin = [];
        this.departments = [];
        this.departmentsWithResellerEntities = [];
        this.selectedDepartment = null;
        this.departmentUserSettings = DepartmentUserSettings.fromApi({});
        this.notificationDepartmentUserSettings = DepartmentUserSettings.fromApi({});
        this.adminUserSettings = null;
        this.adminReseller = null;
        this.routeToNavigate = null;
        this.isUnsavedChangesModalActive = false;
        this.isUnsavedNotifChangesModalActive = false;
        this.isAddDepartmentModalActive = false;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.hasAdminRoleDepartment = false;
        this.hasResellerRoleDepartment = false;
        this.systemAdmindepartmentId = null;
        this.adminNotificationSubscriptions = [];
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserDetailLayoutList);
        this.activeTab = UserPreferences.LocalStorageKeys.AdminUserDetailLayoutActiveTab.defaultValue;
        this.warningModalTitle = null;
        this.warningModalMessage = null;
        this.warningModalConfirm = null;
        this.warningModalAction = null;
        this.isLoading = {
            AdminUserInfoComponent: true,
            AdminUserSettingsEdit: true,
            AdminUserCompanySettings: true,
            AdminRulesList: true,
            NotificationsTable: true
        };
        this.userSettingsLoaded = false;
        this.companySettingsLoaded = false;
        this.notificationsLoaded = false;
        this.notificationSettingsLoaded = false;
        this.notificationFilters = [];
        this.notificationDateRange = [];
        this.notificationSelectedDepartment = null;
        this.notificationSelectedDepartmentId = null;
        this.departmentName = '';
        this.prevRoute = null;
        this.tier = null;
        this.selectedMeasuredRulesMode = null;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.isChangingMode = false;
        this.presets = [];
        this.copiedRules = { companyId: null, rules: null, companyName: null };
        this.activeTabNotifications = NotificationTabs.GENERAL;
        this.schedules = [];
        this.timetableEnabled = false;
        this.defaultScheduleId = null;
        this.defaultSchedule = null;
        this.subscriptionsToCreate = [];
        this.haveChangesToSave = false;
        this.timetableSchedules = [];
        this.selectedPreset = null;
        this.measuredRuleEnabled = null;
    }
    get singleMeasuredRule() {
        return this.notificationSubscriptions?.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredRules() {
        return this.notificationSubscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get systemRules() {
        return this.notificationSubscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.SYSTEM_RULE);
    }
    get hasRulesForEntities() {
        return this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
    }
    get selectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    get hasPresetSelected() {
        return this.selectedPreset != null;
    }
    get userDepartments() {
        return this.allDepartmentsAdmin.filter(dep => this.departments.find(dep2 => dep2.Department.Id === dep.companyId));
    }
    get nonWorkingSchedules() {
        return this.schedules.filter((x) => x.type == NotificationScheduleType.SPECIAL_DAYS);
    }
    get hasAdminDepartmentSelectedInNotifications() {
        return this.systemAdmindepartmentId === this.notificationSelectedDepartmentId;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        userRepository = new UserRepository(this);
        notificationRepository = new NotificationRepository(this);
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        resellerRepository = new ResellerRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        tiersRepository = new TiersRepository(this);
        this.prevRoute = JSON.parse(localStorage.getItem(LocalStorageKeys.ADMIN_PREV_ROUTE));
        await this.loadData(this.$route.params.userId);
    }
    async loadData(userId) {
        if (this.departmentsWithResellerEntities.length > 0) {
            this.selectedDepartment = this.departmentsWithResellerEntities[0];
        }
        this.isLoading = {
            AdminUserInfoComponent: true,
            AdminUserSettingsEdit: true,
            AdminUserCompanySettings: true,
            AdminRulesList: true,
            NotificationsTable: true
        };
        userRepository.getAdminUser(userId).then(async (data) => {
            this.user = data;
            this.loadDepartmentsData(userId);
        });
    }
    changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserDetailLayoutActiveTab, event);
        switch (this.activeTab) {
            case SelectedTab.USERSETTINGS:
                if (!this.userSettingsLoaded) {
                    if (this.selectedDepartment) {
                        this.loadUserSettingsData(this.user.id, this.selectedDepartment.Department.Id);
                    }
                    this.userSettingsLoaded = true;
                }
                break;
            case SelectedTab.COMPANYSETTINGS:
                if (!this.userSettingsLoaded) {
                    if (this.selectedDepartment) {
                        this.loadUserSettingsData(this.user.id, this.selectedDepartment.Department.Id);
                    }
                    this.userSettingsLoaded = true;
                }
                break;
            case SelectedTab.NOTIFSETTINGS:
                if (!this.notificationSettingsLoaded) {
                    if (this.selectedDepartment) {
                        this.loadNotificationsData(this.notificationSelectedDepartmentId);
                    }
                    this.notificationSettingsLoaded = true;
                }
                break;
            case SelectedTab.NOTIFICATION:
                if (!this.notificationsLoaded) {
                    this.loadNotifications(this.notificationPagination, this.notificationSort, this.notificationFilters, this.notificationDateRange);
                    this.notificationsLoaded = true;
                }
                break;
        }
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserDetailLayoutList, this.isLayoutList);
        if (this.isLayoutList) {
            if (!this.userSettingsLoaded ||
                !this.companySettingsLoaded ||
                !this.notificationsLoaded ||
                !this.notificationSettingsLoaded) {
                if (this.selectedDepartment) {
                    this.loadUserSettingsData(this.user.id, this.selectedDepartment.Department.Id);
                    this.loadNotificationsData(this.notificationSelectedDepartmentId);
                    this.userSettingsLoaded = true;
                    this.companySettingsLoaded = true;
                }
                this.loadNotifications(this.notificationPagination, this.notificationSort, this.notificationFilters, this.notificationDateRange);
                this.notificationsLoaded = true;
            }
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminUserDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
    }
    loadDepartmentsData(userId) {
        departmentRepository.getAllDepartmentsForUserAdmin(userId).then(async (departments) => {
            this.departments = departments;
            if (departments.length > 0) {
                this.notificationSelectedDepartmentId = this.departments[0].Department.Id;
                //If current user is reseller admin in departments list
                let resellerAdminDepartments = departments.filter((x) => x.Member.Role.Key === ApiUserRoles.RESELLER_ADMIN);
                if (resellerAdminDepartments.length > 0) {
                    this.adminReseller = await resellerRepository.adminResellerByUserId(this.user.id);
                    let resellerDepartments = await resellerRepository.adminResellerDepartments(this.adminReseller.id);
                    resellerDepartments.forEach((department) => {
                        this.departmentsWithResellerEntities.push({
                            Department: { Id: department.companyId, Name: department.companyName, Role: null },
                            Member: null,
                            IsResellerDepartment: true
                        });
                    });
                }
                //If current user is system admin in departments list
                let systemAdminDepartments = departments.filter((x) => x.Member.Role.Key === ApiUserRoles.SYSTEM_ADMIN);
                if (systemAdminDepartments.length > 0) {
                    this.systemAdmindepartmentId = systemAdminDepartments[0].Department.Id;
                    this.hasAdminRoleDepartment = true;
                }
                let resellerDepartments = departments.filter((x) => x.Member.Role.Key === ApiUserRoles.RESELLER_ADMIN);
                if (resellerDepartments.length > 0) {
                    this.hasResellerRoleDepartment = true;
                }
                this.isLoading.AdminUserCompanySettings = false;
            }
            else {
                this.isLoading.AdminUserCompanySettings = false;
            }
            departments
                .map((x) => x)
                .forEach((department) => {
                this.departmentsWithResellerEntities.push({
                    Department: {
                        Id: department.Department.Id,
                        Name: department.Department.Name,
                        Role: department.Department.Role
                    },
                    Member: department.Member,
                    IsResellerDepartment: false
                });
            });
            if (this.hasAdminRoleDepartment || this.hasResellerRoleDepartment) {
                this.allDepartmentsAdmin = await departmentRepository.getAllDepartmentsAdmin();
                this.notificationSelectedDepartment = this.allDepartmentsAdmin?.find((x) => x.companyId == this.notificationSelectedDepartmentId);
            }
            if (this.isResellerAdmin) {
                this.loadAllAdminDepartments();
                this.departmentsWithResellerEntities = this.departmentsWithResellerEntities.filter((x) => this.allDepartmentsAdmin.map((y) => y.companyId).includes(x.Department.Id));
                this.departments = this.departments.filter((x) => this.allDepartmentsAdmin.map((y) => y.companyId).includes(x.Department.Id));
            }
            this.departmentsWithResellerEntities.sort((a, b) => (a.Department.Name > b.Department.Name ? 1 : -1));
            //remove duplicates
            this.departmentsWithResellerEntities = this.departmentsWithResellerEntities.filter((obj, index) => {
                return index === this.departmentsWithResellerEntities.findIndex((o) => obj.Department.Id === o.Department.Id);
            });
            if (this.departmentsWithResellerEntities.length > 0) {
                this.selectedDepartment = this.departmentsWithResellerEntities[0];
            }
            if (this.isLayoutList) {
                this.userSettingsLoaded = true;
                this.companySettingsLoaded = true;
                this.notificationsLoaded = true;
                this.notificationSettingsLoaded = true;
                if (this.selectedDepartment) {
                    this.loadUserSettingsData(this.user.id, this.selectedDepartment.Department.Id);
                }
                if (this.notificationSelectedDepartmentId) {
                    this.loadNotificationsData(this.notificationSelectedDepartmentId);
                }
                this.loadNotifications(this.notificationPagination, this.notificationSort, this.notificationFilters, this.notificationDateRange);
            }
            else {
                this.activeTab = parseInt(this.$route.query.activeTab)
                    ? parseInt(this.$route.query.activeTab)
                    : UserPreferences.LocalStorageKeys.AdminUserDetailLayoutActiveTab.defaultValue;
                this.changedActiveTab(this.activeTab);
            }
        });
        this.isLoading.AdminUserInfoComponent = false;
    }
    async loadAllAdminDepartments() {
        this.allDepartmentsAdmin = await departmentRepository.getAllDepartmentsAdmin();
    }
    /**
     * Load notifications rules available only for system admin
     */
    async loadAdminNotificationsRules() {
        this.isLoading.AdminRulesList = true;
        let adminPromises = [];
        adminPromises.push(notificationSubscriptionsRepository.adminGetAdminNotificationTypes());
        adminPromises.push(notificationSubscriptionsRepository.getAllNotificationSubscriptionsForUser(this.user.id, this.systemAdmindepartmentId));
        return Promise.all(adminPromises).then((response) => {
            this.adminTypes = response[0];
            this.adminNotificationSubscriptions = response[1];
            this.isLoading.AdminRulesList = false;
        });
    }
    getMeasuredRuleEnabled() {
        return this.singleMeasuredRule ? this.singleMeasuredRule.enabled : false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    selectDepartment(department) {
        this.selectedDepartment = department;
        this.loadUserSettingsData(this.user.id, this.selectedDepartment.Department.Id);
    }
    async loadUserSettingsData(userId, depId) {
        let settingPromises = [];
        this.isLoading.AdminUserSettingsEdit = true;
        this.isLoading.AdminUserCompanySettings = true;
        settingPromises.push(userRepository.getAdminUserSettings(userId));
        settingPromises.push(userSettingsRepository.loadUserSettingsForDepartment(userId, true, depId));
        if (depId) {
            settingPromises.push(eventTypeRepository.getAllUnits(depId));
            settingPromises.push(eventTypeRepository.getAllTypes(true, false, depId, false));
        }
        const response = await Promise.all(settingPromises);
        this.adminUserSettings = response[0];
        this.departmentUserSettings = response[1];
        if (depId) {
            this.units = response[2];
            this.allEventTypes = response[3];
        }
        this.isLoading.AdminUserSettingsEdit = false;
        this.isLoading.AdminUserCompanySettings = false;
    }
    async reloadSettings() {
        this.isLoading.AdminUserSettingsEdit = true;
        this.isLoading.AdminUserCompanySettings = true;
        this.adminUserSettings = await userRepository.getAdminUserSettings(this.user.id);
        this.departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.user.id, true, this.selectedDepartment.Department.Id);
        this.isLoading.AdminUserSettingsEdit = false;
        this.isLoading.AdminUserCompanySettings = false;
    }
    loadNotificationsData(departmentId) {
        this.isLoading.AdminRulesList = true;
        let notificationPromises = [];
        notificationPromises.push(notificationSubscriptionsRepository.getNotificationSchedules(this.user.apiUserId, departmentId));
        notificationPromises.push(notificationSubscriptionsRepository.getNotificationTypes());
        notificationPromises.push(eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true, false, departmentId));
        notificationPromises.push(sourceRepository.adminGetAllSources(departmentId));
        notificationPromises.push(sourceGroupRepository.adminGetAllGroups(departmentId));
        notificationPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.user.id, true, departmentId));
        if (LoggedUserManager.isResellerAdmin(this)) {
            notificationPromises.push(notificationSubscriptionsRepository.getNotificationPresets(this.user.id, departmentId));
        }
        else {
            notificationPromises.push(notificationSubscriptionsRepository.adminGetNotificationPresets());
        }
        notificationPromises.push(tiersRepository.getActiveCompanyTier(departmentId));
        Promise.all(notificationPromises).then((response) => {
            this.availableTypes = response[1];
            this.eventTypes = response[2];
            this.sources = response[3].getData().filter((x) => !x.sourceGroup);
            this.sourceGroups = response[4].getData();
            this.selectedMeasuredRulesMode = response[5].notifications.rules.mode ||
                NotificationMeasuredRuleScope.COMPANY;
            this.presets = response[6].filter(x => x.type != NotificationPresetType.DEPARTMENT);
            this.tier = response[7];
            this.schedules = response[0];
            this.notificationDepartmentUserSettings = response[5];
            let timetableMode = response[5].notifications.schedule.mode;
            if (timetableMode === NotificationScheduleType.TIMETABLE) {
                this.timetableEnabled = true;
                this.notificationSubscriptions = [];
                this.timetableSchedules = response[0].filter((x) => x.type === NotificationScheduleType.TIMETABLE);
            }
            else {
                this.timetableEnabled = false;
                this.defaultSchedule = response[0].find((x) => x.type === NotificationScheduleType.DEFAULT);
                this.defaultScheduleId = this.defaultSchedule?.id;
                this.notificationSubscriptions = this.defaultSchedule?.subscriptions || [];
                this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
            }
            if (this.systemAdmindepartmentId) {
                this.loadAdminNotificationsRules();
            }
            else {
                this.isLoading.AdminRulesList = false;
            }
        });
    }
    async loadNotifications(pagination, sort, filters = [], dateRange = [], departmentId = '') {
        this.isLoading.NotificationsTable = true;
        let convertedDateRange = dateRange.map((x) => this.dateTimeManager.formatTime(x, null, 'MM-DD-YYYY', null, false));
        this.notificationsResult = await notificationRepository.getAdminNotificationsForUser(this.$route.params.userId, pagination, sort, filters, convertedDateRange, departmentId);
        this.isLoading.NotificationsTable = false;
    }
    async onOptionsChange(options, filters = [], dateRange = [], departmentId = '') {
        this.loadNotifications(options.pagination, options.sort, filters, dateRange, departmentId);
    }
    async reloadUser() {
        this.isLoading.AdminUserInfoComponent = true;
        this.user = await userRepository.getAdminUser(this.user.id);
        this.isLoading.AdminUserInfoComponent = false;
    }
    openVerificationDialog(verification) {
        if (this.user.verified && !verification) {
            this.$buefy.dialog.confirm({
                title: this.$t('component.userAdministration.list.cancel_verification.title'),
                message: this.$t('component.userAdministration.list.cancel_verification.body'),
                confirmText: this.$t('component.userAdministration.list.cancel_verification.confirm'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.processUserAction(UserActions.UNVERIFY);
                }
            });
        }
        else {
            this.verifyUser();
        }
    }
    openWarningModal(title, msg, confirmBtn, action) {
        this.warningModalTitle = title;
        this.warningModalMessage = msg;
        this.warningModalConfirm = confirmBtn;
        this.warningModalAction = action;
        this.isWarningModalActive = true;
    }
    openAddDepartmentsModal() {
        this.isAddDepartmentModalActive = true;
    }
    closeAddDepartmentsModal() {
        this.isAddDepartmentModalActive = false;
    }
    closeModal(data) {
        this.isWarningModalActive = false;
    }
    async enableUser() {
        this.isLoading.AdminUserInfoComponent = true;
        let reason = {
            ActionReason: ' - '
        };
        await userRepository.adminEnableUser(this.user.id, reason);
        this.openSuccessToast(this.$t('component.userAdministration.list.disable.successfully_enabled').toString());
        this.reloadUser();
    }
    async disableUser(reasonText) {
        this.isLoading.AdminUserInfoComponent = true;
        this.isWarningModalActive = false;
        let reason = {
            ActionReason: reasonText
        };
        await userRepository.adminDisableUser(this.user.id, reason);
        this.openSuccessToast(this.$t('component.userAdministration.list.disable.successfully_disabled').toString());
        this.reloadUser();
    }
    processUserAction(action, reasonText) {
        if (action == UserActions.DISABLE)
            this.disableUser(reasonText);
        else if (action == UserActions.UNVERIFY)
            this.cancelVerification();
    }
    async makeVerificationCall() {
        this.isLoading.AdminUserInfoComponent = true;
        let updateDTO = {
            Email: this.user.email,
            FullName: this.user.fullName,
            Username: this.user.email,
            DetailedInfo: { Verified: !this.user.verified }
        };
        //repository call
        return userRepository.adminSaveUserChanges(this.user.id, updateDTO);
    }
    async verifyUser() {
        let result = await this.makeVerificationCall();
        if (result) {
            this.isLoading.AdminUserInfoComponent = false;
            this.openSuccessToast(this.$t('component.userAdministration.list.cancel_verification.successfully_verified').toString());
            this.reloadUser();
        }
    }
    async cancelVerification() {
        let result = await this.makeVerificationCall();
        if (result) {
            this.isWarningModalActive = false;
            this.isLoading.AdminUserInfoComponent = false;
            this.openSuccessToast(this.$t('component.userAdministration.list.cancel_verification.successfully_cancelled').toString());
            this.reloadUser();
        }
    }
    confirmNumberDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.settings.profile.user_info.delete_number.title'),
            message: this.$t('admin.component.user.detail.userInfo.delete_number.msg_body'),
            confirmText: this.$t('component.settings.profile.user_info.delete_number.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteNumber()
        });
    }
    async deleteNumber() {
        this.isLoading.AdminUserInfoComponent = true;
        let deleteResult = await userRepository.adminDeletePhoneNumber(this.user.id);
        if (deleteResult) {
            this.openSuccessToast(this.$t('admin.component.user.detail.userInfo.delete_number.success').toString());
            this.reloadUser();
        }
        else {
            this.isLoading.AdminUserInfoComponent = false;
            VueUtilities.openErrorToast(this, this.$t('admin.component.user.detail.userInfo.delete_number.failure'));
        }
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
        this.isUnsavedNotifChangesModalActive = false;
    }
    hasChanges() {
        return this.$refs.AdminUserInfoComponent.hasChanges;
    }
    saveChanges() {
        this.closeUnsavedChangesModal();
        //verification + repository call
        this.$refs.AdminUserInfoComponent.saveChanges();
    }
    reloadData(userId) {
        this.isLoading.AdminUserSettingsEdit = true;
        this.isLoading.AdminUserCompanySettings = true;
        let promises = [];
        promises.push(userRepository.getAdminUserSettings(userId));
        promises.push(userSettingsRepository.loadUserSettingsForDepartment(userId, true, this.selectedDepartment.Department.Id));
        promises.push(eventTypeRepository.getAllTypes(true, false, this.selectedDepartment.Department.Id, false));
        Promise.all(promises).then((response) => {
            this.adminUserSettings = response[0];
            this.departmentUserSettings = response[1];
            this.allEventTypes = response[2];
            this.isLoading.AdminUserSettingsEdit = false;
            this.isLoading.AdminUserCompanySettings = false;
        });
    }
    openSuccessToast(message) {
        VueUtilities.openSuccessToast(this, message);
    }
    companyName() {
        return this.departments?.find((x) => x.Department?.Id === this.prevRoute?.params?.companyId)?.Department.Name;
    }
    getAllDepartmentsForCurrentUser() {
        return vxDepartmentStore.departments;
    }
    //triggered when measured rules mode is changed
    async changeMode(selectedMode) {
        this.isChangingMode = true;
        this.measuredRuleEnabled = true;
        this.selectedMeasuredRulesMode = selectedMode;
        this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM &&
            x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        this.isChangingMode = false;
    }
    async notificationsDepartmentSelected() {
        this.subscriptionsToCreate = [];
        this.loadNotificationsData(this.notificationSelectedDepartmentId);
    }
    selectNotificationDepartment(department) {
        this.notificationSelectedDepartmentId = department.companyId;
        this.loadNotificationsData(this.notificationSelectedDepartmentId);
    }
    focusDepartmentName(event) {
        if (this.$refs.departmentName && event) {
            this.$refs.departmentName.focus();
        }
    }
    async applyPreset(preset) {
        this.isLoading.AdminRulesList = true;
        this.notificationSubscriptions = [];
        let presetWithSubscriptions = null;
        //Load preset with subscriptions
        let response = null;
        if (LoggedUserManager.isResellerAdmin(this)) {
            response = preset;
        }
        else {
            response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(preset.id);
        }
        //handle error response
        if (typeof response == 'string') {
            VueUtilities.openErrorToast(this, response);
            return;
        }
        else {
            presetWithSubscriptions = response;
        }
        this.selectedPreset = presetWithSubscriptions;
        this.selectedPreset.subscriptions.forEach((subs) => {
            this.notificationSubscriptions.push(NotificationSubscription.convertFromPreset(subs, this.selectedDepartmentId));
        });
        this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
        this.$nextTick(() => {
            this.isLoading.AdminRulesList = false;
        });
    }
    async addDepartmentsToUser(assignedDepartments) {
        this.isLoading.AdminUserInfoComponent = true;
        let userId = this.$route.params.userId;
        let promises = new Array();
        assignedDepartments.forEach((dep) => {
            promises.push(userRepository.assignAdminUserRole(dep.companyId, userId, dep.userRole));
        });
        await Promise.all(promises).then((response) => {
            if (response.find((x) => x !== true)) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.user.detail.assign_user_to_deps.failure'));
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.user.detail.assign_user_to_deps.success'));
                this.closeAddDepartmentsModal();
                this.loadDepartmentsData(userId);
            }
            this.isLoading.AdminUserInfoComponent = false;
        });
    }
    getRule(name) {
        return this.systemRules.find((x) => x.notificationType === name);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    getAdminRule(name) {
        return this.adminNotificationSubscriptions.find((x) => x.notificationType === name);
    }
    getAdminType(name) {
        return this.adminTypes.find((x) => x.name === name);
    }
    get hasRules() {
        return this.notificationSubscriptions?.length > 0;
    }
    async copyRulesDialog() {
        //if there is rule with logger or group scope it cannot be coppied
        if (this.notificationSubscriptions.find(x => x.scopeType !== NotificationScopeType.COMPANY)) {
            this.$buefy.dialog.confirm({
                title: this.$t('component.notification.rules_list.copy_rules_dialog.copy_tooltip_msg'),
                message: this.$t('component.notification.rules_list.copy_rules_dialog.dialog_msg'),
                cancelText: this.$t('component.notification.rules_list.copy_rules_dialog.cancel'),
                confirmText: this.$t('component.notification.rules_list.copy_rules_dialog.apply'),
                type: 'is-success',
                onConfirm: () => this.copyRules()
            });
        }
        else {
            this.copyRules();
        }
    }
    copyRules() {
        this.copiedRules.rules = this.notificationSubscriptions;
        this.copiedRules.companyId = this.notificationSelectedDepartmentId;
        this.copiedRules.companyName = this.departmentsWithResellerEntities.find((x) => x.Department.Id === this.notificationSelectedDepartmentId).Department.Name;
        VueUtilities.openSuccessToast(this, this.$t('component.notification.rules_list.copy_rules.copy_success').toString());
    }
    confirmPasteRules() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.notification.rules_list.paste_rules.paste_tooltip_msg'),
            message: this.$t('component.notification.rules_list.paste_rules.dialog_msg', {
                originDep: this.copiedRules.companyName,
                newDep: this.departmentsWithResellerEntities.find((x) => x.Department.Id === this.notificationSelectedDepartmentId).Department.Name
            }),
            cancelText: this.$t('component.notification.rules_list.paste_rules.cancel'),
            confirmText: this.$t('component.notification.rules_list.paste_rules.apply'),
            type: 'is-success',
            onConfirm: () => this.pasteRules()
        });
    }
    async pasteRules() {
        let response = await notificationSubscriptionsRepository.deleteAllNotificationSubscriptions(this.user.id, this.defaultSchedule?.id, this.notificationSelectedDepartmentId);
        if (response !== true) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                error: response
            }));
            return;
        }
        let rulesToCreate = [];
        this.copiedRules.rules.forEach((rule) => {
            //filter out settings for loggers and source groups, we are not able to copy such rules
            if (rule.scopeType === NotificationScopeType.SOURCE ||
                rule.scopeType === NotificationScopeType.SOURCE_GROUP)
                return;
            let currentChannelsDTO = {};
            rule.channels.forEach((channel) => {
                let channelDTO = {
                    Settings: {
                        delay: channel.settings.delay,
                        onResolve: channel.settings.onResolve,
                        repeat: channel.settings.repeat
                    },
                    IsPaused: false
                };
                currentChannelsDTO[channel.type] = channelDTO;
            });
            let newRule = {
                ScopeType: rule.scopeType,
                ScopeId: this.notificationSelectedDepartmentId,
                NotificationType: rule.notificationType,
                Priority: rule.priority.name,
                Enabled: rule.enabled,
                Channels: currentChannelsDTO,
                DisplayMode: rule.displayMode,
                PresetId: rule.presetId
            };
            rulesToCreate.push(newRule);
        });
        let result = await notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.user.id, this.notificationSelectedDepartmentId, this.defaultSchedule?.id);
        if (result instanceof (Array)) {
            VueUtilities.openSuccessToast(this, this.$t('component.notification.create_rule.bulk_success'));
            this.loadNotificationsData(this.notificationSelectedDepartmentId);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                error: result
            }));
        }
    }
    get filteredDepartments() {
        return this.allDepartmentsAdmin.filter((dep) => dep.companyName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.departmentName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    tabChanged(tab) {
        //set active tab in url;
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: tab
            })
        })
            .catch((err) => err);
    }
    async onEnableTimetable() {
        this.isLoading.AdminRulesList = true;
        await notificationSubscriptionsRepository.changeTimetableModeForUser(this.user.apiUserId, this.notificationSelectedDepartmentId);
        VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.use_timetable_success'));
        this.loadNotificationsData(this.notificationSelectedDepartmentId);
    }
    async reloadSchedules() {
        this.isLoading.AdminRulesList = true;
        this.schedules = await notificationSubscriptionsRepository.getNotificationSchedules(this.user.apiUserId, this.notificationSelectedDepartmentId);
        this.notificationDepartmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.user.apiUserId, true, this.notificationSelectedDepartmentId);
        this.timetableSchedules = this.schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        this.isLoading.AdminRulesList = false;
    }
    //triggered when some subscriptions is changed
    ruleChanged(newRule) {
        if (newRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            newRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            let indexOfMeasured = this.subscriptionsToCreate.findIndex((x) => (x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                x.ScopeId === newRule.ScopeId);
            if (indexOfMeasured >= 0) {
                //remove this measured rule by index
                this.subscriptionsToCreate.splice(indexOfMeasured, 1);
            }
        }
        else {
            //remove current subscription from list
            this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== newRule.NotificationType);
        }
        this.subscriptionsToCreate.push(newRule);
        this.haveChangesToSave = !NotificationSubscription.compareSubscriptionsWithSubscriptionsCreateDto(this.defaultSchedule?.subscriptions || [], this.subscriptionsToCreate);
    }
    async saveDefault() {
        let errors = [];
        this.isLoading.AdminRulesList = true;
        //check if preset is used
        //check if preset was selected and compare current subscriptions with preset
        if (this.selectedPreset) {
            let presetIsSameAsSubs = this.selectedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
            //if subscriptions are same, set preset id
            if (presetIsSameAsSubs == true) {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = this.selectedPreset.id;
                });
            }
            else {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        else {
            // if subscriptions are not same, check if there was some preset defined before and if yes,
            //compare subscriptions from this preset (if exists), otherwise set presetId to null
            let presetIds = [...new Set(this.subscriptionsToCreate.map((x) => x.PresetId))];
            if (presetIds.length === 1 && presetIds[0] != null) {
                //Load preset with subscriptions
                let response = null;
                if (LoggedUserManager.isResellerAdmin(this)) {
                    response = this.presets.find((x) => x.id === presetIds[0]);
                }
                else {
                    response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(presetIds[0]);
                }
                //handle error response
                if (typeof response == 'string') {
                    VueUtilities.openErrorToast(this, response);
                    return;
                }
                let loadedPreset = response;
                if (!loadedPreset) {
                    //if preset does not exists
                    this.subscriptionsToCreate.forEach((sub) => {
                        sub.PresetId = null;
                    });
                }
                else {
                    //if preset exists, compare subscriptions
                    let presetIsSameAsSubs = loadedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
                    if (presetIsSameAsSubs == true) {
                        //if subs are same
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = loadedPreset.id;
                        });
                    }
                    else {
                        //if not, set preset id to null
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = null;
                        });
                    }
                }
            }
            else {
                //if there is multiple presets in subscriptions or is null
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        let promises = [];
        //check if type of measured rule was updated
        if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.COMPANY) {
            let measuredForEntity = (this.defaultSchedule?.subscriptions || []).filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                [NotificationScopeType.SOURCE, NotificationScopeType.SOURCE_GROUP].includes(x.scopeType));
            measuredForEntity.forEach((subToDel) => {
                promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.user.apiUserId, this.notificationSelectedDepartmentId));
            });
        }
        else if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP) {
            let measuredForCompany = (this.defaultSchedule?.subscriptions || []).filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                x.scopeType === NotificationScopeType.COMPANY);
            measuredForCompany.forEach((subToDel) => {
                promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.user.apiUserId, this.notificationSelectedDepartmentId));
            });
        }
        //update or create subscription
        let newSubsToCreate = [];
        this.subscriptionsToCreate.forEach((subs) => {
            let idOfExisting = null;
            if (this.availableTypes.find((x) => x.name === subs.NotificationType)?.category ==
                NotificationSubscriptionCategory.MEASUREMENT_RULE) {
                idOfExisting = (this.defaultSchedule?.subscriptions || []).find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                    x.scopeType === subs.ScopeType &&
                    x.scopeId === subs.ScopeId)?.notificationSubscriptionId;
            }
            else {
                idOfExisting = (this.defaultSchedule?.subscriptions || []).find((x) => x.notificationType === subs.NotificationType)?.notificationSubscriptionId;
            }
            if (idOfExisting) {
                let subsUpdateDto = {
                    Channels: subs.Channels,
                    NotificationType: subs.NotificationType,
                    PresetId: subs.PresetId,
                    Priority: subs.Priority,
                    DisplayMode: subs.DisplayMode,
                    Enabled: subs.Enabled
                };
                promises.push(notificationSubscriptionsRepository.updateNotificationSubscription(idOfExisting, subsUpdateDto, this.user.apiUserId, this.notificationSelectedDepartmentId));
            }
            else {
                let newRule = {
                    ScopeType: subs.ScopeType,
                    ScopeId: subs.ScopeId,
                    NotificationType: subs.NotificationType,
                    Enabled: subs.Enabled,
                    Channels: subs.Channels,
                    DisplayMode: subs.DisplayMode,
                    PresetId: subs.PresetId
                };
                newSubsToCreate.push(newRule);
            }
        });
        //Create subs in one call
        if (newSubsToCreate.length > 0) {
            promises.push(notificationSubscriptionsRepository.createNotificationSubscription(newSubsToCreate, this.user.apiUserId, this.notificationSelectedDepartmentId, null));
        }
        //check responses
        await Promise.all(promises).then((response) => {
            errors = response.filter((x) => typeof x === 'string');
        });
        if (errors.length <= 0) {
            await userSettingsRepository.saveUserSettingForDepartment(this.user.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.MODE], this.selectedMeasuredRulesMode, this.notificationSelectedDepartmentId);
        }
        if (errors.length <= 0) {
            VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.success'));
            this.loadNotificationsData(this.notificationSelectedDepartmentId);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.failure', {
                error: errors.join(', ')
            }));
            this.isLoading.AdminRulesList = false;
        }
        this.isUnsavedChangesModalActive = false;
    }
    saveNotifChanges() {
        this.closeUnsavedChangesModal();
        this.saveDefault();
    }
};
AdminUserDetailManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_USERS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_USERS_SETTINGS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            UserPreferences.deletePreference(UserPreferences.LocalStorageKeys.AdminNotificationsListSortDirection);
            UserPreferences.deletePreference(UserPreferences.LocalStorageKeys.AdminNotificationsListSortField);
            UserPreferences.deletePreference(UserPreferences.LocalStorageKeys.AdminNotificationsListPage);
            if (this.prevRoute === null)
                localStorage.setItem(LocalStorageKeys.ADMIN_PREV_ROUTE, JSON.stringify({
                    name: this.$route.name,
                    params: this.$route.params,
                    meta: { name: this.user.username }
                }));
            if (this.hasChanges() && to.query.confirmed != 'true') {
                this.routeToNavigate = to;
                this.isUnsavedChangesModalActive = true;
                return;
            }
            else if (this.timetableEnabled === false) {
                if (this.haveChangesToSave === true && to.query.confirmed != 'true') {
                    this.routeToNavigate = to;
                    this.isUnsavedChangesModalActive = true;
                }
                else {
                    next();
                }
            }
            else if (this.timetableEnabled === true) {
                if (this.$refs.timetableComponent.haveChangesToSave === true && to.query.confirmed != 'true') {
                    this.$refs.timetableComponent.routeToNavigateOnExit = to;
                    this.$refs.timetableComponent.isUnsavedChangesModalActive = true;
                }
                else {
                    next();
                }
            }
            else {
                next();
            }
        },
        components: {
            NotificationsTable,
            AdminUserInfoComponent,
            AdminUserSettingsEdit,
            AdminUserSettingsComponent,
            WarningModal,
            AdminUserCompanySettings,
            ChangeUserRoleModal,
            UnsavedChangesDialog,
            MeasuredDataNotification,
            EntityMeasuredDataNotification,
            GeneralNotificationComponent,
            AdminNotificationComponent,
            AddDepartmentsModal,
            NonWorkingHours,
            Timetable
        }
    })
], AdminUserDetailManagement);
export default AdminUserDetailManagement;
var SelectedTab;
(function (SelectedTab) {
    SelectedTab[SelectedTab["INFO"] = 0] = "INFO";
    SelectedTab[SelectedTab["USERSETTINGS"] = 1] = "USERSETTINGS";
    SelectedTab[SelectedTab["COMPANYSETTINGS"] = 2] = "COMPANYSETTINGS";
    SelectedTab[SelectedTab["NOTIFSETTINGS"] = 3] = "NOTIFSETTINGS";
    SelectedTab[SelectedTab["NOTIFICATION"] = 4] = "NOTIFICATION";
})(SelectedTab || (SelectedTab = {}));

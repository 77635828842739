export class NotificationPriority {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => NotificationPriority.define(et));
        }
        else if (data != null) {
            return NotificationPriority.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let notificationPriority = new NotificationPriority();
        notificationPriority.value = data.Value;
        notificationPriority.text = data.Text;
        return notificationPriority;
    }
}

import DateTimeManager from '@/services/DateTimeManager';
var dateTimeManager;
var format;
/**
 * Chart date formatter
 */
export default class ChartDateFormatter {
    constructor() { }
    /**
     * Creates instance of ChartDateFormatter
     * @param ctx application context
     * @returns ChartDateFormatter instance
     */
    static async CreateFormatter(ctx, useDefaultValues = false) {
        let formatter = new ChartDateFormatter();
        await formatter.initDateFormatter(ctx, useDefaultValues);
        return formatter;
    }
    /**
     * Inits date formatter
     * @param ctx application context
     */
    async initDateFormatter(ctx, useDefaultValues = false) {
        dateTimeManager = await DateTimeManager.CreateManager(ctx, useDefaultValues);
        format = dateTimeManager.getChartDateFormat();
    }
    /**
     * Gets date formatter for chart axis
     * @returns chart axis date formatter
     */
    getFormatter() {
        var tickFormat = function (date) {
            if (date.getSeconds())
                return dateTimeManager.formatTime(date, null, format.second, '');
            if (date.getMinutes())
                return dateTimeManager.formatTime(date, null, format.minute, '');
            if (date.getHours())
                return dateTimeManager.formatTime(date, null, format.hour, '');
            if (date.getDay() && date.getDate() != 1)
                return dateTimeManager.formatTime(date, null, format.day, '');
            if (date.getDate() != 1)
                return dateTimeManager.formatTime(date, null, format.date, '');
            if (date.getMonth())
                return dateTimeManager.formatTime(date, null, format.month, '');
            return dateTimeManager.formatTime(date, null, format.year, '');
        };
        return tickFormat;
    }
}

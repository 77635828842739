var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("article", { staticClass: "message is-warning pb-3" }, [
                _c("div", { staticClass: "message-body" }, [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.notification.rules_list.mode_change.waiting"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box" },
            [
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "column is-one-quarter is-one-quarter-widescreen",
                  },
                  [
                    _c("h2", { staticClass: "subtitle has-text-dark" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("notifications.configuration.preset_title")
                        )
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "is-flex flex-orientation-row is-align-items-center",
                    },
                    [
                      _c(
                        "b-field",
                        { staticClass: "mb-0" },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "notifications.configuration.select_preset_placeholder"
                                ),
                              },
                              model: {
                                value: _vm.selectedPreset,
                                callback: function ($$v) {
                                  _vm.selectedPreset = $$v
                                },
                                expression: "selectedPreset",
                              },
                            },
                            _vm._l(_vm.presets, function (preset) {
                              return _c(
                                "option",
                                { key: preset.id, domProps: { value: preset } },
                                [_vm._v(" " + _vm._s(preset.name) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "button is-small is-primary ml-5",
                          attrs: { disabled: !_vm.hasPresetSelected },
                          on: { click: _vm.applyPreset },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "notifications.configuration.apply_preset_btn"
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("hr"),
              _c("h2", { staticClass: "subtitle has-text-dark" }, [
                _vm._v(
                  _vm._s(_vm.$t("notifications.configuration.custom_title"))
                ),
              ]),
              _vm.isChangingMode
                ? _c(
                    "div",
                    [
                      _c("b-skeleton", {
                        attrs: { width: "20%", animated: true },
                      }),
                      _c("b-skeleton", {
                        attrs: { width: "40%", animated: true },
                      }),
                      _c("b-skeleton", {
                        attrs: { width: "80%", animated: true },
                      }),
                      _c("b-skeleton", { attrs: { animated: true } }),
                    ],
                    1
                  )
                : [
                    !_vm.hasRulesForEntities
                      ? _c("MeasuredDataNotification", {
                          attrs: {
                            rule: _vm.singleMeasuredRule,
                            types: _vm.measuredTypes,
                            currentUser: _vm.currentUser,
                            selectedMeasuredRulesMode:
                              _vm.selectedMeasuredRulesMode,
                            tier: _vm.tier,
                            measuredRuleEnabled: _vm.measuredRuleEnabled,
                            departmentId: _vm.selectedDepartmentId,
                          },
                          on: {
                            changeMode: _vm.changeMode,
                            switchedValue: _vm.switchedValue,
                          },
                        })
                      : _c("EntityMeasuredDataNotification", {
                          attrs: {
                            rules: _vm.measuredRules,
                            types: _vm.measuredTypes,
                            currentUser: _vm.currentUser,
                            selectedMeasuredRulesMode:
                              _vm.selectedMeasuredRulesMode,
                            departmentId: _vm.selectedDepartmentId,
                            tier: _vm.tier,
                          },
                          on: { changeMode: _vm.changeMode },
                        }),
                  ],
              _c("hr"),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                  ),
                  currentUser: _vm.currentUser,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("hr"),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                  ),
                  currentUser: _vm.currentUser,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("hr"),
              _c("h2", { staticClass: "title has-text-dark" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("notifications.configuration.other_rules_title")
                  )
                ),
              ]),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.LOW_BATTERY
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.LOW_BATTERY
                  ),
                  currentUser: _vm.currentUser,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION
                  ),
                  currentUser: _vm.currentUser,
                  advancedAllowed: false,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.PDF_RECORDS_REGENERATED
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.PDF_RECORDS_REGENERATED
                  ),
                  currentUser: _vm.currentUser,
                  advancedAllowed: false,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED
                  ),
                  currentUser: _vm.currentUser,
                  advancedAllowed: false,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.RECORDS_UPDATED
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.RECORDS_UPDATED
                  ),
                  currentUser: _vm.currentUser,
                  advancedAllowed: false,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("GeneralNotificationComponent", {
                attrs: {
                  rule: _vm.getRule(
                    _vm.NotificationSubscriptionType.LOGGER_STATE
                  ),
                  type: _vm.getType(
                    _vm.NotificationSubscriptionType.LOGGER_STATE
                  ),
                  currentUser: _vm.currentUser,
                  advancedAllowed: false,
                  departmentId: _vm.selectedDepartmentId,
                },
              }),
              _c("hr"),
              _c("OtherNotificationSettings", {
                attrs: { departmentSettings: _vm.localDepartmentSettings },
                on: {
                  updateOptimizeNotificationsSetting:
                    _vm.updateOptimizeNotificationsSetting,
                },
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
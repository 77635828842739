var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [_vm._v(" " + _vm._s(_vm.$t("component.dashboard.title")) + " ")]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor" }, [
        _c(
          "div",
          { staticClass: "tile is-vertical" },
          [
            !_vm.isLoading
              ? [
                  _vm.noLoggerOrDevice && _vm.isWizardAllowed
                    ? _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _c(
                            "b-message",
                            {
                              staticClass: "tile is-child",
                              attrs: { type: "is-info" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.dashboard.wizard_message")
                                  ) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "wizard",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                        departmentId:
                                          _vm.$route.params.departmentId,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("component.dashboard.wizard")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.pauseComponentVisible
                    ? _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _c("PauseNotificationsMessage", {
                            staticClass: "tile is-child",
                            on: { reloadPause: _vm.reloadNotificationPause },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.invitationNoticeVisible
                    ? _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _c(
                            "b-message",
                            { attrs: { type: "is-warning" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.invitation.inv_notice")
                                  ) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "organizations",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("generals.view_all"))),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            !_vm.isLoading
              ? _c("div", { staticClass: "tile" }, [
                  _c("div", { staticClass: "tile is-parent" }, [
                    _c(
                      "div",
                      { staticClass: "tile is-child" },
                      [
                        _c("StatusCards", {
                          staticClass: "is-hidden-mobile",
                          attrs: {
                            deviceList: _vm.deviceList,
                            sources: _vm.sources,
                            lastEvents: _vm.aliveEvents,
                            notifications:
                              _vm.lastDaynotificationsResult.getData(),
                            totalSize:
                              _vm.lastDaynotificationsResult.getPagination()
                                .total,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                        }),
                        _c("StatusCardsMobile", {
                          staticClass: "is-hidden-tablet",
                          attrs: {
                            deviceList: _vm.deviceList,
                            sources: _vm.sources,
                            lastEvents: _vm.aliveEvents,
                            notifications:
                              _vm.lastDaynotificationsResult.getData(),
                            totalSize:
                              _vm.lastDaynotificationsResult.getPagination()
                                .total,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "tile" }, [
              _c("div", { staticClass: "tile is-parent is-7" }, [
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.MEASURED_DATA_READ
                ) && !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "tile is-child" },
                      [
                        _vm.floorplanEnabled
                          ? _c(
                              "div",
                              { staticClass: "box" },
                              [
                                _c(
                                  "b-collapse",
                                  {
                                    staticClass: "panel outdated",
                                    attrs: { animation: "slide" },
                                    on: {
                                      open: function ($event) {
                                        return _vm.floorPlanCollapseChanged(
                                          true
                                        )
                                      },
                                      close: function ($event) {
                                        return _vm.floorPlanCollapseChanged(
                                          false
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "trigger",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "panel-heading m-0 p-0",
                                                  attrs: {
                                                    role: "button",
                                                    id: "refers",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "has-text-weight-medium is-flex is-justify-content-space-between",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "is-flex",
                                                        },
                                                        [
                                                          _c(
                                                            "h2",
                                                            {
                                                              staticClass:
                                                                "subtitle is-4 m-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "component.floorPlan.title"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.floorPlanVisible
                                                        ? _c("b-icon", {
                                                            attrs: {
                                                              icon: "chevron-down mt-1",
                                                            },
                                                          })
                                                        : _c("b-icon", {
                                                            attrs: {
                                                              icon: "chevron-right mt-1",
                                                            },
                                                          }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2217549541
                                    ),
                                    model: {
                                      value: _vm.floorPlanVisible,
                                      callback: function ($$v) {
                                        _vm.floorPlanVisible = $$v
                                      },
                                      expression: "floorPlanVisible",
                                    },
                                  },
                                  [
                                    _vm.floorPlanData.length !== 0
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-4" },
                                          [
                                            _c("FloorPlanMeasurements", {
                                              attrs: {
                                                floorPlanData:
                                                  _vm.floorPlanData,
                                                floorPlanImage:
                                                  _vm.floorPlanImage,
                                                imageType: _vm.imageType,
                                                lastEvents:
                                                  _vm.aliveEventsFloorPlan,
                                                sources: _vm.sources,
                                                allLastEvents:
                                                  _vm.lastEventsFloorPlan,
                                                sourceGroups: _vm.sourceGroups,
                                                devices: _vm.deviceList,
                                                canvasId: "mainCanvas",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mt-3 is-flex is-justify-content-right",
                                              },
                                              [
                                                _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "component.floorPlan.enlarge"
                                                      ),
                                                      position: "is-left",
                                                      type: "is-dark",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          size: "is-small",
                                                          type: "is-primary",
                                                          "icon-left":
                                                            "expand-arrows-alt",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.enlargeFloorplan()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "component.floorPlan.enlarge"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content has-text-grey has-text-centered",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.floorPlan.noFloorPlan"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("LastEventsTable", {
                          attrs: {
                            lastEvents: _vm.lastEvents,
                            sourceGroups: _vm.sourceGroups,
                            sources: _vm.sources,
                            refreshAllowed: _vm.refreshAllowed,
                            favoriteGroupId: _vm.favoriteGroupId,
                          },
                          on: {
                            refresh: _vm.refreshMeasurements,
                            favoriteSelected: _vm.favoriteSelected,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "tile is-parent is-5 is-vertical" }, [
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "tile is-child" },
                      [
                        _vm.$ability.can(
                          _vm.$permActions.READ,
                          _vm.$permSubjects.MEASURED_DATA_READ
                        ) && !_vm.isLoading
                          ? _c("DevicesState", {
                              staticClass: "box",
                              attrs: {
                                dateTimeManager: _vm.dateTimeManager,
                                deviceList: _vm.deviceList,
                              },
                            })
                          : _vm._e(),
                        _c("NotificationCard", {
                          staticClass: "box",
                          attrs: {
                            notifications: _vm.notificationsResult.getData(),
                            allNotificationCount:
                              _vm.notificationsResult.getPagination().total,
                            paused: _vm.notificationsPaused,
                            pausedTill: _vm.pauseNotificationsUntil,
                            dateTimeManager: _vm.dateTimeManager,
                            buttonEnabled: true,
                          },
                          on: { notificationRemoved: _vm.notificationRemoved },
                        }),
                        !_vm.isLoading
                          ? _c(
                              "div",
                              [
                                _c("LoggerExpirationList", {
                                  staticClass: "box",
                                  attrs: {
                                    sources: _vm.sources,
                                    dateTimeManager: _vm.dateTimeManager,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _c(
        "b-modal",
        {
          staticClass: "pt-0",
          attrs: {
            active: _vm.isFullScreenFloorPlanActive,
            "has-modal-card": "",
            onCancel: _vm.closeFullScreenFloorPlan,
            "full-screen": "",
            "can-cancel": false,
          },
          on: {
            "update:active": function ($event) {
              _vm.isFullScreenFloorPlanActive = $event
            },
            "close-modal": _vm.closeFullScreenFloorPlan,
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("component.floorPlan.title")) + " "
                  ),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "modal-card-body" },
                [
                  _c("FloorPlanMeasurements", {
                    attrs: {
                      floorPlanData: _vm.floorPlanData,
                      floorPlanImage: _vm.floorPlanImage,
                      imageType: _vm.imageType,
                      lastEvents: _vm.aliveEventsFloorPlan,
                      sources: _vm.sources,
                      allLastEvents: _vm.lastEventsFloorPlan,
                      sourceGroups: _vm.sourceGroups,
                      devices: _vm.deviceList,
                      canvasId: "modalCanvas",
                    },
                  }),
                ],
                1
              ),
              _c(
                "footer",
                { staticClass: "modal-card-foot" },
                [
                  _c("b-button", {
                    attrs: {
                      type: "is-primary",
                      label: _vm.$t("generals.close"),
                    },
                    on: { click: _vm.closeFullScreenFloorPlan },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
let AddResellerDevicesModal = class AddResellerDevicesModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDevices = new Array();
    }
    async created() {
        this.$emit('loadDevices');
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    selectedDevice(device) {
        if (!this.devices.find((x) => x.deviceId === device.deviceId))
            this.selectedDevices.find((x) => x.deviceId === device.deviceId)
                ? this.selectedDevices.splice(this.selectedDevices.indexOf(this.selectedDevices.find((x) => x.deviceId === device.deviceId)), 1)
                : this.selectedDevices.push(device);
    }
    async addResellerDevices() {
        this.$validator.validateAll().then(async (result) => {
            if (this.selectedDevices.length <= 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerDevices.list.addDeviceModal.select_device'));
            }
            else {
                let selectedDevicesId = [];
                this.selectedDevices.forEach((device) => {
                    selectedDevicesId.push(device.deviceId);
                });
                this.$emit('addResellerDevices', selectedDevicesId);
            }
        });
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], AddResellerDevicesModal.prototype, "availableDevices", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AddResellerDevicesModal.prototype, "devices", void 0);
AddResellerDevicesModal = __decorate([
    Component({})
], AddResellerDevicesModal);
export default AddResellerDevicesModal;

import ReportingInterval from '../enums/ReportingInterval';
import StatisticsType from '../enums/statisticsType';
export class ReportConfiguration {
    constructor() {
        this.models = new Array();
    }
    static fromApi(data) {
        if (data != null) {
            return ReportConfiguration.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let parsedData = null;
        try {
            parsedData = JSON.parse(data);
        }
        catch (error) {
            return null;
        }
        let reportConf = new ReportConfiguration();
        reportConf.interval = parsedData.interval;
        if (parsedData.models)
            reportConf.models = ReportConfigurationModel.fromApi(parsedData.models);
        return reportConf;
    }
    static getDefaultConfiguration() {
        let defaultConfiguration = '{"interval":120,"models":[{"modelParameters":{"Offset":0},"model":"daily"}]}';
        return ReportConfiguration.fromApi(defaultConfiguration);
    }
    getModel() {
        if (this.models[0])
            return this.models[0];
        else
            return ReportConfiguration.getDefaultConfiguration().models[0];
    }
    getOffsetForConfiguration() {
        if (this.canHaveOffset())
            return this.getModel().getOffset();
        else
            return 0;
    }
    canHaveOffset() {
        if (this.interval > ReportingInterval.INTERVAL_01_00)
            return true;
        else
            return false;
    }
}
export class ReportConfigurationModel {
    constructor() {
        this.modelParameters = {};
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => ReportConfigurationModel.define(et));
        }
        else if (data != null) {
            return ReportConfigurationModel.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let reportConf = new ReportConfigurationModel();
        reportConf.model = data.model;
        reportConf.modelParameters = data.modelParameters;
        return reportConf;
    }
    getOffset() {
        if (this.model === StatisticsType.DAILY)
            return this.modelParameters.Offset;
        else
            return null;
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import PauseNotificationsInterval from '@/entities/enums/PauseNotificationsInterval';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
var userSettingsRepository;
var notificationSubscriptionRepository;
const SEC_IN_MINUTE = 60;
let PauseNotificationsComponent = class PauseNotificationsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.PauseNotificationsInterval = PauseNotificationsInterval;
    }
    created() {
        notificationSubscriptionRepository = new NotificationSubscriptionRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
    }
    async pauseNotifications(interval) {
        let now = Math.floor(new Date().getTime() / 1000);
        let until = now + interval * SEC_IN_MINUTE;
        let pauseDto = {
            PauseForMinutes: interval,
            PauseByType: 'measuredDataAlarm'
        };
        let result = await notificationSubscriptionRepository.pauseNotification(pauseDto, this.currentUser.apiUserId);
        if (result == true) {
            await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.PAUSE, UserSettingsKeys.UNTIL], until);
            this.$emit('reloadPause');
        }
    }
};
__decorate([
    Prop({ type: Boolean })
], PauseNotificationsComponent.prototype, "paused", void 0);
__decorate([
    Prop({ type: Object })
], PauseNotificationsComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], PauseNotificationsComponent.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Number })
], PauseNotificationsComponent.prototype, "pausedTill", void 0);
PauseNotificationsComponent = __decorate([
    Component({ components: {} })
], PauseNotificationsComponent);
export default PauseNotificationsComponent;

import i18n from '@/lang/lang';
import NotificationChannelType from './NotificationChannelType';
export default class NotificationChannel {
    static ResponseToArray(response) {
        let converted = new Array();
        let keys = Object.keys(response);
        keys.forEach((key) => {
            let channel = new NotificationChannel();
            channel.type = key;
            channel.cooldown = response[key].Cooldown;
            channel.isPaused = response[key].IsPaused;
            channel.settings = {
                delay: parseInt(response[key]?.Settings?.delay || 0),
                repeat: null,
                onResolve: response[key]?.Settings?.onResolve === 'true' || response[key]?.Settings?.onResolve === true
            };
            channel.settings.repeat = response[key]?.Settings?.repeat;
            converted.push(channel);
        });
        return converted;
    }
    static sortByType(channels) {
        let allTypes = Object.values(NotificationChannelType);
        return channels.sort(function (a, b) {
            let aIndex = allTypes.indexOf(a.type);
            let bIndex = allTypes.indexOf(b.type);
            return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
        });
    }
    formatToString(hasDelayAndRepeat = true) {
        let finalString = '';
        let arrayOfSubstrings = [];
        if (hasDelayAndRepeat == false) {
            finalString += '';
        }
        else if (this.settings?.delay == null)
            arrayOfSubstrings.push('-');
        else if (this.settings?.delay / 60 < 120)
            arrayOfSubstrings.push(`${(this.settings?.delay / 60).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            })} min`);
        else
            arrayOfSubstrings.push(`${(this.settings?.delay / 60 / 60).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            })}h`);
        if (hasDelayAndRepeat != false) {
            if (this.settings?.repeat != null) {
                if (typeof this.settings.repeat == 'number') {
                    if (this.settings.repeat <= 0) {
                        arrayOfSubstrings.push(`${i18n.t('component.notifications_list.no_repeat')}`);
                    }
                    else if (this.settings.repeat / 60 < 120) {
                        arrayOfSubstrings.push(`${i18n.t('component.notifications_list.repeat')} (${(this.settings?.repeat / 60).toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                        })} min)`);
                    }
                    else {
                        arrayOfSubstrings.push(`${i18n.t('component.notifications_list.repeat')} (${(this.settings?.repeat / 60 / 60).toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                        })}h)`);
                    }
                }
                else
                    arrayOfSubstrings.push(`${i18n.t('component.notifications_list.repeat')}`);
            }
            else
                arrayOfSubstrings.push(`${i18n.t('component.notifications_list.no_repeat')}`);
        }
        if (hasDelayAndRepeat != false) {
            if (this.settings?.onResolve) {
                arrayOfSubstrings.push(`${i18n.t('generals.yes')}`);
            }
            else {
                arrayOfSubstrings.push(`${i18n.t('generals.no')}`);
            }
        }
        if (arrayOfSubstrings.length > 0) {
            return `(${arrayOfSubstrings.join(' | ')})`;
        }
        else {
            return '';
        }
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import VueUtilities from '@/services/VueUtilities';
var reportRecordRepository;
let EditAnnotationModal = class EditAnnotationModal extends Vue {
    constructor() {
        super(...arguments);
        this.annotationText = null;
    }
    mounted() {
        reportRecordRepository = new ReportRecordRepository(this);
        this.annotationText = this.annotation.text;
    }
    async save() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let annotationDto = {
                    SourceId: this.annotation.sourceId,
                    Text: this.annotationText,
                    StartDate: this.annotation.startDate,
                    EndDate: this.annotation.endDate
                };
                let annotation = await reportRecordRepository.editAnnotation(annotationDto, this.annotation.id);
                VueUtilities.openSuccessToast(this, this.$t('component.report.edit_annotation.success').toString());
                this.resultRetrieved(annotation);
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], EditAnnotationModal.prototype, "annotation", void 0);
__decorate([
    Prop({ type: String })
], EditAnnotationModal.prototype, "timeRange", void 0);
EditAnnotationModal = __decorate([
    Component({})
], EditAnnotationModal);
export default EditAnnotationModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DecimalConversion from '@/services/DecimalConversion';
import MeasurementStatuses from '@/entities/enums/measurementStatuses';
import EventType from '@/entities/EventType';
import { ServiceState } from '@/entities/enums/ServiceState';
const UPTIME_ID = EventType.UPTIME_ID;
let EventDetail = class EventDetail extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.MeasurementStatuses = MeasurementStatuses;
        this.ServiceState = ServiceState;
        this.UPTIME_ID = UPTIME_ID;
    }
    getStatusClass(events) {
        return MeasurementStatuses.findMaxStatus(events.map((event) => event.status)).class;
    }
    scrollToChartFilter() {
        this.$emit('scrollToChartFilter');
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], EventDetail.prototype, "events", void 0);
__decorate([
    Prop({ type: Object })
], EventDetail.prototype, "source", void 0);
__decorate([
    Prop({ type: Object })
], EventDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], EventDetail.prototype, "onPublicPage", void 0);
EventDetail = __decorate([
    Component({})
], EventDetail);
export default EventDetail;

import NotificationChannelType from './NotificationChannelType';
import NotificationSubscriptionCategory from './NotificationSubscriptionCategory';
import NotificationSubscriptionType from './NotificationSubscriptionType';
export class NotificationType {
    constructor() {
        this.channel = [];
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => NotificationType.define(et));
        }
        else if (data != null) {
            return NotificationType.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let notificationType = new NotificationType();
        notificationType.name = data.name;
        notificationType.type = data.type;
        notificationType.category =
            data.name == NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                data.name == NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM
                ? NotificationSubscriptionCategory.MEASUREMENT_RULE
                : NotificationSubscriptionCategory.SYSTEM_RULE;
        notificationType.additionalProperties = {
            isEditing: false
        };
        if (data.channel && Object.keys(data.channel).length > 0) {
            const keys = NotificationType.sortChannels(Object.keys(data.channel));
            keys.forEach((key) => {
                let channel = {
                    type: key,
                    onCreate: data.channel[key].includes('Created'),
                    onResolve: data.channel[key].includes('Resolved')
                };
                notificationType.channel.push(channel);
            });
        }
        return notificationType;
    }
    /**
     * Sorts channels for frontend
     * @param listOfChannels - List of channel names
     * @returns sorted list of channel names
     */
    static sortChannels(listOfChannels) {
        let indexes = {
            [NotificationChannelType.SYSTEM]: 1,
            [NotificationChannelType.EMAIL]: 2,
            [NotificationChannelType.FCM]: 3,
            [NotificationChannelType.SMS]: 4,
            [NotificationChannelType.CALL]: 5,
            [NotificationChannelType.BUZZER]: 6
        };
        return listOfChannels.sort(function (a, b) {
            let aIndex = indexes[a] || 6;
            let bIndex = indexes[b] || 6;
            return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
        });
    }
    static getOnlyTypesForFe(types) {
        return types.filter((x) => ![NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM].includes(x.name) == true);
    }
}

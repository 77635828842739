import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DecimalConversion from '@/services/DecimalConversion';
let BoundaryDetailDesktop = class BoundaryDetailDesktop extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
    }
};
__decorate([
    Prop({ type: Object })
], BoundaryDetailDesktop.prototype, "event", void 0);
__decorate([
    Prop({ type: Object })
], BoundaryDetailDesktop.prototype, "boundary", void 0);
BoundaryDetailDesktop = __decorate([
    Component({})
], BoundaryDetailDesktop);
export default BoundaryDetailDesktop;

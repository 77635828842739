import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
const CALIBRATION_INTERVAL_IN_YEARS = 3;
const locales = ['sk', 'cz', 'en'];
let CreateCalibrationModal = class CreateCalibrationModal extends Vue {
    constructor() {
        super(...arguments);
        this.locales = locales;
        this.calibratedAt = new Date();
        this.expireAt = null;
        this.verifiedAt = new Date();
        this.uploadedFiles = null;
    }
    mounted() {
        let date = new Date();
        date.setFullYear(date.getFullYear() + CALIBRATION_INTERVAL_IN_YEARS);
        this.expireAt = date;
    }
    changeDate() {
        let date = new Date(this.verifiedAt);
        date.setFullYear(this.verifiedAt.getFullYear() + CALIBRATION_INTERVAL_IN_YEARS);
        this.expireAt = date;
    }
    deleteDropFile(name) {
        this.uploadedFiles = this.uploadedFiles.filter((x) => x.name != name);
    }
    filesUploaded() {
        this.uploadedFiles.forEach((file, index) => {
            file.locale = locales[index] || null;
        });
    }
    save() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.calibratedAt.getTime() > this.expireAt.getTime()) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.calibration.create_calibration.msg.date_compare_calibration').toString());
                    return;
                }
                else if (this.verifiedAt.getTime() > this.expireAt.getTime()) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.calibration.create_calibration.msg.date_compare_verification').toString());
                    return;
                }
                else if (this.calibratedAt.getTime() > this.verifiedAt.getTime()) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.calibration.create_calibration.msg.date_compare_verification_calibration').toString());
                    return;
                }
                else {
                    let calibDateUTC = Date.UTC(this.calibratedAt.getFullYear(), this.calibratedAt.getMonth(), this.calibratedAt.getDate(), 0, 0, 0);
                    let verifyDateUTC = Date.UTC(this.verifiedAt.getFullYear(), this.verifiedAt.getMonth(), this.verifiedAt.getDate(), 0, 0, 0);
                    let expireDateUTC = Date.UTC(this.expireAt.getFullYear(), this.expireAt.getMonth(), this.expireAt.getDate(), 23, 59, 59);
                    let files = [];
                    this.uploadedFiles.forEach((file) => {
                        files.push({ Protocol: file, Locale: file.locale });
                    });
                    let calibrationDTO = {
                        CalibratedAt: calibDateUTC,
                        VerifiedAt: verifyDateUTC,
                        ExpiresAt: expireDateUTC,
                        Files: files
                    };
                    this.$emit('saveCalibration', calibrationDTO);
                }
            }
        });
    }
    close() {
        this.$emit('modalClosed');
    }
};
__decorate([
    Prop({ type: Object })
], CreateCalibrationModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], CreateCalibrationModal.prototype, "logger", void 0);
CreateCalibrationModal = __decorate([
    Component({ components: {} })
], CreateCalibrationModal);
export default CreateCalibrationModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ReportRecord } from '@/entities/models/ReportRecord';
import UnitNames from '@/entities/enums/UnitNames';
import CreateAnnotationModal from '@/views/components/reports/CreateAnnotationModal.vue';
import DeleteAnnotationModal from '@/views/components/reports/DeleteAnnotationModal.vue';
import EditAnnotationModal from '@/views/components/reports/EditAnnotationModal.vue';
import AnnotationStatistics from '@/views/components/reports/AnnotationStatistics.vue';
import UserPreferences from '@/services/UserPreferences';
import StatisticsType from '@/entities/enums/statisticsType';
import ApiQueriesKeys from '@/entities/enums/ApiQueriesKeys';
let RecordsTable = class RecordsTable extends Vue {
    constructor() {
        super(...arguments);
        this.UnitNames = UnitNames;
        this.ReportRecord = ReportRecord;
        this.StatisticsType = StatisticsType;
        this.pageSize = 31;
        this.page = 1;
        this.isCreateModalActive = false;
        this.isEditModalActive = false;
        this.isDeleteModalActive = false;
        this.selectedRecordToAnnotate = null;
        this.selectedRecordToAnnotateTime = null;
        this.arrayForTable = [];
        this.detailedIds = [];
        this.hasTemperature = false;
        this.hasHumidity = false;
        this.hasDewPoint = false;
        this.TableObjectType = TableObjectType;
        this.selectedTableObject = null;
        this.checkedTableObjectsArray = [];
        this.selectedReportRecords = [];
        this.isMenuExpanded = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SideMenuExpanded);
    }
    recordsChanged() {
        this.prepareTableData();
    }
    annotationsChanged() {
        this.prepareTableData();
    }
    created() {
        this.prepareTableData();
        this.readQueriesFromRoute();
    }
    readQueriesFromRoute() {
        if (!this.$route.query)
            return;
        let pageFromRoute = this.$route.query[ApiQueriesKeys.PAGE];
        let pageAsNumber = parseInt(pageFromRoute);
        if (pageAsNumber) {
            this.page = pageAsNumber;
        }
        else {
            this.page = Math.ceil(this.arrayForTable.length / this.pageSize);
        }
    }
    updateRouteQuery() {
        let queries = JSON.parse(JSON.stringify(this.$route.query));
        queries[ApiQueriesKeys.PAGE] = this.page;
        this.$router
            .replace({
            query: Object.assign({}, queries),
            params: this.$route.params
        })
            .catch((err) => { });
    }
    onPageChange(page) {
        this.page = page;
        this.updateRouteQuery();
    }
    processRecordAsPoint(record) {
        let time = 0;
        if (record.values.find((x) => x.key == StatisticsType.DAILY)) {
            time = record.values.find((x) => x.key == StatisticsType.DAILY && x.time).time * 1000;
        }
        else if (record.values.find((x) => x.key == StatisticsType.AVERAGE)) {
            time = record.datepoint * 1000;
        }
        else {
            time = record.datepoint * 1000;
        }
        let values = [];
        let statisticsType = '';
        if (this.hasTemperature) {
            let val = record.values.find((x) => x.eventType === UnitNames.TEMPERATURE.name);
            if (val) {
                let stringValue = `${this.$t(`services.${UnitNames.TEMPERATURE.name}`)}: ${val.value.Value.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                })} ${val.value.Unit} `;
                values.push(stringValue);
                statisticsType = `${this.$t(`component.report.statisticTypes.${val.key}`)}`;
            }
        }
        if (this.hasHumidity) {
            let val = record.values.find((x) => x.eventType === UnitNames.HUMIDITY.name);
            if (val) {
                let stringValue = `${this.$t(`services.${UnitNames.HUMIDITY.name}`)}: ${val.value.Value.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                })} ${val.value.Unit} `;
                values.push(stringValue);
                statisticsType = `${this.$t(`component.report.statisticTypes.${val.key}`)}`;
            }
        }
        if (this.hasDewPoint) {
            let val = record.values.find((x) => x.eventType === UnitNames.DEWPOINT.name);
            if (val) {
                let stringValue = `${this.$t(`services.${UnitNames.DEWPOINT.name}`)}: ${val.value.Value.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                })} ${val.value.Unit} `;
                values.push(stringValue);
                statisticsType = `${this.$t(`component.report.statisticTypes.${val.key}`)}`;
            }
        }
        let obj = {
            time: time,
            datepoint: record.datepoint,
            values: values,
            type: statisticsType,
            rawRecord: record
        };
        return obj;
    }
    processRecordAsAnotation(record) {
        let annotation = record.annotations[0];
        let obj = {
            startTime: annotation.startDate,
            endTime: annotation.endDate,
            createdAt: annotation.createdAt,
            timeString: null,
            value: annotation.text,
            annotationId: annotation.id,
            author: this.annotationsForSource.find((x) => x.id == annotation.id)?.createdBy,
            statistics: annotation.statistics,
            points: [],
            rawAnnotation: record.annotations[0]
        };
        return obj;
    }
    assignTimestringToAnnotation(records) {
        const first = Math.min.apply(Math, records.map((o) => o.time));
        const last = Math.max.apply(Math, records.map((o) => o.time));
        const firstDate = new Date(first);
        const lastDate = new Date(last);
        const inSameDay = this.dateTimeManager.areSameDate(firstDate, lastDate);
        let timeString = '';
        if (first === last) {
            timeString = `${this.dateTimeManager.formatTime(new Date(first), null, null, null)}`;
        }
        else {
            timeString = `${this.dateTimeManager.formatTime(new Date(first), null, null, null)} - ${this.dateTimeManager.formatTime(new Date(last), null, inSameDay === false ? null : '', null)}`;
        }
        return timeString;
    }
    prepareTableData() {
        this.arrayForTable = [];
        this.selectedTableObject = null;
        this.selectedReportRecords = [];
        this.selectedReportRecords = [];
        this.hasTemperature = this.services.includes(UnitNames.TEMPERATURE.name);
        this.hasHumidity = this.services.includes(UnitNames.HUMIDITY.name);
        this.hasDewPoint = this.services.includes(UnitNames.DEWPOINT.name);
        let nowTs = new Date().getTime();
        this.records.forEach((record, index) => {
            let processedPoint = this.processRecordAsPoint(record);
            if (processedPoint.time > nowTs)
                return;
            if (record.annotations.length > 0) {
                let existingAnnotationObj = this.arrayForTable.find((x) => x.entity.annotationId === record.annotations[0].id);
                if (existingAnnotationObj) {
                    existingAnnotationObj.entity.points.push(processedPoint);
                    existingAnnotationObj.entity.timeString = this.assignTimestringToAnnotation(existingAnnotationObj.entity.points);
                }
                else {
                    let annotationObj = this.processRecordAsAnotation(record);
                    annotationObj.points.push(processedPoint);
                    annotationObj.timeString = this.assignTimestringToAnnotation(annotationObj.points);
                    this.detailedIds.push(index);
                    this.arrayForTable.push({
                        recordType: TableObjectType.ANNOTATION,
                        entity: annotationObj,
                        id: index
                    });
                }
            }
            else {
                this.arrayForTable.push({
                    recordType: TableObjectType.RECORD,
                    entity: processedPoint,
                    id: index
                });
            }
        });
    }
    deleteAnnotation(obj) {
        this.isDeleteModalActive = true;
        this.selectedTableObject = obj;
    }
    editAnnotation(obj) {
        this.selectedTableObject = obj;
        this.isEditModalActive = true;
    }
    createAnnotation() {
        let ids = this.checkedTableObjectsArray
            .map((x) => x.id)
            .sort(function (a, b) {
            return a - b;
        });
        const isConsecutiveSelection = ids[ids.length - 1] - ids[0] === ids.length - 1;
        if (isConsecutiveSelection === true) {
            this.selectedReportRecords = this.checkedTableObjectsArray.map((x) => {
                return {
                    time: x.entity.time,
                    datepoint: x.entity.datepoint,
                    record: x.entity.rawRecord
                };
            });
            this.isCreateModalActive = true;
        }
        else {
            this.$buefy.dialog.confirm({
                title: this.$t('component.report.record_table.consecutive_alert.title'),
                message: this.$t('component.report.record_table.consecutive_alert.message'),
                confirmText: this.$t('component.report.record_table.consecutive_alert.confirm'),
                type: 'is-danger',
                hasIcon: true
            });
            return;
        }
    }
    closeCreateModal(data) {
        this.isCreateModalActive = false;
        if (data !== false) {
            this.selectedReportRecords = [];
            this.$emit('reloadRecords');
        }
    }
    closeEditModal(data) {
        this.isEditModalActive = false;
        if (data !== false) {
            this.selectedTableObject = null;
            this.$emit('reloadRecords');
        }
    }
    closeDeleteModal(data) {
        this.isDeleteModalActive = false;
        if (data !== false) {
            this.selectedTableObject = null;
            this.$emit('reloadRecords');
        }
    }
};
__decorate([
    Prop({ type: Array })
], RecordsTable.prototype, "records", void 0);
__decorate([
    Prop({ type: Object })
], RecordsTable.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], RecordsTable.prototype, "services", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], RecordsTable.prototype, "annotationsForSource", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], RecordsTable.prototype, "annotationAllowed", void 0);
__decorate([
    Watch('records')
], RecordsTable.prototype, "recordsChanged", null);
__decorate([
    Watch('annotationsForSource')
], RecordsTable.prototype, "annotationsChanged", null);
RecordsTable = __decorate([
    Component({
        components: {
            CreateAnnotationModal,
            EditAnnotationModal,
            DeleteAnnotationModal,
            AnnotationStatistics
        }
    })
], RecordsTable);
export default RecordsTable;
var TableObjectType;
(function (TableObjectType) {
    TableObjectType[TableObjectType["RECORD"] = 0] = "RECORD";
    TableObjectType[TableObjectType["ANNOTATION"] = 1] = "ANNOTATION";
})(TableObjectType || (TableObjectType = {}));

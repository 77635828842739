var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.scanningMethod !== "scanner"
        ? _c("div", { staticClass: "modal-card" }, [
            _c("header", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.company.detail.registerLoggers.registerLoggersTitle"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "section",
              { staticClass: "modal-card-body" },
              [
                _c(
                  "b-tabs",
                  {
                    staticClass: "custom-layout-tabs",
                    attrs: { type: "is-boxed" },
                    on: { input: _vm.tabChanged },
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _c(
                      "b-tab-item",
                      {
                        attrs: {
                          value: "qrScan",
                          label: _vm.$t(
                            "admin.component.company.detail.registerLoggers.qrScanner"
                          ),
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mb-3 mt-4",
                            attrs: { type: "is-primary is-fullwidth" },
                            on: {
                              click: function ($event) {
                                return _vm.setScanMethod("scanner")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.company.detail.registerLoggers.scanQR"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "has-text-centered" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.detail.registerLoggers.or"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-3 mb-5",
                            attrs: { type: "is-primary is-fullwidth" },
                            on: {
                              click: function ($event) {
                                return _vm.setScanMethod("fromFile")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.company.detail.registerLoggers.uploadQR"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("h4", { staticClass: "is-size-4 pb-4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.detail.registerLoggers.scannedLoggers"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "b-table",
                          {
                            attrs: {
                              data: _vm.tableData,
                              "mobile-cards": false,
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "sn",
                                label: _vm.$t("loggers.serial_number"),
                                width: "80%",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        props.row.registrationFailed
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "has-text-danger is-italic",
                                              },
                                              [_vm._v(_vm._s(props.row.sn))]
                                            )
                                          : props.row.cannotRegister
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "has-text-grey is-italic",
                                                staticStyle: {
                                                  "text-decoration":
                                                    "line-through",
                                                },
                                              },
                                              [_vm._v(_vm._s(props.row.sn))]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(props.row.sn)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3151397732
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: { label: _vm.$t("generals.action") },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        props.row.registrationFailed
                                          ? _c("b-icon", {
                                              staticClass:
                                                "clickable has-text-danger",
                                              attrs: { icon: "trash" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.removeFromList(
                                                    props.row.sn
                                                  )
                                                },
                                              },
                                            })
                                          : props.row.cannotRegister
                                          ? _c("b-icon", {
                                              staticClass:
                                                "clickable has-text-grey",
                                              attrs: { icon: "trash" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.removeFromList(
                                                    props.row.sn
                                                  )
                                                },
                                              },
                                            })
                                          : _c("b-icon", {
                                              staticClass: "clickable",
                                              attrs: { icon: "trash" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.removeFromList(
                                                    props.row.sn
                                                  )
                                                },
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2035936739
                              ),
                            }),
                            _c("template", { slot: "empty" }, [
                              _c("section", { staticClass: "section" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "content has-text-grey has-text-centered",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "admin.component.company.detail.registerLoggers.noLoggers"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab-item",
                      {
                        attrs: {
                          value: "fromList",
                          label: _vm.$t(
                            "admin.component.company.detail.registerLoggers.fromList"
                          ),
                        },
                      },
                      [
                        _c("AdminRegisterLoggersModal", {
                          ref: "adminRegisterLoggersModal",
                          attrs: {
                            companyId: _vm.companyId,
                            componentIsLoading: _vm.componentIsLoading,
                            adminLoggers: _vm.adminLoggers,
                            totalSize: _vm.totalSize,
                          },
                          on: {
                            loadAdminLoggers: _vm.loadAdminLoggers,
                            loadDevicesAndLoggers: _vm.loadDevicesAndLoggers,
                            closeModal: _vm.closeModal,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "footer",
              {
                staticClass: "modal-card-foot",
                staticStyle: { "justify-content": "space-between" },
              },
              [
                _c(
                  "b-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.close")))]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.register()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.add")))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.scanningMethod !== null
        ? _c("AdminRegisterLoggersQrParser", {
            attrs: {
              scanningMethod: _vm.scanningMethod,
              scannedLoggers: _vm.scannedLoggers,
            },
            on: {
              setScanMethod: _vm.setScanMethod,
              dataUpdate: _vm.dataUpdate,
              removeFromList: _vm.removeFromList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
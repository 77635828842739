import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CheckboxSelect = class CheckboxSelect extends Vue {
    constructor() {
        super(...arguments);
        this.localSelectedOptions = [];
    }
    created() {
        this.localSelectedOptions = [...this.selectedOptions];
    }
    getValue(path, obj) {
        let value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
        return value ? value : '-';
    }
    entitySelected() {
        this.$emit('entitySelected', this.localSelectedOptions);
    }
};
__decorate([
    Prop({ type: Array })
], CheckboxSelect.prototype, "options", void 0);
__decorate([
    Prop({ type: Array })
], CheckboxSelect.prototype, "selectedOptions", void 0);
__decorate([
    Prop({ type: String })
], CheckboxSelect.prototype, "path", void 0);
__decorate([
    Prop({ type: String })
], CheckboxSelect.prototype, "langPath", void 0);
CheckboxSelect = __decorate([
    Component
], CheckboxSelect);
export default CheckboxSelect;

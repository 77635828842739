var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title is-3 is-spaced has-text-centered-touch" }, [
      _vm._v(" " + _vm._s(_vm.$t("component.settings.about.title")) + " "),
    ]),
    _c("div", { staticClass: "tile is-ancestor" }, [
      _c("div", { staticClass: "tile is-vertical" }, [
        _vm.currentConfig.features.feedback === true
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [_c("ContactUsForm", { staticClass: "tile is-child box" })],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "tile is-parent" },
          [_c("ManualComponent", { staticClass: "tile is-child box" })],
          1
        ),
        _c(
          "div",
          { staticClass: "tile is-parent" },
          [_c("ReleaseNotesComponent", { staticClass: "tile is-child box" })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export class FloorPlan {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => FloorPlan.define(et));
        }
        else if (data != null) {
            return FloorPlan.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let floorPlan = new FloorPlan();
        floorPlan.entity = data.Entity;
        floorPlan.entityId = data.EntityId;
        floorPlan.coordinates = data.Coordinates;
        floorPlan.isDragging = data.IsDragging;
        return floorPlan;
    }
}

import { render, staticRenderFns } from "./ReportSettings.vue?vue&type=template&id=4351a754&"
import script from "./ReportSettings.vue?vue&type=script&lang=ts&"
export * from "./ReportSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4351a754')) {
      api.createRecord('4351a754', component.options)
    } else {
      api.reload('4351a754', component.options)
    }
    module.hot.accept("./ReportSettings.vue?vue&type=template&id=4351a754&", function () {
      api.rerender('4351a754', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/reports/ReportSettings.vue"
export default component.exports
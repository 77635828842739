export var UserSettingsKeys;
(function (UserSettingsKeys) {
    UserSettingsKeys["LANGUAGE"] = "language";
    UserSettingsKeys["DATETIME"] = "datetime";
    UserSettingsKeys["FORMAT"] = "format";
    UserSettingsKeys["DATEFORMAT"] = "dateFormat";
    UserSettingsKeys["TIMEZONE"] = "timezone";
    UserSettingsKeys["CLOCKFORMAT"] = "clockFormat";
    UserSettingsKeys["TZ_IN_FORMAT"] = "tzInFormat";
    UserSettingsKeys["EVENTS"] = "events";
    UserSettingsKeys["UNITS"] = "units";
    UserSettingsKeys["NOTIFICATIONS"] = "notifications";
    UserSettingsKeys["RULES"] = "rules";
    UserSettingsKeys["MODE"] = "mode";
    UserSettingsKeys["PAUSE"] = "pause";
    UserSettingsKeys["UNTIL"] = "until";
    UserSettingsKeys["DISMISSED"] = "msgDismissedAt";
    UserSettingsKeys["PREFERENCES"] = "preferences";
    UserSettingsKeys["FAVORITE_GROUP_ID"] = "favoriteGroupId";
    UserSettingsKeys["FAVORITE_DEPARTMENT_ID"] = "favoriteDepartmentId";
    UserSettingsKeys["MEASUREMENT_SORT"] = "measurementSort";
    UserSettingsKeys["EVENTS_REFRESH"] = "eventsRefresh";
    UserSettingsKeys["ENABLED"] = "enabled";
    UserSettingsKeys["INTERVAL"] = "interval";
    UserSettingsKeys["REPORTS"] = "reports";
    UserSettingsKeys["RECORDS"] = "records";
    UserSettingsKeys["CONFIGURATION"] = "configuration";
    UserSettingsKeys["FILTER_BY_BOUNDARY"] = "filterByBoundary";
    UserSettingsKeys["PROFILE"] = "profile";
    UserSettingsKeys["RECEIVE_REPORTS"] = "receiveByEmail";
    UserSettingsKeys["CUSTOM"] = "customSettings";
    UserSettingsKeys["CHARTHEIGHT"] = "chartHeight";
    UserSettingsKeys["SCHEDULE_MODE"] = "scheduleMode";
})(UserSettingsKeys || (UserSettingsKeys = {}));

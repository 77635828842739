import User from '@/entities/User';
import { DeviceConfiguration } from '@/entities/models/DeviceConfiguration';
import { Company } from '@/entities/models/Company';
import { DeviceFirmwareInfo } from './Firmware';
export default class AdminDevice {
    constructor() {
        this.additionalProperties = {
            isOnline: 'false'
        };
        this.registeredBy = new User();
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminDevice.define(et));
        }
        else if (data != null) {
            return AdminDevice.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let device = new AdminDevice();
        device.deviceGroup = data.DeviceGroup;
        device.deviceId = data.DeviceId;
        device.deviceName = data.Name;
        device.registeredAt = data.RegisteredAt;
        device.deviceHardwareKey = data.HardwareKey;
        device.deviceMacAddress = data.MacAddress;
        device.isOnline = data.IsOnline;
        device.lastSeen = data.LastSeen;
        device.description = data.Description;
        device.registrationKey = data.RegistrationKey;
        device.type = data.Type;
        device.state = data.State;
        device.instanceState = data.InstanceState;
        device.enabled = data.Enabled;
        if (data.Company)
            device.company = Company.fromApi(data.Company);
        if (data.RegisteredBy)
            device.registeredBy = User.fromApi(data.RegisteredBy);
        if (data.CurrentConfig)
            device.currentConfig = DeviceConfiguration.fromApi(data.CurrentConfig);
        if (data.Configs)
            device.configs = DeviceConfiguration.fromApi(data.Configs);
        if (data.Firmware)
            device.firmware = DeviceFirmwareInfo.fromApi(data.Firmware);
        device.additionalProperties.isOnline = data.IsOnline.toString();
        return device;
    }
    static sortByName(sources) {
        return sources.sort(function (a, b) {
            let aId = a ? a.deviceName.toLowerCase() : '';
            let bId = b ? b.deviceName.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
}

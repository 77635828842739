import SessionStorage from './SessionStorage';
import AxiosService from '@/services/api/AxiosService';
import { UserSettings } from '@/entities/UserSettings';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { DepartmentUserSettings } from '@/entities/DepartmentUserSettings';
import { vxm } from '@/store/store.vuex';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
var vxDepartmentStore = vxm.departmentStore;
const MAX_SETTINGS_AGE = 60000 * 60;
/**
 * Singleton repository
 */
export default class UserSettingsRepository {
    constructor(ctx) {
        this.LS_TAG = SessionStorage.keys.SETTINGS;
        this.LS_DEPARTMENT_TAG = SessionStorage.keys.USER_DEPARTMENT_SETTINGS;
        this._responsePromise = null;
        this.ctx = ctx;
    }
    static getInstance(ctx, reload = false) {
        if (!this._instance || reload) {
            this._instance = new this(ctx);
        }
        return this._instance;
    }
    async loadUserSettings(reload = false) {
        let cachedData = SessionStorage.loadData(this.LS_TAG);
        if (cachedData?.lastUpdated + MAX_SETTINGS_AGE < Date.now())
            reload = true;
        if (!reload && cachedData?.data)
            return UserSettings.fromApi(cachedData.data);
        if (!this._responsePromise) {
            let url = ApiLinks.Profile.Settings;
            this._responsePromise = AxiosService.getDataFromServer(url, this.ctx);
        }
        let response = await this._responsePromise;
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let settings = UserSettings.fromApi(response.data.data.profile);
            SessionStorage.storeData(this.LS_TAG, settings);
            this._responsePromise = null;
            return settings;
        }
    }
    async saveUserSetting(key, value) {
        let path = key.join('.');
        let userSetting = [{ Key: path, Value: value }];
        let url = ApiLinks.Profile.Settings;
        let response = await AxiosService.postDataToServer(url, userSetting, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let settings = UserSettings.fromApi(response.data.data.profile);
            SessionStorage.storeData(this.LS_TAG, settings);
            return settings;
        }
    }
    async loadUserSettingsForDepartment(userId, reload = false, departmentId = this.getSelectedDepartmentId()) {
        let cachedData = SessionStorage.loadData(this.LS_DEPARTMENT_TAG);
        if (cachedData?.lastUpdated + MAX_SETTINGS_AGE < Date.now())
            reload = true;
        if (!reload && cachedData?.data)
            return DepartmentUserSettings.fromApi(cachedData.data);
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}${ApiLinks.Users.Keys.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let settings = DepartmentUserSettings.fromApi(response.data.data[departmentId]);
            SessionStorage.storeData(this.LS_DEPARTMENT_TAG, settings);
            return settings;
        }
    }
    async saveUserSettingForDepartment(userId, key, value, departmentId = this.getSelectedDepartmentId()) {
        let path = key.join('.');
        let userSetting = [{ Key: path, Value: value }];
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}${ApiLinks.Users.Keys.Settings}`;
        let response = await AxiosService.postDataToServer(url, userSetting, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let settings = DepartmentUserSettings.fromApi(response.data.data[departmentId]);
            SessionStorage.storeData(this.LS_DEPARTMENT_TAG, settings);
            return settings;
        }
    }
    async saveUserSettingsForDepartment(userId, changes, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}${ApiLinks.Users.Keys.Settings}`;
        let response = await AxiosService.postDataToServer(url, changes, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else
            return response;
    }
    async adminSaveUserSettings(userId, changes) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_USERS_SETTINGS)) {
            throw 'Forbidden action adminSaveUserSettings';
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Settings}`;
        let response = await AxiosService.postDataToServer(url, changes, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else
            return response;
    }
    async removeUserSetting(userId, key, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}${ApiLinks.Users.Keys.Settings}/${key}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.NO_CONTENT)
            return false;
        else
            return true;
    }
    async adminRemoveUserSetting(userId, key) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_USERS_SETTINGS)) {
            throw 'Forbidden action adminRemoveUserSetting';
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Settings}/${key}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.NO_CONTENT)
            return false;
        else
            return true;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminDeviceDetail from '@/views/components/admin/devices/AdminDeviceDetail.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import AdminDevice from '@/entities/models/AdminDevice';
import VueUtilities from '@/services/VueUtilities';
import ApiResponse from '@/entities/ApiResponse';
import FirmwareRepository from '@/services/repository/FirmwareRepository';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var deviceRepository;
var firmwareRepository;
let AdminDeviceDetailManagement = class AdminDeviceDetailManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.device = new AdminDevice();
        this.deviceFirmwareList = new ApiResponse();
        this.deviceInstances = [];
        this.deviceCredentials = [];
        this.dateTimeManager = null;
        this.currentCredentials = null;
        this.historyCredentials = [];
        this.deviceUpdates = [];
        this.commands = new ApiResponse();
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        deviceRepository = new DeviceRepository(this);
        firmwareRepository = new FirmwareRepository(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(deviceRepository.getAdminDevice(this.$route.params.deviceId));
        allPromises.push(firmwareRepository.getFirmwaresList());
        Promise.all(allPromises).then((response) => {
            this.processLoadedData(response[0], response[1]);
        });
    }
    processLoadedData(device, deviceFirmwareList) {
        this.device = device;
        this.deviceFirmwareList = deviceFirmwareList;
        this.isLoading = false;
    }
    async loadAll() {
        this.loadCredentials();
        this.loadCommandsAndUpdates();
        this.loadHistory();
    }
    async loadCredentials() {
        this.deviceCredentials = await deviceRepository.getAdminDeviceCredentials(this.$route.params.deviceId);
        this.prepareCredentials();
    }
    async loadCommandsAndUpdates() {
        this.commands = await deviceRepository.getAdminCommand(this.$route.params.deviceId);
        this.deviceUpdates = await deviceRepository.getAdminDeviceUpdates(this.$route.params.deviceId);
    }
    async loadHistory() {
        this.deviceInstances = await deviceRepository.getAdminDeviceInstances(this.$route.params.deviceId);
    }
    prepareCredentials() {
        let acurrentCredentials;
        let ahistoryCredentials = [];
        this.deviceCredentials.forEach(function (value) {
            if (value.revokedAt == null) {
                acurrentCredentials = value;
            }
            else {
                ahistoryCredentials.push(value);
            }
        });
        this.currentCredentials = acurrentCredentials;
        this.historyCredentials = ahistoryCredentials;
    }
    async revertDevice() {
        this.isLoading = true;
        this.device = await deviceRepository.adminRevertDevice(this.device.deviceId);
        this.isLoading = false;
    }
    async disableCommand(disableCommandId) {
        this.isLoading = true;
        let result = await deviceRepository.disableCommand(this.$route.params.deviceId, disableCommandId);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.detail.deviceCommands.disable.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.devices.detail.deviceCommands.disable.error'));
        }
        this.loadData();
        this.isLoading = true;
    }
    async revokeCredential() {
        this.isLoading = true;
        let result = await deviceRepository.adminRevokeCredential(this.device.deviceId, this.currentCredentials.credentialsId);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.revokeModel.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.devices.revokeModel.failure'));
        }
        this.isLoading = false;
    }
    newCredential() {
        this.loadData();
    }
    loadNewCredential() {
        this.loadData();
    }
    async returnDevice() {
        this.isLoading = true;
        this.device = await deviceRepository.adminReturnDevice(this.device.deviceId);
        this.loadData();
    }
};
AdminDeviceDetailManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_COMMANDS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_UPDATES)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem(LocalStorageKeys.ADMIN_PREV_ROUTE, JSON.stringify({
                name: this.$route.name,
                params: this.$route.params,
                meta: { name: this.device.deviceName }
            }));
            next();
        },
        components: { AdminDeviceDetail }
    })
], AdminDeviceDetailManagement);
export default AdminDeviceDetailManagement;

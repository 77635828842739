import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AuthenticationCalls from '@/services/AuthenticationCalls';
var authenticationCalls;
let VerifyAccount = class VerifyAccount extends Vue {
    constructor() {
        super(...arguments);
        this.verified = false;
        this.isLoading = true;
    }
    created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        let queryData = this.$route.query;
        if (queryData.hasOwnProperty('token')) {
            this.verify(queryData.token);
        }
        else {
            this.isLoading = false;
        }
    }
    async verify(token) {
        let success = await authenticationCalls.verifyAccount(token);
        if (success === true) {
            this.verified = true;
            this.isLoading = false;
        }
        else {
            this.verified = false;
            this.isLoading = false;
        }
    }
};
VerifyAccount = __decorate([
    Component({})
], VerifyAccount);
export default VerifyAccount;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      [
        _c("div", { staticClass: "columns columns-info-form" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                { attrs: { horizontal: "", label: _vm.$t("tiers.name") } },
                [_c("span", [_vm._v(_vm._s(_vm.activeTier.name))])]
              ),
              _c(
                "b-field",
                { attrs: { horizontal: "", label: _vm.$t("tiers.id") } },
                [_c("span", [_vm._v(_vm._s(_vm.activeTier.id))])]
              ),
              _c(
                "b-field",
                { attrs: { horizontal: "", label: _vm.$t("tiers.tier_id") } },
                [_c("span", [_vm._v(_vm._s(_vm.activeTier.tierId))])]
              ),
              _c(
                "b-field",
                { attrs: { horizontal: "", label: _vm.$t("tiers.active") } },
                [
                  _c("span", [
                    _vm.activeTier.active
                      ? _c("i", { staticClass: "fas fa-check" })
                      : _c("i", { staticClass: "fas fa-times" }),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: { horizontal: "", label: _vm.$t("tiers.created_at") },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(_vm.activeTier.createdAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.activated_at"),
                  },
                },
                [
                  _vm.activeTier.activatedAt
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.activeTier.activatedAt * 1000),
                              null,
                              null,
                              null
                            )
                          )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.deactivated_at"),
                  },
                },
                [
                  _vm.activeTier.deactivatedAt
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.activeTier.deactivatedAt * 1000),
                              null,
                              null,
                              null
                            )
                          )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "column" }, [
            _c("p", { staticClass: "has-text-weight-bold is-size-5" }, [
              _vm._v(" " + _vm._s(_vm.$t("tiers.features_list")) + " "),
            ]),
            _c(
              "div",
              { staticClass: "mt-1" },
              _vm._l(_vm.activeTier.features, function (feature, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-1",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "pr-2",
                        class: {
                          "has-text-primary has-text-weight-bold":
                            _vm.activeTier.enabledFeatures.find(function (x) {
                              return x.Name === feature.Name
                            }) != null,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("tiers.features." + feature.Name)))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-flex-direction-row is-align-items-center",
                      },
                      [
                        feature.Type === _vm.FeaturesTypes.BOOL
                          ? _c(
                              "b-field",
                              [
                                _c(
                                  "b-switch",
                                  {
                                    model: {
                                      value: feature.Value.Allowed,
                                      callback: function ($$v) {
                                        _vm.$set(feature.Value, "Allowed", $$v)
                                      },
                                      expression: "feature.Value.Allowed",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("tiers.features.allowed")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        feature.Type === _vm.FeaturesTypes.QUOTA
                          ? _c(
                              "b-field",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  "label-position": "on-border",
                                  label: _vm.$t("tiers.features.quota"),
                                },
                              },
                              [
                                _c("b-numberinput", {
                                  attrs: { controls: false },
                                  model: {
                                    value: feature.Value.Quota,
                                    callback: function ($$v) {
                                      _vm.$set(feature.Value, "Quota", $$v)
                                    },
                                    expression: "feature.Value.Quota",
                                  },
                                }),
                                _c(
                                  "p",
                                  { staticClass: "control" },
                                  [
                                    _c("b-button", {
                                      attrs: {
                                        type: "is-primary",
                                        label: _vm.$t(
                                          "tiers.features.unlimited"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setUnlimited(feature)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        feature.Type === _vm.FeaturesTypes.MONTHLY
                          ? _c(
                              "b-field",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  "label-position": "on-border",
                                  label: _vm.$t("tiers.features.quota"),
                                },
                              },
                              [
                                _c("b-numberinput", {
                                  attrs: { controls: false },
                                  model: {
                                    value: feature.Value.Quota,
                                    callback: function ($$v) {
                                      _vm.$set(feature.Value, "Quota", $$v)
                                    },
                                    expression: "feature.Value.Quota",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        feature.Type === _vm.FeaturesTypes.MONTHLY
                          ? _c(
                              "b-field",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-switch",
                                  {
                                    model: {
                                      value: feature.Value.AllowOverflow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          feature.Value,
                                          "AllowOverflow",
                                          $$v
                                        )
                                      },
                                      expression: "feature.Value.AllowOverflow",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("tiers.features.allowOverflow")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary", expanded: "" },
            on: {
              click: function ($event) {
                return _vm.updateTier()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("tiers.update_tier")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "title-with-edit is-flex is-align-content-center my-4" },
      [
        _c("h3", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.notification.other_settings.title")) +
              " "
          ),
        ]),
      ]
    ),
    _c("section", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-two-thirds columns-info-form" },
        [
          _vm.departmentSettings
            ? _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    type: {
                      "is-danger": _vm.offlineNotification,
                    },
                    message:
                      _vm.offlineNotification && _vm.isDepartmentSettingsEditing
                        ? _vm.$t("loggers.stop_notifications_alert")
                        : "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("loggers.stop_notifications")) +
                                " "
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  type: "is-dark",
                                  label: _vm.$t(
                                    "loggers.stop_notifications_note"
                                  ),
                                  position: "is-left",
                                  multilined: "",
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    size: "is-small",
                                    icon: "question-circle",
                                    type: "is-grey",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1285302072
                  ),
                },
                [
                  !_vm.isDepartmentSettingsEditing ||
                  !_vm.$ability.can(
                    _vm.$permActions.MANAGEOWN,
                    _vm.$permSubjects.COMPANY
                  )
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-flex-direction-row is-align-items-center",
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.offlineNotification
                                    ? _vm.$t("generals.yes")
                                    : _vm.$t("generals.no")
                                ) +
                                " "
                            ),
                          ]),
                          _vm.$ability.can(
                            _vm.$permActions.MANAGEOWN,
                            _vm.$permSubjects.COMPANY
                          )
                            ? _c("b-icon", {
                                staticClass: "control-icon clickable",
                                attrs: { icon: "pencil-alt" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.editDepartmentSettings()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-flex-direction-row is-align-items-center",
                        },
                        [
                          _c(
                            "b-switch",
                            {
                              staticClass: "mr-5",
                              attrs: { type: "is-primary" },
                              model: {
                                value: _vm.offlineNotification,
                                callback: function ($$v) {
                                  _vm.offlineNotification = $$v
                                },
                                expression: "offlineNotification",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.offlineNotification
                                      ? _vm.$t("generals.yes")
                                      : _vm.$t("generals.no")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                size: "is-small",
                                disabled: !_vm.hasChangesDepartmentSettings,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.save()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("generals.save")) + " "
                              ),
                            ]
                          ),
                          _vm.$ability.can(
                            _vm.$permActions.MANAGEOWN,
                            _vm.$permSubjects.COMPANY
                          )
                            ? _c(
                                "b-icon",
                                {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "times-circle" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.editDepartmentSettings()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("generals.cancel_edit")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import Vuex from 'vuex';
import { createProxy, extractVuexModule } from 'vuex-class-component';
import { AbortControllerStore } from '@/store/modules/AbortControllerStore.vuex';
import { DepartmentStore } from '@/store/modules/DepartmentStore.vuex';
import { NotificationStore } from '@/store/modules/NotificationStore.vuex';
/**
 * Class-based vuex store management
 * docs: https://github.com/michaelolof/vuex-class-component
 */
Vue.use(Vuex);
export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule(AbortControllerStore),
        ...extractVuexModule(DepartmentStore),
        ...extractVuexModule(NotificationStore)
    }
});
export const vxm = {
    abortController: createProxy(store, AbortControllerStore),
    departmentStore: createProxy(store, DepartmentStore),
    notificationStore: createProxy(store, NotificationStore)
};

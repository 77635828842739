import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import PauseNotificationsInterval from '@/entities/enums/PauseNotificationsInterval';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import UserRepository from '@/services/repository/UserRepository';
var userSettingsRepository;
var userRepository;
var notificationSubscriptionRepository;
const SEC_IN_MINUTE = 60;
let PauseNotificationsMessage = class PauseNotificationsMessage extends Vue {
    constructor() {
        super(...arguments);
        this.PauseNotificationsInterval = PauseNotificationsInterval;
        this.currentUser = null;
    }
    created() {
        notificationSubscriptionRepository = new NotificationSubscriptionRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        userRepository = new UserRepository(this);
    }
    dismiss() {
        userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [(UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.PAUSE, UserSettingsKeys.DISMISSED)], Math.floor(new Date().getTime() / 1000));
    }
    async pauseNotifications(interval) {
        let now = Math.floor(new Date().getTime() / 1000);
        let until = now + interval * SEC_IN_MINUTE;
        this.currentUser = await userRepository.getCurrentUser();
        let pauseDto = {
            PauseForMinutes: interval,
            PauseByType: 'measuredDataAlarm'
        };
        let result = await notificationSubscriptionRepository.pauseNotification(pauseDto, this.currentUser.apiUserId);
        if (result == true) {
            await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.PAUSE, UserSettingsKeys.UNTIL], until);
            this.$emit('reloadPause');
        }
    }
};
PauseNotificationsMessage = __decorate([
    Component({ components: {} })
], PauseNotificationsMessage);
export default PauseNotificationsMessage;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.loggers.selectCompanyModal.title")
              ) +
              " (" +
              _vm._s(
                _vm.logger.currentlyPublishedAs.serialNumber &&
                  _vm.logger.currentlyPublishedAs
                  ? _vm.logger.currentlyPublishedAs.serialNumber
                  : _vm.logger.macAddress
              ) +
              ") "
          ),
        ]),
      ]),
      _c(
        "section",
        {
          staticClass: "modal-card-body",
          style: _vm.company !== null ? "" : "min-height: 30vh",
        },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.loggers.selectCompanyModal.company"
                ),
              },
            },
            [
              _c("b-autocomplete", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                ref: "companyName",
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.loggers.selectCompanyModal.companyPlaceholder"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.detail.deviceInfo.register.company"
                  ),
                  data: _vm.filteredCompanyList,
                  field: "companyName",
                  "open-on-focus": true,
                  clearable: "",
                },
                on: {
                  select: function (option) {
                    return (_vm.company = option)
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.select()
                  },
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary", disabled: _vm.company == null },
              on: {
                click: function ($event) {
                  return _vm.select()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("admin.component.loggers.publishModal.commit_btn")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
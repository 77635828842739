import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import AdminRegisterLoggersQrParser from '@/views/components/admin/loggers/AdminRegisterLoggersQrParser.vue';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import AdminRegisterLoggersModal from '../company/AdminRegisterLoggersModal.vue';
import VueUtilities from '@/services/VueUtilities';
var companyLoggerRepository;
let AdminRegisterLoggersToDepartmentModal = class AdminRegisterLoggersToDepartmentModal extends Vue {
    constructor() {
        super(...arguments);
        this.scanningMethod = null;
        this.scannedLoggers = [];
        this.availableLoggers = [];
        this.choosedLoggers = [];
        this.cannotRegister = [];
        this.failedRegistration = [];
        this.activeTab = 'qrScan';
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
    }
    get tableData() {
        return this.scannedLoggers.map((logger) => {
            if (this.cannotRegister.includes(logger.sn)) {
                return {
                    ...logger,
                    cannotRegister: true,
                    registrationFailed: false
                };
            }
            else if (this.failedRegistration.includes(logger.sn)) {
                return {
                    ...logger,
                    cannotRegister: false,
                    registrationFailed: true
                };
            }
            else {
                return {
                    ...logger,
                    cannotRegister: false,
                    registrationFailed: false
                };
            }
        });
    }
    async register() {
        if (this.activeTab === 'fromList') {
            this.$refs.adminRegisterLoggersModal.registerLoggers();
            return;
        }
        if (this.scannedLoggers.length == 0) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.emptyArray').toString());
            return;
        }
        let verifyPromises = [];
        this.scannedLoggers.forEach((logger) => {
            verifyPromises.push(companyLoggerRepository.adminVerifyLogger(logger.sn, logger.key));
        });
        await Promise.allSettled(verifyPromises).then(async (response) => {
            let activatePromises = [];
            // 400 || 404
            this.cannotRegister = response
                .filter((result) => result.status === 'rejected' && this.isClientErrorResponse(result.reason.statusCode))
                .map((logger) => logger.reason.sn);
            if (this.cannotRegister.length > 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.verificationFailed').toString() + this.cannotRegister);
                return;
            }
            // 500
            this.failedRegistration = response
                .filter((result) => result.status === 'rejected' && this.isServerErrorResponse(result.reason.statusCode))
                .map((logger) => logger.reason.sn);
            if (this.failedRegistration.length > 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.verificationFailed').toString() +
                    this.failedRegistration);
                return;
            }
            response.forEach((result) => {
                activatePromises.push(companyLoggerRepository.adminActivateLogger(result.value.companyLoggerId, this.companyId));
            });
            await Promise.allSettled(activatePromises).then((response) => {
                if (response.find((result) => result.value === false)) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.registerLoggers.activationFailed').toString());
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.registerLoggers.registrationSuccessful').toString());
                }
            });
            this.closeModal();
            this.$emit('loadDevicesAndLoggers');
        });
    }
    isClientErrorResponse(statusCode) {
        return statusCode === 400 || statusCode === 404;
    }
    isServerErrorResponse(statusCode) {
        return statusCode === 500;
    }
    removeFromList(serialNumberToBeDeleted) {
        this.scannedLoggers = this.scannedLoggers.filter((x) => x.sn !== serialNumberToBeDeleted);
        this.failedRegistration = this.failedRegistration.filter((x) => x !== serialNumberToBeDeleted);
    }
    setScanMethod(method) {
        this.scanningMethod = method;
        this.$emit('scannerModalActive', this.scanningMethod === 'scanner' ? true : false);
    }
    dataUpdate(data) {
        this.scannedLoggers = this.scannedLoggers.concat(data);
    }
    tabChanged(tabName) {
        this.activeTab = tabName;
    }
    closeModal() {
        this.$emit('closeModal');
    }
    async loadAdminLoggers(pagination, currentFilter) {
        this.$emit('loadAdminLoggers', pagination, currentFilter);
    }
    async loadDevicesAndLoggers() {
        this.$emit('loadDevicesAndLoggers');
    }
};
__decorate([
    Prop({ type: String })
], AdminRegisterLoggersToDepartmentModal.prototype, "companyId", void 0);
__decorate([
    Prop({ type: Array })
], AdminRegisterLoggersToDepartmentModal.prototype, "adminLoggers", void 0);
__decorate([
    Prop({ type: Number })
], AdminRegisterLoggersToDepartmentModal.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminRegisterLoggersToDepartmentModal.prototype, "componentIsLoading", void 0);
AdminRegisterLoggersToDepartmentModal = __decorate([
    Component({
        components: {
            AdminRegisterLoggersQrParser,
            AdminRegisterLoggersModal
        }
    })
], AdminRegisterLoggersToDepartmentModal);
export default AdminRegisterLoggersToDepartmentModal;

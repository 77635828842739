export default class User {
    constructor() {
        this.detailedInfo = {};
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => User.define(et));
        }
        else if (data != null) {
            return User.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let user = new User();
        user.apiUserId = data.ApiUserId;
        user.id = data.ApiUserId;
        user.email = data.Email;
        user.fullName = data.FullName;
        user.username = data.Username;
        user.enabled = data.Enabled;
        user.assignedAt = data.AssignedAt;
        user.role = data.Role;
        if (data.DetailedInfo) {
            user.detailedInfo = data.DetailedInfo;
        }
        else {
            user.phoneNumber = data.PhoneNumber;
            user.detailedInfo.PhoneNumber = data.PhoneNumber;
        }
        return user;
    }
}

import { Source } from './Source';
import { SourceGroup } from './SourceGroup';
import Device from '@/entities/Device';
export class PublicDashboard {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => PublicDashboard.define(et));
        }
        else if (data != null) {
            return PublicDashboard.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let publicDashboard = new PublicDashboard();
        publicDashboard.name = data.Name;
        publicDashboard.isPublic = data.IsPublic;
        publicDashboard.settings = data.Settings;
        publicDashboard.allowedComponents = data.AllowedComponents;
        if (data.Sources)
            publicDashboard.sources = Source.fromApi(data.Sources);
        if (data.SourceGroups)
            publicDashboard.sourceGroups = SourceGroup.fromApi(data.SourceGroups);
        if (data.Gateways)
            publicDashboard.gateways = Device.fromApi(data.Gateways);
        return publicDashboard;
    }
    hasSourcesOrGroups() {
        return this.sources.length > 0 || this.sourceGroups.length > 0;
    }
}

import AxiosService from '@/services/api/AxiosService';
import Device from '@/entities/Device';
import ApiLinks from '@/entities/ApiLinks';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiStatus from '@/entities/enums/apiStatuses';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import AdminDevice from '@/entities/models/AdminDevice';
import DeviceInstance from '@/entities/models/DeviceInstance';
import ApiResponse from '@/entities/ApiResponse';
import DeviceCredential from '@/entities/models/DeviceCredential';
import { vxm } from '@/store/store.vuex';
import AdminCommand from '@/entities/models/AdminCommand';
import AdminDeviceUpdate from '@/entities/models/AdminDeviceUpdate';
var vxDepartmentStore = vxm.departmentStore;
export default class DeviceRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getDevices(pagination = null, sort = null, departmentId = this.getSelectedDepartmentId()) {
        let devicesResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.DEVICE)) {
            return devicesResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let filters = ['InstanceState==Active'];
        queries.addFilters(filters);
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Gateways.Keys.Gateways}`;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return devicesResponse;
        else {
            //Create response with pagination data
            devicesResponse.setPagination(response.data);
            devicesResponse.setData(Device.sortByName(Device.fromApi(response.data.data)));
            return devicesResponse;
        }
    }
    async getDevice(deviceId, departmentId = this.getSelectedDepartmentId(), processError = true) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.DEVICE)) {
            return new Device();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Gateways.Keys.Gateways}/${deviceId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, processError);
        if (response.status != ApiStatus.SUCCESS)
            return new Device();
        else
            return Device.fromApi(response.data.data);
    }
    async updateDevice(deviceId, changedDevice, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DEVICE)) {
            return new Device();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Gateways.Keys.Gateways}/${deviceId}`;
        let response = await AxiosService.putDataToServer(url, changedDevice, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Device();
        else
            return Device.fromApi(response.data.data);
    }
    async startScan(deviceId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DEVICE)) {
            throw 'Forbidden action startScan';
        }
        let data = new FormData();
        data.set('command', 'start');
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Gateways.Keys.Gateways}/${deviceId}${ApiLinks.Gateways.Keys.Scan}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    async stopScan(deviceId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DEVICE)) {
            throw 'Forbidden action startScan';
        }
        let data = new FormData();
        data.set('command', 'stop');
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Gateways.Keys.Gateways}/${deviceId}${ApiLinks.Gateways.Keys.Scan}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    async registerDevice(registrationKey, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.REGISTER, Subjects.DEVICE)) {
            return new Device();
        }
        let data = new FormData();
        data.set('registrationKey', registrationKey);
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Gateways.Keys.Gateways}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return Device.fromApi(response?.data?.data);
        else
            return (response || {}).data;
    }
    async getAdminDevices(departmentId = null, pagination = null, sort = null, filter = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS)) {
            return parsedResponse;
        }
        let url = ApiLinks.Gateways.AdminHome;
        let queries = new ApiQueries();
        if (departmentId) {
            queries.departmentId = departmentId;
        }
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        // let filters: Array<string> = ['InstanceState==Active'];
        // queries.addFilters(filters);
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            //Create response with pagination data
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(AdminDevice.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async getAdminDevice(deviceId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS)) {
            return new AdminDevice();
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminDevice();
        else
            return AdminDevice.fromApi(response.data.data);
    }
    async getAdminDeviceInstances(deviceId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS)) {
            return new Array();
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Instances}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return DeviceInstance.fromApi(response.data.data);
    }
    async getAdminDeviceCredentials(deviceId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_CREDENTIALS)) {
            return new Array();
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Credentials}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS) {
            return [];
        }
        else
            return DeviceCredential.fromApi(response.data.data);
    }
    async adminRevokeCredential(deviceId, credentialsId) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_GATEWAYS_CREDENTIALS)) {
            throw 'Forbidden action adminRevokeCredential Device';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Credentials}/${credentialsId}${ApiLinks.Gateways.Keys.Revoke}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async adminNewCredential(deviceId, data) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_GATEWAYS_CREDENTIALS)) {
            throw 'Forbidden action adminNewCredential Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Credentials}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.CREATED)
            return true;
        else
            return false;
    }
    async adminConfigureDevice(deviceId, config) {
        if (!this.ctx.$ability.can(Actions.CONFIGURE, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action adminConfigureDevice Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Config}`;
        let response = await AxiosService.postDataToServer(url, config, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else
            return true;
    }
    async adminDeviceFirmwareUpdate(deviceId, data) {
        if (!this.ctx.$ability.can(Actions.SCHEDULE, Subjects.ADMIN_GATEWAYS_UPDATES)) {
            throw 'Forbidden action adminDeviceFirmwareUpdate Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Updates}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return true;
    }
    async adminUpdatePublicKey(deviceId) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_GATEWAYS_CREDENTIALS)) {
            throw 'Forbidden action adminUpdatePublicKey Device';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Credentials}${ApiLinks.Gateways.Keys.SelfUpdate}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async createAdminDevice(deviceDto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action createAdminDevice Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}`;
        let response = await AxiosService.postDataToServer(url, deviceDto, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return (response || {}).data;
    }
    async activateAdminDevice(deviceId, deviceDto) {
        if (!this.ctx.$ability.can(Actions.ACTIVATE, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action createAdminDevice Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Activate}`;
        let response = await AxiosService.postDataToServer(url, deviceDto, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return (response || {}).data;
    }
    async importDevicesCSV(file) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action importDevicesCSV Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}${ApiLinks.Gateways.Keys.ImportCsv}`;
        let formData = new FormData();
        formData.append('csvFile', file);
        let response = await AxiosService.postDataToServer(url, formData, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return true;
    }
    async adminRevertDevice(deviceId) {
        if (!this.ctx.$ability.can(Actions.REVERT, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action adminRevertDevice Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Revert}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminDevice();
        else
            return AdminDevice.fromApi(response.data.data);
    }
    async adminReturnDevice(deviceId) {
        if (!this.ctx.$ability.can(Actions.RETURN, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action adminReturnDevice Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Return}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminDevice();
        else
            return AdminDevice.fromApi(response.data.data);
    }
    async adminRegisterDevice(deviceId, companyId) {
        if (!this.ctx.$ability.can(Actions.REGISTER, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action adminRegisterDevice Device';
        }
        let data = {
            CompanyId: companyId
        };
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Register}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return (response || {}).data;
    }
    async adminPublishDevice(deviceId, registrationKey) {
        if (!this.ctx.$ability.can(Actions.PUBLISH, Subjects.ADMIN_GATEWAYS)) {
            throw 'Forbidden action adminPublishDevice Device';
        }
        let data = {
            RegistrationKey: registrationKey
        };
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Publish}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return (response || {}).data;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    async getAdminCommand(deviceId, pagination = null, sort = null) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_COMMANDS)) {
            throw 'Forbidden action getAdminCommand Device';
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.commands}`;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        let apiResponse = new ApiResponse();
        if (response.status != ApiStatus.SUCCESS)
            return apiResponse;
        else {
            apiResponse.setPagination(response.data);
            apiResponse.setData(AdminCommand.fromApi(response.data.data));
            return apiResponse;
        }
    }
    async disableCommand(deviceId, commandId) {
        if (!this.ctx.$ability.can(Actions.DISABLE, Subjects.ADMIN_GATEWAYS_COMMANDS)) {
            throw 'Forbidden action disableCommand Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.commands}/${commandId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            false;
    }
    async getAdminDeviceUpdates(deviceId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_UPDATES)) {
            throw 'Forbidden action getAdminDeviceUpdates Device';
        }
        let url = `${ApiLinks.Gateways.AdminHome}/${deviceId}${ApiLinks.Gateways.Keys.Updates}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS) {
            return [];
        }
        else
            return AdminDeviceUpdate.fromApi(response.data.data);
    }
}

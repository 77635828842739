var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header" }, [
        _c(
          "h2",
          { staticClass: "subtitle is-4 is-flex is-align-content-center" },
          [
            _vm._v(" " + _vm._s(_vm.$t("component.last_events.title")) + " "),
            !_vm.componentIsLoading && _vm.inactiveLoggers > 0
              ? _c(
                  "b-tooltip",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      type: "is-dark",
                      label:
                        _vm.inactiveLoggers +
                        "/" +
                        _vm.$tc(
                          "component.last_events.inactive_loggers",
                          _vm.sourceGroupSources.length
                        ),
                      position: "is-right",
                      multilined: "",
                    },
                  },
                  [
                    _c("b-icon", {
                      staticClass: "is-size-5",
                      attrs: {
                        size: "is-small",
                        icon: "exclamation",
                        type: "is-primary",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.showRefreshControls
          ? _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-switch",
                  {
                    attrs: { disabled: _vm.componentIsLoading },
                    on: { input: _vm.refreshChanged },
                    model: {
                      value: _vm.isRefreshOn,
                      callback: function ($$v) {
                        _vm.isRefreshOn = $$v
                      },
                      expression: "isRefreshOn",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("component.last_events.refresh")) + " "
                    ),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t("component.last_events.refresh_note"),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.componentIsLoading
        ? _c(
            "div",
            [_c("b-skeleton", { attrs: { height: "90px", animated: true } })],
            1
          )
        : _vm.currentMeasuredEvents.length <= 0
        ? _c(
            "div",
            [
              _c(
                "b-notification",
                { attrs: { closable: false, type: "is-light" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "has-text-centered has-text-weight-semibold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.measurement_info.empty")) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "event-detail-list" },
            _vm._l(_vm.currentMeasuredEvents, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "event-detail-list-item" },
                [
                  _vm.dateTimeManager
                    ? _c("EventDetail", {
                        attrs: {
                          events: item.events,
                          source: item.source,
                          dateTimeManager: _vm.dateTimeManager,
                          onPublicPage: _vm.onPublicPage,
                        },
                        on: { scrollToChartFilter: _vm.scrollToChartFilter },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
      _vm.oldMeasuredEvents.length > 0
        ? _c(
            "b-collapse",
            {
              staticClass: "panel",
              attrs: { animation: "slide" },
              on: {
                open: function ($event) {
                  return _vm.outdatedDataCollapseChanged(true)
                },
                close: function ($event) {
                  return _vm.outdatedDataCollapseChanged(false)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "panel-heading py-1",
                            attrs: { role: "button" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "has-text-weight-light" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("component.last_events.old_events")
                                  ) + " "
                                ),
                                _vm.outdatedDataVisible
                                  ? _c("b-icon", {
                                      attrs: { icon: "chevron-down" },
                                    })
                                  : _c("b-icon", {
                                      attrs: { icon: "chevron-right" },
                                    }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1375246714
              ),
              model: {
                value: _vm.outdatedDataVisible,
                callback: function ($$v) {
                  _vm.outdatedDataVisible = $$v
                },
                expression: "outdatedDataVisible",
              },
            },
            [
              _c(
                "div",
                { staticClass: "event-detail-list pt-2" },
                _vm._l(_vm.oldMeasuredEvents, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "event-detail-list-item" },
                    [
                      _vm.dateTimeManager
                        ? _c("EventDetail", {
                            attrs: {
                              events: item.events,
                              source: item.source,
                              dateTimeManager: _vm.dateTimeManager,
                              onPublicPage: _vm.onPublicPage,
                            },
                            on: {
                              scrollToChartFilter: _vm.scrollToChartFilter,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
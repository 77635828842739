import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
var deviceRepository;
let ImportDevicesModal = class ImportDevicesModal extends Vue {
    constructor() {
        super(...arguments);
        this.uploadedFile = null;
        this.isLoading = false;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    async importFile() {
        this.isLoading = true;
        let createResult = await deviceRepository.importDevicesCSV(this.uploadedFile);
        this.isLoading = false;
        if (createResult === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.importDevices.success'));
            this.resultRetrieved(true);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.devices.importDevices.failure'));
        }
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    deleteDropFile() {
        this.uploadedFile = null;
    }
};
ImportDevicesModal = __decorate([
    Component({})
], ImportDevicesModal);
export default ImportDevicesModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.settings.profile.user_settings.delete_account.title"
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body is-size-5" },
      [
        _c("div", [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "component.settings.profile.user_settings.delete_account.message"
                  )
                ) +
                " "
            ),
          ]),
          _c("ul", { staticClass: "ml-6 mt-3" }, [
            _c(
              "li",
              [
                _c("b-icon", {
                  attrs: { icon: "times-circle", type: "is-danger" },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.settings.profile.user_settings.delete_account.message_point1"
                      )
                    ) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("b-icon", {
                  attrs: { icon: "times-circle", type: "is-danger" },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.settings.profile.user_settings.delete_account.message_point2"
                      )
                    ) +
                    " "
                ),
              ],
              1
            ),
          ]),
          _c(
            "p",
            {
              staticClass:
                "has-text-centered has-text-weight-bold has-text-primary mt-3",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "component.settings.profile.user_settings.delete_account.warning",
                      { email: _vm.email }
                    )
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c(
          "b-field",
          { staticClass: "mt-4" },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
              ],
              attrs: { type: "text" },
              model: {
                value: _vm.checkedEmail,
                callback: function ($$v) {
                  _vm.checkedEmail = $$v
                },
                expression: "checkedEmail",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: {
              type: "is-primary",
              disabled: _vm.checkedEmail.length === 0,
            },
            on: {
              click: function ($event) {
                return _vm.deleteAccount()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "component.settings.profile.user_settings.delete_account.confirm"
                )
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "nav",
        { staticClass: "breadcrumb", attrs: { "aria-label": "breadcrumb" } },
        [
          _c("h1", { staticClass: "title has-text-centered-touch" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({
                          name: "configuration_groups",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        })
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-chevron-left is-size-5",
                      staticStyle: { "padding-right": "10px" },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.settings.location.location_settings"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("li", { staticClass: "is-active" }, [
                _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                  _vm._v(_vm._s(_vm.$t("component.group.create.title"))),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.$ability.can(
              _vm.$permActions.MANAGE,
              _vm.$permSubjects.SOURCE_GROUP
            ) &&
            _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("CreateForm", {
                      staticClass: "box tile is-child",
                      attrs: {
                        sources: _vm.sources,
                        eventTypes: _vm.eventTypes,
                        currenUser: _vm.currenUser,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
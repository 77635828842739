var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      [
        _c("div", { staticClass: "multi-header has-bottom-margin" }, [
          _c(
            "div",
            {
              staticClass:
                "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "subtitle is-4 is-spaced has-text-centered-touch",
                },
                [_vm._v(" " + _vm._s(_vm.$t("tiers.history_list_title")) + " ")]
              ),
            ]
          ),
          _c("div"),
        ]),
        _c(
          "b-table",
          {
            staticClass: "table-wrap",
            attrs: {
              data: _vm.tiersHistory,
              "default-sort": "activatedAt",
              "default-sort-direction": "desc",
            },
          },
          [
            _c("b-table-column", {
              attrs: {
                field: "tierId",
                label: _vm.$t("tiers.tier_id"),
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tier) {
                    return [
                      _c("span", { staticClass: "is-family-monospace" }, [
                        _vm._v(_vm._s(tier.row.id)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "activatedAt",
                label: _vm.$t("tiers.activated_at"),
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tier) {
                    return [
                      tier.row.activatedAt
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dateTimeManager.formatTime(
                                  new Date(tier.row.activatedAt * 1000),
                                  null,
                                  null,
                                  null
                                )
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "deactivatedAt",
                label: _vm.$t("tiers.deactivated_at"),
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tier) {
                    return [
                      tier.row.deactivatedAt
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dateTimeManager.formatTime(
                                  new Date(tier.row.deactivatedAt * 1000),
                                  null,
                                  null,
                                  null
                                )
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "enabledFeatures",
                label: _vm.$t("tiers.features_count"),
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tier) {
                    return [_vm._v(_vm._s(tier.row.enabledFeatures.length))]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                label: _vm.$t("generals.action"),
                sortable: "",
                visible: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tier) {
                    return [
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          !tier.row.active
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "is-primary",
                                    size: "is-small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.activateTier(tier.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("tiers.activate")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !tier.row.alreadyUsed &&
                          _vm.$ability.can(
                            _vm.$permActions.DELETE,
                            _vm.$permSubjects.ADMIN_DEPARTMENTS_TIERS
                          )
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "is-primary",
                                    size: "is-small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTier(tier.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("tiers.delete")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import UserRepository from '@/services/repository/UserRepository';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import UserPreferences from '@/services/UserPreferences';
var userRepository;
let AddResellerUsersModal = class AddResellerUsersModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedUsers = [];
        this.currentFilter = [];
        this.page = null;
        this.pageSize = null;
        this.jumpToPageValue = null;
        this.emailSearch = '';
    }
    async created() {
        userRepository = new UserRepository(this);
        this.fillFromPref();
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadUsers', this.pagination, this.currentFilter);
    }
    selectedUser(user) {
        if (!this.members.find((x) => x.apiUserId === user.id))
            this.selectedUsers.find((x) => x.id === user.id)
                ? this.selectedUsers.splice(this.selectedUsers.indexOf(this.selectedUsers.find((x) => x.id === user.id)), 1)
                : this.selectedUsers.push(user);
    }
    fillFromPref() {
        this.page = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersRowsPerPage.defaultValue;
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadUsers', pagination, this.currentFilter);
    }
    filterUsers(event) {
        let index = this.filterExists('Email@=');
        if (index !== -1)
            this.currentFilter[index] = 'Email@=' + event;
        else
            this.currentFilter.push('Email@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    get availableUserRoles() {
        return Object.values(ApiUserRoles).filter((x) => x != ApiUserRoles.SYSTEM_ADMIN);
    }
    async addResellerUsers() {
        this.$validator.validateAll().then(async (result) => {
            if (this.selectedUsers.length <= 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.select_user'));
            }
            else {
                let selectedUsersId = [];
                this.selectedUsers.forEach((user) => {
                    selectedUsersId.push(user.id);
                });
                this.$emit('addResellerUsers', selectedUsersId);
            }
        });
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], AddResellerUsersModal.prototype, "availableUsers", void 0);
__decorate([
    Prop({ type: Array })
], AddResellerUsersModal.prototype, "members", void 0);
__decorate([
    Prop({ type: Number })
], AddResellerUsersModal.prototype, "totalSize", void 0);
AddResellerUsersModal = __decorate([
    Component({})
], AddResellerUsersModal);
export default AddResellerUsersModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "multi-header mx-3 mb-0" },
        [
          _c(
            "h1",
            {
              staticClass: "title is-3 mb-3 is-spaced has-text-centered-touch",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("component.public_dashboard.dashboard_detail.title")
                  ) +
                  " "
              ),
            ]
          ),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DASHBOARD)
            ? _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDashboardDelete()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.remove")))]
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "tile is-parent" },
            [
              _c("DashboardInfo", {
                staticClass: "box tile is-child",
                attrs: {
                  dashboard: _vm.dashboard,
                  sources: _vm.sources,
                  sourceGroups: _vm.sourceGroups,
                  devices: _vm.devices,
                  users: _vm.users,
                  dateTimeManager: _vm.dateTimeManager,
                  departmentId: _vm.$route.params.departmentId,
                },
                on: { reload: _vm.loadData },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
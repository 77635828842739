import { __decorate } from "tslib";
import LoggedUserManager from '@/services/LoggedUserManager';
import { Component, Prop, Vue } from 'vue-property-decorator';
let OrganizationItem = class OrganizationItem extends Vue {
    constructor() {
        super(...arguments);
        this.isAdmin = false;
    }
    get isFavorite() {
        return this.favoriteDepartmentId === this.department.id;
    }
    created() {
        this.isAdmin = LoggedUserManager.isAdmin(this);
    }
    favoriteSelected() {
        if (!this.department.member.Enabled)
            return;
        let id = !this.isFavorite ? this.department.id : '';
        this.$emit('favoriteSelected', id);
    }
};
__decorate([
    Prop({ type: Object })
], OrganizationItem.prototype, "department", void 0);
__decorate([
    Prop({ type: String, default: null })
], OrganizationItem.prototype, "favoriteDepartmentId", void 0);
OrganizationItem = __decorate([
    Component({ components: {} })
], OrganizationItem);
export default OrganizationItem;

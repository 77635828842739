import DateTimeManager from '@/services/DateTimeManager';
import ChartDateFormatter from '@/services/chart-data-conversion/chartDateFormatter';
export default class GeneralChartDataConverter {
    async getTimeFormatter(ctx, useDefaultValues = false) {
        let dateTimeManager = await DateTimeManager.CreateManager(ctx, useDefaultValues);
        let timeFormatter = function (d) {
            return dateTimeManager.formatTime(new Date(d));
        };
        return timeFormatter;
    }
    async getChartTimeFormatter(ctx, useDefaultValues = false) {
        let chartDateFormatter = await ChartDateFormatter.CreateFormatter(ctx, useDefaultValues);
        return chartDateFormatter.getFormatter();
    }
    async getOnlyTimeFormatter(ctx, useDefaultValues = false) {
        let dateTimeManager = await DateTimeManager.CreateManager(ctx, useDefaultValues);
        let onlyTimeFormatter = function (d) {
            return dateTimeManager.formatTime(new Date(d), null, '', null);
        };
        return onlyTimeFormatter;
    }
    async getOnlyDateFormatter(ctx, useDefaultValues = false) {
        let dateTimeManager = await DateTimeManager.CreateManager(ctx, useDefaultValues);
        let onlyDateFormatter = function (d) {
            return dateTimeManager.formatTime(new Date(d), null, null, '');
        };
        return onlyDateFormatter;
    }
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import TierDetail from '@/views/components/admin/tiers/TierDetail.vue';
import TiersRepository from '@/services/repository/TiersRepository';
import ApiStatus from '@/entities/enums/apiStatuses';
import DateTimeManager from '@/services/DateTimeManager';
var tiersRepository;
let AdminTierDetail = class AdminTierDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.tier = null;
        this.id = null;
        this.features = [];
        this.dateTimeManager = null;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.id = this.$route.params.tierId;
        if (!this.id) {
            this.$router.replace({
                name: 'pageNotExist',
                params: { lang: this.$route.params.lang }
            });
        }
        else {
            tiersRepository = new TiersRepository(this);
            this.loadData();
        }
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(tiersRepository.getTier(this.id));
        allPromises.push(tiersRepository.getAllFeatures());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1]);
            this.isLoading = false;
        });
    }
    processLoadedDataForPage(response, features) {
        if (response.status === ApiStatus.NOT_FOUND) {
            this.$router.replace({
                name: 'pageNotExist',
                params: { lang: this.$route.params.lang }
            });
        }
        else {
            this.tier = response;
            this.features = features;
        }
    }
    async updateTier(dto) {
        this.isLoading = true;
        await tiersRepository.updateTier(dto, this.tier.id);
        this.tier = await tiersRepository.getTier(this.id);
        this.isLoading = false;
    }
};
AdminTierDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_TIERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { TierDetail }
    })
], AdminTierDetail);
export default AdminTierDetail;

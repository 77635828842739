import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
let TierUsageTable = class TierUsageTable extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.TierUsageSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.TierUsageSortField.defaultValue;
    }
    created() { }
};
__decorate([
    Prop({ type: Object })
], TierUsageTable.prototype, "activeTier", void 0);
TierUsageTable = __decorate([
    Component({})
], TierUsageTable);
export default TierUsageTable;

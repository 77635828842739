var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title is-3 is-spaced has-text-centered-touch" }, [
      _vm._v(" " + _vm._s(_vm.$t("component.settings.title")) + " "),
    ]),
    _c(
      "div",
      {
        staticClass:
          "columns is-multiline is-variable is-8-desktop settings-columns",
      },
      [
        _vm._l(Object.values(_vm.SettingOptions), function (item, index) {
          return [
            _vm.isOptionAllowed(item)
              ? _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "column is-full-mobile is-half-tablet is-one-fifth-desktop",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.getRouteName(item),
                            params: {
                              lang: _vm.$route.params.lang,
                              departmentId: _vm.$route.params.departmentId,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "box setting-box has-text-centered is-full-height",
                          },
                          [
                            _c("b-icon", {
                              staticClass: "is-centered",
                              attrs: { icon: item.icon, size: "is-large" },
                            }),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "category has-text-dark has-text-weight-semibold is-size-5",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.settings.options." +
                                          item.name
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description has-text-grey-dark has-text-weight-light",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.settings.description." +
                                          item.descriptionKey
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
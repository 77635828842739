import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SalesRepository from '@/services/repository/SalesRepository';
import FeedbackCategories from '@/entities/enums/FeedbackCategories';
import VueUtilities from '@/services/VueUtilities';
var salesRepository;
let ContactUsForm = class ContactUsForm extends Vue {
    constructor() {
        super(...arguments);
        this.message = null;
        this.subject = null;
        this.messageSent = false;
    }
    created() {
        salesRepository = new SalesRepository(this);
    }
    send() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let data = {
                    Text: this.message,
                    Category: FeedbackCategories.CONTACT_FORM.name,
                    Subject: this.subject
                };
                let result = await salesRepository.sendFeedback(data);
                if (result === true) {
                    this.messageSent = true;
                    this.message = null;
                    this.subject = null;
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('contact_form.failure'));
                }
            }
        });
    }
};
ContactUsForm = __decorate([
    Component({})
], ContactUsForm);
export default ContactUsForm;

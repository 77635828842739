import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Source } from '@/entities/models/Source';
import UserPreferences from '@/services/UserPreferences';
let StatesComponent = class StatesComponent extends Vue {
    constructor() {
        super(...arguments);
        this.statesVisible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.StatesVisible);
        this.showGroups = true;
        this.selectedGroup = '';
        this.selectedLoggersState = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SelectedLoggersState);
        this.selectedDevicesState = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SelectedDevicesState);
        this.pageLogger = UserPreferences.LocalStorageKeys.PublicDashboardPage.defaultValue;
        this.pageDevice = UserPreferences.LocalStorageKeys.PublicDashboardPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.PublicDashboardRowsPerPage.defaultValue;
        this.jumpToPageValueLogger = this.pageLogger;
        this.jumpToPageValueDevice = this.pageDevice;
        this.sortDirection = UserPreferences.LocalStorageKeys.PublicDashboardSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.PublicDashboardSortField.defaultValue;
    }
    created() {
        this.selectedGroup = this.selectedSourceGroupId;
        this.showGroups = this.byGroup;
    }
    loadData() { }
    get group() {
        if (this.$route.params.groupId != this.selectedGroup)
            return this.sourceGroups.filter((x) => x.id == this.selectedGroup);
    }
    selectLoggerState(event) {
        this.selectedLoggersState = event;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.SelectedLoggersState, this.selectedLoggersState);
    }
    selectDeviceState(event) {
        this.selectedDevicesState = event;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.SelectedDevicesState, this.selectedDevicesState);
    }
    get loggerOfflineCount() {
        if (this.showGroups)
            return this.sourceGroupSources.filter((x) => x.isOnline == false).length;
        else
            return this.allSources.filter((x) => x.isOnline == false).length;
    }
    get loggerOnlineCount() {
        if (this.showGroups)
            return this.sourceGroupSources.filter((x) => x.isOnline == true).length;
        else
            return this.allSources.filter((x) => x.isOnline == true).length;
    }
    get deviceOfflineCount() {
        return this.allDevices.filter((x) => x.isOnline == false).length;
    }
    get deviceOnlineCount() {
        return this.allDevices.filter((x) => x.isOnline == true).length;
    }
    get sourcesStates() {
        if (this.showGroups) {
            if (this.selectedLoggersState)
                return this.sourceGroupSources.filter((x) => x.isOnline == true);
            else
                return this.sourceGroupSources.filter((x) => x.isOnline == false);
        }
        else {
            if (this.selectedLoggersState)
                return this.allSources.filter((x) => x.isOnline == true);
            else
                return this.allSources.filter((x) => x.isOnline == false);
        }
    }
    get devicesStates() {
        if (this.selectedDevicesState)
            return this.allDevices.filter((x) => x.isOnline == true);
        else
            return this.allDevices.filter((x) => x.isOnline == false);
    }
    statesCollapseChanged(isOpen) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.StatesVisible, isOpen);
    }
    get unassignedSources() {
        return Source.getUnassignedSources(this.sources);
    }
    sourceGroupSelected(sourceGroupId) {
        this.$router.push({
            name: this.$route.name,
            query: { groupId: sourceGroupId },
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }
        });
        this.$emit('sourceGroupSelected', sourceGroupId);
    }
    allSelected() {
        if (this.showGroups === false)
            this.$emit('allSelected');
        else
            this.$emit('sourceGroupSelected', this.selectedSourceGroupId);
    }
    onPageChangeLogger(page) {
        this.jumpToPageValueLogger = page;
    }
    onPageChangeDevice(page) {
        this.jumpToPageValueDevice = page;
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], StatesComponent.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatesComponent.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: String })
], StatesComponent.prototype, "selectedSourceGroupId", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatesComponent.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatesComponent.prototype, "allSources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatesComponent.prototype, "allDevices", void 0);
__decorate([
    Prop({ type: Object })
], StatesComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], StatesComponent.prototype, "byGroup", void 0);
StatesComponent = __decorate([
    Component({
        components: {}
    })
], StatesComponent);
export default StatesComponent;

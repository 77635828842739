import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import CookiesStorage from '@/services/CookiesStorage';
let UserDetailAdministration = class UserDetailAdministration extends Vue {
    constructor() {
        super(...arguments);
        this.ApiUserRoles = ApiUserRoles;
        this.username = CookiesStorage.loadData(this, CookiesStorage.keys.USERNAME);
    }
    openDisableDialog(user) {
        this.$emit('disableUserDialog', user);
    }
    enableUser(user) {
        this.$emit('enableUser');
    }
    async openChangeRoleModal(user) {
        this.$emit('openChangeRoleModal');
    }
};
__decorate([
    Prop({ type: Object })
], UserDetailAdministration.prototype, "user", void 0);
__decorate([
    Prop({ type: Object })
], UserDetailAdministration.prototype, "dateTimeManager", void 0);
UserDetailAdministration = __decorate([
    Component({
        components: {}
    })
], UserDetailAdministration);
export default UserDetailAdministration;

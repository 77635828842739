import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import AuthenticationCalls from '@/services/AuthenticationCalls';
var authenticationCalls;
let ChangePassword = class ChangePassword extends Vue {
    constructor() {
        super(...arguments);
        this.error = false;
        this.showForm = true;
        this.email = '';
        this.pwd = '';
        this.pwd_check = '';
        this.sent = false;
        this.wrongToken = false;
        this.token = '';
        this.username = '';
        this.rawToken = '';
    }
    created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        let queryData = this.$route.query;
        if (queryData.hasOwnProperty('token')) {
            this.rawToken = queryData.token;
            try {
                let parsedToken = JSON.parse(atob(this.rawToken.replace(/_/g, '/').replace(/-/g, '+')));
                this.token = parsedToken.token;
                this.username = parsedToken.username;
            }
            catch (error) { }
        }
        else {
            this.showForm = false;
            this.wrongToken = true;
            // Show error
        }
    }
    changePassword() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let success = await authenticationCalls.passwordChange(this.token, this.pwd, this.pwd_check);
                if (success != null) {
                    if (success) {
                        VueUtilities.openSuccessToast(this, this.$t('component.password_recover.change_password_success').toString());
                        this.wrongToken = false;
                        this.sent = true;
                        this.error = false;
                        this.showForm = false;
                    }
                    else {
                        this.wrongToken = true;
                        this.showForm = false;
                    }
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.password_recover.change_password_failure').toString());
                }
            }
            else {
            }
        });
    }
};
ChangePassword = __decorate([
    Component({})
], ChangePassword);
export default ChangePassword;

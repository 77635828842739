import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let AdminDeviceFirmwareHistory = class AdminDeviceFirmwareHistory extends Vue {
    async created() { }
};
__decorate([
    Prop({ type: Object })
], AdminDeviceFirmwareHistory.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceFirmwareHistory.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceFirmwareHistory.prototype, "isLayoutList", void 0);
AdminDeviceFirmwareHistory = __decorate([
    Component({})
], AdminDeviceFirmwareHistory);
export default AdminDeviceFirmwareHistory;

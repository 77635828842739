var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title is-3 is-spaced has-text-centered-touch" }, [
      _vm._v(" " + _vm._s(_vm.$t("contact_form.feedback")) + " "),
    ]),
    _c("div", { staticClass: "tile is-ancestor" }, [
      _c("div", { staticClass: "tile is-vertical" }, [
        _c(
          "div",
          { staticClass: "tile is-parent" },
          [_c("FeedbackForm", { staticClass: "tile is-child box" })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
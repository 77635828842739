import MeasurementStatus from '@/entities/enums/measurementStatuses';
export class Statistics {
    constructor() { }
    static fromApi(data) {
        if (!data)
            return null;
        else {
            let converted = {};
            Object.keys(data).forEach((key) => {
                let newStatistics = [];
                Object.keys(data[key]).forEach((serviceKey) => {
                    newStatistics.push(Statistics.define(data[key][serviceKey], serviceKey));
                });
                let newReportRecord = { [key]: newStatistics };
                converted = Object.assign(converted, newReportRecord);
            });
            return converted;
        }
    }
    static define(data, serviceKey) {
        let statistic = new Statistics();
        statistic.avg = data.Avg;
        statistic.belowBoundary = data.BelowBoundary;
        statistic.belowTime = data.BelowTime;
        statistic.count = data.Count;
        statistic.max = data.Max;
        statistic.min = data.Min;
        statistic.mkt = data.Mkt;
        statistic.overBoundary = data.OverBoundary;
        statistic.overTime = data.OverTime;
        statistic.unit = data.Unit;
        statistic.service = serviceKey;
        statistic.status = MeasurementStatus.getStatusByName(data.Status);
        return statistic;
    }
    static getStatisticByService(statistics = [], serviceSlug) {
        return statistics.find((x) => x.service == serviceSlug);
    }
    static getServices(statistics = []) {
        let recordServices = statistics.flatMap((x) => x.service);
        let distinctServiceSlugs = [...new Set(recordServices)].filter((x) => x != null);
        return distinctServiceSlugs;
    }
}

export default class FormatUtilities {
    static formatRegistrationKey(registrationKey) {
        var regKeyInput = document.getElementById('reg-key');
        let startPosition = regKeyInput.selectionStart;
        if (registrationKey.length == 0)
            return;
        let substring = registrationKey.slice(0, startPosition);
        registrationKey = registrationKey.toUpperCase();
        let formatted = '';
        formatted = registrationKey.split('-').join('');
        registrationKey = formatted.match(/.{1,4}/g).join('-');
        let currentOffset = 0;
        let substringWithoutDashes = substring.split('-').join('');
        let dashCountInSubstring = (substring.match(/-/g) || []).length;
        let expectedDashCount = Math.ceil(substringWithoutDashes.length / 4) - 1;
        currentOffset = expectedDashCount - dashCountInSubstring;
        return {
            RegistrationKey: registrationKey,
            Start: startPosition,
            CurrentOffset: currentOffset
        };
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
var deviceRepository;
let ScheduleUpdateModal = class ScheduleUpdateModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.selectedDevices = [];
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    scheduleUpdate() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.selectedDevices.length > 0) {
                    this.isLoading = true;
                    let data = {
                        FirmwareId: this.firmware.id
                    };
                    let allPromises = [];
                    this.selectedDevices.forEach(async (device) => {
                        allPromises.push(await deviceRepository.adminDeviceFirmwareUpdate(device.deviceId, data));
                    });
                    Promise.all(allPromises).then((response) => {
                        VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.ScheduleUpdateModal.success'));
                        this.resultRetrieved(true);
                        this.$emit('reload');
                    });
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.ScheduleUpdateModal.selectDevices'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], ScheduleUpdateModal.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], ScheduleUpdateModal.prototype, "firmware", void 0);
__decorate([
    Prop({ type: Array })
], ScheduleUpdateModal.prototype, "deviceList", void 0);
ScheduleUpdateModal = __decorate([
    Component({})
], ScheduleUpdateModal);
export default ScheduleUpdateModal;

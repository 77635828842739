export class AdminResellerListable {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminResellerListable.define(et));
        }
        else if (data != null) {
            return AdminResellerListable.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let reseller = new AdminResellerListable();
        reseller.id = data.Id;
        reseller.name = data.Name;
        reseller.userCount = data.UserCount;
        reseller.departmentCount = data.DepartmentCount;
        reseller.gatewayCount = data.GatewayCount;
        reseller.loggerCount = data.LoggerCount;
        return reseller;
    }
}

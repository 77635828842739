import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import UserPreferences from '@/services/UserPreferences';
import SourceChangesActions from '@/entities/enums/SourceChangesActions';
var companyLoggerRepository;
let LoggerChanges = class LoggerChanges extends Vue {
    constructor() {
        super(...arguments);
        this.CalibrationStatus = CalibrationStatus;
        this.groupedSourceChanges = [];
        this.isLoading = false;
        this.sortDirection = UserPreferences.LocalStorageKeys.LoggerChangesSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.LoggerChangesSortField.defaultValue;
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.groupSourceChanges();
    }
    groupSourceChanges() {
        // Group logger modifications based on assignment and unassignment
        this.sourceChanges.forEach((change, index) => {
            let previous = index - 1;
            if (change.action.toLowerCase() === SourceChangesActions.ASSIGN) {
                this.groupedSourceChanges.push({
                    serialNumber: change.logger.serialNumber,
                    assigned: change,
                    unassigned: null
                });
            }
            else if (change.logger.serialNumber === this.sourceChanges[previous].logger.serialNumber &&
                change.action.toLowerCase() === SourceChangesActions.UNASSIGN) {
                this.groupedSourceChanges[this.groupedSourceChanges.length - 1].unassigned = change;
            }
        });
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.downloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    hasCalibrationProtocolToDownload(source) {
        if (source == null || source.calibration == null || source.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
};
__decorate([
    Prop({ type: Object })
], LoggerChanges.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], LoggerChanges.prototype, "sourceChanges", void 0);
LoggerChanges = __decorate([
    Component({ components: {} })
], LoggerChanges);
export default LoggerChanges;

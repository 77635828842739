import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import BoundaryWizardTable from '@/views/components/boundary/BoundaryWizardTable.vue';
let BoundariesStep = class BoundariesStep extends Vue {
    reload() {
        this.$emit('reload');
    }
};
__decorate([
    Prop({ type: Array })
], BoundariesStep.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array })
], BoundariesStep.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], BoundariesStep.prototype, "eventTypes", void 0);
BoundariesStep = __decorate([
    Component({
        components: {
            BoundaryWizardTable
        }
    })
], BoundariesStep);
export default BoundariesStep;

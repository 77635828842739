import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import ResellerRepository from '@/services/repository/ResellerRepository';
var resellerRepository;
let CreateResellerModal = class CreateResellerModal extends Vue {
    constructor() {
        super(...arguments);
        this.name = null;
        this.isLoading = false;
    }
    created() {
        resellerRepository = new ResellerRepository(this);
    }
    async createReseller() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let resellerDto = {
                    Name: this.name
                };
                let createResult = await resellerRepository.adminCreateReseller(resellerDto);
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.createResellerModal.success'));
                    this.$emit('reload');
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.createResellerModal.failure'));
                }
                this.isLoading = false;
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
CreateResellerModal = __decorate([
    Component({})
], CreateResellerModal);
export default CreateResellerModal;

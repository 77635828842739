import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Footer from '@/views/components/common/Footer.vue';
import Languages from '@/entities/enums/languages';
let LandingPage = class LandingPage extends Vue {
    constructor() {
        super(...arguments);
        this.Languages = Languages;
        this.lang = this.$route.params.lang;
    }
    created() {
        document.body.classList.remove('has-navbar-fixed-top');
        this.$validator.localize(this.lang);
    }
    destroyed() {
        document.body.classList.add('has-navbar-fixed-top');
    }
    async changeLanguage(language) {
        this.$validator.localize(language);
        this.lang = language;
        let params = this.$route.params;
        params.lang = language;
        this.$router
            .push({
            name: this.$route.name,
            params: params,
            query: this.$route.query
        })
            .catch((err) => err);
    }
    isCurrentLanguage(lang) {
        return this.lang === lang;
    }
};
LandingPage = __decorate([
    Component({ components: { Footer } })
], LandingPage);
export default LandingPage;

import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import { Event } from '@/entities/models/Event';
import { ApiQueries } from '@/entities/ApiQueries';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiStatus from '@/entities/enums/apiStatuses';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class EventRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getLastEventsForSources(sourceIds = [], signal, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.MEASURED_DATA_READ)) {
            return {};
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Events.Keys.Last}`;
        let queries = new ApiQueries();
        if (sourceIds)
            queries.sourcesArray = sourceIds;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, true, signal);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return Event.fromApi(response.data.data);
    }
    async getChartDataForSources(sourceIds, from, signal, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.MEASURED_DATA_READ)) {
            return {};
        }
        if (sourceIds.length <= 0)
            return {};
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Events.Keys.Chart}`;
        let queries = new ApiQueries();
        if (sourceIds)
            queries.sourcesArray = sourceIds;
        if (from)
            queries.from = from;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, true, signal);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return response.data.data;
    }
    async getUptimeChartDataForSources(sourceIds, from, signal, duration = 300, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.MEASURED_DATA_READ)) {
            return {};
        }
        if (sourceIds.length <= 0)
            return {};
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Events.Keys.Outage}`;
        let queries = new ApiQueries();
        if (sourceIds)
            queries.sourcesArray = sourceIds;
        if (from)
            queries.from = from;
        if (duration)
            queries.duration = duration;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, true, signal);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return response.data.data;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const d3 = __importStar(require("d3"));
const d3MultiFormat = {
    second: '%H:%M:%S',
    minute: '%H:%M',
    hour: '%H:%M',
    day: '%a %d',
    date: '%b %d',
    month: '%B',
    year: '%Y',
};
const tickFormat = function (date) {
    if (date.getSeconds())
        return d3.utcFormat(d3MultiFormat.second)(date);
    if (date.getMinutes())
        return d3.utcFormat(d3MultiFormat.minute)(date);
    if (date.getHours())
        return d3.utcFormat(d3MultiFormat.hour)(date);
    if (date.getDay() && date.getDate() != 1)
        return d3.utcFormat(d3MultiFormat.day)(date);
    if (date.getDate() != 1)
        return d3.utcFormat(d3MultiFormat.date)(date);
    if (date.getMonth())
        return d3.utcFormat(d3MultiFormat.month)(date);
    return d3.utcFormat(d3MultiFormat.year)(date);
};
exports.default = tickFormat;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRegistrationModal from './DeviceRegistrationModal.vue';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import { vxm } from '@/store/store.vuex';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
var vxDepartmentStore = vxm.departmentStore;
let DeviceList = class DeviceList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.isPrivateKeyModalActive = false;
        this.deviceIdToConfigure = null;
        this.selectedDevice = null;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.componentName = 'deviceList';
        this.tableColumns = {
            deviceName: {
                field: 'deviceName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DeviceListField)
            },
            description: {
                field: 'description',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DeviceListDescription)
            },
            registeredAt: {
                field: 'registeredAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DeviceListRegisteredAt)
            },
            lastSeen: {
                field: 'lastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DeviceListLastSeen)
            },
            isOnline: {
                field: 'isOnline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DeviceListIsOnline)
            }
        };
        this.tableQueries = null;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.allDevicesCount ? this.allDevicesCount : this.page * this.pageSize;
    }
    get startAt() {
        return this.pageSize * (this.page - 1);
    }
    created() {
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.reloadDevices(this.pagination);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueries = JSON.parse(this.$router.currentRoute.query.deviceList);
            this.sortField = this.tableQueries.sortField;
            this.sortDirection = this.tableQueries.sortDirection;
            this.page = this.tableQueries.page;
            this.pageSize = this.tableQueries.pageSize;
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.DeviceListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.DeviceListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.DeviceListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.DeviceListRowsPerPage.defaultValue;
    }
    registerNewDevice() {
        this.isPrivateKeyModalActive = true;
    }
    onModalClose(data) {
        this.onPageChange(this.page);
    }
    closeModal(data) {
        this.isPrivateKeyModalActive = false;
        this.onModalClose({});
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    changeRouterUrl(tableQueries) {
        this.$router
            .replace({
            params: {
                departmentId: this.getSelectedDepartmentId(),
                lang: this.$route.params.lang
            },
            query: Object.assign({}, this.$route.query, {
                [this.componentName]: tableQueries
            })
        })
            .catch((err) => err);
    }
    onSort(field, order) {
        this.sortField = field;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange();
    }
    onPageChange(page) {
        this.page = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.reloadDevices(this.pagination);
        this.onOptionsChange();
    }
    onOptionsChange() {
        let tableQueries = {
            sortField: this.sort.field,
            sortDirection: this.sort.direction == '-' ? 'desc' : 'asc',
            page: this.page,
            pageSize: this.pageSize,
            filters: null
        };
        this.changeRouterUrl(JSON.stringify(tableQueries));
    }
    reloadDevices(pagination) {
        this.$emit('pageChanged', pagination);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.description.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DeviceListDescription, event);
                break;
            case this.tableColumns.registeredAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DeviceListRegisteredAt, event);
                break;
            case this.tableColumns.lastSeen.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DeviceListLastSeen, event);
                break;
            case this.tableColumns.isOnline.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DeviceListIsOnline, event);
                break;
        }
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
__decorate([
    Prop({ type: Array })
], DeviceList.prototype, "deviceList", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], DeviceList.prototype, "allDevicesCount", void 0);
__decorate([
    Prop({ type: Object })
], DeviceList.prototype, "dateTimeManager", void 0);
DeviceList = __decorate([
    Component({
        components: { DeviceRegistrationModal }
    })
], DeviceList);
export default DeviceList;

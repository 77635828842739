import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import AdminConfigureDeviceModal from '@/views/components/admin/devices/AdminConfigureDeviceModal.vue';
import DeviceUpdateModal from '@/views/components/admin/devices/DeviceUpdateModal.vue';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import SortDirections from '@/entities/enums/SortDirections';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import { Department } from '@/entities/models/Department';
import DeviceRepository from '@/services/repository/DeviceRepository';
import debounce from 'lodash.debounce';
import { DeviceState } from '@/entities/enums/DeviceState';
import ActivateDeviceModal from './ActivateDeviceModal.vue';
import DeviceMonitor from './DeviceMonitor.vue';
import KeyUpdateModal from './KeyUpdateModal.vue';
import PublishDeviceModal from './PublishDeviceModal.vue';
import RegisterToCompany from './RegisterToCompany.vue';
var deviceRepository;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
const DEBOUNCE_TIMER = 500;
let AdminDeviceList = class AdminDeviceList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.DeviceState = DeviceState;
        this.selectedValue = '';
        this.isNameSearch = true;
        this.isIdSearch = false;
        this.isCompanyNameSearch = false;
        this.isConfigIntervalSearch = false;
        this.isMacAddressSearch = false;
        this.isFirmwareVersionSearch = false;
        this.isDescriptionSearch = false;
        this.isOfflineSearch = false;
        this.nameSearch = '';
        this.companyNameSearch = '';
        this.configIntervalSearch = '';
        this.idSearch = '';
        this.macSearch = '';
        this.firmwareVersionSearch = '';
        this.descriptionSearch = '';
        this.selectedDevices = new Array();
        this.selectedDevice = null;
        this.isConfigModalActive = false;
        this.isDeviceUpdateModalActive = false;
        this.isActivateDeviceModalActive = false;
        this.isPublishModalActive = false;
        this.isRegisterModalActive = false;
        this.isMonitorModalActive = false;
        this.isKeyUpdateModalActive = false;
        this.DeviceInstanceState = DeviceInstanceState;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.currentFilter = [];
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.offlineSearch = null;
        this.isReuse = false;
        this.tableColumns = {
            serialName: {
                field: 'SerialName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListSerialName),
                hasSearch: true
            },
            macAddress: {
                field: 'MacAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListMacAddress),
                hasSearch: true
            },
            deviceId: {
                field: 'Id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDeviceId),
                hasSearch: true
            },
            company: {
                field: 'CompanyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListCompany),
                hasSearch: true
            },
            currentInterval: {
                field: 'ConfigInterval',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListInterval),
                hasSearch: true
            },
            fwVersion: {
                field: 'FirmwareVersion',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListVersion),
                hasSearch: true
            },
            lastSeen: {
                field: 'LastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListLastSeen),
                hasSearch: false
            },
            description: {
                field: 'Description',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDescription),
                hasSearch: true
            },
            isOffline: {
                field: 'IsOffline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListIsOffline),
                hasSearch: true
            }
        };
        this.handleFilterNameDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.serialName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCompanyNameDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.company.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterIntervalDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.currentInterval.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterMacDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.macAddress.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterFirmwareVersionDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.fwVersion.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterDescriptionDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.description.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterIdDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.deviceId.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        this.selectedValue = this.tableColumns.serialName.field;
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadDevices', this.pagination, this.sort, this.currentFilter);
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminDevice);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            this.currentFilter = JSON.parse(this.tableQueriesfromUrl.Filter);
            // send queries to parent component
            let objStringAdminDevice = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromDevice', objStringAdminDevice);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminDeviceListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminDeviceListRowsPerPage.defaultValue;
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.nameSearch =
                this.filterExists('SerialName@=') !== -1
                    ? this.currentFilter[this.filterExists('SerialName@=')].substring(12)
                    : '';
            this.idSearch =
                this.filterExists('Id==') !== -1 ? this.currentFilter[this.filterExists('Id==')].substring(4) : '';
            this.macSearch =
                this.filterExists('MacAddress@=') !== -1
                    ? this.currentFilter[this.filterExists('MacAddress@=')].substring(12)
                    : '';
            this.companyNameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilter[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.configIntervalSearch =
                this.filterExists('ConfigInterval@=') !== -1
                    ? this.currentFilter[this.filterExists('ConfigInterval@=')].substring(16)
                    : '';
            this.firmwareVersionSearch =
                this.filterExists('FirmwareVersion@=') !== -1
                    ? this.currentFilter[this.filterExists('FirmwareVersion@=')].substring(17)
                    : '';
            this.descriptionSearch =
                this.filterExists('Description@=') !== -1
                    ? this.currentFilter[this.filterExists('Description@=')].substring(13)
                    : '';
            this.offlineSearch =
                this.filterExists('IsOffline==true') !== -1
                    ? true
                    : this.filterExists('IsOffline==false') !== -1
                        ? false
                        : null;
        }
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    sorted(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    keyUpdate(device) {
        this.selectedDevice = device;
        this.isKeyUpdateModalActive = true;
    }
    revertDevice(device) {
        this.selectedDevice = device;
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.devices.detail.deviceInfo.revert.title'),
            message: this.$t('admin.component.devices.detail.deviceInfo.revert.message', {
                name: this.selectedDevice.deviceName
            }),
            confirmText: this.$t('admin.component.devices.detail.deviceInfo.revert.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                await deviceRepository.adminRevertDevice(this.selectedDevice.deviceId);
                this.$emit('reloadDevices', this.pagination, this.sort, this.currentFilter);
            }
        });
    }
    reuseDevice(device) {
        this.selectedDevice = device;
        this.isReuse = true;
        this.isPublishModalActive = true;
    }
    activateDevice(device) {
        this.selectedDevice = device;
        this.isActivateDeviceModalActive = true;
    }
    returnDeviceFromCompany(device) {
        this.selectedDevice = device;
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.devices.detail.deviceInfo.return.title'),
            message: this.$t('admin.component.devices.detail.deviceInfo.return.message', {
                name: this.selectedDevice.deviceName
            }),
            confirmText: this.$t('admin.component.devices.detail.deviceInfo.return.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                await deviceRepository.adminReturnDevice(this.selectedDevice.deviceId);
                this.$emit('reloadDevices', this.pagination, this.sort, this.currentFilter);
            }
        });
    }
    registerToCompany(device) {
        this.selectedDevice = device;
        this.isRegisterModalActive = true;
    }
    publishDevice(device) {
        this.selectedDevice = device;
        this.isPublishModalActive = true;
    }
    otaUpdateDevice(device) {
        this.selectedDevice = device;
        this.isDeviceUpdateModalActive = true;
    }
    closeActivateDeviceModal(result) {
        this.isActivateDeviceModalActive = false;
        if (result === true)
            this.$emit('reloadDevices', this.pagination, this.sort, this.currentFilter);
    }
    closePublishModal(result) {
        this.isReuse = false;
        this.isPublishModalActive = false;
        if (result === true)
            this.$emit('reloadDevices', this.pagination, this.sort, this.currentFilter);
    }
    closeRegisterModal(result) {
        this.isRegisterModalActive = false;
        if (result === true)
            this.$emit('reloadDevices', this.pagination, this.sort, this.currentFilter);
    }
    closeMonitorModal() {
        this.isMonitorModalActive = false;
    }
    closeKeyUpdate() {
        this.isKeyUpdateModalActive = false;
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter);
        this.$emit('onOptionsChange', options, this.currentFilter);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterDevices(searchField, value) {
        switch (searchField) {
            case this.tableColumns.deviceId.field:
                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        this.replaceFilter('Id==', value);
                    }
                    else {
                        return;
                    }
                });
                break;
            case this.tableColumns.serialName.field:
                this.replaceFilter('SerialName@=', value);
                break;
            case this.tableColumns.company.field:
                this.replaceFilter('CompanyName@=', value);
                break;
            case this.tableColumns.currentInterval.field:
                this.replaceFilter('ConfigInterval@=', value);
                break;
            case this.tableColumns.macAddress.field:
                this.replaceFilter('MacAddress@=', value);
                break;
            case this.tableColumns.fwVersion.field:
                this.replaceFilter('FirmwareVersion@=', value);
                break;
            case this.tableColumns.description.field:
                this.replaceFilter('Description@=', value);
                break;
            case this.tableColumns.isOffline.field:
                this.replaceFilter('IsOffline==', value);
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange(this.pagination, this.sort);
            }
        });
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    changeRouterUrl(pagination, sort, currentFilter) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            Filter: JSON.stringify(currentFilter.filter((x) => x != null))
        };
        let objStringAdminDevice = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromDevice', objStringAdminDevice);
    }
    configureSelectedDevices() {
        this.isConfigModalActive = true;
    }
    configureDevice(device) {
        this.selectedDevices = [device];
        this.isConfigModalActive = true;
    }
    closeConfigureModal() {
        this.isConfigModalActive = false;
    }
    async configured() {
        this.isConfigModalActive = false;
        this.selectedDevices = new Array();
        this.$emit('reloadDevices', this.pagination, this.sort, this.currentFilter);
    }
    closeDeviceUpdateModal() {
        this.isDeviceUpdateModalActive = false;
    }
    openDeviceMonitor() {
        this.$emit('openDeviceMonitor');
    }
    clearFilter() {
        this.nameSearch = '';
        this.companyNameSearch = '';
        this.configIntervalSearch = '';
        this.idSearch = '';
        this.macSearch = '';
        this.firmwareVersionSearch = '';
        this.descriptionSearch = '';
        this.offlineSearch = null;
        this.currentFilter = [];
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isNameSearch = false;
        this.isCompanyNameSearch = false;
        this.isConfigIntervalSearch = false;
        this.isMacAddressSearch = false;
        this.isFirmwareVersionSearch = false;
        this.isDescriptionSearch = false;
        this.isOfflineSearch = false;
        this.isIdSearch = false;
        switch (event) {
            case this.tableColumns.serialName.field:
                this.isNameSearch = true;
                break;
            case this.tableColumns.deviceId.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.company.field:
                this.isCompanyNameSearch = true;
                break;
            case this.tableColumns.currentInterval.field:
                this.isConfigIntervalSearch = true;
                break;
            case this.tableColumns.fwVersion.field:
                this.isFirmwareVersionSearch = true;
                break;
            case this.tableColumns.macAddress.field:
                this.isMacAddressSearch = true;
                break;
            case this.tableColumns.description.field:
                this.isDescriptionSearch = true;
                break;
            case this.tableColumns.isOffline.field:
                this.isOfflineSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.serialName.field;
        this.changeSearchField(this.tableColumns.serialName.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.deviceId.field:
                if (!event) {
                    this.idSearch = '';
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.deviceId.field, this.idSearch);
                    if (this.sort.field == this.tableColumns.deviceId.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDeviceId, event);
                break;
            case this.tableColumns.macAddress.field:
                if (!event) {
                    this.macSearch = '';
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.macAddress.field, this.macSearch);
                    if (this.sort.field == this.tableColumns.macAddress.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListMacAddress, event);
                break;
            case this.tableColumns.company.field:
                if (!event) {
                    this.companyNameSearch = '';
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.company.field, this.companyNameSearch);
                    if (this.sort.field == this.tableColumns.company.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListCompany, event);
                break;
            case this.tableColumns.currentInterval.field:
                if (!event) {
                    this.configIntervalSearch = '';
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.currentInterval.field, this.configIntervalSearch);
                    if (this.sort.field == this.tableColumns.currentInterval.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListInterval, event);
                break;
            case this.tableColumns.fwVersion.field:
                if (!event) {
                    this.firmwareVersionSearch = '';
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.fwVersion.field, this.firmwareVersionSearch);
                    if (this.sort.field == this.tableColumns.fwVersion.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListVersion, event);
                break;
            case this.tableColumns.lastSeen.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.lastSeen.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListLastSeen, event);
                break;
            case this.tableColumns.description.field:
                if (!event) {
                    this.descriptionSearch = '';
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.description.field, this.descriptionSearch);
                    if (this.sort.field == this.tableColumns.description.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDescription, event);
                break;
            case this.tableColumns.isOffline.field:
                if (!event) {
                    this.offlineSearch = null;
                    this.changeDefaultField();
                    this.filterDevices(this.tableColumns.isOffline.field, '');
                    if (this.sort.field == this.tableColumns.description.field)
                        this.sort.field = this.tableColumns.serialName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListIsOffline, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminDeviceList.prototype, "deviceList", void 0);
__decorate([
    Prop({ type: Array })
], AdminDeviceList.prototype, "deviceFirmwareList", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], AdminDeviceList.prototype, "totalSize", void 0);
AdminDeviceList = __decorate([
    Component({
        components: {
            AdminConfigureDeviceModal,
            DeviceUpdateModal,
            ActivateDeviceModal,
            PublishDeviceModal,
            RegisterToCompany,
            DeviceMonitor,
            KeyUpdateModal
        }
    })
], AdminDeviceList);
export default AdminDeviceList;

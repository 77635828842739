var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "is-flex is-flex-direction-column" }, [
    _c(
      "div",
      {
        staticClass:
          "is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-flex-end",
      },
      [
        _c(
          "div",
          {
            staticClass: "is-flex is-flex-wrap-wrap notification-table-mobile",
          },
          [
            !_vm.inNotificationDepartmentPage
              ? _c(
                  "b-field",
                  {
                    staticClass: "mr-2-tablet",
                    attrs: {
                      label: _vm.$t(
                        "component.companyAdministration.changeName.companyName"
                      ),
                      "custom-class": "is-hidden-mobile",
                    },
                  },
                  [
                    _c("b-autocomplete", {
                      attrs: {
                        data: _vm.filteredDepartments,
                        field: "name",
                        placeholder: _vm.$t(
                          "component.companyAdministration.changeName.companyName"
                        ),
                        "icon-pack": "fas",
                        icon: "search",
                        clearable: "",
                        "open-on-focus": "",
                      },
                      on: { select: _vm.departmentNameChanged },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3307844859
                      ),
                      model: {
                        value: _vm.selectedDepartmentName,
                        callback: function ($$v) {
                          _vm.selectedDepartmentName = $$v
                        },
                        expression: "selectedDepartmentName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-field",
              {
                staticClass: "mr-2-tablet",
                attrs: {
                  label: _vm.$t("notifications.date_range"),
                  "custom-class": "is-hidden-mobile",
                },
              },
              [
                _c("b-datepicker", {
                  attrs: {
                    placeholder: _vm.$t("notifications.date_range"),
                    name: _vm.$t("notifications.date_range"),
                    "mobile-native": false,
                    range: "",
                    "date-formatter": function (date) {
                      return _vm.dateRange.length > 0
                        ? _vm.dateTimeManager.formatTimeRangeInInput(date)
                        : null
                    },
                    "nearby-selectable-month-days": true,
                    "max-date": _vm.today,
                    "first-day-of-week": 1,
                  },
                  on: { input: _vm.dateRangeChanged },
                  model: {
                    value: _vm.selectedDateRange,
                    callback: function ($$v) {
                      _vm.selectedDateRange = $$v
                    },
                    expression: "selectedDateRange",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  label: _vm.$t("notifications.category_filter.title"),
                  "custom-class": "is-hidden-mobile",
                },
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      placeholder: _vm.$t(
                        "notifications.category_filter.select_placeholder"
                      ),
                    },
                    on: { input: _vm.categorySelected },
                    model: {
                      value: _vm.currentlySelectedCategory,
                      callback: function ($$v) {
                        _vm.currentlySelectedCategory = $$v
                      },
                      expression: "currentlySelectedCategory",
                    },
                  },
                  _vm._l(_vm.availableCategoriesToFilter, function (category) {
                    return _c(
                      "option",
                      {
                        key: category.name,
                        attrs: {
                          disabled: _vm.categoryAlreadySelected(category),
                        },
                        domProps: { value: category },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.notifications_list.category." +
                                  category.name
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "buttons mb-1 is-hidden-mobile" },
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.clearFilter()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
            ),
            _c(
              "b-dropdown",
              {
                attrs: { position: "is-bottom-left", "aria-role": "list" },
                scopedSlots: _vm._u([
                  {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.company.list.actions.columns"
                              ),
                              position: "is-left",
                              type: "is-dark",
                            },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "is-dark-grey",
                                staticStyle: { "font-size": "1rem" },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { type: "is-primary", icon: "list" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              _vm._l(_vm.tableColumns, function (column) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: column.field,
                    staticClass: "custom-dropdown-item",
                    attrs: {
                      focusable: false,
                      custom: "",
                      "aria-role": "listitem",
                    },
                  },
                  [
                    _c(
                      "b-checkbox",
                      {
                        attrs: {
                          disabled:
                            column.field == _vm.tableColumns.title.field ||
                            column.field == _vm.tableColumns.createdAt.field,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.chooseTableColumns(column.field, $event)
                          },
                        },
                        model: {
                          value: column.visible,
                          callback: function ($$v) {
                            _vm.$set(column, "visible", $$v)
                          },
                          expression: "column.visible",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.notifications_list.table." +
                                  column.field
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm.selectedCategories.length > 0
      ? _c(
          "div",
          { staticClass: "is-flex is-flex-wrap-wrap line-height-2" },
          [
            _c("p", { staticClass: "pr-2 has-text-grey is-size-6" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("notifications.category_filter.filtered_by")) +
                  " "
              ),
            ]),
            _vm._l(_vm.selectedCategories, function (category) {
              return _c(
                "div",
                { key: category.name, staticClass: "pr-1" },
                [
                  _c(
                    "b-tag",
                    {
                      attrs: { rounded: "", type: category.type, closable: "" },
                      on: {
                        close: function ($event) {
                          return _vm.categoryRemoved(category)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.category." +
                              category.name
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "buttons my-2 is-hidden-tablet" },
      [
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.clearFilter()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
        ),
        _c(
          "b-dropdown",
          {
            attrs: { position: "is-bottom-left", "aria-role": "list" },
            scopedSlots: _vm._u([
              {
                key: "trigger",
                fn: function () {
                  return [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          label: _vm.$t(
                            "admin.component.company.list.actions.columns"
                          ),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "is-dark-grey",
                            staticStyle: { "font-size": "1rem" },
                          },
                          [
                            _c("b-icon", {
                              attrs: { type: "is-primary", icon: "list" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          _vm._l(_vm.tableColumns, function (column) {
            return _c(
              "b-dropdown-item",
              {
                key: column.field,
                staticClass: "custom-dropdown-item",
                attrs: {
                  focusable: false,
                  custom: "",
                  "aria-role": "listitem",
                },
              },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: {
                      disabled:
                        column.field == _vm.tableColumns.title.field ||
                        column.field == _vm.tableColumns.createdAt.field,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.chooseTableColumns(column.field, $event)
                      },
                    },
                    model: {
                      value: column.visible,
                      callback: function ($$v) {
                        _vm.$set(column, "visible", $$v)
                      },
                      expression: "column.visible",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table." + column.field
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("component.report.delete_annotation.title")) + " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c("h2", { staticClass: "title has-text-centered" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.report.delete_annotation.confirm")) +
              " "
          ),
        ]),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("component.report.delete_annotation.range"),
            },
          },
          [_c("p", [_vm._v(" " + _vm._s(_vm.timeRange) + " ")])]
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("component.report.delete_annotation.text"),
            },
          },
          [_c("p", [_vm._v(_vm._s(_vm.annotation.text))])]
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.deleteAnnotation()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.delete")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
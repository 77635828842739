var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "multi-header mb-4" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("loggers.sourceChanges.title")) + " "),
        ]),
      ]),
      _c(
        "section",
        [
          _c(
            "b-table",
            {
              ref: "table",
              attrs: {
                data: _vm.groupedSourceChanges,
                striped: "",
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
              },
            },
            [
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  field: "serialNumber",
                  label: _vm.$t("loggers.sourceChanges.table.serialNumber"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (sourceChanges) {
                      return [
                        _vm._v(
                          " " + _vm._s(sourceChanges.row.serialNumber) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  field: "assigned.changedAt",
                  label: _vm.$t("loggers.sourceChanges.table.assigned"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (sourceChanges) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(
                                sourceChanges.row.assigned.changedAt * 1000
                              ),
                              null,
                              null,
                              null
                            )
                          ) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  field: "assigned.issuer.fullName",
                  label: _vm.$t("loggers.sourceChanges.table.assignedBy"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (sourceChanges) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(sourceChanges.row.assigned.issuer.fullName) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  field: "unassigned.changedAt",
                  label: _vm.$t("loggers.sourceChanges.table.unassigned"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (sourceChanges) {
                      return [
                        sourceChanges.row.unassigned
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dateTimeManager.formatTime(
                                      new Date(
                                        sourceChanges.row.unassigned.changedAt *
                                          1000
                                      ),
                                      null,
                                      null,
                                      null
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  field: "unassigned.issuer.fullName",
                  label: _vm.$t("loggers.sourceChanges.table.unassignedBy"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (sourceChanges) {
                      return [
                        sourceChanges.row.unassigned
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    sourceChanges.row.unassigned.issuer.fullName
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "assigned.logger.calibration.status.name",
                  label: _vm.$t("loggers.calibration"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (sourceChanges) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-align-items-center ml-3-mobile",
                          },
                          [
                            sourceChanges.row.assigned.logger &&
                            sourceChanges.row.assigned.logger.calibration
                              ? _c(
                                  "b-tag",
                                  {
                                    attrs: {
                                      rounded: "",
                                      type: sourceChanges.row.assigned.logger
                                        .calibration.status.type,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "calibration_status." +
                                            sourceChanges.row.assigned.logger
                                              .calibration.status.name
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-tag",
                                  {
                                    attrs: {
                                      type: _vm.CalibrationStatus.UNKNOWN.type,
                                      rounded: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "calibration_status." +
                                            _vm.CalibrationStatus.UNKNOWN.name
                                        )
                                      )
                                    ),
                                  ]
                                ),
                            _vm.$ability.can(
                              _vm.$permActions.READ,
                              _vm.$permSubjects.LOGGER
                            ) &&
                            _vm.hasCalibrationProtocolToDownload(
                              sourceChanges.row.assigned.logger
                            )
                              ? _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t("generals.download"),
                                      position: "is-left",
                                      type: "is-light",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "control-icon clickable",
                                      attrs: { icon: "file-download" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.downloadCalibration(
                                            sourceChanges.row.assigned.logger
                                              .companyLoggerId
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("loggers.sourceChanges.table.empty")) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
const default_layout = 'default';
import { Component, Vue } from 'vue-property-decorator';
import CookiesBar from '@/views/components/cookies/CookiesBar.vue';
import AppConfig from '@/configLoader';
import { bootstrap } from 'vue-gtag';
import { CookieClaimManager } from '@/services/CookieClaimManager';
//firebase
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
var cookieClaimManager;
let App = class App extends Vue {
    get layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
    }
    get hasCookiesAllowed() {
        return AppConfig.getConfig().features.cookies_bar == true;
    }
    get hasAnalyticsAllowed() {
        return AppConfig.getConfig().google_analytics.allowed == true;
    }
    get hasFirebaseAllowed() {
        return AppConfig.getConfig().firebase.allowed == true;
    }
    get firebaseConfiguration() {
        return AppConfig.getConfig().firebase.config;
    }
    get hasAnalyticsAllowedOnFlutter() {
        if (this.isFlutter && AppConfig.getConfig().features.flutter_check == true)
            return false;
        else
            return true;
    }
    get isFlutter() {
        let platform = this.$cookies.get('platform');
        return platform == 'flutter';
    }
    created() {
        cookieClaimManager = new CookieClaimManager(this);
        if (cookieClaimManager.canUseAnalytics()) {
            if (this.hasAnalyticsAllowed && this.hasAnalyticsAllowedOnFlutter) {
                this.enablePlugin();
            }
            if (this.hasFirebaseAllowed) {
                // Initialize Firebase
                const app = initializeApp(this.firebaseConfiguration);
                // Initialize Performance Monitoring and get a reference to the service
                const perf = getPerformance(app);
            }
        }
    }
    enablePlugin() {
        bootstrap().then((gtag) => {
            this.$gtag.optIn();
        });
    }
};
App = __decorate([
    Component({ components: { CookiesBar } })
], App);
export default App;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import QRCodeScanner from '@/views/components/common/QRCodeScanner.vue';
import VueUtilities from '@/services/VueUtilities';
import QRCodeFromFile from '../../common/QRCodeFromFile.vue';
let AdminRegisterLoggersQrParser = class AdminRegisterLoggersQrParser extends Vue {
    constructor() {
        super(...arguments);
        this.lastScannedCode = '';
    }
    parseData(dataStream) {
        let loggerArray = [];
        dataStream.forEach((code) => {
            if (this.lastScannedCode === code.dataStream) {
                return;
            }
            this.lastScannedCode = code.dataStream;
            const correctedData = code.dataStream.replace(/“|”/g, '"').replace(/(\w+):/g, '"$1":');
            try {
                let parsedData = JSON.parse(correctedData);
                if (!this.validateScannedLoggers(parsedData)) {
                    throw new Error(this.getUnknownQRErrorMessage(code.fileName));
                }
                if (!this.scannedLoggers.find((x) => x.sn === parsedData.sn && x.key === parsedData.key)) {
                    loggerArray.push(parsedData);
                    VueUtilities.openSuccessToast(this, this.$t('component.qr_scanner.code_loaded', { fileName: code.fileName }).toString());
                }
            }
            catch (err) {
                VueUtilities.openErrorToast(this, this.getUnknownQRErrorMessage(code.fileName));
            }
        });
        this.$emit('dataUpdate', loggerArray);
    }
    removeFromList(serialNumberToBeDeleted) {
        this.$emit('removeFromList', serialNumberToBeDeleted);
    }
    setScanMethod(method) {
        this.$emit('setScanMethod', method);
    }
    validateScannedLoggers(data) {
        return typeof data.sn === 'string' && typeof data.key === 'string';
    }
    getUnknownQRErrorMessage(fileName) {
        if (fileName) {
            return this.$t('component.qr_scanner.parsing_error_from_file', { fileName: fileName }).toString();
        }
        else {
            return this.$t('component.qr_scanner.parsing_error', { fileName: fileName }).toString();
        }
    }
};
__decorate([
    Prop({ type: String })
], AdminRegisterLoggersQrParser.prototype, "scanningMethod", void 0);
__decorate([
    Prop({ type: Array })
], AdminRegisterLoggersQrParser.prototype, "scannedLoggers", void 0);
AdminRegisterLoggersQrParser = __decorate([
    Component({
        components: {
            QRCodeScanner,
            QRCodeFromFile
        }
    })
], AdminRegisterLoggersQrParser);
export default AdminRegisterLoggersQrParser;

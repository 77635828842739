var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("component.notifications_list.title")) + " "
          ),
        ]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _vm.pauseComponentVisible
                  ? _c("PauseNotificationsMessage", {
                      staticClass: "tile is-child",
                      on: { reloadPause: _vm.reloadNotificationPause },
                    })
                  : _vm._e(),
                !_vm.isLoading && _vm.notificationsPaused
                  ? _c(
                      "b-message",
                      {
                        staticClass: "tile is-child",
                        attrs: { type: "is-warning" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.notification.pause.paused_msg",
                                {
                                  time: _vm.dateTimeManager.formatTime(
                                    new Date(
                                      _vm.pauseNotificationsUntil * 1000
                                    ),
                                    null,
                                    "",
                                    null
                                  ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "tile is-parent" }, [
          _c(
            "section",
            { staticClass: "box tile is-child" },
            [
              _c("NotificationsTable", {
                attrs: {
                  notifications: _vm.notificationsResult,
                  dateTimeManager: _vm.dateTimeManager,
                  departmentFilter: null,
                  canDelete: true,
                  inNotificationDepartmentPage: false,
                },
                on: {
                  notificationRemoved: _vm.notificationRemoved,
                  onOptionsChange: _vm.onOptionsChange,
                  loadNotifications: _vm.loadNotifications,
                  removeAllNotifications: _vm.removeAllNotifications,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
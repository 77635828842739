import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import AuthenticationCalls from '@/services/AuthenticationCalls';
var authenticationCalls;
let ForgotPassword = class ForgotPassword extends Vue {
    constructor() {
        super(...arguments);
        this.askForEmail = true;
        this.email = '';
        this.sent = false;
    }
    created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
    }
    forgotPassword() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let success = await authenticationCalls.passwordForgot(this.email);
                if (success) {
                    this.sent = true;
                    this.askForEmail = false;
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.password_recover.forgot_failure', {
                        email: this.email
                    }).toString());
                }
            }
        });
    }
};
ForgotPassword = __decorate([
    Component({})
], ForgotPassword);
export default ForgotPassword;

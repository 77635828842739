import { TierFeature } from './TierFeature';
export class UsersTier {
    constructor() {
        this.features = new Array();
    }
    sortFeaturesByName() {
        this.features = this.features.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => UsersTier.define(et));
        }
        else if (data != null) {
            return UsersTier.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let tier = new UsersTier();
        tier.activatedAt = data.ActivatedAt;
        tier.name = data.Name;
        tier.deactivatedAt = data.DeactivatedAt;
        tier.tier = data.Tier;
        tier.features = data.Features;
        tier.enabledFeatures = TierFeature.filterEnabledFeaturesByValue(data.Features);
        tier.sortFeaturesByName();
        return tier;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(_vm._s(_vm.$t("users.change_user_role.title"))),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body has-text-centered" },
      [
        _c(
          "p",
          {
            staticClass:
              "has-text-centered has-text-primary has-text-weight-semibold",
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("users.change_user_role.message", {
                    username: _vm.user.username,
                  })
                ) +
                " "
            ),
          ]
        ),
        _c(
          "p",
          {
            staticClass:
              "has-text-centered has-text-primary has-text-weight-semibold",
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("users.change_user_role.actual_role", {
                    role: _vm.user.role.Key,
                  })
                ) +
                " "
            ),
          ]
        ),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("users.change_user_role.new_user_role"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("users.change_user_role.new_user_role")
                ),
              },
            },
          },
          [
            _c(
              "b-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t("users.change_user_role.new_user_role"),
                  message: _vm.errors.first(
                    _vm.$t("users.change_user_role.new_user_role")
                  ),
                },
                model: {
                  value: _vm.newRole,
                  callback: function ($$v) {
                    _vm.newRole = $$v
                  },
                  expression: "newRole",
                },
              },
              _vm._l(_vm.availableUserRoles, function (role) {
                return _c("option", { key: role, domProps: { value: role } }, [
                  _vm._v(" " + _vm._s(_vm.$t("users.roles." + role)) + " "),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.$t("component.userAdministration.list.disable.deny"))
            ),
          ]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("users.change_user_role.save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
let FeaturesList = class FeaturesList extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.FeaturesSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.FeaturesSortField.defaultValue;
    }
    created() { }
};
__decorate([
    Prop({ type: Array })
], FeaturesList.prototype, "features", void 0);
FeaturesList = __decorate([
    Component({})
], FeaturesList);
export default FeaturesList;

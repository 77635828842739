var NotificationPresetType;
(function (NotificationPresetType) {
    NotificationPresetType["CUSTOMER"] = "Customer";
    NotificationPresetType["ADMIN"] = "Admin";
    NotificationPresetType["DEPARTMENT"] = "Department";
})(NotificationPresetType || (NotificationPresetType = {}));
export function getNotificationPresetTypes() {
    return [NotificationPresetType.ADMIN, NotificationPresetType.CUSTOMER];
}
export default NotificationPresetType;

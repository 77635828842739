import User from '@/entities/User';
export default class DepartmentIssue {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DepartmentIssue.define(et));
        }
        else if (data != null) {
            return DepartmentIssue.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let issue = new DepartmentIssue();
        issue.id = data.Id;
        issue.createdAt = data.CreatedAt;
        issue.startedAt = data.StartedAt;
        issue.updatedAt = data.UpdatedAt;
        issue.acknowledgedAt = data.AcknowledgedAt;
        issue.resolvedAt = data.ResolvedAt;
        if (data.AcknowledgedBy)
            issue.acknowledgedBy = User.fromApi(data.AcknowledgedBy);
        issue.type = data.Type;
        issue.metadata = data.Metadata;
        return issue;
    }
}

import axios from 'axios';
import merge from 'lodash.merge';
var defaultConfig;
var localConfig;
class ConfigLoader {
    constructor() {
        this.runnerDefinedApiUrl = process.env.VUE_APP_API_URL;
        this.origin = window.location.origin;
        this.config = {};
    }
    async loadConfig() {
        let promises = [];
        let currentTimestamp = new Date().getTime();
        promises.push(this.loadFile(this.origin + '/config/app.default.json?nocache=' + currentTimestamp));
        promises.push(this.loadFile(this.origin + '/config/app.local.json?nocache=' + currentTimestamp));
        return Promise.allSettled(promises).then((result) => {
            defaultConfig = result[0].value;
            localConfig = result[1].value;
            if (!(localConfig instanceof Object))
                localConfig = {};
            if (!defaultConfig && !localConfig)
                throw new Error('Configuration file is missing');
            else {
                this.config = merge(defaultConfig, localConfig);
            }
            if (this.runnerDefinedApiUrl) {
                this.config.backend.address = this.runnerDefinedApiUrl;
            }
        });
    }
    async loadFile(path) {
        return await axios
            .get(path)
            .then((response) => {
            return response.data;
        })
            .catch((err) => {
            return {};
        });
    }
    getConfig() {
        return this.config;
    }
}
export default new ConfigLoader();

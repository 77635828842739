export var MenuCategoryKeys;
(function (MenuCategoryKeys) {
    MenuCategoryKeys["DASHBOARD"] = "dashboard";
    MenuCategoryKeys["GROUP_DASHBOARD"] = "groupDashboard";
    MenuCategoryKeys["REPORTS"] = "reports";
    MenuCategoryKeys["NOTIFICATIONS"] = "notifications";
    MenuCategoryKeys["NOTIFICATION_ORG"] = "notificationsOrg";
    MenuCategoryKeys["SETTINGS"] = "settings";
    MenuCategoryKeys["WIZARD"] = "wizard";
    MenuCategoryKeys["ABOUT"] = "about";
    MenuCategoryKeys["FEEDBACK"] = "feedback";
    MenuCategoryKeys["PROFILE"] = "profile";
    MenuCategoryKeys["MAIN_ORG"] = "organizations";
    MenuCategoryKeys["LOG_OUT"] = "logOut";
})(MenuCategoryKeys || (MenuCategoryKeys = {}));

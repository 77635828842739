import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import EventsTableItem from '@/views/components/events/EventsTableItem.vue';
import DecimalConversion from '@/services/DecimalConversion';
import EventType from '@/entities/EventType';
import { ServiceState } from '@/entities/enums/ServiceState';
const UPTIME_ID = EventType.UPTIME_ID;
let EventsTable = class EventsTable extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.ServiceState = ServiceState;
        this.UPTIME_ID = UPTIME_ID;
    }
    getTimeDifference(date) {
        let timeUnit = this.dateTimeManager.getTimeDifference(date);
        return `${this.$t('component.last_events.table.before', {
            time: timeUnit.time,
            part: this.$t('component.last_events.table.timeUnits.' + timeUnit.unit)
        })}.
    `;
    }
    isOneDay(date) {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (date.getTime() > yesterday.getTime()) {
            return true;
        }
        else {
            return false;
        }
    }
};
__decorate([
    Prop({ type: Object })
], EventsTable.prototype, "data", void 0);
__decorate([
    Prop({ type: Object })
], EventsTable.prototype, "dateTimeManager", void 0);
EventsTable = __decorate([
    Component({ components: { EventsTableItem } })
], EventsTable);
export default EventsTable;

import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import { Logger } from '@/entities/models/Logger';
import FileDownloader from '../FileDownloader';
import ApiStatus from '@/entities/enums/apiStatuses';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiResponse from '@/entities/ApiResponse';
const DEFAULT_CSV_FILENAME = 'stickers.csv';
export default class LoggerRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getAdminLoggers(pagination = null, sort = null, filter = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_LOGGERS)) {
            return parsedResponse;
        }
        let url = ApiLinks.Loggers.AdminHome;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(Logger.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async getLoggersCalibrationExpires(pagination = null, sort = null, filter = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_LOGGERS)) {
            return parsedResponse;
        }
        let url = ApiLinks.Loggers.AdminHome;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            //Create response with pagination data
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(Logger.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async adminDeleteLogger(loggerId) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_LOGGERS)) {
            return false;
        }
        let url = `${ApiLinks.Loggers.AdminHome}/${loggerId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    async adminPublishLogger(macAddress, serialNumber) {
        if (!this.ctx.$ability.can(Actions.PUBLISH, Subjects.ADMIN_LOGGERS)) {
            return false;
        }
        let url = `${ApiLinks.Loggers.AdminHome}${ApiLinks.Loggers.Keys.Publish}`;
        let body = {
            SerialNumber: serialNumber,
            MacAddress: macAddress
        };
        let response = await AxiosService.postDataToServer(url, body, this.ctx);
        if (response.status != ApiStatus.CREATED) {
            return false;
        }
        else
            return true;
    }
    async getAdminLogger(loggerId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_LOGGERS)) {
            return new Logger();
        }
        let url = `${ApiLinks.Loggers.AdminHome}/${loggerId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Logger();
        else
            return Logger.fromApi(response.data.data);
    }
    async postAdminLoggerCSV(prefix, countToGenerate, startNumber, isCustom, stickerLine1, stickerLine2) {
        if (!this.ctx.$ability.can(Actions.PUBLISH, Subjects.ADMIN_LOGGERS)) {
            throw 'Forbidden action postAdminLoggerCSV';
        }
        let data = new FormData();
        data.append('prefix', prefix);
        data.append('countToGenerate', countToGenerate.toString());
        data.append('custom', isCustom.toString());
        data.append('stickerLine1', stickerLine1);
        data.append('stickerLine2', stickerLine2);
        if (isCustom)
            data.append('startNumber', startNumber.toString());
        let url = `${ApiLinks.Loggers.CSVStrickers}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if ((response.status = ApiStatus.SUCCESS)) {
            FileDownloader.downloadFile(response, DEFAULT_CSV_FILENAME);
            return true;
        }
        else
            return false;
    }
    async adminCreateLogger(loggerDto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_LOGGERS)) {
            return false;
        }
        let url = `${ApiLinks.Loggers.AdminHome}`;
        let response = await AxiosService.postDataToServer(url, loggerDto, this.ctx, false);
        if (response.status == ApiStatus.CREATED)
            return true;
        else
            return response.data;
    }
    async adminEditLogger(loggerId, note) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_LOGGERS)) {
            return null;
        }
        let url = `${ApiLinks.Loggers.AdminHome}/${loggerId}`;
        let queries = new ApiQueries();
        queries.note = note;
        url += `?${queries.toQueryString()}`;
        let response = await AxiosService.putDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Logger();
        else
            return Logger.fromApi(response.data.data);
    }
    async getAdminLoggerChartData(companyLoggerId, from) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_COMPANYLOGGERS)) {
            return {};
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Data}`;
        let queries = new ApiQueries();
        if (from)
            queries.from = from;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return response.data.data;
    }
}

var NotificationChannelType;
(function (NotificationChannelType) {
    NotificationChannelType["SYSTEM"] = "system";
    NotificationChannelType["EMAIL"] = "email";
    NotificationChannelType["SMS"] = "sms";
    NotificationChannelType["CALL"] = "call";
    NotificationChannelType["FCM"] = "fcm";
    NotificationChannelType["BUZZER"] = "buzzer";
})(NotificationChannelType || (NotificationChannelType = {}));
export default NotificationChannelType;

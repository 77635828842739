import { __decorate } from "tslib";
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import { Component, Vue, Prop } from 'vue-property-decorator';
let ChangeUserRoleModal = class ChangeUserRoleModal extends Vue {
    constructor() {
        super(...arguments);
        this.ApiUserRoles = ApiUserRoles;
        this.newRole = null;
    }
    created() {
        this.newRole =
            this.user.role.Key == ApiUserRoles.COMPANY_USER ? ApiUserRoles.COMPANY_ADMIN : ApiUserRoles.COMPANY_USER;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    save() {
        this.$emit('changeRole', this.newRole, this.user);
    }
};
__decorate([
    Prop({ type: Object })
], ChangeUserRoleModal.prototype, "user", void 0);
ChangeUserRoleModal = __decorate([
    Component({ components: {} })
], ChangeUserRoleModal);
export default ChangeUserRoleModal;

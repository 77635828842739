import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminResellerList = class AdminResellerList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.selectedValue = '';
        this.isNameSearch = true;
        this.isIdSearch = false;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.total = 0;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.tableColumns = {
            id: {
                field: 'id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminResellerListId),
                hasSearch: true
            },
            name: {
                field: 'name',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminResellerListName),
                hasSearch: true
            },
            userCount: {
                field: 'userCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminResellerListUserCount),
                hasSearch: false
            },
            departmentCount: {
                field: 'departmentCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminResellerListDepartmentCount),
                hasSearch: false
            },
            gatewayCount: {
                field: 'gatewayCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminResellerListGatewayCount),
                hasSearch: false
            },
            loggerCount: {
                field: 'loggerCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminResellerListLoggerCount),
                hasSearch: false
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    created() {
        this.selectedValue = this.tableColumns.name.field;
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    mounted() {
        this.fillTableFilters();
        this.$nextTick(() => {
            let allData = this.$refs.table.newData;
            this.total = allData.length;
            this.$watch(() => this.$refs.table.filters, () => {
                let filteredData = this.$refs.table.newData;
                this.total = filteredData.length;
            }, { deep: true });
        });
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminReseller);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            // send queries to parent component
            let objStringAdminReseller = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromReseller', objStringAdminReseller);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminResellerSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminResellerSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminResellerPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminResellerRowsPerPage.defaultValue;
    }
    fillTableFilters() {
        if (this.tableQueriesfromUrl) {
            this.$set(this.$refs.table.filters, 'id', this.tableQueriesfromUrl.sId);
            this.$set(this.$refs.table.filters, 'name', this.tableQueriesfromUrl.sName);
        }
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    changeRouteUrl() {
        this.changeRouterUrl(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    changeRouterUrl(pagination, sort) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            sId: this.$refs.table.filters.id,
            sName: this.$refs.table.filters.name
        };
        let objStringAdminReseller = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromReseller', objStringAdminReseller);
    }
    clearFilter() {
        this.$refs.table.filters = {};
        this.page = 1;
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isNameSearch = false;
        this.isIdSearch = false;
        if (event == this.tableColumns.name.field) {
            this.isNameSearch = true;
            this.clearFilter();
        }
        else if (event == this.tableColumns.id.field) {
            this.isIdSearch = true;
            this.clearFilter();
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.name.field;
        this.changeSearchField(this.tableColumns.name.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.id.field:
                if (!event) {
                    this.$refs.table.filters.id = '';
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.id.field)
                        this.sort.field = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminResellerListId, event);
                break;
            case this.tableColumns.userCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.userCount.field)
                        this.sort.field = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminResellerListUserCount, event);
                break;
            case this.tableColumns.departmentCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.departmentCount.field)
                        this.sort.field = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminResellerListDepartmentCount, event);
                break;
            case this.tableColumns.gatewayCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.gatewayCount.field)
                        this.sort.field = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminResellerListGatewayCount, event);
                break;
            case this.tableColumns.loggerCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.loggerCount.field)
                        this.sort.field = this.tableColumns.name.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminResellerListLoggerCount, event);
                break;
        }
        this.changeRouterUrl(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminResellerList.prototype, "resellerList", void 0);
__decorate([
    Prop({ type: Object })
], AdminResellerList.prototype, "dateTimeManager", void 0);
AdminResellerList = __decorate([
    Component({})
], AdminResellerList);
export default AdminResellerList;

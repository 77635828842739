import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import LoggedUserManager from '@/services/LoggedUserManager';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminCompanyList = class AdminCompanyList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.isModalActive = false;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.selectedCompanyToLogin = null;
        this.selectedValue = '';
        this.isNameSearch = true;
        this.isTierSearch = false;
        this.isIdSearch = false;
        this.isIcoSearch = false;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.total = 0;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.tableColumns = {
            companyId: {
                field: 'companyId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyId),
                hasSearch: true
            },
            companyName: {
                field: 'companyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyName),
                hasSearch: true
            },
            ico: {
                field: 'ico',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListIco),
                hasSearch: true
            },
            note: {
                field: 'note',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListNote)
            },
            tierName: {
                field: 'tierName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTierName),
                hasSearch: true
            },
            userCount: {
                field: 'userCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListUserCount),
                hasSearch: false
            },
            deviceCount: {
                field: 'deviceCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListDeviceCount),
                hasSearch: false
            },
            loggerCount: {
                field: 'loggerCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListLoggerCount),
                hasSearch: false
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCreatedAt),
                hasSearch: false
            }
        };
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    created() {
        //remove note field for reseller
        if (this.isResellerAdmin) {
            delete this.tableColumns.note;
        }
        this.selectedValue = this.tableColumns.companyName.field;
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    mounted() {
        this.fillTableFilters();
        this.$nextTick(() => {
            let allData = this.$refs.table.newData;
            this.total = allData.length;
            this.$watch(() => this.$refs.table.filters, () => {
                let filteredData = this.$refs.table.newData;
                this.total = filteredData.length;
            }, { deep: true });
        });
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminCompany);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            // send queries to parent component
            let objStringAdminCompany = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromCompany', objStringAdminCompany);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanySortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanySortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminCompanyPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminCompanyRowsPerPage.defaultValue;
    }
    fillTableFilters() {
        if (this.tableQueriesfromUrl) {
            this.$set(this.$refs.table.filters, 'companyId', this.tableQueriesfromUrl.sId);
            this.$set(this.$refs.table.filters, 'companyName', this.tableQueriesfromUrl.sName);
            this.$set(this.$refs.table.filters, 'tierName', this.tableQueriesfromUrl.sTier);
            this.$set(this.$refs.table.filters, 'ico', this.tableQueriesfromUrl.sIco);
        }
    }
    onSort(field, order) {
        this.sortField = field;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    changeRouteUrl() {
        this.changeRouterUrl(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    loginIntoCompany(company) {
        this.selectedCompanyToLogin = company;
        this.$emit('loginIntoCompany', this.selectedCompanyToLogin, this.$route.name, this.$route.params);
    }
    changeRouterUrl(pagination, sort) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            sId: this.$refs.table.filters.companyId,
            sName: this.$refs.table.filters.companyName,
            sTier: this.$refs.table.filters.tierName,
            sIco: this.$refs.table.filters.ico
        };
        let objStringAdminCompany = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromCompany', objStringAdminCompany);
    }
    clearFilter() {
        this.$refs.table.filters = {};
        this.page = 1;
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isTierSearch = false;
        this.isNameSearch = false;
        this.isIdSearch = false;
        this.isIcoSearch = false;
        if (event == this.tableColumns.companyName.field) {
            this.isNameSearch = true;
            this.clearFilter();
        }
        else if (event == this.tableColumns.tierName.field) {
            this.isTierSearch = true;
            this.clearFilter();
        }
        else if (event == this.tableColumns.companyId.field) {
            this.isIdSearch = true;
            this.clearFilter();
        }
        else if (event == this.tableColumns.ico.field) {
            this.isIcoSearch = true;
            this.clearFilter();
        }
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.companyName.field;
        this.changeSearchField(this.tableColumns.companyName.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.companyId.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.companyId = '';
                    if (this.sort.field == this.tableColumns.companyId.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyId, event);
                break;
            case this.tableColumns.ico.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.ico = '';
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListIco, event);
                break;
            case this.tableColumns.tierName.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.tierName = '';
                    if (this.sort.field == this.tableColumns.tierName.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTierName, event);
                break;
            case this.tableColumns.userCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.userCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListUserCount, event);
                break;
            case this.tableColumns.deviceCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.deviceCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListDeviceCount, event);
                break;
            case this.tableColumns.loggerCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.loggerCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListLoggerCount, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.createdAt.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCreatedAt, event);
                break;
        }
        this.changeRouterUrl(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminCompanyList.prototype, "companies", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyList.prototype, "dateTimeManager", void 0);
AdminCompanyList = __decorate([
    Component({})
], AdminCompanyList);
export default AdminCompanyList;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AppConfig from '@/configLoader';
import UserPreferences from '@/services/UserPreferences';
import CustomSettingsModal from '@/views/components/cookies/CustomSettingsModal.vue';
import AboutModal from '@/views/components/about/AboutModal.vue';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import LoggedUserManager from '@/services/LoggedUserManager';
import SessionStorage from '@/services/repository/SessionStorage';
import { vxm } from '@/store/store.vuex';
import VueUtilities from '@/services/VueUtilities';
var authenticationCalls;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
let Footer = class Footer extends Vue {
    constructor() {
        super(...arguments);
        this.gitBranchString = BRANCH;
        this.gitVersionString = VERSION;
        this.sideBarWidth = 0;
        this.isCustomSettingsVisible = false;
        this.isAboutVisible = false;
        this.cookiesAllowed = null;
        this.isLoggedIn = null;
    }
    get branchInfo() {
        let infoArray = [];
        if (AppConfig.getConfig()?.layout?.show_branch === true)
            infoArray.push(this.gitBranchString);
        if (AppConfig.getConfig()?.layout?.show_version === true)
            infoArray.push(this.gitVersionString);
        return infoArray.join('/');
    }
    get hasCookiesAllowed() {
        return AppConfig.getConfig().features.cookies_bar == true;
    }
    get isPageAfterLogin() {
        return this.$route.meta.needsLogin == true || this.$route.name == null;
    }
    created() {
        this.$nextTick(() => {
            this.getWidth();
        });
        this.loadCookiesPreference();
        authenticationCalls = new AuthenticationCalls(this);
    }
    getWidth() {
        this.sideBarWidth = (document.getElementById('side-menu') || {}).offsetWidth;
    }
    loadCookiesPreference() {
        this.cookiesAllowed = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.CookiesAccepted);
    }
    openCookiesSettings() {
        this.isCustomSettingsVisible = true;
    }
    closeCustomSettingsModal() {
        this.isCustomSettingsVisible = false;
        this.loadCookiesPreference();
    }
    closeAboutModal() {
        this.isAboutVisible = false;
    }
    openAboutModal() {
        this.isAboutVisible = true;
    }
    async logout() {
        let success = await authenticationCalls.logout();
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            LoggedUserManager.deleteAdminLoginData(this);
            SessionStorage.clearStorageForSession();
            vxDepartmentStore.clearData();
            vxNotificationStore.clearData();
            this.$router.push({ name: 'login', params: { lang: this.$route.params.lang } }).catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
};
Footer = __decorate([
    Component({
        components: {
            CustomSettingsModal,
            AboutModal
        }
    })
], Footer);
export default Footer;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import CreateTier from '@/views/components/admin/tiers/CreateTier.vue';
import TiersRepository from '@/services/repository/TiersRepository';
import { TierFeature } from '@/entities/tiers/TierFeature';
var tiersRepository;
let AdminTierCreate = class AdminTierCreate extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.tierFeatures = [];
    }
    async created() {
        tiersRepository = new TiersRepository(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(tiersRepository.getAllFeatures());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0]);
        });
    }
    processLoadedDataForPage(tierFeatures) {
        this.tierFeatures = TierFeature.sortFeaturesByName(tierFeatures);
        this.isLoading = false;
    }
    async createTier(dto) {
        this.isLoading = true;
        let newTier = await tiersRepository.createTier(dto);
        this.isLoading = false;
        this.$router.push({ name: 'adminTiers', params: { lang: this.$route.params.lang } }).catch((err) => err);
    }
};
AdminTierCreate = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_TIERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { CreateTier }
    })
], AdminTierCreate);
export default AdminTierCreate;

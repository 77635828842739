import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import AuthenticationCalls from '@/services/AuthenticationCalls';
var authenticationCalls;
let VerifyResend = class VerifyResend extends Vue {
    constructor() {
        super(...arguments);
        this.askForEmail = true;
        this.email = '';
        this.sent = false;
    }
    created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
    }
    async resendVerificationEmail() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let success = await authenticationCalls.resendVerificationEmail(this.email);
                if (success) {
                    this.sent = true;
                    this.askForEmail = false;
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.password_recover.verification_failed').toString());
                }
            }
        });
    }
};
VerifyResend = __decorate([
    Component({})
], VerifyResend);
export default VerifyResend;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Event } from '@/entities/models/Event';
import EventDetail from '@/views/components/events/EventDetail.vue';
import { EventDetailGridSize } from '@/entities/enums/EventDetailGridSize';
import DateTimeManager from '@/services/DateTimeManager';
import EventDomain from '@/entities/enums/eventDomains';
import UserPreferences from '@/services/UserPreferences';
let LastEventsComponent = class LastEventsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DAY = 86400;
        this.gridSize = EventDetailGridSize.SMALL;
        this.dateTimeManager = null;
        this.isRefreshOn = false;
        this.outdatedDataVisible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.OutdatedGroupEventsVisible);
    }
    get inactiveLoggers() {
        return this.sourceGroupSources.length - (this.currentMeasuredEvents.length + this.oldMeasuredEvents.length);
    }
    get currentMeasuredEvents() {
        let events = this.measuredEvents;
        let arr = [];
        this.sourceGroupSources.forEach((source) => {
            let sourceEventsArr = events.filter((x) => x.measuredAt > this.deltaTime && x.source.Name === source.name);
            if (sourceEventsArr.length > 0) {
                arr.push({
                    events: sourceEventsArr,
                    source: source
                });
            }
        });
        return arr;
    }
    get oldMeasuredEvents() {
        let events = this.measuredEvents;
        let arr = [];
        this.sourceGroupSources.forEach((source) => {
            let sourceEventsArr = events.filter((x) => x.measuredAt <= this.deltaTime && x.source.Name === source.name);
            if (sourceEventsArr.length > 0) {
                arr.push({
                    events: sourceEventsArr,
                    source: source
                });
            }
        });
        return arr;
    }
    get measuredEvents() {
        return Event.sortBySourceName(this.sourceGroupEvents.filter((x) => x.eventType.domain == EventDomain.MEASURED));
    }
    get deltaTime() {
        return Math.floor(new Date().getTime() / 1000) - this.DAY;
    }
    refreshLoaded() {
        this.isRefreshOn = this.refreshLastEvents;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this, this.onPublicPage);
        this.isRefreshOn = this.refreshLastEvents;
    }
    refreshChanged(value) {
        this.$emit('refreshChanged', value);
    }
    outdatedDataCollapseChanged(isOpen) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.OutdatedGroupEventsVisible, isOpen);
    }
    scrollToChartFilter() {
        this.$emit('scrollToChartFilter');
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], LastEventsComponent.prototype, "sourceGroupEvents", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], LastEventsComponent.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: Boolean })
], LastEventsComponent.prototype, "refreshLastEvents", void 0);
__decorate([
    Prop({ type: Boolean })
], LastEventsComponent.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], LastEventsComponent.prototype, "showRefreshControls", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], LastEventsComponent.prototype, "onPublicPage", void 0);
__decorate([
    Watch('refreshLastEvents')
], LastEventsComponent.prototype, "refreshLoaded", null);
LastEventsComponent = __decorate([
    Component({
        components: {
            EventDetail
        }
    })
], LastEventsComponent);
export default LastEventsComponent;

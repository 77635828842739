class MeasurementStatuses {
    constructor() {
        this.NA = { id: 0, name: 'NotAvailable', class: 'is-light', textClass: 'has-text-light' };
        this.OK = { id: 1, name: 'Ok', class: 'is-success', textClass: 'has-text-success' };
        this.WARN = { id: 3, name: 'Warning', class: 'is-warning', textClass: 'has-text-warning' };
        this.ALARM = { id: 2, name: 'Alarm', class: 'is-danger', textClass: 'has-text-danger' };
    }
    getStatusById(id) {
        let status = Object.values(this).find((x) => x.id == id);
        if (!status)
            return this.NA;
        else
            return status;
    }
    getStatusByName(name) {
        let status = Object.values(this).find((x) => x.name == name);
        if (!status)
            return this.NA;
        else
            return status;
    }
    findMaxStatus(statuses) {
        return this.getStatusById(Math.max.apply(Math, statuses.map(function (o) {
            return o.id;
        })));
    }
}
export default new MeasurementStatuses();

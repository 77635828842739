import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import { ReportConfigurationModel } from '@/entities/models/ReportConfiguration';
import ReportingInterval, { defaultReportingIntervals, getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import StatisticsType from '@/entities/enums/statisticsType';
import AppConfig from '@/configLoader';
import VueUtilities from '@/services/VueUtilities';
let ReportConfigurationComponent = 
/**
 * Component to select report configuration.
 */
class ReportConfigurationComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DateTimeManager = DateTimeManager;
        this.StatisticsType = StatisticsType;
        this.interval = null;
        this.selectedType = StatisticsType.AVERAGE;
        this.measurementOffsetIndex = 0;
        this.allMeasuredTimes = [];
        this.offset = 0;
        this.dateRange = [];
        this.timeOfMeasurement = new Date();
        this.hourFormatInClockPicker = null;
        this.currentShowEmpty = null;
    }
    get allReportingIntervals() {
        return defaultReportingIntervals;
    }
    get hasOneDayIntervalSelected() {
        return this.interval === ReportingInterval.INTERVAL_24_00;
    }
    get canHaveOffset() {
        return this.selectedType === StatisticsType.DAILY;
    }
    get reportRange() {
        return AppConfig.getConfig().attributes?.max_report_range_days || 30;
    }
    created() {
        this.currentShowEmpty = this.showEmpty;
        this.dateRange = this.selectedDateRange;
        this.interval = this.configuration.interval;
        this.selectedType = this.configuration.getModel().model;
        if (this.configuration.getModel().model == StatisticsType.DAILY) {
            this.offset = this.configuration.getModel().modelParameters.Offset;
        }
        this.hourFormatInClockPicker = this.dateTimeManager.getSelectedClockFormat().slice(0, 2);
        this.assignMeasureTimes(this.interval).then(() => {
            this.measurementOffsetIndex = this.offset / 60;
        });
        if (this.hasOneDayIntervalSelected == true) {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMinutes(this.configuration.getModel().modelParameters.Offset);
            this.timeOfMeasurement = date;
        }
    }
    /**
     * Listener for reporting interval change
     */
    async reportingIntervalChanged(step) {
        if (this.hasOneDayIntervalSelected == true) {
            this.clockChanged(this.timeOfMeasurement);
        }
        else {
            this.measurementOffsetIndex = 0;
            this.measuredOffsetChanged();
            this.assignMeasureTimes(step);
        }
    }
    /**
     * Create array with all allowed measure times for selected interval
     */
    async assignMeasureTimes(step) {
        let allTimes = await getAllAvailableMeasureTimes(this, step, this.isPublic);
        this.allMeasuredTimes = allTimes;
    }
    /**
     * change listener for offset
     */
    measuredOffsetChanged() {
        if (this.hasOneDayIntervalSelected == true)
            return;
        this.offset = this.measurementOffsetIndex * 60;
    }
    clockChanged(value) {
        if (this.hasOneDayIntervalSelected == false)
            return;
        this.offset = value.getHours() * 60 + value.getMinutes();
    }
    typeChanged() {
        this.measurementOffsetIndex = 0;
        this.measuredOffsetChanged();
        this.assignMeasureTimes(this.interval);
    }
    /**
     * Create configuration DTO and send it with selected month to parent component
     */
    applyConfiguration() {
        let modelParams = {};
        if (this.selectedType == StatisticsType.DAILY) {
            modelParams = { Offset: this.offset };
        }
        let model = new ReportConfigurationModel();
        model.model = this.selectedType;
        model.modelParameters = modelParams;
        let configuration = {
            interval: this.interval,
            models: [model]
        };
        this.$emit('applyConfiguration', configuration, this.dateRange, this.currentShowEmpty);
    }
    rangeValueChanged() {
        let diff_Time = this.dateRange[1].getTime() - this.dateRange[0].getTime();
        let diff_Days = Math.round(diff_Time / (1000 * 3600 * 24));
        if (diff_Days > this.reportRange) {
            VueUtilities.openErrorToast(this, this.$t('component.report.configuration.modal.range_alert', {
                selectedDays: diff_Days,
                maxDays: this.reportRange
            }).toString());
            let end = new Date(this.dateRange[1].getTime());
            let start = new Date(this.dateRange[1].setDate(this.dateRange[1].getDate() - this.reportRange));
            this.dateRange = [start, end];
        }
    }
};
__decorate([
    Prop({ type: Object })
], ReportConfigurationComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], ReportConfigurationComponent.prototype, "configuration", void 0);
__decorate([
    Prop({ type: Array })
], ReportConfigurationComponent.prototype, "selectedDateRange", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ReportConfigurationComponent.prototype, "isPublic", void 0);
__decorate([
    Prop({ type: Boolean })
], ReportConfigurationComponent.prototype, "showEmpty", void 0);
ReportConfigurationComponent = __decorate([
    Component({})
    /**
     * Component to select report configuration.
     */
], ReportConfigurationComponent);
export default ReportConfigurationComponent;

import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EntitySelectionTable from '../../generals/EntitySelectionTable.vue';
import VueUtilities from '@/services/VueUtilities';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
var notificationSubscriptionsRepository;
let AssignPreset = class AssignPreset extends Vue {
    constructor() {
        super(...arguments);
        this.selectedPresetName = '';
        this.selectedPresetId = '';
        this.selectedDepartmentName = '';
        this.selectedUserName = '';
        this.selectedUserId = '';
        this.selectedDepartmentId = '';
        this.hasEditPermissions = true;
        this.filters = { Name: '' };
        this.choosedEntityColumn = 'Name';
        this.activeTab = 'nDepartments';
        this.selectedDepartments = [];
        this.selectedUsers = [];
        this.selectedPreset = null;
    }
    changeFilters() {
        this.filters = this.hasAdminOrResellerRole ? { companyName: '' } : { Name: '' };
        this.choosedEntityColumn = this.hasAdminOrResellerRole ? 'companyName' : 'Name';
    }
    get filteredDepartments() {
        return this.departments.filter((dep) => dep.companyName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.selectedDepartmentName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    get filteredUsers() {
        return this.users.filter((dep) => dep.username
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.selectedUserName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    created() {
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
    }
    presetNameChanged(preset) {
        this.selectedPresetName = preset ? preset.name : '';
        this.selectedPresetId = preset ? preset.id : null;
        this.loadDepartmentUsers();
    }
    departmentNameChanged(department) {
        this.selectedDepartmentName = department ? department.companyName : '';
        this.selectedDepartmentId = department ? department.companyId : '';
        this.loadDepartmentUsers();
    }
    userNameChanged(user) {
        this.selectedDepartmentName = user ? user.username : '';
        this.selectedUserId = user ? user.id : '';
        this.loadUserDepartments();
    }
    tabChanged(tabName) {
        this.activeTab = tabName;
        switch (tabName) {
            case 'nUsers':
                this.filters = { username: '' };
                this.choosedEntityColumn = 'username';
                break;
            case 'nDepartments':
                this.filters = this.hasAdminOrResellerRole ? { companyName: '' } : { Name: '' };
                this.choosedEntityColumn = this.hasAdminOrResellerRole ? 'companyName' : 'Name';
                break;
            default:
                this.filters = {};
                this.choosedEntityColumn = '';
                break;
        }
    }
    closeModal(val) {
        this.$emit('modalClosed', false);
    }
    loadUserDepartments() {
        if (this.selectedPresetName) {
            this.$emit('loadUserDepartments', this.selectedUserId);
        }
    }
    loadDepartmentUsers() {
        if (this.selectedPresetName) {
            this.$emit('loadDepartmentUsers', this.selectedDepartmentId);
        }
    }
    departmentSelected(selectedDepartments) {
        this.selectedDepartments = selectedDepartments;
    }
    userSelected(selectedUsers) {
        this.selectedUsers = selectedUsers;
    }
    async createFromPreset() {
        if (!this.selectedPresetId) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.preset_not_selected'));
            return;
        }
        //Load preset with subscriptions
        let response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(this.selectedPresetId);
        //handle error response
        if (typeof response == 'string') {
            VueUtilities.openErrorToast(this, response);
            return;
        }
        else {
            this.selectedPreset = response;
        }
        switch (this.activeTab) {
            case 'nUsers':
                this.assignPresetToMultipleUsers();
                break;
            case 'nDepartments':
                this.assignPresetToMultipleDepartments();
                break;
            default:
                break;
        }
    }
    assignPresetToMultipleUsers() {
        if (!this.selectedDepartmentId) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.department_not_selected'));
            return;
        }
        if (this.selectedUsers.length <= 0) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.list_not_selected'));
            return;
        }
        this.selectedUsers.forEach(async (user) => {
            let response = await notificationSubscriptionsRepository.deleteAllNotificationSubscriptions(user.apiUserId, null, this.selectedDepartmentId);
            if (response === true) {
                let rulesToCreate = [];
                this.selectedPreset.subscriptions.forEach((rule) => {
                    let newRule = {
                        ScopeType: NotificationScopeType.COMPANY,
                        ScopeId: this.selectedDepartmentId,
                        NotificationType: rule.NotificationType,
                        Enabled: true,
                        Channels: rule.Channels,
                        DisplayMode: NotificationRuleDisplayMode.BASIC,
                        PresetId: this.selectedPreset.id
                    };
                    rulesToCreate.push(newRule);
                });
                let result = await notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, user.apiUserId, this.selectedDepartmentId);
                if (result instanceof (Array)) {
                    VueUtilities.openSuccessToast(this, this.$t('component.notification.create_rule.bulk_success'));
                    this.closeModal(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                        error: result
                    }));
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                    error: response
                }));
            }
        });
    }
    assignPresetToMultipleDepartments() {
        if (!this.selectedUserId) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.user_not_selected'));
            return;
        }
        if (this.selectedDepartments.length <= 0) {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.list_not_selected'));
            return;
        }
        this.selectedDepartments.forEach(async (department) => {
            let response = await notificationSubscriptionsRepository.deleteAllNotificationSubscriptions(this.selectedUserId, null, department.Id);
            if (response === true) {
                let rulesToCreate = [];
                this.selectedPreset.subscriptions.forEach((rule) => {
                    let newRule = {
                        ScopeType: NotificationScopeType.COMPANY,
                        ScopeId: department.Id,
                        NotificationType: rule.NotificationType,
                        Enabled: true,
                        Channels: rule.Channels,
                        DisplayMode: NotificationRuleDisplayMode.BASIC,
                        PresetId: this.selectedPreset.id
                    };
                    rulesToCreate.push(newRule);
                });
                let result = await notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.selectedUserId, department.Id);
                if (result instanceof (Array)) {
                    VueUtilities.openSuccessToast(this, this.$t('component.notification.create_rule.bulk_success'));
                    this.closeModal(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                        error: result
                    }));
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                    error: response
                }));
            }
        });
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], AssignPreset.prototype, "presets", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AssignPreset.prototype, "departments", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AssignPreset.prototype, "users", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AssignPreset.prototype, "departmentUsers", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AssignPreset.prototype, "userDepartments", void 0);
__decorate([
    Prop({ type: Boolean })
], AssignPreset.prototype, "hasAdminOrResellerRole", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AssignPreset.prototype, "isLoading", void 0);
__decorate([
    Watch('hasAdminOrResellerRole')
], AssignPreset.prototype, "changeFilters", null);
AssignPreset = __decorate([
    Component({ components: { EntitySelectionTable } })
], AssignPreset);
export default AssignPreset;

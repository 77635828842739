import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { PublicDashboardOwnersModel } from '@/entities/models/PublicDashboardOwnersModel';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import LoggedUserManager from '../LoggedUserManager';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class PublicDashboardOwnerRepository {
    constructor(ctx) {
        this.ctx = ctx;
        this.isAdmin = LoggedUserManager.isAdmin(this.ctx);
    }
    async getPublicDashboardDetail(dashboardId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DASHBOARD) && !this.isAdmin) {
            throw 'Forbidden action getPublicDashboardData';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Dashboard.Keys.Dashboards}/${dashboardId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return PublicDashboardOwnersModel.fromApi(response.data.data);
    }
    async getAllPublicDashboards(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DASHBOARD) && !this.isAdmin) {
            return new Array();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Dashboard.Keys.Dashboards}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return PublicDashboardOwnersModel.fromApi(response.data.data);
    }
    async createPublicDashboard(data, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DASHBOARD) && !this.isAdmin) {
            throw 'Forbidden action createPublicDashboard';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Dashboard.Keys.Dashboards}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return null;
        else
            return response.data.data;
    }
    async updatePublicDashboard(dashboardId, data, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DASHBOARD) && !this.isAdmin) {
            throw 'Forbidden action updatePublicDashboard';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Dashboard.Keys.Dashboards}/${dashboardId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return PublicDashboardOwnersModel.fromApi(response.data.data);
    }
    async deletePublicDashboard(dashboardId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.DASHBOARD) && !this.isAdmin) {
            throw 'Forbidden action deletePublicDashboard';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Dashboard.Keys.Dashboards}/${dashboardId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.NO_CONTENT)
            return false;
        else
            return true;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}

import { __decorate } from "tslib";
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import DelayOptions from '@/entities/enums/DelayOptions';
import RepeatOptions from '@/entities/enums/RepeatOptions';
/**
 * This component is used as template for basic measured data alarm notifications management in preset
 *
 */
let MeasuredRuleTemplate = class MeasuredRuleTemplate extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationMeasuredRuleScope = NotificationMeasuredRuleScope;
        this.NotificationRuleDisplayMode = NotificationRuleDisplayMode;
        this.RepeatOptions = RepeatOptions;
        this.currentType = NotificationSubscriptionType.MEASURED_DATA_ALARM;
        this.currentEnabled = true;
        this.currentDisplayMode = NotificationRuleDisplayMode.BASIC;
        this.currentChannels = {};
        this.selectedMode = null;
        this.requestState = null;
        this.localRule = null;
    }
    /**
     * Return default settings of currently selected type (there are two types of measured alarm notifications)
     */
    get currentTypeSettings() {
        return this.types.find((x) => x.name == this.currentType);
    }
    /**
     * Returns true if currently selected display mode is basic
     */
    get isBasic() {
        return this.currentDisplayMode === null || this.currentDisplayMode === NotificationRuleDisplayMode.BASIC;
    }
    /**
     * Returns number of columns for table of settings. Number of columns depends on notification type,
     * allowed channels and selected display mode.
     */
    get numberOfColumns() {
        if (this.isBasic)
            return this.currentTypeSettings.channel.length;
        else
            return this.currentTypeSettings.channel.length + 1;
    }
    get delayOptions() {
        return DelayOptions.getValues();
    }
    created() {
        //Create local instance of rule to avoid error about prop mutation
        if (this.rule) {
            this.localRule = this.rule;
            this.currentType = this.localRule.NotificationType;
            this.currentEnabled = true;
            this.currentTypeSettings.channel.forEach((channel) => {
                let existingChannel = this.localRule.Channels[channel.type];
                //load default channel settings
                let ch = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.currentType, channel.type);
                if (existingChannel) {
                    //load values from existing rule
                    ch = {
                        enabled: existingChannel.Settings.enabled != null ? existingChannel.Settings.enabled : true,
                        delay: existingChannel.Settings.delay || 0,
                        onResolve: existingChannel.Settings.onResolve || false,
                        repeat: existingChannel.Settings.repeat || -1
                    };
                }
                else {
                    //if updating rule and channel is not used, set disabled to true
                    ch.enabled = false;
                }
                this.currentChannels[channel.type] = ch;
            });
        }
        else {
            this.currentEnabled = false;
            this.localRule = {
                Channels: {},
                NotificationType: this.currentType
            };
            this.currentTypeSettings.channel.forEach((channel) => {
                this.currentChannels[channel.type] = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.currentType, channel.type);
            });
        }
    }
    changeDisplayMode(selected) {
        this.currentDisplayMode = selected;
        this.save();
    }
    /**
     * Check if given type has available onResolve setting
     */
    channelForTypeHasOnResolve(channelType) {
        let channel = this.currentTypeSettings.channel.find((x) => x.type == channelType);
        return channel.onResolve;
    }
    save() {
        this.$forceUpdate();
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let ruleInPresetDto = {
            NotificationType: this.currentType,
            Channels: currentChannelsDTO
        };
        this.$emit('ruleChanged', ruleInPresetDto, this.currentEnabled);
    }
};
__decorate([
    Prop({ type: Array })
], MeasuredRuleTemplate.prototype, "types", void 0);
__decorate([
    Prop({ type: Object })
], MeasuredRuleTemplate.prototype, "rule", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], MeasuredRuleTemplate.prototype, "editIsAllowed", void 0);
MeasuredRuleTemplate = __decorate([
    Component({})
], MeasuredRuleTemplate);
export default MeasuredRuleTemplate;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminCompanyList from '@/views/components/admin/company/AdminCompanyList.vue';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import Actions, { AdminAction } from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import EventDomain from '@/entities/enums/eventDomains';
import LoggedUserManager from '@/services/LoggedUserManager';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import VueUtilities from '@/services/VueUtilities';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import { vxm } from '@/store/store.vuex';
import CreateCompanyModal from '@/views/components/admin/company/CreateCompanyModal.vue';
import UserRepository from '@/services/repository/UserRepository';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var departmentRepository;
var eventTypeRepository;
var vxDepartmentStore = vxm.departmentStore;
var userRepository;
let AdminCompanyManagement = class AdminCompanyManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isModalActive = false;
        this.departmentsList = new Array();
        this.dateTimeManager = null;
        this.userList = new Array();
        this.selectedCompanyToLogin = null;
        this.tableAdminCompanyQueries = 'adminCompany';
        this.objStringAdminCompany = null;
        this.totalSize = 0;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
    }
    async created() {
        eventTypeRepository = EventTypeRepository.getInstance(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        localStorage.removeItem(LocalStorageKeys.ADMIN_PREV_ROUTE);
        departmentRepository = new DepartmentRepository(this);
        userRepository = new UserRepository(this);
        this.loadData();
    }
    createCompany() {
        this.isModalActive = true;
    }
    closeModal() {
        this.isModalActive = false;
    }
    async loadData() {
        this.isLoading = true;
        this.departmentsList = await departmentRepository.getAllDepartmentsAdmin();
        this.isLoading = false;
    }
    async loadUsers(pagination, currentFilter) {
        let res = await userRepository.getUsersAdmin(null, pagination, null, currentFilter);
        this.userList = res.getData();
        this.totalSize = res.getPagination().total;
    }
    async login(company, name, params) {
        this.selectedCompanyToLogin = company;
        let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
        this.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
        LoggedUserManager.loginToCompany(this, this.selectedCompanyToLogin, this.$ability.rules, name, params);
        this.loginSucessfulAsync();
    }
    async loginSucessfulAsync() {
        await this.$featuresManager.loadTier();
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true, true, this.selectedCompanyToLogin.companyId);
        this.setDepartments(AdminDepartment.convertToUserDepartments(this.departmentsList));
        this.$router
            .push({
            name: 'dashboard',
            params: {
                lang: this.$route.params.lang,
                departmentId: this.selectedCompanyToLogin.companyId
            }
        })
            .catch((err) => err);
    }
    changeRouterUrlFromCompany(objStringAdminCompany) {
        this.objStringAdminCompany = objStringAdminCompany;
        this.changeRouterUrl(this.objStringAdminCompany);
    }
    changeRouterUrl(objStringAdminCompany) {
        this.$router
            .push({
            query: {
                [this.tableAdminCompanyQueries]: objStringAdminCompany
            }
        })
            .catch((err) => err);
    }
    loadNewCompany() {
        this.loadData();
    }
    setDepartments(value) {
        vxDepartmentStore.departments = value;
    }
};
AdminCompanyManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_USERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            AdminCompanyList,
            CreateCompanyModal
        }
    })
], AdminCompanyManagement);
export default AdminCompanyManagement;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("component.invitation.sent")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "is-dark-grey",
                                  staticStyle: { "font-size": "1rem" },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field ==
                                _vm.tableColumns.invitedEmail.field ||
                              column.field == _vm.tableColumns.state.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.invitation." + column.field)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mt-4 table-wrap",
          attrs: {
            data: _vm.invitationGroupByMail,
            paginated: "",
            "per-page": _vm.pageSize,
            "current-page": _vm.page,
            total: _vm.total,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "page-input": true,
            "pagination-order": "is-centered",
            detailed: "",
            "detail-key": "email",
            "has-detailed-visible": function (row) {
              return row.invitations.length > 1
            },
            "show-detail-icon": true,
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "detail",
                fn: function (invitations) {
                  return [
                    _c("article", [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "b-table",
                            {
                              staticClass: "no-table-background",
                              attrs: { data: invitations.row.invitations },
                            },
                            [
                              _c("b-table-column", {
                                attrs: {
                                  field: "state",
                                  label: _vm.$t("component.invitation.state"),
                                  sortable: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (invitation) {
                                        return [
                                          _c(
                                            "b-tag",
                                            {
                                              attrs: {
                                                type: _vm.getStateType(
                                                  invitation.row
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "component.invitation.states." +
                                                        invitation.row.state
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("b-table-column", {
                                attrs: {
                                  field: "createdAt",
                                  label: _vm.$t(
                                    "component.invitation.createdAt"
                                  ),
                                  sortable: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (invitation) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateTimeManager.formatTime(
                                                  new Date(
                                                    invitation.row.createdAt *
                                                      1000
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("b-table-column", {
                                attrs: {
                                  field: "action",
                                  label: _vm.$t("generals.action"),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (invitation) {
                                        return [
                                          _c(
                                            "b-dropdown",
                                            {
                                              attrs: {
                                                position: "is-bottom-left",
                                                "append-to-body": "",
                                                "aria-role": "list",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "trigger",
                                                    fn: function () {
                                                      return [
                                                        _c("b-icon", {
                                                          staticClass:
                                                            "clickable",
                                                          attrs: {
                                                            icon: "ellipsis-h",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  staticClass:
                                                    "custom-dropdown-item",
                                                  attrs: {
                                                    disabled:
                                                      invitation.row.state !==
                                                      _vm.InvitationStates
                                                        .PENDING,
                                                    "aria-role": "listitem",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.revokeInv(
                                                        invitation.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: { icon: "trash" },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "pl-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "component.invitation.actions.revoke"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  staticClass:
                                                    "custom-dropdown-item",
                                                  attrs: {
                                                    "aria-role": "listitem",
                                                    disabled:
                                                      invitation.row.state ===
                                                      _vm.InvitationStates
                                                        .ACCEPTED,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resendInv(
                                                        invitation.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: { icon: "share" },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "pl-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "component.invitation.actions.resend"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.sentInvitations.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.total,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "email",
              label: _vm.$t(
                "component.invitation." + _vm.tableColumns.invitedEmail.field
              ),
              visible: _vm.tableColumns.invitedEmail.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [_vm._v(" " + _vm._s(invitation.row.email) + " ")]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "latestInvitation.state",
              label: _vm.$t(
                "component.invitation." + _vm.tableColumns.state.field
              ),
              visible: _vm.tableColumns.state.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    _c(
                      "b-tag",
                      {
                        attrs: {
                          type: _vm.getStateType(
                            invitation.row.latestInvitation
                          ),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.invitation.states." +
                                  invitation.row.latestInvitation.state
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "latestInvitation.role",
              label: _vm.$t(
                "component.invitation." + _vm.tableColumns.role.field
              ),
              visible: _vm.tableColumns.role.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "users.roles." +
                              invitation.row.latestInvitation.role
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "latestInvitation.createdAt",
              label: _vm.$t(
                "component.invitation." + _vm.tableColumns.createdAt.field
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(
                              invitation.row.latestInvitation.createdAt * 1000
                            )
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { field: "action", label: _vm.$t("generals.action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    invitation.row.invitations.length == 1
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              position: "is-bottom-left",
                              "append-to-body": "",
                              "aria-role": "list",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function () {
                                    return [
                                      _c("b-icon", {
                                        staticClass: "clickable",
                                        attrs: { icon: "ellipsis-h" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "custom-dropdown-item",
                                attrs: {
                                  disabled:
                                    invitation.row.latestInvitation.state !==
                                    _vm.InvitationStates.PENDING,
                                  "aria-role": "listitem",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.revokeInv(
                                      invitation.row.latestInvitation.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "trash" } }),
                                _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.invitation.actions.revoke"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "custom-dropdown-item",
                                attrs: {
                                  "aria-role": "listitem",
                                  disabled:
                                    invitation.row.latestInvitation.state ===
                                    _vm.InvitationStates.ACCEPTED,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resendInv(
                                      invitation.row.latestInvitation.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "share" } }),
                                _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.invitation.actions.resend"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("component.invitation.empty")) + " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
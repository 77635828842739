var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [_vm._v(" " + _vm._s(_vm.$t("component.settings.profile.title")) + " ")]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("UserInfoComponent", {
                  staticClass: "box tile is-child",
                  attrs: {
                    currentUser: _vm.currentUser,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: {
                    updateUser: _vm.updateUser,
                    reloadUser: _vm.reloadUser,
                    signOut: _vm.signOut,
                    signOutAllDevices: _vm.signOutAllDevices,
                    changePassword: _vm.changePassword,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "tile" }, [
              _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("UserSettingsComponent", {
                    staticClass: "box tile is-child",
                    attrs: {
                      dateTimeManager: _vm.dateTimeManager,
                      settings: _vm.settings,
                      requestPending: _vm.userRequestPending,
                    },
                    on: {
                      languageChanged: _vm.languageChanged,
                      measurementSortChanged: _vm.measurementSortChanged,
                      dateFormatSelected: _vm.dateFormatSelected,
                      clockFormatSelected: _vm.clockFormatSelected,
                      timezoneSelected: _vm.timezoneSelected,
                      showTzChanged: _vm.showTzChanged,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      [
        _c("div", { staticClass: "multi-header" }, [
          _c(
            "div",
            {
              staticClass:
                "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "subtitle is-4 is-spaced has-text-centered-touch",
                },
                [_vm._v(" " + _vm._s(_vm.$t("tiers.tier_detail")) + " ")]
              ),
              _vm.updateAllowed
                ? _c("b-button", {
                    class: _vm.isEditing && "has-text-primary",
                    attrs: { "icon-right": "pencil-alt", type: "is-white" },
                    on: {
                      click: function ($event) {
                        return _vm.editTier()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div"),
        ]),
        _c("div", { staticClass: "columns columns-info-form" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                { attrs: { horizontal: "", label: _vm.$t("tiers.id") } },
                [_c("span", [_vm._v(_vm._s(_vm.tier.id))])]
              ),
              _c(
                "b-field",
                { attrs: { horizontal: "", label: _vm.$t("tiers.name") } },
                [_c("span", [_vm._v(_vm._s(_vm.tier.name))])]
              ),
              _c(
                "b-field",
                {
                  class: { required: _vm.isEditing },
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.display_name"),
                    type: {
                      "is-danger": _vm.errors.has(_vm.$t("tiers.display_name")),
                    },
                    message: _vm.errors.first(_vm.$t("tiers.display_name")),
                  },
                },
                [
                  _vm.isEditing
                    ? _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: { name: _vm.$t("tiers.display_name") },
                        model: {
                          value: _vm.displayName,
                          callback: function ($$v) {
                            _vm.displayName = $$v
                          },
                          expression: "displayName",
                        },
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.tier.displayName))]),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.active"),
                    type: {
                      "is-danger": _vm.errors.has(_vm.$t("tiers.active")),
                    },
                    message: _vm.errors.first(_vm.$t("tiers.active")),
                  },
                },
                [
                  _vm.isEditing
                    ? _c("b-switch", {
                        model: {
                          value: _vm.active,
                          callback: function ($$v) {
                            _vm.active = $$v
                          },
                          expression: "active",
                        },
                      })
                    : _c("span", [
                        _vm.tier.active
                          ? _c("i", { staticClass: "fas fa-check" })
                          : _c("i", { staticClass: "fas fa-times" }),
                      ]),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: { horizontal: "", label: _vm.$t("tiers.created_at") },
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(_vm.tier.createdAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: { horizontal: "", label: _vm.$t("tiers.created_by") },
                },
                [_c("span", [_vm._v(_vm._s(_vm.tier.createdBy.email))])]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "column" }, [
            _c(
              "p",
              {
                staticClass: "has-text-weight-bold is-size-5",
                class: { "required only-asterisk": _vm.isEditing },
              },
              [_vm._v(" " + _vm._s(_vm.$t("tiers.features_list")) + " ")]
            ),
            _vm.isEditing
              ? _c(
                  "div",
                  { staticClass: "mt-1" },
                  _vm._l(_vm.featuresInTable, function (feature, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-1",
                      },
                      [
                        _c("b-field", [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("tiers.features." + feature.name))
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-flex-direction-row is-align-items-center",
                          },
                          [
                            feature.type === _vm.FeaturesTypes.BOOL
                              ? _c(
                                  "b-field",
                                  [
                                    _c(
                                      "b-switch",
                                      {
                                        model: {
                                          value: feature.value.Allowed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              feature.value,
                                              "Allowed",
                                              $$v
                                            )
                                          },
                                          expression: "feature.value.Allowed",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("tiers.features.allowed")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            feature.type === _vm.FeaturesTypes.QUOTA
                              ? _c(
                                  "b-field",
                                  {
                                    staticClass: "mb-0",
                                    attrs: {
                                      "label-position": "on-border",
                                      label: _vm.$t("tiers.features.quota"),
                                    },
                                  },
                                  [
                                    _c("b-numberinput", {
                                      attrs: { controls: false },
                                      model: {
                                        value: feature.value.Quota,
                                        callback: function ($$v) {
                                          _vm.$set(feature.value, "Quota", $$v)
                                        },
                                        expression: "feature.value.Quota",
                                      },
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "control" },
                                      [
                                        _c("b-button", {
                                          attrs: {
                                            type: "is-primary",
                                            label: _vm.$t(
                                              "tiers.features.unlimited"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setUnlimited(feature)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            feature.type === _vm.FeaturesTypes.MONTHLY
                              ? _c(
                                  "b-field",
                                  {
                                    staticClass: "mb-0",
                                    attrs: {
                                      "label-position": "on-border",
                                      label: _vm.$t("tiers.features.quota"),
                                    },
                                  },
                                  [
                                    _c("b-numberinput", {
                                      attrs: { controls: false },
                                      model: {
                                        value: feature.value.Quota,
                                        callback: function ($$v) {
                                          _vm.$set(feature.value, "Quota", $$v)
                                        },
                                        expression: "feature.value.Quota",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            feature.type === _vm.FeaturesTypes.MONTHLY
                              ? _c(
                                  "b-field",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "b-switch",
                                      {
                                        model: {
                                          value: feature.value.AllowOverflow,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              feature.value,
                                              "AllowOverflow",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "feature.value.AllowOverflow",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "tiers.features.allowOverflow"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", [
                  _c(
                    "ul",
                    {
                      staticClass: "has-text-dark",
                      staticStyle: { "list-style-type": "disc" },
                    },
                    _vm._l(_vm.tier.tierFeatures, function (feature, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class:
                            _vm.tier.enabledFeatures.find(function (x) {
                              return x.Name == feature.Name
                            }) == null
                              ? "has-text-weight-light has-text-grey"
                              : "has-text-weight-semibold",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("tiers.features." + feature.Name)) +
                                " "
                            ),
                          ]),
                          feature.Value.Quota
                            ? _c("span", [
                                _vm._v("(" + _vm._s(feature.Value.Quota)),
                                feature.Value.AllowOverflow
                                  ? _c("span", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(")"),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
          ]),
        ]),
        _vm.isEditing
          ? _c(
              "b-button",
              {
                attrs: { type: "is-primary", expanded: "" },
                on: {
                  click: function ($event) {
                    return _vm.updateTier()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("tiers.update_tier")) + " ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AddLoggersComponent from '@/views/components/logger/AddLoggersComponent.vue';
let AddLoggersModal = class AddLoggersModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedLoggerIds = [];
        this.loggersToAssign = [];
    }
    onLoggersLoaded() {
        this.loggersToAssign = this.assignedLoggers.filter((x) => this.selectedLoggerIds.includes(x.companyLoggerId));
    }
    created() {
        let withoutSource = this.assignedLoggers.filter((x) => !x.source.exists());
        if (withoutSource.length > 0) {
            this.loggersToAssign = withoutSource;
        }
    }
    resultRetrieved(event) {
        this.$emit('close-modal', event);
    }
};
__decorate([
    Prop({ type: String })
], AddLoggersModal.prototype, "deviceId", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AddLoggersModal.prototype, "assignedLoggers", void 0);
__decorate([
    Prop({ type: Array })
], AddLoggersModal.prototype, "sourceList", void 0);
__decorate([
    Prop({ type: Object })
], AddLoggersModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AddLoggersModal.prototype, "inWizard", void 0);
__decorate([
    Watch('assignedLoggers')
], AddLoggersModal.prototype, "onLoggersLoaded", null);
AddLoggersModal = __decorate([
    Component({ components: { AddLoggersComponent } })
], AddLoggersModal);
export default AddLoggersModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        {
          staticClass: "column org-item-list px-0",
          class:
            _vm.isAdmin || _vm.department.member.Enabled
              ? "is-enabled"
              : "is-disabled",
        },
        [
          !_vm.isAdmin
            ? _c("b-icon", {
                staticClass: "mr-3",
                attrs: {
                  type: _vm.isFavorite ? "is-warning" : "is-light",
                  "custom-class": _vm.department.member.Enabled
                    ? "favorite-star"
                    : "favorite-star disabled",
                  size: "is-small",
                  icon: "star",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.favoriteSelected()
                  },
                },
              })
            : _vm._e(),
          _vm.isAdmin || _vm.department.member.Enabled
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "dashboard",
                      params: {
                        lang: _vm.$route.params.lang,
                        departmentId: _vm.department.id,
                      },
                      query: { reloadTier: "true" },
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.department.name))])]
              )
            : [_c("span", [_vm._v(_vm._s(_vm.department.name))])],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { ReportRecordValue } from './ReportRecordValue';
import { Annotation } from './Annotation';
import { getStatisticsType } from '../enums/statisticsType';
import DecimalConversion from '@/services/DecimalConversion';
export class ReportRecord {
    constructor() {
        this.values = new Array();
        this.annotations = new Array();
    }
    static fromApi(data) {
        if (!data)
            return null;
        else {
            let converted = {};
            Object.keys(data).forEach((key) => {
                let newReportRecord = {
                    [key]: data[key].map((et) => ReportRecord.define(et, key))
                };
                converted = Object.assign(converted, newReportRecord);
            });
            return converted;
        }
    }
    static define(data, sourceId) {
        let reportRecord = new ReportRecord();
        reportRecord.sourceId = sourceId;
        reportRecord.createdAt = data.CreatedAt;
        reportRecord.datepoint = data.Datepoint;
        reportRecord.range = data.Range;
        if (data.Values && data.Values.length > 0)
            reportRecord.values = ReportRecordValue.fromApi(data.Values, reportRecord.datepoint);
        if (data.Annotations && data.Annotations.length > 0)
            reportRecord.annotations = Annotation.fromApi(data.Annotations);
        return reportRecord;
    }
    static pickSource(reportRecords, sourceId) {
        if (reportRecords[sourceId])
            return { [sourceId]: reportRecords[sourceId] };
        else {
            return {};
        }
    }
    static getValues(records) {
        return records.flatMap((x) => x.values);
    }
    static getServices(records) {
        let recordValues = records.flatMap((x) => x.values);
        let recordValuesTypeSlugs = recordValues.map((y) => (y ? y.eventType : null));
        let distinctServiceSlugs = [...new Set(recordValuesTypeSlugs)].filter((x) => x != null);
        return distinctServiceSlugs;
    }
    static getValueForService(record, serviceSlug) {
        if (!record.values)
            return {};
        let value = record.values.find((x) => x.eventType == serviceSlug);
        if (!value)
            return {};
        else
            return value;
    }
    static getValueStringForService(record, serviceSlug) {
        if (!record.values)
            return [];
        let values = record.values.filter((x) => x.eventType == serviceSlug);
        let convertedValues = new Array();
        values.forEach((val) => {
            let stringRecord = {
                stringValue: DecimalConversion.toCommaString(val.value.Value) + ' ' + val.value.Unit,
                statisticsType: getStatisticsType(val.key)
            };
            convertedValues.push(stringRecord);
        });
        return convertedValues;
    }
    static sortRecordsSelectedInTable(a, b) {
        let aId = a.datepoint;
        let bId = b.datepoint;
        return aId < bId ? -1 : aId > bId ? 1 : 0;
    }
}

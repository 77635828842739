import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import EventsTable from '@/views/components/events/EventsTable.vue';
import UserPreferences from '@/services/UserPreferences';
let LastEventsTable = class LastEventsTable extends Vue {
    constructor() {
        super(...arguments);
        this.DAY = 86400;
        this.notAliveEventsCount = Object.keys(this.notAliveEvents).length;
        this.outdatedDataVisible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.OutdatedLastEventsVisible);
    }
    get aliveEvents() {
        let keys = Object.keys(this.lastEvents);
        let filteredRecord = {};
        keys.forEach((key) => {
            let events = this.lastEvents[key];
            let filteredEvents = events.filter((x) => x.measuredAt > Math.floor(new Date().getTime() / 1000) - this.DAY);
            if (!filteredEvents || filteredEvents.length <= 0)
                return;
            filteredRecord[key] = filteredEvents;
        });
        return filteredRecord;
    }
    get notAliveEvents() {
        let keys = Object.keys(this.lastEvents);
        let filteredRecord = {};
        keys.forEach((key) => {
            let events = this.lastEvents[key];
            let filteredEvents = events.filter((x) => x.measuredAt <= Math.floor(new Date().getTime() / 1000) - this.DAY);
            if (!filteredEvents || filteredEvents.length <= 0)
                return;
            filteredRecord[key] = filteredEvents;
        });
        return filteredRecord;
    }
    outdatedDataCollapseChanged(isOpen) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.OutdatedLastEventsVisible, isOpen);
    }
};
__decorate([
    Prop({ type: Object })
], LastEventsTable.prototype, "lastEvents", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], LastEventsTable.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], LastEventsTable.prototype, "refreshAllowed", void 0);
__decorate([
    Prop({ type: String })
], LastEventsTable.prototype, "favoriteGroupId", void 0);
LastEventsTable = __decorate([
    Component({ components: { EventsTable } })
], LastEventsTable);
export default LastEventsTable;

import { __decorate } from "tslib";
import LoggedUserManager from '@/services/LoggedUserManager';
import { Component, Prop, Vue } from 'vue-property-decorator';
let OrganizationItemList = class OrganizationItemList extends Vue {
    constructor() {
        super(...arguments);
        this.isAdmin = false;
        this.randomCity = ['Bratislava', 'Zilina', 'Kocise', 'Vienna'];
        this.randomIco = ['ICO32145', 'ICO98H766', 'ICON27L2', 'ICON5001'];
    }
    get getRandomCity() {
        return this.randomCity[Math.floor(Math.random() * this.randomCity.length)];
    }
    get getrandomIco() {
        return this.randomIco[Math.floor(Math.random() * this.randomIco.length)];
    }
    get isFavorite() {
        return this.favoriteDepartmentId === this.department.id;
    }
    created() {
        this.isAdmin = LoggedUserManager.isAdmin(this);
    }
    favoriteSelected() {
        if (!this.department.member.Enabled)
            return;
        let id = !this.isFavorite ? this.department.id : '';
        this.$emit('favoriteSelected', id);
    }
};
__decorate([
    Prop({ type: Object })
], OrganizationItemList.prototype, "department", void 0);
__decorate([
    Prop({ type: String, default: null })
], OrganizationItemList.prototype, "favoriteDepartmentId", void 0);
OrganizationItemList = __decorate([
    Component({ components: {} })
], OrganizationItemList);
export default OrganizationItemList;

var IssuesFilterDateRangeOptions;
(function (IssuesFilterDateRangeOptions) {
    IssuesFilterDateRangeOptions["TODAY"] = "today";
    IssuesFilterDateRangeOptions["YESTERDAY"] = "yesterday";
    IssuesFilterDateRangeOptions["THISWEEK"] = "thisWeek";
    IssuesFilterDateRangeOptions["LASTWEEK"] = "lastWeek";
    IssuesFilterDateRangeOptions["THISMONTH"] = "thisMonth";
    IssuesFilterDateRangeOptions["LASTMONTH"] = "lastMonth";
})(IssuesFilterDateRangeOptions || (IssuesFilterDateRangeOptions = {}));
export default IssuesFilterDateRangeOptions;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading && !_vm.isChangingMode && _vm.dateTimeManager
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c("div", { staticClass: "tile is-child" }, [
                _c("h2", { staticClass: "subtitle is-4 mb-0 pb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("notifications.configuration.non_working_title")
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "p",
                  { staticClass: "is-size-7 is-italic has-text-grey pb-5" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "notifications.configuration.non_working_subtitle"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-one-third-desktop" },
                    [
                      _c(
                        "b-table",
                        {
                          attrs: {
                            data: _vm.nonWorkingSchedules,
                            narrowed: "",
                            "mobile-cards": false,
                          },
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "validFrom",
                              label: _vm.$t(
                                "notifications.configuration.non_working_list.date"
                              ),
                              sortable: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row.getDateRange(
                                              _vm.dateTimeManager
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2160020851
                            ),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "notifications.configuration.non_working_list.name"
                              ),
                              sortable: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(props.row.name) + " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2982927002
                            ),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              field: "action",
                              label: _vm.$t(
                                "notifications.configuration.non_working_list.action"
                              ),
                              sortable: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "is-flex is-flex-direction-row is-align-items-center",
                                        },
                                        [
                                          props.row.additionalProperties
                                            .isEditing === false
                                            ? _c("b-icon", {
                                                staticClass:
                                                  "control-icon clickable",
                                                attrs: { icon: "pencil-alt" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.editNonWorking(
                                                      props.row
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("b-icon", {
                                                staticClass:
                                                  "control-icon clickable has-text-primary",
                                                attrs: { icon: "times-circle" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.cancelEditNonWorking(
                                                      props.row
                                                    )
                                                  },
                                                },
                                              }),
                                          _c("b-icon", {
                                            staticClass:
                                              "control-icon clickable",
                                            attrs: { icon: "trash" },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.confirmSpecialScheduleDelete(
                                                  props.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1997404658
                            ),
                          }),
                        ],
                        1
                      ),
                      !_vm.isCreating && !_vm.scheduleWhatIsEditing
                        ? _c(
                            "b-button",
                            {
                              staticClass: "button is-primary mt-2",
                              attrs: {
                                outlined: "",
                                expanded: "",
                                size: "is-small",
                                "icon-right": "plus",
                              },
                              on: { click: _vm.addNew },
                            },
                            [_vm._v(_vm._s(_vm.$t("generals.add")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.isCreating || _vm.scheduleWhatIsEditing
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _vm.scheduleWhatIsEditing
                          ? _c("h3", { staticClass: "subtitle" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "notifications.configuration.non_working_list.editing_title",
                                      { name: _vm.scheduleWhatIsEditing.name }
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("h3", { staticClass: "subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "notifications.configuration.non_working_list.creating_title"
                                  )
                                )
                              ),
                            ]),
                        _c("div", { staticClass: "columns" }, [
                          _c(
                            "div",
                            { staticClass: "column is-one-half-desktop" },
                            [
                              _c(
                                "b-field",
                                { attrs: { grouped: "" } },
                                [
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "notifications.configuration.non_working_list.date_select"
                                        ),
                                        type: {
                                          "is-danger": _vm.errors.has(
                                            _vm.$t(
                                              "notifications.configuration.non_working_list.date"
                                            )
                                          ),
                                        },
                                        message: _vm.errors.first(
                                          _vm.$t(
                                            "notifications.configuration.non_working_list.date"
                                          )
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-datepicker", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeholders.click_select"
                                          ),
                                          range: "",
                                          "first-day-of-week": 1,
                                          name: _vm.$t(
                                            "notifications.configuration.non_working_list.date"
                                          ),
                                          "date-formatter": function (date) {
                                            return _vm.dateTimeManager.formatTimeRangeInInput(
                                              date
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.nonWorkingRange,
                                          callback: function ($$v) {
                                            _vm.nonWorkingRange = $$v
                                          },
                                          expression: "nonWorkingRange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "notifications.configuration.non_working_list.name"
                                        ),
                                        grouped: "",
                                        type: {
                                          "is-danger": _vm.errors.has(
                                            _vm.$t(
                                              "notifications.configuration.non_working_list.name"
                                            )
                                          ),
                                        },
                                        message: _vm.errors.first(
                                          _vm.$t(
                                            "notifications.configuration.non_working_list.name"
                                          )
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|max:50",
                                            expression: "'required|max:50'",
                                          },
                                        ],
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "notifications.configuration.non_working_list.name_placeholder"
                                          ),
                                          type: "text",
                                          name: _vm.$t(
                                            "notifications.configuration.non_working_list.name"
                                          ),
                                        },
                                        model: {
                                          value: _vm.nonWorkingName,
                                          callback: function ($$v) {
                                            _vm.nonWorkingName = $$v
                                          },
                                          expression: "nonWorkingName",
                                        },
                                      }),
                                      _c(
                                        "p",
                                        { staticClass: "control" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "button is-success",
                                              on: {
                                                click: _vm.saveNonWorkingHours,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("generals.save"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("hr"),
                        _c(
                          "p",
                          {
                            staticClass:
                              "pt-4 pb-5 subtitle has-text-weight-semibold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "notifications.configuration.general_or_preset"
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "b-dropdown",
                              {
                                attrs: { "aria-role": "list" },
                                on: { change: _vm.applyPresetNonWorking },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "trigger",
                                      fn: function (ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "is-italic is-underlined clickable-text",
                                              attrs: { role: "button" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.general_or_preset_link"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: active
                                                    ? "caret-up"
                                                    : "caret-down",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1251524264
                                ),
                                model: {
                                  value: _vm.selectedPresetNonWorking,
                                  callback: function ($$v) {
                                    _vm.selectedPresetNonWorking = $$v
                                  },
                                  expression: "selectedPresetNonWorking",
                                },
                              },
                              _vm._l(_vm.presets, function (preset) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: preset.id,
                                    attrs: {
                                      "aria-role": "listitem",
                                      value: preset,
                                    },
                                  },
                                  [_vm._v(_vm._s(preset.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.hasRulesForEntitiesNonWorking
                          ? _c("MeasuredDataNotification", {
                              attrs: {
                                rule: _vm.singleMeasuredRuleNonWorking,
                                types: _vm.measuredTypes,
                                currentUser: _vm.currentUser,
                                selectedMeasuredRulesMode:
                                  _vm.selectedMeasuredRulesModeNonWorking,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                measuredRuleEnabled: _vm.measuredRuleEnabled,
                              },
                              on: {
                                changeMode: _vm.changeModeNonWorking,
                                ruleChanged: _vm.nonWorkingChanged,
                                switchedValue: _vm.switchedValue,
                              },
                            })
                          : _c("EntityMeasuredDataNotification", {
                              attrs: {
                                rules: _vm.measuredRulesNonWorking,
                                types: _vm.measuredTypes,
                                currentUser: _vm.currentUser,
                                selectedMeasuredRulesMode:
                                  _vm.selectedMeasuredRulesModeNonWorking,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                              },
                              on: {
                                changeMode: _vm.changeModeNonWorking,
                                ruleChanged: _vm.nonWorkingChanged,
                              },
                            }),
                        _c("hr"),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                            ),
                            currentUser: _vm.currentUser,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("hr"),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                            ),
                            currentUser: _vm.currentUser,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("hr"),
                        _c("h2", { staticClass: "subtitle has-text-dark" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "notifications.configuration.other_rules_title"
                              )
                            )
                          ),
                        ]),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.LOW_BATTERY
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.LOW_BATTERY
                            ),
                            currentUser: _vm.currentUser,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType
                                .CUSTOMER_ENDING_CALIBRATION
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType
                                .CUSTOMER_ENDING_CALIBRATION
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType
                                .PDF_RECORDS_REGENERATED
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType
                                .PDF_RECORDS_REGENERATED
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType
                                .AUTOMATIC_REPORTS_GENERATED
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType
                                .AUTOMATIC_REPORTS_GENERATED
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.RECORDS_UPDATED
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.RECORDS_UPDATED
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.LOGGER_STATE
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.LOGGER_STATE
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { FeaturesTypes } from '@/entities/tiers/FeaturesTypes';
import { Component, Vue, Prop } from 'vue-property-decorator';
let TiersDetail = class TiersDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.FeaturesTypes = FeaturesTypes;
        this.displayName = null;
        this.active = true;
        this.features = [];
        this.featuresInTable = [];
        this.hasFeaturesError = false;
    }
    created() {
        this.tier.tierFeatures.forEach((feature) => {
            let enabled = this.tier.enabledFeatures.filter((x) => x.Name == feature.Name).length > 0;
            let featureFeObj = {
                enabled: enabled,
                type: feature.Type,
                name: feature.Name,
                value: feature.Value
            };
            this.featuresInTable.push(featureFeObj);
        });
        this.displayName = this.tier.displayName;
        this.active = this.tier.active;
    }
    editTier() {
        this.isEditing = !this.isEditing;
    }
    updateTier() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let featuresCreateDtoArr = [];
                this.featuresInTable.forEach((feature) => {
                    let featureDto = {
                        Name: feature.name,
                        Value: feature.value
                    };
                    featuresCreateDtoArr.push(featureDto);
                });
                let tierDto = {
                    Name: this.tier.name,
                    DisplayName: this.displayName,
                    Order: this.tier.order,
                    Active: this.active,
                    TierFeatures: featuresCreateDtoArr
                };
                this.$emit('updateTier', tierDto);
            }
        });
    }
    setUnlimited(feature) {
        feature.value.Quota = -1;
    }
};
__decorate([
    Prop({ type: Object })
], TiersDetail.prototype, "tier", void 0);
__decorate([
    Prop({ type: Array })
], TiersDetail.prototype, "tierFeatures", void 0);
__decorate([
    Prop({ type: Object })
], TiersDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TiersDetail.prototype, "updateAllowed", void 0);
TiersDetail = __decorate([
    Component({})
], TiersDetail);
export default TiersDetail;

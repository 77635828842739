import { __decorate } from "tslib";
import { FeaturesTypes } from '@/entities/tiers/FeaturesTypes';
import { Component, Vue, Prop } from 'vue-property-decorator';
let CompanyTierDetailForm = class CompanyTierDetailForm extends Vue {
    constructor() {
        super(...arguments);
        this.FeaturesTypes = FeaturesTypes;
        this.name = null;
    }
    created() { }
    setUnlimited(feature) {
        feature.Value.Quota = -1;
    }
    updateTier() {
        let dto = {
            Id: this.activeTier.id,
            Name: this.activeTier.name,
            TierId: this.activeTier.tierId,
            CreatedAt: this.activeTier.createdAt,
            ActivatedAt: this.activeTier.activatedAt,
            DeactivatedAt: this.activeTier.deactivatedAt,
            Active: this.activeTier.active,
            Features: this.activeTier.features
        };
        this.$emit('updateTier', dto);
    }
};
__decorate([
    Prop({ type: Object })
], CompanyTierDetailForm.prototype, "activeTier", void 0);
__decorate([
    Prop({ type: Object })
], CompanyTierDetailForm.prototype, "dateTimeManager", void 0);
CompanyTierDetailForm = __decorate([
    Component({ components: {} })
], CompanyTierDetailForm);
export default CompanyTierDetailForm;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between is-align-items-center mb-2",
        },
        [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.organization_card.title")) + " "
            ),
          ]),
          _vm.departments.getData().length > 0
            ? _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-white",
                      on: {
                        click: function ($event) {
                          return _vm.cardView()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "th-large",
                          type: { "is-primary": _vm.cardViewActive },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-white",
                      on: {
                        click: function ($event) {
                          return _vm.tableView()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "th-list",
                          type: { "is-primary": !_vm.cardViewActive },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        [
          _vm.departments.getData().length > 0 && _vm.isLoggedFromAdmin
            ? _c(
                "b-field",
                { staticClass: "mx-1 mb-5" },
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: _vm.$t("generals.search"),
                      "icon-pack": "fas",
                      icon: "search",
                      "icon-right": "close-circle",
                      "icon-right-clickable": "",
                    },
                    on: {
                      "icon-right-click": function ($event) {
                        _vm.departmentName = ""
                      },
                    },
                    model: {
                      value: _vm.departmentName,
                      callback: function ($$v) {
                        _vm.departmentName = $$v
                      },
                      expression: "departmentName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.departments.getData().length > 0
        ? [
            _vm.cardViewActive
              ? _c(
                  "div",
                  {
                    staticClass:
                      "department-item columns is-multiline is-mobile mb-1",
                  },
                  [
                    _vm.filteredDepartments.length > 0
                      ? _vm._l(_vm.filteredDepartments, function (department) {
                          return _c(
                            "div",
                            {
                              key: department.id,
                              staticClass:
                                "column is-half-mobile is-one-third-tablet is-one-quarter-desktop",
                            },
                            [
                              _c("OrganizationItem", {
                                attrs: {
                                  favoriteDepartmentId:
                                    _vm.favoriteDepartmentId,
                                  department: department,
                                },
                                on: { favoriteSelected: _vm.favoriteSelected },
                              }),
                            ],
                            1
                          )
                        })
                      : _c("div", { staticClass: "mx-4 mt-2" }, [
                          _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                        ]),
                  ],
                  2
                )
              : _c(
                  "div",
                  { staticClass: "columns is-multiline is-mobile mx-1 mb-1" },
                  [
                    _vm.filteredDepartments.length > 0
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-full-mobile is-full-tablet is-full-desktop px-0",
                            },
                            [
                              _c("div", { staticClass: "columns" }, [
                                _c("div", { staticClass: "column" }, [
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.organization_card.list.name"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._l(
                            _vm.filteredDepartments,
                            function (department) {
                              return _c(
                                "div",
                                {
                                  key: department.id,
                                  staticClass:
                                    "column is-full-mobile is-full-tablet is-full-desktop",
                                },
                                [
                                  _c("OrganizationItemList", {
                                    attrs: {
                                      department: department,
                                      favoriteDepartmentId:
                                        _vm.favoriteDepartmentId,
                                    },
                                    on: {
                                      favoriteSelected: _vm.favoriteSelected,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                        ]
                      : _c("div", { staticClass: "mt-2" }, [
                          _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                        ]),
                  ],
                  2
                ),
          ]
        : [
            _c(
              "b-notification",
              {
                staticClass: "is-child column is-full p-5 mt-5",
                attrs: {
                  type: "is-warning",
                  "has-icon": "",
                  closable: false,
                  "aria-close-label": "Close notification",
                  role: "alert",
                },
              },
              [
                _c("p", { staticClass: "mt-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("component.organization_card.list.empty_msg"))
                  ),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DeviceState } from '@/entities/enums/DeviceState';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import { Department } from '@/entities/models/Department';
let AdminDeviceInfoComponent = class AdminDeviceInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceState = DeviceState;
        this.DeviceInstanceState = DeviceInstanceState;
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
};
__decorate([
    Prop({ type: Object })
], AdminDeviceInfoComponent.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceInfoComponent.prototype, "isLayoutList", void 0);
AdminDeviceInfoComponent = __decorate([
    Component({})
], AdminDeviceInfoComponent);
export default AdminDeviceInfoComponent;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scan-area" }, [
    _c("video", { ref: "videoElement", attrs: { autoplay: "" } }),
    _c("div", { staticClass: "scanner-overlay" }, [
      _vm._m(0),
      _c(
        "button",
        {
          staticClass: "torch",
          attrs: { disabled: !_vm.isTorchEnabled },
          on: { click: _vm.toggleTorch },
        },
        [
          _c("b-icon", {
            class: _vm.isTorchEnabled ? "is-clickable" : "",
            attrs: { icon: "bolt", size: "is-small" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "corners" }, [
      _c("div", { staticClass: "corner top-left" }),
      _c("div", { staticClass: "corner top-right" }),
      _c("div", { staticClass: "corner bottom-left" }),
      _c("div", { staticClass: "corner bottom-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { createModule, mutation } from 'vuex-class-component';
const VuexModule = createModule({
    namespaced: 'notificationStore',
    strict: false
});
/**
 * Store for Notification management
 */
export class NotificationStore extends VuexModule {
    constructor() {
        super(...arguments);
        this._notifications = null;
        this._totalSize = null;
        this._lastLoaded = null;
    }
    get notifications() {
        return this._notifications;
    }
    set notifications(value) {
        this._notifications = value;
    }
    get totalSize() {
        return this._totalSize;
    }
    set totalSize(value) {
        this._totalSize = value;
    }
    get lastLoaded() {
        return this._lastLoaded;
    }
    set lastLoaded(value) {
        this._lastLoaded = value;
    }
    /**
     * Clears all data after logout.
     */
    clearData() {
        this._notifications = null;
        this._totalSize = null;
        this._lastLoaded = null;
    }
}
__decorate([
    mutation
], NotificationStore.prototype, "clearData", null);

import { Logger } from './Logger';
export class LoggersWithStates {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => LoggersWithStates.define(et));
        }
        else if (data != null) {
            return LoggersWithStates.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let reseller = new LoggersWithStates();
        if (data.Loggers)
            reseller.loggers = Logger.fromApi(data.Loggers);
        reseller.availableStates = data.AvailableStates;
        return reseller;
    }
}

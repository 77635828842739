import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Event } from '@/entities/models/Event';
import EventDomain from '@/entities/enums/eventDomains';
import EventsTableItem from '@/views/components/events/EventsTableItem.vue';
import EventsTableItemMobile from '@/views/components/events/EventsTableItemMobile.vue';
import DateTimeManager from '@/services/DateTimeManager';
import { UserSettingsValues } from '@/entities/UserSettings';
let EventsTable = class EventsTable extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.tableData = [];
        this.Event = Event;
        this.EventDomain = EventDomain;
        this.UserSettingsValues = UserSettingsValues;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.sourceGroups.forEach((group) => {
            this.eventsForGroup(group);
        });
    }
    groupVisibleCollapseChanged(sourceGroupIndex, dataIndex, visibility) {
        this.tableData[sourceGroupIndex].eventsFeArray[dataIndex].isVisible = visibility;
    }
    compareFn(a, b) {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }
    eventsForGroup(group) {
        let events = Event.findEventsBySourceGroupId(this.lastEvents, group.id, EventDomain.MEASURED);
        this.convertEventsToFeArray(events, group);
    }
    convertEventsToFeArray(events, group) {
        let eventsFeArray = [];
        let sourceIds = events.map((x) => x.sourceId).filter((v, i, a) => a.indexOf(v) == i);
        sourceIds.forEach((id) => {
            let eventsForSource = events.filter((x) => x.sourceId === id).sort((a, b) => a.eventType.type - b.eventType.type);
            let source = this.sourceGroups
                .find((x) => x.id == eventsForSource[0].sourceGroup.Id)
                .sources.find((x) => x.id == id);
            let tempMeasuredBoundaries = [];
            eventsForSource.forEach((x) => {
                let temp = source.boundaries.find((y) => y.eventTypeSlug === x.eventType.slug);
                if (temp) {
                    tempMeasuredBoundaries.push(temp);
                }
            });
            let measurementFeObj = {
                source: source,
                events: eventsForSource,
                measuredBoundaries: tempMeasuredBoundaries,
                isVisible: false
            };
            eventsFeArray.push(measurementFeObj);
        });
        this.tableData.push({
            eventsFeArray: eventsFeArray,
            group: { id: group.id, name: group.name }
        });
    }
    favoriteSelected(event, id) {
        event.stopPropagation();
        if (id == null)
            this.$emit('favoriteSelected', UserSettingsValues.UNASSIGNED_GROUP_ID);
        else
            this.$emit('favoriteSelected', id);
    }
    getBoundaryIcon(type) {
        let icon = '';
        switch (type) {
            case 'temperature':
                icon = 'fas fa-thermometer-half';
                break;
            case 'humidity':
                icon = 'fas fa-tint';
                break;
            case 'acceleration':
                icon = 'fas fa-car-crash';
                break;
            default:
                icon = 'fas fa-question';
                break;
        }
        return icon;
    }
};
__decorate([
    Prop({ type: Object })
], EventsTable.prototype, "lastEvents", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], EventsTable.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], EventsTable.prototype, "showFavoriteStar", void 0);
__decorate([
    Prop({ type: String, default: null })
], EventsTable.prototype, "favoriteGroupId", void 0);
EventsTable = __decorate([
    Component({ components: { EventsTableItem, EventsTableItemMobile } })
], EventsTable);
export default EventsTable;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "small" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", [
        _c("h2", { staticClass: "title is-2 has-text-centered" }, [
          _vm._v(" " + _vm._s(_vm.$t("component.verify_account.title")) + " "),
        ]),
        _vm.verified == true
          ? _c(
              "div",
              { staticClass: "has-text-centered" },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "has-text-success has-text-centered has-text-weight-medium",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("component.verify_account.success")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "login",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticStyle: { "margin-top": "1rem" },
                        attrs: { type: "is-primary", outlined: "" },
                      },
                      [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", [
              _c(
                "h3",
                { staticClass: "has-text-danger has-text-weight-medium" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("component.verify_account.failure")) +
                      " "
                  ),
                ]
              ),
              _c("p", { staticClass: "has-text-weight-medium" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.verify_account.causes_title")) +
                    " "
                ),
              ]),
              _c("ul", { staticClass: "account-verify-causes-list" }, [
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("component.verify_account.causes.wrong_url"))
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("component.verify_account.causes.account_missing")
                    )
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("component.verify_account.causes.verified"))
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("component.verify_account.causes.disabled"))
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("component.verify_account.causes.expired"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "resend-box has-text-centered" }, [
                _c("p", { staticClass: "has-text-weight-medium" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("component.verify_account.retry_request")) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "multi-header" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "login",
                            params: {
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          { attrs: { type: "is-primary", outlined: "" } },
                          [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "verifyResend",
                            query: { user: _vm.$route.query.user },
                            params: {
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          { attrs: { type: "is-primary", outlined: "" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("component.verify_account.resend"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "report-configuration" },
      [
        _c(
          "b-field",
          { attrs: { grouped: "", "group-multiline": "" } },
          [
            _c(
              "b-field",
              {
                staticClass: "mb-2",
                attrs: {
                  label: _vm.$t(
                    "component.report.configuration.modal.reportingInterval"
                  ),
                },
              },
              [
                _c(
                  "b-select",
                  {
                    on: { input: _vm.reportingIntervalChanged },
                    model: {
                      value: _vm.interval,
                      callback: function ($$v) {
                        _vm.interval = $$v
                      },
                      expression: "interval",
                    },
                  },
                  _vm._l(_vm.allReportingIntervals, function (interval) {
                    return _c(
                      "option",
                      { key: interval[0], domProps: { value: interval[0] } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.reportingIntervals." +
                                  interval[1]
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticClass: "mb-2",
                attrs: {
                  label: _vm.$t(
                    "component.report.configuration.modal.reportingInterval"
                  ),
                },
              },
              [
                _c(
                  "b-radio-button",
                  {
                    attrs: { "native-value": _vm.StatisticsType.AVERAGE },
                    on: { input: _vm.typeChanged },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.report.configuration.modal.averageValues"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "b-tooltip",
                      {
                        staticClass: "pl-2",
                        attrs: {
                          type: "is-dark",
                          position: "is-bottom",
                          label: _vm.$t(
                            "component.report.configuration.modal.average_note"
                          ),
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "has-text-grey",
                          attrs: { icon: "question-circle" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-radio-button",
                  {
                    attrs: { "native-value": _vm.StatisticsType.DAILY },
                    on: { input: _vm.typeChanged },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.report.configuration.modal.measuredValues"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.hasOneDayIntervalSelected == false
              ? _c(
                  "b-field",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      label: _vm.$t(
                        "component.report.configuration.modal.measuredAt"
                      ),
                    },
                  },
                  [
                    _vm.canHaveOffset
                      ? _c(
                          "b-select",
                          {
                            on: {
                              input: function ($event) {
                                return _vm.measuredOffsetChanged()
                              },
                            },
                            model: {
                              value: _vm.measurementOffsetIndex,
                              callback: function ($$v) {
                                _vm.measurementOffsetIndex = $$v
                              },
                              expression: "measurementOffsetIndex",
                            },
                          },
                          _vm._l(_vm.allMeasuredTimes, function (time, index) {
                            return _c(
                              "option",
                              { key: time, domProps: { value: index } },
                              [_vm._v(" " + _vm._s(time) + " ")]
                            )
                          }),
                          0
                        )
                      : _c("b-select", { attrs: { disabled: "" } }, [
                          _c("option", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.report.configuration.modal.measuredAt"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                  ],
                  1
                )
              : _c(
                  "b-field",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      label: _vm.$t(
                        "component.report.configuration.modal.measuredAt"
                      ),
                    },
                  },
                  [
                    _vm.canHaveOffset
                      ? _c("b-clockpicker", {
                          attrs: {
                            placeholder: _vm.$t("placeholders.click_select"),
                            icon: "clock",
                            "hour-format": _vm.hourFormatInClockPicker,
                            "hours-label": _vm.$t("placeholders.hours"),
                            "minutes-label": _vm.$t("placeholders.minutes"),
                            editable: "",
                          },
                          on: { input: _vm.clockChanged },
                          model: {
                            value: _vm.timeOfMeasurement,
                            callback: function ($$v) {
                              _vm.timeOfMeasurement = $$v
                            },
                            expression: "timeOfMeasurement",
                          },
                        })
                      : _c("b-clockpicker", {
                          attrs: {
                            disabled: "",
                            placeholder: _vm.$t("placeholders.click_select"),
                            icon: "clock",
                          },
                          on: { input: _vm.clockChanged },
                        }),
                  ],
                  1
                ),
            _c(
              "b-field",
              {
                staticClass: "month-picker mb-2",
                attrs: {
                  label: _vm.$t(
                    "component.report.configuration.modal.date_range"
                  ),
                },
              },
              [
                _c(
                  "b-datepicker",
                  {
                    attrs: {
                      placeholder: _vm.$t(
                        "component.report.month_picker.placeholder"
                      ),
                      name: _vm.$t("component.report.month_picker.placeholder"),
                      "mobile-native": false,
                      range: "",
                      "date-formatter": function (date) {
                        return _vm.dateTimeManager.formatTimeRangeInInput(date)
                      },
                      "nearby-selectable-month-days": true,
                      "max-date": new Date(),
                      "first-day-of-week": 1,
                    },
                    on: { input: _vm.rangeValueChanged },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "has-text-danger is-italic has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.configuration.modal.range_note",
                                { days: _vm.reportRange }
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticClass: "mb-2",
                attrs: {
                  label: _vm.$t(
                    "component.report.configuration.modal.show_empty_records"
                  ),
                },
              },
              [
                _c("b-switch", {
                  staticClass: "mt-2",
                  model: {
                    value: _vm.currentShowEmpty,
                    callback: function ($$v) {
                      _vm.currentShowEmpty = $$v
                    },
                    expression: "currentShowEmpty",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass: "is-align-self-flex-end",
                attrs: { type: "is-primary", outlined: "" },
                on: {
                  click: function ($event) {
                    return _vm.applyConfiguration()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.view")))]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
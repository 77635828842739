import { __decorate } from "tslib";
import UserPreferences from '@/services/UserPreferences';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SortDirections from '@/entities/enums/SortDirections';
import AppConfig from '@/configLoader';
import VueUtilities from '@/services/VueUtilities';
import debounce from 'lodash.debounce';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
const DEBOUNCE_TIMER = 500;
let AdminDeviceFirmwareList = class AdminDeviceFirmwareList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.selectedValue = '';
        this.isIdSearch = true;
        this.isCreatedBySearch = false;
        this.isVersionSearch = false;
        this.idSearch = '';
        this.createdBySearch = '';
        this.versionSearch = '';
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.currentFilter = [];
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.tableColumns = {
            id: {
                field: 'Id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListId),
                hasSearch: true
            },
            createdBy: {
                field: 'createdBy',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListCreatedBy),
                hasSearch: false
            },
            version: {
                field: 'Version',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListVersion),
                hasSearch: true
            },
            createdAt: {
                field: 'CreatedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListCreatedAt),
                hasSearch: false
            }
        };
        this.handleFilterIdDebounce = debounce((e) => {
            this.filterFirmware(this.tableColumns.id.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterVersionDebounce = debounce((e) => {
            this.filterFirmware(this.tableColumns.version.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    async created() {
        this.selectedValue = this.tableColumns.id.field;
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadFirmware', this.pagination, this.sort, this.currentFilter);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminFirmware);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            this.currentFilter = JSON.parse(this.tableQueriesfromUrl.Filter);
            let objStringAdminFirmware = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromFirmware', objStringAdminFirmware);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminDeviceFirmwarePerPage.defaultValue;
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.idSearch =
                this.filterExists('Id==') !== -1 ? this.currentFilter[this.filterExists('Id==')].substring(4) : '';
            this.versionSearch =
                this.filterExists('Version@=') !== -1 ? this.currentFilter[this.filterExists('Version@=')].substring(9) : '';
        }
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter);
        this.$emit('onOptionsChange', options, this.currentFilter);
    }
    jumpToPageSelected(page) {
        if (Number.isInteger(page) && page > 0) {
            this.page = page;
            this.onPageChange(page);
        }
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterFirmware(searchField, value) {
        switch (searchField) {
            case this.tableColumns.id.field:
                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        this.replaceFilter('Id==', value);
                    }
                    else {
                        return;
                    }
                });
                break;
            case this.tableColumns.version.field:
                this.replaceFilter('Version@=', value);
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange(this.pagination, this.sort);
            }
        });
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    clearFilter() {
        this.idSearch = '';
        this.createdBySearch = '';
        this.versionSearch = '';
        this.currentFilter = [];
        this.onPageChange(1);
    }
    newFirmware() {
        this.$emit('openAdminFirmwareModal');
    }
    changeSearchField(event) {
        this.isCreatedBySearch = false;
        this.isIdSearch = false;
        this.isVersionSearch = false;
        switch (event) {
            case this.tableColumns.id.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.version.field:
                this.isVersionSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    changeRouterUrl(pagination, sort, currentFilter) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            Filter: JSON.stringify(currentFilter.filter((x) => x != null))
        };
        let objStringAdminFirmware = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromFirmware', objStringAdminFirmware);
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.id.field;
        this.changeSearchField(this.tableColumns.id.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.createdBy.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListCreatedBy, event);
                break;
            case this.tableColumns.version.field:
                if (!event) {
                    this.versionSearch = '';
                    this.changeDefaultField();
                    this.filterFirmware(this.tableColumns.version.field, this.versionSearch);
                    if (this.sort.field == this.tableColumns.version.field)
                        this.sort.field = this.tableColumns.createdAt.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListVersion, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.createdAt.field)
                        this.sort.field = this.tableColumns.id.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceFirmwareListCreatedAt, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminDeviceFirmwareList.prototype, "deviceFirmwareList", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceFirmwareList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], AdminDeviceFirmwareList.prototype, "totalSize", void 0);
AdminDeviceFirmwareList = __decorate([
    Component({})
], AdminDeviceFirmwareList);
export default AdminDeviceFirmwareList;

var EventDomain;
(function (EventDomain) {
    EventDomain["MEASURED"] = "Measured";
    EventDomain["SERVICE"] = "Service";
    EventDomain["DEBUG"] = "Debug";
})(EventDomain || (EventDomain = {}));
export default EventDomain;
export function getEventDomain(domainName) {
    if (!domainName)
        return domainName;
    return EventDomain[domainName.toUpperCase()];
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from 'vee-validate/dist/locale/en';
import sk from 'vee-validate/dist/locale/sk';
import de from 'vee-validate/dist/locale/de';
import cs from 'vee-validate/dist/locale/cs';

//import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';

Vue.use(VueI18n);

const messages = {
  sk: require('./sk.json'),
  en: require('./en.json'),
  de: require('./de.json'),
  cs: require('./cs.json')
};

VeeValidate.Validator.localize({
  sk,
  en,
  de,
  cs
});

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 **/
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here
  if (this.locale == 'sk' || this.locale == 'cs') {
    if (choice === 0) {
      return 0;
    }

    if (choice === 1) {
      return 1;
    }

    if (choice > 1 && choice < 5) {
      return 2;
    }

    return 3;
  } else {
    if (choicesLength === 2) {
      return choice ? (choice > 1 ? 1 : 0) : 1;
    }
    return choice ? Math.min(choice, 2) : 0;
  }
};

// Create VueI18n instance with options

const i18n = new VueI18n({
  locale: 'sk',
  fallbackLocale: 'sk',
  messages // set locale messages
});

export default i18n;

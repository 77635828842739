import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { AdminFirmware } from '@/entities/models/AdminFirmware';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiResponse from '@/entities/ApiResponse';
import { ApiQueries } from '@/entities/ApiQueries';
export default class UserRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getFirmwaresList(pagination = null, sort = null, filter = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_FIRMWARES)) {
            return parsedResponse;
        }
        let url = ApiLinks.Gateways.Firmwares;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(AdminFirmware.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async getFirmware(firmwareId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_FIRMWARES)) {
            throw 'Forbidden action getFirmware';
        }
        let url = `${ApiLinks.Gateways.AdminHome}${ApiLinks.Gateways.Keys.firmwares}/${firmwareId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminFirmware();
        else
            return AdminFirmware.fromApi(response.data.data);
    }
    async deleteFirmware(firmwareId) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_GATEWAYS_FIRMWARES)) {
            throw 'Forbidden action deleteFirmware';
        }
        let url = `${ApiLinks.Gateways.AdminHome}${ApiLinks.Gateways.Keys.firmwares}/${firmwareId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async editFirmware(firmwareId, data) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_GATEWAYS_FIRMWARES)) {
            throw 'Forbidden action editFirmware';
        }
        let url = `${ApiLinks.Gateways.AdminHome}${ApiLinks.Gateways.Keys.firmwares}/${firmwareId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async createFirmware(firmwareDto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_GATEWAYS_FIRMWARES)) {
            throw 'Forbidden action createFirmware';
        }
        let url = `${ApiLinks.Gateways.AdminHome}${ApiLinks.Gateways.Keys.firmwares}`;
        let formData = new FormData();
        formData.append('Version', firmwareDto.Version);
        formData.append('Files[0].File', firmwareDto.Files[0].File);
        formData.append('Files[0].Name', firmwareDto.Files[0].Name);
        formData.append('Description', firmwareDto.Description);
        let response = await AxiosService.postDataToServer(url, formData, this.ctx);
        if (response.status == ApiStatus.CREATED)
            return true;
        else
            return response.data;
    }
}

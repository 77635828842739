import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import RegistrationDto from '@/entities/RegistrationDto';
import VueUtilities from '@/services/VueUtilities';
import DateTimeManager from '@/services/DateTimeManager';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ApiStatus from '@/entities/enums/apiStatuses';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var authenticationCalls;
let Register = class Register extends Vue {
    constructor() {
        super(...arguments);
        this.registrationDto = new RegistrationDto();
        this.dateTimeManager = null;
        this.isLoading = false;
        this.isInvited = false;
        this.confirmation = null;
        this.value = null;
        this.privacyPolicyFlag = null;
        this.isValidPhoneNumber = true;
        this.errorResponse = {};
        this.connectionTypes = {
            wifi: 'wifi',
            eth: 'ethernet',
            none: 'none'
        };
        this.additionalData = {
            connection: null,
            connectionRange: null,
            loggerCount: null,
            roomCount: null,
            address: null,
            newsletter: false
        };
        this.companyName = null;
    }
    async beforeCreate() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this, true);
        let queryData = this.$route.query;
        if (queryData.hasOwnProperty('token')) {
            this.rawToken = queryData.token;
            try {
                let parsedToken = JSON.parse(atob(this.rawToken.split('.')[1].replace('-', '+').replace('_', '/')));
                if (Date.now() >= parsedToken.exp * 1000) {
                    VueUtilities.openErrorToast(this, this.$t('component.register.tokenExp'));
                }
                else {
                    this.isInvited = true;
                    let companyDTO = await authenticationCalls.checkToken(this.rawToken);
                    this.companyName = companyDTO.CompanyName;
                    this.registrationDto.Email = parsedToken.inv ? parsedToken.inv : null;
                }
            }
            catch (error) { }
        }
    }
    async submitRegistration() {
        let hasError = false;
        const passwordValid = await this.$refs.passwordFields.validate();
        if (this.privacyPolicyFlag == false) {
            VueUtilities.openErrorToast(this, this.$t('component.register.additional_info.privacy_policy_error'));
            hasError = true;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result && passwordValid && !hasError) {
                this.isLoading = true;
                this.registrationDto.Username = this.registrationDto.Email;
                if (!this.isInvited)
                    this.registrationDto.CompanyName = this.companyName;
                this.registrationDto.InvitationToken = this.rawToken;
                if (!this.isInvited) {
                    this.additionalData.roomCount = this.additionalData.roomCount ? parseInt(this.additionalData.roomCount) : 0;
                    this.additionalData.loggerCount = this.additionalData.loggerCount
                        ? parseInt(this.additionalData.loggerCount)
                        : 0;
                    this.registrationDto.CustomerData = this.additionalData;
                }
                else {
                    this.registrationDto.CustomerData = {
                        newsletter: this.additionalData.newsletter
                    };
                }
                this.errorResponse = {};
                let response = await authenticationCalls.register(this.registrationDto);
                if (response.status === ApiStatus.BAD_REQUEST && response.data?.code === ApiResponseCodes.VALIDATION_ERROR) {
                    for (const [key, value] of Object.entries(response.data?.errors)) {
                        this.errorResponse[key] = value;
                    }
                    VueUtilities.openErrorToast(this, this.$t('component.register.bad_request'));
                    this.isLoading = false;
                }
                else if (response === true) {
                    this.$router
                        .push({
                        name: 'registrationSuccessful',
                        params: { lang: this.$route.params.lang }
                    })
                        .catch((err) => err);
                }
                else {
                    this.errorResponse = response?.data?.errors || [];
                    VueUtilities.openErrorToast(this, this.$t('component.register.bad_request'));
                }
                this.isLoading = false;
            }
            else {
                if (this.$validator.errors.items)
                    this.$el.querySelector('[name=' + this.$validator.errors.items[0].field + ']').scrollIntoView();
            }
        });
    }
    phoneNumberChanged() {
        delete this.errorResponse['PhoneNumber'];
    }
    hasErrorMsg(fieldName) {
        if (this.errorResponse[fieldName])
            return true;
        else
            return false;
    }
    getErrorMsg(fieldName) {
        if (this.errorResponse[fieldName])
            return this.errorResponse[fieldName];
        else
            return null;
    }
    get isFlutter() {
        let platform = this.$cookies.get('platform');
        return platform == 'flutter';
    }
};
Register = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider
        }
    })
], Register);
export default Register;

class NotificationPriority {
    constructor() {
        this.HIGHEST = { name: 'Highest', type: 'is-danger' };
        this.HIGH = { name: 'High', type: 'is-warning' };
        this.NORMAL = { name: 'Normal', type: 'is-primary' };
        this.LOW = { name: 'Low', type: 'is-success' };
        this.LOWEST = { name: 'Lowest', type: 'is-info' };
        this.UNKNOWN = { name: 'Unknown', type: 'is-black' };
    }
    getNotificationPriorityByName(name) {
        let priority = Object.values(this).find((x) => x.name == name);
        if (!priority)
            priority = this.UNKNOWN;
        return priority;
    }
}
export default new NotificationPriority();

import CalibrationStatus from '@/entities/enums/CalibrationStatus';
export class CalibrationInclude {
    constructor() { }
    static fromApi(data) {
        return CalibrationInclude.define(data);
    }
    static define(data) {
        let calibration = new CalibrationInclude();
        if (data == null) {
            calibration.calibratedAt = 0;
            calibration.expiresAt = 0;
            calibration.verifiedAt = 0;
            calibration.status = CalibrationStatus.NOT_CALIBRATED;
        }
        else {
            calibration.calibratedAt = data.CalibratedAt;
            calibration.expiresAt = data.ExpiresAt;
            calibration.verifiedAt = data.VerifiedAt;
            calibration.setCalibrationStatus();
        }
        return calibration;
    }
    setCalibrationStatus() {
        let currentTS = Math.floor(new Date().getTime() / 1000);
        if (this.calibratedAt <= currentTS && this.expiresAt >= currentTS)
            this.status = CalibrationStatus.CALIBRATED;
        else if (this.expiresAt < currentTS)
            this.status = CalibrationStatus.EXPIRED;
        else
            this.status = CalibrationStatus.NOT_CALIBRATED;
    }
}
export class FullCalibration extends CalibrationInclude {
    static fromApi(data) {
        if (data != null) {
            return FullCalibration.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let calibration = new FullCalibration();
        calibration.calibratedAt = data.CalibratedAt;
        calibration.expiresAt = data.ExpiresAt;
        calibration.verifiedAt = data.VerifiedAt;
        calibration.setCalibrationStatus();
        return calibration;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "multi-header has-bottom-margin" }, [
      _c(
        "div",
        {
          staticClass:
            "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
        },
        [
          _c(
            "h2",
            { staticClass: "subtitle is-4 is-spaced has-text-centered-touch" },
            [_vm._v(" " + _vm._s(_vm.$t("company_settings.title")) + " ")]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _vm.$ability.can(
            _vm.$permActions.UPDATE,
            _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
          )
            ? _c("b-button", {
                attrs: { type: "is-primary", label: _vm.$t("generals.add") },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.isAdding == true
      ? _c("section", [
          _c("hr"),
          _c("h3", { staticClass: "subtitle is-5" }, [
            _vm._v(_vm._s(_vm.$t("company_settings.add_new"))),
          ]),
          _c(
            "div",
            {
              staticClass:
                "is-flex is-justify-content-flex-start is-flex-direction-row",
            },
            [
              _c(
                "b-field",
                { attrs: { grouped: "", "group-multiline": "" } },
                [
                  _c(
                    "b-field",
                    {
                      staticClass: "is-flex-grow-2 mr-1 mb-5",
                      attrs: {
                        label: _vm.$t("company_settings.key"),
                        type: {
                          "is-danger": _vm.errors.has(
                            _vm.$t("company_settings.key")
                          ),
                        },
                        message: _vm.errors.first(
                          _vm.$t("company_settings.key")
                        ),
                        "label-position": "on-border",
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          type: "textarea",
                          expanded: "",
                          name: _vm.$t("company_settings.key"),
                        },
                        model: {
                          value: _vm.selectedFieldLabel,
                          callback: function ($$v) {
                            _vm.selectedFieldLabel = $$v
                          },
                          expression: "selectedFieldLabel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      staticClass: "is-flex-grow-3 mr-1 mb-5",
                      attrs: {
                        label: _vm.$t("company_settings.value"),
                        "label-position": "on-border",
                        type: {
                          "is-danger": _vm.errors.has(
                            _vm.$t("company_settings.value")
                          ),
                        },
                        message: _vm.errors.first(
                          _vm.$t("company_settings.value")
                        ),
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          type: "textarea",
                          name: _vm.$t("company_settings.value"),
                          expanded: "",
                        },
                        model: {
                          value: _vm.selectedFieldValue,
                          callback: function ($$v) {
                            _vm.selectedFieldValue = $$v
                          },
                          expression: "selectedFieldValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      staticClass: "is-flex-grow-1 mb-5",
                      attrs: {
                        label: _vm.$t("company_settings.permission"),
                        "label-position": "on-border",
                        type: {
                          "is-danger": _vm.errors.has(
                            _vm.$t("company_settings.permission")
                          ),
                        },
                        message: _vm.errors.first(
                          _vm.$t("company_settings.permission")
                        ),
                      },
                    },
                    [
                      _c(
                        "b-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            placeholder: _vm.$t("company_settings.permission"),
                            name: _vm.$t("company_settings.permission"),
                          },
                          model: {
                            value: _vm.selectedPermissionsValue,
                            callback: function ($$v) {
                              _vm.selectedPermissionsValue = $$v
                            },
                            expression: "selectedPermissionsValue",
                          },
                        },
                        _vm._l(
                          _vm.AdminCompanySettingPermissions,
                          function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttons is-centered" },
            [
              _c("b-button", {
                attrs: { type: "is-danger", label: _vm.$t("generals.close") },
                on: {
                  click: function ($event) {
                    return _vm.cancelAdding()
                  },
                },
              }),
              _c("b-button", {
                attrs: { type: "is-success", label: _vm.$t("generals.save") },
                on: {
                  click: function ($event) {
                    return _vm.saveNewSetting()
                  },
                },
              }),
            ],
            1
          ),
          _c("hr"),
        ])
      : _vm._e(),
    _c(
      "section",
      [
        _c(
          "b-table",
          {
            staticClass: "table-wrap",
            attrs: { data: _vm.companySettings.getSettings(), striped: "" },
          },
          [
            _c("b-table-column", {
              attrs: { field: "Key", label: _vm.$t("company_settings.key") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (settings) {
                    return [
                      _c("span", [_vm._v(" " + _vm._s(settings.row.Key))]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "Value",
                label: _vm.$t("company_settings.value"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (settings) {
                    return [
                      _vm.fieldEditing == settings.row.Key
                        ? _c(
                            "b-field",
                            [
                              settings.row.Key == "setupRequired"
                                ? _c("b-checkbox", {
                                    model: {
                                      value: _vm.selectedFieldValue,
                                      callback: function ($$v) {
                                        _vm.selectedFieldValue = $$v
                                      },
                                      expression: "selectedFieldValue",
                                    },
                                  })
                                : _c("b-input", {
                                    attrs: {
                                      type: "textarea",
                                      name: settings.row.Key + "_value",
                                      expanded: "",
                                    },
                                    model: {
                                      value: _vm.selectedFieldValue,
                                      callback: function ($$v) {
                                        _vm.selectedFieldValue = $$v
                                      },
                                      expression: "selectedFieldValue",
                                    },
                                  }),
                            ],
                            1
                          )
                        : [
                            _c("p", { staticClass: "text-wrap-anywhere" }, [
                              _vm._v(" " + _vm._s(settings.row.Value) + " "),
                            ]),
                          ],
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "Permission",
                label: _vm.$t("company_settings.permission"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (settings) {
                    return [
                      _vm.fieldEditing == settings.row.Key
                        ? _c(
                            "b-field",
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "company_settings.permission"
                                    ),
                                    name: settings.row.Key + "_permission",
                                  },
                                  model: {
                                    value: _vm.selectedPermissionsValue,
                                    callback: function ($$v) {
                                      _vm.selectedPermissionsValue = $$v
                                    },
                                    expression: "selectedPermissionsValue",
                                  },
                                },
                                _vm._l(
                                  _vm.AdminCompanySettingPermissions,
                                  function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option,
                                        domProps: { value: option },
                                      },
                                      [_vm._v(" " + _vm._s(option) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(" " + _vm._s(settings.row.Permission)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "CreatedAt",
                label: _vm.$t("company_settings.created_at"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (settings) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(settings.row.CreatedAt * 1000)
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "UpdatedAt",
                label: _vm.$t("company_settings.updated_at"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (settings) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(settings.row.UpdatedAt * 1000)
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: { label: _vm.$t("generals.action") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (settings) {
                    return [
                      _vm.fieldEditing == settings.row.Key
                        ? [
                            _c("b-button", {
                              attrs: {
                                "icon-right": "times-circle",
                                type: "is-white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelEdit()
                                },
                              },
                            }),
                            _c("b-button", {
                              attrs: {
                                type: "is-primary",
                                label: _vm.$t("generals.save"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveEdit(settings.row.Key)
                                },
                              },
                            }),
                          ]
                        : [
                            _vm.$ability.can(
                              _vm.$permActions.UPDATE,
                              _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
                            )
                              ? _c("b-button", {
                                  attrs: {
                                    "icon-right": "pencil-alt",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSettings(settings.row.Key)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$ability.can(
                              _vm.$permActions.DELETE,
                              _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
                            )
                              ? _c("b-button", {
                                  attrs: {
                                    "icon-right": "trash",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmSettingDelete(
                                        settings.row.Key
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { CompanyLogger } from './CompanyLogger';
import { getLoggerState } from '../enums/LoggerStates';
import { BatteryState } from './BatteryState';
const ALIVE_OFFSET = 600;
export class Logger {
    constructor() {
        this.additionalProperties = {
            isOnline: 'false'
        };
        this.publishedAs = new Array();
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Logger.define(et));
        }
        else if (data != null) {
            return Logger.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let logger = new Logger();
        logger.id = data.LoggerId;
        logger.macAddress = data.MacAddress;
        logger.registeredAt = data.RegisteredAt;
        logger.lastSeen = data.LastSeen;
        logger.state = getLoggerState(data.State);
        logger.rawBattery = data.Battery;
        logger.note = data.Note;
        if (data.PublishedAs)
            logger.publishedAs = CompanyLogger.fromApi(data.PublishedAs);
        if (data.CurrentlyPublishedAs)
            logger.currentlyPublishedAs = CompanyLogger.fromApi(data.CurrentlyPublishedAs);
        if (data.Battery)
            logger.battery = BatteryState.fromApi(data.Battery);
        logger.additionalProperties.isOnline = data.CurrentlyPublishedAs
            ? data.CurrentlyPublishedAs.IsOnline.toString()
            : 'false';
        return logger;
    }
    isCalibrated() {
        return this.currentlyPublishedAs && this.currentlyPublishedAs.isCalibrated();
    }
}

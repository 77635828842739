var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("component.device.list.title")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _vm.$ability.can(
                _vm.$permActions.REGISTER,
                _vm.$permSubjects.DEVICE
              ) &&
              _vm.$featuresManager.isAvailable(
                _vm.$features.OBSERVER_IN_COMPANY
              )
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      attrs: { type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.registerNewDevice()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("component.device.list.button_label"))
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "is-dark-grey",
                                      staticStyle: { "font-size": "1rem" },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field ==
                                  _vm.tableColumns.deviceName.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.device.list.table." +
                                        column.field
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "table-wrap",
          attrs: {
            data: _vm.deviceList,
            paginated: "",
            "backend-pagination": "",
            "per-page": _vm.pageSize,
            total: _vm.allDevicesCount,
            "current-page": _vm.page,
            striped: "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "page-input": true,
            "pagination-order": "is-centered",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
            "filters-change": function ($event) {
              return _vm.$buefy.toast.open("search")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.deviceList && _vm.deviceList.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.allDevicesCount,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.deviceName.field,
              label: _vm.$t(
                "component.device.list.table." +
                  _vm.tableColumns.deviceName.field
              ),
              visible: _vm.tableColumns.deviceName.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceList) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "deviceConf",
                            params: {
                              deviceId: deviceList.row.deviceId,
                              departmentId: _vm.$route.params.departmentId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(deviceList.row.deviceName) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.description.field,
              label: _vm.$t(
                "component.device.list.table." +
                  _vm.tableColumns.description.field
              ),
              visible: _vm.tableColumns.description.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceList) {
                  return [
                    _c("p", { staticClass: "description" }, [
                      _vm._v(_vm._s(deviceList.row.description)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.registeredAt.field,
              label: _vm.$t(
                "component.device.list.table." +
                  _vm.tableColumns.registeredAt.field
              ),
              visible: _vm.tableColumns.registeredAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceList) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(deviceList.row.registeredAt * 1000)
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.lastSeen.field,
              label: _vm.$t(
                "component.device.list.table." + _vm.tableColumns.lastSeen.field
              ),
              visible: _vm.tableColumns.lastSeen.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceList) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(deviceList.row.lastSeen * 1000)
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.isOnline.field,
              label: _vm.$t(
                "component.device.list.table." + _vm.tableColumns.isOnline.field
              ),
              visible: _vm.tableColumns.isOnline.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceList) {
                  return [
                    _c(
                      "div",
                      { staticClass: "is-flex is-align-items-center" },
                      [
                        _c("b-icon", {
                          staticClass: "is-size-7",
                          attrs: {
                            type:
                              deviceList.row.isOnline == true
                                ? "is-success"
                                : "is-danger",
                            icon: "circle",
                          },
                        }),
                        deviceList.row.isOnline == true
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("generals.online"))),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("generals.offline"))),
                            ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("admin.component.devices.deviceList.table.empty")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.$ability.can(_vm.$permActions.REGISTER, _vm.$permSubjects.DEVICE)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isPrivateKeyModalActive,
                "has-modal-card": "",
                onCancel: _vm.onModalClose,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isPrivateKeyModalActive = $event
                },
                "close-modal": _vm.closeModal,
              },
            },
            [
              _c("DeviceRegistrationModal", {
                on: { modalClosed: _vm.closeModal },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
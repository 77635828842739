import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DecimalConversion from '@/services/DecimalConversion';
let BoundaryDtoInfo = class BoundaryDtoInfo extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
    }
    created() { }
};
__decorate([
    Prop({ type: Array })
], BoundaryDtoInfo.prototype, "boundaries", void 0);
BoundaryDtoInfo = __decorate([
    Component({})
], BoundaryDtoInfo);
export default BoundaryDtoInfo;

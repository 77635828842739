var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "p",
          {
            staticClass:
              "subtitle has-text-centered has-text-primary has-text-weight-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.message) + " ")]
        ),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("component.userAdministration.list.disable.reason"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.userAdministration.list.disable.reason")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.userAdministration.list.disable.reason")
              ),
              "label-position": "on-border",
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                placeholder: _vm.$t(
                  "component.userAdministration.list.disable.reason"
                ),
                name: _vm.$t(
                  "component.userAdministration.list.disable.reason"
                ),
                type: "text",
              },
              model: {
                value: _vm.reason,
                callback: function ($$v) {
                  _vm.reason = $$v
                },
                expression: "reason",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.$t("component.userAdministration.list.disable.deny"))
            ),
          ]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary", disabled: !_vm.hasChanges },
            on: {
              click: function ($event) {
                return _vm.confirm()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.confirmBtn) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
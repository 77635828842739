var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showMsg
        ? _c(
            "b-message",
            {
              staticClass: "modal-card wizard-card pt-4",
              attrs: { type: "is-success" },
            },
            [
              _c("span", { staticClass: "has-text-weight-bold" }, [
                _vm._v(_vm._s(_vm.skipMessage)),
              ]),
              _c(
                "div",
                { staticClass: "has-text-centered wizard-steps-buttons pt-4" },
                [
                  _vm.backAllowed
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            "icon-pack": "fa",
                            "icon-left": "angle-double-left",
                            type: "is-primary",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.backStep(_vm.actualStep)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("generals.back")))]
                      )
                    : _vm._e(),
                  _vm.skipAllowed
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            "icon-pack": "fa",
                            "icon-right": "angle-double-right",
                            type: _vm.canSkip ? "is-success" : "is-primary",
                            outlined: !_vm.canSkip,
                            disabled: !_vm.canSkip,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.skipStep(_vm.actualStep)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("generals.continue")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            { staticClass: "has-text-centered wizard-steps-buttons" },
            [
              _vm.backAllowed
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        "icon-pack": "fa",
                        "icon-left": "angle-double-left",
                        type: "is-primary",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.backStep(_vm.actualStep)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("generals.back")))]
                  )
                : _vm._e(),
              _vm.skipAllowed
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        "icon-pack": "fa",
                        "icon-right": "angle-double-right",
                        type: _vm.canSkip ? "is-success" : "is-primary",
                        outlined: !_vm.canSkip,
                        disabled: !_vm.canSkip,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.skipStep(_vm.actualStep)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("generals.continue")))]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { createModule } from 'vuex-class-component';
const VuexModule = createModule({
    namespaced: 'abortController',
    strict: false
});
/**
 * Store for AbortController management
 * creates 1 instance of controller, which can be added to AxiosCalls
 * allows to cancel multiple AxiosCalls at once (with same controller instance)
 * currently unused
 */
export class AbortControllerStore extends VuexModule {
    constructor() {
        super(...arguments);
        this.abortController = new AbortController();
        this.isLoading = false;
        this.isAborted = false;
    }
    get controller() {
        return this.abortController;
    }
    set controller(value) {
        this.abortController = value;
    }
    get loadingState() {
        return this.isLoading;
    }
    set loadingState(value) {
        this.isLoading = value;
    }
    get aborted() {
        return this.isAborted;
    }
    set aborted(value) {
        this.isAborted = value;
    }
}

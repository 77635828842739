import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let AddNotificationRule = class AddNotificationRule extends Vue {
    created() { }
    redirectToNotification() {
        this.$router.replace({
            name: 'configuration_notifications',
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            },
            query: { showRuleModal: 'true' }
        });
    }
};
AddNotificationRule = __decorate([
    Component({ components: {} })
], AddNotificationRule);
export default AddNotificationRule;

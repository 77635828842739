import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let InvitationCard = class InvitationCard extends Vue {
    acceptInv(invitation) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.invitation.accept_dialog.title', {
                departmentName: invitation.department.CompanyName
            }),
            message: this.$t('component.invitation.accept_dialog.message'),
            confirmText: `${this.$t('component.invitation.accept_dialog.confirm')}`,
            cancelText: `${this.$t('generals.close')}`,
            type: 'is-success',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('acceptInvite', invitation.id);
            },
            onCancel: () => { }
        });
    }
    declineInv(invitation) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.invitation.decline_dialog.title', {
                departmentName: invitation.department.CompanyName
            }),
            message: this.$t('component.invitation.decline_dialog.message'),
            confirmText: `${this.$t('component.invitation.decline_dialog.confirm')}`,
            cancelText: `${this.$t('generals.close')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('declineInvite', invitation.id);
            },
            onCancel: () => { }
        });
    }
};
__decorate([
    Prop({ type: Array })
], InvitationCard.prototype, "invitations", void 0);
__decorate([
    Prop({ type: String, default: null })
], InvitationCard.prototype, "deptId", void 0);
__decorate([
    Prop({ type: Object })
], InvitationCard.prototype, "dateTimeManager", void 0);
InvitationCard = __decorate([
    Component({})
], InvitationCard);
export default InvitationCard;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let InstructionsStep = class InstructionsStep extends Vue {
    showDetailedInfo() {
        this.$emit('showDetailedInstructions');
    }
    get isFlutter() {
        let platform = this.$cookies.get('platform');
        return platform == 'flutter';
    }
};
InstructionsStep = __decorate([
    Component({ components: {} })
], InstructionsStep);
export default InstructionsStep;

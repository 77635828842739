import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let AdminRegisteredLoggersComponent = class AdminRegisteredLoggersComponent extends Vue {
    created() { }
    deleteClicked(logger) {
        this.$emit('deleteLogger', logger.loggerId);
    }
};
__decorate([
    Prop({ type: Array })
], AdminRegisteredLoggersComponent.prototype, "loggerList", void 0);
__decorate([
    Prop({ type: Object })
], AdminRegisteredLoggersComponent.prototype, "dateTimeManager", void 0);
AdminRegisteredLoggersComponent = __decorate([
    Component({})
], AdminRegisteredLoggersComponent);
export default AdminRegisteredLoggersComponent;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
var deviceRepository;
var departmentRepository;
let RegisterToCompany = class RegisterToCompany extends Vue {
    constructor() {
        super(...arguments);
        this.companyList = new Array();
        this.selectedCompany = null;
        this.isLoading = true;
        this.name = '';
    }
    created() {
        deviceRepository = new DeviceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        this.loadData();
    }
    updated() {
        if (this.$refs.companyName) {
            this.$refs.companyName.focus();
        }
    }
    async loadData() {
        this.isLoading = true;
        this.companyList = await departmentRepository.getAllDepartmentsAdmin();
        this.isLoading = false;
    }
    get filteredCompanyList() {
        return this.companyList.filter((option) => {
            return option.companyName.toLowerCase().indexOf(this.name.toLowerCase()) >= 0;
        });
    }
    registerLogger() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let createResult = await deviceRepository.adminRegisterDevice(this.device.deviceId, this.selectedCompany.companyId);
                this.isLoading = false;
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.detail.deviceInfo.register.success'));
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.detail.deviceInfo.register.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], RegisterToCompany.prototype, "device", void 0);
RegisterToCompany = __decorate([
    Component({})
], RegisterToCompany);
export default RegisterToCompany;

export default class FileDownloader {
    static downloadFile(response, defaultFileName) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        if (defaultFileName.includes('pdf'))
            link.href = url;
        else
            link.href = window.URL.createObjectURL(new Blob([response.data]));
        let filename = FileDownloader.getFilenameFromResponse(response, defaultFileName);
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        if (filename.includes('pdf'))
            window.open(url); // opens new tab with the pdf file
        return true;
    }
    static getFilenameFromResponse(response, defaultFileName) {
        let headerLine = response.headers['content-disposition'];
        if (headerLine) {
            let startFileNameIndex = headerLine.indexOf('=') + 1;
            let endFileNameIndex = headerLine.lastIndexOf(';');
            return headerLine.substring(startFileNameIndex, endFileNameIndex);
        }
        else {
            return defaultFileName;
        }
    }
}

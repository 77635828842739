import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import FeedbackForm from '@/views/components/feedback/FeedbackForm.vue';
import ReleaseNotesComponent from '@/views/components/ReleaseNotesComponent.vue';
let Feedback = class Feedback extends Vue {
};
Feedback = __decorate([
    Component({ components: { FeedbackForm, ReleaseNotesComponent } })
], Feedback);
export default Feedback;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AdminCompanyDeletedSources from '@/views/components/admin/company/AdminCompanyDeletedSources.vue';
import AdminCompanyInfoComponent from '@/views/components/admin/company/AdminCompanyInfoComponent.vue';
import AdminCompanyUsersComponent from '@/views/components/admin/company/AdminCompanyUsersComponent.vue';
import AdminCompanyDevicesComponent from '@/views/components/admin/company/AdminCompanyDevicesComponent.vue';
import AdminCompanyLoggersComponent from '@/views/components/admin/company/AdminCompanyLoggersComponent.vue';
import AdminDepartmentSettingsList from '@/views/components/admin/company/AdminDepartmentSettingsList.vue';
import CompanyTiersHistory from '@/views/components/admin/tiers/CompanyTiersHistory.vue';
import ActiveCompanyTierDetail from '@/views/components/admin/tiers/ActiveCompanyTierDetail.vue';
import AppConfig from '@/configLoader';
import DashboardsList from '../../publicDashboard/DashboardsList.vue';
import UserPreferences from '@/services/UserPreferences';
import LoggedUserManager from '@/services/LoggedUserManager';
import IssuesTable from '../../issues/IssuesTable.vue';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
let AdminCompanyDetail = class AdminCompanyDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isModalActive = false;
        this.currentConfig = AppConfig.getConfig();
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsAdminCompanyDetailLayoutList);
        this.issuesLoaded = false;
        this.activeTab = UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
        this.devicesLoggersLoaded = false;
        this.tierLoaded = false;
        this.sharedLoaded = false;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.isSettingAdding = false;
        this.prevRoute = null;
    }
    loginToCompany() {
        this.$emit('loginIntoCompany', this.$route.name, this.$route.params);
    }
    created() {
        this.prevRoute = JSON.parse(localStorage.getItem(LocalStorageKeys.ADMIN_PREV_ROUTE));
        if (this.isLayoutList) {
            this.$emit('loadAll');
            this.devicesLoggersLoaded = true;
            this.tierLoaded = true;
            this.sharedLoaded = true;
            this.issuesLoaded = true;
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
    }
    async onIssuesTableOptionsChange(options, filters = []) {
        this.$emit('onIssuesTableOptionsChange', options, filters);
    }
    acknowledgeIssue(issueId) {
        this.$emit('acknowledgeIssue', issueId);
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IsAdminCompanyDetailLayoutList, this.isLayoutList);
        if (this.isLayoutList) {
            if (!this.devicesLoggersLoaded || !this.tierLoaded || !this.sharedLoaded || !this.issuesLoaded)
                this.$emit('loadAll');
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
    }
    changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceDetailLayoutActiveTab, event);
        switch (this.activeTab) {
            case SelectedTab.DEVICES_LOGGERS:
                if (!this.devicesLoggersLoaded) {
                    this.$emit('loadDevicesAndLoggers');
                    this.devicesLoggersLoaded = true;
                }
                break;
            case SelectedTab.TIER:
                if (!this.tierLoaded) {
                    this.$emit('loadTier');
                    this.tierLoaded = true;
                }
                break;
            case SelectedTab.SHARED_LINKS:
                if (!this.sharedLoaded) {
                    this.$emit('loadSharedLinks');
                    this.sharedLoaded = true;
                }
                break;
            case SelectedTab.ISSUES:
                if (!this.issuesLoaded) {
                    this.$emit('loadDevices');
                    this.$emit('loadDevicesAndLoggers');
                    this.issuesLoaded = true;
                }
                break;
        }
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
    deleteCompany() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.company.detail.deleteCompanyWarning.title')}`,
            message: this.$t('admin.component.company.detail.deleteCompanyWarning.message', {
                departmentId: this.company.companyName
            }),
            confirmText: `${this.$t('admin.component.company.detail.deleteCompanyWarning.confirm')}`,
            cancelText: `${this.$t('admin.component.company.detail.deleteCompanyWarning.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.confirmTodeleteCompany(this.company.companyId);
            },
            onCancel: () => { }
        });
    }
    downloadCalibration(id) {
        this.$emit('downloadCalibration', id);
    }
    addNewSetting() {
        this.activeTab = 0;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceDetailLayoutActiveTab, this.activeTab);
        this.isSettingAdding = true;
        const departmentSetting = this.$el.querySelector('#departmentSetting');
        departmentSetting.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    changeAddSettingState(state) {
        this.isSettingAdding = state;
    }
    confirmTodeleteCompany(departmentId) {
        this.$emit('deleteCompany', departmentId);
    }
    closeModal() {
        this.isModalActive = false;
    }
    openPauseNotificationsModal() {
        this.$emit('openPauseNotificationsModal');
    }
    openRegisterDevicesModal() {
        this.$emit('openRegisterDevicesModal');
    }
    openRegisterLoggersModal() {
        this.$emit('toggleRegisterLoggersModal');
    }
    openAddUsersModal() {
        this.$emit('openAddUsersModal');
    }
    openInviteUserModal() {
        this.$emit('openInviteUserModal');
    }
    openAssignTier() {
        this.$emit('openAssignTier');
    }
    updateCompany(data, departmentId) {
        this.$emit('updateCompany', data, departmentId);
    }
    unassignUser(user) {
        this.$emit('unassignUser', user);
    }
    changeRole(user) {
        this.$emit('openchangeUserRoleModal', user);
    }
    disableUserDialog(user) {
        this.$emit('disableUserDialog', user);
    }
    enableUserDialog(user) {
        this.$emit('enableUserDialog', user);
    }
    reloadTiers() {
        this.$emit('reloadTiers');
    }
    deactivateTier(tier) {
        this.$emit('deactivateTier', tier);
    }
    deleteTier(tier) {
        this.$emit('deleteTier', tier);
    }
    updateTier(dto) {
        this.$emit('updateTier', dto);
    }
    saveSetting(dto) {
        this.$emit('saveSetting', dto);
    }
    deleteSetting(key) {
        this.$emit('deleteSetting', key);
    }
    async loadDeletedSources(departmentId, pagination, sort) {
        this.$emit('loadDeletedSources', departmentId, pagination, sort);
    }
    async reviveSource(departmentId, key) {
        this.$emit('reviveSource', departmentId, key);
    }
    async deleteDashboard(id) {
        this.$emit('deleteDashboard', id);
    }
    async createPublicDashboard() {
        this.$emit('createPublicDashboard');
    }
};
__decorate([
    Prop({ type: Object })
], AdminCompanyDetail.prototype, "company", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyDetail.prototype, "users", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyDetail.prototype, "deviceList", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyDetail.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyDetail.prototype, "companyLoggers", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDetail.prototype, "activeTier", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyDetail.prototype, "tiersHistory", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminCompanyDetail.prototype, "sources", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDetail.prototype, "deletedSourcesApiResponse", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDetail.prototype, "companySettings", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyDetail.prototype, "dashboards", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDetail.prototype, "departmentIssues", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminCompanyDetail.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminCompanyDetail.prototype, "isIssuesTableLoading", void 0);
AdminCompanyDetail = __decorate([
    Component({
        components: {
            AdminCompanyInfoComponent,
            AdminCompanyUsersComponent,
            AdminCompanyDevicesComponent,
            AdminCompanyLoggersComponent,
            ActiveCompanyTierDetail,
            CompanyTiersHistory,
            AdminDepartmentSettingsList,
            AdminCompanyDeletedSources,
            DashboardsList,
            IssuesTable
        }
    })
], AdminCompanyDetail);
export default AdminCompanyDetail;
var SelectedTab;
(function (SelectedTab) {
    SelectedTab[SelectedTab["OVERVIEW"] = 0] = "OVERVIEW";
    SelectedTab[SelectedTab["DEVICES_LOGGERS"] = 1] = "DEVICES_LOGGERS";
    SelectedTab[SelectedTab["TIER"] = 2] = "TIER";
    SelectedTab[SelectedTab["SHARED_LINKS"] = 3] = "SHARED_LINKS";
    SelectedTab[SelectedTab["ISSUES"] = 4] = "ISSUES";
})(SelectedTab || (SelectedTab = {}));

import { __decorate } from "tslib";
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import { Component, Vue, Prop } from 'vue-property-decorator';
let ChangeUserRoleModal = class ChangeUserRoleModal extends Vue {
    constructor() {
        super(...arguments);
        this.ApiUserRoles = ApiUserRoles;
        this.newRole = null;
    }
    get availableUserRoles() {
        return Object.values(ApiUserRoles).filter((x) => x != this.user.role.Key && x != ApiUserRoles.COMPANY_OWNER);
    }
    created() { }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    save() {
        this.$emit('changeRole', this.newRole);
    }
};
__decorate([
    Prop({ type: Object })
], ChangeUserRoleModal.prototype, "user", void 0);
ChangeUserRoleModal = __decorate([
    Component({ components: {} })
], ChangeUserRoleModal);
export default ChangeUserRoleModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let LoggerSettings = class LoggerSettings extends Vue {
    constructor() {
        super(...arguments);
        this.offlineThreshold = null;
        this.offlineNotification = null;
        this.isEditing = false;
    }
    created() {
        this.offlineThreshold = this.secToMinutes(this.departmentSettings.Source.OfflineThreshold);
        this.offlineNotification = this.departmentSettings.Source.DoNotNotifyWhenDeviceOffline;
    }
    editSettings() {
        this.isEditing = !this.isEditing;
    }
    save() {
        this.$validator.validateAll().then((result) => {
            if (result) {
                if (this.offlineThreshold * 60 != this.departmentSettings.Source.OfflineThreshold)
                    this.$emit('updateLoggerThreshold', this.offlineThreshold * 60);
                if (this.offlineNotification != this.departmentSettings.Source.DoNotNotifyWhenDeviceOffline)
                    this.$emit('updateLoggerNotification', this.offlineNotification);
            }
        });
    }
    get hasChanges() {
        return (this.offlineThreshold * 60 != this.departmentSettings.Source.OfflineThreshold ||
            this.offlineNotification != this.departmentSettings.Source.DoNotNotifyWhenDeviceOffline);
    }
    secToMinutes(seconds) {
        if (seconds)
            return seconds / 60;
        else
            return null;
    }
};
__decorate([
    Prop({ type: Object })
], LoggerSettings.prototype, "departmentSettings", void 0);
LoggerSettings = __decorate([
    Component({
        components: {}
    })
], LoggerSettings);
export default LoggerSettings;

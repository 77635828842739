import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let BackgroundJobDetailModal = class BackgroundJobDetailModal extends Vue {
    close() {
        this.$emit('modalClosed');
    }
};
__decorate([
    Prop({ type: Object })
], BackgroundJobDetailModal.prototype, "backgroundJob", void 0);
BackgroundJobDetailModal = __decorate([
    Component({})
], BackgroundJobDetailModal);
export default BackgroundJobDetailModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header has-bottom-margin" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.company.detail.deletedSources.title")
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: {
            data: _vm.deletedSourcesApiResponse.getData(),
            striped: "",
            "backend-sorting": "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            paginated: "",
            "backend-pagination": "",
            "page-input": true,
            "pagination-order": "is-centered",
            "per-page": _vm.rowsPerPage,
            total: _vm.totalSize,
            "current-page": _vm.page,
          },
          on: {
            sort: _vm.onSort,
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "ml-2 has-width-5" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.rowsPerPage,
                              callback: function ($$v) {
                                _vm.rowsPerPage = $$v
                              },
                              expression: "rowsPerPage",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.deletedSourcesApiResponse.getData().length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "SourceName",
              label: _vm.$t(
                "admin.component.company.detail.deletedSources.list.name"
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(" " + _vm._s(props.row.name) + " ")]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "DeletedAt",
              label: _vm.$t(
                "admin.component.company.detail.deletedSources.list.deletedAt"
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(props.row.deletedAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "DeletedByUser",
              label: _vm.$t(
                "admin.component.company.detail.deletedSources.list.deletedBy"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(" " + _vm._s(props.row.deletedBy.fullName) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "action",
              label: _vm.$t(
                "admin.component.company.detail.deletedSources.list.action"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm.$ability.can(
                      _vm.$permActions.REVIVE,
                      _vm.$permSubjects.ADMIN_DEPARTMENTS_SOURCES
                    )
                      ? _c("b-icon", {
                          staticClass: "clickable",
                          attrs: { icon: "fas fa-redo" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.reviveDeletedSource(props.row.id)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.company.detail.deletedSources.noSources"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export var PdfReportTypes;
(function (PdfReportTypes) {
    PdfReportTypes["ONE_PAGE"] = "one-page";
    PdfReportTypes["FULL"] = "default";
    PdfReportTypes["RAW"] = "raw-data";
})(PdfReportTypes || (PdfReportTypes = {}));
export function getIcon(type) {
    switch (type) {
        case PdfReportTypes.FULL:
            return 'copy';
            break;
        case PdfReportTypes.ONE_PAGE:
            return 'compress-alt';
            break;
        case PdfReportTypes.RAW:
            return 'ruler';
            break;
        default:
            break;
    }
}
export var CsvReportTypes;
(function (CsvReportTypes) {
    CsvReportTypes["FULL"] = "default";
    CsvReportTypes["RAW"] = "raw-data";
})(CsvReportTypes || (CsvReportTypes = {}));

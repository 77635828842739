import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let AdminDeviceSelectComponent = class AdminDeviceSelectComponent extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDevice = null;
    }
    created() { }
    connectToDevice() {
        this.$emit('connectToDevice', this.selectedDevice);
    }
};
__decorate([
    Prop({ type: Array })
], AdminDeviceSelectComponent.prototype, "deviceList", void 0);
AdminDeviceSelectComponent = __decorate([
    Component({})
], AdminDeviceSelectComponent);
export default AdminDeviceSelectComponent;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrganizationItem from '@/views/components/organization/OrganizationItem.vue';
import OrganizationItemList from '@/views/components/organization/OrganizationItemList.vue';
import UserPreferences from '@/services/UserPreferences';
import LoggedUserManager from '@/services/LoggedUserManager';
let OrganizationCard = class OrganizationCard extends Vue {
    constructor() {
        super(...arguments);
        this.departmentName = '';
        this.cardViewActive = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.cardViewActive);
    }
    get filteredDepartments() {
        return this.departments.getData().filter((dep) => dep.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.departmentName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    favoriteSelected(id) {
        this.$emit('favoriteSelected', id);
    }
    cardView() {
        this.cardViewActive = true;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.cardViewActive, this.cardViewActive);
    }
    tableView() {
        this.cardViewActive = false;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.cardViewActive, this.cardViewActive);
    }
};
__decorate([
    Prop({ type: Object })
], OrganizationCard.prototype, "departments", void 0);
__decorate([
    Prop({ type: String, default: null })
], OrganizationCard.prototype, "favoriteDepartmentId", void 0);
OrganizationCard = __decorate([
    Component({
        components: {
            OrganizationItem,
            OrganizationItemList
        }
    })
], OrganizationCard);
export default OrganizationCard;

import { __decorate } from "tslib";
import { createModule, mutation } from 'vuex-class-component';
const VuexModule = createModule({
    namespaced: 'departmentStore',
    strict: false
});
/**
 * Store for Department management
 */
export class DepartmentStore extends VuexModule {
    constructor() {
        super(...arguments);
        this._departments = null;
        this._selectedDepartment = null;
        this._favoriteDepartment = null;
        this._selectedDepartmentPermissions = null;
        this._selectedDepartmentRoleKey = null;
    }
    get departments() {
        return this._departments;
    }
    set departments(value) {
        this._departments = value;
    }
    get selectedDepartment() {
        return this._selectedDepartment;
    }
    set selectedDepartment(value) {
        this._selectedDepartment = value;
    }
    get favoriteDepartment() {
        return this._favoriteDepartment;
    }
    set favoriteDepartment(value) {
        this._favoriteDepartment = value;
    }
    get selectedDepartmentPermissions() {
        return this._selectedDepartmentPermissions;
    }
    set selectedDepartmentPermissions(value) {
        this._selectedDepartmentPermissions = value;
    }
    get selectedDepartmentRoleKey() {
        return this._selectedDepartmentRoleKey;
    }
    set selectedDepartmentRoleKey(role) {
        this._selectedDepartmentRoleKey = role;
    }
    /**
     * Sets selected (currently used) department according to id param
     * @param departmentId
     */
    setSelectedDepartmentById(departmentId) {
        let dep = this._departments.find((x) => x.id === departmentId);
        if (dep) {
            this._selectedDepartment = dep;
            // this._selectedDepartmentRoleKey = dep.member.Role.Key;
        }
        else {
            this._selectedDepartment = this._departments[0];
            //this._selectedDepartmentRoleKey = this._departments[0].member.Role.Key;
        }
    }
    /**
     * Sets favorite department according to id param
     * @param departmentId
     */
    setFavoriteDepartmentById(departmentId) {
        let dep = this._departments.find((x) => x.id === departmentId);
        if (dep) {
            this._favoriteDepartment = dep;
        }
        else {
            this._favoriteDepartment = this._departments[0];
        }
    }
    /**
     * Clears permissions.
     */
    clearPermissionsData() {
        this._selectedDepartmentPermissions = null;
    }
    /**
     * Clears all data after logout.
     */
    clearData() {
        this._departments = null;
        this._selectedDepartment = null;
        this._favoriteDepartment = null;
        this._selectedDepartmentPermissions = null;
        this._selectedDepartmentRoleKey = null;
    }
}
__decorate([
    mutation
], DepartmentStore.prototype, "setSelectedDepartmentById", null);
__decorate([
    mutation
], DepartmentStore.prototype, "setFavoriteDepartmentById", null);
__decorate([
    mutation
], DepartmentStore.prototype, "clearPermissionsData", null);
__decorate([
    mutation
], DepartmentStore.prototype, "clearData", null);

import InvitationStates from '@/entities/enums/InvitationStates';
import User from '../User';
export class Invitation {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Invitation.define(et));
        }
        else if (data != null) {
            return Invitation.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let invitation = new Invitation();
        invitation.id = data.Id;
        invitation.role = data.RoleKey;
        invitation.createdAt = data.CreatedAt;
        invitation.invitedEmail = data.InvitedEmail;
        invitation.state = data.State;
        invitation.validUntil = data.ValidUntil;
        invitation.notificationPreset = data.NotificationPreset;
        if (data.CreatedBy)
            invitation.createdBy = User.fromApi(data.CreatedBy);
        invitation.text = data.Text;
        invitation.locale = data.Locale;
        return invitation;
    }
    isExpired() {
        return Date.now() >= this.validUntil * 1000 && this.state === InvitationStates.PENDING;
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WizardSteps from '@/entities/enums/wizardSteps';
let ProgressBar = class ProgressBar extends Vue {
    constructor() {
        super(...arguments);
        this.WizardSteps = WizardSteps;
        this.stepInBar = null;
    }
    actualStepChanged() {
        this.stepInBar = this.actualStep;
    }
    created() {
        this.actualStepChanged();
    }
    stepChanged(step) {
        this.$emit('setStep', step);
    }
    stepIsClickable(step) {
        if (this.actualStep >= step)
            return true;
        else if (this.actualStep == step - 1)
            return this.canSkip(this.actualStep);
        else {
            if (!this.alreadyVisitedSteps.includes(step))
                return false;
            for (let stepIndex = this.actualStep; stepIndex < step; stepIndex++) {
                if (this.canSkip(stepIndex) == false)
                    return false;
            }
            return true;
        }
    }
};
__decorate([
    Prop({ type: Number, default: WizardSteps.DEVICE })
], ProgressBar.prototype, "actualStep", void 0);
__decorate([
    Prop({ type: Function })
], ProgressBar.prototype, "canSkip", void 0);
__decorate([
    Prop({ type: Array })
], ProgressBar.prototype, "alreadyVisitedSteps", void 0);
__decorate([
    Watch('actualStep')
], ProgressBar.prototype, "actualStepChanged", null);
ProgressBar = __decorate([
    Component({})
], ProgressBar);
export default ProgressBar;

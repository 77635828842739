import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import VueUtilities from '@/services/VueUtilities';
var reportRecordRepository;
let DeleteAnnotationModal = class DeleteAnnotationModal extends Vue {
    mounted() {
        reportRecordRepository = new ReportRecordRepository(this);
    }
    async deleteAnnotation() {
        let res = await reportRecordRepository.deleteAnnotation(this.annotation.id);
        if (res) {
            VueUtilities.openSuccessToast(this, this.$t('component.report.delete_annotation.success').toString());
            this.resultRetrieved(true);
        }
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], DeleteAnnotationModal.prototype, "annotation", void 0);
__decorate([
    Prop({ type: String })
], DeleteAnnotationModal.prototype, "timeRange", void 0);
DeleteAnnotationModal = __decorate([
    Component({})
], DeleteAnnotationModal);
export default DeleteAnnotationModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let BackgroundJobPreview = class BackgroundJobPreview extends Vue {
    created() { }
};
__decorate([
    Prop({ type: Object })
], BackgroundJobPreview.prototype, "backgroundJob", void 0);
__decorate([
    Prop({ type: Object })
], BackgroundJobPreview.prototype, "dateTimeManager", void 0);
BackgroundJobPreview = __decorate([
    Component({
        components: {}
    })
], BackgroundJobPreview);
export default BackgroundJobPreview;

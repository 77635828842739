import { __decorate } from "tslib";
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import NotificationSubscriptionType, { typeHasDelay, typeHasOnResolve, typeHasRepeat } from '@/entities/notifications/NotificationSubscriptionType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
import DelayOptions from '@/entities/enums/DelayOptions';
import RepeatOptions from '@/entities/enums/RepeatOptions';
/**
 * This component is used as template for General notifications management in preset
 *
 */
let GeneralRuleTemplate = class GeneralRuleTemplate extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationRuleDisplayMode = NotificationRuleDisplayMode;
        this.RepeatOptions = RepeatOptions;
        this.typeHasOnResolve = typeHasOnResolve;
        this.typeHasRepeat = typeHasRepeat;
        this.typeHasDelay = typeHasDelay;
        this.currentEnabled = true;
        this.currentDisplayMode = NotificationRuleDisplayMode.BASIC;
        this.currentChannels = {};
        this.localRule = null;
    }
    /**
     * Returns true if currently selected display mode is basic
     */
    get isBasic() {
        return this.currentDisplayMode === null || this.currentDisplayMode === NotificationRuleDisplayMode.BASIC;
    }
    /**
     * Returns number of columns for table of settings. Number of columns depends on notification type,
     * allowed channels and selected display mode.
     */
    get numberOfColumns() {
        if (this.isBasic)
            return this.type.channel.length;
        else
            return this.type.channel.length + 1;
    }
    get delayOptions() {
        return DelayOptions.getValues();
    }
    get titleText() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.device_offline_title');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.source_offline_title');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                return this.$t('notifications.configuration.low_battery_title');
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
                return this.$t('notifications.configuration.calibration_title');
                break;
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
                return this.$t('notifications.configuration.pdf_generated_title');
                break;
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
                return this.$t('notifications.configuration.auto_pdf_generated_title');
                break;
            case NotificationSubscriptionType.RECORDS_UPDATED:
                return this.$t('notifications.configuration.records_updated_title');
                break;
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.logger_state_title');
                break;
            default:
                return this.$t('notifications.configuration.format_title');
                break;
        }
    }
    get descriptionText() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.device_offline_desc');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.source_offline_desc');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                return this.$t('notifications.configuration.low_battery_desc');
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
                return this.$t('notifications.configuration.calibration_desc');
                break;
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
                return this.$t('notifications.configuration.pdf_generated_desc');
                break;
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
                return this.$t('notifications.configuration.auto_pdf_generated_desc');
                break;
            case NotificationSubscriptionType.RECORDS_UPDATED:
                return this.$t('notifications.configuration.records_updated_desc');
                break;
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.logger_state_desc');
                break;
            default:
                return '';
                break;
        }
    }
    get turnedOffText() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.device_offline_turned_off');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.source_offline_turned_off');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                return this.$t('notifications.configuration.low_battery_turned_off');
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
                return this.$t('notifications.configuration.calibration_turned_off');
                break;
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
                return this.$t('notifications.configuration.pdf_generated_turned_off');
                break;
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
                return this.$t('notifications.configuration.auto_pdf_generated_turned_off');
                break;
            case NotificationSubscriptionType.RECORDS_UPDATED:
                return this.$t('notifications.configuration.records_updated_turned_off');
                break;
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.logger_state_off');
                break;
            default:
                return '';
                break;
        }
    }
    get onResolveDesc() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.settings.resolve_desc_device');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.settings.resolve_desc_logger');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
            case NotificationSubscriptionType.RECORDS_UPDATED:
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.settings.resolve_desc_general');
                break;
            default:
                return this.$t('notifications.configuration.settings.resolve_desc_general');
                break;
        }
    }
    created() {
        //Create local instance of rule to avoid error about prop mutation
        if (this.rule) {
            this.localRule = this.rule;
            this.currentEnabled = true;
            this.type.channel.forEach((channel) => {
                let existingChannel = this.localRule.Channels[channel.type];
                //load default channel settings
                let ch = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.type.name, channel.type);
                if (existingChannel) {
                    //load values from existing rule
                    ch = {
                        enabled: existingChannel.Settings.enabled != null ? existingChannel.Settings.enabled : true,
                        delay: existingChannel.Settings.delay || 0,
                        onResolve: existingChannel.Settings.onResolve || false,
                        repeat: existingChannel.Settings.repeat || -1
                    };
                }
                else {
                    //if updating rule and channel is not used, set disabled to true
                    ch.enabled = false;
                }
                this.currentChannels[channel.type] = ch;
            });
        }
        else {
            this.currentEnabled = false;
            this.localRule = {
                Channels: {},
                NotificationType: this.type.name
            };
            this.type.channel.forEach((channel) => {
                this.currentChannels[channel.type] = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.type.name, channel.type);
            });
        }
    }
    changeDisplayMode(selected) {
        this.currentDisplayMode = selected;
        this.save();
    }
    /**
     * Check if given type has available onResolve setting
     */
    channelForTypeHasOnResolve(channelType) {
        let channel = this.type.channel.find((x) => x.type == channelType);
        return channel.onResolve;
    }
    save() {
        this.$forceUpdate();
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let ruleInPresetDto = {
            NotificationType: this.type.name,
            Channels: currentChannelsDTO
        };
        this.$emit('ruleChanged', ruleInPresetDto, this.currentEnabled);
    }
};
__decorate([
    Prop({ type: Object })
], GeneralRuleTemplate.prototype, "type", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], GeneralRuleTemplate.prototype, "advancedAllowed", void 0);
__decorate([
    Prop({ type: Object })
], GeneralRuleTemplate.prototype, "rule", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], GeneralRuleTemplate.prototype, "editIsAllowed", void 0);
GeneralRuleTemplate = __decorate([
    Component({})
], GeneralRuleTemplate);
export default GeneralRuleTemplate;

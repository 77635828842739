import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import UserPreferences from '@/services/UserPreferences';
import LoggerStates from '@/entities/enums/LoggerStates';
import LoggerRepository from '@/services/repository/LoggerRepository';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import PublishLoggerModal from '@/views/components/admin/loggers/PublishLoggerModal.vue';
import SelectCompanyModal from '@/views/components/admin/loggers/SelectCompanyModal.vue';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import SortDirections from '@/entities/enums/SortDirections';
import { Department } from '@/entities/models/Department';
import debounce from 'lodash.debounce';
import ReadDataFromLoggerModal from './ReadDataFromLoggerModal.vue';
import AppConfig from '@/configLoader';
import { ServiceState } from '@/entities/enums/ServiceState';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
var loggerRepository;
var companyLoggerRepository;
const DEBOUNCE_TIMER = 500;
let AdminLoggerList = class AdminLoggerList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.LoggerStates = LoggerStates;
        this.CalibrationStatus = CalibrationStatus;
        this.ServiceState = ServiceState;
        this.selectedValue = '';
        this.isSerialNoSearch = true;
        this.isOnlineSearch = false;
        this.isStateSearch = false;
        this.isCalibrationSearch = false;
        this.isBatterySearch = false;
        this.isMacAddressSearch = false;
        this.isCompanyNameSearch = false;
        this.serialNumberSearch = '';
        this.onlineSearch = null;
        this.stateSearch = null;
        this.calibrationSearch = null;
        this.batterySearch = null;
        this.macAddressSearch = '';
        this.companyNameSearch = '';
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.loggerStates = [
            LoggerStates.AVAILABLE,
            LoggerStates.PUBLISHED,
            LoggerStates.ACTIVATED,
            LoggerStates.DEACTIVATED
        ];
        this.selectedLogger = null;
        this.isSerialNumberModalActive = false;
        this.isCompanyModalActive = false;
        this.isLoading = false;
        this.currentFilter = [];
        this.isReadDataModalActive = false;
        this.tableColumns = {
            loggerId: {
                field: 'LoggerId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLoggerId),
                hasSearch: false
            },
            macAddress: {
                field: 'MacAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListMacAddress),
                hasSearch: true
            },
            serialNumber: {
                field: 'SerialNumber',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListSerialNumber),
                hasSearch: true
            },
            companyName: {
                field: 'CompanyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCompanyName),
                hasSearch: true
            },
            lastSeen: {
                field: 'LastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLastSeen),
                hasSearch: false
            },
            lastStateChange: {
                field: 'LastStateChange',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLastStateChange),
                hasSearch: false
            },
            isOnline: {
                field: 'IsOnline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListIsOnline),
                hasSearch: true
            },
            state: {
                field: 'State',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListState),
                hasSearch: true
            },
            battery: {
                field: 'Battery',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListBattery),
                hasSearch: true
            },
            calibrated: {
                field: 'calibrated',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCalibrated),
                hasSearch: true
            },
            note: {
                field: 'note',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListNote),
                hasSearch: false
            }
        };
        this.handleFilterMacAddressDebounce = debounce((e) => {
            this.filterLoggers(this.tableColumns.macAddress.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCompanyNameDebounce = debounce((e) => {
            this.filterLoggers(this.tableColumns.companyName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterSerialNumberDebounce = debounce((e) => {
            this.filterLoggers(this.tableColumns.serialNumber.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterBatteryDebounce = debounce((e) => {
            this.filterLoggers(this.tableColumns.battery.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get loggerList() {
        return this.adminLoggers.getData();
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    async created() {
        loggerRepository = new LoggerRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.selectedValue = this.tableColumns.serialNumber.field;
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadLoggers', this.pagination, this.sort, this.currentFilter);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminLogger);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            this.currentFilter = JSON.parse(this.tableQueriesfromUrl.Filter);
            // send queries to parent component
            let objStringAdminLogger = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromLogger', objStringAdminLogger);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminLoggerListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminLoggerListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminLoggerListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminLoggerListRowsPerPage.defaultValue;
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.serialNumberSearch =
                this.filterExists('SerialNumber@=') !== -1
                    ? this.currentFilter[this.filterExists('SerialNumber@=')].substring(14)
                    : '';
            this.macAddressSearch =
                this.filterExists('MacAddress@=') !== -1
                    ? this.currentFilter[this.filterExists('MacAddress@=')].substring(12)
                    : '';
            this.batterySearch =
                this.filterExists('Battery<=') !== -1
                    ? parseInt(this.currentFilter[this.filterExists('Battery<=')].substring(9))
                    : null;
            this.companyNameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilter[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.onlineSearch =
                this.filterExists('IsOnline==true') !== -1 ? true : this.filterExists('IsOnline==false') !== -1 ? false : null;
            this.stateSearch =
                this.filterExists('State==') !== -1 ? this.currentFilter[this.filterExists('State==')].substring(7) : null;
            if (this.filterExists('HasCalibration') !== -1 &&
                this.currentFilter[this.filterExists('HasCalibration==')].endsWith('true') &&
                this.filterExists('Expired') !== -1 &&
                this.currentFilter[this.filterExists('Expired==')].endsWith('false')) {
                this.calibrationSearch = CalibrationStatus.CALIBRATED.name;
            }
            else if (this.filterExists('HasCalibration') !== -1 &&
                this.currentFilter[this.filterExists('HasCalibration==')].endsWith('true') &&
                this.filterExists('Expired') !== -1 &&
                this.currentFilter[this.filterExists('Expired==')].endsWith('true')) {
                this.calibrationSearch = CalibrationStatus.EXPIRED.name;
            }
            else if (this.filterExists('HasCalibration') !== -1 &&
                this.currentFilter[this.filterExists('HasCalibration==')].endsWith('false')) {
                this.calibrationSearch = CalibrationStatus.NOT_CALIBRATED.name;
            }
        }
    }
    canPublish(logger) {
        return logger.state == LoggerStates.AVAILABLE;
    }
    canUnpublish(logger) {
        return logger.state == LoggerStates.DEACTIVATED;
    }
    canActivate(logger) {
        return logger.state == LoggerStates.PUBLISHED;
    }
    canDeactivate(logger) {
        return logger.state == LoggerStates.ACTIVATED;
    }
    canRevert(logger) {
        return logger.state == LoggerStates.ACTIVATED && logger.currentlyPublishedAs != null;
    }
    canReadData(logger) {
        return logger?.currentlyPublishedAs?.isOnline === true && AppConfig.getConfig().features.read_logger_data === true;
    }
    publishClicked(logger) {
        this.selectedLogger = logger;
        this.isSerialNumberModalActive = true;
    }
    unpublishClicked(logger) {
        this.selectedLogger = logger;
        this.$buefy.dialog.confirm({
            title: ((this.$t('admin.component.loggers.unpublish_modal.title') +
                (this.selectedLogger?.currentlyPublishedAs?.serialNumber
                    ? ' (' + this.selectedLogger.currentlyPublishedAs.serialNumber + ')'
                    : ''))),
            message: this.$t('admin.component.loggers.unpublish_modal.message'),
            confirmText: this.$t('admin.component.loggers.unpublish_modal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.unpublishLogger()
        });
    }
    async unpublishLogger() {
        if ((this.selectedLogger.state == LoggerStates.PUBLISHED || this.selectedLogger.state == LoggerStates.DEACTIVATED) &&
            this.selectedLogger.currentlyPublishedAs) {
            let result = await companyLoggerRepository.adminUnpublishLogger(this.selectedLogger.currentlyPublishedAs.companyLoggerId);
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.loggerList.messages.unpublishSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.loggerList.messages.unpublishFailed').toString());
            }
            this.$emit('loadLoggers', this.pagination, this.sort, this.currentFilter);
        }
    }
    activateClicked(logger) {
        this.selectedLogger = logger;
        this.isCompanyModalActive = true;
    }
    deactivateClicked(logger) {
        this.selectedLogger = logger;
        this.$buefy.dialog.confirm({
            title: ((this.$t('admin.component.loggers.deactivate_modal.title') +
                (this.selectedLogger?.currentlyPublishedAs?.serialNumber
                    ? ' (' + this.selectedLogger.currentlyPublishedAs.serialNumber + ')'
                    : ''))),
            message: this.$t('admin.component.loggers.deactivate_modal.message'),
            confirmText: this.$t('admin.component.loggers.deactivate_modal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deactivateLogger()
        });
    }
    async deactivateLogger() {
        if (this.selectedLogger.state == LoggerStates.ACTIVATED && this.selectedLogger.currentlyPublishedAs) {
            let result = await companyLoggerRepository.adminDeactivateLogger(this.selectedLogger.currentlyPublishedAs.companyLoggerId);
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.loggerList.messages.deactivateSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.loggerList.messages.deactivateFailed').toString());
            }
            this.$emit('loadLoggers', this.pagination, this.sort, this.currentFilter);
        }
    }
    revertDevice(logger) {
        this.selectedLogger = logger;
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.loggers.detail.info.revert.title'),
            message: this.$t('admin.component.loggers.detail.info.revert.message', {
                name: this.selectedLogger.currentlyPublishedAs.serialNumber
            }),
            confirmText: this.$t('admin.component.loggers.detail.info.revert.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                await companyLoggerRepository.adminRevertCompanyLogger(this.selectedLogger.currentlyPublishedAs.companyLoggerId);
                this.$emit('loadLoggers', this.pagination, this.sort, this.currentFilter);
            }
        });
    }
    openReadDataModal(logger) {
        this.selectedLogger = logger;
        this.isReadDataModalActive = true;
    }
    async readData(device) {
        let params = { DeviceId: device.deviceId };
        let res = await companyLoggerRepository.adminReadLoggerData(this.selectedLogger.currentlyPublishedAs.companyLoggerId, params);
        if (res === true) {
            this.isReadDataModalActive = false;
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.info.readData.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.info.readData.failure'));
        }
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter);
        this.$emit('onOptionsChange', options, this.currentFilter);
    }
    jumpToPageSelected(page) {
        if (Number.isInteger(page) && page > 0) {
            this.page = page;
            this.onPageChange(page);
        }
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    changeRouterUrl(pagination, sort, currentFilter) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            Filter: JSON.stringify(currentFilter.filter((x) => x != null))
        };
        let objStringAdminLogger = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromLogger', objStringAdminLogger);
    }
    filterLoggers(searchField, value) {
        switch (searchField) {
            case this.tableColumns.macAddress.field:
                this.replaceFilter('MacAddress@=', value);
                break;
            case this.tableColumns.serialNumber.field:
                this.replaceFilter('SerialNumber@=', value);
                break;
            case this.tableColumns.companyName.field:
                this.replaceFilter('CompanyName@=', value);
                break;
            case this.tableColumns.isOnline.field:
                this.replaceFilter('IsOnline==', value);
                break;
            case this.tableColumns.state.field:
                this.replaceFilter('State==', value);
                break;
            case this.tableColumns.calibrated.field:
                if (value == CalibrationStatus.CALIBRATED.name) {
                    this.replaceFilter('HasCalibration==', 'true');
                    this.replaceFilter('Expired==', 'false');
                }
                else if (value == CalibrationStatus.EXPIRED.name) {
                    this.replaceFilter('HasCalibration==', 'true');
                    this.replaceFilter('Expired==', 'true');
                }
                else if (value == CalibrationStatus.NOT_CALIBRATED.name) {
                    this.replaceFilter('HasCalibration==', 'false');
                    this.replaceFilter('Expired==', null);
                }
                break;
            case this.tableColumns.battery.field:
                this.replaceFilter('Battery<=', value);
                break;
            default:
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange(this.pagination, this.sort);
            }
        });
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    closeModal() {
        this.isSerialNumberModalActive = false;
        this.isCompanyModalActive = false;
        this.isReadDataModalActive = false;
    }
    serialNumberSelected(serialNumber) {
        this.isSerialNumberModalActive = false;
        if (this.selectedLogger) {
            this.publishLogger(this.selectedLogger, serialNumber);
        }
    }
    companySelected(company) {
        this.isCompanyModalActive = false;
        if (this.selectedLogger) {
            this.activateLogger(this.selectedLogger, company);
        }
    }
    async publishLogger(logger, serialNumber) {
        if (logger.state == LoggerStates.AVAILABLE) {
            let result = await loggerRepository.adminPublishLogger(logger.macAddress, serialNumber);
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.loggerList.messages.publishSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.loggerList.messages.publishFailed').toString());
            }
        }
        this.selectedLogger = null;
        this.$emit('loadLoggers', this.pagination, this.sort, this.currentFilter);
    }
    async activateLogger(logger, company) {
        if (logger.state == LoggerStates.PUBLISHED && logger.currentlyPublishedAs) {
            let result = await companyLoggerRepository.adminActivateLogger(logger.currentlyPublishedAs.companyLoggerId, company.companyId.toString());
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.loggerList.messages.activateSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.loggerList.messages.activateFailed').toString());
            }
            this.selectedLogger = null;
            this.$emit('loadLoggers', this.pagination, this.sort, this.currentFilter);
        }
    }
    clearFilter() {
        this.serialNumberSearch = '';
        this.onlineSearch = null;
        this.stateSearch = null;
        this.calibrationSearch = null;
        this.batterySearch = null;
        this.macAddressSearch = '';
        this.companyNameSearch = '';
        this.currentFilter = [];
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isSerialNoSearch = false;
        this.isOnlineSearch = false;
        this.isStateSearch = false;
        this.isCalibrationSearch = false;
        this.isBatterySearch = false;
        this.isMacAddressSearch = false;
        this.isCompanyNameSearch = false;
        switch (event) {
            case this.tableColumns.serialNumber.field:
                this.isSerialNoSearch = true;
                break;
            case this.tableColumns.isOnline.field:
                this.isOnlineSearch = true;
                break;
            case this.tableColumns.state.field:
                this.isStateSearch = true;
                break;
            case this.tableColumns.calibrated.field:
                this.isCalibrationSearch = true;
                break;
            case this.tableColumns.battery.field:
                this.isBatterySearch = true;
                break;
            case this.tableColumns.macAddress.field:
                this.isMacAddressSearch = true;
                break;
            case this.tableColumns.companyName.field:
                this.isCompanyNameSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.serialNumber.field;
        this.changeSearchField(this.tableColumns.serialNumber.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.loggerId.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.loggerId.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLoggerId, event);
                break;
            case this.tableColumns.macAddress.field:
                if (!event) {
                    this.changeDefaultField();
                    this.macAddressSearch = '';
                    this.filterLoggers(this.tableColumns.macAddress.field, this.macAddressSearch);
                    if (this.sort.field == this.tableColumns.macAddress.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListMacAddress, event);
                break;
            case this.tableColumns.companyName.field:
                if (!event) {
                    this.changeDefaultField();
                    this.companyNameSearch = '';
                    this.filterLoggers(this.tableColumns.companyName.field, this.companyNameSearch);
                    if (this.sort.field == this.tableColumns.companyName.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCompanyName, event);
                break;
            case this.tableColumns.lastSeen.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.lastSeen.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLastSeen, event);
                break;
            case this.tableColumns.lastStateChange.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.lastStateChange.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLastStateChange, event);
                break;
            case this.tableColumns.isOnline.field:
                if (!event) {
                    this.changeDefaultField();
                    this.onlineSearch = null;
                    this.filterLoggers(this.tableColumns.isOnline.field, '');
                    if (this.sort.field == this.tableColumns.isOnline.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListIsOnline, event);
                break;
            case this.tableColumns.state.field:
                if (!event) {
                    this.changeDefaultField();
                    this.stateSearch = null;
                    this.filterLoggers(this.tableColumns.state.field, '');
                    if (this.sort.field == this.tableColumns.state.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListState, event);
                break;
            case this.tableColumns.battery.field:
                if (!event) {
                    this.changeDefaultField();
                    this.batterySearch = null;
                    this.filterLoggers(this.tableColumns.battery.field, '');
                    if (this.sort.field == this.tableColumns.battery.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListBattery, event);
                break;
            case this.tableColumns.calibrated.field:
                if (!event) {
                    this.changeDefaultField();
                    this.calibrationSearch = null;
                    this.filterLoggers(this.tableColumns.calibrated.field, '');
                    if (this.sort.field == this.tableColumns.calibrated.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCalibrated, event);
                break;
            case this.tableColumns.note.field:
                if (!event) {
                    this.changeDefaultField();
                    this.filterLoggers(this.tableColumns.note.field, '');
                    if (this.sort.field == this.tableColumns.note.field)
                        this.sort.field = this.tableColumns.note.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListNote, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Object })
], AdminLoggerList.prototype, "adminLoggers", void 0);
__decorate([
    Prop({ type: Number })
], AdminLoggerList.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerList.prototype, "dateTimeManager", void 0);
AdminLoggerList = __decorate([
    Component({ components: { PublishLoggerModal, SelectCompanyModal, ReadDataFromLoggerModal } })
], AdminLoggerList);
export default AdminLoggerList;

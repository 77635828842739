var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.boundaries && _vm.boundaries.length > 0
      ? _c(
          "table",
          { staticClass: "info-table has-bottom-margin" },
          [
            _c("tr", [
              _c("th"),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.lower_alarm"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.lower_warning"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.lower_warning"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("component.boundaries.upper_alarm"))),
              ]),
            ]),
            _vm._l(_vm.boundaries, function (boundary) {
              return _c("tr", { key: boundary.measuredDataTypeSlug }, [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.$t("services." + boundary.measuredDataTypeSlug))
                  ),
                ]),
                _c("td", [
                  boundary.lowerAlarmBoundary
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DecimalConversion.toCommaString(
                              boundary.lowerAlarmBoundary
                            )
                          ) + _vm._s(boundary.unit)
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]),
                _c("td", [
                  boundary.lowerWarningBoundary
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DecimalConversion.toCommaString(
                              boundary.lowerWarningBoundary
                            )
                          ) + _vm._s(boundary.unit)
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]),
                _c("td", [
                  boundary.upperWarningBoundary
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DecimalConversion.toCommaString(
                              boundary.upperWarningBoundary
                            )
                          ) + _vm._s(boundary.unit)
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]),
                _c("td", [
                  boundary.upperAlarmBoundary
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DecimalConversion.toCommaString(
                              boundary.upperAlarmBoundary
                            )
                          ) + _vm._s(boundary.unit)
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]),
              ])
            }),
          ],
          2
        )
      : _c("p", [_vm._v(_vm._s(_vm.$t("component.boundaries.empty")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
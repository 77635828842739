var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("component.logger.configuration.title")) + " "
          ),
        ]
      ),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.MANAGEOWN, _vm.$permSubjects.COMPANY)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("LoggerSettings", {
                  staticClass: "box tile is-child",
                  attrs: { departmentSettings: _vm.departmentSettings },
                  on: {
                    updateLoggerThreshold: _vm.updateLoggerThreshold,
                    updateLoggerNotification: _vm.updateLoggerNotification,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("LoggerConfigurationList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    companyLoggerList: _vm.companyLoggerList,
                    sourceList: _vm.sourceList,
                    dateTimeManager: _vm.dateTimeManager,
                    showHiddenLoggers: _vm.showAllLoggers(),
                  },
                  on: {
                    "reload-loggers": _vm.createApiCalls,
                    changeVisibility: _vm.changeVisibility,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("IdleLoggersList", {
                  staticClass: "box tile is-child",
                  attrs: { companyLoggerList: _vm.companyLoggerList },
                  on: { "reload-loggers": _vm.loadData },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
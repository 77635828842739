import { __decorate } from "tslib";
import { NotificationPresetWithSubscriptions } from '@/entities/notifications/NotificationPreset';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getNotificationPresetTypes } from '@/entities/enums/NotificationPresetType';
import VueUtilities from '@/services/VueUtilities';
import MeasuredRuleTemplate from '@/views/components/admin/notifications/MeasuredRuleTemplate.vue';
import GeneralRuleTemplate from '@/views/components/admin/notifications/GeneralRuleTemplate.vue';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
var notificationSubscriptionsRepository;
let CreateOrEditPreset = class CreateOrEditPreset extends Vue {
    constructor() {
        super(...arguments);
        this.presetDto = {
            Name: '',
            Enabled: true,
            Subscriptions: [],
            Type: null
        };
        this.isLoading = true;
        this.preset_options = [];
        this.types = [];
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.localPreset = null;
    }
    get singleMeasuredRule() {
        return this.localPreset?.subscriptions?.find((x) => x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
    }
    created() {
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        this.preset_options = getNotificationPresetTypes();
        this.loadData();
    }
    loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(notificationSubscriptionsRepository.getNotificationTypes());
        Promise.all(allPromises).then((response) => {
            this.types = response[0];
            if (this.useDefaultPreset === true) {
                this.localPreset = NotificationPresetWithSubscriptions.createDefaultPreset(this.types);
            }
            else {
                this.localPreset = this.preset;
            }
            this.presetDto = {
                Enabled: this.localPreset.enabled,
                Name: this.localPreset.name,
                Type: this.localPreset.type,
                Subscriptions: this.localPreset.subscriptions
            };
            this.isLoading = false;
        });
    }
    getType(name) {
        return this.types.find((x) => x.name === name);
    }
    getRule(name) {
        return this.localPreset.subscriptions.find((x) => x.NotificationType === name);
    }
    createPreset() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.presetDto.Subscriptions.forEach((subs) => {
                    Object.keys(subs.Channels).forEach((chanKey) => {
                        if (subs.Channels[chanKey]?.Settings?.enabled === false) {
                            delete subs.Channels[chanKey];
                        }
                    });
                });
                let resp = await notificationSubscriptionsRepository.adminCreateNotificationPresets(this.presetDto);
                if (resp === true) {
                    VueUtilities.openSuccessToast(this, this.$t('component.notification.presets.create_success'));
                    this.closeModal(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.notification.presets.create_failure'));
                }
            }
        });
    }
    editPreset() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.presetDto.Subscriptions.forEach((subs) => {
                    Object.keys(subs.Channels).forEach((chanKey) => {
                        if (subs.Channels[chanKey].enabled === false) {
                            delete subs.Channels[chanKey];
                        }
                    });
                });
                let resp = await notificationSubscriptionsRepository.adminUpdateNotificationPresets(this.presetDto, this.preset.id);
                if (resp === true) {
                    VueUtilities.openSuccessToast(this, this.$t('component.notification.presets.edit_success'));
                    this.closeModal(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.notification.presets.edit_failure'));
                }
            }
        });
    }
    closeModal(result) {
        this.$emit('modalClosed', result);
    }
    /**
     * This method is called when rule is changed
     */
    onRuleChange(changedRule, isEnabled) {
        //remove current subscription from DTO
        this.presetDto.Subscriptions = this.presetDto.Subscriptions.filter((x) => x.NotificationType !== changedRule.NotificationType);
        //handle two types of measured rule
        if (changedRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM) {
            this.presetDto.Subscriptions = this.presetDto.Subscriptions.filter((x) => x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        }
        else if (changedRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            this.presetDto.Subscriptions = this.presetDto.Subscriptions.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM);
        }
        //if rule is enabled, add it to DTO
        if (isEnabled === true) {
            this.presetDto.Subscriptions.push(changedRule);
        }
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], CreateOrEditPreset.prototype, "useDefaultPreset", void 0);
__decorate([
    Prop({ type: Object })
], CreateOrEditPreset.prototype, "preset", void 0);
CreateOrEditPreset = __decorate([
    Component({ components: { MeasuredRuleTemplate, GeneralRuleTemplate } })
], CreateOrEditPreset);
export default CreateOrEditPreset;

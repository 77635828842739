import MeasurementsSort from './enums/MeasurementsSort';
import { ChartHeight } from './enums/ChartHeight';
export var UserSettingsValues;
(function (UserSettingsValues) {
    UserSettingsValues["UNASSIGNED_GROUP_ID"] = "U";
    UserSettingsValues["ALL_GROUP_ID"] = "A";
})(UserSettingsValues || (UserSettingsValues = {}));
export class UserSettings {
    constructor() {
        this.language = 'sk';
        this.events = {
            units: {
                0: 'Default',
                1: 'Default',
                2: 'Default',
                3: 'Default',
                4: 'Default',
                5: 'Default',
                6: 'Default',
                7: 'Default',
                8: 'Default',
                9: 'Default'
            }
        };
        this.notifications = { pause: { msgDismissedAt: '0', until: '0' } };
        this.preferences = { favoriteDepartmentId: null, measurementSort: MeasurementsSort.TIME, chartHeight: ChartHeight.SMALL };
        this.rawSettings = null;
        this.customSettings = [];
    }
    static fromApi(data) {
        return UserSettings.define(data);
    }
    static define(data) {
        let userSettings = new UserSettings();
        userSettings.rawSettings = data;
        userSettings.language = data?.language || userSettings.language;
        userSettings.datetime = UserSettings.parseDatetime(data?.datetime);
        userSettings.preferences.favoriteDepartmentId =
            data?.preferences?.favoriteDepartmentId || userSettings.preferences.favoriteDepartmentId;
        userSettings.preferences.measurementSort =
            data?.preferences?.measurementSort || userSettings.preferences.measurementSort;
        userSettings.preferences.chartHeight = data?.preferences?.chartHeight || userSettings.preferences.chartHeight;
        userSettings.notifications.pause.msgDismissedAt =
            data?.notifications?.pause?.msgDismissedAt || userSettings.notifications.pause.msgDismissedAt;
        userSettings.notifications.pause.until =
            data?.notifications?.pause?.until || userSettings.notifications.pause.until;
        userSettings.customSettings = data?.customSettings || userSettings.customSettings;
        if (data?.events) {
            Object.keys(userSettings.events.units).forEach((unitKey) => {
                if (data.events.units[unitKey] != null) {
                    userSettings.events.units[unitKey] = data.events.units[unitKey];
                }
            });
        }
        return userSettings;
    }
    static parseDatetime(data) {
        try {
            let parsed = {
                format: 'DD.MM.YYYY HH:mm:ss',
                dateFormat: 'DD.MM.YYYY',
                timezone: 'Europe/Bratislava',
                clockFormat: '24h',
                tzInFormat: 'false'
            };
            if (!data)
                return parsed;
            if (data.clockFormat && data.dateFormat && data.tzInFormat)
                return data;
            parsed.format = data.format.trim();
            data.format = data.format.trim();
            let formatParts = data.format.split(' ');
            let actualLastPart = formatParts.pop();
            if (data.tzInFormat == undefined) {
                if (actualLastPart == 'zz') {
                    parsed.tzInFormat = 'true';
                    actualLastPart = formatParts.pop();
                }
            }
            else {
                parsed.tzInFormat = data.tzInFormat;
            }
            if (data.clockFormat == undefined) {
                if (actualLastPart == 'A') {
                    parsed.clockFormat = '12h';
                    actualLastPart = formatParts.pop();
                }
            }
            else {
                parsed.clockFormat = data.clockFormat;
            }
            if (data.dateFormat)
                parsed.dateFormat = data.dateFormat;
            if (data.timezone)
                parsed.timezone = data.timezone;
            return parsed;
        }
        catch (e) {
            return data;
        }
    }
}

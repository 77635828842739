var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "multi-header mx-3 mx-0-desktop" },
        [
          _c("h1", { staticClass: "title is-3 is-spaced" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("component.settings.location.location_settings")
                ) +
                " "
            ),
          ]),
          _vm.$ability.can(
            _vm.$permActions.MANAGE,
            _vm.$permSubjects.SOURCE_GROUP
          ) &&
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
            ? [
                !_vm.canCreateGroup()
                  ? _c(
                      "span",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-2-mobile",
                            attrs: {
                              disabled: !_vm.canCreateGroup(),
                              type: "is-primary",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.location.list.addGroup_btn")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "create_group",
                            params: {
                              lang: _vm.$route.params.lang,
                              departmentId: _vm.$route.params.departmentId,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-2-mobile",
                            attrs: {
                              disabled: !_vm.canCreateGroup(),
                              type: "is-primary",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.location.list.addGroup_btn")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.SOURCE) &&
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.SOURCE_GROUP
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("GroupList", {
                      staticClass: "box tile is-child",
                      attrs: {
                        sourceGroups: _vm.sourceGroups,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                      on: { changeVisibility: _vm.changeVisibility },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
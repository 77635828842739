export class FirmwareAsset {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => FirmwareAsset.define(et));
        }
        else if (data != null) {
            return FirmwareAsset.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let asset = new FirmwareAsset();
        asset.id = data.Id;
        asset.fileName = data.FileName;
        asset.name = data.Name;
        if (data.Data)
            asset.data = data.Data;
        return asset;
    }
}

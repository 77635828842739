import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AdminUserGeneralSettings from './AdminUserGeneralSettings.vue';
let AdminUserCompanySettings = class AdminUserCompanySettings extends Vue {
    constructor() {
        super(...arguments);
        this.localSelectedDepartment = null;
    }
    onDepartmentChange() {
        this.localSelectedDepartment = this.selectedDepartment;
    }
    created() {
        this.localSelectedDepartment = this.selectedDepartment;
    }
    departmentSelected(department) {
        this.localSelectedDepartment = department;
        this.$emit('selectDepartment', department);
    }
    reloadData(userId) {
        this.$emit('reloadData', userId);
    }
    openSuccessToast(message) {
        this.$emit('openSuccessToast', message);
    }
};
__decorate([
    Prop({ type: Boolean })
], AdminUserCompanySettings.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserCompanySettings.prototype, "user", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserCompanySettings.prototype, "departments", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserCompanySettings.prototype, "selectedDepartment", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserCompanySettings.prototype, "departmentUserSettings", void 0);
__decorate([
    Prop({ type: String })
], AdminUserCompanySettings.prototype, "userId", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserCompanySettings.prototype, "adminUserSettings", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserCompanySettings.prototype, "dateTimeManager", void 0);
__decorate([
    Watch('selectedDepartment')
], AdminUserCompanySettings.prototype, "onDepartmentChange", null);
AdminUserCompanySettings = __decorate([
    Component({
        components: {
            AdminUserGeneralSettings
        }
    })
], AdminUserCompanySettings);
export default AdminUserCompanySettings;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CustomSettingsModal from '@/views/components/cookies/CustomSettingsModal.vue';
import AppConfig from '@/configLoader';
import { bootstrap } from 'vue-gtag';
import { CookieClaimManager, CookieClaimOptions } from '@/services/CookieClaimManager';
//firebase
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
var cookieClaimManager;
let CookiesBar = class CookiesBar extends Vue {
    constructor() {
        super(...arguments);
        this.cookieClaimManager = cookieClaimManager;
        this.currentClaims = null;
        this.isCustomSettingsVisible = false;
    }
    get hasAnalyticsAllowedInConfig() {
        return AppConfig.getConfig().google_analytics.allowed == true;
    }
    get hasFirebaseAllowed() {
        return AppConfig.getConfig().firebase.allowed == true;
    }
    get firebaseConfiguration() {
        return AppConfig.getConfig().firebase.config;
    }
    created() {
        cookieClaimManager = new CookieClaimManager(this);
        this.currentClaims = cookieClaimManager.readGivenClaim();
    }
    openPolicy() {
        this.$router
            .push({
            name: 'privacyPolicy',
            params: { lang: this.$route.params.lang }
        })
            .catch((err) => err);
    }
    acceptCookies() {
        cookieClaimManager
            .addLocalClaim(CookieClaimOptions.ACCEPT_ESSENTIAL_COOKIES)
            .addLocalClaim(CookieClaimOptions.ACCEPT_ANALYTICS_COOKIES)
            .commit();
        if (this.hasAnalyticsAllowedInConfig == true) {
            this.enablePlugin();
            // Initialize Firebase
        }
        if (this.hasFirebaseAllowed) {
            // Initialize Firebase
            const app = initializeApp(this.firebaseConfiguration);
            // Initialize Performance Monitoring and get a reference to the service
            const perf = getPerformance(app);
        }
        this.currentClaims = cookieClaimManager.readGivenClaim();
    }
    rejectCookies() {
        cookieClaimManager.addLocalClaim(CookieClaimOptions.ACCEPT_ESSENTIAL_COOKIES).commit();
        this.currentClaims = cookieClaimManager.readGivenClaim();
    }
    openCustomSettings() {
        this.isCustomSettingsVisible = true;
    }
    closeCustomSettingsModal() {
        this.isCustomSettingsVisible = false;
        this.currentClaims = cookieClaimManager.readGivenClaim();
    }
    enablePlugin() {
        bootstrap().then((gtag) => {
            this.$gtag.optIn();
        });
    }
};
CookiesBar = __decorate([
    Component({ components: { CustomSettingsModal } })
], CookiesBar);
export default CookiesBar;

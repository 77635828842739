import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SettingOptions from '@/entities/enums/SettingOptions';
let Settings = class Settings extends Vue {
    constructor() {
        super(...arguments);
        this.SettingOptions = SettingOptions;
    }
    created() { }
    isOptionAllowed(option) {
        switch (option) {
            case SettingOptions.PROFILE:
                return this.isOptionAvailable(SettingOptions.PROFILE.permissionsPairs);
            case SettingOptions.DEVICES:
                return this.isOptionAvailable(SettingOptions.DEVICES.permissionsPairs);
            case SettingOptions.LOGGERS:
                return this.isOptionAvailable(SettingOptions.LOGGERS.permissionsPairs);
            case SettingOptions.GROUPS:
                return this.isOptionAvailable(SettingOptions.GROUPS.permissionsPairs);
            case SettingOptions.NOTIFICATIONS:
                return this.isOptionAvailable(SettingOptions.NOTIFICATIONS.permissionsPairs);
            case SettingOptions.ADMINISTRATION:
                return this.isOptionAvailable(SettingOptions.ADMINISTRATION.permissionsPairs);
            case SettingOptions.DASHBOARDS:
                return this.isOptionAvailable(SettingOptions.DASHBOARDS.permissionsPairs);
            case SettingOptions.WIZARD:
                return this.isOptionAvailable(SettingOptions.WIZARD.permissionsPairs);
            default:
                return false;
        }
    }
    isOptionAvailable(permissionsPairs) {
        for (let index = 0; index < permissionsPairs.length; index++) {
            let pairResult = this.$ability.can(permissionsPairs[index][0], permissionsPairs[index][1]);
            if (pairResult == false)
                return false;
        }
        return true;
    }
    getRouteName(option) {
        switch (option) {
            case SettingOptions.PROFILE:
                return 'profile_settings';
                break;
            case SettingOptions.DEVICES:
                return 'configuration_devices';
                break;
            case SettingOptions.LOGGERS:
                return 'configuration_loggers';
                break;
            case SettingOptions.GROUPS:
                return 'configuration_groups';
                break;
            case SettingOptions.REPORTS:
                return 'configuration_reports';
                break;
            case SettingOptions.NOTIFICATIONS:
                return 'configuration_notifications';
                break;
            case SettingOptions.ADMINISTRATION:
                return 'companySettings';
                break;
            case SettingOptions.DASHBOARDS:
                return 'publicDashboards';
                break;
            case SettingOptions.WIZARD:
                return 'wizard';
                break;
            default:
                throw 'Unknown option!';
                break;
        }
    }
};
Settings = __decorate([
    Component({})
], Settings);
export default Settings;

import { __decorate } from "tslib";
import UserPreferences from '@/services/UserPreferences';
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import LoggerStates from '@/entities/enums/LoggerStates';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import debounce from 'lodash.debounce';
var companyLoggerRepository;
const DEBOUNCE_TIMER = 500;
let AdminRegisterLoggersModal = class AdminRegisterLoggersModal extends Vue {
    constructor() {
        super(...arguments);
        this.LoggerStates = LoggerStates;
        this.isLoading = false;
        this.selectedLoggers = Array();
        this.currentFilter = [];
        this.page = UserPreferences.LocalStorageKeys.AdminLoggerListPage.defaultValue;
        this.pageSize = 6;
        this.jumpToPageValue = null;
        this.serialNumber = '';
        this.handleFilterSerialNumberDebounce = debounce((e) => {
            this.filterLoggers(e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    async created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.currentFilter.push('State==' + LoggerStates.PUBLISHED.toLowerCase());
        this.$emit('loadAdminLoggers', this.pagination, this.currentFilter);
    }
    filterLoggers(event) {
        let index = this.filterExists('SerialNumber@=');
        if (index !== -1)
            this.currentFilter[index] = 'SerialNumber@=' + event;
        else
            this.currentFilter.push('SerialNumber@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadAdminLoggers', pagination, this.currentFilter);
    }
    registerLoggers() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let allPromises = new Array();
                this.selectedLoggers.forEach((logger) => {
                    allPromises.push(companyLoggerRepository.adminActivateLogger(logger.currentlyPublishedAs.companyLoggerId, this.companyId));
                });
                await Promise.all(allPromises).then((response) => {
                    if (response.find((x) => x !== true)) {
                        VueUtilities.openErrorToast(this, this.$t('admin.component.company.registerCompanyLoggersModal.failure'));
                    }
                    else {
                        VueUtilities.openSuccessToast(this, this.$t('admin.component.company.registerCompanyLoggersModal.success'));
                        this.$emit('closeModal');
                        this.$emit('loadDevicesAndLoggers');
                    }
                    this.isLoading = false;
                });
            }
        });
    }
};
__decorate([
    Prop({ type: Array })
], AdminRegisterLoggersModal.prototype, "adminLoggers", void 0);
__decorate([
    Prop({ type: String })
], AdminRegisterLoggersModal.prototype, "companyId", void 0);
__decorate([
    Prop({ type: Number })
], AdminRegisterLoggersModal.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminRegisterLoggersModal.prototype, "componentIsLoading", void 0);
AdminRegisterLoggersModal = __decorate([
    Component({})
], AdminRegisterLoggersModal);
export default AdminRegisterLoggersModal;

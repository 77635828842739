import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import GroupList from '@/views/components/group/GroupList.vue';
import { Source } from '@/entities/models/Source';
import { SourceGroup } from '@/entities/models/SourceGroup';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import DateTimeManager from '@/services/DateTimeManager';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import CookiesStorage from '@/services/CookiesStorage';
var sourceRepository;
var sourceGroupRepository;
var userSettingsRepository;
let GroupManagement = class GroupManagement extends Vue {
    constructor() {
        super(...arguments);
        this.sourceGroups = new Array();
        this.isLoading = true;
        this.favoriteGroupId = null;
        this.currentUser = null;
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        await this.loadData();
    }
    async loadData(loadSettings = true) {
        this.isLoading = true;
        this.currentUser = CookiesStorage.loadData(this, CookiesStorage.keys.API_USER_ID);
        let allPromises = [];
        allPromises.push(this.loadSourceGroups());
        allPromises.push(this.loadSources());
        if (loadSettings) {
            allPromises.push(userSettingsRepository.loadUserSettings());
            allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser));
        }
        await Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3]);
        });
    }
    processLoadedDataForPage(sourceGroups, sources, settings, departmentUserSettings) {
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        this.sortPreference = settings.preferences.measurementSort;
        Source.sortByName(sources.getData());
        this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(sourceGroups.getData(), sources.getData());
        let unassignedSources = Source.getUnassignedSources(sources.getData());
        this.sourceGroups.push(SourceGroup.createNullSourceGroup(unassignedSources));
        SourceGroup.sort(this.sourceGroups, this.favoriteGroupId, this.sortPreference);
        this.isLoading = false;
    }
    async loadSourceGroups() {
        return sourceGroupRepository.getSourceGroups(null, null, true);
    }
    async loadSources() {
        return sourceRepository.getVisibleSources();
    }
    canCreateGroup() {
        return this.sourceGroups.flatMap((x) => x.sources).length > 0;
    }
    async changeVisibility(group) {
        let groupDTO = {
            Name: group.name,
            Visible: !group.visible
        };
        let res = await sourceGroupRepository.updateGroup(group.id, groupDTO);
        this.loadData();
    }
};
GroupManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.SOURCE_GROUP) && vm.$ability.can(Actions.READ, Subjects.SOURCE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.loadData();
            next(); // to resolve hook
        },
        components: { GroupList }
    })
], GroupManagement);
export default GroupManagement;

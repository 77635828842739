import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let TierCard = class TierCard extends Vue {
    created() { }
};
__decorate([
    Prop({ type: Object })
], TierCard.prototype, "tier", void 0);
TierCard = __decorate([
    Component({})
], TierCard);
export default TierCard;

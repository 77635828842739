var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.userAdministration.list.invite.title")) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.userAdministration.list.invite.form.invite_language"
                ),
              },
            },
            [
              _c(
                "b-select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { placeholder: "Select a character", icon: "globe" },
                  on: { input: _vm.selectLang },
                  model: {
                    value: _vm.selectedLang,
                    callback: function ($$v) {
                      _vm.selectedLang = $$v
                    },
                    expression: "selectedLang",
                  },
                },
                [
                  _c("option", { domProps: { value: _vm.Languages.ENGLISH } }, [
                    _vm._v(_vm._s(_vm.$t("navbar.english"))),
                  ]),
                  _c("option", { domProps: { value: _vm.Languages.SLOVAK } }, [
                    _vm._v(_vm._s(_vm.$t("navbar.slovak"))),
                  ]),
                  _c("option", { domProps: { value: _vm.Languages.CZECH } }, [
                    _vm._v(_vm._s(_vm.$t("navbar.czech"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.userAdministration.list.invite.form.table.invite_emails"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "invite-user-table",
                  attrs: { data: _vm.userList },
                },
                [
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c(
                              "b-field",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "component.userAdministration.list.invite.form.table.email"
                                      ) + row.index
                                    ),
                                  },
                                  message: _vm.errorMessage(row.index),
                                  label: _vm.$t("generals.email"),
                                  "label-position": "on-border",
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|email",
                                      expression: "'required|email'",
                                    },
                                  ],
                                  attrs: {
                                    name:
                                      _vm.$t(
                                        "component.userAdministration.list.invite.form.table.email"
                                      ) + row.index,
                                    type: "text",
                                    placeholder: _vm.$t(
                                      "component.userAdministration.list.invite.form.table.emailPlaceholder"
                                    ),
                                  },
                                  model: {
                                    value: _vm.userList[row.index].email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userList[row.index],
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "userList[row.index].email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c(
                              "b-field",
                              {
                                staticClass: "mt-2 ml-2",
                                attrs: {
                                  label: _vm.$t("component.invitation.role"),
                                  "label-position": "on-border",
                                },
                              },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: { expanded: "" },
                                    model: {
                                      value: _vm.userList[row.index].role,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userList[row.index],
                                          "role",
                                          $$v
                                        )
                                      },
                                      expression: "userList[row.index].role",
                                    },
                                  },
                                  _vm._l(_vm.availableRoles, function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option.Key,
                                        domProps: { value: option },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "users.roles." + option.Key
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c(
                              "b-field",
                              {
                                staticClass: "mt-2 ml-2",
                                attrs: {
                                  label: _vm.$t(
                                    "component.userAdministration.list.invite.form.table.notif_preset"
                                  ),
                                  "label-position": "on-border",
                                },
                              },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: { expanded: "" },
                                    model: {
                                      value: _vm.userList[row.index].preset,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userList[row.index],
                                          "preset",
                                          $$v
                                        )
                                      },
                                      expression: "userList[row.index].preset",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          selected: "",
                                          disabled: "",
                                          hidden: "",
                                        },
                                        domProps: { value: null },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.userAdministration.list.invite.form.table.presetPlaceholder"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.availablePresets,
                                      function (option) {
                                        return _c(
                                          "option",
                                          {
                                            key: option.id,
                                            domProps: { value: option.id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("button", {
                              staticClass: "delete is-vcentered mt-2",
                              on: {
                                click: function ($event) {
                                  return _vm.remove(row.index)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.userAdministration.list.invite.form.table.empty"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.addNewUser()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "component.userAdministration.list.invite.form.table.addEmail_btn"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.userAdministration.list.invite.form.message"
                ),
              },
            },
            [
              _c("b-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "component.userAdministration.list.invite.form.write"
                  ),
                  maxlength: "1200",
                  type: "textarea",
                },
                on: {
                  "~focus": function ($event) {
                    return _vm.prefillMessage()
                  },
                },
                model: {
                  value: _vm.inviteMessage,
                  callback: function ($$v) {
                    _vm.inviteMessage = $$v
                  },
                  expression: "inviteMessage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.validateBeforeSubmit()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("component.userAdministration.list.invite.invite_btn")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import i18n from '@/lang/lang';
import NotificationPresetType from '../enums/NotificationPresetType';
import NotificationPriority from '../enums/NotificationPriority';
import NotificationChannel from './NotificationChannel';
import NotificationSubscription from './NotificationSubscription';
import NotificationSubscriptionCategory from './NotificationSubscriptionCategory';
import NotificationSubscriptionType from './NotificationSubscriptionType';
export class NotificationPreset {
}
export class NotificationPresetWithCount extends NotificationPreset {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => NotificationPresetWithCount.define(et));
        }
        else if (data != null) {
            return NotificationPresetWithCount.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let preset = new NotificationPresetWithCount();
        preset.id = data.Id;
        preset.name = data.Name;
        preset.enabled = data.Enabled;
        preset.type = data.Type;
        preset.subscriptionCount = data.SubscriptionCount;
        return preset;
    }
}
export class NotificationPresetWithSubscriptions extends NotificationPreset {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => NotificationPresetWithSubscriptions.define(et));
        }
        else if (data != null) {
            return NotificationPresetWithSubscriptions.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let preset = new NotificationPresetWithSubscriptions();
        preset.id = data.Id;
        preset.name = data.Name;
        preset.enabled = data.Enabled;
        preset.type = data.Type;
        preset.subscriptions = data.Subscriptions;
        return preset;
    }
    toSubscriptionsArray() {
        let subs = new Array();
        this.subscriptions.forEach((presetSubs) => {
            let subscription = new NotificationSubscription();
            subscription.channels = NotificationChannel.sortByType(NotificationChannel.ResponseToArray(presetSubs.Channels));
            subscription.notificationType = presetSubs.NotificationType;
            subscription.priority = NotificationPriority.getNotificationPriorityByName(presetSubs.Priority);
            subscription.enabled = true;
            if (subscription.notificationType == NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                subscription.notificationType == NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM)
                subscription.subscriptionCategory = NotificationSubscriptionCategory.MEASUREMENT_RULE;
            else
                subscription.subscriptionCategory = NotificationSubscriptionCategory.SYSTEM_RULE;
            subs.push(subscription);
        });
        return subs;
    }
    /**
     * Compares preset with subscriptions. Returns true if arrays are same
     * @param subscriptions : Array<INotificationSubscriptionCreateDTO
     * @returns boolean (true if arrays are same)
     */
    comparePresetWithSubscriptions(subscriptions) {
        let enabledSubscriptionTypes = subscriptions.filter((x) => x.Enabled == true).map((x) => x.NotificationType);
        let typesInPreset = this.subscriptions.map((x) => x.NotificationType);
        let newInSubs = enabledSubscriptionTypes.filter((x) => !typesInPreset.includes(x));
        let newInPreset = typesInPreset.filter((x) => !enabledSubscriptionTypes.includes(x));
        if (newInSubs.length > 0 || newInPreset.length > 0)
            return false;
        let hasDifferences = false;
        subscriptions
            .filter((x) => x.Enabled == true)
            .forEach((sub) => {
            let subInPreset = this.subscriptions.find((x) => x.NotificationType == sub.NotificationType);
            let channelsInSub = Object.keys(sub.Channels);
            let channelsInPreset = Object.keys(subInPreset.Channels);
            let newChanInSubs = channelsInSub.filter((x) => !channelsInPreset.includes(x));
            let newChanInPreset = channelsInPreset.filter((x) => !channelsInSub.includes(x));
            if (newChanInSubs.length > 0 || newChanInPreset.length > 0)
                hasDifferences = true;
            channelsInSub.forEach((chanSub) => {
                let chanInPreset = subInPreset.Channels[chanSub];
                if (sub.Channels[chanSub]?.Settings?.delay !== chanInPreset?.Settings?.delay ||
                    sub.Channels[chanSub]?.Settings?.onResolve !== chanInPreset?.Settings?.onResolve ||
                    sub.Channels[chanSub]?.Settings?.repeat !== chanInPreset?.Settings?.repeat) {
                    hasDifferences = true;
                }
            });
        });
        if (hasDifferences)
            return false;
        else
            return true;
    }
    static createDefaultPreset(types) {
        let defaultPreset = new NotificationPresetWithSubscriptions();
        defaultPreset.name = `${i18n.t('notifications.configuration.new_preset')}`;
        defaultPreset.id = null;
        defaultPreset.enabled = true;
        defaultPreset.type = NotificationPresetType.CUSTOMER;
        defaultPreset.subscriptions = [];
        types
            .filter((x) => x.category === NotificationSubscriptionCategory.SYSTEM_RULE)
            .forEach((type) => {
            let sub = {
                NotificationType: type.name,
                Channels: {}
            };
            type.channel.forEach((channel) => {
                sub.Channels[channel.type] = {
                    Settings: NotificationSubscription.getDefaultValuesForTypeAndChannel(type.name, channel.type)
                };
            });
            defaultPreset.subscriptions.push(sub);
        });
        //measured
        let measured = types.find((x) => x.name === NotificationSubscriptionType.MEASURED_DATA_ALARM);
        let sub = {
            NotificationType: measured.name,
            Channels: {}
        };
        measured.channel.forEach((channel) => {
            sub.Channels[channel.type] = {
                Settings: NotificationSubscription.getDefaultValuesForTypeAndChannel(measured.name, channel.type)
            };
        });
        defaultPreset.subscriptions.push(sub);
        return defaultPreset;
    }
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DeviceList from '@/views/components/device/DeviceList.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import DateTimeManager from '@/services/DateTimeManager';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
var deviceRepository;
var departmentRepository;
const PAGE_SIZE = 10;
let DeviceManagement = class DeviceManagement extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.PAGE_SIZE = PAGE_SIZE;
        this.deviceList = new Array();
        this.allDevicesCount = 0;
        this.SELECTED_DEPARTMENT_ID = null;
        this.isLoading = true;
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
    }
    async loadDevices(pagination) {
        let deviceResult;
        deviceResult = await deviceRepository.getDevices(pagination);
        this.deviceList = deviceResult.getData();
        this.allDevicesCount = deviceResult.getPagination().total;
    }
    async pageChanged(pagination) {
        this.isLoading = true;
        let deviceList = await deviceRepository.getDevices(pagination);
        this.deviceList = deviceList.getData();
        this.allDevicesCount = deviceList.getPagination().total;
        this.isLoading = false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
DeviceManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.DEVICE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartmentId()) {
                this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
                this.$refs.deviceList.onPageChange(1);
            }
            else {
                next(); // to resolve hook
            }
        },
        components: { DeviceList }
    })
], DeviceManagement);
export default DeviceManagement;

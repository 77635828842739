var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "is-mobile department-box is-full-height is-flex",
      class:
        _vm.isAdmin || _vm.department.member.Enabled
          ? "is-enabled"
          : "is-disabled",
    },
    [
      !_vm.isAdmin
        ? _c(
            "div",
            {
              staticClass:
                "mx-3 is-full-height is-flex is-align-items-center is-justify-content-center",
            },
            [
              _c("b-icon", {
                staticClass: "is-clickable",
                attrs: {
                  type: _vm.isFavorite ? "is-warning" : "is-light",
                  "custom-class": _vm.department.member.Enabled
                    ? "favorite-star"
                    : "favorite-star disabled",
                  size: "is-small",
                  icon: "star",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.favoriteSelected()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "column p-0 is-full-height" },
        [
          _vm.isAdmin || _vm.department.member.Enabled
            ? _c(
                "router-link",
                {
                  staticClass:
                    "is-full-height is-flex is-align-items-center is-justify-content-center",
                  attrs: {
                    to: {
                      name: "dashboard",
                      params: {
                        lang: _vm.$route.params.lang,
                        departmentId: _vm.department.id,
                      },
                    },
                  },
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass:
                        "title is-6 has-text-black mb-0 px-1 py-5 has-text-centered",
                    },
                    [_vm._v(" " + _vm._s(_vm.department.name) + " ")]
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "is-full-height is-flex is-align-items-center is-justify-content-center",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass:
                        "title is-6 has-text-black mb-0 py-5 px-1 has-text-centered",
                    },
                    [_vm._v(" " + _vm._s(_vm.department.name) + " ")]
                  ),
                ]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import LoggedUserManager from '@/services/LoggedUserManager';
import { vxm } from '@/store/store.vuex';
import { DepartmentArray } from './arrayModels/DepartmentArray';
import { DepartmentSettings } from './DepartmentSettings';
export class Department {
    constructor() {
        //TODO: experimental, not implemented on backend
        this.organization = null;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return new DepartmentArray(data.map((et) => Department.define(et)));
        }
        else if (data != null) {
            return Department.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let departmentData = data;
        if (data.Department) {
            departmentData = data.Department;
        }
        let department = new Department();
        department.id = departmentData.Id;
        department.name = departmentData.Name;
        department.createdAt = departmentData.CreatedAt;
        department.settings = DepartmentSettings.fromApi(departmentData.Settings);
        if (data.Member) {
            department.member = data.Member;
        }
        return department;
    }
    static hasPermissionToViewDepartment(departmentId, ctx) {
        if (!LoggedUserManager.isResellerAdmin(ctx))
            return true;
        else if (vxm.departmentStore.departments.find((x) => x.id === departmentId))
            return true;
        else
            return false;
    }
}

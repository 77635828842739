import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CompanyAdministration = class CompanyAdministration extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.departmentName = null;
        this.newFavorite = false;
    }
    get isNameChanged() {
        return this.departmentName && this.departmentName != this.currentDepartment.name;
    }
    get isFavoriteChanged() {
        return this.newFavorite !== this.isFavorite;
    }
    get hasChanges() {
        return this.isNameChanged || this.isFavoriteChanged;
    }
    created() {
        this.assignDefaultValues();
    }
    editCompanyInfo() {
        this.isEditing = !this.isEditing;
        if (!this.isEditing)
            this.assignDefaultValues();
    }
    assignDefaultValues() {
        this.departmentName = this.currentDepartment.name;
        this.newFavorite = this.isFavorite;
    }
    saveChanges() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.isNameChanged)
                    this.$emit('saveName', this.departmentName);
                if (this.isFavoriteChanged) {
                    let id = this.newFavorite ? this.currentDepartment.id : null;
                    this.$emit('saveFavorite', id);
                }
                this.isEditing = false;
            }
        });
    }
};
__decorate([
    Prop({ type: Boolean })
], CompanyAdministration.prototype, "isFavorite", void 0);
__decorate([
    Prop({ type: Object })
], CompanyAdministration.prototype, "currentDepartment", void 0);
__decorate([
    Prop({ type: Object })
], CompanyAdministration.prototype, "dateTimeManager", void 0);
CompanyAdministration = __decorate([
    Component({ components: {} })
], CompanyAdministration);
export default CompanyAdministration;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import UserRepository from '@/services/repository/UserRepository';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import LoggedUserManager from '@/services/LoggedUserManager';
import UserPreferences from '@/services/UserPreferences';
var userRepository;
let AddCompanyUsersModal = class AddCompanyUsersModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.selectedUsers = new Array();
        this.assignUsers = new Array();
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.currentFilter = [];
        this.page = null;
        this.pageSize = null;
        this.jumpToPageValue = null;
        this.emailSearch = '';
    }
    async created() {
        userRepository = new UserRepository(this);
        this.fillFromPref();
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadCompanyUsers', this.pagination, this.currentFilter);
    }
    fillFromPref() {
        this.page = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersRowsPerPage.defaultValue;
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadCompanyUsers', pagination, this.currentFilter);
    }
    filterUsers(event) {
        let index = this.filterExists('Email@=');
        if (index !== -1)
            this.currentFilter[index] = 'Email@=' + event;
        else
            this.currentFilter.push('Email@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    get availableUserRoles() {
        if (this.isResellerAdmin) {
            return Object.values(ApiUserRoles).filter((x) => x != ApiUserRoles.SYSTEM_ADMIN && x != ApiUserRoles.RESELLER_ADMIN);
        }
        else {
            return Object.values(ApiUserRoles);
        }
    }
    get adminRoles() {
        return Object.values(this.availableUserRoles).filter((x) => x == ApiUserRoles.SYSTEM_ADMIN || x == ApiUserRoles.RESELLER_ADMIN);
    }
    get userRoles() {
        return Object.values(this.availableUserRoles).filter((x) => x != ApiUserRoles.SYSTEM_ADMIN && x != ApiUserRoles.RESELLER_ADMIN);
    }
    deleteUnChecked(user) {
        if (user.length > 0) {
            this.assignUsers.forEach((element) => {
                if (user.find((x) => element.userId == x.id) == undefined) {
                    this.assignUsers.splice(this.assignUsers.indexOf(element), 1);
                }
            });
        }
        else {
            this.assignUsers = [];
        }
    }
    //Assign user role
    selectRole(user, role) {
        let usera = this.assignUsers.find((x) => user.id == x.userId);
        if (usera != undefined) {
            //changing user role
            usera.userRole = role;
        }
        else {
            this.assignUsers.push({
                userId: user.id,
                fullName: user.fullName,
                userRole: role,
                email: user.email
            });
        }
    }
    async addCompanyUsers() {
        if (this.selectedUsers.length > this.assignUsers.length) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.errorMissingRoles'));
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (this.selectedUsers.length <= 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.select_user'));
            }
            else {
                let ownerRoleCount = this.assignUsers.filter((x) => x.userRole == ApiUserRoles.COMPANY_OWNER).length;
                if (ownerRoleCount >= 2) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.errorOwnerCount'));
                }
                else {
                    if (result) {
                        if (ownerRoleCount > 0) {
                            this.$buefy.dialog.confirm({
                                title: this.$t('users.change_user_role.confirm_dialog_owner.title'),
                                message: this.$t('users.change_user_role.confirm_dialog_owner.msg'),
                                confirmText: this.$t('users.change_user_role.confirm_dialog_owner.confirm'),
                                type: 'is-primary',
                                hasIcon: true,
                                onConfirm: () => {
                                    this.saveData();
                                }
                            });
                        }
                        else {
                            this.saveData();
                        }
                    }
                }
            }
        });
    }
    async saveData() {
        this.isLoading = true;
        let usersPromises = new Array();
        this.assignUsers.forEach((user) => {
            usersPromises.push(this.assignCompanyUsers(this.company.companyId, user.userId, user.userRole));
        });
        await Promise.all(usersPromises).then((response) => {
            if (response.find((x) => x !== true)) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.addCompanyUsersModal.failure'));
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.company.addCompanyUsersModal.success'));
                this.resultRetrieved(true);
                this.$emit('reloadUsers');
            }
            this.isLoading = false;
        });
    }
    async assignCompanyUsers(departmentId, userId, userRole) {
        return userRepository.assignAdminUserRole(departmentId, userId, userRole);
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], AddCompanyUsersModal.prototype, "users", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AddCompanyUsersModal.prototype, "availableUsersForRole", void 0);
__decorate([
    Prop({ type: Object })
], AddCompanyUsersModal.prototype, "company", void 0);
__decorate([
    Prop({ type: Number })
], AddCompanyUsersModal.prototype, "totalSize", void 0);
AddCompanyUsersModal = __decorate([
    Component({})
], AddCompanyUsersModal);
export default AddCompanyUsersModal;

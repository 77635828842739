var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.isLayoutList
        ? _c("div", { staticClass: "level section-header-level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.firmwareHistory.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: { data: _vm.device.firmware.history, striped: "" },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "version",
              label: _vm.$t(
                "admin.component.devices.detail.firmwareHistory.table.version"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [_vm._v(_vm._s(instance.row.version))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "updatedAt",
              label: _vm.$t(
                "admin.component.devices.detail.firmwareHistory.table.updatedAt"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(instance.row.updatedAt * 1000)
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.devices.detail.firmwareHistory.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
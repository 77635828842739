import User from '../User';
import { CompanyLogger } from './CompanyLogger';
export class SourceChanges {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => SourceChanges.define(et));
        }
        else if (data != null) {
            return SourceChanges.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let source = new SourceChanges();
        source.changedAt = data.ChangedAt;
        source.action = data.Action;
        if (data.Logger)
            source.logger = CompanyLogger.fromApi(data.Logger);
        if (data.Issuer)
            source.issuer = User.fromApi(data.Issuer);
        return source;
    }
    static sortByDate(sources) {
        return sources.sort(function (x, y) {
            return x.changedAt - y.changedAt;
        });
    }
}

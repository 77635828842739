import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CreateForm from '@/views/components/group/CreateForm.vue';
import SourceRepository from '@/services/repository/SourceRepository';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import { Source } from '@/entities/models/Source';
import EventType from '@/entities/EventType';
import EventDomain from '@/entities/enums/eventDomains';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import UserRepository from '@/services/repository/UserRepository';
var sourceRepository;
var eventTypeRepository;
var userRepository;
let CreateGroup = class CreateGroup extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.sources = [];
        this.eventTypes = [];
        this.currenUser = null;
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        userRepository = new UserRepository(this);
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(this.loadSources());
        allPromises.push(this.loadEventTypes());
        allPromises.push(this.loadUnits());
        allPromises.push(userRepository.getCurrentUser());
        await Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3]);
        });
    }
    processLoadedDataForPage(sources, eventTypes, units, user) {
        this.sources = Source.sortByGroup(sources.getData());
        this.eventTypes = EventType.addUnits(eventTypes, units);
        this.currenUser = user;
        this.isLoading = false;
    }
    async loadEventTypes() {
        return eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true);
    }
    async loadUnits() {
        return eventTypeRepository.getSelectedUnits();
    }
    async loadSources() {
        return sourceRepository.getVisibleSources();
    }
};
CreateGroup = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP) && vm.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { CreateForm }
    })
], CreateGroup);
export default CreateGroup;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header mx-3 mx-0-desktop" }, [
        _c(
          "h1",
          { staticClass: "title is-3 is-spaced has-text-centered-touch my-1" },
          [_vm._v(" " + _vm._s(_vm.$t("admin.component.company.title")) + " ")]
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_DEPARTMENTS
            )
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      "icon-pack": "fa",
                      "icon-left": "plus",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.createCompany()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("admin.component.company.add_company"))
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_DEPARTMENTS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminCompanyList", {
                      staticClass: "box tile is-child",
                      attrs: {
                        companies: _vm.departmentsList,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                      on: {
                        reload: function ($event) {
                          return _vm.loadData()
                        },
                        changeRouterUrlFromCompany:
                          _vm.changeRouterUrlFromCompany,
                        loginIntoCompany: _vm.login,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
            "can-cancel": ["escape", "x"],
          },
          on: {
            "update:active": function ($event) {
              _vm.isModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("CreateCompanyModal", {
            attrs: {
              users: _vm.userList,
              usersAllowed: !_vm.isResellerAdmin,
              totalSize: _vm.totalSize,
            },
            on: {
              modalClosed: _vm.closeModal,
              loadNewCompany: function ($event) {
                return _vm.loadNewCompany()
              },
              onOptionsChange: _vm.loadUsers,
              loadUsers: _vm.loadUsers,
              reload: _vm.loadData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import StatisticsType from '@/entities/enums/statisticsType';
let AnnotationStatistics = class AnnotationStatistics extends Vue {
    constructor() {
        super(...arguments);
        this.StatisticsType = StatisticsType;
        this.DateTimeManager = DateTimeManager;
    }
    created() { }
};
__decorate([
    Prop({ type: Array })
], AnnotationStatistics.prototype, "statistics", void 0);
AnnotationStatistics = __decorate([
    Component({
        components: {}
    })
], AnnotationStatistics);
export default AnnotationStatistics;

export class Firmware {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Firmware.define(et));
        }
        else if (data != null) {
            return Firmware.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let fw = new Firmware();
        fw.version = data.Version;
        fw.updatedAt = data.UpdatedAt;
        return fw;
    }
}
export class DeviceFirmwareInfo {
    static fromApi(data) {
        if (data != null) {
            return DeviceFirmwareInfo.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let fwInfo = new DeviceFirmwareInfo();
        if (data.Current)
            fwInfo.current = Firmware.fromApi(data.Current);
        if (data.History)
            fwInfo.history = Firmware.fromApi(data.History);
        return fwInfo;
    }
}

import EventType from '../EventType';
import MeasurementStatus from '@/entities/enums/measurementStatuses';
export class Event {
    constructor() { }
    static fromApi(data) {
        if (!data)
            return null;
        else {
            let converted = {};
            Object.keys(data).forEach((key) => {
                let newEventRecord = {
                    [key]: data[key].map((et) => Event.define(et, key))
                };
                converted = Object.assign(converted, newEventRecord);
            });
            return converted;
        }
    }
    static define(data, eventId) {
        let event = new Event();
        event.sourceId = eventId;
        event.source = data.Source || { Id: null, Name: null, LastSeen: 0 };
        event.sourceGroup = data.SourceGroup || { Id: null, Name: null };
        event.measuredAt = data.MeasuredAt;
        event.value = data.Value.Value;
        event.unit = data.Value.Unit;
        event.eventType = EventType.fromApi(data.Type);
        event.status = MeasurementStatus.getStatusByName(data.Status);
        return event;
    }
    static findEventsBySourceGroupId(events, groupId, domain = null) {
        let allSourceGroupEvents = Object.values(events)
            .flat()
            .filter((x) => x.sourceGroup.Id == groupId);
        Event.sortBySourceName(allSourceGroupEvents);
        if (domain != null)
            return allSourceGroupEvents.filter((x) => x.eventType.domain == domain);
        else
            return allSourceGroupEvents;
    }
    static getEventsFromEventsRecord(events) {
        return Object.values(events).flat();
    }
    static getOverallStatus(events, groupId) {
        let eventsForGroup = Object.values(events)
            .flat()
            .filter((x) => x.sourceGroup.Id == groupId);
        return MeasurementStatus.findMaxStatus(eventsForGroup.map(function (o) {
            return o.status;
        }));
    }
    static sortBySourceName(sources) {
        return sources.sort(function (a, b) {
            let aId = a.source && a.source.Name ? a.source.Name.toLowerCase() : '';
            let bId = b.source && b.source.Name ? b.source.Name.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    /**
     * Calculates difference between measured value and defined boundaries for all given events
     * @param events
     * @param sources
     * @returns events with calculated difference
     */
    static calculateBoundaryDifference(events, sources) {
        for (let sourceId in events) {
            let eventsForSource = events[sourceId];
            let source = sources.find((x) => x.id === sourceId);
            if (!source)
                continue;
            eventsForSource.forEach((event) => {
                let diff = { Difference: '', Status: MeasurementStatus.OK };
                let boundaryForService = source.boundaries.find((x) => x.eventTypeSlug === event.eventType.slug);
                if (boundaryForService) {
                    if (boundaryForService.upperAlarmBoundary.Value !== null &&
                        event.value > boundaryForService.upperAlarmBoundary.Value) {
                        diff.Difference =
                            '+' +
                                Math.abs(event.value - boundaryForService.upperAlarmBoundary.Value).toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                });
                        diff.Status = MeasurementStatus.ALARM;
                    }
                    else if (boundaryForService.upperWarningBoundary.Value !== null &&
                        event.value > boundaryForService.upperWarningBoundary.Value) {
                        diff.Difference =
                            '+' +
                                Math.abs(event.value - boundaryForService.upperWarningBoundary.Value).toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                });
                        diff.Status = MeasurementStatus.WARN;
                    }
                    else if (boundaryForService.lowerAlarmBoundary.Value !== null &&
                        event.value < boundaryForService.lowerAlarmBoundary.Value) {
                        diff.Difference =
                            '-' +
                                Math.abs(event.value - boundaryForService.lowerAlarmBoundary.Value).toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                });
                        diff.Status = MeasurementStatus.ALARM;
                    }
                    else if (boundaryForService.lowerWarningBoundary.Value !== null &&
                        event.value < boundaryForService.lowerWarningBoundary.Value) {
                        diff.Difference =
                            '-' +
                                Math.abs(event.value - boundaryForService.lowerWarningBoundary.Value).toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                });
                        diff.Status = MeasurementStatus.WARN;
                    }
                }
                event.boundaryDiff = diff;
            });
        }
        return events;
    }
}

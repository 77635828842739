import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
import { CompanyLogger } from '@/entities/models/CompanyLogger';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import AddLoggersModal from '@/views/components/logger/AddLoggersModal.vue';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import AxiosService from '@/services/api/AxiosService';
import AppConfig from '@/configLoader';
import ApiLinks from '@/entities/ApiLinks';
var deviceRepository;
var companyLoggerRepository;
var sourceRepository;
let DeviceInfo = class DeviceInfo extends Vue {
    constructor() {
        super(...arguments);
        this.SignalStrengthValueBreakpoints = SignalStrengthValueBreakpoints;
        this.isDescriptionEditing = false;
        this.newDeviceDescription = null;
        this.isAddLoggersModalActive = false;
        this.sourceList = [];
        this.companyLoggerList = [];
        this.isLoading = false;
        this.isEditing = false;
        this.signalValue = null;
    }
    get descriptionChanged() {
        return this.newDeviceDescription != this.device.description;
    }
    get hasChanges() {
        return this.descriptionChanged;
    }
    get activeLoggers() {
        return this.companyLoggerList.filter((x) => x.active == true);
    }
    created() {
        deviceRepository = new DeviceRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        sourceRepository = new SourceRepository(this);
        this.newDeviceDescription = this.device.description;
        this.openSocket();
    }
    /*Close websocket if exists */
    async beforeDestroy() {
        await this.connection.stop();
    }
    editDescription() {
        if (this.newDeviceDescription == null)
            this.newDeviceDescription = this.device.description;
        this.isDescriptionEditing = !this.isDescriptionEditing;
    }
    async saveChanges() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let updateResult = null;
                if (this.hasChanges) {
                    let newDeviceDto = {
                        Name: this.device.deviceName,
                        Description: this.newDeviceDescription,
                        HardwareKey: this.device.deviceHardwareKey,
                        MacAddress: this.device.deviceMacAddress
                    };
                    updateResult = await deviceRepository.updateDevice(this.device.deviceId, newDeviceDto);
                }
                if (updateResult) {
                    VueUtilities.openSuccessToast(this, this.$t('component.device.list.table.msg.edit_success').toString());
                    this.isDescriptionEditing = false;
                    this.$emit('reloadDevice', updateResult.deviceId);
                }
            }
        });
    }
    async startScan() {
        await this.createApiCalls();
        this.isAddLoggersModalActive = true;
    }
    showAllLoggers() {
        return this.$ability.can(Actions.MANAGE, Subjects.SOURCE);
    }
    createApiCalls() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLoggers());
        if (this.showAllLoggers())
            allPromises.push(sourceRepository.getAllSources());
        else
            allPromises.push(sourceRepository.getVisibleSources());
        return Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1]);
        });
    }
    processLoadedDataForPage(companyLoggerList, sourceList) {
        CompanyLogger.addSources(companyLoggerList.getData(), sourceList.getData());
        this.companyLoggerList = companyLoggerList.getData();
        this.sourceList = sourceList.getData();
        this.isLoading = false;
    }
    onModalClose(data) {
        this.isAddLoggersModalActive = false;
    }
    editDevice() {
        this.isEditing = !this.isEditing;
    }
    /*Open websocket for PDF status */
    openSocket() {
        if (!this.connection)
            this.listenSignalStrength();
    }
    listenSignalStrength() {
        //Security
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        //open connection
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.SignalStrength.Home}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.device.deviceId);
        })
            .catch((error) => console.log(error));
        this.connection.on('SignalStrengthUpdated', (data) => {
            console.log(data);
            this.signalValue = data;
        });
        this.connection.on('SendCoreAsync', (data) => {
            console.log(data);
        });
    }
};
__decorate([
    Prop({ type: Object })
], DeviceInfo.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], DeviceInfo.prototype, "dateTimeManager", void 0);
DeviceInfo = __decorate([
    Component({ components: { AddLoggersModal } })
], DeviceInfo);
export default DeviceInfo;
/**
 * Enum for Wi-Fi signal strength value breakpoints. It is used to change signal icon.
 */
var SignalStrengthValueBreakpoints;
(function (SignalStrengthValueBreakpoints) {
    SignalStrengthValueBreakpoints[SignalStrengthValueBreakpoints["EXCELLENT"] = -50] = "EXCELLENT";
    SignalStrengthValueBreakpoints[SignalStrengthValueBreakpoints["GOOD"] = -70] = "GOOD";
    SignalStrengthValueBreakpoints[SignalStrengthValueBreakpoints["UNKNOWN"] = null] = "UNKNOWN";
})(SignalStrengthValueBreakpoints || (SignalStrengthValueBreakpoints = {}));

import DecimalConversion from '@/services/DecimalConversion';
export class Boundary {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Boundary.define(et));
        }
        else if (data != null) {
            return Boundary.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let boundary = new Boundary();
        boundary.upperAlarmBoundary = data.UpperAlarmBoundary;
        boundary.lowerAlarmBoundary = data.LowerAlarmBoundary;
        boundary.upperWarningBoundary = data.UpperWarningBoundary;
        boundary.lowerWarningBoundary = data.LowerWarningBoundary;
        boundary.eventTypeSlug = data.EventTypeSlug;
        return boundary;
    }
    toString() {
        return this.toString();
        /* let boundaryString: string = '';
        let unit: string = '';
        if (this.lowerBoundary != null && this.lowerBoundary.Value != null) {
          unit = this.lowerBoundary.Unit;
          boundaryString += `${DecimalConversion.toCommaString(this.lowerBoundary.Value)} ${unit}`;
        }
        if (this.upperBoundary != null && this.upperBoundary.Value != null) {
          boundaryString +=
            boundaryString.length > 0
              ? ` - ${DecimalConversion.toCommaString(this.upperBoundary.Value)}  ${unit}`
              : `${DecimalConversion.toCommaString(this.upperBoundary.Value)}  ${unit}`;
          unit = this.upperBoundary.Unit;
        }
        return boundaryString;*/
    }
    static convertBoundariesToDTO(boundaries) {
        let dtos = new Array();
        boundaries.forEach((boundary) => {
            let dto = new BoundaryDTO();
            dto.lowerAlarmBoundary = boundary.lowerAlarmBoundary.Value;
            dto.upperAlarmBoundary = boundary.upperAlarmBoundary.Value;
            dto.lowerWarningBoundary = boundary.lowerWarningBoundary.Value;
            dto.upperWarningBoundary = boundary.upperWarningBoundary.Value;
            dto.measuredDataTypeSlug = boundary.eventTypeSlug;
            dto.unit = boundary.lowerAlarmBoundary?.Unit || boundary.lowerWarningBoundary?.Unit;
            dtos.push(dto);
        });
        return dtos;
    }
}
export class BoundaryDTO {
    constructor() {
        this.measuredDataTypeSlug = null;
        this.isNew = false;
    }
    /**
     * Compares boundaries
     * @param originBoundaries
     * @param newBoundaries
     * @returns true if boundaries are same, false if not
     */
    static compareBoundaries(originBoundaries, newBoundaries) {
        let result = true;
        if (!originBoundaries || !newBoundaries)
            return true;
        let types = [...new Set(originBoundaries.map((x) => x.measuredDataTypeSlug))];
        let newTypes = [...new Set(newBoundaries.map((x) => x.measuredDataTypeSlug))];
        for (let index = 0; index < newTypes.length; index++) {
            if (!types.includes(newTypes[index]))
                return false;
        }
        types.forEach((type) => {
            let inOriginalBoundaries = originBoundaries.find((x) => x.measuredDataTypeSlug == type);
            let inNewBoundaries = newBoundaries.find((x) => x.measuredDataTypeSlug == type);
            if (!(inNewBoundaries &&
                DecimalConversion.toFloat(inNewBoundaries.lowerAlarmBoundary?.toString()) ==
                    inOriginalBoundaries.lowerAlarmBoundary &&
                DecimalConversion.toFloat(inNewBoundaries.upperAlarmBoundary?.toString()) ==
                    inOriginalBoundaries.upperAlarmBoundary)) {
                result = false;
            }
            if (inNewBoundaries && (DecimalConversion.toFloat(inNewBoundaries.upperWarningBoundary?.toString())) != inOriginalBoundaries.upperWarningBoundary) {
                result = false;
            }
            if (inNewBoundaries && (DecimalConversion.toFloat(inNewBoundaries.lowerWarningBoundary?.toString())) != inOriginalBoundaries.lowerWarningBoundary) {
                result = false;
            }
        });
        return result;
    }
}

import EventDomain, { getEventDomain } from '@/entities/enums/eventDomains';
export default class EventType {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => EventType.define(et));
        }
        else if (data != null) {
            return EventType.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let eventType = new EventType();
        eventType.id = data.Id;
        eventType.type = data.Type;
        eventType.domain = getEventDomain(data.Domain);
        eventType.name = data.Name;
        eventType.slug = data.Slug;
        eventType.icon = this.getIcon(data.Name);
        return eventType;
    }
    static getIcon(type) {
        switch (type) {
            case 'Temperature':
                return 'thermometer-half';
                break;
            case 'TemperatureTime':
                return 'clock';
                break;
            case 'BatteryTemperature':
                return 'temperature-high';
                break;
            case 'Humidity':
                return 'tint';
                break;
            case 'Shock':
                return 'car-crash';
                break;
            case 'Battery':
                return 'battery-full';
                break;
            case 'BatteryVoltage':
                return 'bolt';
                break;
            case 'Acceleration':
                return 'car-crash';
                break;
            case 'Pressure':
                return 'tachometer-alt';
                break;
            case 'Uptime':
                return 'signal';
                break;
            case 'DewPoint':
                return 'temperature-low';
                break;
            default:
                return 'question';
                break;
        }
    }
    static addUnits(eventTypes, units) {
        let typesWithUnit = new Array();
        eventTypes.forEach((type) => {
            let typeWithUnit = type;
            typeWithUnit.unit = units[type.type];
            typesWithUnit.push(typeWithUnit);
        });
        return typesWithUnit;
    }
    static getMeasuredEventTypes(eventTypes) {
        return eventTypes.filter((x) => x.domain == EventDomain.MEASURED);
    }
}
EventType.UPTIME_ID = 16;
export class EventTypeWithUnit extends EventType {
}

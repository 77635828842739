import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UnitNames from '@/entities/enums/UnitNames';
let UnitsSettings = class UnitsSettings extends Vue {
    constructor() {
        super(...arguments);
        this.UnitNames = UnitNames;
        this.selectedUnits = new Array();
        this.requestFinished = false;
    }
    onRequestPending() {
        if (this.requestPending == false) {
            this.requestFinished = true;
        }
    }
    get typesWithUnits() {
        let values = Object.values(UnitNames);
        return values;
    }
    created() {
        this.fillValues();
    }
    fillValues() {
        this.eventTypes.forEach((type) => {
            this.selectedUnits[type.type] = this.settings.events.units[type.type] || 'Default';
        });
    }
    unitChanged(unit, valueIdentifier) {
        this.$emit('unitChanged', unit, valueIdentifier);
    }
};
__decorate([
    Prop({ type: Array })
], UnitsSettings.prototype, "units", void 0);
__decorate([
    Prop({ type: Object })
], UnitsSettings.prototype, "settings", void 0);
__decorate([
    Prop({ type: Boolean })
], UnitsSettings.prototype, "requestPending", void 0);
__decorate([
    Prop({ type: Array })
], UnitsSettings.prototype, "eventTypes", void 0);
__decorate([
    Watch('requestPending')
], UnitsSettings.prototype, "onRequestPending", null);
UnitsSettings = __decorate([
    Component({})
], UnitsSettings);
export default UnitsSettings;

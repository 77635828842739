import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AddLoggersModal from '@/views/components/logger/AddLoggersModal.vue';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import MeasurementStatuses from '@/entities/enums/measurementStatuses';
let IssueInfo = class IssueInfo extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.MeasurementStatuses = MeasurementStatuses;
    }
    openNotificationTab() {
        this.$emit('openNotificationTab');
    }
};
__decorate([
    Prop({ type: Object })
], IssueInfo.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], IssueInfo.prototype, "issue", void 0);
__decorate([
    Prop({ type: Object })
], IssueInfo.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], IssueInfo.prototype, "source", void 0);
__decorate([
    Prop({ type: Object })
], IssueInfo.prototype, "sourceGroup", void 0);
__decorate([
    Prop({ type: Object })
], IssueInfo.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IssueInfo.prototype, "isOnAdminPage", void 0);
__decorate([
    Prop({ type: Number })
], IssueInfo.prototype, "totalNotification", void 0);
IssueInfo = __decorate([
    Component({ components: { AddLoggersModal } })
], IssueInfo);
export default IssueInfo;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("admin.component.devices.firmwareList.title")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item is-hidden-mobile",
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFilter()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
              ),
              _vm.$ability.can(
                _vm.$permActions.CREATE,
                _vm.$permSubjects.ADMIN_GATEWAYS_FIRMWARES
              )
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      attrs: { type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.newFirmware()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.devices.firmwareList.addFirmware"
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "is-dark-grey",
                                      staticStyle: { "font-size": "1rem" },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field == _vm.tableColumns.id.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.devices.firmwareList.table." +
                                        column.field
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter res-table",
          attrs: {
            data: _vm.deviceFirmwareList,
            paginated: "",
            "backend-pagination": "",
            "pagination-order": "is-centered",
            striped: true,
            "per-page": _vm.pageSize,
            total: _vm.totalSize,
            "current-page": _vm.page,
            "backend-sorting": "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "page-input": true,
            "backend-filtering": "",
          },
          on: {
            "page-change": _vm.onPageChange,
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.deviceFirmwareList.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              sortable: "",
              searchable: "",
              field: _vm.tableColumns.id.field,
              label: _vm.$t(
                "admin.component.devices.firmwareList.table." +
                  _vm.tableColumns.id.field
              ),
              visible: _vm.tableColumns.id.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isIdSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.devices.firmwareList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "min:36",
                                      expression: "'min:36'",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: { input: _vm.handleFilterIdDebounce },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterFirmware(
                                        _vm.tableColumns.id.field,
                                        _vm.idSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.idSearch,
                                    callback: function ($$v) {
                                      _vm.idSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "idSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile is-full-width",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              expanded: "",
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.devices.firmwareList.table.Id"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.devices.firmwareList.table.Id"
                                )
                              ),
                            },
                          },
                          [
                            _c("b-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:36",
                                  expression: "'min:36'",
                                },
                              ],
                              attrs: {
                                type: "text",
                                name: _vm.$t(
                                  "admin.component.devices.firmwareList.table.Id"
                                ),
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterIdDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.filterFirmware(
                                    _vm.tableColumns.id.field,
                                    _vm.idSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.idSearch,
                                callback: function ($$v) {
                                  _vm.idSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "idSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (deviceFirmwareList) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "is-family-monospace",
                        attrs: {
                          to: {
                            name: "adminFirmwareDetail",
                            params: {
                              firmwareId: deviceFirmwareList.row.id,

                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                        nativeOn: {
                          contextmenu: function ($event) {
                            return _vm.copyLinkToClipboard(
                              deviceFirmwareList.row.id
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(deviceFirmwareList.row.id))]
                    ),
                    _vm.hasCopyIconAllowed
                      ? _c("b-icon", {
                          staticClass: "clickable is-size-7 is-hidden-touch",
                          attrs: { icon: "clipboard" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.copyLinkToClipboard(
                                deviceFirmwareList.row.id
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdBy.field,
              label: _vm.$t(
                "admin.component.devices.firmwareList.table." +
                  _vm.tableColumns.createdBy.field
              ),
              visible: _vm.tableColumns.createdBy.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceFirmwareList) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "adminUserDetail",
                            params: {
                              userId:
                                deviceFirmwareList.row.createdBy.apiUserId,

                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(deviceFirmwareList.row.createdBy.fullName)
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.version.field,
              label: _vm.$t(
                "admin.component.devices.firmwareList.table." +
                  _vm.tableColumns.version.field
              ),
              visible: _vm.tableColumns.version.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isVersionSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.devices.firmwareList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterVersionDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterFirmware(
                                        _vm.tableColumns.version.field,
                                        _vm.versionSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.versionSearch,
                                    callback: function ($$v) {
                                      _vm.versionSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "versionSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterVersionDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.filterFirmware(
                                _vm.tableColumns.version.field,
                                _vm.versionSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.versionSearch,
                            callback: function ($$v) {
                              _vm.versionSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "versionSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (deviceFirmwareList) {
                  return [
                    _vm._v(" " + _vm._s(deviceFirmwareList.row.version) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                "admin.component.devices.firmwareList.table." +
                  _vm.tableColumns.createdAt.field
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (deviceFirmwareList) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(deviceFirmwareList.row.createdAt * 1000),
                            null,
                            null,
                            null
                          )
                        )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.devices.firmwareList.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
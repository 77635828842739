import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import UserRepository from '@/services/repository/UserRepository';
import AppConfig from '@/configLoader';
import { vxm } from '@/store/store.vuex';
import RegisterLoggersToDepartmentModal from './RegisterLoggersToDepartmentModal.vue';
import AddLoggersModal from './AddLoggersModal.vue';
import SortDirections from '@/entities/enums/SortDirections';
import { ServiceState } from '@/entities/enums/ServiceState';
import LoggedUserManager from '@/services/LoggedUserManager';
var companyLoggerRepository;
var userRepository;
var pdf = require('@/assets/other/loghub_sample_calibration_protocol.pdf');
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
var vxDepartmentStore = vxm.departmentStore;
let LoggerConfigurationList = class LoggerConfigurationList extends Vue {
    constructor() {
        super(...arguments);
        this.demo = AppConfig.getConfig().demo.name;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.CalibrationStatus = CalibrationStatus;
        this.ServiceState = ServiceState;
        this.isScannerModalActive = false;
        this.isRegisterLoggersModalActive = false;
        this.isLoading = false;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.total = 0;
        this.pdf = pdf;
        this.currentUserName = null;
        this.componentName = 'loggerList';
        this.isColumnsChangedForMobile = false;
        this.tableQueries = null;
        this.tableColumns = {
            name: {
                field: 'name',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListName)
            },
            serialNumber: {
                field: 'serialNumber',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListSerialNumber)
            },
            description: {
                field: 'description',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListDescription)
            },
            lastSeen: {
                field: 'lastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListLastSeen)
            },
            battery: {
                field: 'battery',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListBattery)
            },
            isOnline: {
                field: 'isOnline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListIsOnline)
            },
            calibration: {
                field: 'calibration',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListCalibration)
            },
            macAddress: {
                field: 'macAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListMacAddress)
            },
            visible: {
                field: 'visible',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListVisible)
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    async created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        userRepository = new UserRepository(this);
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.loadUser();
        this.isRegisterLoggersModalActive = this.openAddLoggers;
        this.sendLoggerModalEvent();
    }
    fillFilterFromRoute() {
        try {
            this.tableQueries = JSON.parse(this.$router.currentRoute.query.loggerList);
            this.sortField = this.tableQueries.sortField;
            this.sortDirection = this.tableQueries.sortDirection;
            this.page = this.tableQueries.page;
            this.pageSize = this.tableQueries.pageSize;
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.SourceListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.SourceListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.SourceListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.SourceListRowsPerPage.defaultValue;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange();
    }
    onPageChange(page) {
        this.page = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    onOptionsChange() {
        let tableQueries = {
            sortField: this.sort.field,
            sortDirection: this.sort.direction == '-' ? 'desc' : 'asc',
            page: this.page,
            pageSize: this.pageSize,
            filters: null
        };
        this.changeRouterUrl(JSON.stringify(tableQueries));
    }
    changeRouterUrl(tableQueries) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                [this.componentName]: tableQueries
            })
        })
            .catch((err) => err);
    }
    async loadUser() {
        let currentUser = await userRepository.getCurrentUser();
        this.currentUserName = currentUser.username;
    }
    get isDemo() {
        return this.currentUserName == this.demo;
    }
    mounted() {
        this.total = this.$refs.table._data.newData.length;
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    }
    onResize() {
        if (window.innerWidth <= 768 && !this.isColumnsChangedForMobile) {
            this.isColumnsChangedForMobile = true;
            this.tableColumns.battery.visible = false;
            this.tableColumns.calibration.visible = false;
            this.tableColumns.description.visible = false;
            this.tableColumns.lastSeen.visible = false;
            this.tableColumns.macAddress.visible = false;
        }
        else if (window.innerWidth > 768 && this.isColumnsChangedForMobile) {
            this.isColumnsChangedForMobile = false;
            this.tableColumns.description.visible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListDescription);
            this.tableColumns.lastSeen.visible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListLastSeen);
            this.tableColumns.battery.visible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListBattery);
            this.tableColumns.calibration.visible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListCalibration);
            this.tableColumns.macAddress.visible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListMacAddress);
        }
    }
    openModal() {
        this.isRegisterLoggersModalActive = true;
        this.sendLoggerModalEvent();
    }
    onModalClose(data) {
        if (data != false && data != 'outside')
            this.reloadLoggers(true);
        this.isRegisterLoggersModalActive = false;
        this.sendLoggerModalEvent();
    }
    reloadLoggers(showLoadingAnimation) {
        this.$emit('reload-loggers', showLoadingAnimation);
    }
    sendLoggerModalEvent() {
        this.$emit('logger-modal-visible', this.isRegisterLoggersModalActive);
    }
    get activeLoggers() {
        return this.companyLoggerList.filter((x) => x.active == true);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.downloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    hasCalibrationProtocolToDownload(source) {
        if (source.logger == null || source.logger.calibration == null || source.logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.description.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListDescription, event);
                break;
            case this.tableColumns.lastSeen.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListLastSeen, event);
                break;
            case this.tableColumns.isOnline.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListIsOnline, event);
                break;
            case this.tableColumns.calibration.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListCalibration, event);
                break;
            case this.tableColumns.macAddress.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListMacAddress, event);
                break;
            case this.tableColumns.visible.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListVisible, event);
                break;
            case this.tableColumns.battery.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.LoggerConfigurationListBattery, event);
                break;
        }
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    changeVisibility(source) {
        this.$emit('changeVisibility', source, !source.visible);
    }
    changeAllVisibility(vis) {
        this.sourceList.forEach((x) => this.$emit('changeVisibility', x, vis));
    }
    scannerModalActive(value) {
        this.isScannerModalActive = value;
    }
    sortByIsOnline(a, b, isAsc) {
        const isOnline1 = a.checkIsOnline();
        const isOnline2 = b.checkIsOnline();
        if (isOnline1 === isOnline2) {
            return 0;
        }
        if (isAsc) {
            return isOnline1 ? -1 : 1;
        }
        else {
            return isOnline1 ? 1 : -1;
        }
    }
};
__decorate([
    Prop({ type: Array })
], LoggerConfigurationList.prototype, "companyLoggerList", void 0);
__decorate([
    Prop({ type: Array })
], LoggerConfigurationList.prototype, "sourceList", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], LoggerConfigurationList.prototype, "inWizard", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], LoggerConfigurationList.prototype, "openAddLoggers", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], LoggerConfigurationList.prototype, "showHiddenLoggers", void 0);
__decorate([
    Prop({ type: Object })
], LoggerConfigurationList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: String })
], LoggerConfigurationList.prototype, "deviceId", void 0);
LoggerConfigurationList = __decorate([
    Component({ components: { RegisterLoggersToDepartmentModal, AddLoggersModal } })
], LoggerConfigurationList);
export default LoggerConfigurationList;

import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UnitNames from '@/entities/enums/UnitNames';
let AdminUserSettingsComponent = class AdminUserSettingsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.UnitNames = UnitNames;
        this.convertedUnits = [];
    }
    unitsChanged() {
        this.createUnitsObject();
    }
    createUnitsObject() {
        this.typesWithUnits.forEach((type, index) => {
            const filteredUnit = this.units[index].find((x) => x.identifier == this.userSettings.events.units[index]);
            let unitObj = {
                name: type.name,
                key: filteredUnit.name.split(' ').join('_').toLowerCase(),
                unit: filteredUnit.unit
            };
            this.convertedUnits.push(unitObj);
        });
    }
    get typesWithUnits() {
        let values = Object.values(this.UnitNames);
        return values.filter((x) => x.id <= 5);
    }
};
__decorate([
    Prop({ type: Object })
], AdminUserSettingsComponent.prototype, "userSettings", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserSettingsComponent.prototype, "units", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserSettingsComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminUserSettingsComponent.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserSettingsComponent.prototype, "departmentUserSettings", void 0);
__decorate([
    Watch('units')
], AdminUserSettingsComponent.prototype, "unitsChanged", null);
AdminUserSettingsComponent = __decorate([
    Component({})
], AdminUserSettingsComponent);
export default AdminUserSettingsComponent;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MeasurementStatuses from '@/entities/enums/measurementStatuses';
let StatusCards = class StatusCards extends Vue {
    constructor() {
        super(...arguments);
        this.outOfBoundsCount = 0;
        this.outOfBoundsTempCount = 0;
        this.outOfBoundsHumidityCount = 0;
        this.measuredEventsCount = 0;
        this.MeasurementStatuses = MeasurementStatuses;
        this.outOfBoundsmeasuredEvents = {};
        this.measuredEvents = {};
        this.hasAnyMeasurements = false;
    }
    created() {
        this.loadData();
    }
    get companyLoggerExpirationList() {
        let currentTime = new Date();
        return this.sources.filter((x) => x.logger &&
            x.logger.active == true &&
            (x.logger.calibration.status.name == 'not_calibrated' ||
                x.logger.calibration.status.name == 'expired' ||
                (x.logger.calibration.status.name == 'calibrated' &&
                    currentTime.getSeconds() - x.logger.calibration.expiresAt < 7890000)));
    }
    get withBatteryUnder15() {
        return this.sources.filter((x) => x.logger && x.logger.battery <= 15 && x.logger.battery !== null);
    }
    get withBatteryUnder50() {
        return this.sources.filter((x) => x.logger && x.logger.battery < 50 && x.logger.battery > 15 && x.logger.battery !== null);
    }
    get withBatteryAbove50() {
        return this.sources.filter((x) => x.logger && x.logger.battery >= 50 && x.logger.battery !== null);
    }
    get withBatteryUnknown() {
        return this.sources.filter((x) => (x.logger && x.logger.battery == null) || !x.logger);
    }
    loadData() {
        Object.keys(this.lastEvents).forEach((key) => {
            this.lastEvents[key].forEach((event) => {
                if (event) {
                    this.measuredEvents[key] = this.lastEvents[key];
                    if (event.status.name === MeasurementStatuses.ALARM.name) {
                        this.outOfBoundsmeasuredEvents[key] = this.lastEvents[key];
                    }
                }
            });
        });
        this.measuredEventsCount = Object.keys(this.measuredEvents).length;
        this.outOfBoundsCount = Object.keys(this.outOfBoundsmeasuredEvents).length;
        Object.keys(this.outOfBoundsmeasuredEvents).forEach((key) => {
            this.lastEvents[key].forEach((event) => {
                this.measuredEvents[key] = this.lastEvents[key];
                if (event.status.name === MeasurementStatuses.ALARM.name && event.eventType.type == 0) {
                    this.outOfBoundsTempCount++;
                }
                else if (event.status.name === MeasurementStatuses.ALARM.name && event.eventType.type == 1) {
                    this.outOfBoundsHumidityCount++;
                }
            });
        });
    }
};
__decorate([
    Prop({ type: Array })
], StatusCards.prototype, "deviceList", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatusCards.prototype, "sources", void 0);
__decorate([
    Prop({ type: Object })
], StatusCards.prototype, "lastEvents", void 0);
__decorate([
    Prop({ type: Object })
], StatusCards.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            return [];
        }
    })
], StatusCards.prototype, "notifications", void 0);
__decorate([
    Prop({ type: Number })
], StatusCards.prototype, "totalSize", void 0);
StatusCards = __decorate([
    Component({
        components: {}
    })
], StatusCards);
export default StatusCards;

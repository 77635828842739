export class DeviceConfiguration {
    constructor() {
        this.additionalData = {};
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DeviceConfiguration.define(et));
        }
        else if (data != null) {
            return DeviceConfiguration.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let config = new DeviceConfiguration();
        config.configId = data.ConfigId;
        config.deviceId = data.DeviceId;
        config.createdAt = data.CreatedAt;
        config.configuredAt = data.ConfiguredAt;
        config.interval = data.Interval;
        if (data.AdditionalData)
            config.additionalData = data.AdditionalData;
        return config;
    }
}

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
var deviceRepository;
let AdminConfigureDeviceModal = class AdminConfigureDeviceModal extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceInstanceState = DeviceInstanceState;
        this.interval = null;
        this.maxOutOfRangeTime = null;
        this.offlineSyncInterval = null;
        this.scanTimeout = null;
        this.checkInterval = null;
        this.chunkSize = null;
        this.customPair = [{ customKey: null, customValue: null }];
    }
    get deviceNames() {
        return this.deviceList.map((x) => x.deviceName).join(', ');
    }
    created() {
        deviceRepository = new DeviceRepository(this);
        if (this.deviceList.length == 1) {
            this.interval = this.deviceList[0].currentConfig?.interval?.toString();
            this.maxOutOfRangeTime = this.deviceList[0].currentConfig?.additionalData?.maxOutOfRangeTime;
            this.offlineSyncInterval = this.deviceList[0].currentConfig?.additionalData?.offlineSyncInterval;
            this.scanTimeout = this.deviceList[0].currentConfig?.additionalData?.scanTimeout;
            this.checkInterval = this.deviceList[0].currentConfig?.additionalData?.checkInterval;
            this.chunkSize = this.deviceList[0].currentConfig?.additionalData?.chunkSize;
        }
    }
    async configure() {
        this.$validator.validateAll().then((result) => {
            if (result) {
                let dateNow = new Date();
                let minutesNow = dateNow.getMinutes();
                dateNow.setMinutes(minutesNow + 1);
                let intervalAsNumber = parseInt(this.interval);
                let additionalData = {
                    maxOutOfRangeTime: this.maxOutOfRangeTime,
                    offlineSyncInterval: this.offlineSyncInterval,
                    scanTimeout: this.scanTimeout,
                    checkInterval: this.checkInterval,
                    chunkSize: this.chunkSize
                };
                this.customPair.forEach((pair) => {
                    additionalData[pair.customKey] = pair.customValue;
                });
                Object.keys(additionalData).forEach((k) => (additionalData[k] == null || additionalData[k] == '') && delete additionalData[k]);
                let config = {
                    ScheduleAt: Math.round(dateNow.getTime() / 1000),
                    Interval: intervalAsNumber,
                    AdditionalData: additionalData
                };
                let configurePromises = [];
                this.deviceList.forEach((device) => {
                    configurePromises.push(deviceRepository.adminConfigureDevice(device.deviceId, config));
                });
                Promise.all(configurePromises).then((response) => {
                    VueUtilities.openSuccessToast(this, this.$tc('admin.component.devices.successfully_configured', response.length).toString());
                    this.$emit('configured', true);
                });
            }
        });
    }
    addCustomSetting() {
        this.customPair.push({ customKey: null, customValue: null });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Array })
], AdminConfigureDeviceModal.prototype, "deviceList", void 0);
AdminConfigureDeviceModal = __decorate([
    Component({})
], AdminConfigureDeviceModal);
export default AdminConfigureDeviceModal;

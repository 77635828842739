import NotificationPriority from '@/entities/enums/NotificationPriority';
import NotificationCategory from '@/entities/enums/NotificationCategory';
const NOTIFICATION_CHECK_TIME_S = 600;
const DISMISS_TIMEOUT_S = 3600;
const SHOW_PAUSE_NOTIF_COUNT = 1;
export class Notification {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Notification.define(et));
        }
        else if (data != null) {
            return Notification.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let notification = new Notification();
        notification.category = NotificationCategory.getNotificationCategoryByName(data.Category);
        notification.createdAt = data.CreatedAt;
        notification.description = data.Description;
        notification.link = data.Link;
        notification.metadata = data.Metadata;
        notification.notificationId = data.NotificationId;
        notification.priority = NotificationPriority.getNotificationPriorityByName(data.Priority);
        notification.title = data.Title;
        return notification;
    }
    static countIsEnoughToShowPause(notifications, maxCount) {
        let compareTime = Math.round(new Date().getTime() / 1000) - NOTIFICATION_CHECK_TIME_S;
        let countInTime = notifications.filter((x) => x.createdAt > compareTime).length;
        return countInTime >= maxCount;
    }
    static pauseComponentIsVisible(pauseDismissedAt, pauseNotificationsUntil, notifications) {
        let now = Math.floor(new Date().getTime() / 1000);
        if (pauseDismissedAt + Notification.DISMISS_TIMEOUT < now &&
            Notification.countIsEnoughToShowPause(notifications, Notification.SHOW_PAUSE_NOTIF_COUNT) &&
            pauseNotificationsUntil < now)
            return true;
        else
            return false;
    }
    static get DISMISS_TIMEOUT() {
        return DISMISS_TIMEOUT_S;
    }
    static get SHOW_PAUSE_NOTIF_COUNT() {
        return SHOW_PAUSE_NOTIF_COUNT;
    }
}

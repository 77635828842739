var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "div",
          {
            staticClass:
              "is-flex is-justify-content-space-between is-align-items-center mb-2",
          },
          [
            _c("h2", { staticClass: "subtitle is-4 mb-0" }, [
              _vm._v(" " + _vm._s(_vm.$t("component.last_events.title")) + " "),
            ]),
            _c(
              "b-button",
              {
                staticClass: "is-hidden-mobile",
                attrs: {
                  type: "is-primary",
                  outlined: "",
                  "icon-pack": "fa",
                  "icon-right": "sync",
                  disabled: !_vm.refreshAllowed,
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("refresh")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.refresh")))]
            ),
            _c("b-button", {
              staticClass: "is-hidden-tablet",
              attrs: {
                type: "is-primary",
                inverted: "",
                size: "is-medium",
                "icon-right": "fas fa-sync",
                disabled: !_vm.refreshAllowed,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("refresh")
                },
              },
            }),
          ],
          1
        ),
        _vm.refreshAllowed
          ? [
              _c("EventsTable", {
                attrs: {
                  sourceGroups: _vm.sourceGroups,
                  lastEvents: _vm.aliveEvents,
                  showFavoriteStar: true,
                  favoriteGroupId: _vm.favoriteGroupId,
                },
              }),
            ]
          : [
              _c(
                "div",
                [
                  _c("b-skeleton", { attrs: { width: "20%", animated: true } }),
                  _c("b-skeleton", { attrs: { width: "40%", animated: true } }),
                  _c("b-skeleton", { attrs: { width: "80%", animated: true } }),
                  _c("b-skeleton", { attrs: { animated: true } }),
                ],
                1
              ),
            ],
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm.refreshAllowed
          ? [
              _c(
                "b-collapse",
                {
                  staticClass: "panel outdated",
                  attrs: { animation: "slide" },
                  on: {
                    open: function ($event) {
                      return _vm.outdatedDataCollapseChanged(true)
                    },
                    close: function ($event) {
                      return _vm.outdatedDataCollapseChanged(false)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "trigger",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "panel-heading m-0 p-0",
                                attrs: { role: "button" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "has-text-weight-medium is-flex is-justify-content-space-between",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.last_events.old_events"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tag",
                                          {
                                            attrs: {
                                              type: "is-primary",
                                              rounded: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.notAliveEventsCount)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.outdatedDataVisible
                                      ? _c("b-icon", {
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "chevron-right" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2638075828
                  ),
                  model: {
                    value: _vm.outdatedDataVisible,
                    callback: function ($$v) {
                      _vm.outdatedDataVisible = $$v
                    },
                    expression: "outdatedDataVisible",
                  },
                },
                [
                  _c("EventsTable", {
                    attrs: {
                      sourceGroups: _vm.sourceGroups,
                      lastEvents: _vm.notAliveEvents,
                      showFavoriteStar: false,
                      favoriteGroupId: _vm.favoriteGroupId,
                    },
                  }),
                ],
                1
              ),
            ]
          : [
              _c(
                "div",
                [
                  _c("b-skeleton", { attrs: { width: "20%", animated: true } }),
                  _c("b-skeleton", { attrs: { width: "40%", animated: true } }),
                  _c("b-skeleton", { attrs: { width: "80%", animated: true } }),
                  _c("b-skeleton", { attrs: { animated: true } }),
                ],
                1
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "multi-header",
          class: {
            "has-bottom-margin": !_vm.$ability.can(
              _vm.$permActions.MANAGE,
              _vm.$permSubjects.SOURCE_GROUP
            ),
          },
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb",
              attrs: { "aria-label": "breadcrumb" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _vm._m(0),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                      ]
                    ),
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "dashboard",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "home" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.home")))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "settings",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("component.settings.title")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "configuration_groups",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.settings.location.group_settings"
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(_vm._s(_vm.group.name)),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.$ability.can(
            _vm.$permActions.MANAGE,
            _vm.$permSubjects.SOURCE_GROUP
          )
            ? _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeGroupConfirm()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("component.group.detail.removeGroup_btn"))
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c("div", { staticClass: "tile is-ancestor" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "tile is-child box" },
                [
                  _c("GroupInfo", {
                    ref: "groupInfoComponent",
                    attrs: {
                      group: _vm.group,
                      eventTypes: _vm.eventTypes,
                      sources: _vm.sources,
                      hasEditPermissions: _vm.hasEditPermissions,
                    },
                    on: {
                      reloadGroup: _vm.loadData,
                      triggerUnsavedChangesModal:
                        _vm.triggerUnsavedChangesModal,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.triggerUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.triggerUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.triggerUnsavedChangesModal,
              discard: _vm.triggerUnsavedChangesModal,
              cancel: _vm.triggerUnsavedChangesModal,
              save: _vm.saveUnsavedChanges,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
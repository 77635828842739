import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AssignSourceList from '@/views/components/logger/AssignSourceList.vue';
let CreateSourcesModal = class CreateSourcesModal extends Vue {
    constructor() {
        super(...arguments);
        this.loggersToAssign = [];
    }
    created() {
        let withoutSource = this.assignedLoggers.filter((x) => !x.source.exists());
        if (withoutSource.length > 0) {
            this.loggersToAssign = withoutSource;
        }
    }
    resultRetrieved(event) {
        this.$emit('close-modal', event);
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], CreateSourcesModal.prototype, "assignedLoggers", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], CreateSourcesModal.prototype, "inWizard", void 0);
CreateSourcesModal = __decorate([
    Component({ components: { AssignSourceList } })
], CreateSourcesModal);
export default CreateSourcesModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FeaturesTypes } from '@/entities/tiers/FeaturesTypes';
let CreateTier = class CreateTier extends Vue {
    constructor() {
        super(...arguments);
        this.FeaturesTypes = FeaturesTypes;
        this.name = null;
        this.displayName = null;
        this.active = true;
        this.features = [];
        this.featuresInTable = [];
        this.hasFeaturesError = false;
    }
    created() {
        this.tierFeatures.forEach((feature) => {
            let featureFeObj = {
                enabled: false,
                type: feature.type,
                name: feature.name,
                value: feature.default
            };
            this.featuresInTable.push(featureFeObj);
        });
    }
    createTier() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let featuresCreateDtoArr = [];
                this.featuresInTable.forEach((feature) => {
                    let featureDto = {
                        Name: feature.name,
                        Value: feature.value
                    };
                    featuresCreateDtoArr.push(featureDto);
                });
                let tierDto = {
                    Name: this.name,
                    DisplayName: this.displayName,
                    Order: 0,
                    Active: this.active,
                    Features: featuresCreateDtoArr
                };
                this.$emit('createTier', tierDto);
            }
        });
    }
    setUnlimited(feature) {
        feature.value.Quota = -1;
    }
};
__decorate([
    Prop({ type: Array })
], CreateTier.prototype, "tierFeatures", void 0);
CreateTier = __decorate([
    Component({})
], CreateTier);
export default CreateTier;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header mb-5" }, [
        _c(
          "div",
          {
            staticClass:
              "is-flex is-justify-content-start is-align-items-center",
          },
          [
            _c("h2", { staticClass: "level-item mb-0 subtitle is-4 mr-2" }, [
              _vm._v(" " + _vm._s(_vm.$t("component.floorPlan.title")) + " "),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "b-tooltip",
              {
                attrs: {
                  type: "is-dark",
                  label: _vm.$t("component.floorPlan.deletePlan"),
                  position: "is-left",
                },
              },
              [
                _vm.editedFloorPlanData && _vm.floorPlanImage && !_vm.isUpdating
                  ? _c("b-button", {
                      staticClass: "mr-2",
                      attrs: { type: "is-primary", "icon-left": "trash" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteFloorPlan()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.editedFloorPlanData && _vm.floorPlanImage
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      "icon-left": _vm.isUpdating
                        ? "times-circle"
                        : "pencil-alt",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updatePlan()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.isUpdating ? _vm.$t("generals.cancel_edit") : ""
                      ) + " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              [
                !_vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        attrs: {
                          label: _vm.$t("admin.component.layout.listView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c("b-button", {
                          staticStyle: { "font-size": "1rem" },
                          attrs: { "icon-left": "stream" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLayout()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        attrs: {
                          label: _vm.$t("admin.component.layout.gridView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c("b-button", {
                          staticStyle: { "font-size": "1rem" },
                          attrs: { "icon-left": "th-large" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLayout()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.isLayoutList
        ? [
            _c("div", { staticClass: "columns columns-info-form" }, [
              _c(
                "div",
                { staticClass: "column is-half" },
                [
                  _vm.editedFloorPlanData.length === 0 &&
                  _vm.floorPlanImage == null &&
                  _vm.imageFile == null
                    ? _c(
                        "image-uploader",
                        {
                          staticClass: "my-2",
                          attrs: {
                            className: [
                              "fileinput",
                              { "fileinput--loaded": _vm.isUploading },
                            ],
                            debug: 0,
                            quality: 1,
                            preview: false,
                            autoRotate: true,
                            accept: ".jpg,.png,.jpeg",
                          },
                          on: { input: _vm.setImage },
                        },
                        [
                          _c(
                            "label",
                            {
                              attrs: { slot: "upload-label", for: "fileInput" },
                              slot: "upload-label",
                            },
                            [
                              _c(
                                "figure",
                                {
                                  staticClass:
                                    "is-flex border dash is-justify-content-center py-5 px-2 is-clickable",
                                  attrs: { expanded: "" },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("b-icon", {
                                        staticClass:
                                          "control-icon clickable p-0 is-block",
                                        staticStyle: { margin: "0px auto" },
                                        attrs: {
                                          type: "is-primary",
                                          icon: "upload",
                                          size: "is-medium",
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "line-height": "2rem",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isUploading
                                                  ? _vm.$t(
                                                      "component.floorPlan.replace"
                                                    )
                                                  : _vm.$t(
                                                      "component.floorPlan.upload"
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.imageFile && _vm.editedFloorPlanData.length == 0
                    ? _c(
                        "b-message",
                        {
                          staticClass: "mt-2 mb-4",
                          attrs: { type: "is-info", "has-icon": "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("component.floorPlan.message")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  (_vm.isUploading && _vm.editedFloorPlanData.length > 0) ||
                  (_vm.isUpdating && _vm.editedFloorPlanData.length > 0)
                    ? _c(
                        "b-message",
                        {
                          staticClass: "mt-2 mb-4",
                          attrs: { type: "is-info", "has-icon": "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.floorPlan.dragMessage")
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _vm.isUploading && _vm.imageFile != null
                        ? _c("b-button", {
                            staticClass: "canvas-close",
                            attrs: {
                              size: "is-small",
                              type: "is-primary",
                              "icon-right": "times",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetData()
                              },
                            },
                          })
                        : _vm._e(),
                      _c("canvas", {
                        staticClass: "mt-3 mb-2",
                        attrs: { id: "canvas", height: "1px" },
                        on: {
                          click: function ($event) {
                            return _vm.draw($event)
                          },
                          mousedown: _vm.myDown,
                          mouseup: _vm.myUp,
                          mousemove: _vm.myMove,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isUploading || _vm.isUpdating
                ? _c(
                    "div",
                    { staticClass: "column is-half" },
                    [
                      _vm._l(_vm.editedFloorPlanData, function (v, index) {
                        return _c(
                          "b-field",
                          {
                            key: index,
                            staticClass: "is-flex is-flex-wrap-wrap",
                            attrs: {
                              label: index + ".",
                              "label-position": "on-border",
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(index + ".")
                                ),
                              },
                              message: _vm.errors.first(_vm.$t(index + ".")),
                            },
                          },
                          [
                            _vm.editedFloorPlanData[index].entityId
                              ? _c(
                                  "b-select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "is-half-width",
                                    attrs: {
                                      expanded: "",
                                      placeholder: _vm.$t(
                                        "component.floorPlan.placeholder"
                                      ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.selectEntity($event, index)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.editedFloorPlanData[index].entityId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedFloorPlanData[index],
                                          "entityId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedFloorPlanData[index].entityId",
                                    },
                                  },
                                  [
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "component.floorPlan.sources"
                                          ),
                                        },
                                      },
                                      _vm._l(_vm.sources, function (source) {
                                        return _c(
                                          "option",
                                          {
                                            key: source.id,
                                            domProps: { value: source.id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(source.name) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "component.floorPlan.devices"
                                          ),
                                        },
                                      },
                                      _vm._l(_vm.devices, function (device) {
                                        return _c(
                                          "option",
                                          {
                                            key: device.deviceId,
                                            domProps: {
                                              value: device.deviceId,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(device.deviceName) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "is-half-width",
                                    attrs: {
                                      expanded: "",
                                      placeholder: _vm.$t(
                                        "component.floorPlan.placeholder"
                                      ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.selectEntity($event, index)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.editedFloorPlanData[index].entityId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedFloorPlanData[index],
                                          "entityId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedFloorPlanData[index].entityId",
                                    },
                                  },
                                  [
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "component.floorPlan.sources"
                                          ),
                                        },
                                      },
                                      _vm._l(
                                        _vm.getAvailableSources(),
                                        function (source) {
                                          return _c(
                                            "option",
                                            {
                                              key: source.id,
                                              domProps: { value: source.id },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(source.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "component.floorPlan.devices"
                                          ),
                                        },
                                      },
                                      _vm._l(
                                        _vm.getAvailableDevices(),
                                        function (device) {
                                          return _c(
                                            "option",
                                            {
                                              key: device.deviceId,
                                              domProps: {
                                                value: device.deviceId,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(device.deviceName) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                            _c("b-button", {
                              staticClass: "ml-2",
                              attrs: {
                                disabled: _vm.editedFloorPlanData.length <= 1,
                                type: "is-primary",
                                "icon-right": "trash",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeLogger(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          (_vm.numberOfLoggers !== 0 && _vm.isUploading) ||
                          _vm.isUpdating
                            ? _c("b-button", {
                                attrs: {
                                  label: _vm.$t(
                                    "component.floorPlan.resetPlan"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetFloorPlan()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.numberOfLoggers !== 0 &&
                          _vm.isUploading &&
                          !_vm.isUpdating
                            ? _c("b-button", {
                                attrs: {
                                  type: "is-primary",
                                  label: _vm.$t("component.floorPlan.savePlan"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveFloorPlan()
                                  },
                                },
                              })
                            : _vm._e(),
                          !_vm.isUploading && _vm.isUpdating
                            ? _c("b-button", {
                                attrs: {
                                  type: "is-primary",
                                  label: _vm.$t(
                                    "component.floorPlan.updatePlan"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateFloorPlan()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.isUploading &&
              !_vm.isUpdating &&
              _vm.editedFloorPlanData.length !== 0
                ? _c(
                    "div",
                    { staticClass: "column is-halfs" },
                    [
                      _c(
                        "b-table",
                        { attrs: { data: _vm.editedFloorPlanData } },
                        [
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t("component.floorPlan.table.index"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (entity) {
                                    return [_vm._v(_vm._s(entity.index + "."))]
                                  },
                                },
                              ],
                              null,
                              false,
                              1987620866
                            ),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t("component.floorPlan.table.name"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (entity) {
                                    return [
                                      entity.row.entity ==
                                      _vm.FloorPlanEntities.SOURCE
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLoggerName(
                                                  entity.row.entityId
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      entity.row.entity ==
                                      _vm.FloorPlanEntities.GATEWAY
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDeviceName(
                                                  entity.row.entityId
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2153946860
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        : [
            _c(
              "div",
              [
                _vm.editedFloorPlanData.length === 0 &&
                _vm.floorPlanImage == null &&
                _vm.imageFile == null
                  ? _c(
                      "image-uploader",
                      {
                        attrs: {
                          className: [
                            "fileinput",
                            { "fileinput--loaded": _vm.isUploading },
                          ],
                          debug: 0,
                          quality: 1,
                          preview: false,
                          autoRotate: true,
                          accept: ".jpg,.png,.jpeg",
                        },
                        on: { input: _vm.setImage },
                      },
                      [
                        _c(
                          "label",
                          {
                            attrs: { slot: "upload-label", for: "fileInput" },
                            slot: "upload-label",
                          },
                          [
                            _c(
                              "figure",
                              {
                                staticClass:
                                  "is-flex border dash is-justify-content-center py-5 px-2 is-clickable",
                                attrs: { expanded: "" },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("b-icon", {
                                      staticClass:
                                        "control-icon clickable p-0 is-block",
                                      staticStyle: { margin: "0px auto" },
                                      attrs: {
                                        type: "is-primary",
                                        icon: "upload",
                                        size: "is-medium",
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "line-height": "2rem" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isUploading
                                                ? _vm.$t(
                                                    "component.floorPlan.replace"
                                                  )
                                                : _vm.$t(
                                                    "component.floorPlan.upload"
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.imageFile && _vm.editedFloorPlanData.length == 0
                  ? _c(
                      "b-message",
                      {
                        staticClass: "mt-2 mb-4",
                        attrs: { type: "is-info", "has-icon": "" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("component.floorPlan.message")) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                (_vm.isUploading && _vm.editedFloorPlanData.length > 0) ||
                (_vm.isUpdating && _vm.editedFloorPlanData.length > 0)
                  ? _c(
                      "b-message",
                      {
                        staticClass: "mt-2 mb-4",
                        attrs: { type: "is-info", "has-icon": "" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("component.floorPlan.dragMessage")) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _vm.isUploading && _vm.imageFile != null
                      ? _c("b-button", {
                          staticClass: "canvas-close",
                          attrs: {
                            size: "is-small",
                            type: "is-primary",
                            "icon-right": "times",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resetData()
                            },
                          },
                        })
                      : _vm._e(),
                    _c("canvas", {
                      staticClass: "mt-3 mb-2",
                      attrs: { id: "canvas", height: "1px" },
                      on: {
                        click: function ($event) {
                          return _vm.draw($event)
                        },
                        mousedown: _vm.myDown,
                        mouseup: _vm.myUp,
                        mousemove: _vm.myMove,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isUploading || _vm.isUpdating
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.editedFloorPlanData, function (v, index) {
                      return _c(
                        "b-field",
                        {
                          key: index,
                          staticClass: "is-flex is-flex-wrap-wrap",
                          attrs: {
                            label: index + ".",
                            "label-position": "on-border",
                            type: {
                              "is-danger": _vm.errors.has(_vm.$t(index + ".")),
                            },
                            message: _vm.errors.first(_vm.$t(index + ".")),
                          },
                        },
                        [
                          _vm.editedFloorPlanData[index].entityId
                            ? _c(
                                "b-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "is-half-width",
                                  attrs: {
                                    expanded: "",
                                    placeholder: _vm.$t(
                                      "component.floorPlan.placeholder"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectEntity($event, index)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.editedFloorPlanData[index].entityId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editedFloorPlanData[index],
                                        "entityId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editedFloorPlanData[index].entityId",
                                  },
                                },
                                [
                                  _c(
                                    "optgroup",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "component.floorPlan.sources"
                                        ),
                                      },
                                    },
                                    _vm._l(_vm.sources, function (source) {
                                      return _c(
                                        "option",
                                        {
                                          key: source.id,
                                          domProps: { value: source.id },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(source.name) + " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _c(
                                    "optgroup",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "component.floorPlan.devices"
                                        ),
                                      },
                                    },
                                    _vm._l(_vm.devices, function (device) {
                                      return _c(
                                        "option",
                                        {
                                          key: device.deviceId,
                                          domProps: { value: device.deviceId },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(device.deviceName) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _c(
                                "b-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "is-half-width",
                                  attrs: {
                                    expanded: "",
                                    placeholder: _vm.$t(
                                      "component.floorPlan.placeholder"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectEntity($event, index)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.editedFloorPlanData[index].entityId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editedFloorPlanData[index],
                                        "entityId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editedFloorPlanData[index].entityId",
                                  },
                                },
                                [
                                  _c(
                                    "optgroup",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "component.floorPlan.sources"
                                        ),
                                      },
                                    },
                                    _vm._l(
                                      _vm.getAvailableSources(),
                                      function (source) {
                                        return _c(
                                          "option",
                                          {
                                            key: source.id,
                                            domProps: { value: source.id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(source.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "optgroup",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "component.floorPlan.devices"
                                        ),
                                      },
                                    },
                                    _vm._l(
                                      _vm.getAvailableDevices(),
                                      function (device) {
                                        return _c(
                                          "option",
                                          {
                                            key: device.deviceId,
                                            domProps: {
                                              value: device.deviceId,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(device.deviceName) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                          _c("b-button", {
                            staticClass: "ml-2",
                            attrs: {
                              disabled: _vm.editedFloorPlanData.length <= 1,
                              type: "is-primary",
                              "icon-right": "trash",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeLogger(index)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        (_vm.numberOfLoggers !== 0 && _vm.isUploading) ||
                        _vm.isUpdating
                          ? _c("b-button", {
                              attrs: {
                                label: _vm.$t("component.floorPlan.resetPlan"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetFloorPlan()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.numberOfLoggers !== 0 &&
                        _vm.isUploading &&
                        !_vm.isUpdating
                          ? _c("b-button", {
                              attrs: {
                                type: "is-primary",
                                label: _vm.$t("component.floorPlan.savePlan"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveFloorPlan()
                                },
                              },
                            })
                          : _vm._e(),
                        !_vm.isUploading && _vm.isUpdating
                          ? _c("b-button", {
                              attrs: {
                                type: "is-primary",
                                label: _vm.$t("component.floorPlan.updatePlan"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateFloorPlan()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            !_vm.isUploading &&
            !_vm.isUpdating &&
            _vm.editedFloorPlanData.length !== 0
              ? _c(
                  "div",
                  [
                    _c(
                      "b-table",
                      { attrs: { data: _vm.editedFloorPlanData } },
                      [
                        _c("b-table-column", {
                          attrs: {
                            label: _vm.$t("component.floorPlan.table.index"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (entity) {
                                  return [_vm._v(_vm._s(entity.index + "."))]
                                },
                              },
                            ],
                            null,
                            false,
                            1987620866
                          ),
                        }),
                        _c("b-table-column", {
                          attrs: {
                            label: _vm.$t("component.floorPlan.table.name"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (entity) {
                                  return [
                                    entity.row.entity ==
                                    _vm.FloorPlanEntities.SOURCE
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getLoggerName(
                                                entity.row.entityId
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    entity.row.entity ==
                                    _vm.FloorPlanEntities.GATEWAY
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDeviceName(
                                                entity.row.entityId
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2153946860
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
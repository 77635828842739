var NotificationSubscriptionType;
(function (NotificationSubscriptionType) {
    NotificationSubscriptionType["DEVICE_OFFLINE"] = "deviceOffline";
    NotificationSubscriptionType["DEVICE_ONLINE"] = "deviceOnline";
    NotificationSubscriptionType["LOGGER_OUT_OF_RANGE"] = "loggerOutOfRange";
    NotificationSubscriptionType["MEASURED_DATA_ALARM"] = "measuredDataAlarm";
    NotificationSubscriptionType["GROUP_MEASURED_DATA_ALARM"] = "groupMeasuredDataAlarm";
    NotificationSubscriptionType["RECORDS_REGENERATED"] = "recordsRegenerated";
    NotificationSubscriptionType["PDF_RECORDS_REGENERATED"] = "pdfReportGenerated";
    NotificationSubscriptionType["AUTOMATIC_REPORTS_GENERATED"] = "automaticPdfReportGenerated";
    NotificationSubscriptionType["LOW_BATTERY"] = "lowBattery";
    NotificationSubscriptionType["ADMIN_MESSAGE"] = "adminMessage";
    NotificationSubscriptionType["CUSTOMER_REGISTERED"] = "customerRegistered";
    NotificationSubscriptionType["FEEDBACK"] = "feedbackSent";
    NotificationSubscriptionType["ROLE_CHANGED"] = "roleChanged";
    NotificationSubscriptionType["SOURCE_OFFLINE"] = "sourceOffline";
    NotificationSubscriptionType["SOURCE_ONLINE"] = "sourceOnline";
    NotificationSubscriptionType["ADMIN_LOGGER_CALIBRATION_ENDING"] = "adminCompanyLoggerEndingCalibration";
    NotificationSubscriptionType["RECORDS_UPDATED"] = "recordsUpdated";
    NotificationSubscriptionType["CUSTOMER_ENDING_CALIBRATION"] = "customerCompanyLoggerEndingCalibration";
    NotificationSubscriptionType["ADMIN_LOGGER_STATE"] = "adminLoggerStateUpdated";
    NotificationSubscriptionType["LOGGER_STATE"] = "loggerStateUpdated";
})(NotificationSubscriptionType || (NotificationSubscriptionType = {}));
export default NotificationSubscriptionType;
export function typeHasDelayAndRepeat(type) {
    const allowedTypes = [
        NotificationSubscriptionType.MEASURED_DATA_ALARM,
        NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM,
        NotificationSubscriptionType.DEVICE_OFFLINE,
        NotificationSubscriptionType.SOURCE_OFFLINE,
        NotificationSubscriptionType.LOW_BATTERY
    ];
    return allowedTypes.includes(type);
}
export function typeHasDelay(type) {
    const allowedTypes = [
        NotificationSubscriptionType.MEASURED_DATA_ALARM,
        NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM,
        NotificationSubscriptionType.DEVICE_OFFLINE,
        NotificationSubscriptionType.SOURCE_OFFLINE,
        NotificationSubscriptionType.LOW_BATTERY
    ];
    return allowedTypes.includes(type);
}
export function typeHasRepeat(type) {
    const allowedTypes = [
        NotificationSubscriptionType.MEASURED_DATA_ALARM,
        NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM,
        NotificationSubscriptionType.DEVICE_OFFLINE,
        NotificationSubscriptionType.SOURCE_OFFLINE,
        NotificationSubscriptionType.LOW_BATTERY
    ];
    return allowedTypes.includes(type);
}
export function typeHasOnResolve(type) {
    const allowedTypes = [
        NotificationSubscriptionType.DEVICE_OFFLINE,
        NotificationSubscriptionType.SOURCE_OFFLINE,
        NotificationSubscriptionType.MEASURED_DATA_ALARM,
        NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM
    ];
    return allowedTypes.includes(type);
}
export function typeHasCooldown(type) {
    const allowedTypes = [];
    return allowedTypes.includes(type);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DatasetEntity {
    constructor(dataset, color) {
        this.data = dataset.data;
        this.metadata = dataset.metadata;
        this.serviceId = dataset.serviceId;
        this.serviceName = dataset.serviceName;
        this.name = dataset.name;
        this.style = dataset.style;
        this.color = color;
        this.isVisible = true;
        this.isSelectedLoggerFilter = true;
    }
}
exports.default = DatasetEntity;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let OtherNotificationSettings = class OtherNotificationSettings extends Vue {
    constructor() {
        super(...arguments);
        this.isDepartmentSettingsEditing = false;
        this.isUserSettingsEditing = false;
        this.offlineNotification = null;
    }
    get hasChangesDepartmentSettings() {
        return (this.offlineNotification != (this.departmentSettings.Source.DoNotNotifyWhenDeviceOffline == 'true' ? true : false));
    }
    created() {
        if (this.departmentSettings) {
            this.offlineNotification = this.departmentSettings.Source.DoNotNotifyWhenDeviceOffline == 'true' ? true : false;
        }
    }
    editDepartmentSettings() {
        if (this.isUserSettingsEditing) {
            this.showWarningMessageWhenEditModeIsOn();
            return;
        }
        this.isDepartmentSettingsEditing = !this.isDepartmentSettingsEditing;
    }
    editUserSettings() {
        if (this.isDepartmentSettingsEditing) {
            this.showWarningMessageWhenEditModeIsOn();
            return;
        }
        this.isUserSettingsEditing = !this.isUserSettingsEditing;
    }
    save() {
        this.$validator.validateAll().then((result) => {
            if (result) {
                if (this.offlineNotification !=
                    (this.departmentSettings.Source.DoNotNotifyWhenDeviceOffline == 'true' ? true : false))
                    this.$emit('updateOptimizeNotificationsSetting', this.offlineNotification);
                this.isDepartmentSettingsEditing = false;
            }
        });
    }
    showWarningMessageWhenEditModeIsOn() {
        this.$buefy.dialog.alert({
            title: this.$t('component.notification.other_settings.edit_warning.dialogTitle'),
            message: "<p class='has-text-primary has-text-centered has-text-weight-bold'>" +
                this.$t('component.notification.other_settings.edit_warning.message') +
                '</p>',
            confirmText: this.$t('component.notification.other_settings.edit_warning.confirm'),
            type: 'is-primary',
            hasIcon: true,
            canCancel: ['escape', 'outside']
        });
    }
};
__decorate([
    Prop({ type: Object })
], OtherNotificationSettings.prototype, "departmentSettings", void 0);
OtherNotificationSettings = __decorate([
    Component({
        components: {}
    })
], OtherNotificationSettings);
export default OtherNotificationSettings;

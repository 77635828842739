import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import { Department } from '@/entities/models/Department';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import LoggerStates from '@/entities/enums/LoggerStates';
import SortDirections from '@/entities/enums/SortDirections';
import debounce from 'lodash.debounce';
const DEBOUNCE_TIMER = 500;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminResellerLoggersComponent = class AdminResellerLoggersComponent extends Vue {
    constructor() {
        super(...arguments);
        this.CalibrationStatus = CalibrationStatus;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.Action = Action;
        this.LoggerStates = LoggerStates;
        this.selectedValue = '';
        this.isSerialNoSearch = true;
        this.isOnlineSearch = false;
        this.isStateSearch = false;
        this.isCalibrationSearch = false;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.loggerStates = [
            LoggerStates.AVAILABLE,
            LoggerStates.PUBLISHED,
            LoggerStates.ACTIVATED,
            LoggerStates.DEACTIVATED
        ];
        this.serialNumberSearch = '';
        this.onlineSearch = null;
        this.stateSearch = null;
        this.calibrationSearch = null;
        this.batterySearch = null;
        this.macAddressSearch = '';
        this.companyNameSearch = '';
        this.currentFilter = [];
        this.urlQueriesLoaded = false;
        this.tableColumns = {
            loggerId: {
                field: 'LoggerId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLoggerId),
                hasSearch: true
            },
            macAddress: {
                field: 'MacAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListMacAddress),
                hasSearch: true
            },
            serialNumber: {
                field: 'SerialNumber',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListSerialNumber),
                hasSearch: true
            },
            companyName: {
                field: 'CompanyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCompanyName),
                hasSearch: true
            },
            lastSeen: {
                field: 'LastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLastSeen),
                hasSearch: true
            },
            isOnline: {
                field: 'IsOnline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListIsOnline),
                hasSearch: true
            },
            state: {
                field: 'State',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListState),
                hasSearch: true
            },
            battery: {
                field: 'Battery',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListBattery),
                hasSearch: true
            },
            calibrated: {
                field: 'calibrated',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCalibrated),
                hasSearch: true
            }
        };
        this.handleFilterSerialNumberDebounce = debounce((e) => {
            this.filterChange(this.tableColumns.serialNumber.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterIsOnlineDebounce = debounce((e) => {
            this.filterChange(this.tableColumns.isOnline.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterStateDebounce = debounce((e) => {
            this.filterChange(this.tableColumns.state.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCalibratedDebounce = debounce((e) => {
            this.filterChange(this.tableColumns.calibrated.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    loadLoggers() {
        if (this.loggersLoaded) {
            if (!this.urlQueriesLoaded) {
                this.loadQueries();
            }
            this.resellerLoggers(this.pagination, this.sort);
        }
    }
    created() {
        this.selectedValue = this.tableColumns.serialNumber.field;
        this.loadQueries();
        if (this.loggersLoaded && this.loggers.length == 0) {
            this.resellerLoggers(this.pagination, this.sort);
        }
    }
    loadQueries() {
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.urlQueriesLoaded = true;
    }
    actionSelect(logger) {
        this.$emit('unassignLogger', logger);
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    downloadCalibration(id) {
        this.$emit('downloadCalibration', id);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminLogger);
            this.sortField = this.tableQueriesfromUrl.field;
            this.sortDirection = this.tableQueriesfromUrl.direction;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.pageSize;
            this.currentFilter = JSON.parse(this.tableQueriesfromUrl.filters);
            this.changeRouterUrl('adminLogger', JSON.stringify(this.tableQueriesfromUrl));
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminLoggerListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminLoggerListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminLoggerListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminLoggerListRowsPerPage.defaultValue;
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.serialNumberSearch =
                this.filterExists('SerialNumber@=') !== -1
                    ? this.currentFilter[this.filterExists('SerialNumber@=')].substring(14)
                    : '';
            this.macAddressSearch =
                this.filterExists('MacAddress@=') !== -1
                    ? this.currentFilter[this.filterExists('MacAddress@=')].substring(12)
                    : '';
            this.batterySearch =
                this.filterExists('Battery<=') !== -1
                    ? parseInt(this.currentFilter[this.filterExists('Battery<=')].substring(9))
                    : null;
            this.companyNameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilter[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.onlineSearch =
                this.filterExists('IsOnline==true') !== -1 ? true : this.filterExists('IsOnline==false') !== -1 ? false : null;
            this.stateSearch =
                this.filterExists('State==') !== -1 ? this.currentFilter[this.filterExists('State==')].substring(7) : null;
            if (this.filterExists('HasCalibration') !== -1 &&
                this.currentFilter[this.filterExists('HasCalibration==')].endsWith('true') &&
                this.filterExists('Expired') !== -1 &&
                this.currentFilter[this.filterExists('Expired==')].endsWith('false')) {
                this.calibrationSearch = CalibrationStatus.CALIBRATED.name;
            }
            else if (this.filterExists('HasCalibration') !== -1 &&
                this.currentFilter[this.filterExists('HasCalibration==')].endsWith('true') &&
                this.filterExists('Expired') !== -1 &&
                this.currentFilter[this.filterExists('Expired==')].endsWith('true')) {
                this.calibrationSearch = CalibrationStatus.EXPIRED.name;
            }
            else if (this.filterExists('HasCalibration') !== -1 &&
                this.currentFilter[this.filterExists('HasCalibration==')].endsWith('false')) {
                this.calibrationSearch = CalibrationStatus.NOT_CALIBRATED.name;
            }
        }
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    sorted(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterChange(searchField, value) {
        switch (searchField) {
            case this.tableColumns.serialNumber.field:
                this.replaceFilter('SerialNumber@=', value);
                break;
            case this.tableColumns.isOnline.field:
                this.replaceFilter('IsOnline==', value);
                break;
            case this.tableColumns.state.field:
                this.replaceFilter('State==', value);
                break;
            case this.tableColumns.calibrated.field:
                if (value == CalibrationStatus.CALIBRATED.name) {
                    this.replaceFilter('HasCalibration==', 'true');
                    this.replaceFilter('Expired==', 'false');
                }
                else if (value == CalibrationStatus.EXPIRED.name) {
                    this.replaceFilter('HasCalibration==', 'true');
                    this.replaceFilter('Expired==', 'true');
                }
                else if (value == CalibrationStatus.NOT_CALIBRATED.name) {
                    this.replaceFilter('HasCalibration==', 'false');
                    this.replaceFilter('Expired==', null);
                }
                break;
            default:
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (value === null) {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    onOptionsChange(pagination, sort) {
        this.tableQueries = {
            field: sort.field,
            direction: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            pageSize: pagination.pageSize,
            filters: JSON.stringify(this.currentFilter.filter((x) => x != null))
        };
        this.changeRouterUrl('adminLogger', JSON.stringify(this.tableQueries));
        // this.resellerLoggers(this.pagination, this.sort);
    }
    resellerLoggers(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.$emit('resellerLoggers', options, this.currentFilter);
    }
    changeRouterUrl(componentName, queries) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                [componentName]: queries
            })
        })
            .catch((err) => err);
    }
    clearFilter() {
        this.serialNumberSearch = '';
        this.onlineSearch = null;
        this.stateSearch = null;
        this.calibrationSearch = null;
        this.batterySearch = null;
        this.macAddressSearch = '';
        this.companyNameSearch = '';
        this.currentFilter = [];
        this.page = 1;
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isSerialNoSearch = false;
        this.isOnlineSearch = false;
        this.isStateSearch = false;
        this.isCalibrationSearch = false;
        switch (event) {
            case this.tableColumns.serialNumber.field:
                this.isSerialNoSearch = true;
                break;
            case this.tableColumns.isOnline.field:
                this.isOnlineSearch = true;
                break;
            case this.tableColumns.state.field:
                this.isStateSearch = true;
                break;
            case this.tableColumns.calibrated.field:
                this.isCalibrationSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.serialNumber.field;
        this.changeSearchField(this.tableColumns.serialNumber.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.loggerId.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.loggerId.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLoggerId, event);
                break;
            case this.tableColumns.macAddress.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.macAddress.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListMacAddress, event);
                break;
            case this.tableColumns.companyName.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCompanyName, event);
                break;
            case this.tableColumns.lastSeen.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.lastSeen.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListLastSeen, event);
                break;
            case this.tableColumns.isOnline.field:
                if (!event) {
                    this.isOnlineSearch = null;
                    this.changeDefaultField();
                    this.filterChange(this.tableColumns.isOnline.field, '');
                    if (this.sort.field == this.tableColumns.isOnline.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListIsOnline, event);
                break;
            case this.tableColumns.state.field:
                if (!event) {
                    this.stateSearch = null;
                    this.changeDefaultField();
                    this.filterChange(this.tableColumns.state.field, '');
                    if (this.sort.field == this.tableColumns.state.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListState, event);
                break;
            case this.tableColumns.battery.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListBattery, event);
                break;
            case this.tableColumns.calibrated.field:
                if (!event) {
                    this.calibrationSearch = null;
                    this.changeDefaultField();
                    this.filterChange(this.tableColumns.calibrated.field, '');
                    if (this.sort.field == this.tableColumns.calibrated.field)
                        this.sort.field = this.tableColumns.serialNumber.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerListCalibrated, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminResellerLoggersComponent.prototype, "loggers", void 0);
__decorate([
    Prop({ type: Object })
], AdminResellerLoggersComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminResellerLoggersComponent.prototype, "isLayoutList", void 0);
__decorate([
    Prop({ type: Number })
], AdminResellerLoggersComponent.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminResellerLoggersComponent.prototype, "loggersLoaded", void 0);
__decorate([
    Watch('loggersLoaded')
], AdminResellerLoggersComponent.prototype, "loadLoggers", null);
AdminResellerLoggersComponent = __decorate([
    Component({})
], AdminResellerLoggersComponent);
export default AdminResellerLoggersComponent;
var Action;
(function (Action) {
    Action["REMOVE"] = "remove";
})(Action || (Action = {}));

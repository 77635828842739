import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import BackgroundJobPreview from '@/views/components/admin/system/BackgroundJobPreview.vue';
import BackgroundJobDetailModal from '@/views/components/admin/system/BackgroundJobDetailModal.vue';
import UserPreferences from '@/services/UserPreferences';
let BackgroundJobsComponent = class BackgroundJobsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.selectedJob = null;
        this.isDetailModalActive = false;
        this.sortDirection = UserPreferences.LocalStorageKeys.BackgroundJobsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.BackgroundJobsSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.BackgroundJobsPage.defaultValue;
        this.total = 0;
    }
    created() { }
    showRawJob(data) {
        this.selectedJob = data;
        this.isDetailModalActive = true;
    }
    closeDetailModal() {
        this.isDetailModalActive = false;
    }
};
__decorate([
    Prop({ type: Array })
], BackgroundJobsComponent.prototype, "backgroundJobs", void 0);
__decorate([
    Prop({ type: Number, default: 40 })
], BackgroundJobsComponent.prototype, "pageSize", void 0);
__decorate([
    Prop({ type: Object })
], BackgroundJobsComponent.prototype, "dateTimeManager", void 0);
BackgroundJobsComponent = __decorate([
    Component({
        components: { BackgroundJobPreview, BackgroundJobDetailModal }
    })
], BackgroundJobsComponent);
export default BackgroundJobsComponent;

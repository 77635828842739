var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "generals-settings" }, [
    _c("div", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.settings.profile.units.title")) + " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "level-right" }, [
        _vm.requestPending
          ? _c(
              "span",
              {
                staticClass:
                  "level-item has-text-warning has-text-weight-semibold is-size-4",
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.saving_changes")) + " ")]
            )
          : _vm.requestFinished
          ? _c(
              "span",
              {
                staticClass:
                  "level-item has-text-success successfully-saved has-text-weight-semibold is-size-4",
                class: { "hide-opacity": _vm.requestFinished },
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.changes_saved")) + " ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "table",
      { staticClass: "section-table" },
      _vm._l(_vm.eventTypes, function (type) {
        return _c("tr", { key: type.eventTypeId }, [
          _c("th", [_vm._v(_vm._s(_vm.$t("services." + type.slug)))]),
          _c(
            "td",
            [
              _c(
                "b-field",
                _vm._l(_vm.units[type.type], function (option) {
                  return _c(
                    "b-radio-button",
                    {
                      key: option.identifier,
                      attrs: {
                        expanded: true,
                        "native-value": option.identifier,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.unitChanged(type, option.identifier)
                        },
                      },
                      model: {
                        value: _vm.selectedUnits[type.type],
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedUnits, type.type, $$v)
                        },
                        expression: "selectedUnits[type.type]",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "units." +
                                option.name.split(" ").join("_").toLowerCase()
                            )
                          ) +
                            " " +
                            _vm._s("(" + option.unit + ")")
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
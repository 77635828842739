var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BoundaryWizardTable", {
        staticClass: "box",
        attrs: {
          sources: _vm.sources,
          sourceGroups: _vm.sourceGroups,
          eventTypes: _vm.eventTypes,
        },
        on: { reload: _vm.reload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import NotificationRuleMode from '@/entities/enums/NotificationRuleMode';
import UserRepository from '@/services/repository/UserRepository';
var userSettingsRepository;
var userRepository;
let CreateRuleWarning = class CreateRuleWarning extends Vue {
    constructor() {
        super(...arguments);
        this.currenUser = null;
    }
    async created() {
        userRepository = new UserRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.currenUser = await userRepository.getCurrentUser();
        this.departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.currenUser.apiUserId, true);
        if (this.departmentUserSettings.notifications.rules.mode == NotificationRuleMode.ADVANCED)
            this.showDialog();
        else {
            this.$emit('warningClosed');
        }
    }
    showDialog() {
        let params = {
            lang: this.$route.params.lang,
            departmentId: this.$route.params.departmentId
        };
        if (this.sourceIds)
            params.source = this.sourceIds;
        if (this.sourceGroupId)
            params.sourceGroup = this.sourceGroupId;
        this.$buefy.dialog.confirm({
            title: this.$t('component.notification.create_rule.warning_component.title'),
            message: this.$t('component.notification.create_rule.warning_component.content'),
            cancelText: this.$t('component.notification.create_rule.warning_component.deny'),
            confirmText: this.$t('component.notification.create_rule.warning_component.agree'),
            type: 'is-success',
            onConfirm: () => this.$router
                .push({
                name: 'configuration_notifications',
                params: params
            })
                .catch((err) => err),
            onCancel: () => this.$emit('warningClosed')
        });
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], CreateRuleWarning.prototype, "sourceIds", void 0);
__decorate([
    Prop({ type: String })
], CreateRuleWarning.prototype, "sourceGroupId", void 0);
CreateRuleWarning = __decorate([
    Component({
        components: {}
    })
], CreateRuleWarning);
export default CreateRuleWarning;

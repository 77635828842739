import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
const SettingOptions = Object.freeze({
    DEVICES: {
        name: 'device',
        icon: 'microchip',
        descriptionKey: 'device',
        activeOnPages: ['configuration_devices', 'deviceConf'],
        permissionsPairs: [[Actions.READ, Subjects.DEVICE]]
    },
    LOGGERS: {
        name: 'logger',
        icon: 'thermometer-half',
        descriptionKey: 'logger',
        activeOnPages: ['logger_detail', 'configuration_loggers'],
        permissionsPairs: [[Actions.READ, Subjects.LOGGER]]
    },
    GROUPS: {
        name: 'group',
        icon: 'project-diagram',
        descriptionKey: 'group',
        activeOnPages: ['configuration_groups', 'create_group', 'group_detail'],
        permissionsPairs: [
            [Actions.READ, Subjects.SOURCE],
            [Actions.READ, Subjects.SOURCE_GROUP]
        ]
    },
    REPORTS: {
        name: 'report',
        icon: 'paste',
        descriptionKey: 'report',
        activeOnPages: ['configuration_reports'],
        permissionsPairs: [[Actions.READ, Subjects.REPORTS]]
    },
    NOTIFICATIONS: {
        name: 'notification',
        icon: 'bell',
        descriptionKey: 'notification',
        activeOnPages: ['configuration_notifications'],
        permissionsPairs: []
    },
    DASHBOARDS: {
        name: 'publicDashboards',
        icon: 'share-alt',
        descriptionKey: 'publicDashboards',
        activeOnPages: ['publicDashboards', 'publicDashboardDetail'],
        permissionsPairs: [[Actions.MANAGE, Subjects.DASHBOARD]]
    },
    ADMINISTRATION: {
        name: 'administration',
        icon: 'users-cog',
        descriptionKey: 'administration',
        activeOnPages: ['companySettings', 'userDetail'],
        permissionsPairs: [[Actions.LIST, Subjects.USERS]]
    },
    PROFILE: {
        name: 'profile',
        icon: 'address-card',
        descriptionKey: 'profile',
        activeOnPages: ['profile_settings'],
        permissionsPairs: []
    },
    WIZARD: {
        name: 'wizard',
        icon: 'hat-wizard',
        descriptionKey: 'wizard',
        activeOnPages: [],
        permissionsPairs: [
            [Actions.REGISTER, Subjects.DEVICE],
            [Actions.MANAGE, Subjects.DEVICE],
            [Actions.MANAGE, Subjects.LOGGER],
            [Actions.MANAGE, Subjects.SOURCE_GROUP],
            [Actions.MANAGE, Subjects.SOURCE],
            [Actions.CONFIGURE, Subjects.REPORTS],
            [Actions.CREATE, Subjects.MEASURED_DATA_VALIDATE]
        ]
    },
    ABOUT: {
        name: 'about',
        icon: 'info',
        descriptionKey: 'about',
        activeOnPages: [],
        permissionsPairs: []
    },
    FEEDBACK: {
        name: 'feedback',
        icon: 'comment-dots',
        descriptionKey: 'feedback',
        activeOnPages: [],
        permissionsPairs: []
    },
    LOG_OUT: {
        name: 'LogOut',
        icon: 'sign-out-alt',
        descriptionKey: 'LogOut',
        activeOnPages: [],
        permissionsPairs: []
    }
});
export default SettingOptions;

import { AbilityBuilder, Ability, createAliasResolver } from '@casl/ability';
import Actions, { AdminAction } from './Actions';
import Subjects from './Subjects';
export default class AbilitiesManager {
    constructor(rawAbilities) {
        this.abilities = this.processRawAbilities(rawAbilities);
    }
    getAbilities() {
        const resolveAction = createAliasResolver({
            [AdminAction]: Object.values(Actions).filter((x) => x !== AdminAction)
        });
        const { can, cannot, rules } = new AbilityBuilder(Ability);
        this.abilities.forEach((abilityPair) => {
            can(abilityPair.action, abilityPair.subject);
        });
        return new Ability(rules, { resolveAction: resolveAction });
    }
    processRawAbilities(rawAbilities) {
        let processed = new Array();
        rawAbilities.forEach((rawAbility) => {
            let lastIndexOfDot = rawAbility.lastIndexOf('.');
            let subject = rawAbility.slice(0, lastIndexOfDot);
            let action = rawAbility.slice(lastIndexOfDot + 1);
            let pair = {
                subject: Subjects[subject.toUpperCase()] || subject,
                action: Actions[action.toUpperCase()] || action
            };
            processed.push(pair);
        });
        return processed;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            _vm._s(_vm.$t("admin.component.devices.ota_update.title")) +
              " (" +
              _vm._s(_vm.device.deviceName) +
              ")"
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("admin.component.devices.ota_update.firmware"),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "select-location-table border res-table",
                  attrs: {
                    data: _vm.deviceFirmwareList,
                    paginated: true,
                    "per-page": 5,
                    narrowed: "",
                    hoverable: "",
                    selected: _vm.selectedFirmware,
                    "default-sort-direction": _vm.sortDirection,
                    "default-sort": _vm.sortField,
                  },
                  on: {
                    "update:selected": function ($event) {
                      _vm.selectedFirmware = $event
                    },
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "id",
                      label: _vm.$t(
                        "admin.component.devices.ota_update.table.id"
                      ),
                      searchable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "searchable",
                        fn: function (props) {
                          return [
                            _c("b-input", {
                              attrs: { size: "is-small" },
                              model: {
                                value: props.filters[props.column.field],
                                callback: function ($$v) {
                                  _vm.$set(
                                    props.filters,
                                    props.column.field,
                                    $$v
                                  )
                                },
                                expression: "props.filters[props.column.field]",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (deviceFirmwareList) {
                          return [
                            _c("p", { staticStyle: { "max-width": "150px" } }, [
                              _vm._v(_vm._s(deviceFirmwareList.row.id)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "version",
                      label: _vm.$t(
                        "admin.component.devices.ota_update.table.version"
                      ),
                      searchable: "",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "searchable",
                        fn: function (props) {
                          return [
                            _c("b-input", {
                              attrs: { size: "is-small" },
                              model: {
                                value: props.filters[props.column.field],
                                callback: function ($$v) {
                                  _vm.$set(
                                    props.filters,
                                    props.column.field,
                                    $$v
                                  )
                                },
                                expression: "props.filters[props.column.field]",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (deviceFirmwareList) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(deviceFirmwareList.row.version)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "createdBy",
                      label: _vm.$t(
                        "admin.component.devices.ota_update.table.createdBy"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (deviceFirmwareList) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  deviceFirmwareList.row.createdBy.fullName
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "createdAt",
                      label: _vm.$t(
                        "admin.component.devices.ota_update.table.createdAt"
                      ),
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (deviceFirmwareList) {
                          return [
                            _vm.dateTimeManager
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dateTimeManager.formatTime(
                                          new Date(
                                            deviceFirmwareList.row.createdAt *
                                              1000
                                          )
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("section", { staticClass: "section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "content has-text-grey has-text-centered",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.devices.ota_update.table.empty"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "component.notification.create_rule.entity_table.selected_count"
                  )
                ) +
                " : "
            ),
            _vm.selectedFirmware
              ? _c("span", [
                  _c("strong", [_vm._v(_vm._s(_vm.selectedFirmware.version))]),
                  _vm._v(" (" + _vm._s(_vm.selectedFirmware.id) + ") "),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.scheduleUpdate()
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("admin.component.devices.ota_update.confirm"))
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card logger-modal-header" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("h1", { staticClass: "title mt-3" }, [
        _vm._v(_vm._s(_vm.$t("component.logger.modal.title"))),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c("AssignSourceList", {
          ref: "assignSourcesComponent",
          attrs: { loggers: _vm.loggersToAssign, inWizard: _vm.inWizard },
          on: { sourcesCreated: _vm.resultRetrieved },
        }),
      ],
      1
    ),
    _c("footer", { staticClass: "modal-card-foot is-block" }, [
      _c("div", { staticClass: "level is-mobile" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                on: {
                  click: function ($event) {
                    return _vm.resultRetrieved()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.close")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                attrs: {
                  type: "is-primary",
                  disabled: _vm.loggersToAssign.length <= 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.$refs.assignSourcesComponent.createSources()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("component.logger.modal.add")))]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
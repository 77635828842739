import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DashboardInfo from '@/views/components/publicDashboard/DashboardInfo.vue';
import PublicDashboardOwnerRepository from '@/services/repository/PublicDashboardOwnerRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import VueUtilities from '@/services/VueUtilities';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import UserRepository from '@/services/repository/UserRepository';
import DateTimeManager from '@/services/DateTimeManager';
import LoggedUserManager from '@/services/LoggedUserManager';
import DeviceRepository from '@/services/repository/DeviceRepository';
var publicDashboardOwnerRepository;
var sourceGroupRepository;
var sourceRepository;
var userRepository;
var deviceRepository;
let PublicDashboardDetail = class PublicDashboardDetail extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.dashboard = null;
        this.isLoading = true;
        this.sourceGroups = [];
        this.sources = [];
        this.users = [];
        this.devices = [];
    }
    async created() {
        this.isLoading = true;
        publicDashboardOwnerRepository = new PublicDashboardOwnerRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        userRepository = new UserRepository(this);
        deviceRepository = new DeviceRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    loadData() {
        this.isLoading = true;
        let dashboardId = this.$route.params.dashboardId;
        let allPromises = [];
        allPromises.push(publicDashboardOwnerRepository.getPublicDashboardDetail(dashboardId));
        allPromises.push(sourceGroupRepository.getSourceGroups());
        allPromises.push(sourceRepository.getVisibleSources());
        allPromises.push(deviceRepository.getDevices());
        allPromises.push(userRepository.getUsers());
        Promise.all(allPromises)
            .then((response) => {
            this.dashboard = response[0];
            this.sourceGroups = response[1].getData();
            this.sources = response[2].getData();
            this.devices = response[3].getData();
            this.users = response[4];
        })
            .catch((err) => {
            this.$router.replace({
                name: 'pageNotExist',
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.$route.params.departmentId
                }
            });
        })
            .finally(() => {
            this.isLoading = false;
        });
    }
    confirmDashboardDelete(id) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.public_dashboard.delete.title'),
            message: this.$t('component.public_dashboard.delete.message'),
            confirmText: this.$t('component.public_dashboard.delete.confirm'),
            type: 'is-primary',
            hasIcon: true,
            onConfirm: async () => {
                let result = await publicDashboardOwnerRepository.deletePublicDashboard(this.$route.params.dashboardId);
                if (result === true) {
                    VueUtilities.openSuccessToast(this, this.$t('component.public_dashboard.delete.success'));
                    this.$router.replace({
                        name: 'publicDashboards',
                        params: {
                            lang: this.$route.params.lang,
                            departmentId: this.$route.params.departmentId
                        }
                    });
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.public_dashboard.delete.failure'));
                }
            }
        });
    }
};
PublicDashboardDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (LoggedUserManager.isAdmin(vm) ||
                    (vm.$ability.can(Actions.MANAGE, Subjects.DASHBOARD) && vm.$ability.can(Actions.LIST, Subjects.USERS))) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { DashboardInfo }
    })
], PublicDashboardDetail);
export default PublicDashboardDetail;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import CreateCalibrationModal from '@/views/components/admin/loggers/CreateCalibrationModal.vue';
import DecimalConversion from '@/services/DecimalConversion';
var companyLoggerRepository;
let CalibrationInfoComponent = class CalibrationInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.CalibrationStatus = CalibrationStatus;
        this.isModalActive = false;
        this.isLoading = false;
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
    }
    async deleteCalibration() {
        let deleteResult = await companyLoggerRepository.adminDeleteCalibration(this.companyLogger.companyLoggerId);
        if (deleteResult == true)
            this.reloadLogger();
    }
    openCalibrationModal() {
        this.isModalActive = true;
    }
    closeModal() {
        this.isModalActive = false;
    }
    async saveCalibration(calibration) {
        this.closeModal();
        let createResult = await companyLoggerRepository.adminCreateCalibration(this.companyLogger.companyLoggerId, calibration);
        if (createResult == true)
            this.reloadLogger();
    }
    reloadLogger() {
        this.$emit('reloadLogger');
    }
    openConfirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.loggers.calibration.delete.title'),
            message: this.$t('admin.component.loggers.calibration.delete.message', {
                loggerId: this.companyLogger.serialNumber,
                mac: this.companyLogger.macAddress
            }),
            confirmText: this.$t('admin.component.loggers.calibration.delete.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteCalibration()
        });
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
};
__decorate([
    Prop({ type: Object })
], CalibrationInfoComponent.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Object })
], CalibrationInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], CalibrationInfoComponent.prototype, "isLayoutList", void 0);
CalibrationInfoComponent = __decorate([
    Component({ components: { CreateCalibrationModal } })
], CalibrationInfoComponent);
export default CalibrationInfoComponent;

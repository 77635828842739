import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import VueUtilities from '@/services/VueUtilities';
import LoggedUserManager from '@/services/LoggedUserManager';
import AuthenticationCalls from '@/services/AuthenticationCalls';
var userRepository;
var authenticationCalls;
let Invitation = class Invitation extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.user = null;
    }
    async created() {
        this.isLoading = true;
        userRepository = new UserRepository(this);
        authenticationCalls = new AuthenticationCalls(this);
        if (LoggedUserManager.storageContainLoginCredentials(this.$cookies) != false)
            this.user = await userRepository.getCurrentUser();
        let queryData = this.$route.query;
        if (queryData.hasOwnProperty('token')) {
            this.rawToken = queryData.token;
            try {
                let parsedToken = JSON.parse(atob(this.rawToken.split('.')[1].replace('-', '+').replace('_', '/')));
                if (Date.now() >= parsedToken.exp * 1000) {
                    VueUtilities.openErrorToast(this, this.$t('component.register.tokenExp'));
                    this.$router.push({
                        name: 'organizations',
                        params: {
                            lang: this.$route.params.lang
                        }
                    });
                }
                else {
                    if (this.user !== null && parsedToken.inv === this.user.email) {
                        this.$router.push({
                            name: 'organizations',
                            query: {
                                deptId: parsedToken.deptId
                            },
                            params: {
                                lang: this.$route.params.lang
                            }
                        });
                    }
                    else {
                        if (this.user !== null) {
                            await authenticationCalls.logout();
                            LoggedUserManager.deleteLoginData(this);
                        }
                        let response = await authenticationCalls.checkToken(this.rawToken);
                        if (!response || response.AccountExists) {
                            this.$router
                                .push({
                                name: 'login',
                                params: {
                                    lang: this.$route.params.lang
                                }
                            })
                                .catch((err) => err);
                        }
                        else {
                            this.$router
                                .push({
                                name: 'register',
                                query: {
                                    token: this.rawToken
                                },
                                params: {
                                    lang: this.$route.params.lang
                                }
                            })
                                .catch((err) => err);
                        }
                    }
                }
            }
            catch (error) { }
        }
        else {
            this.$router.replace({
                name: 'organizations',
                params: {
                    lang: this.$route.params.lang
                }
            });
        }
    }
};
Invitation = __decorate([
    Component
], Invitation);
export default Invitation;

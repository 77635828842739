class FeedbackCategories {
    constructor() {
        this.CONTACT_FORM = { name: 'contact_form', type: 'is-info' };
        this.FEEDBACK = { name: 'feedback', type: 'is-primary' };
        this.USER_DELETE_REQUEST = { name: 'user_delete_request', type: 'is-warning' };
        this.UNKNOWN = { name: 'unknown', type: 'is-black' };
    }
    getFeedbackCategoryByName(name) {
        let category = Object.values(this).find((x) => x.name == name);
        if (!category)
            category = this.UNKNOWN;
        return category;
    }
}
export default new FeedbackCategories();

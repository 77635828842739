import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import { PdfReportTypes } from '@/entities/enums/ReportTypes';
import StatisticsType from '@/entities/enums/statisticsType';
import PeriodicReportPdfType from '@/entities/enums/PeriodicReportPdfType';
import ReportingInterval, { defaultReportingIntervals, getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
var departmentRepository;
let ReportSettingsModal = class ReportSettingsModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.PdfScope = PdfScope;
        this.RecordsTableTypes = RecordsTableTypes;
        this.PdfReportTypes = PdfReportTypes;
        this.selectedEntity = PdfScope.GROUP;
        this.selectedSourceGroups = [];
        this.selectedSources = [];
        this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        this.today = new Date();
        this.selectedMode = PdfReportTypes.FULL;
        this.selectedPeriodic = PeriodicReportPdfType.MONTHLY;
        this.monthFilter = null;
        this.interval = null;
        this.offset = 0;
        this.measurementOffsetIndex = 0;
        this.allMeasuredTimes = [];
        this.StatisticsType = StatisticsType;
        this.PeriodicReportPdfType = PeriodicReportPdfType;
        this.selectedType = StatisticsType.AVERAGE;
        this.selectedEventTypes = [];
        this.visibleEventTypes = [];
        this.hourFormatInClockPicker = null;
        this.timeOfMeasurement = new Date();
        this.currentShowEmpty = null;
    }
    get allReportingIntervals() {
        return defaultReportingIntervals;
    }
    get canHaveOffset() {
        return this.selectedType === StatisticsType.DAILY;
    }
    get hasOneDayIntervalSelected() {
        return this.interval === ReportingInterval.INTERVAL_24_00;
    }
    created() {
        this.loadData();
    }
    loadData() {
        this.selectedPeriodic = this.reportPdfConfiguration?.periodicReportPdfType || PeriodicReportPdfType.MONTHLY;
        this.showChart = this.reportPdfConfiguration?.showChart;
        this.showAnnotations = this.reportPdfConfiguration?.showAnnotationTable;
        this.currentShowEmpty = this.reportPdfConfiguration?.showEmpty;
        this.interval = this.reportPdfConfiguration?.interval || this.configuration.interval;
        if (typeof this.reportPdfConfiguration?.eventTypes === 'object') {
            this.selectedEventTypes = this.reportPdfConfiguration?.eventTypes || [];
        }
        else {
            this.selectedEventTypes = [];
        }
        if (this.reportPdfConfiguration?.showRecordsTable && this.reportPdfConfiguration?.alarmOnly)
            this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        else if (this.reportPdfConfiguration?.showRecordsTable && !this.reportPdfConfiguration?.alarmOnly)
            this.selectedRecordsTableType = RecordsTableTypes.ALL;
        else
            this.selectedRecordsTableType = RecordsTableTypes.NONE;
        if (this.reportPdfConfiguration?.showRecordsTable === null || this.reportPdfConfiguration?.alarmOnly === null)
            this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        this.selectedType = this.configuration.getModel().model;
        departmentRepository = new DepartmentRepository(this);
        this.hourFormatInClockPicker = this.dateTimeManager.getSelectedClockFormat().slice(0, 2);
        if (this.configuration.getModel().model == StatisticsType.DAILY) {
            this.offset = this.reportPdfConfiguration?.offset || this.configuration.getModel().modelParameters.Offset;
        }
        this.assignMeasureTimes(this.interval).then(() => {
            this.measurementOffsetIndex = this.offset / 60;
        });
        if (this.hasOneDayIntervalSelected == true) {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMinutes(this.reportPdfConfiguration?.offset || this.configuration.getModel().modelParameters.Offset);
            this.timeOfMeasurement = date;
        }
        this.visibleEventTypes = this.eventTypes.map((x) => x.name);
    }
    async configSettings() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let sourceIds = [];
                if (this.selectedEntity == PdfScope.LOGGER) {
                    if (this.selectedSources.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('reports.report_settings.report_config_modal.configuration.select_entity'));
                        return;
                    }
                    sourceIds = this.selectedSources.map((x) => x.id);
                }
                else {
                    if (this.selectedSourceGroups.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('reports.report_settings.report_config_modal.configuration.select_entity'));
                        return;
                    }
                    let groupSourcesIds = this.selectedSourceGroups.flatMap((x) => x.sources.map((y) => y.id));
                    sourceIds = sourceIds.concat(groupSourcesIds);
                }
                let res = null;
                let config = {
                    sourceIds: sourceIds,
                    apiUserId: this.currentUser.apiUserId,
                    periodicReportPdfType: this.selectedPeriodic,
                    showChart: this.showChart,
                    showAnnotationTable: this.showAnnotations,
                    showRecordsTable: this.selectedRecordsTableType == RecordsTableTypes.OUT_OF ||
                        this.selectedRecordsTableType == RecordsTableTypes.ALL,
                    alarmOnly: this.selectedRecordsTableType == RecordsTableTypes.OUT_OF,
                    statModels: [this.selectedType],
                    offset: this.offset,
                    interval: this.interval,
                    showEmpty: this.currentShowEmpty,
                    eventTypes: this.selectedEventTypes,
                    timezone: this.dateTimeManager.getSelectedTimezone()
                };
                this.isLoading = true;
                res = await departmentRepository.saveDepartmentPdfSetting([DepartmentSettingsKeys.REPORTS, DepartmentSettingsKeys.PDF, DepartmentSettingsKeys.CONFIGURATION], JSON.stringify(config));
                if (res == true) {
                    VueUtilities.openSuccessToast(this, this.$t('reports.report_settings.report_config_modal.configuration.success'));
                    this.resultRetrieved(true);
                    this.$emit('reload');
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('reports.report_settings.report_config_modal.configuration.failure'));
                }
                this.isLoading = false;
            }
        });
    }
    /**
     * Listener for reporting interval change
     */
    async reportingIntervalChanged(step) {
        if (this.hasOneDayIntervalSelected == true) {
            this.clockChanged(this.timeOfMeasurement);
        }
        else {
            this.measurementOffsetIndex = 0;
            this.measuredOffsetChanged();
            this.assignMeasureTimes(step);
        }
    }
    /**
     * Create array with all allowed measure times for selected interval
     */
    async assignMeasureTimes(step) {
        let allTimes = await getAllAvailableMeasureTimes(this, step);
        this.allMeasuredTimes = allTimes;
    }
    /**
     * change listener for offset
     */
    measuredOffsetChanged() {
        if (this.hasOneDayIntervalSelected == true)
            return;
        this.offset = this.measurementOffsetIndex * 60;
    }
    clockChanged(value) {
        if (this.hasOneDayIntervalSelected == false)
            return;
        this.offset = value.getHours() * 60 + value.getMinutes();
    }
    typeChanged() {
        this.measurementOffsetIndex = 0;
        this.measuredOffsetChanged();
        this.assignMeasureTimes(this.interval);
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    get hasTemp() {
        return this.visibleEventTypes.findIndex((element) => element.includes('Temperature'));
    }
    get hasHum() {
        return this.visibleEventTypes.findIndex((element) => element.includes('Humidity'));
    }
    entitySelected(selected) { }
};
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettingsModal.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettingsModal.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettingsModal.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "configuration", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "reportPdfConfiguration", void 0);
__decorate([
    Prop({ type: Boolean })
], ReportSettingsModal.prototype, "showEmpty", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "currentUser", void 0);
ReportSettingsModal = __decorate([
    Component({ components: {} })
], ReportSettingsModal);
export default ReportSettingsModal;
var PdfScope;
(function (PdfScope) {
    PdfScope["LOGGER"] = "logger";
    PdfScope["GROUP"] = "group";
})(PdfScope || (PdfScope = {}));
var RecordsTableTypes;
(function (RecordsTableTypes) {
    RecordsTableTypes["ALL"] = "all";
    RecordsTableTypes["OUT_OF"] = "only_out_of";
    RecordsTableTypes["NONE"] = "none";
})(RecordsTableTypes || (RecordsTableTypes = {}));

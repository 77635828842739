var Actions;
(function (Actions) {
    Actions["CREATE"] = "Create";
    Actions["READ"] = "Read";
    Actions["UPDATE"] = "Update";
    Actions["DELETE"] = "Delete";
    Actions["EDIT"] = "Edit";
    Actions["MANAGE"] = "Manage";
    Actions["LIST"] = "List";
    Actions["MANAGEOWN"] = "ManageOwn";
    Actions["VERIFY"] = "Verify";
    Actions["ENABLE"] = "Enable";
    Actions["DISABLE"] = "Disable";
    Actions["ACTIVATE"] = "Activate";
    Actions["DEACTIVATE"] = "Deactivate";
    Actions["REGISTER"] = "Register";
    Actions["MANAGESOURCES"] = "ManageSources";
    Actions["CONFIGURE"] = "Configure";
    Actions["ADMIN"] = "Admin";
    Actions["ANNOTATE"] = "Annotate";
    Actions["EXPORTPDF"] = "ExportPdf";
    Actions["READSUBS"] = "ReadSubscriptions";
    Actions["EDITSUBS"] = "EditSubscriptions";
    Actions["ADD"] = "Add";
    Actions["CHANGE_ROLE"] = "ChangeRole";
    Actions["REMOVE"] = "Remove";
    Actions["REVERT"] = "Revert";
    Actions["RETURN"] = "Return";
    Actions["PUBLISH"] = "Publish";
    Actions["SCHEDULE"] = "Schedule";
    Actions["UNPUBLISH"] = "Unpublish";
    Actions["PROTOCOL"] = "Protocol";
    Actions["READDATA"] = "ReadData";
    Actions["SEND"] = "Send";
    Actions["LOG"] = "Log";
    Actions["MAILINGLIST"] = "MailingList";
    Actions["REVIVE"] = "Revive";
    Actions["HISTORY"] = "History";
    Actions["CUSTOMIZE"] = "Customize";
})(Actions || (Actions = {}));
export default Actions;
export const AdminAction = 'Manage';

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import NotificationItem from '@/views/components/notification/NotificationItem.vue';
let NotificationCard = class NotificationCard extends Vue {
    constructor() {
        super(...arguments);
        this.name = 'NotificationCard';
        this.slicedNotifications = new Array();
    }
    async created() { }
    notificationRemoved(notifIds) {
        this.$emit('notificationRemoved', notifIds);
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => {
            return [];
        }
    })
], NotificationCard.prototype, "notifications", void 0);
__decorate([
    Prop({ type: Number })
], NotificationCard.prototype, "allNotificationCount", void 0);
__decorate([
    Prop({ type: Boolean })
], NotificationCard.prototype, "paused", void 0);
__decorate([
    Prop({ type: Boolean })
], NotificationCard.prototype, "buttonEnabled", void 0);
__decorate([
    Prop({ type: Object })
], NotificationCard.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], NotificationCard.prototype, "pausedTill", void 0);
NotificationCard = __decorate([
    Component({ components: { NotificationItem } })
], NotificationCard);
export default NotificationCard;

import User from '../User';
import { FirmwareAsset } from './FirmwareAsset';
export class AdminFirmware {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminFirmware.define(et));
        }
        else if (data != null) {
            return AdminFirmware.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let firmware = new AdminFirmware();
        firmware.id = data.Id;
        firmware.version = data.Version;
        firmware.description = data.Description;
        firmware.createdAt = data.CreatedAt;
        if (data.CreatedBy)
            firmware.createdBy = User.fromApi(data.CreatedBy);
        if (data.Data)
            firmware.data = data.Data;
        if (data.Assets)
            firmware.assets = FirmwareAsset.fromApi(data.Assets);
        return firmware;
    }
}

import User from '@/entities/User';
export default class IssueNotification {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => IssueNotification.define(et));
        }
        else if (data != null) {
            return IssueNotification.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let notification = new IssueNotification();
        notification.channel = data.Channel;
        notification.issueId = data.IssueId;
        if (data.ApiUser)
            notification.apiUser = User.fromApi(data.ApiUser);
        notification.notifyOnResolve = data.NotifyOnResolve;
        notification.createdAt = data.CreatedAt;
        return notification;
    }
}

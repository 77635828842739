import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminDeviceUpdates = class AdminDeviceUpdates extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.selectedValue = '';
        this.isIdSearch = true;
        this.isFwIdSearch = false;
        this.isVersionSearch = false;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceUpdatesSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceUpdatesSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminDeviceUpdatesPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminDeviceUpdatesRowsPerPage.defaultValue;
        this.total = 0;
        this.tableColumns = {
            id: {
                field: 'id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesId),
                hasSearch: true
            },
            firmwareId: {
                field: 'firmwareId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesFirmwareId),
                hasSearch: true
            },
            fwVersion: {
                field: 'fwVersion',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesFwVersion),
                hasSearch: true
            },
            description: {
                field: 'description',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesDescription),
                hasSearch: false
            },
            scheduleAt: {
                field: 'scheduleAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesScheduleAt),
                hasSearch: false
            },
            confirmedAt: {
                field: 'confirmedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesConfirmedAt),
                hasSearch: false
            },
            updatedAt: {
                field: 'updatedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesUpdatedAt),
                hasSearch: false
            },
            disabledAt: {
                field: 'disabledAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesDisabledAt),
                hasSearch: false
            }
        };
    }
    created() {
        this.selectedValue = this.tableColumns.id.field;
    }
    deviceUpdatesChanged() {
        this.total = this.deviceUpdates.length;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    clearFilter() {
        this.$refs.table.filters = {};
        this.page = 1;
    }
    changeSearchField(event) {
        this.isIdSearch = false;
        this.isFwIdSearch = false;
        this.isVersionSearch = false;
        switch (event) {
            case this.tableColumns.id.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.firmwareId.field:
                this.isFwIdSearch = true;
                break;
            case this.tableColumns.fwVersion.field:
                this.isVersionSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.id.field;
        this.changeSearchField(this.tableColumns.id.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.firmwareId.field:
                if (!event) {
                    this.$refs.table.filters['firmware.id'] = '';
                    this.changeDefaultField();
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesId, event);
                break;
            case this.tableColumns.fwVersion.field:
                if (!event) {
                    this.$refs.table.filters['firmware.version'] = '';
                    this.changeDefaultField();
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesFwVersion, event);
                break;
            case this.tableColumns.description.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesDescription, event);
                break;
            case this.tableColumns.scheduleAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesScheduleAt, event);
                break;
            case this.tableColumns.confirmedAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesConfirmedAt, event);
                break;
            case this.tableColumns.updatedAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesUpdatedAt, event);
                break;
            case this.tableColumns.disabledAt.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceUpdatesDisabledAt, event);
                break;
        }
    }
};
__decorate([
    Prop({ type: Array })
], AdminDeviceUpdates.prototype, "deviceUpdates", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceUpdates.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceUpdates.prototype, "isLayoutList", void 0);
__decorate([
    Watch('deviceUpdates')
], AdminDeviceUpdates.prototype, "deviceUpdatesChanged", null);
AdminDeviceUpdates = __decorate([
    Component({})
], AdminDeviceUpdates);
export default AdminDeviceUpdates;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
let AddDepartmentsModal = class AddDepartmentsModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDepartments = new Array();
        this.assignedDepartments = new Array();
    }
    get availableUserRoles() {
        return Object.values(ApiUserRoles);
    }
    async created() {
        this.$emit('loadDepartments');
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    selectedDepartment(checkedDepartments, checkedDep) {
        if (this.isAssignUserToDeps && checkedDep) {
            let depI = this.assignedDepartments.indexOf(this.assignedDepartments.find((x) => x.companyId === checkedDep.companyId));
            if (depI > -1) {
                this.assignedDepartments.splice(depI, 1);
            }
        }
        else if (this.isAssignUserToDeps && checkedDepartments.length === 0) {
            this.assignedDepartments = [];
        }
    }
    async addDepartments() {
        this.$validator.validateAll().then(async (result) => {
            if (this.selectedDepartments.length <= 0) {
                VueUtilities.openErrorToast(this, (this.$t('admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.select_department')));
            }
            else if (result) {
                if (this.isAssignUserToDeps) {
                    this.assignUserToDeps();
                }
                else {
                    let selectedDepartmentsId = [];
                    this.selectedDepartments.forEach((department) => {
                        selectedDepartmentsId.push(department.companyId);
                    });
                    this.$emit('addDepartments', selectedDepartmentsId);
                }
            }
        });
    }
    assignUserToDeps() {
        if (this.selectedDepartments.length > this.assignedDepartments.length) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.user.detail.assign_user_to_deps.role_not_assigned'));
            return;
        }
        let ownerRoleCount = this.assignedDepartments.filter((x) => x.userRole == ApiUserRoles.COMPANY_OWNER).length;
        if (ownerRoleCount >= 2) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.errorOwnerCount'));
            return;
        }
        if (ownerRoleCount > 0) {
            this.$buefy.dialog.confirm({
                title: this.$t('users.change_user_role.confirm_dialog_owner.title'),
                message: this.$t('users.change_user_role.confirm_dialog_owner.msg'),
                confirmText: this.$t('users.change_user_role.confirm_dialog_owner.confirm'),
                type: 'is-primary',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('addDepartments', this.assignedDepartments);
                }
            });
        }
        else {
            this.$emit('addDepartments', this.assignedDepartments);
        }
    }
    //Assign user role
    selectRole(selectedDep, role) {
        let dep = this.assignedDepartments.find((x) => selectedDep.companyId == x.companyId);
        if (dep != undefined) {
            //changing user role in dep
            dep.userRole = role;
        }
        else {
            this.assignedDepartments.push({
                companyId: selectedDep.companyId,
                companyName: selectedDep.companyName,
                userRole: role
            });
        }
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], AddDepartmentsModal.prototype, "availableDepartments", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AddDepartmentsModal.prototype, "departments", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AddDepartmentsModal.prototype, "isAssignUserToDeps", void 0);
AddDepartmentsModal = __decorate([
    Component({})
], AddDepartmentsModal);
export default AddDepartmentsModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let AdminResellerInfoComponent = class AdminResellerInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.resellerName = '';
    }
    created() {
        this.loadData();
    }
    loadData() {
        this.resellerName = this.reseller.name;
    }
    async updateReseller() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let data = {
                    Name: this.resellerName
                };
                this.$emit('updateReseller', data, this.reseller.id);
                this.toggleEditing();
            }
        });
    }
    toggleEditing() {
        this.$emit('editReseller');
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], AdminResellerInfoComponent.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Object })
], AdminResellerInfoComponent.prototype, "reseller", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AdminResellerInfoComponent.prototype, "isEditing", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminResellerInfoComponent.prototype, "isLayoutList", void 0);
AdminResellerInfoComponent = __decorate([
    Component({})
], AdminResellerInfoComponent);
export default AdminResellerInfoComponent;

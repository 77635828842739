var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("component.public_dashboard.refreshSettings.title")) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body has-text-centered" },
      [
        _c(
          "b-field",
          {
            attrs: { horizontal: "" },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("component.last_events.refresh")) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("b-switch", {
              staticClass: "is-flex",
              model: {
                value: _vm.isRefreshOn,
                callback: function ($$v) {
                  _vm.isRefreshOn = $$v
                },
                expression: "isRefreshOn",
              },
            }),
          ],
          1
        ),
        _vm.isRefreshOn
          ? _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t(
                        "component.settings.profile.user_settings.events_refresh_interval"
                      )
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t(
                      "component.settings.profile.user_settings.events_refresh_interval"
                    )
                  ),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.settings.profile.user_settings.events_refresh_interval"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1664995184
                ),
              },
              [
                _c("b-numberinput", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|numeric|min_value:1",
                      expression: "'required|numeric|min_value:1'",
                    },
                  ],
                  attrs: {
                    name: _vm.$t(
                      "component.settings.profile.user_settings.events_refresh_interval"
                    ),
                  },
                  model: {
                    value: _vm.refreshInt,
                    callback: function ($$v) {
                      _vm.refreshInt = $$v
                    },
                    expression: "refreshInt",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot is-flex is-justify-content-space-between",
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("generals.close")) + " ")]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.saveRefreshSettings()
              },
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "component.public_dashboard.refreshSettings.saveSettings"
                  )
                ) +
                " "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
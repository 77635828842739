//v2
export default class ApiResponse {
    constructor() {
        this._data = null;
        this._total = 0;
        this._pageSize = 0;
        this._currentPage = 0;
        this._links = null;
    }
    setPagination(response) {
        this._total = response.total;
        this._currentPage = response.currentPage;
        this._pageSize = response.pageSize;
        this._links = response._links;
    }
    setData(data) {
        this._data = data;
    }
    getData() {
        if (this._data == null)
            return [];
        else
            return this._data;
    }
    getPagination() {
        return {
            total: this._total,
            currentPage: this._currentPage,
            pageSize: this._pageSize,
            _links: this._links
        };
    }
}

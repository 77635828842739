import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
let AddResellerLoggersModal = class AddResellerLoggersModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedLoggers = new Array();
    }
    async created() {
        this.$emit('loadLoggers');
    }
    selectedLogger(logger) {
        if (!this.loggers.find((x) => x.currentlyPublishedAs.companyLoggerId === logger.currentlyPublishedAs.companyLoggerId))
            this.selectedLoggers.find((x) => x.currentlyPublishedAs.companyLoggerId === logger.currentlyPublishedAs.companyLoggerId)
                ? this.selectedLoggers.splice(this.selectedLoggers.indexOf(this.selectedLoggers.find((x) => x.currentlyPublishedAs.companyLoggerId === logger.currentlyPublishedAs.companyLoggerId)), 1)
                : this.selectedLoggers.push(logger);
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    async addResellerLoggers() {
        this.$validator.validateAll().then(async (result) => {
            if (this.selectedLoggers.length <= 0) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerLoggers.list.addLoggerModal.select_logger'));
            }
            else {
                let selectedLoggersId = [];
                this.selectedLoggers.forEach((logger) => {
                    selectedLoggersId.push(logger.currentlyPublishedAs.companyLoggerId);
                });
                this.$emit('addResellerLoggers', selectedLoggersId);
            }
        });
    }
};
__decorate([
    Prop({ type: Array })
], AddResellerLoggersModal.prototype, "availableLoggers", void 0);
__decorate([
    Prop({ type: Array })
], AddResellerLoggersModal.prototype, "loggers", void 0);
AddResellerLoggersModal = __decorate([
    Component({})
], AddResellerLoggersModal);
export default AddResellerLoggersModal;

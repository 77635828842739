var StatisticsType;
(function (StatisticsType) {
    StatisticsType["AVERAGE"] = "average";
    StatisticsType["DAILY"] = "daily";
})(StatisticsType || (StatisticsType = {}));
export default StatisticsType;
export function getStatisticsType(typeName) {
    if (!typeName)
        return null;
    return StatisticsType[typeName.toUpperCase()];
}
export function getStatisticsTypeValues() {
    return Object.values(StatisticsType);
}

export class AdminMember {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminMember.define(et));
        }
        else if (data != null) {
            return AdminMember.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let user = new AdminMember();
        user.apiUserId = data.ApiUserId;
        user.email = data.Email;
        user.fullName = data.FullName;
        user.username = data.Username;
        user.role = data.Role;
        user.enabled = data.Enabled;
        if (data.PhoneNumber) {
            user.phoneNumber = {
                Number: data.PhoneNumber.Number,
                Verified: data.PhoneNumber.Verified
            };
        }
        user.assignedAt = data.AssignedAt;
        return user;
    }
}

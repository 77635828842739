class DelayOptions {
    constructor() {
        this.values = [
            2 * 60,
            5 * 60,
            10 * 60,
            15 * 60,
            30 * 60,
            60 * 60,
            120 * 60,
            10800,
            240 * 60,
            360 * 60,
            720 * 60,
            1440 * 60
        ];
    }
    getMinuteValues() {
        return this.values.filter((x) => x < 60 * 60);
    }
    getHourValues() {
        return this.values.filter((x) => x >= 60 * 60);
    }
    getValues() {
        return this.values;
    }
}
export default new DelayOptions();

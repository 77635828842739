import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { PublicDashboard } from '@/entities/models/PublicDashboard';
import { ApiQueries } from '@/entities/ApiQueries';
import { Event } from '@/entities/models/Event';
import { ReportRecord } from '@/entities/models/ReportRecord';
import { Statistics } from '@/entities/Statistics';
import EventType from '@/entities/EventType';
export default class PublicDashboardRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getPublicDashboardData(dashboardId) {
        let url = `${ApiLinks.PublicDashboard.Home}/${dashboardId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return response;
        else
            return PublicDashboard.fromApi(response.data.data);
    }
    async getPublicDashboardEventTypes(dashboardId) {
        let url = `${ApiLinks.PublicDashboard.Home}/${dashboardId}${ApiLinks.PublicDashboard.Keys.EventTypes}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return response;
        else
            return EventType.fromApi(response.data.data);
    }
    async getPublicDashboardMeasurements(dashboardId) {
        let url = `${ApiLinks.PublicDashboard.Home}/${dashboardId}${ApiLinks.PublicDashboard.Keys.Sources}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return Event.fromApi(response.data.data);
    }
    async getPublicDashboardChart(dashboardId, sourceIds, from) {
        if (sourceIds.length <= 0)
            return {};
        let url = `${ApiLinks.PublicDashboard.Home}/${dashboardId}${ApiLinks.PublicDashboard.Keys.Chart}`;
        let queries = new ApiQueries();
        let sourcesParam = [];
        sourceIds.forEach((id) => {
            sourcesParam.push(`sourceIds[]=${id}`);
        });
        if (from)
            queries.from = from;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}&${sourcesParam.join('&')}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return response.data.data;
    }
    async getUptimeChartDataForSources(sourceIds, from, dashboardId, duration = 300) {
        if (sourceIds.length <= 0)
            return {};
        let url = `${ApiLinks.PublicDashboard.Home}/${dashboardId}${ApiLinks.PublicDashboard.Keys.Outage}`;
        let queries = new ApiQueries();
        if (sourceIds)
            queries.sourcesArray = sourceIds;
        if (from)
            queries.from = from;
        if (duration)
            queries.duration = duration;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return response.data.data;
    }
    async getPublicDashboardReports(dashboardId, sourceIds, annotations, from, to, model, interval, offset, showEmpty, timezone) {
        if (sourceIds.length <= 0)
            return { records: {}, statistics: {} };
        let url = `${ApiLinks.PublicDashboard.Home}/${dashboardId}${ApiLinks.PublicDashboard.Keys.Reports}`;
        let queries = new ApiQueries();
        let sourcesParam = [];
        sourceIds.forEach((id) => {
            sourcesParam.push(`sourceIds[]=${id}`);
        });
        if (from)
            queries.from = from;
        if (to)
            queries.to = to;
        if (annotations)
            queries.annotations = annotations;
        if (model)
            queries.model = model;
        if (interval)
            queries.interval = interval;
        if (offset)
            queries.offset = offset;
        if (!showEmpty)
            queries.showEmpty = showEmpty;
        queries.timezone = timezone;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}&${sourcesParam.join('&')}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        let rawReportRecords = {};
        let rawStatistics = {};
        Object.keys(response.data.data).forEach((key) => {
            let dataPerSource = response.data.data[key];
            rawReportRecords[key] = dataPerSource.data;
            rawStatistics[key] = dataPerSource.statistics;
        });
        let parsedRecords = ReportRecord.fromApi(rawReportRecords);
        let parsedStatistics = Statistics.fromApi(rawStatistics);
        return { records: parsedRecords || {}, statistics: parsedStatistics || {} };
    }
}

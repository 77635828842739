import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let DetailedInstructionsModal = class DetailedInstructionsModal extends Vue {
    closeModal() {
        this.$emit('modalClosed');
    }
};
DetailedInstructionsModal = __decorate([
    Component({})
], DetailedInstructionsModal);
export default DetailedInstructionsModal;

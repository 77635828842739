var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header mx-3 mx-0-desktop" }, [
        _c(
          "h1",
          { staticClass: "title is-3 is-spaced has-text-centered-touch my-1" },
          [_vm._v(" " + _vm._s(_vm.$t("admin.component.loggers.title")) + " ")]
        ),
        _c(
          "div",
          { staticClass: "buttons is-justify-content-center" },
          [
            _c(
              "router-link",
              {
                staticClass: "button is-primary level-item is-outlined",
                attrs: {
                  to: {
                    name: "adminRegisterNewLoggers",
                    params: {
                      lang: _vm.$route.params.lang,
                    },
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.loggers.registerNewLogger_btn")
                  ) + " "
                ),
              ]
            ),
            _vm.$ability.can(
              _vm.$permActions.PUBLISH,
              _vm.$permSubjects.ADMIN_LOGGERS
            )
              ? _c(
                  "b-button",
                  {
                    staticClass: "level-item",
                    attrs: { outlined: "", type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.showCvsModal()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("admin.component.loggers.csvButton")))]
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_LOGGERS
            )
              ? _c(
                  "b-button",
                  {
                    staticClass: "level-item",
                    attrs: { outlined: "", type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.showCreateModal()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("admin.component.loggers.createLogger_btn"))
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_LOGGERS)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggerList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    adminLoggers: _vm.adminLoggers,
                    dateTimeManager: _vm.dateTimeManager,
                    totalSize: _vm.adminLoggers.getPagination().total,
                  },
                  on: {
                    onOptionsChange: _vm.onOptionsChange,
                    loadLoggers: _vm.loadLoggers,
                    changeRouterUrlFromLogger: _vm.changeRouterUrlFromLogger,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_LOGGERS)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggersCalibrationExpires", {
                  staticClass: "box tile is-child",
                  attrs: {
                    adminLoggers: _vm.loggersCalibrationExpires,
                    totalSize:
                      _vm.loggersCalibrationExpires.getPagination().total,
                    dateTimeManager: _vm.dateTimeManager,
                    currentDateTime: _vm.currentDateTime,
                    calibrationExpire: _vm.selectedCalibrationExpire,
                  },
                  on: {
                    onOptionsChangeLoggersCalibrationExpires:
                      _vm.onOptionsChangeLoggersCalibrationExpires,
                    loadLoggersCalibrationExpires:
                      _vm.loadLoggersCalibrationExpires,
                    changeRouterUrlFromCalibrationExpires:
                      _vm.changeRouterUrlFromCalibrationExpires,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCsvModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCsvModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [_c("GenerateStickerModal", { on: { modalClosed: _vm.closeModal } })],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateLoggerModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateModalActive = $event
            },
            "close-modal": _vm.closeCreateLoggerModal,
          },
        },
        [
          _c("CreateLoggerModal", {
            on: { modalClosed: _vm.closeCreateLoggerModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserActions } from '@/entities/models/AdminUser';
import UserRepository from '@/services/repository/UserRepository';
var userRepository;
let AdminUserInfoComponent = class AdminUserInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.email = null;
        this.fullname = null;
    }
    onLoading() {
        this.assignDefaultValues();
    }
    mounted() {
        if (!this.componentIsLoading)
            this.assignDefaultValues();
    }
    get hasChanges() {
        return this.email !== this.user.email || this.fullname !== this.user.fullName;
    }
    created() {
        userRepository = new UserRepository(this);
    }
    assignDefaultValues() {
        this.email = this.user.email;
        this.fullname = this.user.fullName;
    }
    editUser() {
        this.isEditing = !this.isEditing;
        if (!this.isEditing)
            this.assignDefaultValues();
    }
    verifyUser(verification) {
        this.$emit('verifyUser', verification);
    }
    enableUser() {
        this.$emit('enableUser');
    }
    disableUser() {
        this.$emit('disableUser', this.$t('component.userAdministration.list.disable.title').toString(), this.$t('component.userAdministration.list.disable.body').toString(), this.$t('component.userAdministration.list.disable.confirm').toString(), UserActions.DISABLE);
    }
    deleteNumber() {
        this.$emit('deleteNumber');
    }
    saveChanges() {
        //vee-validate + process changes
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let updateResult = null;
                let updateDTO = {
                    Email: this.email,
                    FullName: this.fullname,
                    Username: this.email
                };
                //repository call
                this.isEditing = false;
                updateResult = await userRepository.adminSaveUserChanges(this.user.id, updateDTO);
                if (updateResult) {
                    this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
                    this.$emit('reloadUser');
                }
            }
        });
    }
};
__decorate([
    Prop({ type: Object })
], AdminUserInfoComponent.prototype, "user", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminUserInfoComponent.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserInfoComponent.prototype, "departments", void 0);
__decorate([
    Watch('componentIsLoading')
], AdminUserInfoComponent.prototype, "onLoading", null);
AdminUserInfoComponent = __decorate([
    Component({ components: {} })
], AdminUserInfoComponent);
export default AdminUserInfoComponent;

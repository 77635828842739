class FeedbackStatus {
    constructor() {
        this.OPENED = { name: 'opened', type: 'is-primary' };
        this.CLOSED = { name: 'closed', type: 'is-success' };
        this.UNKNOWN = { name: 'unknown', type: 'is-black' };
    }
    getFeedbackStatusByName(name) {
        let category = Object.values(this).find((x) => x.name == name);
        if (!category)
            category = this.UNKNOWN;
        return category;
    }
}
export default new FeedbackStatus();

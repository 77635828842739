import { FeaturesTypes } from './FeaturesTypes';
export class TierFeature {
    constructor() { }
    static sortFeaturesByName(arr) {
        return arr.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => TierFeature.define(et));
        }
        else if (data != null) {
            return TierFeature.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let tierFeature = new TierFeature();
        tierFeature.name = data.Name;
        tierFeature.type = data.Type;
        tierFeature.default = data.Default;
        return tierFeature;
    }
    static filterEnabledFeatures(features) {
        let enabledFeatures = [];
        features.forEach((feature) => {
            if (feature.Type === FeaturesTypes.BOOL) {
                if (feature.Value.Allowed === true) {
                    enabledFeatures.push(feature);
                }
            }
            else if (feature.Type === FeaturesTypes.QUOTA || feature.Type === FeaturesTypes.MONTHLY) {
                if (feature.Value.Quota === -1 || feature.Value.Quota > 0 || feature.Value.AllowOverflow === true) {
                    enabledFeatures.push(feature);
                }
            }
        });
        return enabledFeatures;
    }
    static filterEnabledFeaturesByValue(features) {
        let enabledFeatures = [];
        features.forEach((feature) => {
            if (feature.Value.Allowed === true ||
                feature.Value.Quota === -1 ||
                feature.Value.Quota > 0 ||
                feature.Value.AllowOverflow === true) {
                enabledFeatures.push(feature);
            }
        });
        return enabledFeatures;
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "multi-header mx-3 mx-0-desktop" }, [
        _c(
          "h1",
          { staticClass: "title is-3 is-spaced has-text-centered-touch my-1" },
          [_vm._v(" " + _vm._s(_vm.$t("admin.component.devices.title")) + " ")]
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_GATEWAYS
            )
              ? _c(
                  "b-button",
                  {
                    attrs: { outlined: "", type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.createNewDevice()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("admin.component.devices.create_device_btn")
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_GATEWAYS
            )
              ? _c(
                  "b-button",
                  {
                    attrs: { outlined: "", type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.importDevices()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("admin.component.devices.import_device_btn")
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_GATEWAYS
        )
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminDeviceList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    deviceList: _vm.deviceList.getData() || [],
                    totalSize: _vm.deviceList.getPagination().total,
                    dateTimeManager: _vm.dateTimeManager,
                    deviceFirmwareList: _vm.deviceFirmwareList.getData() || [],
                  },
                  on: {
                    onOptionsChange: _vm.onOptionsChange,
                    loadDevices: _vm.loadDevices,
                    loadFirmware: _vm.loadFirmware,
                    changeRouterUrlFromDevice: _vm.changeRouterUrlFromDevice,
                    reloadDevices: _vm.loadDevices,
                    openDeviceMonitor: function ($event) {
                      return _vm.openDeviceMonitor()
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.ADMIN_GATEWAYS_FIRMWARES
        )
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminDeviceFirmwareList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    deviceFirmwareList: _vm.deviceFirmwareList.getData() || [],
                    totalSize: _vm.deviceFirmwareList.getPagination().total,
                    pageSize: _vm.PAGE_SIZE,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: {
                    onOptionsChange: _vm.onOptionsChangeFirmware,
                    loadFirmware: _vm.loadFirmware,
                    changeRouterUrlFromFirmware:
                      _vm.changeRouterUrlFromFirmware,
                    openAdminFirmwareModal: _vm.openAdminFirmwareModal,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateDeviceModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateDeviceModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateDeviceModalActive = $event
            },
            "close-modal": _vm.closeCreateDeviceModal,
          },
        },
        [
          _c("CreateDeviceModal", {
            on: { modalClosed: _vm.closeCreateDeviceModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAdminFirmwareModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAdminFirmwareModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAdminFirmwareModalActive = $event
            },
            "close-modal": _vm.closeAdminFirmwareModal,
          },
        },
        [
          _c("AdminFirmwareModal", {
            on: { modalClosed: _vm.closeAdminFirmwareModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isActivateDeviceModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeActivateDeviceModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActivateDeviceModalActive = $event
            },
            "close-modal": _vm.closeActivateDeviceModal,
          },
        },
        [
          _c("ActivateDeviceModal", {
            on: { modalClosed: _vm.closeActivateDeviceModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isImportDeviceModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeImportDeviceModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isImportDeviceModalActive = $event
            },
            "close-modal": _vm.closeImportDeviceModal,
          },
        },
        [
          _c("ImportDevicesModal", {
            on: { modalClosed: _vm.closeImportDeviceModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isMonitorModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeMonitorModal,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.isMonitorModalActive = $event
            },
            "close-modal": _vm.closeMonitorModal,
          },
        },
        [
          _c("DeviceMonitor", {
            attrs: { dateTimeManager: _vm.dateTimeManager },
            on: { modalClosed: _vm.closeMonitorModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import { BoundaryDTO, Boundary } from '@/entities/models/Boundary';
import BoundaryInfoWithEdit from '@/views/components/boundary/BoundaryInfoWithEdit.vue';
import BoundaryRepository from '@/services/repository/BoundaryRepository';
import VueUtilities from '@/services/VueUtilities';
import CreateRuleWarning from '@/views/components/notification/CreateRuleWarning.vue';
import DecimalConversion from '@/services/DecimalConversion';
import AppConfig from '@/configLoader';
import EntitySelectionTable from '../generals/EntitySelectionTable.vue';
var sourceGroupRepository;
var boundaryRepository;
var sourceRepository;
let GroupInfo = class GroupInfo extends Vue {
    constructor() {
        super(...arguments);
        this.nameExists = false;
        this.groupName = null;
        this.boundaries = null;
        this.groupVisibility = null;
        this.originBoundaries = null;
        this.originalSources = null;
        this.unsavedSelectedSources = null;
        this.createRuleWarningVisible = false;
        this.unasignedSourcesIds = [];
        this.choosedEntityColumn = 'name';
        this.filters = {
            name: '',
            'sourceGroup.name': ''
        };
    }
    get tableData() {
        return this.group.sources.concat(this.sources.filter((x) => !this.group.sources.includes(x)));
    }
    get resetFormDefaultsEnabled() {
        return AppConfig.getConfig().features.reset_form_defaults;
    }
    get sourcesToRemove() {
        if (this.unsavedSelectedSources) {
            let selectedIds = this.unsavedSelectedSources.map((x) => x.id);
            return this.group.sources.filter((x) => !selectedIds.includes(x.id));
        }
        else
            return [];
    }
    get sourcesToAdd() {
        if (this.unsavedSelectedSources) {
            let assignedIds = this.group.sources.map((x) => x.id);
            return this.unsavedSelectedSources.filter((x) => !assignedIds.includes(x.id));
        }
        else
            return [];
    }
    get hasSourcesChanged() {
        if (this.unsavedSelectedSources) {
            let hasRemoved = this.sourcesToRemove.length > 0;
            let hasNew = this.sourcesToAdd.length > 0;
            return hasNew || hasRemoved;
        }
        else
            return false;
    }
    get hasUnsavedSourcesChanged() {
        if (this.unsavedSelectedSources) {
            return !(this.unsavedSelectedSources.length === this.originalSources.length &&
                this.unsavedSelectedSources.every((x) => this.originalSources.includes(x)));
        }
        else
            return false;
    }
    get hasBoundariesChanged() {
        return !BoundaryDTO.compareBoundaries(this.originBoundaries, this.boundaries);
    }
    get nameChanged() {
        return this.groupName != this.group.name;
    }
    get visibilityChanged() {
        return this.groupVisibility != this.group.visible;
    }
    get hasChanges() {
        return this.hasUnsavedSourcesChanged || this.nameChanged || this.hasBoundariesChanged || this.visibilityChanged;
    }
    get canHaveBoundaries() {
        return !((!this.hasSourcesChanged && this.group.sources.length <= 0) ||
            (this.hasSourcesChanged && this.unsavedSelectedSources.length <= 0));
    }
    get hasLoggers() {
        return this.group.sources.length > 0;
    }
    get emptyGroupName() {
        return this.groupName === '';
    }
    get emptyBoundaryField() {
        return this.boundaries.find((b) => (!b.lowerAlarmBoundary || !b.upperAlarmBoundary) && (b.lowerAlarmBoundary === '' || b.upperAlarmBoundary === ''));
    }
    created() {
        sourceGroupRepository = new SourceGroupRepository(this);
        boundaryRepository = new BoundaryRepository(this);
        sourceRepository = new SourceRepository(this);
        this.assignDefaultValues();
    }
    assignDefaultValues() {
        this.originalSources = this.group.sources || [];
        this.unsavedSelectedSources = this.group.sources || [];
        this.groupName = this.group.name;
        this.groupVisibility = this.group.visible;
        this.boundaries = Boundary.convertBoundariesToDTO(this.getBoundary());
        this.originBoundaries = Boundary.convertBoundariesToDTO(this.getBoundary());
    }
    getBoundary() {
        return this.group.sources.length > 0 ? this.group.sources[0].boundaries : new Array();
    }
    getCopyOfBoundaries() {
        if (!this.boundaries)
            return [];
        else
            return JSON.parse(JSON.stringify(this.boundaries));
    }
    boundariesChanged(boundaries) {
        this.boundaries = boundaries;
    }
    getLink(obj) {
        this.$router.push({
            name: 'logger_detail',
            params: {
                title: this.group.name,
                locationId: obj.id,
                departmentId: this.$route.params.departmentId,
                lang: this.$route.params.lang
            }
        });
    }
    resetDefaultsModal() {
        this.$emit('triggerUnsavedChangesModal');
    }
    reloadDefaults() {
        this.assignDefaultValues();
        this.$refs.entitySelectionTable.assignLocalSelected();
        this.$refs.boundariesComponent.assignBoundaries(this.originBoundaries);
    }
    onBoundaryValidation(result) {
        if (result == true) {
            this.saveChanges();
        }
    }
    validate() {
        if (this.$refs.boundariesComponent)
            this.$refs.boundariesComponent.validate();
        else {
            this.onBoundaryValidation(true);
        }
    }
    async saveChanges() {
        await this.checkIfNameExists(this.groupName);
        if (this.nameExists) {
            VueUtilities.openErrorToast(this, this.$t('component.group.create.msg.unique_name').toString());
        }
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.nameExists) {
                let updateResult = null;
                if (this.nameChanged || this.visibilityChanged) {
                    let groupDTO = {
                        Name: this.groupName,
                        Visible: this.groupVisibility
                    };
                    updateResult = await sourceGroupRepository.updateGroup(this.group.id, groupDTO);
                }
                if (this.group.sources.length > 0) {
                    updateResult = await this.processUpdateWithSources();
                }
                else {
                    updateResult = await this.processUpdateWithoutSources();
                }
                if (updateResult) {
                    if (this.$route.query.isBoundaryEdit) {
                        this.$router
                            .replace({
                            query: Object.assign({}, this.$route.query, {
                                isBoundaryEdit: false
                            })
                        })
                            .catch((err) => err);
                    }
                    VueUtilities.openSuccessToast(this, this.$t('component.group.detail.msg.edit_success').toString());
                    if (this.hasSourcesChanged && this.sourcesToRemove.length > 0) {
                        this.unasignedSourcesIds = this.sourcesToRemove.map((x) => x.id);
                        this.createRuleWarningVisible = true;
                    }
                    else {
                        this.$emit('reloadGroup');
                    }
                }
            }
        });
    }
    warningClosed() {
        this.$emit('reloadGroup');
    }
    async processUpdateWithSources() {
        let result = true;
        if (this.hasBoundariesChanged) {
            let allBoundaryPromisses = [];
            this.group.sources.forEach((source) => {
                let boundaries = this.getCopyOfBoundaries();
                boundaries.forEach((boundary) => {
                    boundary.lowerAlarmBoundary = DecimalConversion.toFloat(boundary.lowerAlarmBoundary);
                    boundary.lowerWarningBoundary = DecimalConversion.toFloat(boundary.lowerWarningBoundary);
                    boundary.upperWarningBoundary = DecimalConversion.toFloat(boundary.upperWarningBoundary);
                    boundary.upperAlarmBoundary = DecimalConversion.toFloat(boundary.upperAlarmBoundary);
                });
                allBoundaryPromisses.push(this.assignBoundaries(boundaries, source.id));
            });
            result = await Promise.all(allBoundaryPromisses)
                .then((response) => {
                return true;
            })
                .catch((error) => {
                VueUtilities.openErrorToast(this, `${this.$t('component.group.detail.msg.boundaries_failure').toString()} ${error.response.data.message}`);
                return false;
            });
        }
        if (this.hasSourcesChanged) {
            let allSourcesAssignPromisses = [];
            this.sourcesToAdd.forEach((source) => {
                allSourcesAssignPromisses.push(sourceRepository.assignToGroup(source.id, this.group.id));
            });
            this.sourcesToRemove.forEach((source) => {
                allSourcesAssignPromisses.push(sourceRepository.unassignFromGroup(source.id, source.sourceGroup.id));
            });
            result = await Promise.all(allSourcesAssignPromisses)
                .then((response) => {
                return true;
            })
                .catch((error) => {
                VueUtilities.openErrorToast(this, `${this.$t('component.group.detail.msg.source_assign_failure').toString()} ${error.response.data.message}`);
                return false;
            });
        }
        return result;
    }
    async processUpdateWithoutSources() {
        let result = true;
        if (this.sourcesToAdd.length > 0)
            await sourceRepository.assignToGroup(this.sourcesToAdd[0].id, this.group.id);
        if (this.hasBoundariesChanged) {
            let boundaries = this.getCopyOfBoundaries();
            boundaries.forEach((boundary) => {
                boundary.lowerAlarmBoundary = DecimalConversion.toFloat(boundary.lowerAlarmBoundary);
                boundary.lowerWarningBoundary = DecimalConversion.toFloat(boundary.lowerWarningBoundary);
                boundary.upperWarningBoundary = DecimalConversion.toFloat(boundary.upperWarningBoundary);
                boundary.upperAlarmBoundary = DecimalConversion.toFloat(boundary.upperAlarmBoundary);
            });
            await this.assignBoundaries(boundaries, this.sourcesToAdd[0].id);
        }
        if (this.sourcesToAdd.length > 1) {
            let allSourcesAssignPromisses = [];
            for (let i = 1; i < this.sourcesToAdd.length; i++) {
                allSourcesAssignPromisses.push(sourceRepository.assignToGroup(this.sourcesToAdd[i].id, this.group.id));
            }
            result = await Promise.all(allSourcesAssignPromisses)
                .then((response) => {
                return true;
            })
                .catch((error) => {
                VueUtilities.openErrorToast(this, `${this.$t('component.group.detail.msg.source_assign_failure').toString()} ${error.response.data.message}`);
                return false;
            });
        }
        return result;
    }
    async assignBoundaries(boundaries, sourceId) {
        return boundaryRepository.createBoundary(boundaries, sourceId);
    }
    saveBoundariesCommand(boundaries) {
        this.boundaries = boundaries;
    }
    sourceSelected(unsavedSelected) {
        this.unsavedSelectedSources = unsavedSelected;
    }
    async checkIfNameExists(name) {
        if (!name || name == this.group.name) {
            this.nameExists = false;
        }
        else {
            let checkResponse = await sourceGroupRepository.checkIfNameExists(name);
            this.nameExists = checkResponse.Exist;
        }
    }
};
__decorate([
    Prop({ type: Object })
], GroupInfo.prototype, "group", void 0);
__decorate([
    Prop({ type: Array })
], GroupInfo.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Array })
], GroupInfo.prototype, "sources", void 0);
__decorate([
    Prop({ type: Boolean })
], GroupInfo.prototype, "hasEditPermissions", void 0);
GroupInfo = __decorate([
    Component({
        components: {
            CreateRuleWarning,
            BoundaryInfoWithEdit,
            EntitySelectionTable
        }
    })
], GroupInfo);
export default GroupInfo;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import IssueInfo from '@/views/components/issues/IssueInfo.vue';
import DateTimeManager from '@/services/DateTimeManager';
import UserPreferences from '@/services/UserPreferences';
import IssueRepository from '@/services/repository/IssueRepository';
import { vxm } from '@/store/store.vuex';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import DeviceRepository from '@/services/repository/DeviceRepository';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import IssueNotifications from './IssueNotifications.vue';
import NotificationChannelType from '@/entities/notifications/NotificationChannelType';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
var deviceRepository;
var issueRepository;
var vxDepartmentStore = vxm.departmentStore;
var departmentRepository;
var companyLoggerRepository;
var sourceRepository;
var sourceGroupRepository;
let IssueDetail = class IssueDetail extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.issueId = this.$route.params.issueId;
        this.issue = null;
        this.isLoading = false;
        this.activeTab = UserPreferences.LocalStorageKeys.IssueDetailLayoutActiveTab.defaultValue;
        this.departmentIssues = [];
        this.companyId = this.$route.params.companyId;
        this.isOnAdminPage = this.$route.params.companyId ? true : false;
        this.company = null;
        this.device = null;
        this.companyLogger = null;
        this.source = null;
        this.sourceGroup = null;
        this.issueNotifications = [];
        this.issueNotificationsQueries = 'issueNotifications';
        this.SELECTED_DEPARTMENT_ID = null;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        issueRepository = new IssueRepository(this);
        departmentRepository = new DepartmentRepository(this);
        deviceRepository = new DeviceRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        sourceRepository = new SourceRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        if (!this.isOnAdminPage) {
            this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        }
        this.loadData();
    }
    async loadData() {
        this.activeTab = parseInt(this.$route.query.activeTab)
            ? parseInt(this.$route.query.activeTab)
            : UserPreferences.LocalStorageKeys.IssueDetailLayoutActiveTab.defaultValue;
        this.changedActiveTab(this.activeTab);
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(issueRepository.getDepartmentIssue(this.issueId, this.companyId));
        allPromises.push(issueRepository.getDepartmentIssueNotifications(this.issueId, this.companyId));
        Promise.all(allPromises).then((response) => {
            this.processLoadedData(response[0], response[1]);
        });
    }
    async processLoadedData(departmentIssue, issueNotifications) {
        this.issue = departmentIssue;
        this.issueNotifications = issueNotifications
            .getData()
            .filter((notification) => notification.channel != NotificationChannelType.SYSTEM);
        if (this.isOnAdminPage) {
            this.company = await departmentRepository.getDepartmentAdmin(this.companyId);
            if (this.issue.metadata.deviceInstanceId && this.issue.type == NotificationSubscriptionType.DEVICE_OFFLINE) {
                this.device = await deviceRepository.getDevice(this.issue.metadata.deviceInstanceId, this.companyId);
            }
            if (this.issue.metadata.companyLoggerId && this.issue.type == NotificationSubscriptionType.LOW_BATTERY) {
                this.companyLogger = await companyLoggerRepository.adminGetCompanyLogger(this.issue.metadata.companyLoggerId);
            }
            if ((this.issue.metadata.sourceId && NotificationSubscriptionType.SOURCE_OFFLINE) ||
                (this.issue.metadata.sourceId && NotificationSubscriptionType.MEASURED_DATA_ALARM)) {
                await sourceRepository
                    .getSource(this.issue.metadata.sourceId, this.companyId, false)
                    .then(async (source) => {
                    this.companyLogger = await companyLoggerRepository.adminGetCompanyLogger(source?.logger?.companyLoggerId);
                });
            }
        }
        else {
            if (this.issue.metadata.sourceGroupId && NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
                this.sourceGroup = await sourceGroupRepository.getSourceGroup(this.issue.metadata.sourceGroupId, this.companyId, false);
            }
            if (this.issue.metadata.deviceInstanceId && NotificationSubscriptionType.DEVICE_OFFLINE) {
                this.device = await deviceRepository.getDevice(this.issue.metadata.deviceInstanceId, this.companyId, false);
            }
            if (this.issue.metadata.sourceId && NotificationSubscriptionType.SOURCE_OFFLINE) {
                this.source = await sourceRepository.getSource(this.issue.metadata.sourceId, this.companyId, false);
            }
            if (this.issue.metadata.companyLoggerId && NotificationSubscriptionType.LOW_BATTERY) {
                let filters = ['LoggerId==' + this.issue.metadata.companyLoggerId];
                let filteredSources = (await sourceRepository.getVisibleSources(null, null, undefined, filters)).getData();
                if (filteredSources.length > 0) {
                    this.source = filteredSources[0];
                }
                else
                    this.source = null;
            }
        }
        this.isLoading = false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    openNotificationTab() {
        this.activeTab = 1;
    }
    changeRouterUrl(objString) {
        this.$router
            .push({
            query: Object.assign({}, this.$route.query, {
                [this.issueNotificationsQueries]: objString
            })
        })
            .catch((err) => err);
    }
    async changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceDetailLayoutActiveTab, event);
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
};
IssueDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ISSUES)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (!this.isOnAdminPage) {
                if (this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartmentId()) {
                    this.loadData();
                }
            }
            next(); // to resolve hook
        },
        components: { IssueInfo, IssueNotifications }
    })
], IssueDetail);
export default IssueDetail;

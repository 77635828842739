import { __decorate } from "tslib";
import { AdminCompanySettingPermissions } from '@/entities/AdminDepartmentSettings';
import VueUtilities from '@/services/VueUtilities';
import { Component, Vue, Prop } from 'vue-property-decorator';
let AdminDepartmentSettingsList = class AdminDepartmentSettingsList extends Vue {
    constructor() {
        super(...arguments);
        this.AdminCompanySettingPermissions = AdminCompanySettingPermissions;
        this.fieldEditing = null;
        this.selectedFieldValue = null;
        this.selectedFieldLabel = null;
        this.selectedPermissionsValue = null;
    }
    created() { }
    editSettings(key) {
        this.fieldEditing = key;
        this.selectedFieldLabel = key;
        let setting = this.companySettings.getSettings().find((x) => x.Key == key);
        this.selectedFieldValue = setting.Value;
        this.selectedPermissionsValue = setting.Permission;
    }
    cancelEdit() {
        this.fieldEditing = null;
        this.selectedFieldLabel = null;
        this.selectedFieldValue = null;
        this.selectedPermissionsValue = null;
    }
    cancelAdding() {
        this.fieldEditing = null;
        this.selectedFieldLabel = null;
        this.selectedFieldValue = null;
        this.selectedPermissionsValue = null;
        this.$emit('changeAddSettingState', false);
    }
    saveEdit(key) {
        let dto = {
            Key: key,
            Value: this.selectedFieldValue,
            Permission: this.selectedPermissionsValue
        };
        this.$emit('saveSetting', dto);
    }
    addNew() {
        this.$emit('changeAddSettingState', true);
        this.cancelEdit();
    }
    confirmSettingDelete(key) {
        this.$buefy.dialog.confirm({
            title: this.$t('company_settings.delete_msg'),
            message: this.$t('company_settings.delete_msg', { key: key }),
            cancelText: this.$t('generals.close'),
            confirmText: this.$t('company_settings.delete_confirm'),
            type: 'is-danger',
            onConfirm: () => this.deleteSetting(key)
        });
    }
    deleteSetting(key) {
        this.$emit('deleteSetting', key);
    }
    saveNewSetting() {
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.checkIfKeyExists(this.selectedFieldLabel)) {
                let dto = {
                    Key: this.selectedFieldLabel,
                    Value: this.selectedFieldValue,
                    Permission: this.selectedPermissionsValue
                };
                this.$emit('saveSetting', dto);
            }
            else if (this.checkIfKeyExists(this.selectedFieldLabel)) {
                VueUtilities.openErrorToast(this, this.$t('system_settings.already_exists'));
            }
        });
    }
    checkIfKeyExists(key) {
        if (this.companySettings.getSettings().find((x) => x.Key === key) != null)
            return true;
        else
            return false;
    }
};
__decorate([
    Prop({ type: Object })
], AdminDepartmentSettingsList.prototype, "companySettings", void 0);
__decorate([
    Prop({ type: Object })
], AdminDepartmentSettingsList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminDepartmentSettingsList.prototype, "isAdding", void 0);
AdminDepartmentSettingsList = __decorate([
    Component({
        components: {}
    })
], AdminDepartmentSettingsList);
export default AdminDepartmentSettingsList;

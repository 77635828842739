import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CompanyTiersHistory = class CompanyTiersHistory extends Vue {
    activateTier(companyTier) {
        this.$emit('deactivateTier', companyTier);
    }
    deleteTier(companyTier) {
        this.$emit('deleteTier', companyTier);
    }
};
__decorate([
    Prop({ type: Array })
], CompanyTiersHistory.prototype, "tiersHistory", void 0);
__decorate([
    Prop({ type: Object })
], CompanyTiersHistory.prototype, "dateTimeManager", void 0);
CompanyTiersHistory = __decorate([
    Component({ components: {} })
], CompanyTiersHistory);
export default CompanyTiersHistory;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm.useDefaultPreset
          ? _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "component.notification.presets.create_preset_btn_label"
                  )
                )
              ),
            ])
          : _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("component.notification.presets.edit_preset_btn_label")
                )
              ),
            ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _vm.isLoading
          ? _c("b-loading", {
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            })
          : _c(
              "div",
              { staticClass: "preset" },
              [
                _c(
                  "b-field",
                  {
                    staticClass: "required mb-1-mobile",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.notification.presets.table.name"
                      ),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t("component.notification.presets.table.name")
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("component.notification.presets.table.name")
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        placeholder: _vm.$t(
                          "component.notification.presets.table.name"
                        ),
                        type: "text",
                        maxlength: "50",
                        name: _vm.$t(
                          "component.notification.presets.table.name"
                        ),
                      },
                      model: {
                        value: _vm.presetDto.Name,
                        callback: function ($$v) {
                          _vm.$set(_vm.presetDto, "Name", $$v)
                        },
                        expression: "presetDto.Name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { grouped: "" } },
                  [
                    _c(
                      "b-field",
                      {
                        staticClass: "mr-6",
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.notification.presets.modal.preset_type"
                          ),
                        },
                      },
                      [
                        _c(
                          "b-select",
                          {
                            attrs: { disabled: !_vm.useDefaultPreset },
                            model: {
                              value: _vm.presetDto.Type,
                              callback: function ($$v) {
                                _vm.$set(_vm.presetDto, "Type", $$v)
                              },
                              expression: "presetDto.Type",
                            },
                          },
                          _vm._l(_vm.preset_options, function (type) {
                            return _c(
                              "option",
                              { key: type, domProps: { value: type } },
                              [_vm._v(" " + _vm._s(type) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.notification.presets.modal.preset_enabled"
                          ),
                        },
                      },
                      [
                        _c("b-switch", {
                          attrs: { type: "is-primary" },
                          model: {
                            value: _vm.presetDto.Enabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.presetDto, "Enabled", $$v)
                            },
                            expression: "presetDto.Enabled",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pt-5" },
                  [
                    _c("MeasuredRuleTemplate", {
                      attrs: { types: _vm.types, rule: _vm.singleMeasuredRule },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("hr"),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                        ),
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("hr"),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                        ),
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("hr"),
                    _c("h2", { staticClass: "title has-text-dark" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "notifications.configuration.other_rules_title"
                          )
                        )
                      ),
                    ]),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType.LOW_BATTERY
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType.LOW_BATTERY
                        ),
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType.RECORDS_UPDATED
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType.RECORDS_UPDATED
                        ),
                        advancedAllowed: false,
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType
                            .CUSTOMER_ENDING_CALIBRATION
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType
                            .CUSTOMER_ENDING_CALIBRATION
                        ),
                        advancedAllowed: false,
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType
                            .PDF_RECORDS_REGENERATED
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType
                            .PDF_RECORDS_REGENERATED
                        ),
                        advancedAllowed: false,
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType
                            .AUTOMATIC_REPORTS_GENERATED
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType
                            .AUTOMATIC_REPORTS_GENERATED
                        ),
                        advancedAllowed: false,
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                    _c("GeneralRuleTemplate", {
                      attrs: {
                        type: _vm.getType(
                          _vm.NotificationSubscriptionType.LOGGER_STATE
                        ),
                        rule: _vm.getRule(
                          _vm.NotificationSubscriptionType.LOGGER_STATE
                        ),
                        advancedAllowed: false,
                      },
                      on: { ruleChanged: _vm.onRuleChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
      ],
      1
    ),
    _c("footer", { staticClass: "modal-card-foot is-block" }, [
      _c("div", { staticClass: "level is-mobile" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.close")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _vm.useDefaultPreset
              ? _c(
                  "b-button",
                  {
                    staticClass: "level-item",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.createPreset()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.notification.presets.create_preset_btn_label"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _c(
                  "b-button",
                  {
                    staticClass: "level-item",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.editPreset()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.notification.presets.edit_preset_btn_label"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
let DeleteAccountModal = class DeleteAccountModal extends Vue {
    constructor() {
        super(...arguments);
        this.checkedEmail = '';
    }
    deleteAccount() {
        this.$validator.validateAll().then(async (result) => {
            if (result && this.checkedEmail === this.email) {
                this.$emit('deleteAccount');
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_settings.delete_account.error'));
            }
        });
    }
    closeModal() {
        this.$emit('modalClosed');
    }
};
__decorate([
    Prop({ type: String })
], DeleteAccountModal.prototype, "email", void 0);
DeleteAccountModal = __decorate([
    Component({})
], DeleteAccountModal);
export default DeleteAccountModal;

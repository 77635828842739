import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import VueUtilities from '@/services/VueUtilities';
var authenticationCalls;
let ResendVerificationModal = class ResendVerificationModal extends Vue {
    created() {
        authenticationCalls = new AuthenticationCalls(this);
    }
    closeModal() {
        this.$emit('modalClosed');
    }
    async resend(event) {
        let wasSent = await authenticationCalls.resendVerificationEmail(this.email);
        if (wasSent) {
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_info.verification_modal.success'));
            this.closeModal();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_info.verification_modal.failure').toString());
        }
    }
};
__decorate([
    Prop({ type: String })
], ResendVerificationModal.prototype, "email", void 0);
ResendVerificationModal = __decorate([
    Component({ components: {} })
], ResendVerificationModal);
export default ResendVerificationModal;

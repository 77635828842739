import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import PauseNotificationsInterval from '@/entities/enums/PauseNotificationsInterval';
import UserPreferences from '@/services/UserPreferences';
let PauseNotificationsModal = class PauseNotificationsModal extends Vue {
    constructor() {
        super(...arguments);
        this.PauseNotificationsInterval = PauseNotificationsInterval;
        this.isLoading = false;
        this.selectedUsers = [];
        this.pauseValues = {};
        this.page = null;
        this.pageSize = null;
        this.jumpToPageValue = null;
    }
    async created() {
        this.fillFromPref();
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    fillFromPref() {
        this.page = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersRowsPerPage.defaultValue;
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    selecteUser(users) {
        this.selectedUsers = users;
        let newPauseValues = {};
        this.selectedUsers.forEach((user) => {
            newPauseValues[user.apiUserId] = this.pauseValues[user.apiUserId]
                ? this.pauseValues[user.apiUserId]
                : PauseNotificationsInterval.THIRTY_MIN;
        });
        this.pauseValues = newPauseValues;
    }
    async pauseNotifications() {
        if (this.selectedUsers.length <= 0) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.pauseNotificationsModal.select_user'));
            return;
        }
        else if (Object.entries(this.pauseValues).find(([key, value]) => value === null || value === '')) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.pauseNotificationsModal.errorPauseValue'));
            return;
        }
        else if (Object.entries(this.pauseValues).find(([key, value]) => isNaN(value))) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.pauseNotificationsModal.inputError'));
            return;
        }
        else {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    let usersPauseNotifications = [];
                    Object.entries(this.pauseValues).forEach(([key, value], index) => {
                        usersPauseNotifications.push({ apiUserId: key, interval: value });
                    });
                    this.$emit('pauseNotifications', usersPauseNotifications);
                }
            });
        }
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], PauseNotificationsModal.prototype, "users", void 0);
__decorate([
    Prop({ type: Number })
], PauseNotificationsModal.prototype, "totalSize", void 0);
PauseNotificationsModal = __decorate([
    Component({})
], PauseNotificationsModal);
export default PauseNotificationsModal;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "subtitle is-4" }, [
        _vm._v(_vm._s(_vm.$t("tiers.features_list"))),
      ]),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.features,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "name",
              label: _vm.$t("tiers.features.name"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return [
                    _vm._v(
                      _vm._s(_vm.$t("tiers.features." + features.row.name))
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "type",
              label: _vm.$t("tiers.features.type"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return [_vm._v(_vm._s(features.row.type))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "default",
              label: _vm.$t("tiers.features.default"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return _vm._l(
                    Object.keys(features.row.default),
                    function (valueKey) {
                      return _c("p", { key: valueKey }, [
                        _vm._v(
                          " " +
                            _vm._s(valueKey) +
                            ": " +
                            _vm._s(features.row.default[valueKey]) +
                            " "
                        ),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
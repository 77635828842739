var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "columns is-mobile",
      class: _vm.data.isVisible ? "is-table-row-selected-mobile" : "",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "column is-3-desktop is-5-touch is-flex is-align-items-center",
        },
        [
          _c("p", { staticClass: "text-wrap-anywhere" }, [
            _vm._v(_vm._s(_vm.data.source.name)),
          ]),
          _c(
            "div",
            { staticClass: "status-container mt-1 mx-2" },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.$t("error_messages.low_battery"),
                    position: "is-right",
                    type: "is-dark",
                  },
                },
                [
                  _vm.data.source.logger &&
                  _vm.data.source.logger.hasLowBattery() == true
                    ? _c("b-icon", {
                        staticClass: "status-item",
                        attrs: {
                          icon: "battery-quarter",
                          size: "is-small",
                          type: "is-primary",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.$t("error_messages.signal_lost"),
                    position: "is-right",
                    type: "is-dark",
                  },
                },
                [
                  !_vm.data.source.logger ||
                  _vm.data.source.logger.isOnline == false
                    ? _c("img", {
                        staticClass: "signal",
                        attrs: { src: require("@/assets/img/no_signal.svg") },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "column is-9-desktop is-6-touch" },
        _vm._l(_vm.data.events, function (event, index) {
          var _obj
          return _c(
            "div",
            {
              key: event.eventType.id,
              staticClass: "columns is-mobile is-flex is-align-items-center",
            },
            [
              _c(
                "div",
                { staticClass: "column is-5-desktop is-12-touch" },
                [
                  _vm.data.source.logger &&
                  _vm.data.source.logger.state == _vm.ServiceState.ERROR
                    ? _c(
                        "div",
                        [
                          index == 0
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-danger" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("loggers.logger_states.Error")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : [
                        event.eventType.id == _vm.UPTIME_ID
                          ? _c("div", [
                              _vm.data.source.checkIsOnline()
                                ? _c(
                                    "span",
                                    { staticClass: "has-text-success" },
                                    [_vm._v(_vm._s(_vm.$t("generals.online")))]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "has-text-primary" },
                                    [_vm._v(_vm._s(_vm.$t("generals.offline")))]
                                  ),
                            ])
                          : _c(
                              "div",
                              { staticClass: "is-flex is-flex-wrap-wrap" },
                              [
                                _c("div", { staticClass: "mr-2" }, [
                                  _c(
                                    "p",
                                    {
                                      class:
                                        ((_obj = {}),
                                        (_obj[event.status.textClass] =
                                          event.status.name ===
                                          _vm.MeasurementStatuses.ALARM.name),
                                        (_obj["has-text-weight-bold"] =
                                          event.eventType.type == 0),
                                        _obj),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.DecimalConversion.toCommaString(
                                              event.value
                                            ) + event.unit
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  [
                                    event.boundaryDiff.Difference == ""
                                      ? _c(
                                          "b-tag",
                                          {
                                            staticClass: "status-item",
                                            attrs: {
                                              rounded: "",
                                              type: event.status.class,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "measurement_status." +
                                                      event.status.name
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "b-tag",
                                          {
                                            staticClass: "status-item",
                                            attrs: {
                                              rounded: "",
                                              type: event.boundaryDiff.Status
                                                .class,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  event.boundaryDiff
                                                    .Difference + event.unit
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                      ],
                ],
                2
              ),
              _c("BoundaryDetailDesktop", {
                attrs: {
                  event: event,
                  boundary: _vm.getBoundaryForService(
                    event,
                    _vm.data.measuredBoundaries
                  ),
                },
              }),
              _vm.isOneDay(new Date(event.measuredAt * 1000))
                ? _c(
                    "div",
                    { staticClass: "column is-4 is-hidden-touch" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getTimeDifference(
                              new Date(event.measuredAt * 1000)
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            type: "is-dark",
                            label: _vm.dateTimeManager.formatTime(
                              new Date(event.measuredAt * 1000)
                            ),
                            position: "is-left",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              size: "is-small",
                              icon: "question-circle",
                              type: "is-grey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "column is-4 is-hidden-touch" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(event.measuredAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass:
            "column is-1 is-flex is-justify-content-end is-align-items-center is-hidden-desktop",
        },
        [
          _vm.data.isVisible
            ? _c("b-icon", { attrs: { icon: "chevron-down" } })
            : _c("b-icon", { attrs: { icon: "chevron-right" } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("admin.component.notifications.title")) + " "
          ),
        ]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        _c("div", { staticClass: "tile is-parent" }, [
          !_vm.isLoading
            ? _c(
                "section",
                { staticClass: "box tile is-child" },
                [
                  _c(
                    "div",
                    { staticClass: "is-flex is-align-items-center pb-6" },
                    [
                      _c("span", { staticClass: "pr-3 has-text-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notifications.configuration.select_department"
                            )
                          ) + ":"
                        ),
                      ]),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            scrollable: true,
                            "max-height": 300,
                            "aria-role": "list",
                          },
                          on: {
                            input: _vm.selectDepartment,
                            "active-change": _vm.focusDepartmentName,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "buttons" },
                                      [
                                        _c("b-button", {
                                          staticClass:
                                            "is-justify-content-space-between control-overflow-btn",
                                          attrs: {
                                            label:
                                              _vm.selectedDepartment
                                                .companyName,
                                            "icon-right": "angle-down",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2588853292
                          ),
                          model: {
                            value: _vm.selectedDepartment,
                            callback: function ($$v) {
                              _vm.selectedDepartment = $$v
                            },
                            expression: "selectedDepartment",
                          },
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            { attrs: { custom: "", "aria-role": "listitem" } },
                            [
                              _c("b-input", {
                                ref: "departmentName",
                                staticClass: "mb-2",
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  expanded: "",
                                  "icon-right": "close-circle",
                                  "icon-right-clickable": "",
                                },
                                on: {
                                  "icon-right-click": function ($event) {
                                    _vm.departmentName = ""
                                  },
                                },
                                model: {
                                  value: _vm.departmentName,
                                  callback: function ($$v) {
                                    _vm.departmentName = $$v
                                  },
                                  expression: "departmentName",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.filteredDepartments,
                            function (department) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: department.companyId,
                                  attrs: {
                                    value: department,
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "media" }, [
                                    _c(
                                      "div",
                                      { staticClass: "media-content" },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(department.companyName)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _vm.hasRules &&
                      _vm.activeTabNotifications ===
                        _vm.NotificationTabs.GENERAL
                        ? _c(
                            "div",
                            { staticClass: "ml-3" },
                            [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "component.notification.rules_list.copy_rules.copy_tooltip_msg"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { on: { click: _vm.copyRulesDialog } },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "copy",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.copiedRules.companyId !== null
                        ? [
                            _vm.copiedRules.companyId !=
                            _vm.selectedDepartment.companyId
                              ? _c(
                                  "div",
                                  { staticClass: "ml-3" },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "component.notification.rules_list.paste_rules.paste_tooltip_msg"
                                          ),
                                          position: "is-left",
                                          type: "is-dark",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            on: {
                                              click: _vm.confirmPasteRules,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                type: "is-primary",
                                                icon: "paste",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "b-tabs",
                    {
                      attrs: { type: "is-boxed" },
                      on: { input: _vm.tabChanged },
                      model: {
                        value: _vm.activeTabNotifications,
                        callback: function ($$v) {
                          _vm.activeTabNotifications = $$v
                        },
                        expression: "activeTabNotifications",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            value: _vm.NotificationTabs.GENERAL,
                            label: _vm.$t(
                              "notifications.configuration.tab_general"
                            ),
                          },
                        },
                        [
                          !_vm.hasAdminDepartmentSelectedInNotifications
                            ? _c("div", { staticClass: "pb-4" }, [
                                _c("h2", { staticClass: "subtitle mb-1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.use_timetable_title"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    [
                                      _c("b-switch", {
                                        attrs: { size: "is-small" },
                                        on: { input: _vm.onEnableTimetable },
                                        model: {
                                          value: _vm.timetableEnabled,
                                          callback: function ($$v) {
                                            _vm.timetableEnabled = $$v
                                          },
                                          expression: "timetableEnabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "is-size-7 is-italic has-text-grey",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "notifications.configuration.use_timetable_subtitle"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("hr"),
                          _vm.timetableEnabled &&
                          !_vm.hasAdminDepartmentSelectedInNotifications
                            ? _c(
                                "div",
                                [
                                  _c("Timetable", {
                                    ref: "timetableComponent",
                                    attrs: {
                                      availableTypes: _vm.availableTypes,
                                      currentUser: _vm.currentUser,
                                      schedules: _vm.timetableSchedules,
                                      presets: _vm.presets,
                                      departmentUserSettings:
                                        _vm.notificationDepartmentUserSettings,
                                      departmentId: _vm.selectedDepartmentId,
                                      dateTimeManager: _vm.dateTimeManager,
                                    },
                                    on: {
                                      reloadSchedules: _vm.reloadSchedules,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "title has-text-dark is-4 mb-0 pb-0",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notifications.configuration.rules_title"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-size-7 is-italic has-text-grey pb-5",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notifications.configuration.rules_subtitle"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pt-4 pb-5 subtitle has-text-weight-semibold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notifications.configuration.general_or_preset"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "b-dropdown",
                                        {
                                          attrs: { "aria-role": "list" },
                                          on: { change: _vm.applyPreset },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "trigger",
                                                fn: function (ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "is-italic is-underlined clickable-text",
                                                        attrs: {
                                                          role: "button",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.general_or_preset_link"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: active
                                                              ? "caret-up"
                                                              : "caret-down",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1251524264
                                          ),
                                          model: {
                                            value: _vm.selectedPreset,
                                            callback: function ($$v) {
                                              _vm.selectedPreset = $$v
                                            },
                                            expression: "selectedPreset",
                                          },
                                        },
                                        _vm._l(_vm.presets, function (preset) {
                                          return _c(
                                            "b-dropdown-item",
                                            {
                                              key: preset.id,
                                              attrs: {
                                                "aria-role": "listitem",
                                                value: preset,
                                              },
                                            },
                                            [_vm._v(_vm._s(preset.name))]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isChangingMode
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              width: "20%",
                                              animated: true,
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              width: "40%",
                                              animated: true,
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              width: "80%",
                                              animated: true,
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: { animated: true },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        !_vm.hasRulesForEntities
                                          ? _c("MeasuredDataNotification", {
                                              attrs: {
                                                rule: _vm.singleMeasuredRule,
                                                types: _vm.measuredTypes,
                                                currentUser: _vm.currentUser,
                                                selectedMeasuredRulesMode:
                                                  _vm.selectedMeasuredRulesMode,
                                                tier: _vm.tier,
                                                scheduleId:
                                                  _vm.defaultScheduleId,
                                                departmentId:
                                                  _vm.selectedDepartmentId,
                                                autoSave: false,
                                                measuredRuleEnabled:
                                                  _vm.measuredRuleEnabled,
                                              },
                                              on: {
                                                changeMode: _vm.changeMode,
                                                switchedValue:
                                                  _vm.switchedValue,
                                                ruleChanged: _vm.ruleChanged,
                                              },
                                            })
                                          : _c(
                                              "EntityMeasuredDataNotification",
                                              {
                                                attrs: {
                                                  rules: _vm.measuredRules,
                                                  types: _vm.measuredTypes,
                                                  currentUser: _vm.currentUser,
                                                  selectedMeasuredRulesMode:
                                                    _vm.selectedMeasuredRulesMode,
                                                  departmentId:
                                                    _vm.selectedDepartmentId,
                                                  tier: _vm.tier,
                                                  autoSave: false,
                                                },
                                                on: {
                                                  changeMode: _vm.changeMode,
                                                  ruleChanged: _vm.ruleChanged,
                                                },
                                              }
                                            ),
                                      ],
                                  _c("hr"),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .DEVICE_OFFLINE
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .DEVICE_OFFLINE
                                      ),
                                      currentUser: _vm.currentUser,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("hr"),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .SOURCE_OFFLINE
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .SOURCE_OFFLINE
                                      ),
                                      currentUser: _vm.currentUser,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("hr"),
                                  _c(
                                    "h2",
                                    { staticClass: "subtitle has-text-dark" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "notifications.configuration.other_rules_title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .LOW_BATTERY
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .LOW_BATTERY
                                      ),
                                      currentUser: _vm.currentUser,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .CUSTOMER_ENDING_CALIBRATION
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .CUSTOMER_ENDING_CALIBRATION
                                      ),
                                      currentUser: _vm.currentUser,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .PDF_RECORDS_REGENERATED
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .PDF_RECORDS_REGENERATED
                                      ),
                                      currentUser: _vm.currentUser,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .AUTOMATIC_REPORTS_GENERATED
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .AUTOMATIC_REPORTS_GENERATED
                                      ),
                                      currentUser: _vm.currentUser,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .RECORDS_UPDATED
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .RECORDS_UPDATED
                                      ),
                                      currentUser: _vm.currentUser,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .LOGGER_STATE
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .LOGGER_STATE
                                      ),
                                      currentUser: _vm.currentUser,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                ],
                                2
                              ),
                          _vm.hasAdminDepartmentSelectedInNotifications &&
                          _vm.$ability.can(
                            _vm.$permActions.READ,
                            _vm.$permSubjects.ADMIN_USERS_DEPARTMENTS
                          ) &&
                          _vm.activeTabNotifications ===
                            _vm.NotificationTabs.GENERAL
                            ? _c(
                                "section",
                                [
                                  _c("h3", { staticClass: "subtitle pb-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.notification.rules_list.admin_rules_title"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("AdminNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getAdminRule(
                                        _vm.NotificationSubscriptionType
                                          .ADMIN_LOGGER_CALIBRATION_ENDING
                                      ),
                                      type: _vm.getAdminType(
                                        _vm.NotificationSubscriptionType
                                          .ADMIN_LOGGER_CALIBRATION_ENDING
                                      ),
                                      currentUser: _vm.currentUser,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("AdminNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getAdminRule(
                                        _vm.NotificationSubscriptionType
                                          .FEEDBACK
                                      ),
                                      type: _vm.getAdminType(
                                        _vm.NotificationSubscriptionType
                                          .FEEDBACK
                                      ),
                                      currentUser: _vm.currentUser,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("AdminNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getAdminRule(
                                        _vm.NotificationSubscriptionType
                                          .ROLE_CHANGED
                                      ),
                                      type: _vm.getAdminType(
                                        _vm.NotificationSubscriptionType
                                          .ROLE_CHANGED
                                      ),
                                      currentUser: _vm.currentUser,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("AdminNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getAdminRule(
                                        _vm.NotificationSubscriptionType
                                          .CUSTOMER_REGISTERED
                                      ),
                                      type: _vm.getAdminType(
                                        _vm.NotificationSubscriptionType
                                          .CUSTOMER_REGISTERED
                                      ),
                                      currentUser: _vm.currentUser,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("AdminNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getAdminRule(
                                        _vm.NotificationSubscriptionType
                                          .ADMIN_LOGGER_STATE
                                      ),
                                      type: _vm.getAdminType(
                                        _vm.NotificationSubscriptionType
                                          .ADMIN_LOGGER_STATE
                                      ),
                                      currentUser: _vm.currentUser,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            value: _vm.NotificationTabs.NON_WORKING_DAYS,
                            label: _vm.$t(
                              "notifications.configuration.tab_non_working"
                            ),
                          },
                        },
                        [
                          _c("NonWorkingHours", {
                            attrs: {
                              availableTypes: _vm.availableTypes,
                              notificationSubscriptions:
                                _vm.notificationSubscriptions,
                              presets: _vm.presets,
                              schedules: _vm.nonWorkingSchedules,
                              departmentUserSettings:
                                _vm.notificationDepartmentUserSettings,
                              departmentId: _vm.selectedDepartmentId,
                              currentUser: _vm.currentUser,
                            },
                            on: { reloadSchedules: _vm.reloadSchedules },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        !_vm.isResellerAdmin
          ? _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "section",
                { staticClass: "box tile is-child" },
                [
                  _c("AdminPresetsList", {
                    attrs: {
                      presets: _vm.presets,
                      users: _vm.allUsers,
                      departments: _vm.allDepartments,
                    },
                    on: { reloadPresets: _vm.reloadPresets },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "tile is-parent" }, [
          _c(
            "section",
            { staticClass: "box tile is-child" },
            [
              _c("NotificationsTable", {
                attrs: {
                  notifications: _vm.notificationsResult,
                  departmentFilter: null,
                  dateTimeManager: _vm.dateTimeManager,
                  canDelete: true,
                  inNotificationDepartmentPage: false,
                },
                on: {
                  notificationRemoved: _vm.notificationRemoved,
                  onOptionsChange: _vm.onOptionsChange,
                  loadNotifications: _vm.loadNotifications,
                  removeAllNotifications: _vm.removeAllNotifications,
                },
              }),
            ],
            1
          ),
        ]),
        !_vm.isLoading &&
        _vm.$ability.can(
          _vm.$permActions.SEND,
          _vm.$permSubjects.ADMIN_NOTIFICATIONS
        )
          ? _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "section",
                { staticClass: "box tile is-child" },
                [
                  _c("SendNotification", {
                    attrs: {
                      priorities: _vm.notificationPriorities,
                      channels: _vm.channels,
                    },
                    on: { sendNotification: _vm.sendNotification },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.closeUnsavedChangesModal,
              save: _vm.saveDefaultRules,
            },
          }),
        ],
        1
      ),
      !_vm.timetableEnabled &&
      _vm.activeTabNotifications === _vm.NotificationTabs.GENERAL &&
      _vm.haveChangesToSave
        ? _c(
            "div",
            { staticClass: "fixed-form-footer" },
            [
              _c("span", { staticClass: "pb-2 has-text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "notifications.configuration.timetable.save_description"
                    )
                  )
                ),
              ]),
              _c(
                "b-button",
                {
                  attrs: { type: "is-success" },
                  on: {
                    click: function ($event) {
                      return _vm.saveDefaultRules()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("notifications.configuration.timetable.save"))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
var deviceRepository;
let CredentialModal = class CredentialModal extends Vue {
    constructor() {
        super(...arguments);
        this.publicKey = null;
        this.keyId = null;
        this.keyType = 'ES256';
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    newCredential() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let data = {
                    PublicKey: this.publicKey,
                    KeyId: this.keyId,
                    KeyType: this.keyType
                };
                let result = await deviceRepository.adminNewCredential(this.device.deviceId, data);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.newCredentialModal.success'));
                    this.resultRetrieved(true);
                    this.loadNewCredential();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.newCredentialModal.failure'));
                    this.resultRetrieved(false);
                }
            }
        });
    }
    resultRetrieved(result) {
        this.$emit('modalClosed', result);
    }
    loadNewCredential() {
        this.$emit('loadNewCredential');
    }
};
__decorate([
    Prop({ type: Object })
], CredentialModal.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], CredentialModal.prototype, "currentCredentials", void 0);
CredentialModal = __decorate([
    Component({})
], CredentialModal);
export default CredentialModal;

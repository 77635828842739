import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import VueUtilities from '@/services/VueUtilities';
import SourceRepository from '@/services/repository/SourceRepository';
var sourceGroupRepository;
var sourceRepository;
let CreateGroupModal = class CreateGroupModal extends Vue {
    constructor() {
        super(...arguments);
        this.groupName = null;
        this.nameExists = false;
    }
    created() {
        sourceRepository = new SourceRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
    }
    resultRetrieved() {
        this.$emit('modalClosed', false);
    }
    async createGroup() {
        await this.checkIfGroupNameExists(this.groupName);
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.nameExists) {
                let createResult = null;
                let assignResult = null;
                let groupDTO = {
                    Name: this.groupName
                };
                createResult = await sourceGroupRepository.createGroup(groupDTO);
                assignResult = await sourceRepository.assignToGroup(this.loggerToAssign.id, createResult.id);
                if (createResult && assignResult) {
                    VueUtilities.openSuccessToast(this, this.$t('component.group.create.msg.group_created', {
                        name: createResult.name
                    }));
                    this.$emit('modalClosed', true);
                }
            }
        });
    }
    async checkIfGroupNameExists(name) {
        let checkResponse = await sourceGroupRepository.checkIfNameExists(name);
        this.nameExists = checkResponse.Exist;
    }
};
__decorate([
    Prop({ type: Object })
], CreateGroupModal.prototype, "loggerToAssign", void 0);
CreateGroupModal = __decorate([
    Component({ components: {} })
], CreateGroupModal);
export default CreateGroupModal;

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
let DevicesState = class DevicesState extends Vue {
    constructor() {
        super(...arguments);
        this.deviceStatesVisible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.DeviceStatesVisible);
        this.onlineDevices = [];
        this.offlineDevices = [];
        this.isSelectedOnline = false;
        this.offlineCount = 0;
        this.onlineCount = 0;
    }
    created() {
        this.loadData();
    }
    loadData() {
        if (this.deviceList.length) {
            this.deviceList.forEach((device) => {
                if (device.isOnline == true) {
                    this.onlineDevices.push(device);
                }
                else {
                    this.offlineDevices.push(device);
                }
            });
        }
        this.onlineCount = this.onlineDevices.length;
        this.offlineCount = this.offlineDevices.length;
    }
    getDayTime(date) {
        let today = new Date();
        if (date.getDate() == today.getDate()) {
            return `${this.$t('component.device.list.table.today')} ${this.dateTimeManager.formatTime(date, null, '', null)}`;
        }
        else if (date.getDate() == today.getDate() - 1) {
            return `${this.$t('component.device.list.table.yesterday')} ${this.dateTimeManager.formatTime(date, null, '', null)}`;
        }
        else {
            return this.dateTimeManager.formatTime(date, null, null, null);
        }
    }
    getOfflineTime(date) {
        let timeUnit = this.getTimeUnit(date);
        return `${this.$t('component.device.list.table.offlineTime', {
            time: timeUnit.time
        })} ${this.$t('component.device.list.table.timeUnits.' + timeUnit.unit)}`;
    }
    getTimeUnit(date) {
        let differenceInSeconds = Math.floor(new Date().getTime() / 1000 - date.getTime() / 1000);
        var months = Math.floor(differenceInSeconds / 2629743);
        var weeks = Math.floor(differenceInSeconds / 604800) % 4;
        var days = Math.floor(differenceInSeconds / 86400) % 7;
        var hours = Math.floor(differenceInSeconds / 3600) % 24;
        var minutes = Math.floor(differenceInSeconds / 60) % 60;
        var seconds = differenceInSeconds % 60;
        let timeUnit = { time: null, unit: null };
        if (months > 0) {
            timeUnit.time = months;
            timeUnit.unit = months == 1 ? 'month' : 'months';
        }
        else if (weeks > 0) {
            timeUnit.time = weeks;
            timeUnit.unit = weeks == 1 ? 'week' : 'weeks';
        }
        else if (days > 0) {
            timeUnit.time = days;
            timeUnit.unit = days == 1 ? 'day' : 'days';
        }
        else if (hours > 0) {
            timeUnit.time = hours;
            timeUnit.unit = hours == 1 ? 'hour' : 'hours';
        }
        else if (minutes > 0) {
            timeUnit.time = minutes;
            timeUnit.unit = minutes == 1 ? 'minute' : 'minutes';
        }
        else {
            timeUnit.time = seconds;
            timeUnit.unit = 'seconds';
        }
        return timeUnit;
    }
    selectDevices(event) {
        this.isSelectedOnline = event;
    }
    stateDataCollapseChanged(isOpen) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.DeviceStatesVisible, isOpen);
    }
};
__decorate([
    Prop({ type: Array })
], DevicesState.prototype, "deviceList", void 0);
__decorate([
    Prop({ type: Object })
], DevicesState.prototype, "dateTimeManager", void 0);
DevicesState = __decorate([
    Component({})
], DevicesState);
export default DevicesState;

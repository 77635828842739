var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-notification", {
    staticClass: "always-in-view",
    attrs: {
      type: "is-info",
      "has-icon": "",
      "aria-close-label": "Close notification",
      icon: "question-circle",
      indefinite: "",
      message: _vm.text,
    },
    on: { close: _vm.notificationClosed },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
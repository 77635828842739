var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background-job-preview" }, [
    _c(
      "div",
      {
        staticClass:
          "is-flex is-justify-content-space-between is-flex-direction-row",
      },
      [
        _c("div", [
          _c("p", { staticClass: "has-text-weight-semibold" }, [
            _vm._v(_vm._s(_vm.backgroundJob.FullName)),
          ]),
          _c("p", { staticClass: "is-italic" }, [
            _vm._v(_vm._s(_vm.backgroundJob.Description)),
          ]),
        ]),
        _c("b-icon", {
          staticClass: "control-icon clickable",
          attrs: { icon: "info" },
          nativeOn: {
            click: function ($event) {
              return _vm.showRawJob(_vm.jobsList.row)
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "mt-1" }, [
      _c(
        "table",
        [
          _c("caption", [
            _vm._v(" " + _vm._s(_vm.$t("background_jobs.triggers")) + " "),
          ]),
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("background_jobs.name")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("background_jobs.next")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("background_jobs.previous")))]),
          ]),
          _vm._l(_vm.backgroundJob.Triggers, function (trigger) {
            return _c("tr", { key: trigger.FullName }, [
              _c("td", [_vm._v(_vm._s(trigger.Name))]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(trigger.NextFireTimeUtc * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(trigger.PreviousFireTimeUtc * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td"),
            ])
          }),
        ],
        2
      ),
    ]),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
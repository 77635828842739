import User from '@/entities/User';
import { DeviceConfiguration } from '@/entities/models/DeviceConfiguration';
import { Company } from '@/entities/models/Company';
export default class DeviceInstance {
    constructor() {
        this.registeredBy = new User();
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DeviceInstance.define(et));
        }
        else if (data != null) {
            return DeviceInstance.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let instance = new DeviceInstance();
        instance.id = data.Id;
        instance.state = data.State;
        instance.createdAt = data.CreatedAt;
        instance.returnedAt = data.ReturnedAt;
        instance.registrationKey = data.RegistrationKey;
        instance.registeredAt = data.RegisteredAt;
        instance.lastSeen = data.LastSeen;
        instance.enabled = data.Enabled;
        instance.currentConfigId = data.CurrentConfigId;
        if (data.Company)
            instance.company = Company.fromApi(data.Company);
        if (data.RegisteredBy)
            instance.registeredBy = User.fromApi(data.RegisteredBy);
        if (data.CurrentConfig)
            instance.currentConfig = DeviceConfiguration.fromApi(data.CurrentConfig);
        if (data.CurrentConfig)
            instance.lastConfig = DeviceConfiguration.fromApi(data.LastConfig);
        if (data.Configs)
            instance.configs = DeviceConfiguration.fromApi(data.Configs);
        return instance;
    }
}

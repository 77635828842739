import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import AdminResellerList from '@/views/components/admin/reseller/AdminResellerList.vue';
import ResellerRepository from '@/services/repository/ResellerRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import CreateResellerModal from '@/views/components/admin/reseller/CreateResellerModal.vue';
var resellerRepository;
let AdminResellerManagement = class AdminResellerManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.dateTimeManager = null;
        this.resellerList = new Array();
        this.tableAdminResellerQueries = 'adminReseller';
        this.objStringAdminReseller = null;
        this.isModalActive = false;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        resellerRepository = new ResellerRepository(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.resellerList = await resellerRepository.getAdminResellers();
        this.isLoading = false;
    }
    createReseller() {
        this.isModalActive = true;
    }
    closeModal() {
        this.isModalActive = false;
    }
    changeRouterUrlFromReseller(objStringAdminReseller) {
        this.objStringAdminReseller = objStringAdminReseller;
        this.changeRouterUrl(this.objStringAdminReseller);
    }
    changeRouterUrl(objStringAdminReseller) {
        this.$router
            .push({
            query: {
                [this.tableAdminResellerQueries]: objStringAdminReseller
            }
        })
            .catch((err) => err);
    }
};
AdminResellerManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.LIST, Subjects.ADMIN_RESELLER)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            AdminResellerList,
            CreateResellerModal
        }
    })
], AdminResellerManagement);
export default AdminResellerManagement;

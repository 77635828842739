import { __decorate } from "tslib";
import { Source } from '@/entities/models/Source';
import { SourceGroup } from '@/entities/models/SourceGroup';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import BoundaryRepository from '@/services/repository/BoundaryRepository';
import { Boundary } from '@/entities/models/Boundary';
import BoundaryInfo from '@/views/components/boundary/BoundaryInfo.vue';
import CreateGroupModal from '@/views/components/group/CreateGroupModal.vue';
import VueUtilities from '@/services/VueUtilities';
import BoundaryModal from '@/views/components/boundary/BoundaryModal.vue';
import MeasurementsSort from '@/entities/enums/MeasurementsSort';
var sourceRepository;
var sourceGroupRepository;
var boundaryRepository;
let BoundaryWizardTable = class BoundaryWizardTable extends Vue {
    constructor() {
        super(...arguments);
        this.sourceGroupsWithBoundaries = [];
        this.boundariesForModal = [];
        this.arrayForTable = [];
        this.nameExists = false;
        this.loggerToAssign = null;
        this.isCreateGroupModalActive = false;
        this.isBoundaryModalActive = false;
        this.boundariesEntity = null;
    }
    get sourceGroupsForSelect() {
        return this.sourceGroups.reverse();
    }
    createArrayForTable() {
        let finalArr = [];
        SourceGroup.sort(this.sourceGroupsWithBoundaries, '', MeasurementsSort.ALPHABETICAL);
        this.sourceGroupsWithBoundaries.forEach((group) => {
            if (group.sources.length <= 0)
                return;
            let groupObj = {
                entity: group,
                isEditNameActive: false,
                newName: group.name,
                selectedGroup: this.sourceGroups.find((x) => x.id == group.id),
                lastSourceInGroup: false
            };
            finalArr.push(groupObj);
            group.sources.forEach((source, index) => {
                let isLast = group.sources.length == index + 1;
                let sourceObj = {
                    entity: source,
                    isEditNameActive: false,
                    newName: source.name,
                    selectedGroup: this.sourceGroups.find((x) => x.id == source.sourceGroup?.id),
                    lastSourceInGroup: isLast
                };
                finalArr.push(sourceObj);
            });
        });
        return finalArr;
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        boundaryRepository = new BoundaryRepository(this);
        this.processSourcesAndGroups(this.sources, this.sourceGroups);
        this.arrayForTable = this.createArrayForTable();
    }
    processSourcesAndGroups(sources, sourceGroups) {
        let groups = SourceGroup.assignSourcesToSourceGroups(SourceGroup.createArrayCopy(sourceGroups), Source.createArrayCopy(sources));
        let unassignedSources = Source.getUnassignedSources(sources);
        groups.find((x) => x.id == null).sources = unassignedSources;
        this.sourceGroupsWithBoundaries = groups;
    }
    isSourceGroup(element) {
        return element instanceof SourceGroup;
    }
    isSourceInGroup(source) {
        let group = this.sourceGroupsWithBoundaries.find((x) => x.sources.includes(source));
        return group != null && group.name != null;
    }
    boudariesForGroup(group) {
        if (group.name && group.sources.length > 0) {
            return group.sources[0].boundaries;
        }
        else {
            return [];
        }
    }
    editName(entity) {
        let currentVal = entity.isEditNameActive;
        this.arrayForTable.forEach((x) => {
            x.isEditNameActive = false;
        });
        entity.isEditNameActive = !currentVal;
    }
    async checkIfGroupNameExists(entity) {
        if (!entity.newName || entity.newName == entity.entity.name) {
            this.nameExists = false;
        }
        else {
            let checkResponse = await sourceGroupRepository.checkIfNameExists(entity.newName);
            this.nameExists = checkResponse.Exist;
        }
    }
    async checkIfSourceNameExists(entity) {
        if (!entity.newName || entity.newName == entity.entity.name) {
            this.nameExists = false;
        }
        else {
            let checkResponse = await sourceRepository.checkIfNameExists(entity.newName);
            this.nameExists = checkResponse.Exist;
        }
    }
    async saveGroupName(entity) {
        await this.checkIfGroupNameExists(entity);
        if (this.nameExists || (entity.newName && entity.newName == entity.entity.name)) {
            VueUtilities.openErrorToast(this, this.$t('component.group.create.msg.unique_name').toString());
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.nameExists) {
                let updateResult = null;
                let groupDTO = {
                    Name: entity.newName,
                    Visible: entity.selectedGroup.visible
                };
                updateResult = await sourceGroupRepository.updateGroup(entity.entity.id, groupDTO);
                if (updateResult) {
                    VueUtilities.openSuccessToast(this, this.$t('component.group.detail.msg.edit_success').toString());
                    this.$emit('reload');
                }
            }
        });
    }
    async saveSourceName(entity) {
        await this.checkIfSourceNameExists(entity);
        if (this.nameExists || (entity.newName && entity.newName == entity.entity.name)) {
            VueUtilities.openErrorToast(this, this.$t('component.location.detail.msg.unique_name').toString());
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.nameExists) {
                let updateResult = null;
                let sourceDTO = {
                    Name: entity.newName,
                    Description: entity.entity.description,
                    Visible: entity.entity.visible
                };
                updateResult = await sourceRepository.updateSource(entity.entity.id, sourceDTO);
                if (updateResult) {
                    VueUtilities.openSuccessToast(this, this.$t('component.location.detail.msg.edit_success').toString());
                    this.$emit('reload');
                }
            }
        });
    }
    async groupSelected(entity) {
        let updateResult = null;
        if (entity.selectedGroup === 'null') {
            this.loggerToAssign = entity.entity;
            this.isCreateGroupModalActive = true;
        }
        else if (entity.selectedGroup.id === null) {
            updateResult = await sourceRepository.unassignFromGroup(entity.entity.id, entity.entity.sourceGroup?.id);
        }
        else {
            updateResult = await sourceRepository.assignToGroup(entity.entity.id, entity.selectedGroup.id);
        }
        if (updateResult) {
            VueUtilities.openSuccessToast(this, this.$t('component.location.detail.msg.edit_success').toString());
            this.$emit('reload');
        }
    }
    editBoundaries(entity) {
        this.boundariesEntity = entity;
        if (this.isSourceGroup(entity)) {
            if (entity.sources.length > 0)
                this.boundariesForModal = Boundary.convertBoundariesToDTO(entity.sources[0].boundaries || []);
            else
                this.boundariesForModal = Boundary.convertBoundariesToDTO([]);
        }
        else {
            this.boundariesForModal = Boundary.convertBoundariesToDTO(entity.boundaries || []);
        }
        this.isBoundaryModalActive = true;
    }
    async saveBoundariesCommand(boundaries) {
        let result = true;
        if (!this.boundariesEntity)
            return;
        if (this.isSourceGroup(this.boundariesEntity)) {
            let allBoundaryPromisses = [];
            this.boundariesEntity.sources.forEach((source) => {
                allBoundaryPromisses.push(this.assignBoundaries(boundaries, source.id));
            });
            result = await Promise.all(allBoundaryPromisses)
                .then((response) => {
                return true;
            })
                .catch((error) => {
                VueUtilities.openErrorToast(this, `${this.$t('component.group.detail.msg.boundaries_failure').toString()} ${error.response.data.message}`);
                return false;
            });
        }
        else {
            result = await this.assignBoundaries(boundaries, this.boundariesEntity.id);
        }
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('component.group.detail.msg.boundaries_success'));
            this.$emit('reload');
        }
    }
    async assignBoundaries(boundaries, sourceId) {
        return boundaryRepository.createBoundary(boundaries, sourceId);
    }
    getCopyOfBoundaries() {
        if (!this.boundariesForModal)
            return [];
        else
            return JSON.parse(JSON.stringify(this.boundariesForModal));
    }
    closeGroupModal(result) {
        this.isCreateGroupModalActive = false;
        if (result === true)
            this.$emit('reload');
    }
    closeBoundaryModal(result) {
        this.isBoundaryModalActive = false;
    }
};
__decorate([
    Prop({ type: Array })
], BoundaryWizardTable.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array })
], BoundaryWizardTable.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], BoundaryWizardTable.prototype, "eventTypes", void 0);
BoundaryWizardTable = __decorate([
    Component({ components: { BoundaryInfo, CreateGroupModal, BoundaryModal } })
], BoundaryWizardTable);
export default BoundaryWizardTable;

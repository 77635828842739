var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "event-detail",
      class: [
        _vm.getStatusClass(_vm.events),
        { "is-old-value": !_vm.source.checkIsOnline() },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("div", { staticClass: "event-detail-level level is-mobile" }, [
            _c("div", { staticClass: "level-left event-detail-head" }, [
              _c("h5", { staticClass: "title is-5 has-text-black mb-0" }, [
                _vm._v(_vm._s(_vm.source.name)),
              ]),
            ]),
            !_vm.onPublicPage && _vm.source.checkIsOnline()
              ? _c(
                  "div",
                  { staticClass: "level-right pl-3 pr-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t("component.last_events.filter_note"),
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "filter",
                            type: "is-grey",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.scrollToChartFilter()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          !_vm.onPublicPage
            ? [
                _vm.source.logger && _vm.source.logger.serialNumber
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "is-subtitle-event-detail text-wrap-anywhere",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.source.logger.serialNumber) + " "
                        ),
                      ]
                    )
                  : _c("p", { staticClass: "is-subtitle-event-detail" }, [
                      _vm._v("-"),
                    ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.source.logger && _vm.source.logger.state == _vm.ServiceState.ERROR
        ? _c(
            "div",
            [
              _c("b-tag", { attrs: { rounded: "", type: "is-danger" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("loggers.logger_states.Error")) + " "
                ),
              ]),
            ],
            1
          )
        : _vm._l(_vm.events, function (event) {
            return _c(
              "div",
              {
                key: event.eventType.id,
                staticClass:
                  "value is-flex is-align-items-center is-justify-content-space-between m-1",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-flex-wrap-nowrap is-align-items-center",
                  },
                  [
                    _c("b-icon", {
                      staticClass: "type-icon is-justify-content-flex-start",
                      attrs: { icon: event.eventType.icon },
                    }),
                    event.eventType.id != _vm.UPTIME_ID
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(event.value)
                              ) +
                              _vm._s(event.unit) +
                              " "
                          ),
                        ])
                      : _c("span", { staticClass: "pl-2" }, [
                          _vm.source.checkIsOnline()
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("generals.online"))),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("generals.offline"))),
                              ]),
                        ]),
                  ],
                  1
                ),
                event.eventType.id != _vm.UPTIME_ID
                  ? [
                      event.status.name !==
                        _vm.MeasurementStatuses.ALARM.name || _vm.onPublicPage
                        ? _c(
                            "b-tag",
                            {
                              staticClass: "status-item",
                              attrs: { rounded: "", type: event.status.class },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "measurement_status." + event.status.name
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "b-tag",
                            {
                              staticClass: "status-item",
                              attrs: {
                                rounded: "",
                                type: event.boundaryDiff.Status.class,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    event.boundaryDiff.Difference + event.unit
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
      _c("div", { staticClass: "detail-footer mt-3" }, [
        _c("span", { staticClass: "is-subtitle-event-detail mr-3" }, [
          _vm._v(
            _vm._s(
              _vm.dateTimeManager.formatTime(
                new Date(_vm.events[0].measuredAt * 1000)
              )
            )
          ),
        ]),
        _c(
          "div",
          { staticClass: "status-container" },
          [
            !_vm.onPublicPage
              ? _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$t("error_messages.low_battery"),
                      position: "is-left",
                      type: "is-light",
                    },
                  },
                  [
                    _vm.source.logger &&
                    _vm.source.logger.hasLowBattery() == true
                      ? _c("b-icon", {
                          staticClass: "status-item",
                          attrs: {
                            icon: "battery-quarter",
                            size: "is-small",
                            type: "is-primary",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-tooltip",
              {
                attrs: {
                  label: _vm.$t("error_messages.signal_lost"),
                  position: "is-left",
                  type: "is-light",
                },
              },
              [
                !_vm.source.checkIsOnline()
                  ? _c("img", {
                      staticClass: "signal",
                      attrs: { src: require("@/assets/img/no_signal.svg") },
                    })
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
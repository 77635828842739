var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.public_dashboard.create.title")) +
              " "
          ),
        ]),
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _vm.dashboardKey === null
          ? _c(
              "div",
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.public_dashboard.create.dashboard_title"
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        expanded: "",
                        placeholder: _vm.$t(
                          "component.public_dashboard.create.title_placeholder"
                        ),
                      },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.public_dashboard.create.is_public"
                      ),
                    },
                  },
                  [
                    _c("b-switch", {
                      model: {
                        value: _vm.isPublic,
                        callback: function ($$v) {
                          _vm.isPublic = $$v
                        },
                        expression: "isPublic",
                      },
                    }),
                  ],
                  1
                ),
                !_vm.isPublic
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t(
                            "component.public_dashboard.create.users_list"
                          ),
                          horizontal: "",
                        },
                      },
                      [
                        _c(
                          "b-table",
                          {
                            staticClass: "select-location-table border",
                            attrs: {
                              data: _vm.availableUsers,
                              "checked-rows": _vm.selectedUsers,
                              paginated: true,
                              "per-page": 5,
                              narrowed: "",
                              checkable: "",
                            },
                            on: {
                              "update:checkedRows": function ($event) {
                                _vm.selectedUsers = $event
                              },
                              "update:checked-rows": function ($event) {
                                _vm.selectedUsers = $event
                              },
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "name",
                                label: _vm.$t(
                                  "component.public_dashboard.create.name"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (users) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(users.row.username)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                877991824
                              ),
                            }),
                            _c("template", { slot: "bottom-left" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.public_dashboard.create.selected_count"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                " : " + _vm._s(_vm.selectedUsers.length) + " "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.sources.length > 0
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t(
                            "component.public_dashboard.create.source_list"
                          ),
                          horizontal: "",
                        },
                      },
                      [
                        _c(
                          "b-table",
                          {
                            staticClass: "select-location-table border",
                            attrs: {
                              data: _vm.sources,
                              "checked-rows": _vm.selectedSources,
                              paginated: true,
                              "per-page": 5,
                              narrowed: "",
                              checkable: "",
                            },
                            on: {
                              "update:checkedRows": function ($event) {
                                _vm.selectedSources = $event
                              },
                              "update:checked-rows": function ($event) {
                                _vm.selectedSources = $event
                              },
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "name",
                                label: _vm.$t(
                                  "component.public_dashboard.create.name"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (sources) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(sources.row.name)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2608515585
                              ),
                            }),
                            _c("template", { slot: "bottom-left" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.public_dashboard.create.selected_count"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                " : " + _vm._s(_vm.selectedSources.length) + " "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.availableSourceGroups.length > 0
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t(
                            "component.public_dashboard.create.source_group_list"
                          ),
                          horizontal: "",
                        },
                      },
                      [
                        _c(
                          "b-table",
                          {
                            staticClass: "select-location-table border",
                            attrs: {
                              data: _vm.availableSourceGroups,
                              "checked-rows": _vm.selectedSourceGroups,
                              paginated: true,
                              "per-page": 5,
                              narrowed: "",
                              checkable: "",
                            },
                            on: {
                              "update:checkedRows": function ($event) {
                                _vm.selectedSourceGroups = $event
                              },
                              "update:checked-rows": function ($event) {
                                _vm.selectedSourceGroups = $event
                              },
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "name",
                                label: _vm.$t(
                                  "component.public_dashboard.create.name"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (groups) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(groups.row.name)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2249423201
                              ),
                            }),
                            _c("template", { slot: "bottom-left" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.public_dashboard.create.selected_count"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                " : " +
                                  _vm._s(_vm.selectedSourceGroups.length) +
                                  " "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.availableDevices.length > 0
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t(
                            "component.public_dashboard.create.device_list"
                          ),
                          horizontal: "",
                        },
                      },
                      [
                        _c(
                          "b-table",
                          {
                            staticClass: "select-location-table border",
                            attrs: {
                              data: _vm.availableDevices,
                              "checked-rows": _vm.selectedDevices,
                              paginated: true,
                              "per-page": 5,
                              narrowed: "",
                              checkable: "",
                            },
                            on: {
                              "update:checkedRows": function ($event) {
                                _vm.selectedDevices = $event
                              },
                              "update:checked-rows": function ($event) {
                                _vm.selectedDevices = $event
                              },
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "name",
                                label: _vm.$t(
                                  "component.public_dashboard.create.name"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (devices) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(devices.row.deviceName)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2825979161
                              ),
                            }),
                            _c("template", { slot: "bottom-left" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.public_dashboard.create.selected_count"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                " : " + _vm._s(_vm.selectedDevices.length) + " "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "component.public_dashboard.create.components"
                      ),
                      horizontal: "",
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t("component.public_dashboard.create.components")
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("component.public_dashboard.create.components")
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "my-1",
                            attrs: {
                              "native-value":
                                _vm.PublicDashboardComponents.LAST_MEASUREMENTS,
                              name: _vm.$t(
                                "component.public_dashboard.create.components"
                              ),
                            },
                            model: {
                              value: _vm.allowedComponents,
                              callback: function ($$v) {
                                _vm.allowedComponents = $$v
                              },
                              expression: "allowedComponents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.public_dashboard.allowedComponents." +
                                      _vm.PublicDashboardComponents
                                        .LAST_MEASUREMENTS
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "my-1",
                            attrs: {
                              "native-value":
                                _vm.PublicDashboardComponents.CHART,
                              name: _vm.$t(
                                "component.public_dashboard.create.components"
                              ),
                            },
                            model: {
                              value: _vm.allowedComponents,
                              callback: function ($$v) {
                                _vm.allowedComponents = $$v
                              },
                              expression: "allowedComponents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.public_dashboard.allowedComponents." +
                                      _vm.PublicDashboardComponents.CHART
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "my-1",
                            attrs: {
                              "native-value":
                                _vm.PublicDashboardComponents.REPORTS,
                              name: _vm.$t(
                                "component.public_dashboard.create.components"
                              ),
                            },
                            model: {
                              value: _vm.allowedComponents,
                              callback: function ($$v) {
                                _vm.allowedComponents = $$v
                              },
                              expression: "allowedComponents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.public_dashboard.allowedComponents." +
                                      _vm.PublicDashboardComponents.REPORTS
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.allowedComponents.includes(
                  _vm.PublicDashboardComponents.REPORTS
                )
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t(
                            "component.public_dashboard.create.show_annotations"
                          ),
                          horizontal: "",
                        },
                      },
                      [
                        _c("b-switch", {
                          attrs: {
                            "native-value":
                              _vm.PublicDashboardComponents.ANNOTATIONS,
                          },
                          model: {
                            value: _vm.showAnnotations,
                            callback: function ($$v) {
                              _vm.showAnnotations = $$v
                            },
                            expression: "showAnnotations",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "has-text-centered" },
              [
                _c("h3", { staticClass: "subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.public_dashboard.create.created")
                      ) +
                      " "
                  ),
                ]),
                _c("router-link", {
                  attrs: {
                    to: {
                      name: "publicDashboard",
                      params: {
                        dashboardKey: _vm.dashboardKey,
                        lang: _vm.$route.params.lang,
                        departmentId: _vm.departmentId,
                      },
                    },
                    custom: "",
                    target: "_blank",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var href = ref.href
                        return [
                          _c(
                            "a",
                            {
                              staticClass:
                                "has-text-primary is-size-4 wrap-long-text",
                              attrs: { id: "linkToDashboard", href: href },
                            },
                            [_vm._v(_vm._s(_vm.origin) + _vm._s(href))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "has-text-centered has-text-weight-bold mt-4" },
          [
            _vm.copyResult === true
              ? _c("span", { staticClass: "has-text-success" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.public_dashboard.create.copy_success")
                      ) +
                      " "
                  ),
                ])
              : _vm.copyResult === false
              ? _c("span", { staticClass: "has-text-primary" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.public_dashboard.create.copy_failure")
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "footer",
        {
          staticClass:
            "modal-card-foot is-flex is-justify-content-space-between",
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _vm.dashboardKey === null &&
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DASHBOARD)
            ? _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createPublicDashboard()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.public_dashboard.create.confirm"))
                  ),
                ]
              )
            : _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.copyLinkToClipboard()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.public_dashboard.create.copy"))
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import WizardSteps from '@/entities/enums/wizardSteps';
import { UserSettings } from '@/entities/UserSettings';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import InstructionsStep from '@/views/components/wizard/steps/InstructionsStep.vue';
import ProgressBar from '@/views/components/wizard/ProgressBar.vue';
import SkipStep from '@/views/components/wizard/SkipStep.vue';
import WizardHelp from '@/views/components/wizard/WizardHelp.vue';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import EventType from '@/entities/EventType';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import DeviceRepository from '@/services/repository/DeviceRepository';
import { CompanyLogger } from '@/entities/models/CompanyLogger';
import { Source } from '@/entities/models/Source';
import { SourceGroup } from '@/entities/models/SourceGroup';
import DateTimeManager from '@/services/DateTimeManager';
import EventDomain from '@/entities/enums/eventDomains';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import LoggedUserManager from '@/services/LoggedUserManager';
import UserRepository from '@/services/repository/UserRepository';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import EventValidationRepository from '@/services/repository/EventValidationRepository';
import DeviceRegistrationStep from '@/views/components/wizard/steps/DeviceRegistrationStep.vue';
import LoggerRegistrationStep from '@/views/components/wizard/steps/LoggerRegistrationStep.vue';
import BoundariesStep from '@/views/components/wizard/steps/BoundariesStep.vue';
import NotificationsStep from '@/views/components/wizard/steps/NotificationsStep.vue';
import DetailedInstructionsModal from '@/views/components/wizard/DetailedInstructionsModal.vue';
import AppConfig from '@/configLoader';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import ApiQueriesKeys from '@/entities/enums/ApiQueriesKeys';
import { DepartmentUserSettings } from '@/entities/DepartmentUserSettings';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import VueUtilities from '@/services/VueUtilities';
import { vxm } from '@/store/store.vuex';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
import TiersRepository from '@/services/repository/TiersRepository';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
var userSettingsRepository;
var eventTypeRepository;
var companyLoggerRepository;
var sourceRepository;
var sourceGroupRepository;
var deviceRepository;
var userRepository;
var notificationSubscriptionsRepository;
var eventsValidationRepository;
var departmentRepository;
var tiersRepository;
const DEFAULT_ROUTE = 'dashboard';
var vxDepartmentStore = vxm.departmentStore;
let Wizard = class Wizard extends Vue {
    constructor() {
        super(...arguments);
        this.name = 'Wizard';
        this.WizardSteps = WizardSteps;
        this.deviceId = null;
        this.deviceList = [];
        this.actualProcessStep = WizardSteps.INSTRUCTIONS;
        this.sourceRoute = DEFAULT_ROUTE;
        this.sourceRouteParams = {};
        this.availableEventTypes = [];
        this.boundariesList = [];
        this.boundariesLoaded = false;
        this.selectedUnits = [];
        this.companyLoggerList = [];
        this.sourceGroups = new Array();
        this.originSourceGroups = new Array();
        this.sources = [];
        this.eventTypes = [];
        this.isLoading = true;
        this.isChangingStep = false;
        this.isSwappingRulesMode = false;
        this.loggers = new Array();
        this.company = null;
        this.isSetupRequired = true;
        this.settings = new UserSettings();
        this.departmentUserSettings = new DepartmentUserSettings();
        this.currentUser = null;
        this.availableTypes = [];
        this.notificationSubscriptions = [];
        this.fullHelpVisible = true;
        this.alreadyVisitedSteps = [];
        this.isDetailedInfoModalActive = false;
        this.helpButtonHidden = false;
        this.loggedFromAdmin = LoggedUserManager.isAdmin(this);
        this.openLoggerScanFlag = false;
        this.departmentSettings = null;
        this.presets = [];
        this.tier = null;
        this.timetableEnabled = false;
        this.defaultScheduleId = null;
        this.defaultSchedule = null;
        this.subscriptionsToCreate = [];
        this.haveChangesToSave = false;
        this.timetableSchedules = [];
        this.dataLoadedForStep = {
            INSTRUCTIONS: false,
            DEVICE: false,
            LOGGERS: false,
            BOUNDARIES: false,
            NOTIFICATIONS: false,
            DONE: false
        };
    }
    get selectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    async created() {
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        sourceRepository = new SourceRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        deviceRepository = new DeviceRepository(this);
        userRepository = new UserRepository(this);
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        eventsValidationRepository = new EventValidationRepository(this);
        departmentRepository = new DepartmentRepository(this);
        tiersRepository = new TiersRepository(this);
        this.loadStepFromRoute();
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.moveToNextStep(this.actualProcessStep);
    }
    updateRouteQuery() {
        let queries = JSON.parse(JSON.stringify(this.$route.query));
        queries[ApiQueriesKeys.STEP] = this.actualProcessStep;
        this.$router
            .replace({
            query: Object.assign({}, queries),
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => { });
    }
    loadStepFromRoute() {
        if (!this.$route.query)
            return;
        let stepFromRoute = this.$route.query[ApiQueriesKeys.STEP];
        if (stepFromRoute) {
            if (isNaN(parseInt(stepFromRoute)))
                this.actualProcessStep = WizardSteps.INSTRUCTIONS;
            else {
                this.actualProcessStep = parseInt(stepFromRoute);
            }
        }
    }
    async loadDataForStep(step) {
        if (this.dataLoadedForStep.INSTRUCTIONS == false) {
            await this.loadInitialData();
        }
        if (step == WizardSteps.INSTRUCTIONS)
            return true;
        if (this.dataLoadedForStep.DEVICE == false) {
            await this.loadDeviceData();
        }
        if (step == WizardSteps.DEVICE)
            return true;
        if (this.dataLoadedForStep.LOGGERS == false) {
            await this.loadLoggerData(false);
        }
        if (step == WizardSteps.LOGGERS)
            return true;
        if (this.dataLoadedForStep.BOUNDARIES == false) {
            await this.loadBoundaryData();
        }
        if (step == WizardSteps.BOUNDARIES)
            return true;
        if (this.dataLoadedForStep.NOTIFICATIONS == false) {
            await this.loadNotificationsData();
        }
        if (step == WizardSteps.NOTIFICATIONS)
            return true;
    }
    async moveToNextStep(step) {
        switch (step) {
            case WizardSteps.INSTRUCTIONS:
                this.isChangingStep = true;
                await this.loadDataForStep(WizardSteps.INSTRUCTIONS);
                this.isLoading = false;
                this.actualProcessStep = WizardSteps.INSTRUCTIONS;
                this.updateRouteQuery();
                this.isChangingStep = false;
                this.alreadyVisitedSteps.push(WizardSteps.INSTRUCTIONS);
                break;
            case WizardSteps.DEVICE:
                this.isChangingStep = true;
                await this.loadDataForStep(WizardSteps.DEVICE);
                this.isLoading = false;
                this.actualProcessStep = WizardSteps.DEVICE;
                this.updateRouteQuery();
                this.isChangingStep = false;
                this.alreadyVisitedSteps.push(WizardSteps.DEVICE);
                if (!this.$featuresManager.isAvailable(this.$features.OBSERVER_IN_COMPANY))
                    this.moveToNextStep(step + 1);
                break;
            case WizardSteps.LOGGERS:
                this.isChangingStep = true;
                this.isLoading = true;
                await this.loadDataForStep(WizardSteps.LOGGERS);
                this.isLoading = false;
                this.openLoggerScanFlag = this.companyLoggerList.length == 0;
                this.actualProcessStep = WizardSteps.LOGGERS;
                this.updateRouteQuery();
                this.isChangingStep = false;
                this.alreadyVisitedSteps.push(WizardSteps.LOGGERS);
                break;
            case WizardSteps.BOUNDARIES:
                this.isChangingStep = true;
                await this.loadDataForStep(WizardSteps.BOUNDARIES);
                this.isLoading = false;
                this.actualProcessStep = WizardSteps.BOUNDARIES;
                this.updateRouteQuery();
                this.isChangingStep = false;
                this.alreadyVisitedSteps.push(WizardSteps.BOUNDARIES);
                break;
            case WizardSteps.NOTIFICATIONS:
                this.isChangingStep = true;
                await this.loadDataForStep(WizardSteps.NOTIFICATIONS);
                this.isLoading = false;
                this.actualProcessStep = WizardSteps.NOTIFICATIONS;
                this.updateRouteQuery();
                this.isChangingStep = false;
                this.alreadyVisitedSteps.push(WizardSteps.NOTIFICATIONS);
                break;
            case WizardSteps.DONE:
                this.isChangingStep = true;
                this.actualProcessStep = WizardSteps.DONE;
                this.updateRouteQuery();
                this.isChangingStep = false;
                this.alreadyVisitedSteps.push(WizardSteps.DONE);
                this.isLoading = false;
                break;
            default:
                break;
        }
        this.hideHelpComponent(this.fullHelpVisible);
        document.getElementById('progressBar').scrollIntoView();
    }
    loadInitialData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(userSettingsRepository.loadUserSettings(true));
        allPromises.push(departmentRepository.getDepartment());
        return Promise.all(allPromises).then(async (response) => {
            this.settings = response[0];
            this.isSetupRequired = response[1].settings.setupRequired;
            this.departmentSettings = response[1].settings;
            this.dataLoadedForStep.INSTRUCTIONS = true;
        });
    }
    loadDeviceData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(deviceRepository.getDevices());
        return Promise.all(allPromises).then((response) => {
            this.deviceList = response[0].getData();
            this.dataLoadedForStep.DEVICE = true;
        });
    }
    loadLoggerData(showLoadingAnimation = true) {
        if (showLoadingAnimation)
            this.isLoading = true;
        this.openLoggerScanFlag = false;
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLoggers());
        allPromises.push(sourceGroupRepository.getSourceGroups());
        allPromises.push(sourceRepository.getVisibleSources());
        return Promise.all(allPromises).then((response) => {
            CompanyLogger.addSources(response[0].getData(), response[2].getData());
            this.companyLoggerList = response[0].getData();
            this.originSourceGroups = response[1].getData();
            this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(response[1].getData(), response[2].getData());
            let unassignedSources = Source.getUnassignedSources(response[2].getData());
            this.sourceGroups.push(SourceGroup.createNullSourceGroup(unassignedSources));
            this.sources = Source.sortByGroup(response[2].getData());
            this.dataLoadedForStep.LOGGERS = true;
            if (showLoadingAnimation)
                this.isLoading = false;
        });
    }
    loadBoundaryData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true));
        allPromises.push(eventTypeRepository.getSelectedUnits());
        return Promise.all(allPromises).then((response) => {
            this.eventTypes = EventType.addUnits(response[0], response[1]);
            this.dataLoadedForStep.BOUNDARIES = true;
        });
    }
    async loadNotificationsData() {
        this.isLoading = true;
        let allPromises = [];
        this.currentUser = await userRepository.getCurrentUser();
        allPromises.push(notificationSubscriptionsRepository.getNotificationTypes());
        allPromises.push(notificationSubscriptionsRepository.getNotificationSchedules(this.currentUser.apiUserId, this.selectedDepartmentId));
        allPromises.push(userSettingsRepository.loadUserSettings(true));
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, true));
        allPromises.push(notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId));
        allPromises.push(tiersRepository.getActiveTier());
        return Promise.all(allPromises).then((response) => {
            this.departmentUserSettings = response[3];
            let timetableMode = this.departmentUserSettings.notifications.schedule.mode;
            if (timetableMode === NotificationScheduleType.TIMETABLE) {
                this.timetableEnabled = true;
                this.notificationSubscriptions = [];
                this.timetableSchedules = response[1].filter((x) => x.type === NotificationScheduleType.TIMETABLE);
            }
            else {
                this.timetableEnabled = false;
                this.defaultSchedule = response[1].find((x) => x.type === NotificationScheduleType.DEFAULT);
                this.defaultScheduleId = this.defaultSchedule?.id;
                this.notificationSubscriptions = this.defaultSchedule?.subscriptions;
            }
            this.availableTypes = response[0];
            this.settings = response[2];
            this.presets = response[4];
            this.tier = response[5];
            this.dataLoadedForStep.NOTIFICATIONS = true;
            this.isLoading = false;
        });
    }
    reloadBoundaries() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLoggers());
        allPromises.push(sourceGroupRepository.getSourceGroups());
        allPromises.push(sourceRepository.getVisibleSources());
        return Promise.all(allPromises).then((response) => {
            CompanyLogger.addSources(response[0].getData(), response[2].getData());
            this.companyLoggerList = response[0].getData();
            this.originSourceGroups = response[1].getData();
            this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(response[1].getData(), response[2].getData());
            let unassignedSources = Source.getUnassignedSources(response[2].getData());
            this.sourceGroups.push(SourceGroup.createNullSourceGroup(unassignedSources));
            this.sources = Source.sortByGroup(response[2].getData());
            this.isLoading = false;
        });
    }
    canSkipStep(step) {
        if (this.$route.query?.skip === 'true' && AppConfig.getConfig().features.skip_wizard_allowed == true)
            return true;
        switch (step) {
            case WizardSteps.DEVICE:
                return this.deviceList.length > 0;
            case WizardSteps.LOGGERS:
                return (this.companyLoggerList.length > 0 &&
                    this.allSources.length > 0 &&
                    this.companyLoggerList.filter((x) => !x.source.name && x.active == true).length <= 0);
            case WizardSteps.BOUNDARIES:
                return this.allSources.length > 0 && this.allSources.filter((x) => x.boundaries.length == 0).length == 0;
            default:
                return true;
        }
    }
    async finishDeviceStep(id) {
        this.deviceList = (await deviceRepository.getDevices()).getData();
        this.deviceId = id;
        this.moveToNextStep(WizardSteps.LOGGERS);
    }
    closeWizard() {
        if (this.isSetupRequired.toString() === 'false' || this.loggedFromAdmin)
            this.$buefy.dialog.confirm({
                title: `${this.$t('component.wizard.close.title')}`,
                message: `${this.$t('component.wizard.close.body')}`,
                confirmText: `${this.$t('component.wizard.close.confirm')}`,
                cancelText: `${this.$t('component.wizard.close.deny')}`,
                type: 'is-primary',
                hasIcon: true,
                onConfirm: () => {
                    this.finishWizard(this.sourceRoute);
                },
                onCancel: () => { }
            });
        else
            this.$buefy.dialog.alert({
                title: `${this.$t('component.wizard.close.required_title')}`,
                message: `${this.$t('component.wizard.close.required_body')}`,
                type: 'is-primary',
                hasIcon: true
            });
    }
    async finishWizard(routeName = DEFAULT_ROUTE) {
        await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.SETUP_REQUIRED], 'false');
        this.$router
            .push({
            name: routeName,
            params: Object.assign({}, this.sourceRouteParams, {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }),
            query: { confirmed: 'true' }
        })
            .catch((err) => err);
    }
    async applyPreset(selectedPreset) {
        this.isLoading = true;
        if (this.departmentUserSettings?.notifications?.rules?.mode !== NotificationMeasuredRuleScope.COMPANY) {
            //change modo to basic when preset is applied
            await eventsValidationRepository.changeNotificationsMode(NotificationMeasuredRuleScope.COMPANY, this.currentUser.apiUserId, this.selectedDepartmentId);
            //load settings (new setting can be created when mode is changed)
            this.notificationSubscriptions = await notificationSubscriptionsRepository.getAllNotificationSubscriptions(this.currentUser.apiUserId);
        }
        //remove all settings
        let promisesRemove = [];
        this.notificationSubscriptions.forEach((rule) => {
            promisesRemove.push(notificationSubscriptionsRepository.deleteNotificationSubscription(rule.notificationSubscriptionId, this.currentUser.id, this.selectedDepartmentId));
        });
        Promise.all(promisesRemove).then(async (response) => {
            let rulesToCreate = [];
            selectedPreset.subscriptions.forEach((rule) => {
                let newRule = {
                    ScopeType: NotificationScopeType.COMPANY,
                    ScopeId: this.selectedDepartmentId,
                    NotificationType: rule.NotificationType,
                    Enabled: true,
                    Channels: rule.Channels,
                    DisplayMode: NotificationRuleDisplayMode.BASIC,
                    PresetId: selectedPreset.id
                };
                rulesToCreate.push(newRule);
            });
            let result = null;
            result = await notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.currentUser.id, this.selectedDepartmentId);
            if (result instanceof (Array)) {
                VueUtilities.openSuccessToast(this, this.$t('component.notification.create_rule.bulk_success'));
                this.loadNotificationsData();
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.notification.create_rule.failure', {
                    error: result
                }));
                this.isLoading = false;
            }
        });
    }
    skipStep(step) {
        this.moveToNextStep(step + 1);
    }
    backStep(step) {
        this.moveToNextStep(step - 1);
    }
    setStep(step) {
        this.moveToNextStep(step);
    }
    get wizzardTitle() {
        switch (this.actualProcessStep) {
            case WizardSteps.DEVICE:
                return this.$t('component.device.register.modal.title');
                break;
            case WizardSteps.LOGGERS:
                return this.$t('component.logger.configuration.title');
                break;
            case WizardSteps.BOUNDARIES:
                return this.$t('component.wizard.wizardBoundaries.setBoundaries.title');
                break;
            case WizardSteps.NOTIFICATIONS:
                return this.$t('component.notification.title');
                break;
            default:
                return null;
                break;
        }
    }
    get wizzardHelp() {
        switch (this.actualProcessStep) {
            case WizardSteps.INSTRUCTIONS:
                return this.$t('component.wizard.info_modal.body.tutorial.instructions.text');
                break;
            case WizardSteps.DEVICE:
                return this.$t('component.wizard.info_modal.body.tutorial.observer.text');
                break;
            case WizardSteps.LOGGERS:
                return this.$t('component.wizard.info_modal.body.tutorial.loggers.text');
                break;
            case WizardSteps.BOUNDARIES:
                return this.$t('component.wizard.info_modal.body.tutorial.boundaries.text');
                break;
            case WizardSteps.NOTIFICATIONS:
                return this.$t('component.wizard.info_modal.body.tutorial.notifications.text');
                break;
            case WizardSteps.DONE:
                return this.$t('component.wizard.info_modal.body.tutorial.finish.text');
                break;
            default:
                return null;
                break;
        }
    }
    showHelp() {
        this.fullHelpVisible = true;
    }
    notificationClosed() {
        this.fullHelpVisible = false;
    }
    hideHelpComponent(overrideVisibleFlag = false) {
        this.fullHelpVisible = overrideVisibleFlag;
    }
    get allSources() {
        return this.sourceGroups.flatMap((x) => x.sources);
    }
    showDetailedInfo() {
        this.isDetailedInfoModalActive = true;
    }
    closeModal() {
        this.isDetailedInfoModalActive = false;
    }
    onLoggerModalEvent(val) {
        this.helpButtonHidden = val;
    }
};
Wizard = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (from.name && from.name != 'login') {
                    vm.sourceRoute = from.name;
                    vm.sourceRouteParams = from.params;
                }
                if (vm.$ability.can(Actions.REGISTER, Subjects.DEVICE) &&
                    vm.$ability.can(Actions.MANAGE, Subjects.DEVICE) &&
                    vm.$ability.can(Actions.MANAGE, Subjects.LOGGER) &&
                    vm.$ability.can(Actions.MANAGE, Subjects.SOURCE_GROUP) &&
                    vm.$ability.can(Actions.MANAGE, Subjects.SOURCE) &&
                    vm.$ability.can(Actions.CREATE, Subjects.MEASURED_DATA_VALIDATE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            if (!LoggedUserManager.storageContainLoginCredentials(this.$cookies))
                next();
            else if (to.query.confirmed == 'true')
                next();
            else
                this.closeWizard();
        },
        components: {
            ProgressBar,
            SkipStep,
            InstructionsStep,
            DeviceRegistrationStep,
            LoggerRegistrationStep,
            BoundariesStep,
            NotificationsStep,
            WizardHelp,
            DetailedInstructionsModal
        }
    })
], Wizard);
export default Wizard;
